import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FiTrash2 } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';
import { getSqlResponse } from 'services/dataset';
import { updateByReference } from 'services/messageProcessment';
import Modal from 'components/Modal';
import useModal from 'hooks/use-modal';
import useUser from 'hooks/use-user';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import Button from 'components/Button';
import { Text } from 'styles/text';
import {
  ButtonClose,
  ModalHeader,
  ModalTitle,
  FlexModalContainer,
  ModalFooter,
} from 'styles/modal';
import {
  Content,
  InputEmailWrapper,
  AddedEmail,
  SelectContainer,
} from './styles';
import { Email, ModalParams } from './types';
import Select from '../../ReactSelect';
import { isValidEmail } from 'utils/common';
import Input from 'components/Input';

export default function SelectEmailsModal() {
  const { isOpen, params, windowSec } = useModal<ModalParams>(
    ModalName.SELECT_EMAILS,
  );

  const [internalEmailsList, setInternalEmailsList] = useState([] as Email[]);
  const [selectedEmails, setSelectedEmails] = useState([] as Email[]);
  const [externalEmail, setExternalEmail] = useState('');
  const [externalEmailsList, setExternalEmailsList] = useState([] as string[]);

  const dispatch = useDispatch();

  const user = useUser();

  const { addToast } = useToasts();

  useEffect(() => {
    if (isOpen) {
      loadTemplate();
    } else {
      setSelectedEmails([]);
      setExternalEmailsList([]);
      setExternalEmail('');
    }
  }, [isOpen, user]);

  async function loadTemplate() {
    dispatch(
      showModal(ModalName.LOADING, { text: 'Carregando as informações' }),
    );

    if (!user.idEmpresa) return;

    const response = await getSqlResponse<Email[]>(1304, {
      idEmpresa: user.idEmpresa,
    });

    setInternalEmailsList(response);

    if ('emails' in params) {
      setExternalEmailsList(validateEmailArray(params.emails));
    }

    dispatch(closeModal(ModalName.LOADING));
  }

  function validateEmailArray(emails: string[]) {
    const validEmails = [] as string[];
    emails.forEach(email => {
      if (isValidEmail(email) && !validEmails.includes(email)) {
        validEmails.push(email);
      }
    });

    return validEmails;
  }

  function handleClose() {
    dispatch(closeModal(ModalName.SELECT_EMAILS));
  }

  function handleAddEmail() {
    if (!isValidEmail(externalEmail)) {
      addToast('Digite um email válido', { appearance: 'error' });

      return;
    }

    if (
      externalEmailsList.includes(externalEmail) ||
      selectedEmails.some(e => e.value === externalEmail)
    ) {
      addToast('Este email já está adicionado para envio', {
        appearance: 'error',
      });

      return;
    }

    setExternalEmailsList([...externalEmailsList, externalEmail]);
    setExternalEmail('');
  }

  function handleRemoveEmail(email: string) {
    setExternalEmailsList(externalEmailsList.filter(item => item !== email));
  }

  function handleSendEmail() {
    if ('onSendEmail' in params) {
      params.onSendEmail(externalEmailsList);
    } else {
      updateByReference({
        referencia: params.uuid,
        jsonDestino: externalEmailsList,
      });
    }

    addToast('Email enviado', { appearance: 'success' });
    dispatch(closeModal(ModalName.SELECT_EMAILS));
  }

  function handleSetSelectedEmails() {
    if (externalEmailsList.includes(selectedEmails.value)) {
      addToast('Este email já está adicionado para envio', {
        appearance: 'error',
      });

      setSelectedEmails([]);

      return;
    }

    setExternalEmailsList([...externalEmailsList, selectedEmails.value]);
    setSelectedEmails([]);
  }

  return (
    <Modal isOpen={isOpen} windowSec={windowSec}>
      <ModalHeader>
        <ButtonClose onClick={handleClose} />
        <ModalTitle> Selecionar destinatários </ModalTitle>
      </ModalHeader>

      <FlexModalContainer width="60rem" height="50vh">
        <Content>
          <InputEmailWrapper>
            <SelectContainer>
              <Select
                onChange={emails => setSelectedEmails(emails || [])}
                placeholder={`Selecionar email`}
                options={internalEmailsList}
                value={selectedEmails}
              />
            </SelectContainer>
            <Button primary text="+" onClick={handleSetSelectedEmails} />
          </InputEmailWrapper>
          <InputEmailWrapper>
            <Input
              placeholder="Digitar email"
              type="email"
              value={externalEmail}
              onChange={e => setExternalEmail(e.target.value)}
            />
            <Button primary text="+" onClick={handleAddEmail} />
          </InputEmailWrapper>
          <div>
            {externalEmailsList.map(email => (
              <AddedEmail key={email}>
                <Text>{email}</Text>
                <FiTrash2 onClick={() => handleRemoveEmail(email)} />
              </AddedEmail>
            ))}
          </div>
        </Content>
        <ModalFooter>
          <Button
            primary
            disabled={!selectedEmails.length && !externalEmailsList.length}
            text="Enviar Email"
            onClick={handleSendEmail}
          />
        </ModalFooter>
      </FlexModalContainer>
    </Modal>
  );
}
