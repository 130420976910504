import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const GerencialGeral = lazy(() =>
  import('./layouts/geral/gerencial' /* webpackChunkName: 'p4.gerencial' */),
);
const FisicoAcompanhamentoSemanal = lazy(() =>
  import(
    './layouts/fisico/acompanhamentoSemanal' /* webpackChunkName: 'p4.fisico.acompanhamento-semanal' */
  ),
);
const FisicoPortfolioSemanal = lazy(() =>
  import(
    './layouts/fisico/portfolioSemanal' /* webpackChunkName: 'p4.fisico.portfolio-semanal' */
  ),
);
const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'p4.fisico.gerencial' */
  ),
);
const FisicoPortfolio = lazy(() =>
  import(
    './layouts/fisico/portfolio' /* webpackChunkName: 'p4.fisico.portfolio' */
  ),
);
const LinhaDeBalanco = lazy(() =>
  import(
    './layouts/fisico/linhaDeBalanco' /* webpackChunkName: 'p4.fisico.linha-de-balanco' */
  ),
);
const FisicoOfensores = lazy(() =>
  import(
    './layouts/fisico/ofensores' /* webpackChunkName: 'p4.fisico.ofensores' */
  ),
);
const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'p4.financeiro.obras' */
  ),
);
const FinanceiroDashboardObras = lazy(() =>
  import(
    './layouts/financeiro/dashboardObras' /* webpackChunkName: 'p4.financeiro.dashboard-obras' */
  ),
);
const DesviosOrcamento = lazy(() =>
  import(
    './layouts/financeiro/desviosOrcamento' /* webpackChunkName: 'p4.financeiro.desvios-do-orcamento' */
  ),
);
const FinanceiroPortfolio = lazy(() =>
  import(
    './layouts/financeiro/portfolio' /* webpackChunkName: 'p4.financeiro.portfolio' */
  ),
);
const ChecklistQualidade = lazy(() =>
  import(
    './layouts/qualidade/checklist' /* webpackChunkName: 'p4.qualidade.checklist-de-qualidade' */
  ),
);
const InspecaoQualidade = lazy(() =>
  import(
    './layouts/qualidade/inspecaoQualidade' /* webpackChunkName: 'p4.qualidade.inspecao-qualidade' */
  ),
);
const ChecklistQualidade2 = lazy(() =>
  import(
    './layouts/qualidade/checklist2' /* webpackChunkName: 'p4.qualidade.checklist-de-qualidade-2' */
  ),
);
const DashboardGeral = lazy(() =>
  import(
    './layouts/qualidade/dashboardGeral' /* webpackChunkName: 'p4.qualidade.dashboard-geral' */
  ),
);
const ChecklistSeguranca = lazy(() =>
  import(
    './layouts/seguranca/checklist' /* webpackChunkName: 'p4.seguranca.checklist-de-seguranca' */
  ),
);
const FvmGerencial = lazy(() =>
  import('./layouts/fvm/gerencial' /* webpackChunkName: 'p4.fvm.gerencial' */),
);
const FvmFornecedores = lazy(() =>
  import(
    './layouts/fvm/fornecedores' /* webpackChunkName: 'p4.fvm.avaliacao-de-fornecedores' */
  ),
);
const FvmMateriais = lazy(() =>
  import('./layouts/fvm/materiais' /* webpackChunkName: 'p4.fvm.materiais' */),
);
const GeralDeSeguranca = lazy(() =>
  import(
    './layouts/seguranca/relatorioGeralDeSeguranca' /* webpackChunkName: 'p4.seguranca.relatorio-geral-de-seguranca' */
  ),
);
const InspecaoSeguranca = lazy(() =>
  import(
    './layouts/seguranca/inspecaoSeguranca' /* webpackChunkName: 'p4.seguranca.inspecao-seguranca' */
  ),
);
const DashboardAcidentesSeguranca = lazy(() =>
  import(
    './layouts/seguranca/DashboardAcidentes' /* webpackChunkName: 'p4.seguranca.dashboards-acidentes' */
  ),
);
const DashboardSGQQRO = lazy(() =>
  import(
    './layouts/qualidade/dashboardSgqQro' /* webpackChunkName: 'p4.qualidade.dashboards-sgq-qro' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/p4/gerencial"
    component={GerencialGeral}
    path="/relatorios/p4/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/p4/fisico/acompanhamento-semanal"
    component={FisicoAcompanhamentoSemanal}
    path="/relatorios/p4/fisico/acompanhamento-semanal"
  />,
  <PrivateRoute
    key="/relatorios/p4/fisico/portfolio-semanal"
    component={FisicoPortfolioSemanal}
    path="/relatorios/p4/fisico/portfolio-semanal"
  />,
  <PrivateRoute
    key="/relatorios/p4/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/p4/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/p4/fisico/portfolio"
    component={FisicoPortfolio}
    path="/relatorios/p4/fisico/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/p4/fisico/linha-de-balanco"
    component={LinhaDeBalanco}
    path="/relatorios/p4/fisico/linha-de-balanco"
  />,
  <PrivateRoute
    key="/relatorios/p4/fisico/ofensores"
    component={FisicoOfensores}
    path="/relatorios/p4/fisico/ofensores"
  />,
  <PrivateRoute
    key="/relatorios/p4/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/p4/financeiro/obras"
  />,
  <PrivateRoute
    key="/relatorios/p4/financeiro/dashboard-obras"
    component={FinanceiroDashboardObras}
    path="/relatorios/p4/financeiro/dashboard-obras"
  />,
  <PrivateRoute
    key="/relatorios/p4/financeiro/desvios-do-orcamento"
    component={DesviosOrcamento}
    path="/relatorios/p4/financeiro/desvios-do-orcamento"
  />,
  <PrivateRoute
    key="/relatorios/p4/financeiro/portfolio"
    component={FinanceiroPortfolio}
    path="/relatorios/p4/financeiro/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/p4/qualidade/checklist-de-qualidade"
    component={ChecklistQualidade}
    path="/relatorios/p4/qualidade/checklist-de-qualidade"
  />,
  <PrivateRoute
    key="/relatorios/p4/qualidade/inspecao-qualidade"
    component={InspecaoQualidade}
    path="/relatorios/p4/qualidade/inspecao-qualidade"
  />,
  <PrivateRoute
    key="/relatorios/p4/qualidade/checklist-de-qualidade-2"
    component={ChecklistQualidade2}
    path="/relatorios/p4/qualidade/checklist-de-qualidade-2"
  />,
  <PrivateRoute
    key="/relatorios/p4/qualidade/dashboard-geral"
    component={DashboardGeral}
    path="/relatorios/p4/qualidade/dashboard-geral"
  />,
  <PrivateRoute
    key="/relatorios/p4/seguranca/checklist-de-seguranca"
    component={ChecklistSeguranca}
    path="/relatorios/p4/seguranca/checklist-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/p4/fvm/gerencial"
    component={FvmGerencial}
    path="/relatorios/p4/fvm/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/p4/fvm/avaliacao-de-fornecedores"
    component={FvmFornecedores}
    path="/relatorios/p4/fvm/avaliacao-de-fornecedores"
  />,
  <PrivateRoute
    key="/relatorios/p4/fvm/materiais"
    component={FvmMateriais}
    path="/relatorios/p4/fvm/materiais"
  />,
  <PrivateRoute
    key="/relatorios/p4/seguranca/relatorio-geral-de-seguranca"
    component={GeralDeSeguranca}
    path="/relatorios/p4/seguranca/relatorio-geral-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/p4/seguranca/inspecao-seguranca"
    component={InspecaoSeguranca}
    path="/relatorios/p4/seguranca/inspecao-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/p4/seguranca/dashboard-acidentes"
    component={DashboardAcidentesSeguranca}
    path="/relatorios/p4/seguranca/dashboard-acidentes"
  />,
  <PrivateRoute
    key="/relatorios/p4/qualidade/dashboard-sgq-qro"
    component={DashboardSGQQRO}
    path="/relatorios/p4/qualidade/dashboard-sgq-qro"
  />,
];
