import Input from 'components/Input';
import { DateModalParams } from 'components/Modal/Calendar';
import React from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { toDateBR, toDateUS, toInputDate } from 'utils/date';

interface Props {
  label: string;
  value: string | null;
  name: string;
  onChange(date: string | null): void;
  disabled?: boolean;
}

export default function InputDateModal(props: Props) {
  const { label, name, value, disabled, onChange } = props;
  const dispatch = useDispatch();

  function handleShowModal() {
    if (disabled) {
      return;
    }

    const handleChangeDate = (_: string, newDate: Date) => {
      onChange(toDateUS(newDate));
    };

    dispatch(
      showModal<DateModalParams>(ModalName.DATE_TIME, {
        autoCloseModal: true,
        name: name,
        value: toInputDate(value),
        showTime: false,
        selectOtherMonths: true,
        showOtherMonths: true,
        dateFormat: 'dd/mm/yy',
        disableClear: false,
        onSelectDate: handleChangeDate,
      }),
    );
  }

  return (
    <Input
      label={label}
      onClick={handleShowModal}
      value={toDateBR(value) || ''}
      disabled={disabled}
    />
  );
}
