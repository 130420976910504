/* eslint-disable @typescript-eslint/ban-types */
import React, { ReactElement } from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import {
  Body,
  ButtonWrapper,
  Container,
  ContainerOverlay,
  Header,
  Subtitle,
  Title,
} from './styles';
import Button from './components/Button';
import { Alert } from '../../store/types';

/**
 * @typeParam Alert
 * @param options.title
 * @param options.subtitle
 * @param options.actions[]
 * @param options.actions[].text
 * @param options.actions[].onClick
 */

export default function showAlert(options: Alert): void {
  const { title, subtitle, actions } = options;

  const node = document.createElement('div');
  document.body.appendChild(node);

  function handleOnClick(onAction: Function | null | undefined) {
    unmountComponentAtNode(node);

    if (onAction) {
      onAction();
    }
  }

  function closeAlert() {
    unmountComponentAtNode(node);
    document.body.removeChild(node);
  }

  function renderActions(): ReactElement[] {
    if (!actions) {
      return [
        <div key="key_alert_fake" />,
        <Button
          key="key_alert_ok_fake"
          onClick={() => handleOnClick(null)}
          text="Ok"
        />,
      ];
    }

    return actions?.map(ac => (
      <Button
        {...ac}
        key={ac.text}
        onClick={() => handleOnClick(ac.onClick)}
        text={ac.text}
      />
    ));
  }

  function renderComponent(): ReactElement {
    return (
      <>
        <ContainerOverlay />
        <Container>
          <Body>
            <Header>
              <Title>{title}</Title>
              <button onClick={closeAlert}>x</button>
            </Header>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            <ButtonWrapper>{renderActions()}</ButtonWrapper>
          </Body>
        </Container>
      </>
    );
  }

  ReactDOM.render(renderComponent(), node);
}
