import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 5.3rem;

  @media only screen and (max-width: 768px) {
    margin-bottom: 3.8rem;
  }
`;

export const StyledNavBar = styled.nav`
  position: fixed;
  width: 100%;
  height: 5.3rem;
  padding: 0 2rem;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.primary};
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    height: 3.8rem;
    padding: 0 1rem;
  }
`;

export const NavLogoImg = styled.img`
  width: 16rem;
  height: 2rem;

  @media only screen and (max-width: 768px) {
    width: 2.3rem;
    height: 2.2rem;
  }
`;

export const TitleWrapper = styled.div<{ size: number }>`
  position: absolute;
  width: calc(100vw - 4rem);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;

  span {
    color: #fff;
    font-size: 2.2rem;

    @media only screen and (max-width: 768px) {
      font-size: 1.6rem;
    }
  }
`;
