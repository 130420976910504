import React from 'react';
import Modal from '..';
import useModal from '../../../hooks/use-modal';
import { ModalName } from '../../../store/modal/types';
import { Container, Text, Card } from './styles';
import Spinner from '../../Spinner';
import { LoadingModalParams } from '../../../store/types';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    background: 'transparent',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 9999999,
  },
};

export default function LoadingModal() {
  const { isOpen, params, windowSec } = useModal<LoadingModalParams>(
    ModalName.LOADING,
  );

  if (windowSec) {
    customStyles.overlay.zIndex += windowSec;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => null}
      style={customStyles}
      windowSec={windowSec}
    >
      <Container>
        <Card>
          <Spinner size="large" color="#fff" />
          <Text>{params?.text || 'Carregando'}</Text>
        </Card>
      </Container>
    </Modal>
  );
}
