import camelcaseKeys from 'camelcase-keys';
import { v1 as uuid } from 'uuid';
import { OptionsType } from '../store/types';
import { EnumEnterpise } from 'types/enterprise';

export function removeDuplicate<T, U extends keyof T>(data: T[], key: U): T[] {
  return Array.from(
    data
      .reduce((innerKey, item) => innerKey.set(item[key], item), new Map())
      .values(),
  );
}

export function getBrowserName() {
  let sBrowser;
  const sUsrAg = navigator.userAgent;

  if (sUsrAg.indexOf('Chrome') > -1) {
    sBrowser = 'Google Chrome';
  } else if (sUsrAg.indexOf('Safari') > -1) {
    sBrowser = 'Apple Safari';
  } else if (sUsrAg.indexOf('Opera') > -1) {
    sBrowser = 'Opera';
  } else if (sUsrAg.indexOf('Firefox') > -1) {
    sBrowser = 'Mozilla Firefox';
  } else if (sUsrAg.indexOf('MSIE') > -1) {
    sBrowser = 'Microsoft Internet Explorer';
  }

  return sBrowser;
}

export function getDevice() {
  const width = window.innerWidth;

  if (width > 850) return 'desktop';

  if (width > 480) return 'tablet';

  return 'mobile';
}

export function toSelectOptions<T>(
  data: T[],
  keys: OptionsType,
): OptionsType[] {
  return data.map(val => ({
    value: val[keys.value],
    label: val[keys.label],
  }));
}

export function normalizeParamsUrl(
  path: string,
  paramToReplace: string,
  paramValue: string | number,
): string {
  return path.replace(paramToReplace, paramValue.toString());
}

export function preventRelaod(location: any): boolean {
  const state = location.state as { preventReload: boolean };
  if (state && state.preventReload) {
    return true;
  }

  return false;
}

export function numericArrayOf(length: number) {
  return Array.from(Array(length), (x, i) => i + 1);
}

export function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}

export function chunkArray<T>(data: T[], chunkSize: number): [[T]] {
  const results = [];
  const parts = Math.ceil(data.length / chunkSize);

  for (let i = 0; i < parts; i++) {
    const partData = data.slice(i * chunkSize, (i + 1) * chunkSize);
    results.push(partData);
  }

  return results;
}

export function isObjectEmpty(value: any) {
  return value ? !Object.keys(value).length : true;
}

export function removeNonNumeric(value: string) {
  return value.replace(/\D/g, '');
}

export function currencyToNumber(value: string | number | undefined) {
  return value ? +value.toString().replace(',', '.') : 0;
}

export function dotToComma(value: string | number | undefined) {
  return value ? value.toString().replace('.', ',') : '';
}

export function toTrunc(value: number, places = 2) {
  let stringValue = value.toString();

  if (stringValue.indexOf('.') >= 0) {
    stringValue = stringValue.slice(0, stringValue.indexOf('.') + places + 1);

    return +stringValue;
  }

  return value;
}

export function toRound(value: number, places = 2) {
  return value?.toString().indexOf('.') >= 0 ? +value?.toFixed(places) : value;
}

export function jsonToCamelCase<T>(jsonData: string, deep = false) {
  const normalizedJson = jsonData
    ? camelcaseKeys(JSON.parse(jsonData), { deep })
    : jsonData;

  return normalizedJson as T;
}

export function checkOutOfLimit(value: number) {
  return value < -5 || value > 5;
}

export function zeroPad(value: string | number, places: number) {
  return String(value).padStart(places, '0');
}

export function generateUUID() {
  return uuid();
}

export function isValidEmail(email: string) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export function isPrdEnvironment() {
  // return process.env.NODE_ENV === 'production'; //webpack-comments

  return import.meta.env.VITE_NODE_ENV === 'production'; // vite-comments
}

export function isApiPrd() {
  const host = import.meta.env.VITE_BASE_URL_ADONIS;

  return host === 'https://apiadonis.gescorpgo.com';
}

export function normalizeFieldJsonList<T>(data: T[], field: keyof T) {
  return data.map(item => {
    if (typeof item[field] === 'string') {
      return { ...item, [field]: JSON.parse(item[field] as string) };
    }

    return item;
  });
}

export function jsonParse(data: string | object) {
  return typeof data === 'string' ? JSON.parse(data) : data;
}

export function formatValueWithDecimals(value: string, places: number) {
  const normalizedValue = currencyToNumber(value);

  return Number(normalizedValue).toFixed(places);
}

export function isGescorpGo(idEmpresa: number) {
  return EnumEnterpise.GESCORP_GO === idEmpresa;
}
