import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setReportQueueList } from 'store/report/actions';
import { RootState } from 'store/rootReducer';
import Item from './Item';
import {
  ButtonClose,
  ButtonCloseWrapper,
  Container,
  Header,
  ListContainer,
} from './styles';

export default function ReportObserver() {
  const { reportQueue } = useSelector((state: RootState) => state.report);

  const [isShowing, setIsShowing] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsShowing(!!reportQueue.length);
  }, [reportQueue.length]);

  function handleClose() {
    setIsShowing(false);
    dispatch(setReportQueueList([]));
  }

  if (!isShowing) {
    return null;
  }

  return (
    <Container>
      <Header>
        <h3> Fila de Relatórios </h3>

        <ButtonCloseWrapper>
          <ButtonClose onClick={handleClose} />
        </ButtonCloseWrapper>
      </Header>
      <ListContainer>
        {reportQueue.map(report => (
          <Item key={report.uuid} report={report} />
        ))}
      </ListContainer>
    </Container>
  );
}
