import { createContext, useContext } from 'react';
import {
  TableTreeviewRow,
  ExpandedRows,
  DatasetParams,
  TableConfigFile,
  DynamicTableFilters,
} from '../../../store/types';
import { Column } from 'react-data-grid';
import { Dispatch } from 'redux';

export interface DynamicTableContextProps {
  layoutConfig: TableConfigFile | null;
  rows: TableTreeviewRow[];
  selectedFilters: DynamicTableFilters[];
  totalResults: number;
  treeDepth: number;
  idCotacaoCapa?: number;
  isClosePrice?: boolean;
  isOpenTooltip?: boolean;
  rowData?: any;
  addToast: any;
  dispatch: Dispatch<any>;
  setRows: React.Dispatch<React.SetStateAction<TableTreeviewRow[]>>;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<DynamicTableFilters[]>
  >;
  setColumns: React.Dispatch<React.SetStateAction<Column<any>[]>>;
  setIsOpenTooltip?: React.Dispatch<React.SetStateAction<boolean>>;
  setRowData?: React.Dispatch<React.SetStateAction<any>>;
  setExpandedRows: React.Dispatch<React.SetStateAction<ExpandedRows>>;
  setTotalResults: React.Dispatch<React.SetStateAction<number>>;
  clearTable(): void;
  getRows(isReload?: boolean): Promise<void>;
  reloadQuotationTable(isReload?: boolean): Promise<void>;
  getFiltersParameters(): DatasetParams;
  setStateRef<T>(state: T): void;
  getStateRef<T>(): T;
  onCellExpandWithFetchData(
    currentExpandedRow: TableTreeviewRow,
    currentRows: TableTreeviewRow[],
    isReload: boolean,
  ): void;
  onRemoveRow(
    rowToRemove: TableTreeviewRow,
    newCurrentRows?: TableTreeviewRow[],
  ): void;
  modalParams: DatasetParams;
  onAfterClose(): void;
  reloadFilters(): void;
}

export const DynamicTableContext = createContext<DynamicTableContextProps>(
  {} as DynamicTableContextProps,
);

export function useDynamicTable() {
  return useContext(DynamicTableContext);
}
