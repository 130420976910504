import React from 'react';
import { LabelInfo, Text } from 'styles/text';
import { toDateTimeBR } from 'utils/date';
import { Row } from '../../../../styles';
import { FormData } from '../../types';

interface Props {
  occurrence: FormData;
}

export default function Info(props: Props) {
  const { occurrence } = props;

  if (!occurrence.dtAbertura) {
    return null;
  }

  return (
    <Row>
      <div>
        <LabelInfo>Criado por</LabelInfo>
        <Text>{occurrence.usuarioCriacao}</Text>
      </div>
      <div>
        <LabelInfo>Data de criação</LabelInfo>
        <Text>{toDateTimeBR(occurrence.dtAbertura)}</Text>
      </div>
    </Row>
  );
}
