import styled from 'styled-components';
import ButtonComponent from 'components/Button';
import { MdFileUpload } from 'react-icons/md';

export const ButtonUpload = styled(ButtonComponent)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  gap: 1rem;
  background-color: #ddd;
`;

export const IconUpload = styled(MdFileUpload)`
  font-size: 1.8rem;
`;
