import styled from 'styled-components';
import ButtonComponent from 'components/Button';

export const ModalContainer = styled.div`
  border-radius: ${({ theme }) => theme.radius.medium}px;
  padding: 2rem;
  padding-top: 0;
  width: 65rem;
  height: 80vh;
  position: relative;
  overflow: hidden;
`;
export const ModalHeader = styled.div`
  padding: 0 1.5rem;
  width: 65rem;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  height: 100%;
  padding: 2rem 0;
  display: block;
  overflow: scroll;
  padding-bottom: 6rem;
`;

export const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  padding: 0 2rem 2rem;
`;

export const Button = styled(ButtonComponent)``;
