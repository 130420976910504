import React from 'react';
import { useDispatch } from 'react-redux';
import { getSingleResponse } from 'services/dataset';
import { useDynamicForm } from 'components/Widgets/DynamicForm/context';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { useToasts } from 'react-toast-notifications';
import { EnumActions, EnumProfile, Form } from 'store/types';
import { getFieldsAllowedToCopy } from '../../../utils';
import { Button } from '../../../styles';
import WithPermission from 'components/WithPermission';

interface CopyButtonProps {
  parametersToSave: {
    data: string;
    id_formulario: number;
  };
  isDisabled?: boolean;
}

export default function CopyButton(props: CopyButtonProps) {
  const {
    globalState,
    setGlobalState,
    newFormData,
    contractId: idContrato,
  } = useDynamicForm();

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { isDisabled } = props;

  async function handleCopyLastDay() {
    dispatch(showModal(ModalName.LOADING, { text: 'Buscando último diário' }));

    if (!globalState) {
      return;
    }

    const { templateConfig, datasetParams } = globalState;

    const { tipo } = templateConfig;

    const { data } = datasetParams;

    const res = await getSingleResponse<Form>(666, {
      tipo,
      idContrato,
      data,
    });

    if (!res) {
      addToast('Nenhum diário anterior encontrato');
      dispatch(closeModal(ModalName.LOADING));

      return;
    }

    const { idFormulario, form } = res;

    const formattedFormData = getFieldsAllowedToCopy(
      JSON.parse(form),
      templateConfig,
    );

    newFormData.current = formattedFormData;

    const { parametersToSave } = props;

    if (parametersToSave) {
      newFormData.current = {
        ...formattedFormData,
        ...parametersToSave,
      };
    }

    setGlobalState({
      ...globalState!,
      datasetParams: { ...datasetParams, idFormulario },
      defaultFormData: { form: newFormData.current } as Form,
    });

    dispatch(closeModal(ModalName.LOADING));
  }

  function renderCopyButton() {
    if (
      globalState?.defaultFormData?.idFormulario ||
      !globalState?.templateConfig?.templatePortal?.allowCopyLastDay
    ) {
      return <></>;
    }

    return (
      <WithPermission
        action={EnumActions.COPIAR}
        rules={[
          EnumProfile.ADMINISTRADOR,
          EnumProfile.PRODUCAO,
          EnumProfile.EXDCUTIVO,
          EnumProfile.DEMO,
          EnumProfile.OPERACIONAL,
          EnumProfile.APROVADOR,
          EnumProfile.AUDITOR,
          EnumProfile.GESTOR,
        ]}
      >
        <Button
          small
          text="Recuperar último diário"
          onClick={handleCopyLastDay}
          disabled={isDisabled}
        />
      </WithPermission>
    );
  }

  return renderCopyButton();
}
