import React, { useState } from 'react';
import {
  EyeIconViewPointOff,
  EyeIconViewPointOn,
  IconWrapper,
  InputContainer,
} from './styles';

type Props = React.InputHTMLAttributes<HTMLInputElement>;

export default function PasswordInput(props: Props) {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <InputContainer>
      <input {...props} type={passwordVisible ? 'text' : 'password'} />
      <IconWrapper onClick={() => setPasswordVisible(!passwordVisible)}>
        {passwordVisible ? <EyeIconViewPointOff /> : <EyeIconViewPointOn />}
      </IconWrapper>
    </InputContainer>
  );
}
