import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const InspecaoQualidade = lazy(() =>
  import(
    './layouts/qualidade/inspecaoQualidade' /* webpackChunkName: "relatorios.canopus.qualidade.inspecao-qualidade" */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/canopus/qualidade/inspecao-qualidade"
    component={InspecaoQualidade}
    path="/relatorios/canopus/qualidade/inspecao-qualidade"
  />,
];
