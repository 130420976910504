import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { LoadingModalParams } from 'store/types';
import { generateFormPdf, GeneratePDFProps } from 'services/report';
import { useDynamicForm } from 'components/Widgets/DynamicForm/context';
import { Button } from '../../../styles';
import { generateUUID } from 'utils/common';
import { setReportQueue } from 'store/report/actions';
import { create as createReportQueue } from 'services/report_queue';

export default function PdfButton() {
  const dispatch = useDispatch();

  const { approvalData, globalState, reportData } = useDynamicForm();

  async function generateNewPDF() {
    const reportUuid = generateUUID();

    const { defaultFormData, datasetParams } = globalState!;

    const nome =
      globalState?.templateConfig.templatePortal?.titlePDF || 'Relatório';

    dispatch(
      setReportQueue({
        nome,
        uuid: reportUuid,
        status: 0,
      }),
    );

    await createReportQueue({
      nome,
      idDataset: 0,
      uuid: reportUuid,
      idContrato: defaultFormData?.idContrato || 0,
      idEmpresa: defaultFormData?.idEmpresa,
      parametros: {
        ...datasetParams,
        idFormulario: defaultFormData?.idFormulario,
      },
      tipoRelatorio: 'dynamic-form',
    });
  }

  async function generateOldPDF() {
    dispatch(
      showModal<LoadingModalParams>(ModalName.LOADING, {
        text: 'Gerando Relatório',
      }),
    );

    const pdfPayload: GeneratePDFProps = {
      idFormulario: globalState?.defaultFormData?.idFormulario,
      aprovacao: approvalData,
      ...reportData.current,
    };

    const res = await generateFormPdf(pdfPayload);

    window.open(res.toString());
  }

  function handleGeneratePDF() {
    if (!globalState?.defaultFormData?.idFormulario) {
      return;
    }

    if (globalState?.templateConfig.templatePortal?.allowNewPDF) {
      generateNewPDF();
    } else {
      generateOldPDF();
    }

    dispatch(closeModal(ModalName.LOADING));
  }

  function renderPDFButton() {
    if (
      globalState?.templateConfig?.templatePortal?.allowPDF &&
      globalState?.defaultFormData?.idFormulario
    ) {
      return <Button small text="Gerar PDF" onClick={handleGeneratePDF} />;
    }

    return <></>;
  }

  return renderPDFButton();
}
