import React from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { Button } from '../../../styles';
import { useDynamicForm } from 'components/Widgets/DynamicForm/context';
import { generateUUID } from 'utils/common';
import { create as createReportQueue } from 'services/report_queue';
import { ModalParams } from 'components/Modal/SelectEmails/types';

export default function SendEmailButton() {
  const dispatch = useDispatch();
  const { globalState, newFormData } = useDynamicForm();

  async function onSendEmail(emails: string[]) {
    const { defaultFormData, datasetParams } = globalState!;

    await createReportQueue({
      nome:
        globalState?.templateConfig?.templatePortal?.titlePDF || 'Relatório',
      idDataset: 0,
      uuid: generateUUID(),
      idContrato: defaultFormData?.idContrato || 0,
      idEmpresa: defaultFormData?.idEmpresa || 0,
      parametros: {
        ...datasetParams,
        emails,
        idFormulario: defaultFormData?.idFormulario || 0,
      },
      tipoRelatorio: 'dynamic-form',
    });
  }

  function handleShowSendEmailModal() {
    const emails = newFormData.current.tableUserForEmail
      ? newFormData.current.tableUserForEmail.map(user => user.email)
      : [];

    dispatch(
      showModal<ModalParams>(ModalName.SELECT_EMAILS, {
        emails: emails,
        onSendEmail,
      }),
    );
  }

  function renderSendEmailButton() {
    if (
      !globalState?.templateConfig.templatePortal?.allowSendEmail ||
      !globalState?.defaultFormData?.idFormulario
    ) {
      return null;
    }

    return (
      <Button small text="Enviar Email" onClick={handleShowSendEmailModal} />
    );
  }

  return renderSendEmailButton();
}
