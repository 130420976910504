import styled from 'styled-components';

export const StyledDiv = styled.div`
  position: relative;
  padding: 0 26px;

  .icon-container {
    position: absolute;
    left: 4px;
  }
`;
