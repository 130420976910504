import api from './api';

export interface DataValues {
  nome: string;
  empresa: string;
  email: string;
  celular: number;
  comentario: string;
  canalEntrada: string;
}

export function SaveValues(data: DataValues) {
  return api.post('leads/store.json', data);
}
