import ReportObserver from 'components/Widgets/ReportObserver';
import FormOccurrenceModal from 'pages/TableReport/components/Modals/FormOccurrence';
import React, { lazy, ReactElement, Suspense } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './components/GlobalStyles';
import LoadingModal from './components/Modal/Loading';
import ToastMessage from './components/ToastMessage';
import Routes from './routes';
import { theme } from './theme';
import './utils/ReactotronConfig';
import SingleInputModal from 'components/Modal/SingleInput';
import PhotoGalleryComments from 'components/Modal/PhotoGalleryComments';
import CreateUserSimpleModal from 'components/Modal/CreateUserSimple';
import useUser from 'hooks/use-user';
import useContract from 'hooks/use-contract';
import { setTags as SentrySetTags } from '@sentry/react';
import { registerLicense } from '@syncfusion/ej2-base';

const FormSupplierModal = lazy(() => import('./components/Modal/FormSupplier'));
const DynamicFormModal = lazy(() => import('./pages/DynamicForm'));
const UploadModal = lazy(() => import('./components/Modal/Upload'));
const ImageViewerModal = lazy(() => import('./components/Modal/ImageViewer'));
const SelectUserCompany = lazy(() =>
  import('./components/Modal/SelectUserCompany'),
);
const DynamicFormModal2 = lazy(() =>
  import('./components/Modal/DynamicFormModal2'),
);
const PhotoGalleryPeriodModal = lazy(() =>
  import('components/Modal/PhotoGalleryPeriod'),
);

registerLicense(import.meta.env.VITE_LICENSE_KEY_SYNCFUSION);

export default function App(): ReactElement {
  const user = useUser();
  const contract = useContract();

  SentrySetTags({
    userId: user.idUsuario,
    companyId: user.idEmpresa,
    contractId: contract?.idContrato,
    contractName: contract?.nome,
  });

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider
        autoDismissTimeout={3000}
        autoDismiss
        components={{ Toast: ToastMessage }}
      >
        <Routes />
        <GlobalStyles />
        <LoadingModal />
        <ReportObserver />
        <Suspense fallback={null}>
          <DynamicFormModal />
          <FormSupplierModal />
          <ImageViewerModal />
          <FormOccurrenceModal />
          <UploadModal />
          <SingleInputModal />
          <PhotoGalleryComments />
          <SelectUserCompany />
          <CreateUserSimpleModal />
          <DynamicFormModal2 />
          <PhotoGalleryPeriodModal />
        </Suspense>
      </ToastProvider>
    </ThemeProvider>
  );
}
