import moment, { Moment } from 'moment';

export function toDateTimeBR(date: string | null | Date | undefined | Moment) {
  return date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : null;
}

export function toDateBR(date: string | null | Date | undefined | Moment) {
  return date ? moment(date).format('DD/MM/YYYY') : null;
}

export function toDateUS(date: string | null | Date | Moment) {
  return date ? moment(date).format('YYYY-MM-DD') : null;
}

export function toTime(date: string | null) {
  return date ? moment(date).format('HH:mm:ss') : null;
}

export function getUsDateTime() {
  return moment().format('YYYY-MM-DD HH:mm:ss');
}

export function removeUtc(date: string | undefined | null) {
  return date ? moment(date).subtract(3, 'hour') : null;
}

export function getUtcDatetime() {
  return moment()
    .utc()
    .format('YYYY-MM-DD HH:mm:ss');
}

export function toInputDate(date: string | null | undefined) {
  return date
    ? moment(date).toDate()
    : // .toString()
      undefined;
}

export function convertDateBrToUS(date: string) {
  return moment(date, 'DD/MM/YYYY').isValid()
    ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    : date;
}
