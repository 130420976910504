import useUser from 'hooks/use-user';
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { create } from '../../../services/catalog_list';
import { getSqlResponse } from '../../../services/dataset';
import { OptionsType } from '../../../store/types';
import { Label } from '../../../styles/form';
import { CreatableSelect } from '../../ReactSelect';
import { AddCircle, Button, FileUpload, LabelWrapper } from './style';
import showAlert from 'components/Alert';
import { normalizeFileUrl } from 'utils/file';
import { useDispatch } from 'react-redux';
import { closeModal, showModal } from 'store/modal/actions';
import { SingleInputModalParams } from 'components/Modal/SingleInput';
import { ModalName } from 'store/modal/types';
import FormCatalogModal from './FormCatalog';

interface Props {
  label: string;
  name: string;
  idTipo: number;
  idModulo: number;
  datasetId?: number;
  placeholder?: string;
  catalog: OptionsType | OptionsType[] | null;
  setCatalog(name: string, option: OptionsType | OptionsType[]): void;
  isMulti?: boolean;
  isClearable?: boolean;
  disableCreate?: boolean;
  isXlsUploadAllowed?: boolean;
  isCreateModal?: boolean;
  isDisabled?: boolean;
  idContratoFornecedor?: number;
}

export default function SelectCatalog(props: Props) {
  const [options, setOptions] = useState<OptionsType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState<
    OptionsType | OptionsType[] | null
  >([]);

  const user = useUser();
  const {
    label,
    name,
    placeholder,
    catalog,
    isMulti,
    isClearable,
    disableCreate,
    isDisabled,

    // idTipo,
  } = props;

  const dispatch = useDispatch();

  const inputFileRef = useRef<InputHTMLAttributes<HTMLInputElement>>();

  useEffect(() => {
    setSelectedOptions(catalog);
  }, [catalog]);

  useEffect(() => {
    if (user?.idEmpresa) {
      getData();
    }
  }, [user?.idEmpresa]);

  async function getData() {
    const { datasetId, idTipo, idModulo, idContratoFornecedor } = props;

    const res = await getSqlResponse<OptionsType[]>(datasetId || 574, {
      idTipo,
      idEmpresa: user.idEmpresa,
      idModulo,
      idContratoFornecedor,
    });

    setOptions(res);
    setIsLoading(false);
  }

  async function handleCreateNew(option: string) {
    if (disableCreate) {
      return;
    }

    const { idTipo, idModulo, setCatalog } = props;

    dispatch(showModal(ModalName.LOADING, { text: 'Criando...' }));

    setIsLoading(true);

    const newCatalog = await create({
      id: 0,
      idEmpresa: user.idEmpresa!,
      descricao: option.toString(),
      idTipo,
      idModulo,
    });

    const newOption: OptionsType = {
      value: newCatalog.id!,
      label: newCatalog.descricao,
      uuidCatalogo: newCatalog.uuid,
    };

    if (isMulti) {
      const oldOptions = selectedOptions as OptionsType[];
      const newSelectedOptions = [...(oldOptions || []), newOption];

      setCatalog(name, newSelectedOptions);
      setSelectedOptions(newSelectedOptions);
    } else {
      setCatalog(name, newOption);
      setSelectedOptions(newOption);
    }

    setOptions([...options, newOption]);
    setIsLoading(false);

    dispatch(closeModal(ModalName.LOADING));
    dispatch(closeModal(ModalName.SINGLE_INPUT));
  }

  function handleChange(option: OptionsType | OptionsType[]) {
    const { setCatalog } = props;

    setSelectedOptions(option);
    setCatalog(name, option);
  }

  function handleShowModalFormCatalog() {
    dispatch(
      showModal(ModalName.FORM_CATALOG, {
        title: 'Nova area',
        onAfterSave: handleCreateNew,
      }),
    );
  }

  const dynamicProps = disableCreate
    ? { formatCreateLabel: () => undefined }
    : {};

  function renderCreateButton() {
    const { isCreateModal } = props;

    if (!isCreateModal || disableCreate) {
      return null;
    }

    return (
      <Button
        tiny
        text="Criar"
        icon={<AddCircle />}
        onClick={e => {
          e.preventDefault();
          handleShowModalFormCatalog();
        }}
      />
    );
  }

  function renderUploadButton() {
    const { isXlsUploadAllowed } = props;

    if (!isXlsUploadAllowed || disableCreate) {
      return null;
    }

    const handleShowUploadOptions = () => {
      showAlert({
        title: '',
        subtitle: 'Preencher o modelo para realizar carga massiva!',
        actions: [
          {
            text: 'Baixar Modelo Excel',
            onClick: () => {
              window.open(
                normalizeFileUrl(
                  '/datafiles/diversos/diversos/Carga_de_pecas.xlsx',
                ),
              );
            },
          },
          {
            text: 'Selecionar Arquivo',
            onClick: () => inputFileRef.current!.click(),
          },
        ],
      });
    };

    return (
      <Button
        tiny
        text="Upload"
        icon={<FileUpload />}
        onClick={e => {
          e.preventDefault();
          handleShowUploadOptions();
        }}
      />
    );
  }

  return (
    <div>
      <Label>{label}</Label>

      <CreatableSelect
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        isLoading={isLoading}
        onCreateOption={handleCreateNew}
        placeholder={placeholder || ''}
        isClearable={isClearable}
        isMulti={isMulti}
        isDisabled={isDisabled}
        {...dynamicProps}
      />

      <LabelWrapper>
        {renderCreateButton()}
        {renderUploadButton()}
      </LabelWrapper>
      <FormCatalogModal />
    </div>
  );
}
