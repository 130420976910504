import React, { useState, useEffect, useRef, useMemo } from 'react';
import ActionsBar from './components/ActionsBar';
import ColumnFilter from './components/ColumnFilter';
import TreeviewHandler from './components/TreeviewHandler';
import Default from './components/CellTypes/Default';
import TextSlicer from './components/CellTypes/TextSlicer';
import Arrows from './components/CellTypes/Arrows';
import Farol from './components/CellTypes/Farol';
import BarChart from './components/CellTypes/BarChart';
import AvancoBarChart from './components/CellTypes/AvancoBarChart';
import CustomContent from './components/CellTypes/CustomContent';
import CustomStyleType from './components/CellTypes/CustomStyle';
import ChecklistFormatter from './components/CellTypes/Checklist';
import makeTreeviewData from './components/helperFunctions/normalizeTreeviewData';
import { SimpleTableContext } from './context';
import { Container, TableWrapper, TableStyled } from './styles';
import StatusIPS from './components/CellTypes/StatusIPS';
import GenericIcon from 'pages/TableReport/components/Formatters/GenericIcon';
import { SimpleTableProps } from './types';

const cellTypes = {
  default: Default,
  textSlicer: TextSlicer,
  arrows: Arrows,
  farol: Farol,
  barChart: BarChart,
  avancoBarChart: AvancoBarChart,
  customContent: CustomContent,
  customStyle: CustomStyleType,
  checklist: ChecklistFormatter,
  statusIPS: StatusIPS,
  genericIcon: GenericIcon,
};

function renderCellType(type, props) {
  return React.createElement(cellTypes[type], { ...props }, null);
}

function handleOnClick(fn, localProps) {
  if (fn) {
    return fn(localProps);
  }

  return null;
}

export default function SimpleTable<T>(props: SimpleTableProps<T>) {
  const {
    data: propData,
    config,
    multilineHeader,
    isTreeview,
    id,
    stickyHeader,
    stickyFirstRow,
    lockFirstColumn,
    maxHeight,
    xlsExport,
    idPeriodo,
    presetStyle,
    customStyle,
    gradientStartColor,
  } = props;
  const [staticData, setStaticData] = useState<any>([]);
  const [data, setData] = useState([]);
  // const [columns, setColumns] = useState<SimpleTableConfig[]>([]);
  const [filters, setFilters] = useState({});
  const [parentRowsInfo, setParentRowsInfo] = useState({});
  const [levelSelection, setLevelSelection] = useState(null);
  const [treeviewStyle, setTreeviewStyle] = useState({});
  const [maxLevel, setMaxLevel] = useState(1);
  const [stickyFirstRowStyle, setStickyFirstRowStyle] = useState({});

  useEffect(() => {
    if (isTreeview) {
      initTreeview();
    } else {
      setStaticData(propData || []);
      setData(propData || []);
      // setData(normalizeSimpleTableRows(propData || []));
    }

    // const dynamicConfigTable = getDynamicColumns(propData);

    // setColumns([...config, ...dynamicConfigTable]);
  }, [propData]);

  useEffect(() => {
    if (data.length) {
      lockColumnsWidths();
    }
  }, [data]);

  useEffect(() => {
    if (!isTreeview) {
      filterData();
    }
  }, [filters, staticData]);

  const tableRef = useRef(null);

  function initTreeview() {
    if (!propData.length) {
      setStaticData([]);
      setData([]);
    } else {
      // const newPropData = normalizeSimpleTableRows(propData || []);

      const [
        treeviewData,
        parentRowsInfoRes,
        treeviewStyleRes,
        maxLevelAux,
      ] = makeTreeviewData(propData, gradientStartColor);

      setData(treeviewData);
      setParentRowsInfo(parentRowsInfoRes);
      setTreeviewStyle(treeviewStyleRes);
      setMaxLevel(maxLevelAux);
    }
  }

  function lockColumnsWidths() {
    if (!tableRef?.current) {
      setTimeout(lockColumnsWidths, 1000);
    }

    const firstRowTds = tableRef?.current?.querySelectorAll(
      'tbody .row-index-0 td',
    );
    if (firstRowTds?.length) {
      firstRowTds.forEach(col => {
        const { width } = col.getBoundingClientRect();
        col.style.width = `${width}px`;
      });

      if (stickyFirstRow) {
        const stickyFirstRowAux = {
          position: 'sticky',
          zIndex: 101,
          top: 0,
        };

        if (stickyHeader) {
          const headerHeight = tableRef.current
            ?.querySelector('thead')
            .getBoundingClientRect().height;
          stickyFirstRowAux.top = headerHeight;
        }

        setStickyFirstRowStyle(stickyFirstRowAux);
      }
    } else {
      setTimeout(lockColumnsWidths, 1000);
    }
  }

  function filterData() {
    const filterKeys = Object.keys(filters);

    let newData = staticData;

    filterKeys.forEach(key => {
      const filterValues = filters[key];

      newData = newData.filter(
        row => filterValues.indexOf(row[key]?.toString()) > -1,
      );
    });

    setData(newData);
  }

  function renderActionsBar() {
    if (!isTreeview) {
      return <></>;
    }

    return <ActionsBar />;
  }

  function getMaxValue(indicadorName: string) {
    if (!data) return;

    const onlyIndicadores = data.map(dt => {
      const result = JSON.parse(dt[indicadorName]);

      if (typeof result.value !== 'string') {
        return result.value;
      }

      return false;
    });

    const maxValue = Math.max(...onlyIndicadores);

    return maxValue;
  }

  function renderMultilineHeader() {
    if (!multilineHeader) {
      return <></>;
    }

    return multilineHeader.map(row => (
      <tr key={`${row.label}-${row.colspan}`}>
        {row.map(col => (
          <th
            key={`${col.label}-${col.colspan}`}
            style={col.style}
            colSpan={col.colspan}
            rowSpan={col.rowspan}
          >
            {col.label}
          </th>
        ))}
      </tr>
    ));
  }

  function renderTable() {
    if (!config) return <></>;

    const { className } = props;

    return (
      <TableWrapper
        maxHeight={maxHeight}
        lockFirstColumn={lockFirstColumn}
        className={className}
      >
        <TableStyled
          ref={tableRef}
          stickyHeader={stickyHeader}
          stickyFirstRowStyle={stickyFirstRowStyle}
          lockFirstColumn={lockFirstColumn}
          preset={presetStyle}
          treeviewStyle={treeviewStyle}
        >
          <thead>
            {renderMultilineHeader()}
            <tr>
              {config.map((col, index) => {
                const thStyle = col.thStyle || {};

                return (
                  <th
                    key={`col-index-${index} col-key-${col.key}`}
                    className={`col-index-${index} col-key-${col.key}`}
                    style={thStyle}
                  >
                    <div
                      className="header-wrapper"
                      style={col.enableFilter ? { paddingRight: '18px' } : {}}
                    >
                      <span>{col.headerLabel}</span>
                      {col.enableFilter ? (
                        <ColumnFilter
                          data={staticData}
                          colKey={col.key}
                          filterOrientation={col.filterOrientation}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => {
              const customAttrs = {};
              let trClassStr = `row-index-${rowIndex} row-${
                rowIndex % 2 === 0 ? 'even' : 'odd'
              }`;
              if (isTreeview) {
                trClassStr += ` row-level-${row.level}`;
                customAttrs['data-key'] = row.key;
                customAttrs['data-is-open'] = 'yes';
                customAttrs['data-is-visible'] = 'yes';
                if (row.isLastChild) {
                  trClassStr += ` treeview__is-last-child`;
                }
              }

              return (
                <tr
                  key={`${row.key}.${rowIndex}`}
                  className={trClassStr}
                  {...customAttrs}
                >
                  {config.map((col, colIndex) => {
                    const localProps = {
                      row,
                      col,
                      maxValue: col.cellTypeParams?.maxValueBy
                        ? getMaxValue(col.key)
                        : false,
                      value: row[col.key] != null ? row[col.key] : '',
                      rowIndex,
                    };
                    const cellType = col.cellType || 'default';
                    const tdStyle =
                      col.tdStyle || row?.rowColor
                        ? {
                            ...col?.tdStyle,
                            backgroundColor: row?.rowColor,
                            color: row?.textColor,
                          }
                        : {};

                    let tdClassStr = `col-index-${colIndex} col-key-${col.key}`;
                    if (colIndex === 0 && isTreeview && !row.isLastChild) {
                      tdClassStr += ' treeview__is-handler';
                    }

                    return (
                      <td
                        key={`${colIndex}.${col.key}`}
                        className={tdClassStr}
                        onClick={() => handleOnClick(col.onClick, localProps)}
                        style={tdStyle}
                      >
                        {colIndex === 0 && isTreeview && !row.isLastChild ? (
                          <TreeviewHandler rowKey={row.key} />
                        ) : (
                          <></>
                        )}
                        {renderCellType(cellType, localProps)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </TableStyled>
      </TableWrapper>
    );
  }

  const simpleTableContextProps = useMemo(
    () => ({
      id,
      parentRowsInfo,
      setFilters,
      levelSelection,
      setLevelSelection,
      xlsExport,
      idPeriodo,
      maxLevel,
    }),
    [
      id,
      parentRowsInfo,
      setFilters,
      levelSelection,
      setLevelSelection,
      xlsExport,
      idPeriodo,
      maxLevel,
    ],
  );

  return (
    <SimpleTableContext.Provider value={simpleTableContextProps}>
      <Container customStyle={customStyle} id={id && id}>
        {renderActionsBar()}
        {renderTable()}
      </Container>
    </SimpleTableContext.Provider>
  );
}
