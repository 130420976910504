import React from 'react';
import { Container, Span, IconLegend } from './style';

export function Legend(props) {
  const { data } = props;

  if (!data) return null;

  function renderLegend() {
    return data.legendas.map(item => {
      return (
        <Container key={item.type}>
          <IconLegend
            style={{
              borderRadius: `${item.type === 'circle' ? '50%' : 0}`,
              backgroundColor: `${item.backgroundColorRGB}`,
            }}
          />
          <div>
            <Span>{item.text}</Span>
            <div>
              {item.pecas && (
                <>
                  <Span>Peças: </Span>
                  <Span>{item.pecas.map(peca => peca.label).join(', ')}</Span>
                </>
              )}
            </div>
          </div>
        </Container>
      );
    });
  }

  return (
    <>
      <h2>Legendas</h2>
      {renderLegend()}
    </>
  );
}
