import showAlert from 'components/Alert';
import Spinner from 'components/Spinner';
import useUser from 'hooks/use-user';
import { Params } from 'pages/TableReport/components/Modals/FormOccurrence';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { getSqlResponse } from 'services/dataset';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { useDynamicForm } from '../../context';
import { RowTitle, TitleWrapper } from '../../styles';
import { Field, State } from '../../types';
import { checkUserEditPermission } from '../../utils';
import { AddIcon, EditIcon, TableStyled, TableWrapper, Th } from './styles';

interface FieldProps extends Field {
  templateType: string;
}

interface Props {
  field: FieldProps;
  isDisabled?: boolean;
}

export default function TableOccurrence(props: Props) {
  const [data, setData] = useState<State[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    globalState,
    contractId: idContrato,
    approvalData,
    onSaveForm,
    setGlobalState,
    fieldsAllowProps,
  } = useDynamicForm();

  const dispatch = useDispatch();

  const user = useUser();

  const { field, isDisabled } = props;

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    getData();
  }, [globalState]);

  async function getData() {
    const { datasetParams, defaultFormData } = globalState!;

    setIsLoading(true);

    const params = {
      ...datasetParams,
      idContrato,
      idFormulario: defaultFormData?.idFormulario || null,
    };

    const res = await getSqlResponse<State[]>(field.datasetId!, params, true);
    setData(res);

    setIsLoading(false);
  }

  function openFormModal(rowData: State | null) {
    if (isDisableChecked) {
      return;
    }

    const { defaultFormData } = globalState!;

    /* The form need to be saved before add images */
    if (!defaultFormData?.idFormulario) {
      showAlert({
        title: 'Atenção!',
        subtitle:
          'Para adicionar uma ocorrência é necessário salvar antes. Deseja salvar os dados editados?',
        actions: [
          {
            text: 'Não',
          },
          {
            text: 'Sim',
            onClick: onSaveForm,
          },
        ],
      });

      return;
    }

    dispatch(
      showModal<Params>(ModalName.FORM_OCCURRENCE, {
        idContrato,
        itemNome: '',
        idOcorrencia: rowData?.id_ocorrencia || 0,
        referencia: defaultFormData!.idFormulario.toString(),
        isNewSupplierTable: true,
        onClose: () => {
          setGlobalState({ ...globalState });
          getData();
        },
        shouldUpdateOccurrenceAfterSaveComment: true,
        templateType: field?.templateType,
        idTemplateFormulario:
          field?.idTemplateFormulario || rowData?.id_template_formulario,
      }),
    );
  }

  function renderColumns(rowData: State) {
    const normalizeText = (tableField: Field) => {
      return rowData[tableField.name];
    };

    return field.fields.map((tableField, idx) => {
      return (
        <td key={`${rowData.uuid}-${tableField.name}`}>
          {normalizeText(tableField)}

          {idx === field.fields.length - 1 && !isDisableChecked && <EditIcon />}
        </td>
      );
    });
  }

  function renderRows() {
    return data.map(rowData => (
      <tr key={rowData.uuid} onClick={() => openFormModal(rowData)}>
        {renderColumns(rowData)}
      </tr>
    ));
  }

  function renderButtons() {
    if (isDisableChecked) {
      return <></>;
    }

    return (
      <>
        {!field.disableAdd && (
          <AddIcon data-tip="Adicionar" onClick={() => openFormModal(null)} />
        )}
      </>
    );
  }

  return (
    <TableWrapper>
      <TitleWrapper>
        {field.label ? (
          <RowTitle dangerouslySetInnerHTML={{ __html: field.label }} />
        ) : (
          <div />
        )}

        {isLoading && <Spinner />}
        {renderButtons()}
      </TitleWrapper>

      <TableStyled>
        <thead>
          <tr>
            {field.fields.map(configField => (
              <Th key={`h-${configField.name}`} size={configField.size}>
                {configField.label}
              </Th>
            ))}
          </tr>
        </thead>

        <tbody>{renderRows()}</tbody>
      </TableStyled>
      <ReactTooltip />
    </TableWrapper>
  );
}
