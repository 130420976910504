import React, { lazy } from 'react';
import { PrivateRoute } from 'routes';
const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.planem.fisico.gerencial' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/planem/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/planem/fisico/gerencial"
  />,
];
