import styled from 'styled-components';

export const Card = styled.div`
  background: #fff;
  padding: 2rem;
  border-radius: ${({ theme }) => theme.radius.medium}px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  overflow: hidden;

  .slicer-container {
    ul {
      display: flex;
      flex-direction: row;
    }
  }
`;

export const CardTitle = styled.h1`
  padding-bottom: 1.1rem;
  text-align: center;
  text-transform: capitalize;
`;
