import { IoMdAddCircle } from 'react-icons/io';
import { MdFileDownload } from 'react-icons/md';
import styled from 'styled-components';

export const AddIcon = styled(IoMdAddCircle)`
  position: absolute;
  right: 2px;
  top: -3.8rem;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export const Container = styled.div`
  position: relative;
`;

export const IconDownload = styled(MdFileDownload)`
  color: #333;
  font-size: 2.2rem;
  cursor: pointer;
  display: none;
`;

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0.5rem 0.3rem 0;

    img {
      margin-right: 1rem;
    }

    span {
      font-size: 1.4rem;
    }

    &:hover {
      cursor: pointer;
      background-color: #eee;

      & ${IconDownload} {
        display: block;
      }
    }
  }
`;
