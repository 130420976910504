import Button from 'components/Button';
import React from 'react';
import { Text } from 'styles/text';
import { removeUtc, toDateTimeBR } from 'utils/date';
import { Reply } from '../../types';

import { Card, Col, Container, DateText, Row, Spinner } from './styles';

interface Props {
  isLoadingReply: boolean;
  replies: Reply[];
  onShowReplyModal(reply: Reply);
}

export default function OccurrenceReplyList(props: Props) {
  const { isLoadingReply, replies, onShowReplyModal } = props;

  if (isLoadingReply) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (!replies.length) {
    return null;
  }

  return (
    <Container>
      <Row>
        <h2>Atualizações</h2>
      </Row>

      {replies.map(reply => (
        <Card key={reply.idComentario}>
          <Col>
            <Row>
              <div>
                <DateText>{toDateTimeBR(removeUtc(reply.dtCriacao))}</DateText>
                <Text> {reply.nomeUsuario} </Text>
              </div>
            </Row>

            <Row>
              <Text>{reply.comentario}</Text>
            </Row>
          </Col>

          <Button
            tiny
            type="button"
            text="Ver mais"
            onClick={onShowReplyModal(reply)}
          />
        </Card>
      ))}
    </Container>
  );
}
