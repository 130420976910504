import { createContext, useContext } from 'react';
import { GlobalState, State } from './types';

import { FormApproval } from '../../../store/types';

export interface DynamicFormContextProps {
  approvalData: FormApproval | null;
  reportData: State;
  newFormData: State;
  globalState: GlobalState | null;
  setGlobalState(props: GlobalState | null): void;
  onSaveForm(): Promise<void>;
  getApprovalData(idFormulario: number, tipo: string): Promise<void>;
  setApprovalData(props: FormApproval | null): void;
  setRequiredFields(props: State): void;
  contractId: number;
  fieldsAllowProps: string[];
}

export const DynamicFormContext = createContext<DynamicFormContextProps>(
  {} as DynamicFormContextProps,
);

export function useDynamicForm() {
  return useContext(DynamicFormContext);
}
