import { theme } from 'theme';
import { StatusApproval } from './types';

export const STATUS_ITEMS = {
  [StatusApproval.REEVALUATE]: {
    label: 'Reavaliar',
    color: theme.colors.grey40,
  },
  [StatusApproval.APPROVED]: {
    label: 'Aprovado',
    color: theme.colors.primary,
  },
  [StatusApproval.REPPROVED]: {
    label: 'Reprovado',
    color: theme.colors.danger,
  },
};
