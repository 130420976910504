import React from 'react';
import {
  ContainerCard,
  ContainerFlex,
  ContainerStatus,
  EditIcon,
  IconsContainer,
  LabelList,
  RemoveIcon,
  SignedWrapper,
} from './styles';
import { EnumStatus, Subscriber } from 'pages/DocSign/types';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import Button from 'components/Button';
import moment from 'moment';

interface Props {
  data: Subscriber;
  handleEdit: (item: Subscriber) => void;
  handleDelete: (item: Subscriber) => void;
  handletoSign: (item: Subscriber) => void;
}
export default function SubscriberCard(props: Props) {
  const { data, handleEdit, handleDelete, handletoSign } = props;

  function renderRowButtons() {
    console.log(data.status);
    if (!data?.status) {
      return (
        <IconsContainer>
          <EditIcon onClick={() => handleEdit(data)} />
          <RemoveIcon onClick={() => handleDelete(data)} />
        </IconsContainer>
      );
    }

    if (data?.status === EnumStatus.REGISTER_SUBSCRIBERS_DONE) {
      return (
        <IconsContainer>
          <Button
            primary
            small
            text="Assinar agora"
            onClick={() => handletoSign(data)}
            icon={<EditIcon color="#fff" />}
          />
        </IconsContainer>
      );
    }

    if ([EnumStatus.SIGNED, EnumStatus.SIGNED_PDF].includes(data!.status)) {
      return (
        <SignedWrapper>
          <ContainerStatus>
            <IoMdCheckmarkCircle />
            Assinado
          </ContainerStatus>
          <p>{moment(data.dtAssinatura).format('DD/MM/YYYY HH:mm')}</p>
        </SignedWrapper>
      );
    }

    return <ContainerFlex></ContainerFlex>;
  }

  return (
    <ContainerCard key={data.email}>
      <h3 className="text">{data.nome}</h3>
      <p>{data.email}</p>
      <LabelList>{data.cargo}</LabelList>
      <LabelList>{data.alcada}</LabelList>
      {renderRowButtons()}
    </ContainerCard>
  );
}
