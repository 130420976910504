export function dataURLtoFile(dataUrl: string, filename: string) {
  const arr = dataUrl.split(',');
  const mimeMatch = arr[0].match(/:(.*?);/);
  const mime = mimeMatch ? mimeMatch[1] : 'image/png';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export async function initCam(videoRef: React.RefObject<HTMLVideoElement>) {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'user' },
      audio: false,
    });

    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  } catch (error) {
    console.error('Erro ao acessar a câmera:', error);
  }
}

export function stopCam(videoRef: React.RefObject<HTMLVideoElement>) {
  if (videoRef.current && videoRef.current.srcObject) {
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach(track => track.stop());

    videoRef.current.srcObject = null;
  }
}

export function captureFrameFromVideo(
  videoRef: React.RefObject<HTMLVideoElement>,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  fileName: string,
) {
  const canvas = canvasRef.current!;
  const context = canvas.getContext('2d');

  canvas.width = videoRef.current!.videoWidth;
  canvas.height = videoRef.current!.videoHeight;

  context!.drawImage(videoRef.current!, 0, 0, canvas.width, canvas.height);

  const imageUrl = canvas.toDataURL('image/jpeg');

  return dataURLtoFile(imageUrl, `${fileName}.jpeg`);
}
