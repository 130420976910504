import Modal from 'components/Modal';
import useModal from 'hooks/use-modal';
import React from 'react';
import { ModalName } from 'store/modal/types';
import { GoBackIcon } from 'pages/DocSign/styles';
import { getModalStyle } from 'pages/DocSign/utils';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modal/actions';
import { Button, DocWrapper } from './styles';
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Inject,
} from '@syncfusion/ej2-react-pdfviewer';
import useDeviceDetect from 'hooks/use-device-detect';

let viewer: PdfViewerComponent;

export interface DocViewerModalParams {
  fileUrl: string;
}

export default function DocViewerModal() {
  const { isOpen, params, windowSec } = useModal<DocViewerModalParams>(
    ModalName.SIGN_DOC_VIEWER,
  );

  const dispatch = useDispatch();
  const deviceSize = useDeviceDetect();

  function handleClose() {
    dispatch(closeModal(ModalName.SIGN_DOC_VIEWER));
  }

  const { fileUrl } = params;

  function created() {
    viewer.toolbarSettings = {
      toolbarItems: [
        'PageNavigationTool',
        'MagnificationTool',
        'PanTool',
        'PrintOption',
      ],
    };

    viewer.dataBind();
  }

  return (
    <Modal
      isOpen={isOpen}
      windowSec={windowSec}
      openLikePage
      style={getModalStyle()}
    >
      <DocWrapper isDesktop={deviceSize.isDesktop}>
        <PdfViewerComponent
          ref={scope => {
            viewer = scope;
          }}
          documentPath={fileUrl}
          resourceUrl="https://cdn.syncfusion.com/ej2/23.2.6/dist/ej2-pdfviewer-lib"
          created={created}
          style={{ height: '100%' }}
          annotationSettings={{ isLock: true }}
        >
          <Inject
            services={[
              Toolbar,
              Magnification,
              Navigation,
              ThumbnailView,
              Print,
              TextSelection,
              TextSearch,
            ]}
          />
        </PdfViewerComponent>
      </DocWrapper>

      <Button small onClick={handleClose} text="Voltar" icon={<GoBackIcon />} />
    </Modal>
  );
}
