import useDeviceDetect from 'hooks/use-device-detect';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  dataHardwareAtom,
  currentStepAtom,
  formDataAtom,
  handleSaveSignatureAtom,
  urlParamsAtom,
} from 'pages/DocSign/atoms';
import Signature from 'pages/DocSign/components/SignaturePage/components/Signature';
import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useToasts } from 'react-toast-notifications';
import { isSourceApp } from 'pages/DocSign/utils';
import { useDispatch } from 'react-redux';
import { ModalName } from 'store/modal/types';
import { closeModal, showModal } from 'store/modal/actions';
import { EnumStep } from 'pages/DocSign/types';

export default function SignaturePage() {
  const [formData, setFormData] = useAtom(formDataAtom);
  const urlParams = useAtomValue(urlParamsAtom);
  const handleSaveSignature = useSetAtom(handleSaveSignatureAtom);
  const setCurrentStep = useSetAtom(currentStepAtom);
  const dataHardware = useAtomValue(dataHardwareAtom);
  const dispatch = useDispatch();

  const { isMobile } = useDeviceDetect();

  const signCanvasRef = useRef<SignatureCanvas>(null);

  const { addToast } = useToasts();

  function handleRedirectSuccessPage() {
    setCurrentStep(EnumStep.SIGNED);
  }

  async function handleSave(isSignByName: boolean) {
    if (signCanvasRef.current?.isEmpty() && !isSignByName) {
      addToast('Assinatura obrigatória.', { appearance: 'error' });

      return;
    }

    handleImg(dataHardware.latitude, dataHardware.longitude, dataHardware.ip);

    if (isSourceApp(urlParams)) {
      setCurrentStep(EnumStep.OPEN_CAM);

      return;
    }

    try {
      dispatch(
        showModal(ModalName.LOADING, { text: 'Salvando assinatura...' }),
      );

      await handleSaveSignature();

      handleRedirectSuccessPage();

      dispatch(closeModal(ModalName.LOADING));

      addToast('Assinatura salva com sucesso.', { appearance: 'success' });
    } catch (error) {
      addToast('Error ao finalizar assinatura', {
        appearance: 'error',
      });
    }
  }

  function handleImg(latitude, longitude, dataIp) {
    const img64 = signCanvasRef.current?.toDataURL();

    const device = navigator.userAgent;

    const newFormData = {
      img64,
      isSignByName: img64 ? false : true,
      ip: dataIp,
      dispositivo: device,
      localizacao: `${latitude}, ${longitude}`,
      isRotatedImage: isMobile,
    };

    setFormData({ ...formData, dados: newFormData });
  }

  return <Signature onSave={handleSave} signCanvasRef={signCanvasRef} />;
}
