import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.linka.fisico.gerencial' */
  ),
);
const InspecaoSeguranca = lazy(() =>
  import(
    './layouts/seguranca/inspecaoSeguranca' /* webpackChunkName: 'linka.seguranca.inspecao-seguranca' */
  ),
);
const IndicadorDesempenho = lazy(() =>
  import(
    './layouts/seguranca/indicadorDesempenho' /* webpackChunkName: 'linka.seguranca.indicador-desempenho' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/linka/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/linka/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/linka/seguranca/inspecao-seguranca"
    component={InspecaoSeguranca}
    path="/relatorios/linka/seguranca/inspecao-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/seguranca/linka/indicador-desempenho"
    component={IndicadorDesempenho}
    path="/relatorios/seguranca/linka/indicador-desempenho"
  />,
];
