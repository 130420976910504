import React, { useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
} from 'recharts';
import { Wrapper, Container } from './styles';
import * as fn from 'pages/Reports/utils/dataFunctions';
import { isObjectEmpty } from 'utils/common';
import { getSqlResponse } from 'services/dataset';
import useQuery from 'hooks/use-query';

interface Data {
  idPeriodo: number | string;
  totalNotas: number;
  naoConformidade: number;
  conformidade: number;
}

const mainColorsDefault = {
  realizadoBar: '#6da449',
  realizadoLine: '#355222',
  tendenciaBar: '#A9D18E',
  tendenciaLine: '#508a05',
  contratualBar: '#8497B0',
  contratualLine: '#333F50',
  desafioBar: '#EBAF50',
  desafioLine: '#9B6611',
  barArray: ['#6da449', '#A9D18E', '#8497B0', '#EBAF50'],
  lineArray: ['#355222', '#355222', '#333F50', '#9B6611'],
  valGreen: '#008000',
  valRed: '#f00',
};

interface QueryProps {
  idDataset: number;
  params: string;
  height?: number;
  width?: number;
}

const percentFn = fn.formatNumber({ type: 'percent', decimalPlaces: 2 });

export default function BarDrillDown() {
  const [staticData, setStaticData] = useState<Data[]>([]);
  const [legend, setLegend] = useState('');
  const mainColors = mainColorsDefault;

  const dataUrl = useQuery<QueryProps>();

  useEffect(() => {
    initData();
  }, [dataUrl]);

  async function initData() {
    if (isObjectEmpty(dataUrl)) return;

    const { idDataset, params } = dataUrl;

    const normalizedParams = JSON.parse(decodeURIComponent(params));

    const staticDataAux: Data[] = await getSqlResponse(
      idDataset,
      normalizedParams,
    );
    if (!staticDataAux.length) return;

    setStaticData(staticDataAux);
  }

  const { width, height } = dataUrl;

  return (
    <Wrapper className="curvas-wrapper">
      <div className="curvas-title">DASHBOARD</div>

      <Container width={width} height={height}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={staticData}
            margin={{
              top: 50,
            }}
          >
            <CartesianGrid />
            <XAxis
              type="category"
              dataKey={'idPeriodo'}
              angle={-35}
              textAnchor="end"
              tickLine={false}
              axisLine={false}
              interval={0}
              height={70}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              tickCount={7}
              tickLine={false}
              axisLine={false}
              tickFormatter={percentFn}
              scale="auto"
            />

            <Legend
              className="curvas-legend"
              verticalAlign="top"
              align="right"
            />
            <Tooltip />
            <Bar
              yAxisId="left"
              stackId="realTend"
              overflow="visible"
              cursor="pointer"
              dataKey={'totalNotas'}
              name={legend}
              fill={mainColors.realizadoBar}
              stroke="none"
              formatter={percentFn}
              barSize={150}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Container>
    </Wrapper>
  );
}
