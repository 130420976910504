export function toPhone(value: string) {
  let formated = value.replace(/\D/g, '');
  formated = formated.replace(/^(\d{2})(\d)/g, '($1) $2');
  formated = formated.replace(/(\d)(\d{4})$/, '$1-$2');
  return formated;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
