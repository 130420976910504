import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { CategoryWithFiles, File } from '../../../../store/types';
import { getFileImg } from '../../../../utils/file';
import {
  Container,
  CategoryName,
  ItemWrapper,
  CategoryWrapper,
  IconRemove,
  RightIconWrapper,
  LeftIconWrapper,
  IconDownload,
  CheckBox,
  ItemButton,
} from './styles';
import Spinner from '../../../../components/Spinner';
import showAlert from '../../../../components/Alert';

interface Props {
  allowSelectFiles: boolean;
  selectedFileId: string | null;
  categories: CategoryWithFiles[];
  onSelectFileToSendEmail: (id: number) => void;
  onSelectFile: (file: File) => void;
  onUpload: (uuid: string) => Promise<void>;
  onDelete: (itemToDelete: File) => void;
  onDownloadFile: (file: File) => void;
}

export default function ListFiles(props: Props): ReactElement {
  const {
    selectedFileId,
    categories,
    onUpload,
    onSelectFile,
    onDelete,
    onDownloadFile,
    allowSelectFiles,
    onSelectFileToSendEmail,
  } = props;

  return (
    <Container>
      {categories.map(category => (
        <CategoryWrapper key={category.category}>
          <CategoryName>{category.category}</CategoryName>

          {category.files.map(file => (
            <Item
              key={file.uuid}
              file={file}
              selectedFileId={selectedFileId}
              allowSelectFiles={allowSelectFiles}
              onSelectFile={onSelectFile}
              onUpload={onUpload}
              onDelete={onDelete}
              onDownloadFile={onDownloadFile}
              onSelectFileToSendEmail={onSelectFileToSendEmail}
            />
          ))}
        </CategoryWrapper>
      ))}
    </Container>
  );
}

interface ItemProps {
  allowSelectFiles: boolean;
  file: File;
  selectedFileId: string | null;
  onSelectFileToSendEmail: (id: number) => void;
  onSelectFile: (file: File) => void;
  onUpload: (uuid: string) => Promise<void>;
  onDelete: (itemToDelete: File) => void;
  onDownloadFile: (file: File) => void;
}

function Item(props: ItemProps): ReactElement {
  const [isUploading, setIsUploading] = useState(false);
  const [rightSpinner, setRightSpinner] = useState(false);

  const {
    selectedFileId,
    file,
    allowSelectFiles,
    onSelectFile,
    onUpload,
    onDownloadFile,
    onSelectFileToSendEmail,
  } = props;

  useEffect(() => {
    if (file.uploading) {
      setIsUploading(true);

      onUpload(file.uuid).then(() => {
        setIsUploading(false);
      });
    }
  }, []);

  async function handleDelete(e: React.MouseEvent<SVGElement, MouseEvent>) {
    e.stopPropagation();

    const { onDelete } = props;

    const remove = async () => {
      setRightSpinner(true);

      await onDelete(file);

      setRightSpinner(false);
    };

    showAlert({
      title: 'Excluir',
      subtitle: 'Excluir agora',
      actions: [{ text: 'Cancelar' }, { text: 'Ok', onClick: remove }],
    });
  }

  async function handleDownloadFile(
    e: React.MouseEvent<SVGElement, MouseEvent>,
  ) {
    e.stopPropagation();

    setRightSpinner(true);

    await onDownloadFile(file);

    setRightSpinner(false);
  }

  function renderLeftIcon(): ReactElement {
    return (
      <LeftIconWrapper>
        {isUploading ? (
          <Spinner />
        ) : (
          <img alt="" src={getFileImg(file.tipoArquivo)} />
        )}
      </LeftIconWrapper>
    );
  }

  function renderRightIcon(): ReactElement {
    if (isUploading) {
      return <></>;
    }

    return (
      <RightIconWrapper>
        {rightSpinner ? (
          <Spinner />
        ) : (
          <>
            <IconDownload onClick={handleDownloadFile} />
            <IconRemove onClick={handleDelete} />
          </>
        )}
      </RightIconWrapper>
    );
  }

  function renderCheckbox(): ReactElement | null {
    if (allowSelectFiles) {
      return (
        <CheckBox
          id={file.uuid}
          onChange={() => onSelectFileToSendEmail(file.id)}
        />
      );
    }

    return null;
  }

  return (
    <ItemWrapper isSelected={file.uuid === selectedFileId}>
      {renderCheckbox()}
      <ItemButton onClick={() => onSelectFile(file)}>
        {renderLeftIcon()}
        <span>{file.nome}</span>
        {renderRightIcon()}
      </ItemButton>
    </ItemWrapper>
  );
}
