import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.samaar.fisico.gerencial' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/samaar/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/samaar/fisico/gerencial"
  />,
];
