import React, { ReactElement } from 'react';
import {
  Container,
  CheckIconStyled,
  XIconStyled,
  DashIconStyled,
  AlertIconStyled,
  HalfMoon,
  GreenCircle,
  EmptyContainer,
  StyledUpArrow,
  StyledDownArrow,
  PaperClip,
} from './styles';

interface ContainerProps {
  containerColor?: string;
  containerSize?: string;
  checkColor?: string;
  checkSize?: string;
}

export function RoundedEmptyIcon(): ReactElement {
  return <EmptyContainer />;
}

export function RoundedCheckIcon(props: ContainerProps): ReactElement {
  const { containerColor, containerSize, checkColor, checkSize } = props;

  const containerStyle = {};
  const iconStyle = {};

  if (containerSize) {
    containerStyle.width = containerSize;
    containerStyle.height = containerSize;
  }

  if (checkColor) iconStyle.color = checkColor;
  if (checkSize) iconStyle.fontSize = checkSize;

  return (
    <Container color={containerColor} style={containerStyle}>
      <CheckIconStyled style={iconStyle} />
    </Container>
  );
}

export function RoundedIcon(props: ContainerProps): ReactElement {
  const { containerColor, containerSize } = props;

  const containerStyle = {};

  if (containerSize) {
    containerStyle.width = containerSize;
    containerStyle.height = containerSize;
  }

  return <Container color={containerColor} style={containerStyle} />;
}

export function RoundedXIcon(props: ContainerProps): ReactElement {
  const { containerColor } = props;

  return (
    <Container className="rounded-x-icon" color={containerColor}>
      <XIconStyled />
    </Container>
  );
}

export function RoundedAlertIcon(props: ContainerProps): ReactElement {
  const { containerColor, checkColor } = props;

  return (
    <Container className="rounded-alert-icon" color={containerColor}>
      <AlertIconStyled color={checkColor} />
    </Container>
  );
}

export function GreenCircleAlertIcon(): ReactElement {
  return (
    <GreenCircle className="green-circle-alert-icon">
      <AlertIconStyled />
    </GreenCircle>
  );
}

export function DashIcon(): ReactElement {
  return <DashIconStyled className="dash-icon" />;
}

export function RoundedHalfMoonIcon(): ReactElement {
  return <HalfMoon />;
}

export function UpArrow({ reverseColor }): ReactElement {
  return <StyledUpArrow reverseColor={reverseColor} />;
}

export function DownArrow({ reverseColor }): ReactElement {
  return <StyledDownArrow reverseColor={reverseColor} />;
}

export function MidRect(): ReactElement {
  const style = {
    width: '22px',
    height: 'auto',
    fill: '#ffcf25',
  };

  return (
    <svg width="11" height="3" style={style}>
      <rect x="3" width="100%" height="100%" />
    </svg>
  );
}

export function Clip() {
  return <PaperClip />;
}
