import styled from 'styled-components';
import { RiArrowLeftCircleLine } from 'react-icons/ri';
import { VscFilePdf } from 'react-icons/vsc';

export const Container = styled.div`
  width: 260px;
  flex-shrink: 0;
  height: 100%;
  position: relative;
  position: absolute;
  z-index: 1000;
  left: 0px;
  transition: left 1s;
`;

export const StyledNav = styled.nav`
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0px;
  background-color: #404040;
  padding-top: 2rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledInput = styled.input`
  display: none;
`;

export const StyledLabel = styled.label`
  display: block;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #55c8c2;
  }

  span.arrow-down,
  span.arrow-up {
    position: absolute;
    right: 15px;
    top: 4px;
  }
  span.arrow-down {
    display: initial;
  }
  span.arrow-up {
    display: none;
  }
`;

export const StyledUl = styled.ul`
  list-style: none;
  background-color: #404040;
  padding: 0;
  height: 90%;
  overflow-y: auto;
`;

export const LestLevel = styled.li`
  background-color: #5f5f5f;
  font-size: 1.4rem;
  color: #fff;
  line-height: 32px;
  margin: 0;

  span {
    padding-left: 20px;
  }
`;

export const StyledLi = styled.li`
  line-height: 40px;
  position: relative;
  color: white;
  font-size: 16px;
  width: 100%;
  margin: auto;
  cursor: pointer;
  padding: 0;

  input:checked ~ ul {
    display: block;
  }

  input:checked ~ label .arrow-down {
    display: none;
  }
  input:checked ~ label .arrow-up {
    display: initial;
  }

  span {
    padding-left: 15px;
  }

  ul li span {
    padding-left: 20px;
    font-size: 80%;
  }

  ul {
    display: none;
    width: 100%;
    background-color: #5f5f5f;
    box-shadow: inset 0 0 5px 0 #000000;
    padding: 5px 0;
  }

  ul li {
    line-height: 30px;
    width: 100%;
  }
`;

export const BackToHomeContainer = styled.div`
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;

  span {
    font-size: 1.5rem;
    padding-left: 15px;
  }
`;

export const BackToHomeIcon = styled(RiArrowLeftCircleLine)`
  font-size: 2.3rem;
  margin-right: 20px;
`;

export const PdfPrint = styled.div`
  width: 100%;
  height: 30px;
  background-color: #6f6f6f;
  position: absolute;
  bottom: 18px;
  cursor: pointer;
`;

export const PdfPrintText = styled.span`
  color: white;
  font-size: 15px;
  line-height: 30px;
  padding-left: 15px;
`;

export const PdfPrintImage = styled(VscFilePdf)`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 20px;
  font-size: 2.3rem;
  color: white;
`;

export const StyledSpan = styled.span`
  font-size: 1.5rem;
`;
