import React, { ChangeEvent, useEffect, useRef } from 'react';
import { ModalName } from 'store/modal/types';
import { validation } from './validation';
import { getInitValuesFormik } from './utils';
import { closeModal, showModal } from 'store/modal/actions';
import { useDispatch } from 'react-redux';
import { ContainerInputs, Error, Footer, InputText } from './styles';
import Button from 'components/Button';
import Input from 'components/Input';
import { CreateOrUpdateSign, updateOrCreate } from 'services/signature';
import { useToasts } from 'react-toast-notifications';
import { generateUUID } from 'utils/common';
import Modal from 'components/Modal';
import { FormikProps, useFormik } from 'formik';
import useModal from 'hooks/use-modal';
import { useAtomValue } from 'jotai';
import { urlParamsAtom } from 'pages/DocSign/atoms';
import { GoBackIcon, IconCheck } from 'pages/DocSign/styles';
import { getModalStyle } from 'pages/DocSign/utils';
import FieldSelect from './components/FieldSelect';
import { ModalContainer, ModalHeader } from 'styles/modal';
import { FormDataType } from './type';
import { ModalTitle } from '../styles';
import { Subscriber } from 'pages/DocSign/types';
import { Label } from 'styles/form';

export interface CreateSubscriberRegistrationParams {
  name: string;
  onSave: () => Promise<Subscriber[]>;
}

export default function CreateSubscriberRegistration() {
  const { isOpen, params, windowSec } = useModal<
    CreateSubscriberRegistrationParams
  >(ModalName.CREATE_SUBSCRIBER_REGISTRATION);

  const urlParams = useAtomValue(urlParamsAtom);

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      if (!params.name) {
        initFormik();
      } else {
        formik.setErrors({});
        formik.setValues({ ...getInitValuesFormik(), name: params.name });
      }

      setTimeout(() => {
        nameInputRef.current?.focus();
      }, 100);
    }
  }, [isOpen]);

  const formik: FormikProps<FormDataType> = useFormik<FormDataType>({
    initialValues: getInitValuesFormik(),
    validationSchema: validation,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  function initFormik() {
    formik.setErrors({});
    formik.setValues(getInitValuesFormik());
  }

  function handleCloseModal() {
    dispatch(closeModal(ModalName.CREATE_SUBSCRIBER_REGISTRATION));
  }

  async function handleSubmit() {
    dispatch(showModal(ModalName.LOADING, { text: 'Salvando assinante...' }));

    try {
      const uuid = generateUUID();

      const newAsign: CreateOrUpdateSign = {
        uuid: uuid,
        nome: formik.values.name,
        email: formik.values.email,
        cargo: formik.values.position,
        alcada: formik.values.role?.value,
        telefone: formik.values.phone,
        cpf: formik.values.cpf,
        idContrato: urlParams!.idContrato,
        idEmpresa: urlParams!.idEmpresa,
        idReferencia: urlParams!.idReferencia,
        uuidArquivo: urlParams!.uuidArquivo,
        uuidReferencia: urlParams!.uuidReferencia,
        idUsuarioCriacao: urlParams!.idUsuarioCriacao,
        tipo: urlParams!.tipo,
      };

      await updateOrCreate(newAsign);

      addToast('Assinante salvo com sucesso.', { appearance: 'success' });

      const { onSave } = params;

      if (onSave) {
        onSave();
      }

      dispatch(closeModal(ModalName.SUBSCRIBER_REGISTRATION));
      dispatch(closeModal(ModalName.CREATE_SUBSCRIBER_REGISTRATION));
    } catch {
      addToast('Erro ao salvar assinante.', { appearance: 'error' });
    }

    dispatch(closeModal(ModalName.LOADING));
  }

  function validateField(name: string) {
    formik.validateField(name);
  }

  function handleChangeName(e: ChangeEvent<HTMLInputElement>) {
    formik.setFieldValue(e.target.name, e.target.value);
  }

  return (
    <Modal
      isOpen={isOpen}
      windowSec={windowSec}
      openLikePage
      style={getModalStyle()}
    >
      <ModalHeader>
        <ModalTitle>Cadastrar Novo Assinante</ModalTitle>
      </ModalHeader>

      <ModalContainer>
        <form onSubmit={formik.handleSubmit}>
          <ContainerInputs>
            <Label>Nome</Label>
            <InputText
              name="name"
              onChange={handleChangeName}
              onBlur={() => validateField('name')}
              value={formik.values.name || ''}
              ref={nameInputRef}
            />

            {formik.errors?.name && <Error>{formik.errors?.name}</Error>}

            <Input
              label="E-mail"
              type="email"
              id="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email || ''}
            />
            {formik.errors?.email && <Error>{formik.errors?.email}</Error>}

            <Input
              label="Cargo"
              name="position"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.position || ''}
            />
            {formik.errors?.position && (
              <Error>{formik.errors?.position}</Error>
            )}

            <FieldSelect
              label="Alçada"
              name="role"
              formik={formik}
              validateField={validateField}
            />
          </ContainerInputs>

          <Footer>
            <Button
              text="Voltar"
              onClick={handleCloseModal}
              icon={<GoBackIcon />}
              small
            />
            <Button
              primary
              type="submit"
              text="Salvar"
              icon={<IconCheck />}
              small
            />
          </Footer>
        </form>
      </ModalContainer>
    </Modal>
  );
}
