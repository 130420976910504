import {
  UserState,
  UserActionTypes,
  SET_USER_INFO,
  SET_USER_PROFILE_MODULE,
  SET_USER_ACTIONS,
} from './types';

const initialState: UserState = {
  idUsuario: null,
  idEmpresa: null,
  empresa: null,
  nome: null,
  sobrenome: null,
  apelido: null,
  email: null,
  status: null,
  idPerfilModulo: null,
  idPerfil: null,
  idUserModelo: null,
  idAcoes: null,
  flagRecarregarAcessos: null,
  flagRecarregaEmpreendimento: null,
};

export default (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case SET_USER_PROFILE_MODULE:
      return {
        ...state,
        idPerfilModulo: action.payload,
      };
    case SET_USER_ACTIONS:
      return {
        ...state,
        idAcoes: action.payload,
      };
    default:
      return state;
  }
};
