import React from 'react';
import Button from 'components/Button';
import { useAtomValue } from 'jotai';
import { urlParamsAtom } from 'pages/DocSign/atoms';
import { useDispatch } from 'react-redux';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { getSpacesFolder, normalizeFileUrl } from 'utils/file';
import { DocViewerModalParams } from '../Modal/DocViewerModal';
import { PDFIcon } from './styles';

export default function ShowDocButton() {
  const dispatch = useDispatch();
  const urlParams = useAtomValue(urlParamsAtom);

  function handleShowModal() {
    dispatch(
      showModal<DocViewerModalParams>(ModalName.SIGN_DOC_VIEWER, {
        fileUrl: normalizeFileUrl(
          `${getSpacesFolder()}/datafiles/reports/signed/${
            urlParams!.uuidArquivo
          }.pdf`,
        ),
      }),
    );
  }

  return (
    <Button
      small
      primary
      outline
      onClick={handleShowModal}
      text="Ver Documento"
      icon={<PDFIcon />}
    />
  );
}
