import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const Obras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'relatorios.r2x.financeiro.obras' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/r2x/financeiro/obras"
    component={Obras}
    path="/relatorios/r2x/financeiro/obras"
  />,
];
