import React, { ReactElement, useState, useEffect } from 'react';
import Spinner from '../../components/Spinner';
import { File, CategoryWithFiles } from '../../store/types';
import { getFileImg } from '../../utils/file';
import { download, getByUser } from '../../services/file';
import {
  CategoryName,
  CategoryWrapper,
  Container,
  FileWrapper,
  IconDownload,
  LeftIconWrapper,
  RightIconWrapper,
  LinkFile,
} from './styles';
import Badge from '../../components/Badge';

export default function Files(): ReactElement {
  const [files, setFiles] = useState<CategoryWithFiles[]>([]);

  useEffect(() => {
    getFiles();
  }, []);

  async function getFiles() {
    const res = await getByUser();

    const categoriesName: { [key: string]: boolean } = {};

    res.forEach(({ categoria }) => {
      categoriesName[
        categoria.categoria || 'Arquivos não Classificados'
      ] = true;
    });

    const normalizedFiles = Object.keys(categoriesName).map<CategoryWithFiles>(
      category => ({
        category,
        files: res.filter(file => file.categoria.categoria === category),
      }),
    );

    setFiles(normalizedFiles);
  }

  return (
    <Container>
      {files.map(category => (
        <CategoryWrapper key={category.category}>
          <CategoryName>{category.category}</CategoryName>

          {category.files.map(file => (
            <Item key={file.uuid} file={file} />
          ))}
        </CategoryWrapper>
      ))}
    </Container>
  );
}

interface ItemProps {
  file: File;
}

function Item(props: ItemProps) {
  const { file } = props;
  const [rightSpinner, setRightSpinner] = useState(false);
  const [donwloadedFile, setDownloadedFile] = useState(file.flagDownload);

  async function handleDownloadFile(
    e: React.MouseEvent<SVGElement, MouseEvent>,
  ) {
    e.stopPropagation();

    setRightSpinner(true);

    await download(file);

    setRightSpinner(false);

    // setDownloadedFile(false);
  }

  function renderLeftIcon(): ReactElement {
    return (
      <LeftIconWrapper>
        <img alt="" src={getFileImg(file.tipoArquivo)} />
      </LeftIconWrapper>
    );
  }

  function renderRightIcon(): ReactElement {
    return (
      <RightIconWrapper>
        {donwloadedFile ? <Badge text="novo" /> : null}
        {rightSpinner ? (
          <Spinner />
        ) : (
          <IconDownload onClick={handleDownloadFile} />
        )}
      </RightIconWrapper>
    );
  }

  return (
    <FileWrapper onClick={handleDownloadFile}>
      {renderLeftIcon()}

      <LinkFile>{file.nome}</LinkFile>

      {renderRightIcon()}
    </FileWrapper>
  );
}
