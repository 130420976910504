import styled from 'styled-components';

export const Label = styled.label`
  font-size: 1.4rem;
  display: block;
  margin: 5px 0;
  position: relative;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 50px;
  justify-content: space-between;
`;

export const LabelInfo = styled.p<{ fontSize?: string }>`
  font-size: ${({ fontSize }) => fontSize || '1.5rem'};
  font-weight: bold;
  margin-bottom: -2px;
  color: #333;
`;

export const ValueInfo = styled.p<{ fontSize?: string }>`
  font-size: ${({ fontSize }) => fontSize || '1.5rem'};
`;

export const FormGroup = styled.div`
  display: block;
`;
