import styled from 'styled-components';
import { MdEdit } from 'react-icons/md';

export const EditIcon = styled(MdEdit)`
  position: absolute;
  right: -20px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.grey30};
  display: none;
`;

export const TableWrapper = styled.div`
  position: relative;
`;

export const TreeviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  background-color: #ededed;
  padding: 0.4rem 1rem;
`;

export const TreeviewText = styled.span`
  font-size: 1.4rem;
`;

export const TreeviewRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.4rem 1.5rem;

  &:hover {
    cursor: pointer;

    & ${EditIcon} {
      display: block;
    }
  }
`;
