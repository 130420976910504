import { FormGroup } from 'components/Input/styles';
import React, { ReactElement } from 'react';
import { TextareaAutosizeProps } from 'react-textarea-autosize';
import { Label } from 'styles/form';
import { Danger } from 'styles/text';
import { StyledTextArea } from './styles';

interface Props extends TextareaAutosizeProps {
  customMaxHeight?: string;
  error?: boolean;
  small?: boolean;
  label?: string;
  errorMessage?: string;
}

export default function TextArea(props: Props): ReactElement {
  const { label, id } = props;
  const { errorMessage, ...rest } = props;

  if (label) {
    return (
      <FormGroup>
        <Label htmlFor={id}>{label}</Label>
        <StyledTextArea {...rest} />
        {errorMessage ? <Danger>{errorMessage}</Danger> : null}
      </FormGroup>
    );
  }

  return (
    <FormGroup>
      <StyledTextArea {...rest} />
      {errorMessage ? <Danger>{errorMessage}</Danger> : null}
    </FormGroup>
  );
}
