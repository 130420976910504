import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 30rem 1fr;
  height: 100%;
`;

export const Sidebar = styled.div`
  padding: 2rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  margin-bottom: 0.5rem;
`;

export const ViewerContainer = styled.div`
  .react-viewer-canvas {
    background-color: #ededed;
  }
  .react-viewer-navbar {
    background-color: #ededed;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  margin-top: auto;
  gap: 1rem;
  justify-content: flex-end;
`;
