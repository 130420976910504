import { FaUserAlt } from 'react-icons/fa';
import { GiPoisonCloud } from 'react-icons/gi';
import { IoIosCloudDone } from 'react-icons/io';
import { MdOpenInNew } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import styled from 'styled-components';
import { CalendarIcon as CalendarIconStyled } from 'styles/icons';

export const Card = styled.article`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  flex: 0 0 30rem;
  margin-left: 1rem;
  position: relative;

  &.dragging-helper-class {
    box-shadow: 0 1px 10px 5px rgba(0, 0, 0, 0.3);
    z-index: 99999;
  }

  &:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const SectionComment = styled.section`
  padding: 1rem;
`;

export const Comment = styled.textarea`
  width: 100%;
  height: 14rem;
  border: 1px solid #ededed;
  padding: 1rem 1rem 0 1rem;
  resize: none;
  border-radius: 3px;

  &::placeholder {
    color: #999;
  }

  &:focus {
    outline: 0 none;
  }
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: 20rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const Image = styled.img`
  max-width: 100%;
  object-fit: cover;
`;

export const Label = styled.label`
  font-size: 1.2rem;
  font-weight: 400;
`;

export const Actions = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0;
  display: flex;

  label {
    left: 0;
  }
  label:before {
    top: -14px;
    left: -12px;
  }
`;

export const IconRemove = styled(RiDeleteBin6Line)<{ defaultColor: string }>`
  margin-right: 0.4rem;
  margin-top: -1px;
`;

export const ActionStyled = styled.button<{ defaultColor: string }>`
  font-size: 1.2rem;
  flex: 0 0 10rem;
  text-transform: uppercase;
  color: ${({ defaultColor }) => defaultColor};
  height: 2.2rem;
  cursor: pointer;
  border-radius: 2px;
  background-color: #fff;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:active {
    border-color: transparent;
  }

  &:focus {
    outline: 0;
  }

  &:not(:last-child) {
    border-right: 1px solid #ededed;
  }

  &:hover {
    background-color: ${({ defaultColor }) => defaultColor};
    color: #fff;
  }
`;

export const OpenImageWrapper = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  left: 0;
  padding: 0 0.5rem;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transform: translateX(-1px);
`;

export const InputWrapper = styled.div`
  margin-left: 2.4rem;
`;

export const OpenImageIcon = styled(MdOpenInNew)`
  font-size: 1.5rem;
  margin-top: 2px;
  color: #fff;
`;

export const SpinnerWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  height: 2.2rem;
`;

export const UploadIconWrapper = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  right: 0;
  bottom: 0;
  padding: 0 0.5rem;
  border-top-left-radius: 4px;
  transform: translateX(-1px);
`;

export const UploadedIcon = styled(IoIosCloudDone)`
  font-size: 1.5rem;
  color: #fff;
`;

export const NotUploadIcon = styled(GiPoisonCloud)`
  font-size: 1.5rem;
  color: #fff;
`;
export const DisableCheck = styled.div`
  font-size: 1.4rem;
  position: relative;
  display: inline-block;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    left: -13px;
    top: -13px;
    width: 1.9rem;
    height: 1.9rem;
    background: transparent;
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &::after {
    content: '';
    position: absolute;
    display: ${props => (props.isChecked ? 'inline-block' : 'none')};
    left: -9px;
    top: -9px;
    width: 1.3rem;
    height: 0.6rem;

    border-left: 3px solid ${({ theme }) => theme.colors.primary};
    border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
    transform: rotate(-45deg);
  }

  label {
    position: absolute;
    bottom: -7px;
    left: 10px;
  }
`;

export const InforWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InforHeader = styled.div`
  padding: 0.5rem;
`;

export const UserIcon = styled(FaUserAlt)`
  color: #333;
  font-size: 1.5rem;
  margin-right: 0.8rem;
`;

export const CalendarIcon = styled(CalendarIconStyled)`
  margin-right: 0.5rem;
`;
