import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const ChecklistInspecao = lazy(() =>
  import(
    './layouts/gerencial/checklistInspecao' /* webpackChunkName: 'relatorios.gafisa.gerencial.checklist-inspecao' */
  ),
);
const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial/index' /* webpackChunkName: 'relatorios.gafisa.fisico.gerencial' */
  ),
);
const AnaliseDePrazos = lazy(() =>
  import(
    './layouts/fisico/portfolio' /* webpackChunkName: 'relatorios.gafisa.fisico.portfolio' */
  ),
);
const GeralDeObras = lazy(() =>
  import(
    './layouts/fisico/geralDeObras' /* webpackChunkName: 'relatorios.gafisa.fisico.relatorio-geral-de-obras' */
  ),
);
const LinhaDeBalanco = lazy(() =>
  import(
    './layouts/fisico/linhaDeBalanco' /* webpackChunkName: 'relatorios.gafisa.fisico.linha-de-balanco' */
  ),
);
const ChecklistDeSeguranca = lazy(() =>
  import(
    './layouts/seguranca/checklistDeSeguranca' /* webpackChunkName: 'relatorios.gafisa.seguranca.checklist-de-seguranca' */
  ),
);
const RelatorioGeralDeSeguranca = lazy(() =>
  import(
    './layouts/seguranca/relatorioGeralDeSeguranca' /* webpackChunkName: 'relatorios.gafisa.seguranca.relatorio-geral-de-seguranca' */
  ),
);
const FisicoPortfolioSemanal = lazy(() =>
  import(
    './layouts/fisico/portfolioSemanal' /* webpackChunkName: 'relatorios.gafisa.fisico.portfolio-semanal' */
  ),
);
const AcompanhamentoSemanal = lazy(() =>
  import(
    './layouts/fisico/acompanhamentoSemanal' /* webpackChunkName: 'relatorios.gafisa.fisico.acompanhamento-semanal' */
  ),
);
const IDP = lazy(() =>
  import(
    './layouts/fisico/idp' /* webpackChunkName: 'relatorios.gafisa.fisico.idp' */
  ),
);

const MeioAmbienteDashboardObra = lazy(() =>
  import(
    './layouts/meio-ambiente/dashboardObra' /*webpackChunkName: 'relatorios.gafisa.meio-ambiente.dashboard-obra' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/gafisa/gerencial/checklist-inspecao"
    component={ChecklistInspecao}
    path="/relatorios/gafisa/gerencial/checklist-inspecao"
  />,
  <PrivateRoute
    key="/relatorios/gafisa/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/gafisa/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/gafisa/fisico/portfolio"
    component={AnaliseDePrazos}
    path="/relatorios/gafisa/fisico/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/gafisa/fisico/relatorio-geral-de-obras"
    component={GeralDeObras}
    path="/relatorios/gafisa/fisico/relatorio-geral-de-obras"
  />,
  <PrivateRoute
    key="/relatorios/gafisa/fisico/linha-de-balanco"
    component={LinhaDeBalanco}
    path="/relatorios/gafisa/fisico/linha-de-balanco"
  />,
  <PrivateRoute
    key="/relatorios/gafisa/seguranca/checklist-de-seguranca"
    component={ChecklistDeSeguranca}
    path="/relatorios/gafisa/seguranca/checklist-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/gafisa/seguranca/relatorio-geral-de-seguranca"
    component={RelatorioGeralDeSeguranca}
    path="/relatorios/gafisa/seguranca/relatorio-geral-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/gafisa/fisico/portfolio-semanal"
    component={FisicoPortfolioSemanal}
    path="/relatorios/gafisa/fisico/portfolio-semanal"
  />,
  <PrivateRoute
    key="/relatorios/gafisa/fisico/acompanhamento-semanal"
    component={AcompanhamentoSemanal}
    path="/relatorios/gafisa/fisico/acompanhamento-semanal"
  />,
  <PrivateRoute
    key="/relatorios/gafisa/fisico/idp"
    component={IDP}
    path="/relatorios/gafisa/fisico/idp"
  />,
  <PrivateRoute
    key="/relatorios/gafisa/meio-ambiente/dashboard-obra"
    component={MeioAmbienteDashboardObra}
    path="/relatorios/gafisa/meio-ambiente/dashboard-obra"
  />,
];
