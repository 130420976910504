import Button from 'components/Button';
import Input from 'components/Input';
import useDeviceDetect from 'hooks/use-device-detect';
import { useAtom, useSetAtom } from 'jotai';
import {
  currentStepAtom,
  dataHardwareAtom,
  docNameAtom,
  formDataAtom,
  getCurrenttGeolocationAtom,
  urlParamsAtom,
} from 'pages/DocSign/atoms';
import { CancelIcon, GoBackIcon, IconCheck } from 'pages/DocSign/styles';
import { EnumStatus, EnumStep } from 'pages/DocSign/types';
import { handleConfirmSignCancel, isSourceApp } from 'pages/DocSign/utils';
import React, { ChangeEvent, useEffect, useState } from 'react';
import InputMaskComponent from 'react-input-mask';
import { useToasts } from 'react-toast-notifications';
import { getIp } from 'services/apify';
import { getSingleResponseAdonis } from 'services/dataset';
import { Label } from 'styles/form';
import { Danger } from 'styles/text';
import { Content, Footer, MobileContainer, WebContainer } from './styles';
import { ErrorType, UserType } from './type';
import { isInvalidCPF, isInvalidPhone } from './utils';

export default function SubscriberDoc() {
  const [errors, setErrors] = useState<ErrorType>({} as ErrorType);

  const [formData, setFormData] = useAtom(formDataAtom);
  const [urlParams, setUrlParams] = useAtom(urlParamsAtom);
  const [dataHardware, setDataHardware] = useAtom(dataHardwareAtom);
  const getCurrenttGeolocation = useSetAtom(getCurrenttGeolocationAtom);
  const setCurrentStep = useSetAtom(currentStepAtom);
  const setDocName = useSetAtom(docNameAtom);

  const { isMobile } = useDeviceDetect();

  const { addToast } = useToasts();

  useEffect(() => {
    getData();
    getDataHardware();
  }, [urlParams?.uuid]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  }

  async function getData() {
    if (isSourceApp(urlParams)) return;

    const dataUser = await getSingleResponseAdonis<UserType>(4586, {
      uuid: urlParams?.uuid,
    });

    if (!dataUser) return;

    if ([EnumStatus.SIGNED, EnumStatus.SIGNED_PDF].includes(dataUser.status)) {
      handleRedirectSuccessPage();

      return;
    }

    setDocName(dataUser.nomeDocumento);

    setFormData(prev => ({ ...prev, ...dataUser }));

    setUrlParams({
      ...urlParams!,
      idContrato: dataUser.idContrato,
      idEmpresa: dataUser.idEmpresa,
      tipo: dataUser.tipo,
      uuidArquivo: dataUser.uuidArquivo,
      uuidReferencia: dataUser.uuidReferencia,
    });
  }

  function handleRedirectSuccessPage() {
    setCurrentStep(EnumStep.SIGNED);
  }

  function validateFields() {
    const newErrors = {} as ErrorType;

    const { cpf, telefone } = formData;

    const cleanCPF = cpf?.replace(/\D/g, '') || '';
    const cleanPhone = telefone?.replace(/\D/g, '') || '';

    if (isInvalidCPF(cleanCPF)) {
      newErrors.cpf = 'CPF inválido';
    }

    if (isInvalidPhone(cleanPhone)) {
      newErrors.telefone = 'Telefone inválido';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }

  async function handleClick() {
    if (!dataHardware?.longitude && !dataHardware?.latitude) {
      await getCurrenttGeolocation();

      return;
    }

    if (!validateFields()) {
      addToast('Necessário preencher todos os campos.', {
        appearance: 'error',
      });

      return;
    }

    setCurrentStep(EnumStep.PDF_VIEWER);
  }

  function handleCancel() {
    setCurrentStep(EnumStep.SUBSCRIBER_LIST);

    addToast('Assinatura cancelada com sucesso.', {
      appearance: 'success',
    });
  }

  function handleRedirectCancelPage() {
    setCurrentStep(EnumStep.REGISTER_SUBSCRIBERS_CANCEL);
  }

  async function getDataHardware() {
    try {
      const res = await getIp();

      setDataHardware({ ...dataHardware, ip: res.ip });
    } catch (error) {
      addToast('Erro ao solicitar ip.', {
        appearance: 'error',
      });
    }

    getCurrenttGeolocation();
  }

  function renderButtons() {
    if (isSourceApp(urlParams)) {
      return (
        <Footer>
          <Button
            text="Voltar"
            onClick={() => setCurrentStep(EnumStep.SUBSCRIBER_LIST)}
            small
            icon={<GoBackIcon />}
          />

          <Button
            text="Cancelar"
            onClick={() => handleConfirmSignCancel(handleCancel)}
            small
            icon={<CancelIcon />}
          />

          <Button
            primary
            text="Avançar"
            onClick={handleClick}
            small
            icon={<IconCheck />}
          />
        </Footer>
      );
    }

    return (
      <Footer>
        <Button
          text="Cancelar"
          onClick={() => handleConfirmSignCancel(handleRedirectCancelPage)}
          small
          icon={<CancelIcon />}
        />

        <Button
          primary
          text="Avançar"
          onClick={handleClick}
          small
          icon={<IconCheck />}
        />
      </Footer>
    );
  }

  function renderContent() {
    return (
      <>
        <Content>
          <Label>Nome</Label>
          <Input disabled id="nome" name="nome" value={formData?.nome} />

          <Label>Telefone</Label>
          <InputMaskComponent
            mask="(99) 99999-9999"
            id="telefone"
            name="telefone"
            error={errors?.telefone}
            onChange={handleChange}
            value={formData?.telefone}
          >
            {inputProps => <Input {...inputProps} />}
          </InputMaskComponent>

          {errors?.telefone && <Danger>{errors?.telefone}</Danger>}

          <Label>Documento (CPF)</Label>
          <InputMaskComponent
            mask="999.999.999-99"
            id="cpf"
            name="cpf"
            error={errors?.cpf}
            onChange={handleChange}
            value={formData?.cpf}
          >
            {inputProps => <Input {...inputProps} />}
          </InputMaskComponent>

          {errors?.cpf && <Danger>{errors?.cpf}</Danger>}
        </Content>

        {renderButtons()}
      </>
    );
  }

  return (
    <>
      {isMobile ? (
        <MobileContainer>{renderContent()}</MobileContainer>
      ) : (
        <WebContainer>{renderContent()}</WebContainer>
      )}
    </>
  );
}
