import React, { useState, useEffect } from 'react';
import { Title } from 'components/HeaderAction/styles';
import { DynamicFormModalParams } from '../../types';
import { getSingleResponse } from 'services/dataset';
import { useDynamicForm } from '../../context';

interface Props {
  params: DynamicFormModalParams;
}

export default function HeaderTitle(props: Props) {
  const [formTitle, setFormTitle] = useState('');
  const { globalState, contractId: idContrato } = useDynamicForm();

  useEffect(() => {
    if (idContrato && globalState) {
      getTitle();
    }
  }, [idContrato, globalState]);

  async function getTitle() {
    const { params } = props;
    const { titleDatasetId, dynamicTitle } = params;

    if (dynamicTitle) {
      setFormTitle(dynamicTitle);

      return;
    }

    if (!idContrato || !titleDatasetId) {
      return;
    }

    const titleDatasetParams = {
      ...params,
      idContrato,
    };

    delete titleDatasetParams.parametersToSave;
    delete titleDatasetParams.getRows;

    const { title } = await getSingleResponse(
      titleDatasetId!,
      titleDatasetParams,
    );

    setFormTitle(title);
  }

  return <Title> {formTitle} </Title>;
}
