import { FormUser, SupplierNormalizeTypes } from './types';
import { store as storeUserArea } from 'services/user_area';
import { store as storeUserSupplier } from 'services/user_supplier';
import { v1 as uuid } from 'uuid';

export function normalizeSupllier(props: SupplierNormalizeTypes) {
  const { fornecedores, suppliersSelected } = props;
  const suppliersToSave = [...fornecedores];

  suppliersSelected.forEach(supplier => {
    if (!fornecedores.some(item => item.idFornecedor === supplier.value)) {
      suppliersToSave.push({
        idFornecedor: supplier.value,
        flagStatus: 0,
      });
    }
  });

  return suppliersToSave;
}

export function saveAreas(
  values: FormUser,
  uuidUsuario: string,
  idEmpresa: number,
) {
  const areasToSave = values?.area || [];

  values?.previousAreas?.forEach(previousArea => {
    if (
      !values.area!.some(
        currentArea => currentArea.value === previousArea.value,
      )
    ) {
      areasToSave.push({ ...previousArea, flagStatus: 0 });
    }
  });

  if (!areasToSave.length) return;

  const paramsTosave = areasToSave.map(item => ({
    uuid: item.uuid || uuid(),
    uuidCatalogoListas: item.uuidCatalogo,
    uuidUsuario,
    idEmpresa,
    flagStatus: item.flagStatus === 0 ? 0 : 1,
  }));

  return storeUserArea(paramsTosave);
}

export function saveSupplier(
  values: FormUser,
  idUsuario: number,
  idEmpresa: number,
) {
  const supplierToSave = values?.fornecedores || [];

  values?.previousFornecedores?.forEach(previousFornecedore => {
    if (
      !values.fornecedores!.some(
        currentArea => currentArea.value === previousFornecedore.value,
      )
    ) {
      supplierToSave.push({ ...previousFornecedore, flagStatus: 0 });
    }
  });

  if (!supplierToSave.length) return;

  const normalizedParams = supplierToSave.map(
    ({ flagStatus, idFornecedor, value }) => {
      const previousSuplier = values.previousFornecedores.find(
        sup => sup.value === idFornecedor || sup.value === value,
      );

      return {
        id: previousSuplier?.idUsuarioFornecedor || 0,
        flagStatus,
        idCadastroFornecedor: previousSuplier?.value || idFornecedor,
        idEmpresa,
        idUsuario,
      };
    },
  );

  return storeUserSupplier(normalizedParams);
}
