import React, { Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { modalsLazy } from './utils';
import LoadingSuspense from 'components/LoadingSuspense';

export default function GlobalModalImporter() {
  const listOfOpenModals = useSelector((state: RootState) => state.modal) as {
    modal: string;
  }[];

  function renderModal() {
    const listOfModalNames = listOfOpenModals.map(item => item.modal);
    const listWithoutDuplicateName = [...new Set(listOfModalNames)];

    return listWithoutDuplicateName.map(name => {
      const CurrentComponentModal = modalsLazy[name];

      return CurrentComponentModal ? (
        <CurrentComponentModal key={name} />
      ) : null;
    });
  }

  const renderedModals = useMemo(renderModal, [listOfOpenModals]);

  return (
    <Suspense fallback={<LoadingSuspense text="Carregando..." />}>
      {renderedModals}
    </Suspense>
  );
}
