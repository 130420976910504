import styled from 'styled-components';
import ButtonComponent from 'components/Button';

export const Button = styled(ButtonComponent)`
  display: flex;
  align-items: center;
`;

interface Props {
  readonly small?: boolean;
  readonly tiny?: boolean;
}

function getWidth(props: Props) {
  if (props.tiny) {
    return '2rem';
  }

  if (props.small) {
    return '2.4rem';
  }

  return '3.2rem';
}

export const StyleXlsImg = styled.img<Props>`
  width: ${props => getWidth(props)};
  margin-right: 0.5rem;
`;
