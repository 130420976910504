import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import {
  getImpersonateToken,
  removeImpersonateToken,
  saveImpersonateToken,
  saveToken,
} from 'services/auth';
import { impersonate, removeUserCompanyFromStorage } from 'services/user';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import {
  CloseBtn,
  Btn,
  CloseLabel,
  Container,
  Input,
  Label,
  ShowBtn,
} from './styles';

export default function Impersonate() {
  const [userID, setUserID] = useState('');
  const [display, setDisplay] = useState('none');

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  async function handleClick() {
    if (!userID) {
      addToast('Informe um id usuário');

      return;
    }

    dispatch(showModal(ModalName.LOADING, { text: 'Aguarde' }));

    try {
      const { token, impersonateToken } = await impersonate(
        +userID,
        getImpersonateToken() || null,
      );

      saveToken(token);
      saveImpersonateToken(impersonateToken);
      removeUserCompanyFromStorage();
      window.location.reload();
    } catch (error) {
      removeImpersonateToken();
      addToast(error[0]);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    dispatch(closeModal(ModalName.LOADING));
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setUserID(e.target.value);
  }

  return (
    <>
      <Container style={{ display }} id="impersonate-wrapper">
        <Label>id_usuario:</Label>
        <Input onChange={handleChange} value={userID} type="text" />

        <Btn onClick={handleClick}>IMPERSONATE</Btn>

        <CloseLabel onClick={() => setDisplay('none')}>
          fechar
          <CloseBtn />
        </CloseLabel>
      </Container>
      <ShowBtn onClick={() => setDisplay('flex')}>Impersonate</ShowBtn>
    </>
  );
}
