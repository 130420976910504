import styled from 'styled-components';
import TextAreaComponent from 'components/TextArea';

export const TextArea = styled(TextAreaComponent)`
  margin: 1rem 0;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;
