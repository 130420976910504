import showAlert from 'components/Alert';
import useUser from 'hooks/use-user';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { download, get } from 'services/file';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { EnumActions, EnumProfile, EnumRoutes, File } from 'store/types';
import { getFileImg } from 'utils/file';
import { useDynamicForm } from '../../context';
import { RowTitle, TitleWrapper } from '../../styles';
import { Field, UploadModalParams } from '../../types';
import { checkUserEditPermission } from '../../utils';
import { AddIcon, Container, FileWrapper, IconDownload } from './styles';
import WithPermission from 'components/WithPermission';

interface Props {
  field: Field;
  isDisabled: boolean;
}

export default function Files(props: Props) {
  const [files, setFiles] = useState<File[]>([]);
  const {
    globalState,
    onSaveForm,
    contractId: idContrato,
    approvalData,
    fieldsAllowProps,
  } = useDynamicForm();

  const user = useUser();

  const dispatch = useDispatch();
  const { field, isDisabled } = props;

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    if (globalState) {
      fetchFiles();
    }
  }, [globalState]);

  function fetchFiles() {
    const { defaultFormData } = globalState!;

    if (defaultFormData?.idFormulario) {
      get(defaultFormData.idFormulario, 'FM').then(setFiles);
    }
  }

  function openFormModal() {
    const { defaultFormData } = globalState!;

    /* The form need to be saved before add images */
    if (!defaultFormData?.idFormulario) {
      showAlert({
        title: 'Atenção!',
        subtitle:
          'Para realizar o upload de arquivos é necessário salvar antes. Deseja salvar os dados editados?',
        actions: [
          {
            text: 'Não',
          },
          {
            text: 'Sim',
            onClick: onSaveForm,
          },
        ],
      });

      return;
    }

    dispatch(
      showModal<UploadModalParams>(ModalName.MODAL_UPLOAD, {
        idContrato,
        id: defaultFormData.idFormulario,
        tipo: 'FM',
        typeOfPage: EnumRoutes.UPLOAD_FILE,
        idUsuario: user.idUsuario!,
        onReloadPageForm: fetchFiles,
      }),
    );
  }

  return (
    <Container>
      <TitleWrapper>
        {field.label && (
          <RowTitle dangerouslySetInnerHTML={{ __html: field.label }} />
        )}

        {!isDisableChecked && (
          <WithPermission
            action={EnumActions.SALVAR}
            rules={[
              EnumProfile.ADMINISTRADOR,
              EnumProfile.PRODUCAO,
              EnumProfile.EXDCUTIVO,
              EnumProfile.DEMO,
              EnumProfile.OPERACIONAL,
              EnumProfile.APROVADOR,
              EnumProfile.AUDITOR,
              EnumProfile.GESTOR,
            ]}
          >
            <AddIcon style={{ top: '0.5rem' }} onClick={openFormModal} />
          </WithPermission>
        )}
      </TitleWrapper>

      <FileWrapper>
        {files.map(file => (
          <div key={file.id} onClick={() => download(file)}>
            <div>
              <img alt="" src={getFileImg(file.tipoArquivo)} />

              <span>
                {file.categoria
                  ? `${file.categoria?.categoria} | ${file.nome}`
                  : `${file.nome}`}
              </span>
            </div>
            <IconDownload />
          </div>
        ))}
      </FileWrapper>
    </Container>
  );
}
