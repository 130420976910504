import styled from 'styled-components';

export const StyleOption = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    display: none;
  }

  &:hover svg {
    display: flex;
  }
`;
