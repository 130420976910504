/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useSimpleTableContext } from '../../context';
import { Wrapper } from './styles';
import { ParentRowsInfoType } from '../../types';

interface Props {
  rowKey: string;
}
export default function TreeviewHandler(props: Props) {
  const { rowKey: key } = props;
  const [opened, setOpened] = useState(true);
  const [rowInfo, setRowInfo] = useState<ParentRowsInfoType>([]);
  const { parentRowsInfo, id, levelSelection } = useSimpleTableContext();

  useEffect(() => {
    if (!!key && !!parentRowsInfo) {
      setRowInfo(parentRowsInfo[key]);
    }
  }, [key, parentRowsInfo]);

  useEffect(() => {}, [rowInfo]);

  useEffect(() => {}, [opened]);

  useEffect(() => {
    handleLevelSelection();
  }, [levelSelection]);

  function handleClick() {
    const op = opened;

    handleDescendants(op);
    handleChildren(op);
    setOpened(!opened);
  }

  function handleDescendants(op) {
    const { descendants } = rowInfo;
    descendants.forEach(desc => {
      const trEl = document.querySelector(`#${id} tr[data-key='${desc}']`);
      const value = !op ? 'yes' : 'no';
      trEl?.setAttribute('data-is-visible', value);
    });
  }

  function handleChildren(op) {
    const { children } = rowInfo;
    children.forEach(child => {
      const trEl = document.querySelector(`#${id} tr[data-key='${child}']`);
      const value = !op ? 'yes' : 'no';
      trEl?.setAttribute('data-is-open', value);
    });
  }

  function handleLevelSelection() {
    if (!levelSelection) return null;

    if (levelSelection === 'abrir-todos') {
      setOpened(true);
    } else if (levelSelection === 'fechar-todos') {
      setOpened(false);
    } else if (key.split('-').length < levelSelection) {
      setOpened(true);
    } else {
      setOpened(false);
    }
  }

  function IsOpenedIcon() {
    return (
      <span className="toggle-icon" onClick={handleClick}>
        {/* ▼ */}
        &#9660;
      </span>
    );
  }

  function IsClosedIcon() {
    return (
      <span className="toggle-icon" onClick={handleClick}>
        {/* ▶ */}
        &#9654;
      </span>
    );
  }

  return <Wrapper>{opened ? <IsOpenedIcon /> : <IsClosedIcon />}</Wrapper>;
}
