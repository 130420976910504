import React from 'react';
import { Label } from '../../styles';
import { Field } from '../../types';
import { Container, Icon } from './styles';

export default function Link(props: { field: Field }) {
  const { field } = props;

  return (
    <>
      <Label
        htmlFor={field.name}
        dangerouslySetInnerHTML={{ __html: field.label }}
        isLabel={field.label}
      />
      <Container>
        <a target="_blank" href={field.url} rel="noreferrer">
          <Icon />
        </a>
      </Container>
    </>
  );
}
