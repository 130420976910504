import FileDownload from 'js-file-download';
import { File, EnumFileType } from '../store/types';
import { normalizeFileUrl } from '../utils/file';
import api from './api';
import apiAdonis from './apiAdonis';
import { DataFileType } from 'pages/Support/components/Modals/GescorpGoCommentModal/types';

export function get(id: number, type: string): Promise<File[]> {
  return api.get(`/arquivo/get-by-id-referencia/${id}/${type}.json`);
}

export function getByKeyReferencia(
  keyReferencia: string,
  type: string,
): Promise<File[]> {
  return api.get(
    `/arquivo/get-by-key-referencia/${keyReferencia}?type=${type}`,
  );
}

export function getByUser(): Promise<File[]> {
  return api.get('/arquivo/get-by-user.json');
}

interface UpdateProps {
  id: number;
  nome: string;
  idCategoria: number;
  idComentario: number;
  tipoArquivo: EnumFileType;
  versao: number;
}
export function update(file: UpdateProps) {
  return api.post('/arquivo/edit.json', file).then(res => {
    saveLog({
      idArquivo: file.id,
      versao: file.versao,
      acao: 'update',
    });

    return res;
  });
}

export interface RemoveProps {
  id: number;
  idComentario: number;
  versao: number;
}
export function remove(file: RemoveProps) {
  return api.post('/arquivo/delete.json', file).then(res => {
    saveLog({
      idArquivo: file.id,
      versao: file.versao,
      acao: 'remove',
    });

    return res;
  });
}

export function download(file: File, disableLog = false) {
  const fileUrl = normalizeFileUrl(file.referencia);

  return fetch(fileUrl)
    .then(response => response.blob())
    .then(response => {
      FileDownload(response as Blob, `${file.nome}.${file.tipoArquivo}`);

      if (!disableLog) {
        saveLog({
          idArquivo: file.id,
          versao: file.versao,
          acao: 'download',
        });
      }
    });
}

interface LogProps {
  idArquivo: number;
  versao: number;
  acao: string;
  descricao?: string;
  keyReferencia?: string | number;
}

export function saveLog(data: LogProps) {
  return api.post('log-arquivo/add.json', data);
}

export function saveLogAdonis(data: LogProps) {
  return apiAdonis.post('log-arquivo/create', data);
}

export function editFile(data) {
  return api.put('/arquivo/update.json', data);
}

export function deleteByUuid(data: { uuidList: string[] }) {
  return apiAdonis.post('/arquivo/delete-by-uuid', data);
}

export function deleteByKeyReference(data: { keyReference: string }) {
  return apiAdonis.post('/arquivo/delete-by-key-reference', data);
}

export function bulkInsertOrUpdate(data: File[]): Promise<boolean> {
  return apiAdonis.post('/arquivo/bulk-insert-or-update', data);
}

export function bulkInsert(data: DataFileType[]) {
  return apiAdonis.post('/arquivo/bulk-insert', data);
}
