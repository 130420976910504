import styled from 'styled-components';
import { MdClear } from 'react-icons/md';
import { ButtonWithNoStyle } from '../../../../styles/common';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 77vh;

  & > div {
    margin-bottom: 1rem;
  }
`;

export const EmailsWrapper = styled.div`
  padding: 1rem;
  padding-bottom: .5rem;
  display: flex;
  flex-wrap: wrap;
`;

export const IconRemove = styled(MdClear)`
  font-size: 1.4rem;
`;

export const IconWrapper = styled.div`
  margin-left: 1rem;
  font-size: 1.2rem;
  display: none;
`;

export const EmailItem = styled.div<{ enambleHover?: boolean }>`
  border: 1px solid #ededed;
  border-radius: 3px;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  
  span {
    font-size: 1.2rem;
    margin: .3rem 1rem;
  }

  ${({ enambleHover }) => enambleHover && `
    &:hover {
      span {
        margin-right: 0;
      }
    }
    
    &:hover ${IconWrapper} {
      display: inline-block;
      background-color: #ededed;
      height: 100%;
      padding: 0 .5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }`}
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;

  button {
    width: 25%;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
`;

export const ActionButtom = styled.button`
  ${ButtonWithNoStyle}
  text-decoration: underline;
`;
