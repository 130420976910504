import styled from 'styled-components';

export const Span = styled.span`
  font-size: 1.4rem;
`;

export const Container = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`;

export const IconLegend = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
