import Button from 'components/Button';
import HeaderAction from 'components/HeaderAction';
import { IconGoBack } from 'components/HeaderAction/styles';
import Modal from 'components/Modal';
import useModal from 'hooks/use-modal';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import ImageViewerModal from '../ImageViewer';
import {
  fetchImagesAtom,
  imagesAtom,
  onShowUploadImagesModalAtom,
} from './atom';
import Gallery from './components/Gallery';
import { LineBlue, ModalContainerGallery, Title } from './styles';
import { ParamsGallery } from './types';

export default function PhotoGalleryComments() {
  const { isOpen, params, windowSec } = useModal<ParamsGallery>(
    ModalName.PHOTO_GALLERY_COMMENTS,
  );

  const dispatch = useDispatch();

  const fetchImages = useSetAtom(fetchImagesAtom);
  const onShowUploadImagesModal = useSetAtom(onShowUploadImagesModalAtom);
  const [photoReferenceList, setPhotoReferenceList] = useAtom(imagesAtom);

  useEffect(() => {
    const { idContrato, uuidOcorrencia, tipo } = params;

    if (isOpen) {
      fetchImages({
        tipo,
        idContrato,
        uuidOcorrencia,
      });

      return;
    }

    setPhotoReferenceList([]);
  }, [isOpen, params]);

  function handleClose() {
    dispatch(closeModal(ModalName.PHOTO_GALLERY_COMMENTS));
  }

  function handleShowUploadImagesModal() {
    const { idContrato, uuidOcorrencia, idReferencia, tipo } = params;

    onShowUploadImagesModal({
      tipo,
      idContrato,
      idReferencia,
      uuidOcorrencia,
      uuidReferencia: uuidOcorrencia,
      title: 'Adicionar evidência',
    });
  }

  const { title } = params;

  return (
    <>
      <Modal openLikePage isOpen={isOpen} windowSec={windowSec}>
        <HeaderAction>
          <IconGoBack data-tip="Fechar formulário" onClick={handleClose} />
          <Title>{title}</Title>

          <Button
            primary
            small
            text="Upload"
            onClick={handleShowUploadImagesModal}
          />
        </HeaderAction>

        <ModalContainerGallery>
          {photoReferenceList.map(item => (
            <>
              <Gallery key={item.key} mainImage={item} />
              <LineBlue>-</LineBlue>
            </>
          ))}
        </ModalContainerGallery>
      </Modal>
      <ImageViewerModal />
    </>
  );
}
