import { IoMdAddCircle } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import styled from 'styled-components';

export const AddIcon = styled(IoMdAddCircle)`
  position: absolute;
  right: 2px;
  top: -3.8rem;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export const EditIcon = styled(MdEdit)`
  position: absolute;
  right: 2px;
  top: 1.1rem;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export const Container = styled.div`
  position: relative;
`;

export const PhotoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Figure = styled.div`
  width: 26vw;
  min-height: 27vw;
  margin-bottom: 2rem;
  box-shadow: 0 3px 13px rgba(0, 0, 0, 0.2);

  img {
    width: 26vw;
    height: 26vw;
  }

  div {
    padding: 1rem;
    font-size: 1.2rem;
  }
`;

export const FakeFigure = styled.div`
  width: 28vw;
  min-height: 30vw;
`;
