import React, { useEffect, useState } from 'react';
import { getSqlResponse } from 'services/dataset';
import { Label } from 'styles/form';
import { OptionsType } from 'store/types';
import { Occurrence } from '../../types';
import { Container } from './styles';
import ReactSelect from 'components/ReactSelect';

interface Props {
  idContrato: number;
  setFormData(formData: Occurrence): void;
  formData: Occurrence;
}

export default function SelectCategory(props: Props) {
  const [options, setOptions] = useState<OptionsType[]>([]);

  const { idContrato, formData, setFormData } = props;

  useEffect(() => {
    getData();
  }, [idContrato]);

  async function getData() {
    if (!idContrato) {
      return;
    }

    const responsables = await getSqlResponse<OptionsType[]>(837, {
      idContrato,
    });

    setOptions(responsables);
  }

  function handleSelectCategory(option: OptionsType) {
    const { label, value } = option;

    setFormData({
      ...formData,
      categoriaOcorrencia: label as string,
      idCategoriaOcorrencia: value as number,
    });
  }

  function getSelectedValue() {
    const { categoriaOcorrencia, idCategoriaOcorrencia } = formData;

    return {
      label: categoriaOcorrencia,
      value: idCategoriaOcorrencia,
    };
  }

  return (
    <Container>
      <Label> Categoria </Label>
      <ReactSelect
        value={getSelectedValue()}
        options={options}
        onChange={handleSelectCategory}
      />
    </Container>
  );
}
