import styled from 'styled-components';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';

export const InputContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  input {
    height: 5.2rem;
    width: 100%;
    padding: 0 1.5rem;
    padding-right: 5rem;
    color: #777;
    font-size: 1.8rem;
    border: 1px solid #ddd;
    margin-bottom: 1.5rem;
    font-style: inherit;
    letter-spacing: 0.08rem;
    border-radius: ${({ theme }) => theme.radius.medium}px;

    &::placeholder {
      color: #999;
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.green};
      outline: 0 none;
    }
  }
`;

export const IconWrapper = styled.span`
  position: absolute;
  right: 1rem;
  top: 1.4rem;
`;

export const EyeIconViewPointOff = styled(BsEyeSlashFill)`
  color: #ddd;
  font-size: 2.3rem;
  cursor: pointer;
  &:hover {
    color: #aaa;
  }
`;

export const EyeIconViewPointOn = styled(BsEyeFill)`
  color: #ddd;
  font-size: 2.3rem;
  cursor: pointer;
  &:hover {
    color: #aaa;
  }
`;
