import styled, { css } from 'styled-components';

export const displayNone = css`
  display: none !important;
`;

export const Container = styled.div<{ height: string }>`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height};
`;

export const WrapperDoc = styled.div<{ isDesktop: boolean }>`
  flex: 1;
  overflow: hidden;
  width: ${({ isDesktop }) => (isDesktop ? '85vw' : '98vw')};
  margin: 0 auto;

  .e-pv-viewer-main-container {
    .e-toolbar-right {
      ${({ isDesktop }) => (!isDesktop ? displayNone : '')}
    }
  }
  .e-pv-bookmark-button {
    ${({ isDesktop }) => (isDesktop ? displayNone : '')}
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
`;
