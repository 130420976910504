import Axios from 'axios';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import { baseURL } from './api';
import apiAdonis from './apiAdonis';
import { getToken } from './auth';
import { EnumFileType, File } from '../store/types';
import { saveLog, saveLogAdonis } from './file';

export function upload(data, file) {
  const formData = new FormData();

  const token = getToken();

  formData.append('data', JSON.stringify(snakecaseKeys(data)));
  formData.append('file', file);

  return Axios.post(
    `${baseURL}/upload/with-comment.json?token=${token}`,
    formData,
  ).then(res => camelcaseKeys(res.data.response));
}

export interface UploadFileProps {
  file: globalThis.File;
  data: {
    nome: string;
    tipoArquivo: EnumFileType;
    tipo: string;
    uuid: string;
    idContrato?: number;
    idEmpresa?: number;
    idReferencia?: number;
    keyReferencia?: string;
    appendedData?: string;
    isPrivate?: boolean;
  };
}

export function uploadFileAdonis(props: UploadFileProps): Promise<File> {
  const { data, file } = props;
  const formData = new FormData();

  formData.append('fileData', JSON.stringify(data));
  formData.append('file', file);

  return apiAdonis
    .post(`arquivo/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => {
      // @ts-ignore
      const normalizedData: File = res;
      normalizedData.versao = 1;

      saveLogAdonis({
        idArquivo: normalizedData.id || 0,
        versao: normalizedData.versao,
        acao: 'create',
      });

      return normalizedData;
    });
}

export function uploadFile(props: UploadFileProps): Promise<File> {
  const { data, file } = props;
  const formData = new FormData();

  const token = getToken();

  formData.append('data', JSON.stringify(snakecaseKeys(data)));
  formData.append('file', file);

  return Axios.post(
    `${baseURL}/upload/file.json?token=${token}`,
    formData,
  ).then(res => {
    const normalizedData: File = camelcaseKeys(res.data.response);
    normalizedData.versao = 1;

    saveLog({
      idArquivo: normalizedData.id,
      versao: normalizedData.versao,
      acao: 'create',
    });

    return normalizedData;
  });
}

interface UploadFileNewVersionProps {
  file: globalThis.File;
  data: { uuid: string; fileName: string };
}

export function uploadFileNewVersion(props: UploadFileNewVersionProps) {
  const { data, file } = props;
  const formData = new FormData();

  const token = getToken();

  formData.append('data', JSON.stringify(snakecaseKeys(data)));
  formData.append('file', file);

  return Axios.post(
    `${baseURL}/upload/file-new-version.json?token=${token}`,
    formData,
  ).then(res => camelcaseKeys(res.data.response));
}
