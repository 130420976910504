import { SlicerState, SlicerActionTypes, SET_SLICER_SELECTION } from './types';

const initialState: SlicerState = {
  // slicerSelection will hold n objects, each for a different DataSlicer instance.
  // Each object is comprised by the slicerId string (set in the layout config file) as key, and that key will hold a {id, value} object.
  // value is the string selected in the DataSlicer component.
  // id is what will be used to filter the rawData of the chart component
  slicerSelection: {},
  lastSlicerSelection: {},
  replacementString: '$_SLICERVALUE',
};

export default (
  state = initialState,
  action: SlicerActionTypes,
): SlicerState => {
  switch (action.type) {
    case SET_SLICER_SELECTION:
      return {
        ...state,
        slicerSelection: {
          ...state.slicerSelection,
          ...action.payload,
        },
        lastSlicerSelection: { ...action.payload },
      };
    default:
      return state;
  }
};
