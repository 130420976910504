import styled from 'styled-components';
import { ModalContainer } from 'styles/modal';

export const ModalContainerGallery = styled(ModalContainer)`
  overflow: auto;
  margin-top: 5rem;
`;

export const LineBlue = styled.div`
  margin: 1.5rem 0;
  min-width: 100%;
  height: 3px;
  color: transparent;
  background: #fff;
`;

export const Title = styled.h1`
  flex: 1;
  padding: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
