import { CgMathPlus } from 'react-icons/cg';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

export const ListContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  span {
    font-size: 1.2rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 2rem;
`;

export const IconMore = styled(CgMathPlus)`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export const WrapperButton = styled.div`
  flex: 1;
  margin-top: auto;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 1rem;
`;
