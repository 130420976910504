import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SelectedIcon } from 'components/List/styles';
import Modal from 'components/Modal';
import useModal from 'hooks/use-modal';
import { getSqlResponse } from 'services/dataset';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { LoadingModalParams } from 'store/types';
import { ButtonClose, ModalTitle } from 'styles/modal';
import { FormNewItemModalParams, State } from '../../types';
import { useDynamicForm } from '../../context';
import { TableStyled, Th } from '../Table/styles';
import {
  Button,
  Container,
  IconWrapper,
  ModalContainer,
  SelectAll,
} from './styles';

const customStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 999,
  },
};

export default function TableModal() {
  const [data, setData] = useState<State[]>([]);
  const [filteredData, setFilteredData] = useState<State[]>([]);
  const { globalState, contractId: idContrato } = useDynamicForm();

  const dispatch = useDispatch();

  const { isOpen, params, windowSec } = useModal<FormNewItemModalParams>(
    ModalName.ACTIVITIES_TABLE_MODAL,
  );

  useEffect(() => {
    if (isOpen) {
      getData();
    } else {
      setData([]);
      setFilteredData([]);
    }
  }, [isOpen]);

  useEffect(() => {
    normalizeSelectedData();
  }, [params, data]);

  async function getData() {
    dispatch(
      showModal<LoadingModalParams>(ModalName.LOADING, {
        text: 'Carregando atividades',
      }),
    );

    const { datasetParams } = globalState!;

    const res = await getSqlResponse<State[]>(332, {
      idContrato,
      ...datasetParams,
    });

    setData(res);

    dispatch(closeModal(ModalName.LOADING));
  }

  function getTitle() {
    const { title } = params;

    if (title) {
      return title.replace('<b>', '').replace('</b>', '');
    }

    return 'Formulário';
  }

  function normalizeSelectedData() {
    const { previousData } = params;

    if (!previousData || !data.length) {
      return;
    }

    const newData = data?.filter(
      item => previousData.findIndex(prev => prev.uuid === item.uuid) === -1,
    );

    setFilteredData(newData);
  }

  function handleClose() {
    dispatch(closeModal(ModalName.ACTIVITIES_TABLE_MODAL));
  }

  function handleSelect(key: string, selectAll?: boolean) {
    const dataSelected = filteredData.filter(sec => sec.selected);

    const normalized = filteredData.map(item => {
      const newItem = { ...item };

      if (selectAll) {
        newItem.selected = dataSelected.length !== filteredData.length;

        return newItem;
      }

      if (newItem.uuid === key) {
        newItem.selected = !newItem.selected;
      }

      return newItem;
    });

    setFilteredData(normalized);
  }

  function handleSelectAll() {
    handleSelect('', true);
  }

  function handleSave() {
    const { onSave } = params;
    const dataSelected = filteredData.filter(sec => sec.selected);
    onSave(dataSelected);
    handleClose();
  }

  function renderRows() {
    return filteredData.map(rowData => (
      <tr key={rowData.uuid} onClick={() => handleSelect(rowData.uuid!)}>
        <td>{rowData.atividade}</td>
        <td>{rowData.status}</td>
        <td>
          {rowData.selected && (
            <IconWrapper>
              <SelectedIcon />
            </IconWrapper>
          )}
        </td>
      </tr>
    ));
  }

  return (
    <Modal
      onRequestClose={handleClose}
      isOpen={isOpen}
      style={customStyle}
      windowSec={windowSec}
    >
      <ModalContainer>
        <ButtonClose onClick={handleClose} />
        <ModalTitle> {getTitle()} </ModalTitle>

        <SelectAll onClick={handleSelectAll}>
          Selecionar Todos &nbsp;
          <SelectedIcon />
        </SelectAll>

        <Container>
          <TableStyled>
            <thead>
              <tr>
                <Th size={80}>Atividade</Th>
                <Th size={15}>Status</Th>
                <Th size={5}>
                  <div />
                </Th>
              </tr>
            </thead>

            <tbody>{renderRows()}</tbody>
          </TableStyled>
        </Container>

        <Button primary text="Salvar" onClick={handleSave} />
      </ModalContainer>
    </Modal>
  );
}
