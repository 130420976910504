/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { getAdonisToken, signOut } from './auth';
import { captureException } from '@sentry/react';

// const host = process.env.BASE_URL_ADONIS; //webpack-comments
const host = import.meta.env.VITE_BASE_URL_ADONIS; // vite-comments

export const baseURL = `${host}/api/v1`;

const apiAdonis = axios.create({ baseURL });

apiAdonis.defaults.validateStatus = () => true;

apiAdonis.interceptors.request.use(configRes => {
  const config = configRes;

  /* Add token  */
  const token = getAdonisToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

apiAdonis.interceptors.response.use(res => {
  if (res.config.params?.ignoreInterceptor) {
    return res;
  }

  if (![200, 201, 204].includes(res.status)) {
    handleError(res);
  }

  const { data } = res;

  if (res.config.params?.apiConfig?.disableConvertToCamelcase) {
    return data;
  }

  if (typeof data === 'object') {
    /* Normalize payload data to camelCase  */
    const camelcaseKeysConfig = res.config.params?.apiConfig || {};

    return camelcaseKeys(data, { deep: true, ...camelcaseKeysConfig });
  }

  return data;
}, handleError);

function handleError(error: AxiosResponse<any>): Promise<any> {
  console.error(error);

  captureException(error, {
    tags: {
      status: error.status,
      method: error.config.method,
      payload: error.config.data,
      'api-url': error.config.url,
      'request-api-error': true,
      base: 'adonis',
    },
  });

  if (error.status === 401) {
    signOut();
    throw error;
  }

  throw error;
}

export default apiAdonis;
