import RenderWhen from 'components/RenderWhen';
import SimpleNavbar from 'components/SimpleNavbar';
import useDeviceDetect from 'hooks/use-device-detect';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { saveAdonisToken } from 'services/auth';
import { OptionsType } from 'store/types';
import { isObjectEmpty } from 'utils/common';
import { currentStepAtom, urlParamsAtom } from './atoms';
import DocNameHeader from './components/DocNameHeader';
import OpenCam from './components/OpenCam';
import PdfViewer from './components/PdfViewer';
import SignaturePage from './components/SignaturePage';
import SubscriberDoc from './components/SubscriberDoc';
import SubscriberList from './components/SubscriberList';
import WarningInvalidTokenPage from './components/WarningInvalidTokenPage';
import WarningSignedCancelPage from './components/WarningSignedCancelPage';
import WarningSignedCanceled from './components/WarningSignedCanceled';
import WarningSignedSuccessPage from './components/WarningSignedSuccessPage';
import { Circle, ContainerPage, ProgressContainer, Step } from './styles';
import { EnumSource, EnumStep, RawURLParams } from './types';
import {
  checkFileViewer,
  checkTokenValidity,
  getProgressContianerMarginSize,
  getStepsConfig,
  isExistToken,
  isShowProgressHeader,
  isSourceApp,
  normalizeUrlParams,
  resendEmailWhenExpiredToken,
} from './utils';

export default function DocSign() {
  const [isLoading, setIsLoading] = useState(true);
  const [stepsConfig, setStepsConfig] = useState<OptionsType[]>([]);

  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [urlParams, setUrlParams] = useAtom(urlParamsAtom);
  const rawURLParams = useParams<RawURLParams>();

  const deviceSize = useDeviceDetect();

  useEffect(() => {
    loadPage();
  }, [rawURLParams]);

  async function loadPage() {
    if (isObjectEmpty(rawURLParams)) return;

    const params = normalizeUrlParams(rawURLParams);

    setUrlParams(params);

    if (params.source === EnumSource.VIEWER) {
      checkFileViewer(params.uuid!, setCurrentStep);

      return;
    }

    if (isExistToken(params?.token)) return;

    if (params?.token) {
      saveAdonisToken(params.token);
    }

    const isTokenValid = await checkTokenValidity();

    if (!isTokenValid) {
      setCurrentStep(EnumStep.TOKEN_INVALID);
      resendEmailWhenExpiredToken(params!.uuid!);

      return;
    }

    setStepsConfig(getStepsConfig(params!.source));

    if (params!.source === EnumSource.EMAIL) {
      setCurrentStep(EnumStep.SUBSCRIBER_DOC);
    }

    setIsLoading(false);
  }

  function renderLayout() {
    if (currentStep === EnumStep.SUBSCRIBER_LIST) {
      return <SubscriberList />;
    }

    if (currentStep === EnumStep.SUBSCRIBER_DOC) {
      return <SubscriberDoc />;
    }

    if (currentStep === EnumStep.PDF_VIEWER) {
      return <PdfViewer />;
    }

    if (currentStep === EnumStep.SIGNATURE_PAGE) {
      return <SignaturePage />;
    }

    if (currentStep === EnumStep.OPEN_CAM) {
      return <OpenCam />;
    }

    return <></>;
  }

  if (currentStep === EnumStep.TOKEN_INVALID) {
    return <WarningInvalidTokenPage />;
  }

  if (currentStep === EnumStep.REGISTER_SUBSCRIBERS_CANCEL) {
    return <WarningSignedCancelPage />;
  }

  if (currentStep === EnumStep.SIGNED) {
    return <WarningSignedSuccessPage />;
  }

  if (currentStep === EnumStep.SIGNED_CANCELED) {
    return <WarningSignedCanceled />;
  }

  if (isLoading) {
    return null;
  }

  return (
    <ContainerPage>
      {!isSourceApp(urlParams) && <SimpleNavbar title="Assinatura" />}

      <RenderWhen
        isTrue={[EnumStep.SUBSCRIBER_LIST, EnumStep.SUBSCRIBER_DOC].includes(
          currentStep,
        )}
      >
        <DocNameHeader />
      </RenderWhen>

      {isShowProgressHeader(currentStep) && (
        <ProgressContainer
          marginSize={getProgressContianerMarginSize(
            isSourceApp(urlParams),
            deviceSize,
          )}
        >
          {stepsConfig.map(item => {
            return (
              <Step completed={currentStep >= +item.value} key={item.value}>
                <Circle
                  completed={currentStep >= +item.value}
                  className="circle"
                >
                  {item.value}
                </Circle>
                <span> {item.label}</span>
              </Step>
            );
          })}
        </ProgressContainer>
      )}
      {renderLayout()}
    </ContainerPage>
  );
}
