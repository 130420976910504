import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    text-align: center;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem 0;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
