import { createContext, useContext } from 'react';
import { OptionsType, Company } from '../../../store/types';

export interface FileGroupContextProps {
  fileGroupOptions: OptionsType[];
  setFileGroupOptions: React.Dispatch<React.SetStateAction<OptionsType[]>>;
  company: Company | null;
  setCompany: React.Dispatch<React.SetStateAction<Company | null>>;
}

export const FileGroupContext = createContext({} as FileGroupContextProps);

export function useFileGroup() {
  return useContext(FileGroupContext);
}
