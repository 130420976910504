import {
  BACK_TO_HOME_DISPLAY,
  HAS_MENU,
  HIDE_MENU,
  MenuActionTypes,
  SET_MENU_FLAG,
  SET_MENU_PARAMS,
  SET_MENU_RAW,
  SET_MENU_ROUTES,
  SET_SUB_MENU,
  SHOW_MENU,
} from './types';

export function setMenuFlag(flag: string): MenuActionTypes {
  return {
    type: SET_MENU_FLAG,
    payload: flag,
  };
}

export function setMenuRoutes(data: string[]): MenuActionTypes {
  return {
    type: SET_MENU_ROUTES,
    payload: data,
  };
}

export function setMenuParams(data: object): MenuActionTypes {
  return {
    type: SET_MENU_PARAMS,
    payload: data,
  };
}

export function showMenu(): MenuActionTypes {
  return {
    type: SHOW_MENU,
  };
}

export function hideMenu(): MenuActionTypes {
  return {
    type: HIDE_MENU,
  };
}

export function backToHomeDisplay(display: string): MenuActionTypes {
  return {
    type: BACK_TO_HOME_DISPLAY,
    payload: display,
  };
}

export function setHasMenu(hasMenu: boolean): MenuActionTypes {
  return {
    type: HAS_MENU,
    payload: hasMenu,
  };
}

export function setMenuRendered(payload: any[]): MenuActionTypes {
  return {
    type: SET_SUB_MENU,
    payload,
  };
}

export function setMenuRaw(payload: any[]): MenuActionTypes {
  return {
    type: SET_MENU_RAW,
    payload,
  };
}
