import { Device } from 'hooks/use-device-detect';

export function getCanvasSize(
  device: Device,
): { width: number; height: number } {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  if (device.isMobile) {
    return {
      width: screenWidth - 100,
      height: screenHeight - 200,
    };
  }

  return {
    width: 420,
    height: 300,
  };
}

export function getCanvasSizeWeb(): { width: number; height: number } {
  return {
    width: 650,
    height: 350,
  };
}
