/* eslint-disable no-console */
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import NavLogoMobile from 'assets/navbar-logo-o.png';
import NavLogo from 'assets/navbar-logo3.png';
import useUser from 'hooks/use-user';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isDevMode } from 'services/api';
import {
  checkUserAuthenticatedFromAd,
  isAuthenticated,
  signOut,
  isImpersonate as verifyImpersonate,
} from 'services/auth';
import {
  getUserCompanyFromStorage,
  getUserWithProfile,
  removeUserCompanyFromStorage,
} from 'services/user';
import store from 'store';
import { setIsMobile } from 'store/config/actions';
import { clear, clearDatasetID } from 'store/contract/actions';
import {
  clearIsImpersonate,
  setIsImpersonate,
} from 'store/impersonate/actions';
import {
  hideMenu,
  setMenuFlag,
  setMenuRoutes,
  showMenu,
} from 'store/menu/actions';
import { closeModal } from 'store/modal/actions';
import { clearTitle } from 'store/navbar/actions';
import { RootState } from 'store/rootReducer';
import { EnumRoutes } from 'store/types';
import { setUserInfo } from 'store/user/actions';
import ContractFilter from '../ContractFilter';
import {
  Container,
  ContainerIcon,
  ContractName,
  LogOutIcon,
  LogoMenuWrapper,
  MenuIcon,
  NavLogoImg,
  RightContent,
  StyledNavBar,
  SupportIcon,
  TitleWrapper,
  // ImpersonateInfoContainer,
  UserInfo,
  WarningModeText,
} from './styles';
import { getSingleResponseAdonis } from 'services/dataset';
import CallTypeModal from 'pages/Support/components/Modals/CallTypeModal';
import CheckCNPJModal from 'pages/Support/components/Modals/CallTypeModal/components/Modals/checkCNPJ';

interface Props {
  hideButtons: boolean;
}

export default function NavBar(props: Props): ReactElement {
  const [isSmallerThan960, setIsSmallerThan960] = useState(false);
  const [isPendingSupport, setIsPendingSupport] = useState(false);
  const contractName = useSelector(
    (state: RootState) => state.contract.contract?.nome,
  );
  const { datasetId } = useSelector((state: RootState) => state.contract);
  const { title } = useSelector((state: RootState) => state.navbar);
  const menuDisplay = useSelector((state: RootState) => state.menu.display);
  const { isImpersonate } = useSelector(
    (state: RootState) => state.impersonate,
  );

  const userInfo = useUser() || {};

  const dispatch = useDispatch();
  const history = useHistory();

  const userInfoIsSet = useRef(false);
  const isAuthenticatedAd = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();

  const { hideButtons } = props;

  useEffect(() => {
    if (!userInfo.email) {
      return;
    }

    checkUserAuthenticatedFromAd(inProgress, accounts, instance, userInfo);
    checkPendingSupport();
  }, [inProgress, accounts, instance, userInfo?.email]);

  useEffect(() => {
    isMobileCheck();
    window.addEventListener('resize', isMobileCheck);

    if (verifyImpersonate()) {
      dispatch(setIsImpersonate());
    } else {
      dispatch(clearIsImpersonate());
    }

    return () => {
      window.removeEventListener('resize', isMobileCheck);
    };
  }, []);

  useEffect(() => {
    if (!userInfoIsSet.current) {
      setUserInfoToRedux();
    }
  }, []);

  function logStateOnLocalhost() {
    if (location.hostname === 'localhost') {
      const state = store.getState();
      console.log('ppp-state', state);
    } else {
      const state = store.getState();
      console.log('ppp-state', state);
    }
  }

  async function checkPendingSupport() {
    if (
      (!isAuthenticated() && userInfo?.idUsuario) ||
      history.location.pathname === EnumRoutes.SUPPORT
    ) {
      return;
    }

    const res = await getSingleResponseAdonis<{ isPending: number }>(4422, {
      idUsuario: userInfo?.idUsuario,
    });

    if (!res?.isPending) return;

    setIsPendingSupport(true);
  }

  async function setUserInfoToRedux() {
    if (!isAuthenticated()) {
      return;
    }

    const userInfo = await getUserWithProfile();

    if (Object.keys(getUserCompanyFromStorage()).length) {
      const userFromLocalStorage = getUserCompanyFromStorage();
      dispatch(setUserInfo({ ...userInfo, ...userFromLocalStorage }));

      return;
    }

    dispatch(setUserInfo(userInfo));

    userInfoIsSet.current = true;
  }

  function isMobileCheck() {
    const isMobileAux = window.innerWidth < 768;
    dispatch(setIsMobile(isMobileAux));

    const isSmallerThan960Aux = window.innerWidth <= 960;
    setIsSmallerThan960(isSmallerThan960Aux);
  }

  function handleLogout() {
    logOutAd();

    signOut();

    dispatch(closeModal());
    dispatch(clearDatasetID());
    dispatch(clear());
    dispatch(setMenuRoutes([]));
    dispatch(clearIsImpersonate());

    removeUserCompanyFromStorage();
  }

  function handleGoSupport() {
    history.push(EnumRoutes.SUPPORT);
    setIsPendingSupport(false);
  }

  function logOutAd() {
    if (isAuthenticatedAd) {
      instance.logoutPopup();
    }
  }

  function handleGoHome() {
    if (hideButtons) {
      return;
    }

    dispatch(clearTitle());

    dispatch(setMenuFlag('topMenu'));
    history.push(EnumRoutes.HOME);

    dispatch(closeModal());
    dispatch(clearDatasetID());
    dispatch(clear());
  }

  function handleToggleMenu() {
    // eslint-disable-next-line no-unused-expressions
    if (menuDisplay === '0px') {
      dispatch(hideMenu());
    } else {
      dispatch(showMenu());
    }

    logStateOnLocalhost();
  }

  function renderDevMode() {
    return isDevMode ? <WarningModeText>API - DEV</WarningModeText> : <></>;
  }

  function renderImpersonateMode() {
    const { idUsuario, apelido, empresa } = userInfo;

    if (!isImpersonate) {
      return null;
    }

    return (
      <WarningModeText>
        {`IMPERSONATE - ID: ${idUsuario}`}
        <UserInfo>
          <p>Info do Usuário Atual:</p>
          <p>{`id_usuario: ${idUsuario}`}</p>
          <p>{`Usuário: ${apelido}`}</p>
          <p>{`Empresa: ${empresa}`}</p>
        </UserInfo>
      </WarningModeText>
    );
  }

  function renderIcons() {
    if (hideButtons) return null;

    return (
      <ContainerIcon isPendingSupport={isPendingSupport}>
        <div className="notification">
          <span />
          <SupportIcon onClick={handleGoSupport} />
        </div>

        <LogOutIcon onClick={handleLogout} />
      </ContainerIcon>
    );
  }

  return (
    <Container>
      <StyledNavBar>
        <LogoMenuWrapper>
          {!hideButtons && (
            <button type="button" onClick={handleToggleMenu}>
              <MenuIcon />
            </button>
          )}

          <button type="button" onClick={handleGoHome}>
            <NavLogoImg src={isSmallerThan960 ? NavLogoMobile : NavLogo} />
          </button>
          {renderDevMode()}
          {renderImpersonateMode()}
        </LogoMenuWrapper>
        <TitleWrapper size={title ? title.length : 0}>
          <span id="title-wrapper">{title}</span>
        </TitleWrapper>

        <RightContent>
          {datasetId ? <ContractFilter /> : null}
          {!datasetId && contractName ? (
            <ContractName id="contract-name">{contractName}</ContractName>
          ) : null}

          {renderIcons()}
        </RightContent>
      </StyledNavBar>
      <CallTypeModal />
      <CheckCNPJModal />
    </Container>
  );
}
