import React from 'react';
import { Text, Row, TextWrapper } from './styles';
import { useDispatch } from 'react-redux';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import useUser from 'hooks/use-user';
import { useAtomValue } from 'jotai';
import { isMultiCompanyAtom } from 'components/Modal/SelectUserCompany/atoms';

export default function SelectUserCompanyButton() {
  const dispatch = useDispatch();
  const user = useUser();
  const isMultiCompany = useAtomValue(isMultiCompanyAtom);

  function handleSelectUserCompany() {
    if (isMultiCompany) {
      dispatch(showModal(ModalName.SELECT_USER_COMPANY));
    }
  }

  return (
    <Row onClick={handleSelectUserCompany}>
      <TextWrapper>
        <Text>{user?.empresa}</Text>
        {isMultiCompany && (
          <Text style={{ fontSize: '8px' }}>Clique para mudar de empresa</Text>
        )}
      </TextWrapper>
    </Row>
  );
}
