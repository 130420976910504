import ButtonComponent from 'components/Button';
import styled from 'styled-components';

export const ContainerMobile = styled.div`
  border: 1px solid #ddd;
  display: flex;
  flex: 1;
  position: relative;
`;

export const ContainerWeb = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Button = styled(ButtonComponent)`
  white-space: nowrap;

  @media only screen and (max-width: 768px) {
    margin-left: 3rem;
    transform: rotate(90deg);
    transform-origin: left;
  }
`;

export const ButtonsWrapperMobile = styled.div`
  display: grid;
  grid-template-rows: 1fr 11rem 10rem 17rem 10rem;
  height: 85vh;
  justify-items: left;

  width: 5rem;
`;

export const ButtonsWrapperWeb = styled.div<{
  width: number;
}>`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => width}px;
  margin: 0 auto;
`;

export const SignatureCanvasWrapperMobile = styled.div<{
  width: number;
  height: number;
}>`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  margin: auto 0;
  margin-left: 1rem;

  &::before {
    content: '';
    position: absolute;
    border-left: 1px solid ${({ theme }) => theme.colors.primary};
    top: 5rem;
    bottom: 5rem;
    left: 7rem;
  }
`;

export const SignatureCanvasWrapperWeb = styled.div<{
  width: number;
  height: number;
}>`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  margin: 2rem auto;

  &::after {
    content: '';
    position: absolute;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    bottom: 9rem;
    right: 7rem;
    left: 7rem;
  }
`;

export const SignatureName = styled.p`
  font-family: 'Lucida Handwriting', cursive;
  font-weight: bold;
  font-size: 3rem;
  position: absolute;

  white-space: nowrap;

  @media only screen and (max-width: 768px) {
    transform: rotate(90deg);
    transform-origin: left top;
    top: 2rem;
    left: 13rem;
  }

  @media only screen and (min-width: 769px) {
    bottom: 9rem;
    left: 7rem;
  }
`;
