import React from 'react';

export default function FeiconDownload() {
  return (
    <div style={{ overflow: 'scroll' }}>
      <div
        style={{
          backgroundColor: '#54C3BD',
          height: '55px',
          display: 'flex',
          alignItems: 'center',
          padding: '15px',
          paddingTop: '18px',
        }}
      >
        <img
          width={120}
          height={25}
          src="https://gescorpgo-datafiles.nyc3.digitaloceanspaces.com/datafiles/logos/empresa/2b.png"
          alt="gescorp"
        />
      </div>
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '15px',
            fontSize: '1.4rem',
          }}
        >
          <b>Política de Gestão de Dados GescorpGO</b>
        </p>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50vw',
            margin: '0 auto',
            fontSize: '1.4rem',
          }}
        >
          A GescorpGO informa que a contratação de seus aplicativos é realizada
          por empresas interessadas em obter um diferencial competitivo a partir
          da otimização da caputra de informação em seus empreendimentos e da
          gestão eficiente destes dados para o gerenciamento de suas obras.
        </p>
        <br />
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50vw',
            margin: '0 auto',
            fontSize: '1.4rem',
          }}
        >
          Todos os usuários criados na plataforma são definidos e geridos
          exclusivamente pelos gestores de nossos clientes e não há qualquer
          coleta de dados pessoais à excessão do nome e email utilizados para
          fins de tratar perfil de acesso e apresentação nos relatórios.
        </p>
        <br />
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50vw',
            margin: '0 auto',
            fontSize: '1.4rem',
          }}
        >
          Neste contexto todos os dados coletados são de propriedade de nossos
          clientes, não sendo possível a solicitação de forma individual da
          exclusão definitiva de um usuário, pois isso implicaria em perda de
          dados importantes para nossos clientes como autoria de inspeções,
          aprovações realizadas entre outras.
        </p>
        <br />
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50vw',
            margin: '0 auto',
            fontSize: '1.4rem',
          }}
        >
          Cada cliente possui um gestor de relacionamento que poderá ser
          acionado para solicitações de gestão de usuários, backup ou exclusão
          definitiva dos dados existentes, cada solicitação será devidamente
          analisada, aprovada e realizada de forma a não gerar interrupções ou
          outros impactos na operação dos projetos.
        </p>
      </div>
    </div>
  );
}
