import styled from 'styled-components';

export const ModalContainer = styled.div`
  padding: 2rem;
  min-width: 50vw;
  max-width: 90vw;
  min-height: 40vh;
  max-height: 80vh;
  overflow: scroll;

  .react-pdf__Page {
    position: absolute;
    top: 50%;
    left: 50px;
  }
`;
export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
`;

export const SectionButton = styled.div`
  position: absolute;
  right: 25px;
  bottom: 15px;
  width: 20px;
  height: auto;
`;

export const OptionsButtonContainer = styled.div`
  position: relative;
`;

export const ButtonOption = styled.button`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #333;

  &:hover {
    background-color: #999;
    border: 0.5px solid #333;
  }
`;
