import showAlert from 'components/Alert';
import Button from 'components/Button';
import InputDateModal from 'components/InputDateModal';
import Modal from 'components/Modal';
import TextArea from 'components/TextArea';
import Attachments from 'components/Widgets/Attachments';
import { useFormik } from 'formik';
import useModal from 'hooks/use-modal';
import useUser from 'hooks/use-user';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { create as createRepproval } from 'services/approval';
import { getSingleResponse, getSqlResponse } from 'services/dataset';
import { create, update } from 'services/occurrence';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import {
  Approval,
  Occurrence,
  OccurrenceStatus,
  OptionsType,
} from 'store/types';
import {
  ButtonClose,
  ModalContainer,
  ModalHeader,
  ModalTitle,
} from 'styles/modal';
import { generateUUID } from 'utils/common';
import { getUsDateTime } from 'utils/date';
import { ApprovalLevel, StatusApproval } from '../../types';
import Info from './components/Info';
import { ContainerTagButton, Content, Footer, Form, Row } from './styles';
import { FormData } from './types';
import { validation } from './validation';
import Select from 'components/ReactSelect';
import { Label } from 'styles/form';
import { Danger } from 'styles/text';
import { update as updateRepproval } from 'services/approval';

export interface FormRepprovalOccurrenceModalParams {
  idContrato: number;
  referenciaAprovacao: number;
  origem: string;
  isCancellation?: boolean;
  approvalData: ApprovalLevel;
  idTipo?: number;
  onAfterCancell?(): Promise<void>;
  onAfterSave?(): Promise<void> | void;
}

interface SaveValuesProps extends FormData {
  areaResponsavel?: OptionsType;
  tags?: string;
}

export default function FormRepprovalOccurrenceModal() {
  const previousFormDataRef = useRef({} as SaveValuesProps);

  const { isOpen, params, windowSec } = useModal<
    FormRepprovalOccurrenceModalParams
  >(ModalName.FORM_REPPROVAL_OCCURRENCE_MODAL);

  const [optionsList, setOptionsList] = useState<OptionsType[]>();

  const user = useUser();

  const formik = useFormik({
    validateOnMount: false,
    initialValues: {} as SaveValuesProps,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    if (isOpen) {
      getData();
    } else {
      formik.setValues({} as FormData);
    }
  }, [isOpen]);

  async function getData() {
    const { approvalData, idTipo } = params;
    const { idOcorrencia } = approvalData;

    const optionsListResponse = await getSqlResponse<OptionsType[]>(2324, {
      idEmpresa: user.idEmpresa,
      idTipo,
    });

    setOptionsList(optionsListResponse);

    if (!idOcorrencia) {
      formik.setFieldValue('uuid', generateUUID());

      return;
    }

    dispatch(showModal(ModalName.LOADING, { text: 'Carregando ocorrência' }));

    const occurrenceResponse = await getSingleResponse<Occurrence>(1103, {
      idOcorrencia,
    });

    const newOccurrenceResponse = {
      ...occurrenceResponse,
      areaResponsavel: {} as OptionsType,
    };

    previousFormDataRef.current = newOccurrenceResponse as SaveValuesProps;

    formik.setValues(newOccurrenceResponse as SaveValuesProps);

    dispatch(closeModal(ModalName.LOADING));
  }

  function checkIsDisabledField() {
    const { approvalData } = params;
    const { idOcorrencia } = approvalData;

    if (!idOcorrencia) {
      return false;
    }

    return user.idUsuario !== formik.values.idUsuarioCriacao;
  }

  function handleSubmit(values: SaveValuesProps) {
    const { isCancellation } = params;

    if (isCancellation) {
      showAlert({
        title: 'Atenção!',
        subtitle: 'Deseja cancelar essa ocorrência?',
        actions: [
          {
            text: 'Não',
          },
          {
            text: 'Sim',
            onClick: () => onSave(values),
          },
        ],
      });
    } else {
      onSave(values);
    }
  }

  async function onSave(values: SaveValuesProps) {
    dispatch(showModal(ModalName.LOADING, { text: 'Salvando' }));

    const { approvalData, onAfterCancell, onAfterSave } = params;
    const { idOcorrencia, status, idAprovacao } = approvalData;

    if (!idOcorrencia) {
      if (status && status === StatusApproval.REEVALUATE) {
        await updateRepproval({
          id: idAprovacao,
          status: 2,
          areaResponsavel: values.areaResponsavel!.value,
        });

        await createNewOccurrence(idAprovacao, values);
      } else {
        const { id } = await createNewRepproval(
          values.areaResponsavel!.value as number,
        );

        await createNewOccurrence(id!, values);
      }
    } else {
      await updateCurrentOccurrence(values);
    }

    if (onAfterCancell) {
      await onAfterCancell();
    }

    if (onAfterSave) {
      await onAfterSave();
    }

    addToast('Salvo com sucesso', { appearance: 'success' });
    dispatch(closeModal(ModalName.LOADING));
    handleClose();
  }

  function createNewRepproval(idResponsavel: number) {
    const { idContrato, referenciaAprovacao, origem, approvalData } = params;

    const approval = {
      origem,
      idContrato,
      idEmpresa: user.idEmpresa,
      idAprovador: user.idUsuario!,
      idNivelAprovacao: approvalData.idNivelAprovacao,
      observacao: approvalData.observacaoOriginal,
      status: StatusApproval.REPPROVED,
      idReferencia: referenciaAprovacao,
      dtAprovacao: '',
      areaResponsavel: idResponsavel,
    } as Approval;

    return createRepproval(approval);
  }

  function createNewOccurrence(idAprovacao: number, values: SaveValuesProps) {
    const { idContrato, origem } = params;

    const occurrence: Partial<Occurrence> = {
      ...values,
      origem,
      idContrato,
      referencia: idAprovacao.toString(),
      idEmpresa: user.idEmpresa,
      idUsuarioCriacao: user.idUsuario!,
      idUltimoUsuario: user.idUsuario!,
      idCategoriaOcorrencia: 0,
      idOcorrencia: 0,
      flagStatus: 1,
      dtStatus: getUsDateTime(),
      tags: formik.values.tags ? JSON.parse(formik.values.tags as string) : [],
    };

    return create(occurrence as Occurrence);
  }

  function updateCurrentOccurrence(values: SaveValuesProps) {
    const { approvalData, idContrato, origem, isCancellation } = params;
    const { idOcorrencia } = approvalData;

    const status = isCancellation ? OccurrenceStatus.CANCELADO : values.status;

    const {
      status: previousStatus,
      idUltimoUsuario,
    } = previousFormDataRef.current;

    const isStatusChanged = values.status !== previousStatus;

    const occurrence: Partial<Occurrence> = {
      ...values,
      status,
      origem,
      idContrato,
      dtStatus: isStatusChanged ? getUsDateTime() : values.dtStatus,
      idOcorrencia: idOcorrencia!,
      idEmpresa: user.idEmpresa,
      idUltimoUsuario: isStatusChanged ? user.idUsuario! : idUltimoUsuario,
      tags: formik.values.tags ? JSON.parse(formik.values.tags as string) : [],
    };

    return update(occurrence as Occurrence);
  }

  function handleClose() {
    dispatch(closeModal(ModalName.FORM_REPPROVAL_OCCURRENCE_MODAL));
  }

  function handleChangeDate(name: string) {
    return (date: string) => {
      formik.setFieldValue(name, date);
    };
  }

  if (!isOpen) {
    return null;
  }

  function handleSelectChange(selectedOption: OptionsType) {
    formik.setFieldValue('areaResponsavel', selectedOption);
  }

  const { origem, isCancellation, approvalData } = params;
  const { idOcorrencia, showTagButton, idTipoAprovacao } = approvalData;

  const isFieldDisabled = checkIsDisabledField();

  function handleOpenModalTag() {
    dispatch(
      showModal(ModalName.SELECT_TAGS_MODAL, {
        selectedMyTags: formik.values.tags
          ? JSON.parse(formik.values.tags as string)
          : [],
        onSaveTags: handleSaveTags,
        datasetId: 2734,
        idTipoAprovacao: idTipoAprovacao,
      }),
    );
  }

  function getButtonTagLabel() {
    if (!formik?.values?.tags) {
      return `Tags (0)`;
    }

    const normalizedTags = JSON.parse(formik?.values?.tags);

    return `Tags (${normalizedTags.filter(item => item !== null).length})`;
  }

  function handleSaveTags(tag: OptionsType) {
    const value = tag.value ? JSON.stringify([tag.value]) : null;

    formik.setFieldValue('tags', value);
  }

  return (
    <>
      <Modal isOpen={isOpen} windowSec={windowSec}>
        <ModalHeader>
          <ButtonClose onClick={handleClose} />
          <ModalTitle>Detalhe da Reprovação</ModalTitle>
        </ModalHeader>

        <ModalContainer width="70rem" height="80vh">
          <Form onSubmit={formik.handleSubmit}>
            <Content>
              <Info occurrence={formik.values} />

              <TextArea
                label="Descrição"
                name="ocorrencia"
                value={formik.values.ocorrencia}
                errorMessage={formik.errors.ocorrencia}
                onChange={formik.handleChange}
                disabled={isFieldDisabled}
              />

              <Row>
                <InputDateModal
                  label="Prazo"
                  name="prazo"
                  value={formik.values.prazo}
                  onChange={handleChangeDate('prazo')}
                  disabled={isFieldDisabled}
                />

                {!idOcorrencia && (
                  <div style={{ width: '20rem' }}>
                    <Label>Enviar Para</Label>
                    <Select
                      name="area_responsavel"
                      options={optionsList}
                      value={formik.values.areaResponsavel}
                      onChange={handleSelectChange}
                      error={!!formik.errors.areaResponsavel}
                    />
                    {formik.errors.areaResponsavel ? (
                      <Danger>{formik.errors.areaResponsavel}</Danger>
                    ) : null}
                  </div>
                )}

                {!!showTagButton && (
                  <ContainerTagButton>
                    <Button
                      text={getButtonTagLabel()}
                      onClick={handleOpenModalTag}
                      type="button"
                    />
                  </ContainerTagButton>
                )}

                <div></div>
              </Row>

              <Attachments
                type={origem}
                title="Anexos"
                idReferencia={0}
                isDisabled={isFieldDisabled}
                inputId={'anexo_reprovacao'}
                keyReferencia={formik.values.uuid}
              />
            </Content>

            <Footer>
              <Button
                danger={isCancellation}
                primary={!isCancellation}
                text={!isCancellation ? 'Salvar' : 'Cancelar'}
                type="submit"
              />
            </Footer>
          </Form>
        </ModalContainer>
      </Modal>
    </>
  );
}
