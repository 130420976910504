import styled from 'styled-components';
import ButtonComponent from 'components/Button';

export const ModalContainer = styled.div`
  border-radius: ${({ theme }) => theme.radius.medium}px;
  padding: 2rem;
  width: 75vw;
  height: 80vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  height: 70vh;
  overflow: scroll;
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  padding-right: 2rem;
  display: flex;
  justify-content: space-between;
  width: 63rem;
`;

export const SelectAll = styled.span`
  cursor: pointer;
  font-size: 1.4rem;
  float: right;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  align-self: flex-end;
`;

export const Button = styled(ButtonComponent)`
  display: block;
  margin-left: auto;
  margin-top: auto;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.8rem;
`;
