import React, { ReactElement, ButtonHTMLAttributes } from 'react';
import { StyledButton } from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

export default function Button(props: Props): ReactElement {
  const { text } = props;

  return (
    <StyledButton {...props}>
      <span>{text}</span>
    </StyledButton>
  );
}
