import showAlert from 'components/Alert';
import {
  ShowAlertContainer,
  ShowAlertLabel,
  ShowAlertList,
} from 'components/Alert/styles';
import InputFile from 'components/InputFile';
import useContract from 'hooks/use-contract';
import useUser from 'hooks/use-user';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { extractData, ParamsProps } from 'services/access_gate_api';
import { getSqlResponseAdonis } from 'services/dataset';
import { createSupplierAndJob } from 'services/supplier';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { OptionsType } from 'store/types';
import { generateUUID } from 'utils/common';
import { toDateUS } from 'utils/date';
import {
  FormGate,
  normalizeFormGate,
  normalizeFormGateCsv,
  normalizeFormGateKallas,
  readXlsx,
} from 'utils/xlsx';
import { v1 as uuid } from 'uuid';
import { useDynamicForm } from '../../context';
import { RowTitle, TitleWrapper } from '../../styles';
import { Field, FormNewItemModalParams, State } from '../../types';
import {
  checkUserEditPermission,
  getJobsToCreate,
  getSupplierToCreate,
  SuppliersAndJobs,
} from '../../utils';
import {
  AddIcon,
  DeleteAllIcon,
  LoadDataIcon,
  TableStyled,
  Th,
} from '../Table/styles';
import {
  EditIcon,
  TableWrapper,
  TreeviewHeader,
  TreeviewRow,
  TreeviewText,
} from './styles';

interface TreeviewDataProps {
  fornecedor: string;
  funcao: string;
  quantidade: number;
  uuid: string;
}

interface TreeviewProps {
  [key: string]: TreeviewDataProps[];
}

interface Props {
  field: Field;
  setFormAnswer(name: string, value: [] | State[]): void;
  defaultValue: State[];
  dateIniParam?: string;
  isDisabled?: boolean;
}

interface TableData {
  idContrato: number;
  idEmpresa: number;
  dados: JSON;
}

interface LabelsType {
  suppliers: string[];
  jobs: string[];
}

export default function Treeview(props: Props) {
  const [data, setData] = useState<State[]>([]);
  const [treeview, setTreeview] = useState<TreeviewProps>([]);
  const {
    contractId: idContrato,
    approvalData,
    fieldsAllowProps,
  } = useDynamicForm();
  const user = useUser();

  const dispatch = useDispatch();

  const contract = useContract();

  const { addToast } = useToasts();

  const { field, defaultValue, setFormAnswer, isDisabled } = props;

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    if (defaultValue && defaultValue.length) {
      setData(defaultValue);
    } else {
      setData([]);
    }
  }, [defaultValue]);

  useEffect(() => {
    setFormAnswer(field.name, data);
    normalizeTreeview();
  }, [data]);

  function normalizeTreeview() {
    const treeViewObject = {};

    data.forEach(row => {
      treeViewObject[row.fornecedor] = [
        ...(treeViewObject[row.fornecedor] || []),
        row,
      ];
    });

    setTreeview(treeViewObject);
  }

  function handleRemoveAll() {
    showAlert({
      title: 'Atenção!',
      subtitle: 'Deseja remover todos os itens da lista?',
      actions: [
        {
          text: 'Não',
        },
        {
          text: 'Sim',
          onClick: () => setData([]),
        },
      ],
    });
  }

  function handleSave(newData: State) {
    const previousData = data.find(answer => answer.uuid === newData.uuid);

    /* Update data */
    if (previousData) {
      const previousAnswerIndex = data.findIndex(
        answer => answer.uuid === previousData.uuid,
      );

      const newDataToUpdate = [...data];
      newDataToUpdate[previousAnswerIndex] = newData;

      setData(newDataToUpdate);

      return;
    }

    /* Create new data */
    setData([...data, { ...newData, uuid: uuid() }]);
  }

  function handleSave2(newData: State) {
    /* Create new data */
    const normalizedNewData = {
      uuid: uuid(),
      funcao: newData.funcao.label,
      fornecedor: newData.fornecedor.label,
      quantidade: +newData.quantidade,
    };

    setData([...data, normalizedNewData]);
  }

  function handleRemove(answerUuid: string) {
    const confirm = () => {
      const removedAnsers = data.filter(answer => answer.uuid !== answerUuid);
      setData(removedAnsers);
    };

    showAlert({
      title: 'Atenção!',
      subtitle: 'Deseja deletar o registros?',
      actions: [
        {
          text: 'Não',
        },
        {
          text: 'Sim',
          onClick: confirm,
        },
      ],
    });
  }

  async function handleReadXlsx(files: File[]) {
    const jsonXls = await readXlsx(files[0]);

    const tableRes = await getSqlResponseAdonis<TableData>(467, {
      idEmpresa: user!.idEmpresa,
      idContrato,
    });

    const tableDataResponse = tableRes[0]?.dados || {};

    if (tableDataResponse.layoutUploadEfetivo === 2) {
      setData(normalizeFormGateCsv(jsonXls));
    } else if (tableDataResponse.layoutUploadEfetivo === 3) {
      setData(normalizeFormGateKallas(jsonXls));
    } else {
      setData(normalizeFormGate(jsonXls));
    }

    setFormAnswer('xlsFile', files[0]);
  }

  function openFormModal2() {
    dispatch(
      showModal<FormNewItemModalParams>(ModalName.DYNAMIC_FORM_NEW_ITEM, {
        fields: field.fields2,
        tableFieldName: field.name,
        previousData: null,
        onSave: handleSave2,
        onRemove: handleRemove,
        title: field.label,
      }),
    );
  }

  function openFormModal(rowData: State | null) {
    if (isDisableChecked) {
      return;
    }

    dispatch(
      showModal<FormNewItemModalParams>(ModalName.DYNAMIC_FORM_NEW_ITEM, {
        fields: field.fields,
        tableFieldName: field.name,
        previousData: rowData,
        onSave: handleSave,
        onRemove: handleRemove,
        title: field.label,
      }),
    );
  }

  function renderTreeview() {
    if (!Object.keys(treeview).length) {
      return null;
    }

    const sumRoles = (roles: FormGate[]) =>
      roles.reduce((a, b) => a + +b.quantidade, 0);

    return Object.entries(treeview).map(([key, rows]) => (
      <div key={key}>
        <TreeviewHeader>
          <TreeviewText>{key}</TreeviewText>
          <TreeviewText style={{ paddingRight: '.5rem' }}>
            {sumRoles(rows)}
          </TreeviewText>
        </TreeviewHeader>

        {rows.map((row: FormGate) => (
          <TreeviewRow key={row.uuid} onClick={() => openFormModal(row)}>
            <TreeviewText>{row.funcao}</TreeviewText>
            <TreeviewText>{row.quantidade}</TreeviewText>
            {!isDisableChecked && <EditIcon />}
          </TreeviewRow>
        ))}
      </div>
    ));
  }

  async function verifyAndInsertOrUpdateForm() {
    const { dateIniParam } = props;

    const normalizedDateIni = toDateUS(dateIniParam as string);

    const params: ParamsProps = {
      constructionId: contract?.codErp,
      type: 1,
      event: 7,
      beginTime: normalizedDateIni,
    };

    dispatch(showModal(ModalName.LOADING, { text: 'Carregando...' }));

    const newData = (await extractData(params)) || [];

    const newList = (data || []) as SuppliersAndJobs[];

    newData.forEach(({ fornecedor, funcao, quantidade }) => {
      const indexFromDefaultValue = newList.findIndex(
        item => item.fornecedor === fornecedor && item.funcao === funcao,
      );

      if (indexFromDefaultValue >= 0) {
        newList[indexFromDefaultValue] = {
          ...newList[indexFromDefaultValue],
          quantidade,
        };

        return;
      }

      newList.push({
        fornecedor,
        funcao,
        quantidade,
      });
    });

    onHandleValidNewData(newList);
  }

  async function onHandleValidNewData(listToVerify: SuppliersAndJobs[]) {
    const supplierListPromise = getSqlResponseAdonis<OptionsType[]>(330, {
      idContrato,
      idEmpresa: user.idEmpresa,
      idModulo: user.idUserModelo,
      idUsuario: user.idUsuario,
    });

    const jobListPromise = getSqlResponseAdonis<OptionsType[]>(329, {
      idContrato,
      idEmpresa: user.idEmpresa,
      idModulo: user.idUserModelo,
      idUsuario: user.idUsuario,
    });

    const [supplierList, jobList] = await Promise.all([
      supplierListPromise,
      jobListPromise,
    ]);

    const newSuppliersToSave = getSupplierToCreate(supplierList, listToVerify);

    const newJobsToSave = getJobsToCreate(jobList, listToVerify);

    if (!newSuppliersToSave.length && !newJobsToSave.length) {
      const setAllItemsFromList = listToVerify.map(
        ({ uuid, fornecedor, funcao, quantidade }) => ({
          uuid: uuid || generateUUID(),
          fornecedor,
          funcao,
          quantidade,
        }),
      );

      setData(setAllItemsFromList);

      dispatch(closeModal(ModalName.LOADING));

      addToast('Dados atualizados com sucesso.', {
        appearance: 'success',
      });

      return;
    }

    dispatch(closeModal(ModalName.LOADING));

    const labels = {
      suppliers: newSuppliersToSave.map(label => label.fornecedor).sort(),
      jobs: newJobsToSave.map(label => label.funcao).sort(),
    };

    const renderAlertContent = (): ReactElement => {
      return (
        <ShowAlertContainer>
          <h3>
            Deseja inserir os novos itens? <br />
          </h3>
          {labels.suppliers.length ? (
            <div>
              <ShowAlertLabel>Fornecedor:</ShowAlertLabel>
              <ShowAlertList>
                {labels.suppliers.map(supplier => (
                  <li key={supplier}>{supplier}</li>
                ))}
              </ShowAlertList>
            </div>
          ) : null}
          {labels.jobs.length ? (
            <div>
              <ShowAlertLabel>Cargo:</ShowAlertLabel>
              <ShowAlertList>
                {labels.jobs.map(job => (
                  <li key={job}>{job}</li>
                ))}
              </ShowAlertList>
            </div>
          ) : null}
        </ShowAlertContainer>
      );
    };

    showAlert({
      title: 'Atenção!',
      subtitle: renderAlertContent(),
      actions: [
        {
          text: 'Não',
          onClick: () => onHandleSetOnlyItensSaved(listToVerify, labels),
        },
        {
          text: 'Sim',
          onClick: () =>
            onHandleCreateNewData(listToVerify, [
              ...newSuppliersToSave,
              ...newJobsToSave,
            ]),
        },
      ],
    });
  }

  async function onHandleCreateNewData(
    formList: SuppliersAndJobs[],
    newItemListToCreate: SuppliersAndJobs[],
  ) {
    dispatch(showModal(ModalName.LOADING, { text: 'Salvando...' }));

    await createSupplierAndJob(newItemListToCreate);

    const normaizedNewList = formList.map(
      ({ fornecedor, funcao, quantidade }) => ({
        uuid: generateUUID(),
        fornecedor,
        funcao,
        quantidade,
      }),
    );

    dispatch(closeModal(ModalName.LOADING));

    addToast('Itens cadastrados com sucesso.', {
      appearance: 'success',
    });

    setData(normaizedNewList);
  }

  function onHandleSetOnlyItensSaved(
    newList: SuppliersAndJobs[],
    suppliersAndJobsToCreate: LabelsType,
  ) {
    const alredySavesSuppliersAndJobs = newList.filter(
      ({ fornecedor, funcao }) =>
        !suppliersAndJobsToCreate.suppliers.includes(fornecedor) &&
        !suppliersAndJobsToCreate.jobs.includes(funcao),
    );

    setData(alredySavesSuppliersAndJobs);

    addToast('Dados atulizados com sucesso.', {
      appearance: 'success',
    });
  }

  function renderButtons() {
    if (isDisableChecked) {
      return <></>;
    }

    return (
      <>
        <DeleteAllIcon data-tip="Remover todos" onClick={handleRemoveAll} />
        <AddIcon data-tip="Adicionar" onClick={openFormModal2} />
        {field?.allowGetData && (
          <LoadDataIcon
            data-tip="Carregar dados"
            onClick={() => verifyAndInsertOrUpdateForm()}
          />
        )}
      </>
    );
  }

  const total = useMemo(() => data.reduce((a, b) => a + +b.quantidade, 0), [
    data,
  ]);

  return (
    <>
      <TitleWrapper>
        {field.label && (
          <RowTitle dangerouslySetInnerHTML={{ __html: field.label }} />
        )}

        {renderButtons()}
      </TitleWrapper>

      <TableWrapper>
        {field.allowUpload && !isDisableChecked && (
          <InputFile
            id={`input_file_treeview_${field.name}`}
            text="Selecionar arquivo"
            onSelectFiles={handleReadXlsx}
          />
        )}

        <TableStyled>
          <thead>
            <tr>
              <Th size={80}>Empresa</Th>
              <Th size={20} style={{ textAlign: 'right' }}>
                Efetivo: {total}
              </Th>
            </tr>
          </thead>
        </TableStyled>
        {renderTreeview()}
      </TableWrapper>
    </>
  );
}
