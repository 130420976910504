import useOutsideClick from 'hooks/use-outside-click-handle';
import React, { useState, useRef } from 'react';
import { OptionsType } from 'store/types';
import { Container, Button, ArrowIcon, PopupWrapper } from './styles';

interface Props {
  options: OptionsType[];
  onChange(option: OptionsType): void;
  value?: string | number;
}

export default function Dropdown(props: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const componentRef = useRef(null);

  useOutsideClick({
    ref: componentRef,
    state: isOpen,
    onOutsideClicked: () => setIsOpen(false),
  });

  function handleChange(selected: OptionsType) {
    const { onChange } = props;

    onChange(selected);
    setIsOpen(false);
  }

  function renderPopup() {
    const { options } = props;

    return (
      <PopupWrapper>
        <ul>
          {options.map(option => (
            <li key={option.value} onClick={() => handleChange(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      </PopupWrapper>
    );
  }

  const { value } = props;

  return (
    <Container ref={componentRef}>
      <Button onClick={() => setIsOpen(!isOpen)}>
        <span>{value || 'Ações'}</span>
        <ArrowIcon />
      </Button>

      {isOpen && renderPopup()}
    </Container>
  );
}
