import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 94vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: 2.5rem 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.span`
  color: #fff;
  font-size: 1.8rem;
  margin-left: 2rem;
`;
