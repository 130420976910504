import showAlert from 'components/Alert';

export function handleAlertErrorGeneratingDocumentation(confirm: () => void) {
  showAlert({
    title: 'Atenção!',
    subtitle: `Houve um erro ao gerar a documentação. Deseja tentar novamente? Se o problema persistir, entre em contato com o suporte da gescorpGO.`,
    actions: [
      {
        text: 'Tentar novamente',
        onClick: confirm,
      },
    ],
  });
}
