import styled, { css } from 'styled-components';
import ButtonComponent from 'components/Button';

export const WidgetContainer = css`
  width: 100%;
  height: auto;
  position: relative;
  padding: 0;
`;

export const ModalContainer = css`
  width: 100%;
  height: 90vh;
  position: relative;
  background-color: #e9edf0;
  padding: 0 10rem;
  padding-top: 8rem;
`;

export const WrapperContainer = styled.div`
  ${({ widgetStyle }) => (!widgetStyle ? ModalContainer : WidgetContainer)};
`;

export const ActionSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;

  @media print {
    display: none;
  }
`;

export const HeaderContent = styled.div`
  padding: 0 2rem;
  height: 4rem;
  top: 5.3rem;
  left: 0;
  right: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const Container = styled.div`
  padding: ${({ widgetStyle }) => (!widgetStyle ? '2rem' : '0')};
  background-color: #fff;
  min-height: ${({ widgetStyle }) => (!widgetStyle ? '94vh' : 'auto')};
`;

export const Group = styled.div``;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const Col = styled.div<{ size: number }>`
  flex: 0 0 ${({ size }) => size}%;

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const RowTitle = styled.p`
  font-size: 1.5rem;
  flex: 1;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 1rem;
  background-color: #eee;
  padding: 0.5rem;
  border-radius: 2px;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  height: 4rem;
  justify-content: space-between;
`;

export const Label = styled.label<{ isLabel: string }>`
  font-size: 1.4rem;

  &:after {
    display: ${({ isLabel }) => !isLabel && 'none'};
    content: ':';
    position: relative;
  }
`;

export const Button = styled(ButtonComponent)`
  margin: 0 0.5rem;
`;
