import { Field, Formik } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v1 as uuid } from 'uuid';
import { useToasts } from 'react-toast-notifications';
import { create as createUser } from 'services/user';
import { fields } from './fields';
import { FormUser, ModalParams, SetFieldValue } from './types';
import { validation } from './validation';
import CheckBox from 'components/CheckBox';
import SupplierCreatableSelect from 'components/Widgets/SupplierCreatableSelect';
import { getSingleResponse, getSqlResponseAdonis } from 'services/dataset';
import { EnumRoutes, OptionsType } from 'store/types';
import {
  ContainerButton,
  Content,
  FooterButtons,
  StyledForm,
  SubmitButton,
} from './styles';
import UserTemplateSelect from './UserTemplateSelect';
import { store as storeUserProfileTypeContract } from 'services/userProfileTypeContract';
import { store as storeUserReport } from 'services/userReport';
import { saveAreas, saveSupplier } from './utils';
import CatalogSelect from './components/CatalogSelect';
import {
  ButtonClose,
  ModalContainer,
  ModalHeader,
  ModalTitle,
} from 'styles/modal';
import { Label } from 'styles/form';
import Input from 'components/Input';
import { ModalName } from 'store/modal/types';
import { closeModal, showModal } from 'store/modal/actions';
import useModal from 'hooks/use-modal';
import Modal from 'components/Modal';
import { useHistory } from 'react-router';
import { useSetAtom } from 'jotai';
import { userListAtom } from 'pages/ContractAccess/atoms';
import showAlert from 'components/Alert';

const emptyInitialValues = {
  idUsuario: null,
  idEmpresa: null,
  nome: '',
  sobrenome: '',
  email: '',
  telefone: '',
  fornecedores: [],
  previousFornecedores: [],
  area: null,
  previousAreas: null,
  idCargo: null,
  // cargo: '',
  flagStatus: 1,
  cargoSelecionado: null,
  idUserModelo: null,
  uuidUsuario: '',
  uuid: '',
};

export default function CreateUserModal(): ReactElement {
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState<FormUser>(
    emptyInitialValues,
  );

  const [suppliersSelected, setSuppliersSelected] = useState<OptionsType[]>([]);
  const setUserList = useSetAtom(userListAtom);

  const history = useHistory();

  const { isOpen, params, windowSec } = useModal<ModalParams>(
    ModalName.CREATE_USER,
  );
  const { idOrUuidUser, idEmpresa, afterSubmit } = params || {};
  const { addToast } = useToasts();

  useEffect(() => {
    if (!isOpen) {
      setInitialValues(emptyInitialValues);
      setSuppliersSelected([]);
    }

    getData();
  }, [isOpen, idOrUuidUser]);

  async function getData() {
    if (!idOrUuidUser) {
      return;
    }

    dispatch(showModal(ModalName.LOADING, { text: 'Carregando...' }));

    const user = await getSingleResponse<FormUser>(1262, { idOrUuidUser });

    const fetchedSuppliersSelectedPromise = getSqlResponseAdonis<OptionsType[]>(
      1099,
      {
        idUsuario: user.idUsuario,
        idEmpresa: user.idEmpresa,
      },
    );

    const fetcheAreasPromise = getSqlResponseAdonis<OptionsType[]>(2723, {
      uuidUsuario: user.uuid,
      idEmpresa: user.idEmpresa,
    });

    const [fetchedSuppliersSelected, fetcheAreas] = await Promise.all([
      fetchedSuppliersSelectedPromise,
      fetcheAreasPromise,
    ]);

    setInitialValues({
      ...user,
      fornecedores: fetchedSuppliersSelected,
      previousFornecedores: fetchedSuppliersSelected,
      area: fetcheAreas,
      previousAreas: fetcheAreas,
    });

    setSuppliersSelected(fetchedSuppliersSelected);

    dispatch(closeModal(ModalName.LOADING));
  }

  function handleUserManagement(userInfo) {
    setUserList([
      {
        idUsuario: userInfo.idUsuario,
        nome: userInfo.nome,
      },
    ]);
    history.push(EnumRoutes.CONTRACT_MANAGEMENT);
  }

  async function submit(
    values: FormUser,
    setSubmitting: (isSubmitting: boolean) => void,
    isRedirect = false,
  ) {
    const uuidUsuario: string = values.uuid || uuid();
    const userData = { ...values, idEmpresa, uuidUsuario };

    let newUserData = userData;

    if (setSubmitting) {
      setSubmitting(true);
    }

    dispatch(showModal(ModalName.LOADING, { text: 'Salvando...' }));

    try {
      const {
        nome,
        sobrenome,
        idEmpresa,
        email,
        idUserModelo,
        telefone,
        flagStatus,
      } = userData;

      const paramsToSave = {
        nome,
        sobrenome,
        idEmpresa,
        email,
        idUserModelo,
        telefone,
        flagStatus,
        uuid: uuidUsuario,
        flagRecarregarAcessos:
          initialValues.idUserModelo !== userData.idUserModelo ? 1 : 0,
      };

      newUserData = await createUser(paramsToSave);

      addToast(
        idOrUuidUser
          ? 'Alterações salvas com sucesso!'
          : 'Usuário criado com sucesso!',
        { appearance: 'success' },
      );

      setInitialValues(emptyInitialValues);
      dispatch(closeModal(ModalName.CREATE_USER));

      if (afterSubmit) {
        afterSubmit(newUserData);
      }

      setSubmitting(false);
    } catch (error) {
      addToast(error.data.message, { appearance: 'error' });

      return dispatch(closeModal(ModalName.LOADING));
    }

    if (userData.idModelo !== initialValues?.idUserModelo) {
      const resContractPromise = storeUserProfileTypeContract(newUserData);
      const resReportPromise = storeUserReport(newUserData);

      await Promise.all([resContractPromise, resReportPromise]);
    }

    const resSupplierPromise = saveSupplier(
      values,
      newUserData.idUsuario!,
      idEmpresa!,
    );
    const resAreasPromise = saveAreas(values, uuidUsuario, idEmpresa!);

    await Promise.all([resSupplierPromise, resAreasPromise]);

    dispatch(closeModal(ModalName.LOADING));

    if (isRedirect) {
      return handleUserManagement(newUserData);
    }
  }

  function handleSetSupplier(
    selectedSupplier: OptionsType[] | OptionsType | null,
    setFieldValue: SetFieldValue,
  ) {
    if (!selectedSupplier) {
      setFieldValue('fornecedores', []);
      setSuppliersSelected([] as OptionsType[]);

      return;
    }

    const newSelectedSupplier = Array.isArray(selectedSupplier)
      ? selectedSupplier
      : [selectedSupplier];

    const normalizedSelectedSuppliers = newSelectedSupplier?.map(
      ({ value }) => ({
        idFornecedor: value,
        flagStatus: 1,
      }),
    );

    setFieldValue('fornecedores', normalizedSelectedSuppliers || []);
    setSuppliersSelected(selectedSupplier as OptionsType[]);
  }

  function handleRedirectUserManagement(values: FormUser) {
    showAlert({
      title: 'Atenção!',
      subtitle:
        'O formulario precisa ser salvo antes de mudar de pagina. Se clicar em "Continuar", o formulario sera salvo automaticamente e ira de redirecionar para tela de empreendimento!',
      actions: [
        {
          text: 'Cancelar',
        },
        {
          text: 'Continuar',
          onClick: () => submit(values, () => {}, true),
        },
      ],
    });
  }

  return (
    <Modal isOpen={isOpen} windowSec={windowSec}>
      <ModalHeader>
        <ButtonClose
          onClick={() => dispatch(closeModal(ModalName.CREATE_USER))}
        />
        <ModalTitle>
          {idOrUuidUser ? 'Editar usuário' : 'Criar usuário'}
        </ModalTitle>
      </ModalHeader>
      <ModalContainer height="70vh" width="60rem">
        <Formik
          initialValues={initialValues}
          onSubmit={(data, { setSubmitting }) => submit(data, setSubmitting)}
          validationSchema={validation}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize
        >
          {({ errors, isSubmitting, setFieldValue, values }) => (
            <StyledForm>
              <Content>
                <Label>Perfil</Label>

                <Field
                  as={UserTemplateSelect}
                  idEmpresa={idEmpresa || initialValues.idEmpresa}
                  initialUserTemplateId={initialValues.idUserModelo}
                  name="modelo"
                  setTemplate={selectedOption => {
                    setFieldValue('idUserModelo', selectedOption);
                  }}
                />

                <Field
                  as={SupplierCreatableSelect}
                  isMulti
                  isClearable={false}
                  supplier={suppliersSelected}
                  setSupplier={selectedSupplier =>
                    handleSetSupplier(selectedSupplier, setFieldValue)
                  }
                  name="fornecedores"
                  datasetId={564}
                  companyId={values.idEmpresa}
                />

                <CatalogSelect values={values} setFieldValue={setFieldValue} />

                {fields.map(field => (
                  <Field
                    key={field.name}
                    name={field.name}
                    as={Input}
                    {...(field.onChange
                      ? {
                          onChange: e => {
                            field.onChange!(e, setFieldValue);
                          },
                        }
                      : {})}
                    type={field.type}
                    label={field.label}
                    errorMessage={errors[field.name]}
                    mask={field.mask}
                  />
                ))}
              </Content>

              <FooterButtons>
                {idOrUuidUser && (
                  <CheckBox
                    id="checkbox"
                    label="Desabilitar/Habilitar"
                    checked={!!values.flagStatus}
                    onChange={() => {
                      const normalizedValue = values.flagStatus === 1 ? 0 : 1;

                      setFieldValue('flagStatus', normalizedValue);
                    }}
                  />
                )}

                <ContainerButton>
                  {initialValues.idUserModelo && (
                    <SubmitButton
                      text="Associar empreendimento"
                      type="button"
                      onClick={() => handleRedirectUserManagement(values)}
                    />
                  )}
                  <SubmitButton
                    text="Salvar"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    primary
                    type="submit"
                  />
                </ContainerButton>
              </FooterButtons>
            </StyledForm>
          )}
        </Formik>
      </ModalContainer>
    </Modal>
  );
}
