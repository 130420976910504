import Button from 'components/Button';
import useModal from 'hooks/use-modal';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import {
  ButtonClose,
  ModalContainer,
  ModalHeader,
  ModalTitle,
} from 'styles/modal';
import { Footer } from './styles';
import Modal from 'components/Modal';
import { useToasts } from 'react-toast-notifications';
import TextArea from 'components/TextArea';

export interface FormCatalogtModalParams {
  title: string;
  onAfterSave(area: string): void;
}

interface FormData {
  id?: number;
  uuid?: string;
  description: string;
  value?: string;
}

export default function FormCatalogModal() {
  const [formData, setFormData] = useState({} as FormData);

  const { isOpen, params, windowSec } = useModal<FormCatalogtModalParams>(
    ModalName.FORM_CATALOG,
  );

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { title } = params;

  useEffect(() => {
    if (!isOpen) {
      setFormData({} as FormData);
    }
  }, [isOpen]);

  function handleClose() {
    dispatch(closeModal(ModalName.FORM_CATALOG));
  }

  async function handleSave() {
    if (!formData.description?.length) {
      return addToast('Campo descrição obrigatório');
    }

    dispatch(showModal(ModalName.LOADING, { text: `Salvando ${title}` }));

    const { onAfterSave } = params;

    await onAfterSave(formData.description);

    dispatch(closeModal(ModalName.FORM_CATALOG));
    dispatch(closeModal(ModalName.LOADING));
  }

  return (
    <Modal isOpen={isOpen} windowSec={windowSec}>
      <ModalHeader>
        <ButtonClose onClick={handleClose} />
        <ModalTitle>{title || 'Catalogo'}</ModalTitle>
      </ModalHeader>

      <ModalContainer width="35vw" height="auto">
        <TextArea
          label="Descrição"
          value={formData.description}
          placeholder={'Descrição'}
          onChange={e =>
            setFormData({ ...formData, description: e.target.value })
          }
        />

        {/* <CurrencyInput
          label="Valor"
          value={formData.value}
          onValueChange={value => setFormData({ ...formData, value })}
          allowNegativeValue={false}
          decimalSeparator=","
          groupSeparator="."
        /> */}

        <Footer>
          {/* {formData[primaryKey] ? (
            <Button danger text="Remover" onClick={handleRemove} />
          ) : (
            <div />
          )} */}
          <div />
          <Button primary text="Salvar" onClick={handleSave} />
        </Footer>
      </ModalContainer>
    </Modal>
  );
}
