import { toPhone } from 'utils/formaters';
import { phoneNumberMask } from 'utils/forms/masks';

export const fields = [
  {
    name: 'nome',
    label: 'Nome',
    placeholder: 'Insira o nome do usuário',
    type: 'text',
  },
  {
    name: 'sobrenome',
    label: 'Sobrenome',
    placeholder: 'Insira sobrenome o do usuário',
    type: 'text',
  },
  {
    name: 'email',
    label: 'E-mail',
    placeholder: 'Insira e-mail o do usuário',
    type: 'email',
  },
  {
    name: 'telefone',
    label: 'Telefone',
    placeholder: 'Insira o telefone do usuário',
    type: 'text',
    mask: phoneNumberMask,
    onChange: (e, setFieldValue) => {
      setFieldValue('telefone', toPhone(e.target.value));
    },
  },
];
