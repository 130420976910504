import React from 'react';
import {
  RiEmotionHappyLine,
  RiEmotionNormalLine,
  RiEmotionUnhappyLine,
} from 'react-icons/ri';

interface ClassificationProps {
  value: number;
}

export default function Classification(props: ClassificationProps) {
  const { value } = props;

  if (value >= 90) {
    return (
      <>
        <RiEmotionHappyLine fontSize={'1.5rem'} color="#0f0" />
        <RiEmotionHappyLine fontSize={'1.5rem'} color="#0f0" />
      </>
    );
  }

  if (value >= 70) {
    return <RiEmotionHappyLine fontSize={'1.5rem'} color="#00f" />;
  }

  if (value >= 50) {
    return <RiEmotionNormalLine fontSize={'1.5rem'} color="#ffa500" />;
  }

  if (value >= 25) {
    return <RiEmotionUnhappyLine fontSize={'1.5rem'} color="#f00" />;
  }

  return (
    <>
      <RiEmotionUnhappyLine fontSize={'1.5rem'} color="#f00" />
      <RiEmotionUnhappyLine fontSize={'1.5rem'} color="#f00" />
    </>
  );
}
