import { Address, Supplier } from '../store/types';
import { ExtractDataParams } from './access_gate_api';
import api from './api';
import apiAdonis from './apiAdonis';

export function create(supplier: Supplier): Promise<Supplier> {
  return api.post(`/fornecedores/add-new.json`, [supplier]);
}

export function store(supplier: Supplier): Promise<Supplier> {
  return api.post(`/fornecedores/store`, [supplier]);
}

interface SaveAddressProps {
  endereco: Address;
  idFornecedor: number;
}

export function saveAddress(data: SaveAddressProps): Promise<void> {
  return api.post(`/fornecedores/save-address.json`, data);
}

export function get(id: number): Promise<Supplier> {
  return api.get(`/fornecedores/get/${id}.json`);
}

export function update(supplier: Supplier): Promise<Supplier> {
  return api.post(`/fornecedores/update`, supplier);
}

export function createSupplierAndJob(payload: ExtractDataParams[]) {
  return apiAdonis.post(`/fornecedor/create-fornecedor-cargo`, payload);
}
