import useUser from 'hooks/use-user';
import { useDynamicForm } from 'components/Widgets/DynamicForm/context';
import React from 'react';
import { FormApproval } from 'store/types';
import { LabelInfo, ValueInfo } from 'styles/form';
import { removeUtc } from 'utils/date';
import { normalizeFileUrl } from 'utils/file';
import { Container, Row, Image } from './styles';

interface Props {
  approvalData: FormApproval | null;
}

export default function HeaderInfo(props: Props) {
  const { approvalData } = props;

  const { globalState } = useDynamicForm();

  const user = useUser();

  function renderApprovalData() {
    if (
      !approvalData?.usuarioAprovacao ||
      globalState?.templateConfig.templatePortal?.allowMultipleApproval
    ) {
      return null;
    }

    return (
      <Row>
        <div>
          <LabelInfo>Aprovador</LabelInfo>
          <ValueInfo>{approvalData?.usuarioAprovacao}</ValueInfo>
        </div>
        <div style={{ position: 'relative', right: '15px' }}>
          <LabelInfo>Data de aprovação</LabelInfo>
          <ValueInfo>
            {removeUtc(approvalData?.dtAprovacao)?.format(
              'DD/MM/YYYY HH:mm:ss',
            )}
          </ValueInfo>
        </div>

        <div style={{ position: 'relative', right: '30px' }}>
          <LabelInfo>Status</LabelInfo>
          <ValueInfo>Aprovado</ValueInfo>
        </div>
      </Row>
    );
  }

  if (!approvalData) {
    return <></>;
  }

  return (
    <Container>
      <Row>
        <div>
          <LabelInfo>Criado por</LabelInfo>
          <ValueInfo>{approvalData?.usuarioCriacao}</ValueInfo>
        </div>
        <div>
          <LabelInfo>Data de criação</LabelInfo>
          <ValueInfo>
            {removeUtc(approvalData?.dtCriacao)?.format('DD/MM/YYYY HH:mm:ss')}
          </ValueInfo>
        </div>

        <div style={{ flex: '0 0 10rem', position: 'relative' }}>
          <Image
            src={normalizeFileUrl(
              `/datafiles/logos/empresa/${user.idEmpresa}.png`,
            )}
            alt="Logo"
          />
        </div>
      </Row>

      {renderApprovalData()}
    </Container>
  );
}
