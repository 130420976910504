import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import SentImage from '../../assets/sent.png';
import Button from '../../components/Button';
import { forgotPassword } from '../../services/auth';
import { Container, Form, H1, Sent } from './styles';

export default function ResetPassword(): ReactElement {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { addToast } = useToasts();

  useEffect(() => {
    const { location } = history;
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, []);

  async function handleRecover(e: FormEvent) {
    e.preventDefault();

    if (!email) {
      addToast('Email obrigatório', { appearance: 'warning' });

      return;
    }

    setLoading(true);

    let fromPortal = false;
    const { location } = history;

    if (location.state && location.state.fromPortal) {
      fromPortal = location.state.fromPortal;
    }

    const { errors } = await forgotPassword({
      email,
      fromPortal: Number(fromPortal),
    });

    if (errors && errors.length) {
      addToast(errors[0], { appearance: 'warning' });
    } else {
      setEmailSent(true);
    }

    setLoading(false);
  }

  if (emailSent) {
    return (
      <Container>
        <Form>
          <Sent src={SentImage} />
          <p style={{ fontSize: '1.6rem' }}>
            Um email foi enviado para o endereço informado com as instruções
            para redefinir sua senha.
          </p>
        </Form>
      </Container>
    );
  }

  function verifyEmail(email: string) {
    return email.match(/@tenda/gi);
  }

  function notAllowedRecovery(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    return false;
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    if (!verifyEmail(email)) {
      handleRecover(event);
    } else {
      notAllowedRecovery(event);
    }
  }

  return (
    <>
      <Container>
        <Form onSubmit={handleSubmit}>
          <H1>Por favor, informe seu email </H1>

          <input
            value={email}
            type="email"
            placeholder="Informe seu email"
            onChange={e => setEmail(e.target.value)}
          />

          <Button
            primary
            text="Recuperar"
            isLoading={loading}
            type="submit"
            style={{
              cursor: `${verifyEmail(email) ? 'not-allowed' : 'pointer'}`,
              background: `${verifyEmail(email) ? '#ccc' : ''}`,
            }}
          />
        </Form>
      </Container>
    </>
  );
}
