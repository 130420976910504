import api from './api';
import { Comment } from '../store/types';
import apiAdonis from './apiAdonis';

export function addAndSetPhoto(comment: Comment): Promise<Comment> {
  return api.post('/comentarios/add_and_set_photo.json', comment);
}

export function add(comment: Comment): Promise<Comment[]> {
  return api.post('/comentarios/add.json', { comentarios: [comment] });
}

export function addNew(comment: Comment): Promise<Comment[]> {
  return api.post('/comentarios/add-new.json', [comment]);
}

export function editNew(comment: Comment): Promise<Comment[]> {
  return api.post('/comentarios/edit-new.json', [comment]);
}

export function addToSubservico(comment: Comment) {
  return api.post('/comentarios/add_to_subservico', comment);
}

export function updateFromSubservico(comment: Comment) {
  return api.put('/comentarios/update_from_subservico', comment);
}

export function deleteFromSubservico(commentId: number) {
  return api.delete(`/comentarios/delete_from_subservico/${commentId}`);
}

export function update(comment: Comment) {
  return api.post('/comentarios/edit.json', { comentarios: [comment] });
}

export function remove(commentId: number) {
  return api.post('/comentarios/delete.json', {
    // eslint-disable-next-line @typescript-eslint/camelcase
    comentarios: [{ id_comentario: commentId }],
  });
}

export function updateOrCreate(data: Comment) {
  return apiAdonis.post(`/comentario/update-or-create`, data);
}
