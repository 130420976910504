import styled from 'styled-components';
import { IoIosLogOut } from 'react-icons/io';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdSupportAgent } from 'react-icons/md';
import { RiLogoutBoxRLine } from 'react-icons/ri';

export const Container = styled.div`
  margin-bottom: 5.3rem;

  @media only screen and (max-width: 960px) {
    margin-bottom: 3.8rem;
  }
`;

export const StyledNavBar = styled.nav`
  position: fixed;
  width: 100%;
  height: 5.3rem;
  padding: 0 2rem;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.primary};
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 960px) {
    height: 3.8rem;
    padding: 0 1rem;
  }

  button {
    font-size: 1.7rem;
    color: #fff;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.primary};
    border: none;

    &:focus {
      outline: none;
    }

    @media only screen and (max-width: 960px) {
      font-size: inherit;
    }
  }
`;

export const LogoMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LogOutIcon = styled(RiLogoutBoxRLine)`
  font-size: 2.3rem;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }
`;

export const SupportIcon = styled(MdSupportAgent)`
  font-size: 2.3rem;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }
`;

export const ContainerIcon = styled.div`
  display: flex;

  gap: 1.4rem;

  .notification {
    position: relative;

    span {
      position: absolute;
      top: 0;
      right: 0;
      width: 0.8rem;
      height: 0.8rem;
      background-color: ${({ isPendingSupport }) =>
        isPendingSupport ? '#ff6b6b' : 'transparent'};
      border-radius: 50%;
    }
  }
`;

export const MenuIcon = styled(GiHamburgerMenu)`
  font-size: 2.3rem;
  margin-right: 2rem;

  @media only screen and (max-width: 960px) {
    font-size: 2rem;
    margin-left: 1rem;
  }
`;

export const NavLogoImg = styled.img`
  width: 16rem;
  height: 2rem;

  @media only screen and (max-width: 960px) {
    width: 2.3rem;
    height: 2.2rem;
  }
`;

export const ContractName = styled.span`
  color: #fff;
  font-size: 1.6rem;
  padding-right: 2rem;
`;
export const RightContent = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleWrapper = styled.div<{ size: number }>`
  position: absolute;
  width: calc(100vw - 4rem);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  text-transform: uppercase;

  @media only screen and (max-width: 960px) {
    position: relative;
  }

  span {
    color: #fff;
    font-size: 2.2rem;

    @media only screen and (max-width: 960px) {
      font-size: 1.6rem;
    }
  }
`;

export const WarningModeText = styled.span`
  position: relative;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
  background-color: #eb4034;
  padding: 2px 0.5rem 0;
  margin-left: 1rem;
  white-space: nowrap;
  @media only screen and (max-width: 960px) {
    font-size: 1rem;
  }

  &:hover > div {
    display: block;
  }
`;

export const ImpersonateInfoContainer = styled.div``;

export const UserInfo = styled.div`
  display: none;
  position: absolute;
  margin-top: 3px;
  padding: 10px;
  background-color: black;
  left: 7rem;
  width: fit-content;
  font-size: 11px;
  font-weight: 400;

  p:first-child {
    color: white;
    font-size: 12px;
  }

  p {
    color: #c4c4c4;
  }
`;
