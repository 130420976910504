import Button from 'components/Button';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  currentStepAtom,
  formDataAtom,
  handleSaveSignatureAtom,
  urlParamsAtom,
} from 'pages/DocSign/atoms';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import PictureConfirmModal from './components/Modals/PictureConfirmModal';
import { storeApp, upload } from 'services/photo';
import { generateUUID } from 'utils/common';
import { getUtcDatetime } from 'utils/date';
import { useToasts } from 'react-toast-notifications';
import {
  ButtonPicture,
  Cam,
  Canvas,
  Container,
  IconCam,
  Footer,
  WrapperCam,
} from './styles';
import { CancelIcon, GoBackIcon } from 'pages/DocSign/styles';
import { EnumStep } from 'pages/DocSign/types';
import { handleConfirmSignCancel, isSourceApp } from 'pages/DocSign/utils';
import { captureFrameFromVideo, initCam, stopCam } from './utils';

export default function OpenCam() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [formData, setFormData] = useAtom(formDataAtom);

  const urlParams = useAtomValue(urlParamsAtom);

  const handleSaveSignature = useSetAtom(handleSaveSignatureAtom);
  const setCurrentStep = useSetAtom(currentStepAtom);

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const uuidImage = useRef(generateUUID());

  useEffect(() => {
    if (videoRef) {
      initCam(videoRef);
    }
  }, []);

  function handleSave() {
    let imageFile;

    if (videoRef.current && canvasRef.current) {
      imageFile = captureFrameFromVideo(videoRef, canvasRef, uuidImage.current);
    }

    setFormData({ ...formData, imgSignature: imageFile });

    const urlPreviewImg = URL.createObjectURL(imageFile);

    renderConfirmationModal(urlPreviewImg, imageFile);
  }

  async function handleConfirmPicture(confirmPicture, imgFile: File) {
    dispatch(showModal(ModalName.LOADING, { text: 'Salvando foto...' }));

    if (confirmPicture && imgFile) {
      const formDataToSend = new FormData();

      formDataToSend.append('image', imgFile);

      const refImg = await upload(formDataToSend);

      await storeApp([
        {
          referencia: refImg.referencia,
          uuid: uuidImage.current,
          uuidReferencia: formData!.uuid,
          idContrato: urlParams!.idContrato || 0,
          tipo: 'ASSINATURA_SELFIE',
          id: 1,
          idUsuario: 0,
          dtCriacao: getUtcDatetime(),
        },
      ]);

      if (handleSaveSignature) {
        // TODO trycatch
        dispatch(
          showModal(ModalName.LOADING, { text: 'Salvando assinatura...' }),
        );

        await handleSaveSignature();
        setCurrentStep(EnumStep.SUBSCRIBER_LIST);

        dispatch(closeModal(ModalName.LOADING));
        addToast('Assinatura salva com sucesso.', { appearance: 'success' });
      }
    }

    dispatch(closeModal(ModalName.LOADING));
  }

  function handleRedirectCancelPage() {
    if (isSourceApp(urlParams)) {
      setCurrentStep(EnumStep.SUBSCRIBER_LIST);

      addToast('Assinatura cancelada .', {
        appearance: 'success',
      });

      return;
    }

    setCurrentStep(EnumStep.REGISTER_SUBSCRIBERS_CANCEL);
  }

  function renderConfirmationModal(urlPreviewImg, imageFile) {
    stopCam(videoRef);

    dispatch(
      showModal(ModalName.PICTURE_CONFIRM_MODAL, {
        urlPreviewImg: urlPreviewImg,
        imageFile: imageFile,
        onConfirm: handleConfirmPicture,
        onRetry: initCam,
      }),
    );
  }

  function handleClick(page: number) {
    stopCam(videoRef);
    setCurrentStep(page);
  }

  return (
    <Container>
      <div style={{ flex: 1 }}>
        <WrapperCam>
          <Cam ref={videoRef} autoPlay playsInline muted />
        </WrapperCam>
      </div>

      <Canvas ref={canvasRef} />

      <ButtonPicture
        onClick={() => handleSave()}
        text="Registrar foto"
        primary
        icon={<IconCam />}
      />

      <Footer>
        <Button
          onClick={() => handleClick(EnumStep.SIGNATURE_PAGE)}
          text="Voltar"
          icon={<GoBackIcon />}
          small
        />
        <Button
          onClick={() => handleConfirmSignCancel(handleRedirectCancelPage)}
          text="Cancelar"
          icon={<CancelIcon />}
          small
        />
      </Footer>
      <PictureConfirmModal />
    </Container>
  );
}
