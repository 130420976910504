import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showModal, closeModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { getFileImg } from 'utils/file';
import { EnumFileType } from 'store/types';
import { Button, StyleXlsImg } from './styles';
import { generateUUID } from 'utils/common';
import { setReportQueue } from 'store/report/actions';
import { create as createReportQueue } from 'services/report_queue';
import useContract from 'hooks/use-contract';
import useUser from 'hooks/use-user';

export interface Props {
  datasetId?: number;
  idDataset?: number;
  text?: string;
  iconButton?: boolean;
  showModalMessage?: boolean;
  small?: boolean;
  tiny?: boolean;
  fileName?: string;
  params: { [key: string]: string | number | undefined };
}

export default function ExportDatasetXls(props: Props) {
  const {
    datasetId,
    idDataset,
    params,
    fileName,
    text,
    iconButton,
    showModalMessage,
    small,
    tiny,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const contract = useContract();
  const user = useUser();

  const dispatch = useDispatch();

  async function handleExport() {
    if (showModalMessage) {
      dispatch(
        showModal(ModalName.LOADING, { text: 'Preparando Relatório ...' }),
      );
    } else {
      setIsLoading(true);
    }

    const reportUUID = generateUUID();

    const nome = fileName || '';

    dispatch(
      setReportQueue({
        nome,
        uuid: reportUUID,
        status: 0,
      }),
    );

    await createReportQueue({
      nome,
      uuid: reportUUID,
      idDataset: idDataset || datasetId,
      parametros: {
        params: params,
        idDataset: idDataset || datasetId,
      },
      idContrato: contract?.idContrato || 0,
      idEmpresa: user?.idEmpresa || 0,
      tipoRelatorio: 'dataset-xlsx',
    });

    if (showModalMessage) {
      dispatch(closeModal(ModalName.LOADING));
    } else {
      setIsLoading(false);
    }
  }

  if (iconButton) {
    return (
      <StyleXlsImg
        small={small}
        tiny={tiny}
        src={getFileImg(EnumFileType.XLSX)}
        onClick={handleExport}
        style={{ cursor: 'pointer' }}
      />
    );
  }

  return (
    <Button
      small={small}
      tiny={tiny}
      isLoading={isLoading}
      onClick={handleExport}
    >
      <StyleXlsImg
        small={small}
        tiny={tiny}
        src={getFileImg(EnumFileType.XLSX)}
      />
      <span>{text}</span>
    </Button>
  );
}
