import React, { InputHTMLAttributes } from 'react';
import { Container } from './styles';
import { CheckedIcon } from 'styles/icons';
import { Label } from 'styles/form';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export default function CheckBox(props: Props) {
  const { label, onChange, checked } = props;

  return (
    <Container onClick={onChange}>
      <CheckedIcon checked={checked} />
      <Label>{label}</Label>
    </Container>
  );
}
