import React, { ReactElement } from 'react';
import { formatNumber } from '../../../../../../utils/dataFunctions';
import { StyledDiv } from './styles';

interface Props {
  value: any;
  row: any;
  col: any;
}

const percentFormatter = formatNumber({ style: 'percent', decimalPlaces: 2 });

export default function BarChart(props: Props): ReactElement {
  const {
    value: valueRaw,
    col: { cellTypeParams },
  } = props;

  if (!valueRaw) {
    return <></>;
  }
  const parsedValue = JSON.parse(valueRaw);
  let value = 0;
  let label = '';

  if (typeof parsedValue === 'object') {
    value = parseFloat(parsedValue.value);
    label = cellTypeParams?.hideLabel ? '' : parsedValue.label;
  } else {
    value = parseFloat(parsedValue);
    label = percentFormatter(cellTypeParams?.hideLabel ? '' : parsedValue);
  }

  const widthFactor = value < 0 ? -1 : 1;
  // const barColor = value < 0 ? 'red' : 'green';
  // const barWidth = widthFactor * value * 50;

  var barWidth;
  var barStyle;
  var labelStyle = {};

  // console.log("cellTypesParams: ", cellTypeParams);
  // console.log("cellTypesParams.hasRange: ", cellTypeParams?.hasRange);

  if (cellTypeParams !== undefined) {
    barWidth = cellTypeParams?.hasRange && Math.abs(value / cellTypeParams.range) * cellTypeParams.maxSize;

    const { barColors: { negative, positive } } = cellTypeParams; //new
    const negativeColor = negative; // new
    const positiveColor = positive; // new
    const barColor = value < 0 ? positiveColor : negativeColor; // new

    barStyle = {
      height: `${cellTypeParams.height ? cellTypeParams.height : ''}`,
      width: `${cellTypeParams.hasRange !== undefined ? (barWidth + 'px') : (barWidth + '%')}`,
      // width: `${barWidth}%`,
      backgroundColor: barColor,
    };

    if (value > 0) {
      barStyle.right = '50%';
      labelStyle.left = '50%';
      labelStyle.paddingLeft = '3px';
    } else {
      barStyle.left = '50%';
      labelStyle.right = '50%';
      labelStyle.paddingRight = '3px';
    }
  } else {
    barWidth = widthFactor * value * 50;

    const barColor = value < 0 ? 'red' : 'green';

    barStyle = {
      width: `${barWidth}%`,
      backgroundColor: barColor,
    };

    if (value < 0) {
      barStyle.right = '50%';
      labelStyle.left = '50%';
      labelStyle.paddingLeft = '3px';
    } else {
      barStyle.left = '50%';
      labelStyle.right = '50%';
      labelStyle.paddingRight = '3px';
    }
  }
  

  // if(cellTypeParams.invertBar !== undefined) {
  //   if (value > 0) {
  //     barStyle.right = '50%';
  //     labelStyle.left = '50%';
  //     labelStyle.paddingLeft = '3px';
  //   } else {
  //     barStyle.left = '50%';
  //     labelStyle.right = '50%';
  //     labelStyle.paddingRight = '3px';
  //   }
  // } else {
  // if (value < 0) {
  //   barStyle.right = '50%';
  //   labelStyle.left = '50%';
  //   labelStyle.paddingLeft = '3px';
  // } else {
  //   barStyle.left = '50%';
  //   labelStyle.right = '50%';
  //   labelStyle.paddingRight = '3px';
  // }
  // }

  const content = (
    <>
      <div className="separator" />
      <div className="bar" style={barStyle} />
      <span className="label" style={labelStyle}>
        {label}
      </span>
    </>
  );

  // const content = 1;

  return <StyledDiv className="celltype-barchart">{content}</StyledDiv>;
}
