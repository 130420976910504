import { SheetState, SheetActionTypes, SET_SHEET, CLEAR_SHEET } from './types';

const initialState: SheetState = {
  sheet: null,
};

export default (state = initialState, action: SheetActionTypes): SheetState => {
  switch (action.type) {
    case SET_SHEET:
      return {
        sheet: action.payload,
      };
    case CLEAR_SHEET:
      return {
        sheet: null,
      };
    default:
      return state;
  }
};
