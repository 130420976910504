import { Text } from 'styles/text';
import styled from 'styled-components';
import SpinnerComponent from 'components/Spinner';

export const Container = styled.div`
  margin-top: 1rem;

  h2 {
    margin-bottom: 0.5rem;
  }
`;

export const Card = styled.div`
  padding: 0 0.8rem;
  height: auto;
  margin-bottom: 0.5rem;
  background-color: #fafafa;
  border-radius: ${({ theme }) => theme.radius.medium}px;
  display: flex;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const DateText = styled(Text)`
  font-size: 1.2rem;
  margin-right: 0.5rem;
  color: #333;
  font-style: italic;
`;

export const Col = styled.div`
  flex: 1;
`;

export const Spinner = styled(SpinnerComponent)`
  display: block;
  margin: 0 auto;
`;
