import React from 'react';
import {
  PopupActions as PopupActionsType,
  PopoupActionsShow,
} from '../../store/types';
import {
  ActionButton,
  ActionText,
  ActionWrapper,
  Container,
  Edit,
  IconCheckAll,
  IconTrash,
} from './styles';

interface Props {
  popUpConfig: PopoupActionsShow;
  actions?: PopupActionsType[];
  fixed?: boolean;
}

export default function PopupActions(props: Props) {
  const { popUpConfig, actions, fixed } = props;
  if (!popUpConfig.isVisible) {
    return null;
  }

  const position: { left?: number; top?: number; right?: number } = {};

  if (popUpConfig.clientX) {
    position.left = popUpConfig.clientX;
  }

  if (popUpConfig.right) {
    position.right = popUpConfig.right;
  }

  if (popUpConfig.clientY) {
    position.top = popUpConfig.clientY;
  }

  function renderIcon(action: PopupActionsType) {
    if (action.iconComponent) {
      return action.iconComponent;
    }

    switch (action.icon) {
      case 'IconTrash':
        return <IconTrash />;
      case 'Edit':
        return <Edit />;
      default:
        return <IconCheckAll />;
    }
  }

  return (
    <Container fixed={fixed} style={position} {...props}>
      {(actions || popUpConfig.actions || [])
        .filter(action => action.visible)
        .map(action => (
          <ActionWrapper key={action.text}>
            <ActionButton
              type="button"
              onClick={() => action.onClick(action.type)}
            >
              {renderIcon(action)}
              <ActionText>{action.text}</ActionText>
            </ActionButton>
          </ActionWrapper>
        ))}
    </Container>
  );
}
