export const SET_SLICER_SELECTION = 'slicer/SET_SLICER_SELECTION';

export interface SlicerState {
  slicerSelection: object;
  lastSlicerSelection: object;
  replacementString: string;
}

interface SetSlicerSelection {
  type: typeof SET_SLICER_SELECTION;
  payload: object;
}

export type SlicerActionTypes = SetSlicerSelection;
