import SimpleTableComponent from 'pages/Reports/components/tables/SimpleTable';
import styled from 'styled-components';
import { ModalContainer as ModalContainerComponent } from 'styles/modal';

export const SimpleTable = styled(SimpleTableComponent)`
  height: 100%;
  overflow-y: scroll;
`;

export const Content = styled.div`
  overflow-y: scroll;
  flex: 1;
`;

export const ModalContainer = styled(ModalContainerComponent)`
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
`;

export const DropdownWrapper = styled.div`
  z-index: 103;
`;

export const SelectWrapper = styled.div`
  width: 25rem;
`;
