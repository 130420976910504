import fileDownload from 'js-file-download';
import DocImg from '../assets/doc.png';
import HtmlImg from '../assets/html.png';
import ImageImg from '../assets/image.png';
import MPPImg from '../assets/mpp.png';
import PDFImg from '../assets/pdf.png';
import PPTImg from '../assets/ppt.png';
import SheetImg from '../assets/sheet.png';
import TxtImg from '../assets/txt.png';
import VideoImg from '../assets/video.png';
import { filesHost } from '../services/api';
import { EnumFileType } from '../store/types';
import { isApiPrd } from './common';

export function normalizeFileUrl(file) {
  return `${filesHost}${file}`;
}

export function getFileImg(type: EnumFileType) {
  switch (type) {
    case EnumFileType.PPT:
    case EnumFileType.PPTX:
      return PPTImg;
    case EnumFileType.PDF:
      return PDFImg;
    case EnumFileType.MPP:
      return MPPImg;
    case EnumFileType.DOCX:
    case EnumFileType.DOC:
      return DocImg;
    case EnumFileType.XLS:
    case EnumFileType.XLSX:
    case EnumFileType.XLSM:
    case EnumFileType.CSV:
      return SheetImg;
    case EnumFileType.TXT:
      return TxtImg;
    case EnumFileType.JPEG:
    case EnumFileType.JPG:
    case EnumFileType.PNG:
      return ImageImg;
    case EnumFileType.HTML:
      return HtmlImg;
    case EnumFileType.MOV:
    case EnumFileType.MP4:
      return VideoImg;
    default:
      return '';
  }
}

export function getFileType(fileName: string) {
  const splitedName = fileName.split('.');

  return splitedName[splitedName.length - 1] as EnumFileType;
}

export function getFileName(fileName: string) {
  const splitedName = fileName.split('.');

  return splitedName[0];
}

export function downloadFileByUrl2(url: string, name = '') {
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.target = '_blank';
  downloadLink.download = name;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export function downloadFileByUrl(url: string, name = '') {
  return fetch(url)
    .then(response => response.blob())
    .then(response => {
      fileDownload(response as Blob, name);
    });
}

export function getSpacesFolder() {
  if (isApiPrd()) {
    return '';
  }

  return '/dev';
}

export enum AllowedTypeEnum {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  BMP = 'image/bmp',
}

export const ALLOWED_TYPE_LABEL = {
  [AllowedTypeEnum.JPG]: EnumFileType.JPG,
  [AllowedTypeEnum.JPEG]: EnumFileType.JPEG,
  [AllowedTypeEnum.PNG]: EnumFileType.PNG,
  [AllowedTypeEnum.BMP]: EnumFileType.BMP,
};
