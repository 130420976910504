/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Enums
 */

import { ReactElement } from 'react';
import { Column } from 'react-data-grid';
import { Config, State } from 'components/Widgets/DynamicForm/types';

export enum EnumRoutes {
  HOME = '/',
  SIGNIN = '/signin',
  SHEET_MANAGER = '/sheets-manager',
  SECTORS_MANAGER = '/sectors-manager/',
  SCHEDULE_SECTORS_MANAGER = '/schedule-sectors-manager',
  REPORTS = '/reports',
  REPORT_PAGES = '/rota-teste/',
  MOBILE_CONSTRUCTION = '/under-construction',
  FVS = '/fvs-setup',
  FVS_SCHEDULE = '/schedule-fvs-setup',
  FVS_SCHEDULE_CONFIGURE_SECTORS = '/fvs-schedule-config',
  SHEETS = '/sheets',
  UPLOAD_FILE = '/upload-file',
  UPLOAD_IMAGE = '/upload-image',
  NEW_PASSWORD = '/new-password',
  RESET_PASSWORD = '/reset-password',
  TABLE_REPORT = '/table-report',
  FILES = '/files',
  FEICON = '/gescorpgo-na-feicon',
  GOOGLE_PLAY = '/google-play',
  PRIVACY_POLICY = '/privacy-policy',
  COMPANY_FILES = '/company-files',
  DYNAMIC_FORM = '/dynamic-form',
  LIST_SUPPLIER_CONTRACT = '/list-supplier-contract',
  LIST_ORDER_CONTRACT = '/order-contract-list',
  MEASUREMENT_SHEET = '/measurement-sheet',
  ORDER_LIST = '/order-list',
  ORDER_MATERIAL_RECEIPT = '/order-material-receipt',
  REPORT_ORDER_RECEIPT = '/report-order-receipt',
  REGISTER_MEASUREMENT = '/register-measurement',
  MEASUREMENT = '/measurement',
  CONTRACT_LIST_FINISH_REPORT = '/contract-list-finish-report',
  IMPORT_XLS = '/import-xls',
  NOT_FOUND = '*',
  USER_ACCESS_LEVEL = '/user-access-level',
  USER_ACCESS_MODEL = '/user-access-model',
  USERS_MANAGEMENT = '/users-management',
  SUPPLIER_MANAGEMENT = '/supplier-management',
  ORDER_SCOPE = '/order-scope',
  BUDGET_MAINTENANCE = '/budget-maintenance',
  BASELINE_BUDGET_MAINTENANCE = '/baseline-budget-maintenance',
  TAGS = '/tags',
  CONFIG_APPROPRIATION = '/config-appropriation',
  PHANTOM_REPORT_TEST = '/phantom-report-test',
  DOWNLOAD = '/download',
  GENERATE_DOWNLOAD_PDF = '/generate-download-pdf',
  FLOW_DISBURSEMENT = '/flow-disbursement',
  REPORT_QUEUE = '/report-queue',
  MAPS = '/maps',
  MAPSNEW = '/controle-georreferenciado',
  QUOTATION_TABLE = '/quotation-table',
  TABLE_REPORT_QUOTATION_EDIT = '/table-report?isEditMode=1',
  GRAPHIC_PRINT = '/graphic-print',
  TABLE_APPROVAL = '/table-approval',
  MILESTONE_MANAGER = '/milestone-manager',
  SQLITE_BACKUP_LIST = '/sqlite-backup-list',
  CONTRACT_MANAGEMENT = '/contract-management',
  LIST_MEASUREMENT_CONTRACT = '/list-measurement-contract',
  NEW_REGISTER_MEASUREMENT = '/new-register-measurement',
  SUPPORT = '/support',
  PDF_VIEWER = '/pdf-viewer',
  DOC_SIGN = '/doc-sign/*',
  PHOTO_GALLERY = '/photo-gallery',
}

export enum EnumProfile {
  ADMINISTRADOR = 1,
  PRODUCAO = 2,
  EXDCUTIVO = 3,
  DEMO = 4,
  OPERACIONAL = 5,
  APROVADOR = 6,
  AUDITOR = 7,
  GESTOR = 8,
}

export enum EnumActions {
  CONSULTA = 1,
  SALVAR = 2,
  EDITAR = 3,
  EXCLUIR = 4,
  REPROVAR = 5,
  APROVAR = 6,
  FECHAR_PERIODO = 7,
  GRAVAR_BASELINE = 8,
  FINALIZAR_OCORRENCIA = 9,
  FECHAR_COTACAO = 10,
  COPIAR = 11,
  LIMPAR = 12,
  EDITAR_VGV = 13,
  EDITAR_APROVACAO = 14,
  EXCLUIR_APROVACAO = 15,
  EDITAR_FOTOS = 16,
  REENVIAR = 17,
}

/**
 * Data types
 */

export interface ReportLayout {
  id: number;
  name: string;
  route: EnumRoutes;
  datasetId: number;
}

export interface ReportMenu {
  layouts: ReportLayout[];
  id: number;
  name: string;
}

export interface TopMenu {
  idModulo: number;
  idLayoutCapa: number;
  idDataset: number;
  tituloPortal: string;
  rota: string;
  relatorio: string;
}

export interface Alert {
  title: string;
  subtitle?: string | Element;
  actions?: { text: string; onClick?(): void }[];
}

export interface Credentials {
  email: string;
  senha: string;
}

export interface TreeViewItem {
  key: string | number;
  value: string;
  level: number;
  isLastLevel: boolean;
  columns?: TreeViewItem[];
  children?: TreeViewItem[];
  isOpen?: boolean;
  flagConfigurado?: boolean;
  [key: string]: any;
}

export interface AxiosResponse {
  success: boolean;
  response: any;
  status: number;
  errors: string[];
  user?: any;
}

export interface Styles {
  [key: string]: any;
}

export interface ReportConfig {
  url: number;
  style?: Styles;
  seq: number;
}

export interface PowerBIContract {
  contractId: number;
  company: string;
  report: string;
  config: ReportConfig[];
}

export type OptionsType = {
  value: string | number;
  label: string | number;
  type?: string;
  __isNew__?: boolean;
  selected?: boolean;
  [key: string]: any;
};

export interface TreeViewItemProps {
  item: TreeViewItem;
  isOpen?: boolean;
  forceOpen?: boolean;
  disableHoverEffect?: boolean;
  onOpen?: () => void;
  onClick?: (item: TreeViewItem) => Promise<void>;
  renderItem?: (
    item: TreeViewItem,
    isOpen: boolean,
    onClick: () => void,
  ) => ReactElement;
  getHeaderConfig: (columnIdx: number) => string;
}

export interface ValueStyles {
  key: number;
  value: string;
  styles: any;
}

export interface TreeViewHeaderProps {
  header: ValueStyles[];
  subHeader: ValueStyles[];
}

export interface Style {
  styles: any;
}

export interface Contract {
  idContrato: number;
  idEmpresa?: number;
  idEmpreendimento?: number;
  nome?: string;
  flagSupConAprv?: number;
  nomeEmpreendimento?: string;
  mascaraWbsFin?: string;
  flagSupIncc?: number;
  fvsMedicao?: number;
  medicaoContrato?: number;
  flagMedicao?: number;
  tipoIncc?: string;
  deslocamentoIncc?: string;
  razaoSocialObra?: string;
  cnpjObra?: string;
  endereco?: string;
  telefone?: string;
  mascaraCodInsumo?: number;
}

export interface Sheet {
  sheetId: number;
  familyId?: number;
  name: string;
}

export interface Sheet2 {
  idFicha: number;
  ficha: string;
}

export interface Family {
  idFamilia?: number;
  idContrato?: number;
  idEmpresa?: number;
  familia?: string;
  tipo?: string;
  idSubProjeto?: number;
  idFicha?: number;
  codErp?: number;
}

export interface FileGroup {
  id: number;
  descricao: string;
}

export interface SubserviceSheet {
  id?: number;
  idContrato: number;
  idSubservico: number;
  idFicha: number;
  idFamilia: number;
  idFamilia2: number;
}

export interface Sector {
  idSetor?: number;
  nomeSetor: string;
  idContrato?: number;
  idFamilia?: number;
  [key: string]: any;
  seq?: number;
}

export interface ExcelSector {
  familia: string;
  setor: string;
  idContrato?: number;
  idEmpresa?: number;
}

export interface SectorProps {
  idSetor?: number;
  idContrato?: number;
  idEmpresa?: number;
}

export interface FamilySector {
  id?: number;
  idFamilia: number;
  idSetor: number;
  idContrato?: number;
  idEmpresa?: number;
}

export interface SheetSector {
  nivelReporte: number;
  setorN1: string;
  setorN2: string | null;
  idSetorN1: number;
  idSetorN2: number | null;
  isNew?: boolean;
  flagConfigurado: boolean;
  flagHabilitado?: boolean;
  idSubservico?: string;
  flagInspecionado?: boolean;
  idMedicao?: number;
  isFromCronograma?: boolean;
  idStatusFicha?: number;
}

export interface Service extends Family {
  idSubservico: number;
  subservico: string;
  subServico?: string;
  seq: number;
}

export interface Subservice {
  idSubservico: number;
  subServico?: string;
  seq: number;
}

export interface ServiceManager extends Service {
  flagConfigurado: boolean;
  idFamilia2?: number;
  familia2?: string;
  type: 'group' | 'service';
}

export enum EnumActionType {
  REMOVE,
  SELECT_ALL,
  ENABLE,
  EDIT,
}

export interface PopupActions {
  text: string;
  icon?: string;
  iconComponent?: ReactElement;
  type?: EnumActionType;
  onClick(actionType: EnumActionType): void;
  visible?: boolean;
}

export interface PopoupActionsShow {
  clientX?: number;
  clientY?: number;
  right?: number;
  isVisible: boolean;
  actions?: PopupActions[];
}

export enum EnumSectorType {
  SECTOR_N1 = 'setor_n1',
  SECTOR_N2 = 'setor_n2',
}

export enum EnumReportLevel {
  N1 = 4,
  N2 = 5,
  N3 = 6,
}

export interface TableFiltersConfig {
  key: string;
  label: string;
  idDataset?: number;
  required?: boolean;
  requiredMessage?: string;
  isSingle?: boolean;
  type?: string;
}

export interface TableTreeviewConfig {
  opened: boolean;
  openedAtLevel: number;
  treeDepth: number;
  isNewFlow: number;
  idDataset: number;
  idDatasetRows?: number;
  idDatasetColumns?: number;
  header: any;
  rowsSettings: object;
  rows: object;
  idLayoutToAddAdditive?: number;
  rowsGradientStartingColor: string;
  rowHeight: number;
  columns: Column<any>[];
  wrapperCss?: string;
}

export interface TableConfigFile {
  idDatasetContadorFiltros: number;
  fetchAfterSelectContract: number;
  fetchDataOnRowClick?: boolean;
  tooltipIcons?: {
    [key: string]: string;
  };
  sidebar?: {
    isSidebarExpanded?: boolean;
    hideSidebar?: boolean;
    [key: string]: any;
  };
  pdfExport?: TablePdfExport;
  pdfExportNew?: TablePdfExportNew;
  xlsExport?: any;
  boletimExport?: any;
  photoReportExport?: any;
  filters: TableFiltersConfig[];
  treeview: TableTreeviewConfig;
  dataNotFoundMessage: string;
}

export interface FlowConfigFile {
  sidebar?: {
    isSidebarExpanded?: boolean;
    hideSidebar?: boolean;
    [key: string]: any;
  };
  flowDisbursement: {
    idDasetRows: number;
    idDasetValueClosePeriod: number;
    idDasetValueOpenPeriod: number;
    idDasetColumnsPeriod: number;
    columns: {
      [key: string]: any;
    };
  };

  simpleTable: {
    incorrido: {
      datasetId: number;
    };
    contratado: {
      datasetId: number;
    };
  };
  xlsExport: {
    idDataset?: number;
    text?: string;
    fileName?: string;
  };
}

export interface TablePdfExport {
  formatter?: string;
  template?: string;
  css?: string;
}
export interface TablePdfExportNew {
  nomeArquivo?: string;
}

export interface TableTreeviewRow {
  key: string;
  description: string;
  treeDepth: number;
  columns: { [key: string]: any };
  photoParameters?: { [key: string]: any };
  childrens: { [key: string]: any };
  [key: string]: any;
}

export interface ExpandedRows {
  [key: string]: boolean;
}

export interface Layout {
  configFile: TableConfigFile;
  idDataset: number;
}

export interface Comment {
  idComentario?: number;
  comentario: string;
  uuid?: string;
  dtCriacao?: string;
  id?: number;
  tipo?: string;
  idChecklist?: number;
  checklistKey?: string;
  idContrato?: number;
  origem?: string;
  idUsuario?: number;
  nomeUsuario?: string;
  keyReferencia?: string;
  uuidReferencia?: string;
  status?: number;
  flagStatus?: number;
}

export interface CommentPeriod {
  id: number;
  idContrato: number;
  idUsuario: number;
  comentario: string;
  idPeriodo: number;
  idServicoOrcamento: number;
  flagStatus: number;
}

export interface Photo extends Comment {
  idFoto: number;
  id: number;
  referencia: string;
  src?: string;
  seq: number;
  comentario: string;
  habilitado?: number;
  flagStatus?: number;
  shouldSaveComment?: boolean;
  uuidComentario?: string;
}

export interface TablePhoto {
  idFoto: number;
  referencia: string;
  tipo: string;
  usuario: string;
  dtFoto: string;
  comentario: string;
  descricaoOcorrencia?: string;
  descricao?: string;
}

export enum EnumFileType {
  TXT = 'txt',
  MPP = 'mpp',
  PPT = 'ppt',
  PPTX = 'pptx',
  PDF = 'pdf',
  XLS = 'xls',
  XLSX = 'xlsx',
  XLSM = 'xlsm',
  CSV = 'csv',
  DOCX = 'docx',
  DOC = 'doc',
  JPEG = 'jpeg',
  JPG = 'jpg',
  PNG = 'png',
  HTML = 'html',
  MOV = 'mov',
  MP4 = 'mp4',
  KMZ = 'kmz',
  BMP = 'bmp',
}

export interface Category {
  id: number;
  categoria: string;
}

export interface User {
  idUsuario: number;
  idEmpresa: number;
  nome: string;
  sobrenome: string;
  apelido?: string;
  telefone?: string;
  email: string;
  idGrupoArquivo?: number;
  idPerfil?: EnumProfile;
  cargo?: string;
  idCargo?: number;
  flagStatus: number;
  idUserModelo?: number;
  nomeTemplate?: string;
}

export interface File {
  nome: string;
  referencia: string;
  tipoArquivo: EnumFileType;
  id?: number;
  uuid?: string;
  tipo?: string;
  dtCriacao?: string;
  usuario?: User;
  comentario?: Comment;
  categoria?: Category;
  idReferencia?: boolean;
  versao?: number;
  uploading?: boolean;
  flagDownload?: boolean;
  appendedData?: any;
}

export interface CategoryWithFiles {
  category: string;
  files: File[];
}

export interface DynamicTableFilters {
  key: string;
  label: string;
  options?: OptionsType[];
  type?: string;
  isSingle: boolean;
}

export interface DatasetParams {
  [key: string]: string | number | null | undefined | string[];
}

export interface Ticket {
  idUsuario: number;
  idEmpresa: number;
  idContrato: number | null;
  modulo: string;
  origem: string;
  versaoApp: string;
  situacao: string;
  assunto: string;
  descricaoErro: string;
  anexos: string;
  telefone: string;
}

export interface Company {
  idEmpresa: number;
  empresa: string;
}

export interface RelatedFileGroup {
  id: number;
  idEmpresa: number;
  idGrupoArquivo: number;
  idArquivo: number;
}

export interface Form {
  idFormulario: number;
  idEmpresa: number;
  idContrato: number;
  idEmpreendimento: number;
  idUsuario: number;
  idTemplate: number;
  tipo: string;
  form: State;
  referencia?: number;
}

export interface Approval {
  id?: number;
  idEmpresa: number;
  idContrato: number;
  idNivelAprovacao?: number;
  origem: string;
  idReferencia: number;
  dtAprovacao: string;
  observacao: string;
  idAprovador: number;
  flagStatus: number;
  status: number;
  areaResponsavel: string | number;
}

export interface ReportAppropriation {
  id?: number;
  idContrato: number;
  idContratoFornecedor: number;
  idItemEscopo?: number;
  idCapa: number;
  idInsumoComprometido?: number;
  vlApropriacao?: number;
  tipo: 'CONTRATO' | 'PEDIDO' | 'DESPESA';
}

export enum EnumReportStatus {
  OPENED = 0,
  FINISHED = 1,
  DISCHARGED = 2,
}

export interface ReportCoverData {
  notas: any;
  periodoMedicaoInicio: string;
  periodoMedicaoFim: string;
  dataVencimento?: string;
  numeroPedidoErp: string;
  observacao: string;
  codLancamentoCap: string[];
  percentualRetencao: Currency;
  valorRetencao: Currency;
  categorias: OptionsType[] | [];
  desconto: number;
  totalAPagar: number;
}

export interface ReportCover {
  id?: number;
  idContrato: number;
  idContratoFornecedor: number;
  seq: number;
  status: EnumReportStatus;
  dtCriacao: string;
  flagFvsCapa?: boolean;
  flagLancamentoCap?: number;
  dados: ReportCoverData | string;
  flagAprvBoletim: boolean;
  desconto: string;
  saldoQuitacao: number;
  totalAPagar: number;
  dtLimit?: string;
}

export interface LogMenu {
  // verificar se pode ou nao ser null de acordo com o banco assim: | null
  idReferencia: string;
  idUsuario: number;
  idEmpresa: number;
  rota: string;
  browser: string;
  dispositivo: string;
}

export interface FormApproval {
  id: number;
  dtAprovacao: string;
  dtCriacao: string;
  usuarioAprovacao: string;
  usuarioCriacao: string;
}

export interface TemplateForm {
  id: number;
  idEmpresa: number;
  tipo: string;
  template: Config;
  templatePortal?: Config;
}

export interface LoadingModalParams {
  text: string;
}

export interface Role {
  idCargo?: number;
  idEmpresa: number;
  cargo: string;
}

export interface Supplier {
  idFornecedor?: number;
  categoryIds?: number[];
  idEmpresa: number;
  razaoSocial: string;
  codErp?: string;
  fornecedor: string;
  nomeFantasia?: string;
  cnpj?: string;
  uuid?: string;
}

export interface SectorPlanning {
  flagStatus: number;
  dtIni: string;
  dtFim: string;
  planejado: number;
  flagTipoPlanejamento: number;
  idUsuario: number;
  idContrato: number;
  idMedicao: number;
  uuidProject: string;
  idSubProjeto: number;
  idSubservico: number;
  idSetorPai: number;
  idSetorFilho: number;
  id: number | null;
}

export interface PlanningSectorEdit {
  dtIni: string | null;
  dtFim: string | null;
  comentario: string;
  dtIniBaseline?: string | null;
  dtFimBaseline?: string | null;
  status: OptionsType;
}

export interface CatalogList {
  id: number;
  idEmpresa: number;
  descricao: string;
  idTipo: number;
  idModulo: number;
}

export interface CatalogType {
  id: number;
  idEmpresa: number;
  descricao: string;
  idTipo: number;
  idModulo: number;
  uuid: string;
}

export interface CatalogListContract {
  id: number;
  descricao: string;
  idTipo: number;
  idContrato: number;
  uuid: string;
  seq: number;
  flagStatus: number;
  valor?: number;
}

export interface SupplierContract {
  idContratoFornecedor?: number;
  flagSupConAprv?: number;
  idCfPai?: number;
  idEmpresa: number;
  idContrato: number;
  idFornecedor: number;
  nomeFornecedor?: string;
  totalOf: number;
  saldoPedido?: number;
  nomeContrato: string;
  dtCriacao?: string;
  origem?: string;
  flagStatus?: number;
  tipo: 'PEDIDO' | 'CONTRATO' | 'DESPESA' | 'TAXA';
  dadosContrato: {
    dtLiberacaoRetencao?: string;
    percentualRetencao?: Currency;
    quantidadeParcelas?: number;
    diaFechamentoMedicao?: number;
    totalFaturamentoDireto?: Currency;
    prazoPagamento?: number;
    codigoContratoErp?: string;
    condicaoPagamento?: OptionsType;
    formaPagamento?: OptionsType;
    tipoPedido?: OptionsType;
    dataEntrega?: string;
    vencimento?: string;
    codPedidoErp?: string;
    flagSupIncc?: number;
    taxaAdministracao?: number;
    observacao?: string;
    moeda?: string;
    tipoIncc?: string;
    valorIncc?: number;
  };
  nomeFantasia?: string;
  flagSupIncc?: number;
  idFormulario?: number;
  idCotacaoCapa?: number;
  idCotacaoCapaFornecedor?: number;
  flagCriarBoletim?: boolean;
  idUsuario?: number;
  dadosAssinantes?: {
    nome: string;
    email: string;
    cargo: string;
    link?: string;
    status?: number;
  }[];
}

export interface CommittedInput {
  idInsumoComprometido?: number;
  idContratoFornecedor: number;
  idEmpresa: number;
  idServicoOrcamento: number;
  idContrato: number;
  codInsumo: string;
  qtdeComprometida: number;
  vlUnitario: number;
  vlTotal: number;
  flagStatus: number;
}

export interface EnterpriseData {
  id_dados_empreendimento?: number;
  id_empresa: number;
  id_contrato: number;
  versao: number;
  dados: any;
  origem: string;
}

export interface XlsxData {
  key: string;
  descricao: string;
  chave: string;
  valor: string | number;
}

export interface MeasuredData {
  idPeriodo: number | null;
  idMedicao: number;
  medicao: string | number;
  dtInicio: string | null;
  dtFim: string | null;
}

export interface MeasuredDataL3 {
  curva: string;
  periodo: string;
  producao: string | number;
  avancoMensal: string | number;
  acumulado: string | number;
}

export interface ReceivedData {
  uuidNota: string;
  uuid: string;
  uuidItemNota: string;
  idFicha: number;
  idItemVerificacao: number;
  idUsuario: number;
  dtCriacao: string;
  resposta: string | null;
}

export interface Address {
  lat: string;
  lon: string;
  endereco: string;
}

export interface ContractSupplierDetail {
  id?: number;
  idEmpresa: number;
  idContrato: number;
  idFornecedor: number;
  distancia: number;
}

export interface ReportMeasurement {
  id?: number;
  idContrato: number;
  idContratoFornecedor: number;
  idMedicaoServico: number;
  idBoletimCapa: number;
  quantidade: string | number | undefined;
  chave: string;
  vlUnitarioVigente: number;
  qtdeSaldoAtual: number;
  qtdeSaldoRestante: number;
  idUsuario: number;
}

export interface PurchaseInput {
  id: number;
  idContrato: number;
  idContratoFornecedor: number;
  descricao: string;
  unidade: string;
  quantidade: number | string | undefined;
  vlUnitario: number | string | undefined;
  flagStatus?: number;
  flagApropriacao?: number;
}

export interface ReceiptOrder {
  id?: number;
  idContrato: number;
  idContratoFornecedor: number;
  idInsumoCompra: number;
  idPedidoBaixaCapa: number;
  quantidade: number;
}

export interface OrderScopePayment {
  uuid: string;
  dtVencimento: string;
  valor: Currency;
  codLancamentoCap?: string | string[];
  tipoPagamento?: OptionsType;
}

export interface OrderScopeData {
  danfe?: string;
  dataEmissao?: string;
  numeroNf: string;
  observacao?: string;
  idFvmNf?: number;
  pagamentos?: OrderScopePayment[];
  categorias: OptionsType[] | [];
}

export interface OrderScope {
  id?: number;
  idContrato: number;
  status: number;
  idContratoFornecedor: number;
  seq?: number;
  dtCriacao?: string;
  flagLancamentoCap?: number;
  dados: OrderScopeData | null;
}

export interface Occurrence {
  idResponsavel?: number;
  idOcorrencia: number;
  idContrato?: number;
  idEmpresa: number | null;
  idCategoriaOcorrencia: number;
  idChecklist: number;
  idMedicao?: string | null;
  idEtapa?: number;
  idServico?: number;
  idSetorPai?: number;
  idSubProjeto?: number;
  idSubservico?: number;
  checklistKey: string;
  idFornecedor: number;
  dtIniReal: string;
  dtFimReal: string;
  fornecedor: string;
  categoriaOcorrencia?: string;
  ocorrencia: string;
  status: number;
  responsavel?: string;
  flagStatus: number;
  dtAbertura: string;
  dtStatus: string;
  origem: string;
  prazo: string | null;
  uuidFornecedor?: string | null;
  idUsuarioCriacao: number;
  referencia?: string;
  uuid?: string;
  tags?: string;
  idUltimoUsuario?: number;
}

export interface SubserviceSectionData {
  tituloSecao: string;
  statusSecao: string;
  dataStatus?: string;
  fornecedorSelecionado?: string;
  valorPropostaFinal?: string;
}

export interface ReportFvs {
  id?: number;
  idContrato: number;
  idContratoFornecedor: number;
  idMedicaoServico: number;
  idBoletimCapa: number;
  idInspecao: number;
}

export interface Additive {
  idAditivo: number;
  justificativa: string;
  dtCriacao: string;
  flagStatus: number;
  quantidade: number | string | undefined;
  vlUnitario: number | string | undefined;
  vlAditivoReal: number;
  tags: string;
}

export interface ServiceBudget {
  idContrato: number;
  idEmpresa: number;
  idServicoOrcamento: number;
  servicoOrcamento: string;
  flagCongelamentoReajuste: number;
  codErp: string;
  tags: string | [] | null;
  idFamilia1: number;
  idFamilia2: number | null;
  idFamilia3: number | null;
  idFamilia4: number | null;
  idFamilia5: number | null;
}

export interface Input {
  idServicoOrcamento: number;
  idContrato: number;
  descricao: string;
  dtBaseIncc: string;
  unidade: string;
  codErp: string;
  codInsumoErp: string;
  qtdeOrcado: number | string | undefined;
  vlOrcadoReais: number | string | undefined;
  vlUnit: number | string | undefined;
  flagStatus?: number;
  vlOrcadoIncc?: number | string;
  dados: {
    wbsCliente: string;
    planoDeContas?: string;
  };
}

export type Currency = number | string | undefined;

export interface NF {
  id?: number;
  uuid: string;
  idContrato: number;
  flagLancamentoCap?: number;
  dados: {
    pesoCarga: Currency;
    codLancamentoCap: string[];
  };
}

export interface ReportDiscount {
  id?: number;
  idContratoFornecedor: number;
  idBoletimCapa: number;
  idCatalogo: number;
  valor: Currency;
  flagStatus?: number;
  uuid?: string;
}

export interface Tag {
  id: number;
  idEmpresa: number;
  idModulo: number;
  idCatalogoListas: number;
  descricao: string;
  habilitado: number;
}

export interface MeasureService {
  id: number;
  chave: string;
  descricao: string;
  quantidade: number;
  vlUnitario: number;
  nivel: number;
}

export interface ConfigAppropriation {
  id: number;
  idContrato: number;
  idContratoFornecedor: number;
  idMedicaoServico: number;
  idInsumoComprometido: number;
  valor: number;
  tipo: 'CONTRATO' | 'PEDIDO';
  flagStatus?: number;
}

export interface Environment {
  idAmbiente: number;
  ambiente: string;
  status: number;
}

export enum OccurrenceStatus {
  PENDENTE = 3,
  CANCELADO = 5,
  FINALIZADO = 4,
}

export const statusOptions: OptionsType[] = [
  { value: OccurrenceStatus.PENDENTE, label: 'PENDENTE' },
  { value: OccurrenceStatus.CANCELADO, label: 'CANCELADO' },
  { value: OccurrenceStatus.FINALIZADO, label: 'FINALIZADO' },
];

export interface ItemEAP {
  id: number;
  idEmpresa?: number;
  idContrato?: number;
  idReferencia?: number | null;
  nivel?: number;
  seq?: number;
  descricao: string;
  idUsuario?: number;
  flagStatus?: number;
  flagTipoPlanejamento?: number;
  medicao?: number;
  abreviacao?: string;
  uuidProject?: number | string;
  tipoReferencia?: string | null;
}

export interface Navigation {
  id: number;
  idMedicao: number | null;
  flagStatus?: number;
  idEmpresa?: number;
  idContrato?: number;
  nivel?: number;
  seq?: number;
  uuidProject?: number;
  idReferencia?: number;
  visao?: string;
  key?: string;
}

export interface Mesure {
  idMedicao: number;
  idContrato: number;
  peso: number;
  flagStatus: number;
  medicao: number;
  dtMedicao: string;
  dtStatus: string;
  uuidProject: string;
  key: string;
  idFornecedor?: number;
  idUsuarioResponsavel: number | null;
  dtIniReal: string;
  dtFimReal: string;
  uuidUsuarioResponsavel: string | null;
  uuidFornecedor: string | null;
}

export interface BaselineGoals {
  id: number;
  uuidProject: number;
  planejado: number;
  idUsuario: number;
  idSubservico: number;
  idSubProjeto: number;
  idSetorPai: number;
  idSetorFilho: number;
  idMedicao: number;
  idContrato: number;
  flagTipoPlanejamento: number;
  dtIni?: string;
  dtFim?: string;
  flagStatus?: number;
  idBaseline?: number;
}

export interface UserRelationship {
  uuid: string;
  uuidUsuario: string;
  uuidReferencia: string;
  idContrato: number;
  flagStatus: number;
}

export enum EnumReferenceCurve {
  DATA_FIXA = 1,
  CONTRATO = 2,
  PLANEJADO = 3,
  DESAFIO = 4,
}
