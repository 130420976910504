import styled from 'styled-components';
import { DonwloadIcon as DonwloadIconStyled } from 'styles/icons';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 5.3rem);
`;

export const LogoImg = styled.img`
  max-width: 700px;
  margin: auto;
  height: 20vh;
`;

export const Wrapper = styled.div`
  display: inline-grid;
  text-align: center;

  h2 {
    margin: 3rem 0;
  }

  button {
    margin: auto;
    margin-bottom: 3rem;
  }
`;

export const DonwloadIcon = styled(DonwloadIconStyled)`
  margin-right: 0.5rem;
  color: #fff;

  &:hover {
    color: #fff;
  }
`;
