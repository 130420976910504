/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import Select from 'components/ReactSelect';
import Span from 'components/Span';
import { getSqlResponse } from 'services/dataset';
import { OptionsType } from 'store/types';
import { Label } from '../../styles';
import { DynamicFormsInputProps } from '../../types';
import { useDynamicForm } from '../../context';
import useUser from 'hooks/use-user';
import { checkUserEditPermission } from '../../utils';

export default function InputSelect(props: DynamicFormsInputProps) {
  const { field, defaultValue, isRequired, onChange, isDisabled } = props;

  const [selectedOptions, setSelectedOptions] = useState<
    OptionsType | OptionsType[]
  >(defaultValue);

  const [options, setOptions] = useState<OptionsType[]>([]);
  const user = useUser();

  const {
    globalState,
    contractId: idContrato,
    approvalData,
    fieldsAllowProps,
  } = useDynamicForm();

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    setSelectedOptions(defaultValue || null);
  }, [defaultValue]);

  useEffect(() => {
    if (globalState) {
      loadOptions();
    }
  }, [user, globalState]);

  async function loadOptions() {
    if (!user) {
      return;
    }

    if (field.datasetId) {
      const { idEmpresa, idUsuario } = user;
      const { datasetParams } = globalState!;

      const params = {
        idUsuario,
        idEmpresa,
        idContrato,
        idModulo: 10,
        ...field.datasetParam,
        ...datasetParams,
      };

      for (const iterator of [1, 2, 3, 4, 5]) {
        try {
          const res = await getSqlResponse<OptionsType[]>(
            field.datasetId,
            params,
          );
          setOptions(res);
          break;
        } catch (error) {
          console.log('retentaviva', iterator);
        }
      }

      return;
    }

    setOptions([
      { label: 'Sim', value: 'Sim' },
      { label: 'Não', value: 'Não' },
    ]);
  }

  function handleSelect(option: OptionsType | OptionsType[]) {
    onChange(field.name, option);
    setSelectedOptions(option);
  }

  return (
    <>
      <Label
        htmlFor={field.name}
        dangerouslySetInnerHTML={{ __html: field.label }}
        isLabel={field.label}
      />
      <div style={{ textAlign: 'left', height: 'auto' }}>
        <Select
          isDisabled={isDisableChecked}
          minHeight={1}
          isLoading={!options.length}
          options={options}
          value={selectedOptions}
          defaultValue={selectedOptions}
          onChange={handleSelect}
          error={isRequired}
          isMulti={field.isMulti}
          closeMenuOnSelect={!field.isMulti}
        />
        {isRequired && <Span text="Campo obrigatório" />}
      </div>
    </>
  );
}
