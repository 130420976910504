import { Approval } from '../store/types';
import api from './api';

export interface EditStatusProps {
  idReferencia: number;
  idContrato: number;
  origem: string;
  status: number;
}

export function create(data: Approval): Promise<Approval> {
  return api.post(`/aprovacao/add.json`, data);
}

export function update(data: Partial<Approval>): Promise<Approval> {
  return api.post(`/aprovacao/update.json`, data);
}

export function cancel(id: number): Promise<void> {
  return api.post(`/aprovacao/delete.json`, { id });
}

export function editStatus(data: EditStatusProps[]) {
  return api.post(`/aprovacao/edit-status.json`, data);
}

export function remove(
  data: { idReferencia: string; origem: string }[],
): Promise<Approval> {
  return api.post(`/aprovacao/remove.json`, data);
}
