import * as Yup from 'yup';

export const validation = Yup.object().shape({
  nome: Yup.string()
    .max(100, 'Este nome é muito longo')
    .typeError('Este campo é obrigatório')
    .required('Este campo é obrigatório'),
  sobrenome: Yup.string()
    .max(100, 'Este sobrenome é muito longo')
    .typeError('Este campo é obrigatório')
    .required('Este campo é obrigatório'),
  email: Yup.string()
    .max(80, 'Este email é muito longo')
    .email('E-mail inválido.')
    .required('Este campo é obrigatório'),
});
