import React, { useEffect, useState } from 'react';

import Button from 'components/Button';
import { Text } from 'styles/text';

import { SingleInputModalParams } from 'components/Modal/SingleInput';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';

import Badge from 'components/Badge';
import { FormReplyOccurrenceModalParams } from 'components/Widgets/MultipleApproval/components/FormReplyOccurrenceModal';
import { getSqlResponse } from 'services/dataset';
import { LabelInfo } from 'styles/form';
import { getUtcDatetime } from 'utils/date';
import { Row } from '../../styles';
import { ApprovalLevel, Reply, StatusApproval } from '../../types';
import OccurrenceReplyList from '../OccurrenceReplyList';
import {
  ActionsWrapper,
  CancelApprovalIcon,
  Card,
  CardTag,
  Container,
  GroupWrapper,
  StatusWrapper,
} from './styles';
import { STATUS_ITEMS } from '../../utils';

interface Props {
  idContrato: number;
  approval: ApprovalLevel;
  onApproval(approval: ApprovalLevel): Promise<void>;
  onEdit(approval: Partial<ApprovalLevel>): Promise<void>;
  onCancelApproval(approval: ApprovalLevel): void;
  onRepproval(approval: ApprovalLevel): void;
  onEditOccurrence(
    approval: ApprovalLevel,
    onReloadReplyList: () => Promise<void>,
  ): void;
  approvalButtonText?: string;
  disableApproval?: boolean;
  afterApproval(): void;
  idTipo?: number;
  afterReply(): void;
}

export default function Item(props: Props) {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [replies, setReplies] = useState<Reply[]>([]);
  const [isLoadingReply, setIsLoadingReply] = useState(false);

  const { approval } = props;

  useEffect(() => {
    getReplies();
  }, [approval.idOcorrencia]);

  async function getReplies() {
    const { idOcorrencia } = approval;

    if (!idOcorrencia) {
      setReplies([]);

      return;
    }

    setIsLoadingReply(true);

    const repliesResponse = await getSqlResponse<Reply[]>(1107, {
      idOcorrencia,
    });

    setReplies(repliesResponse);
    setIsLoadingReply(false);
  }

  function handleShowReplyModal(reply?: Reply) {
    return () => {
      if (reply?.isOccurrence) {
        const { onEditOccurrence } = props;

        onEditOccurrence(approval, getReplies);

        return;
      }

      const { idContrato, afterApproval, idTipo, afterReply } = props;
      const { idOcorrencia, uuidOcorrencia, idAprovacao } = approval;

      dispatch(
        showModal<FormReplyOccurrenceModalParams>(
          ModalName.FORM_REPLY_OCCURRENCE_MODAL,
          {
            idContrato,
            idAprovacao,
            seq: reply!.seq,
            idComentario: reply?.idComentario || 0,
            uuidOcorrencia: uuidOcorrencia!,
            idOcorrencia: idOcorrencia!,
            idTipo,
            onAfterSave: () => {
              getReplies(), afterApproval(), afterReply();
            },
          },
        ),
      );
    };
  }

  function handleShowRepprovalReevaluateModal(reply?: Reply) {
    return () => {
      if (reply?.isOccurrence) {
        const { onEditOccurrence } = props;

        onEditOccurrence(approval, getReplies);

        return;
      }

      const { idContrato, afterApproval, idTipo, afterReply } = props;
      const { idOcorrencia, uuidOcorrencia, idAprovacao } = approval;

      dispatch(
        showModal<FormReplyOccurrenceModalParams>(
          ModalName.FORM_REPPROVAL_REEVALUATE_MODAL,
          {
            idContrato,
            idAprovacao,
            seq: reply!.seq,
            idComentario: reply?.idComentario || 0,
            uuidOcorrencia: uuidOcorrencia!,
            idOcorrencia: idOcorrencia!,
            idTipo,
            onAfterSave: () => {
              getReplies(), afterApproval(), afterReply();
            },
          },
        ),
      );
    };
  }

  function renderButtonApproval() {
    if (approval.status === StatusApproval.APPROVED) {
      return null;
    }

    const onSave = async (observacao: string | undefined) => {
      const { onApproval, onEdit } = props;

      const newApprovalData: ApprovalLevel = {
        ...approval,
        observacaoOriginal: observacao || '',
        status: StatusApproval.APPROVED,
        dtAprovacao: getUtcDatetime(),
      };

      if (
        approval.status === StatusApproval.REPPROVED ||
        approval.status === StatusApproval.REEVALUATE
      ) {
        await onEdit(newApprovalData);
      } else {
        await onApproval(newApprovalData);
      }

      dispatch(closeModal(ModalName.SINGLE_INPUT));
    };

    const showSingleInputModal = () => {
      dispatch(
        showModal<SingleInputModalParams>(ModalName.SINGLE_INPUT, {
          value: approval.observacaoOriginal,
          placeholder: 'Observação opcional',
          title: `${approval.nivel}`,
          onSave,
        }),
      );
    };

    const { approvalButtonText } = props;

    return (
      <Button
        small
        primary
        text={approvalButtonText || 'Aprovar'}
        onClick={showSingleInputModal}
      />
    );
  }

  function renderButtonRepproval() {
    const { disableApproval } = props;

    if (approval.status === StatusApproval.REEVALUATE) {
      if (approval.idOcorrencia) {
        return (
          <Button
            small
            danger
            text="Reprovar"
            onClick={handleShowRepprovalReevaluateModal({ seq: 1 })}
          />
        );
      }

      const { onRepproval } = props;

      return (
        <Button small danger text="Reprovar" onClick={onRepproval(approval)} />
      );
    }

    if (disableApproval || approval.idAprovacao) {
      return null;
    }

    const { onRepproval } = props;

    return (
      <Button small danger text="Reprovar" onClick={onRepproval(approval)} />
    );
  }

  function renderButtonCancelApproval() {
    if (
      !approval.allowApproval ||
      !approval.idAprovacao ||
      approval.status === StatusApproval.REEVALUATE
    ) {
      return null;
    }

    const { onCancelApproval } = props;

    return <CancelApprovalIcon onClick={onCancelApproval(approval)} />;
  }

  function renderButtonReply() {
    if (!approval.idOcorrencia || approval.status === StatusApproval.APPROVED) {
      return null;
    }

    return (
      <Button
        outline
        primary
        small
        text="Responder"
        onClick={handleShowReplyModal({ seq: 1 })}
      />
    );
  }

  function renderButtonEditObservation() {
    if (
      !approval.allowApproval ||
      !approval.idAprovacao ||
      approval.status === StatusApproval.REPPROVED
    ) {
      return null;
    }

    const onSave = async (observacao: string | undefined) => {
      if (
        approval.status === StatusApproval.REPPROVED &&
        !observacao?.trim().length
      ) {
        addToast('Observação obrigatória', { appearance: 'warning' });

        return;
      }

      const { onEdit } = props;

      await onEdit({
        idAprovacao: approval.idAprovacao,
        observacaoOriginal: observacao || '',
      });

      dispatch(closeModal(ModalName.SINGLE_INPUT));
    };

    const showSingleInputModal = () => {
      dispatch(
        showModal<SingleInputModalParams>(ModalName.SINGLE_INPUT, {
          value: approval.observacaoOriginal,
          title: `Observação`,
          onSave,
        }),
      );
    };

    return <Button small text="Editar" onClick={showSingleInputModal} />;
  }

  function renderActionButtons() {
    if (!approval.allowApproval) {
      return null;
    }

    return (
      <>
        {renderButtonApproval()}
        {renderButtonRepproval()}
      </>
    );
  }

  function renderStatus() {
    if (!approval.idAprovacao) {
      return <div>-</div>;
    }

    return (
      <StatusWrapper>
        <Badge
          text={STATUS_ITEMS[approval.status || StatusApproval.REPPROVED].label}
          backgroundColor={
            STATUS_ITEMS[approval.status || StatusApproval.REPPROVED].color
          }
        />
      </StatusWrapper>
    );
  }

  function getObservationText(approval: ApprovalLevel) {
    if (approval.status === StatusApproval.APPROVED) {
      return approval.observacaoOriginal || '-';
    }

    return (
      approval.observacaoComplementar || approval.observacaoOriginal || '-'
    );
  }

  return (
    <Container key={approval.idNivelAprovacao}>
      <CardTag>{approval.nivel}</CardTag>
      <Card>
        <Row>
          <GroupWrapper>
            <LabelInfo>Nome</LabelInfo>
            <Text>{approval.nomeUsuario || '-'}</Text>
          </GroupWrapper>

          <GroupWrapper>
            <LabelInfo>Data</LabelInfo>
            <Text>{approval.dtAprovacao || '-'}</Text>
          </GroupWrapper>

          <GroupWrapper>
            <LabelInfo>Status</LabelInfo>
            <Row>
              {renderButtonCancelApproval()}
              {renderStatus()}
            </Row>
          </GroupWrapper>
        </Row>

        <Row>
          <GroupWrapper>
            <LabelInfo>Observação</LabelInfo>
            <Text>{getObservationText(approval)}</Text>
          </GroupWrapper>
        </Row>

        <Row>
          <ActionsWrapper>
            <div>{renderButtonEditObservation()}</div>
            <div>
              {renderActionButtons()}
              {renderButtonReply()}
            </div>
          </ActionsWrapper>
        </Row>

        <OccurrenceReplyList
          isLoadingReply={isLoadingReply}
          replies={replies}
          onShowReplyModal={handleShowReplyModal}
        />
      </Card>
    </Container>
  );
}
