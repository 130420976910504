import { useFormik } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useToasts } from 'react-toast-notifications';

import CheckBox from 'components/CheckBox';
import { getSingleResponseAdonis } from 'services/dataset';
import { createUserResponsible, create } from 'services/user';

import Modal from '..';
import useModal from 'hooks/use-modal';

import { closeModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { Content } from '../Calendar/styles';

import { SubmitButton } from '../Suprimentos/EditAntecedencesModal/styles';
import Input from 'components/Input';
import { validation } from './validation';
import {
  ButtonClose,
  ModalContainer,
  ModalHeader,
  ModalTitle,
} from 'styles/modal';
import { generateUUID } from 'utils/common';
import useContract from 'hooks/use-contract';
import { DivFooterButtons } from './styles';
import {
  FormUser,
  ModalParams,
} from 'pages/UsersManagement/components/CreateUserModal/types';

const emptyInitialValues = {
  nome: '',
  sobrenome: '',
  email: '',
  flagStatus: true,
  idEmpresa: 0,
  uuid: '',
  flagResponsavel: true,
};

export default function CreateUserSimpleModal(): ReactElement {
  const dispatch = useDispatch();
  const contract = useContract();

  const [initialValues, setInitialValues] = useState<FormUser>(
    emptyInitialValues,
  );

  const { isOpen, params, windowSec } = useModal<ModalParams>(
    ModalName.CREATE_USER_SIMPLE,
  );

  const { idOrUuidUser, idEmpresa, afterSubmit, nome, idContrato } =
    params || {};
  const { addToast } = useToasts();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: data => submit(data, formik.setSubmitting),
  });

  useEffect(() => {
    if (!isOpen) {
      setInitialValues(emptyInitialValues);
    }

    getData();
  }, [isOpen, idOrUuidUser]);

  async function getData() {
    if (!idOrUuidUser) {
      if (nome) setInitialValues({ ...initialValues, nome });

      return;
    }

    const user = await getSingleResponseAdonis<FormUser>(2819, {
      idOrUuidUser,
    });

    setInitialValues(user);
  }

  async function submit(
    values: FormUser,
    setSubmitting: (isSubmitting: boolean) => void,
  ) {
    setSubmitting(true);

    try {
      let newUserData: FormUser = { ...values };

      if (idOrUuidUser) {
        newUserData = {
          ...newUserData,
          flagStatus: Number(values.flagStatus),
        };

        await create(newUserData);
      } else {
        newUserData = {
          ...newUserData,
          idEmpresa: idEmpresa!,
          uuid: generateUUID(),
          flagResponsavel: Number(values.flagResponsavel),
          flagStatus: Number(values.flagStatus),
        };
        const reponseUser = await createUserResponsible({
          payload: [newUserData],
          idContrato: idContrato || contract!.idContrato,
        });

        newUserData = {
          ...newUserData,
          uuid: reponseUser[0]?.uuidOnline
            ? reponseUser[0]?.uuidOnline
            : newUserData.uuid,
          idUsuario: reponseUser[0].idUsuario,
        };
      }

      addToast(
        idOrUuidUser
          ? 'Alterações salvas com sucesso!'
          : 'Usuário criado com sucesso!',
        {
          appearance: 'success',
        },
      );

      setInitialValues(emptyInitialValues);

      handleClose();

      if (afterSubmit) {
        afterSubmit(newUserData);
      }
    } catch (errors) {
      const errorList = errors as Error[];

      errorList.forEach(error => {
        addToast(error, { appearance: 'error' });
      });
    }

    setSubmitting(false);
  }

  function handleClose() {
    dispatch(closeModal(ModalName.CREATE_USER_SIMPLE));
  }

  return (
    <Modal isOpen={isOpen} windowSec={windowSec}>
      <ModalHeader>
        <ButtonClose onClick={handleClose} />
        <ModalTitle>
          {idOrUuidUser ? 'Editar usuário' : 'Criar usuário'}
        </ModalTitle>
      </ModalHeader>
      <ModalContainer width="50rem">
        <form onSubmit={formik.handleSubmit}>
          <Content>
            <Input
              name="nome"
              type="text"
              label="Nome"
              errorMessage={formik.errors.nome}
              value={formik.values.nome}
              onChange={formik.handleChange}
            />
            <Input
              name="sobrenome"
              type="text"
              label="Sobrenome"
              errorMessage={formik.errors.sobrenome}
              value={formik.values.sobrenome}
              onChange={formik.handleChange}
            />
            <Input
              name="email"
              type="email"
              label="E-mail"
              errorMessage={formik.errors.email}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </Content>

          <DivFooterButtons>
            {idOrUuidUser && (
              <CheckBox
                id="checkbox"
                label="Desabilitar/Habilitar"
                checked={!!formik.values.flagStatus}
                onChange={() =>
                  formik.setFieldValue('flagStatus', !formik.values.flagStatus)
                }
              />
            )}

            <SubmitButton
              text="Salvar"
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
              primary
              type="submit"
            />
          </DivFooterButtons>
        </form>
      </ModalContainer>
    </Modal>
  );
}
