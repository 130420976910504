import styled from 'styled-components';
import { Card } from 'styles/card';

export const Container = styled(Card)`
  margin-bottom: 1rem;
  height: auto;
`;

export const Row = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: relative;
`;

export const Image = styled.img`
  width: 100px;
  position: absolute;
  right: 1rem;
  top: 0;
  width: 15rem;
  position: absolute;
  transform: translateY(-50%);
`;
