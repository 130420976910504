import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const DashboardFisicoFVS = lazy(() =>
  import(
    './layouts/fisicoFvs' /* webpackChunkName: 'relatorios.gescorpgo.fisico-fvs' */
  ),
);
const FisicoDash = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.gescorpgo.fisico.gerencial' */
  ),
);
const Gerencial = lazy(() =>
  import(
    './layouts/gerencial' /* webpackChunkName: 'relatorios.gescorpgo.gerencial' */
  ),
);
const GerencialV2 = lazy(() =>
  import(
    './layouts/gerencialv2' /* webpackChunkName: 'relatorios.gescorpgo.gerencialv2' */
  ),
);
const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'relatorios.gescorpgo.financeiro.obras' */
  ),
);
const FinanceiroDashboardContrato = lazy(() =>
  import(
    './layouts/financeiro/dashboardContrato' /* webpackChunkName: 'relatorios.gescorpgo.financeiro.dashboardcontrato' */
  ),
);
const FinanceiroDashboardIncorrido = lazy(() =>
  import(
    './layouts/financeiro/dashboardIncorrido' /* webpackChunkName: 'relatorios.gescorpgo.financeiro.dashboardincorrido' */
  ),
);
const FinanceiroDashboardTendencia = lazy(() =>
  import(
    './layouts/financeiro/dashboardTendencia' /* webpackChunkName: 'relatorios.gescorpgo.financeiro.dashboardtendencia' */
  ),
);
const FisicoPortfolio = lazy(() =>
  import(
    './layouts/fisico/portfolio' /* webpackChunkName: 'relatorios.gescorpgo.fisico.portfolio' */
  ),
);
const ChecklistQualidade = lazy(() =>
  import(
    './layouts/qualidade/checklist' /* webpackChunkName: 'relatorios.gescorpgo.qualidade.checklist-de-qualidade' */
  ),
);
const DashboardGeral = lazy(() =>
  import(
    './layouts/qualidade/dashboardGeral' /* webpackChunkName: 'relatorios.gescorpgo.qualidade.dashboard-geral' */
  ),
);
const InspecaoQualidade = lazy(() =>
  import(
    './layouts/qualidade/inspecaoQualidade' /* webpackChunkName: 'relatorios.gescorpgo.qualidade.inspecao-qualidade' */
  ),
);
const GeralDeSeguranca = lazy(() =>
  import(
    './layouts/seguranca/relatorioGeralDeSeguranca' /* webpackChunkName: 'relatorios.gescorpgo.seguranca.relatorio-geral-de-seguranca' */
  ),
);
const Checklist = lazy(() =>
  import(
    './layouts/seguranca/checklist' /* webpackChunkName: 'relatorios.gescorpgo.seguranca.checklist-de-seguranca' */
  ),
);
const InspecaoSeguranca = lazy(() =>
  import(
    './layouts/seguranca/inspecaoSeguranca' /* webpackChunkName: 'relatorios.gescorpgo.seguranca.inspecao-seguranca' */
  ),
);
const DashboardAcidentes = lazy(() =>
  import(
    './layouts/seguranca/DashboardAcidentes' /* webpackChunkName: 'relatorios.gescorpgo.seguranca.dashboard-acidentes' */
  ),
);
const DashboardEquipamentos = lazy(() =>
  import(
    './layouts/seguranca/DashboardEquipamentos' /* webpackChunkName: 'relatorios.gescorpgo.seguranca.dashboard-equipamentos' */
  ),
);
const UtilizacaoComportamental = lazy(() =>
  import(
    './layouts/seguranca/UtilizacaoComportamental' /* webpackChunkName: 'relatorios.go.seguranca.utilizacao-comportamental' */
  ),
);
const DashboardIPS = lazy(() =>
  import(
    './layouts/seguranca/IPS' /* webpackChunkName: 'relatorios.go.seguranca.comportamental-dashboard' */
  ),
);
const ComportamentalDashboard = lazy(() =>
  import(
    './layouts/seguranca/ComportamentalDashboard' /* webpackChunkName: 'relatorios.go.seguranca.comportamental-dashboard' */
  ),
);
const ChecklistSeguranca = lazy(() =>
  import(
    './layouts/seguranca/ChecklistSeguranca' /* webpackChunkName: 'relatorios.go.seguranca.checklist-de-seguranca' */
  ),
);
const IndicadorDesempenho = lazy(() =>
  import(
    './layouts/seguranca/indicadorDesempenho' /* webpackChunkName: 'gescorpgo.seguranca.indicador-desempenho' */
  ),
);
const VistoriaVias = lazy(() =>
  import(
    './layouts/ccop/vistoriaVias' /* webpackChunkName: 'gescorpgo.seguranca.indicador-desempenho' */
  ),
);
const AcompanhamentoProducao = lazy(() =>
  import(
    './layouts/ccop/acompanhamentoProducao' /* webpackChunkName: 'gescorpgo.seguranca.acompanhamento-de-producao' */
  ),
);
const DashboardMateriais = lazy(() =>
  import(
    './layouts/fvm/materiais' /* webpackChunkName: 'relatorios.mitre.fvm.dashboard-materiais' */
  ),
);
const Rdo = lazy(() =>
  import('./layouts/rdo' /* webpackChunkName: 'relatorios.gescorpgo.rdo' */),
);
const DashboardIncidentes = lazy(() =>
  import(
    './layouts/seguranca/DashboardIncidentes' /* webpackChunkName: 'relatorios.gescorpgo.seguranca.dashboard-incidentes' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/go/fisico-fvs"
    component={DashboardFisicoFVS}
    path="/relatorios/go/fisico-fvs"
  />,
  <PrivateRoute
    key="/relatorios/gescorpgo/fisico/gerencial"
    component={FisicoDash}
    path="/relatorios/gescorpgo/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/gescorpgo/gerencial"
    component={Gerencial}
    path="/relatorios/gescorpgo/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/gescorpgo/gerencialv2"
    component={GerencialV2}
    path="/relatorios/gescorpgo/gerencialv2"
  />,
  <PrivateRoute
    key="/relatorios/gescorpgo/gerencialv2-cliente"
    component={GerencialV2}
    path="/relatorios/gescorpgo/gerencialv2-cliente"
  />,
  <PrivateRoute
    key="/relatorios/gescorpgo/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/gescorpgo/financeiro/obras"
  />,
  <PrivateRoute
    key="/relatorios/ggo/financeiro/dashboardcontrato"
    component={FinanceiroDashboardContrato}
    path="/relatorios/ggo/financeiro/dashboardcontrato"
  />,
  <PrivateRoute
    key="/relatorios/ggo/financeiro/dashboardincorrido"
    component={FinanceiroDashboardIncorrido}
    path="/relatorios/ggo/financeiro/dashboardincorrido"
  />,
  <PrivateRoute
    key="/relatorios/ggo/financeiro/dashboardtendencia"
    component={FinanceiroDashboardTendencia}
    path="/relatorios/ggo/financeiro/dashboardtendencia"
  />,
  <PrivateRoute
    key="/relatorios/gescorpgo/fisico/portfolio"
    component={FisicoPortfolio}
    path="/relatorios/gescorpgo/fisico/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/go/qualidade/checklist-de-qualidade"
    component={ChecklistQualidade}
    path="/relatorios/go/qualidade/checklist-de-qualidade"
  />,
  <PrivateRoute
    key="/relatorios/go/qualidade/dashboard-geral"
    component={DashboardGeral}
    path="/relatorios/go/qualidade/dashboard-geral"
  />,
  <PrivateRoute
    key="/relatorios/go/qualidade/inspecao-qualidade"
    component={InspecaoQualidade}
    path="/relatorios/go/qualidade/inspecao-qualidade"
  />,
  <PrivateRoute
    key="/relatorios/go/seguranca/relatorio-geral-de-seguranca"
    component={GeralDeSeguranca}
    path="/relatorios/go/seguranca/relatorio-geral-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/go/seguranca/checklist-de-seguranca"
    component={Checklist}
    path="/relatorios/go/seguranca/checklist-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/go/seguranca/inspecao-seguranca"
    component={InspecaoSeguranca}
    path="/relatorios/go/seguranca/inspecao-seguranca"
  />,

  // TODO: George remover os dois
  <PrivateRoute
    key="/relatorios/go/seguranca/inspecao-seguranca-geral"
    component={InspecaoSeguranca}
    path="/relatorios/go/seguranca/inspecao-seguranca-geral"
  />,
  <PrivateRoute
    key="/relatorios/go/seguranca/inspecao-seguranca-altura"
    component={InspecaoSeguranca}
    path="/relatorios/go/seguranca/inspecao-seguranca-altura"
  />,

  <PrivateRoute
    key="/relatorios/gescorpgo/seguranca/dashboard-acidentes"
    component={DashboardAcidentes}
    path="/relatorios/gescorpgo/seguranca/dashboard-acidentes"
  />,
  <PrivateRoute
    key="/relatorios/seguranca/dashboard-equipamentos"
    component={DashboardEquipamentos}
    path="/relatorios/seguranca/dashboard-equipamentos"
  />,
  <PrivateRoute
    key="/relatorios/seguranca/ips"
    component={DashboardIPS}
    path="/relatorios/seguranca/ips"
  />,
  <PrivateRoute
    key="/relatorios/seguranca/comportamental-dashboard"
    component={ComportamentalDashboard}
    path="/relatorios/seguranca/comportamental-dashboard"
  />,
  <PrivateRoute
    key="/relatorios/seguranca/utilizacao-comportamental"
    component={UtilizacaoComportamental}
    path="/relatorios/seguranca/utilizacao-comportamental"
  />,
  <PrivateRoute
    key="/relatorios/seguranca/checklist-de-seguranca"
    component={ChecklistSeguranca}
    path="/relatorios/seguranca/checklist-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/seguranca/relatorio-geral-de-seguranca"
    component={GeralDeSeguranca}
    path="/relatorios/seguranca/relatorio-geral-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/seguranca/indicador-desempenho"
    component={IndicadorDesempenho}
    path="/relatorios/seguranca/indicador-desempenho"
  />,
  <PrivateRoute
    key="/relatorios/vistoria-vias"
    component={VistoriaVias}
    path="/relatorios/vistoria-vias"
  />,
  <PrivateRoute
    key="/relatorios/acompanhamento-de-producao"
    component={AcompanhamentoProducao}
    path="/relatorios/acompanhamento-de-producao"
  />,
  <PrivateRoute
    key="/relatorios/gescorpgo/fvm/avaliacao-fornecedores"
    component={DashboardMateriais}
    path="/relatorios/gescorpgo/fvm/avaliacao-fornecedores"
  />,
  <PrivateRoute
    key="/relatorios/gescorpgo/rdo"
    component={Rdo}
    path="/relatorios/gescorpgo/rdo"
  />,
  <PrivateRoute
    key="/relatorios/gescorpgo/seguranca/incidentes"
    component={DashboardIncidentes}
    path="/relatorios/gescorpgo/seguranca/incidentes"
  />,
];
