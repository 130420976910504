import {
  MdEdit,
  MdFileDownload,
  MdMoreVert,
  MdVisibility,
} from 'react-icons/md';
import styled from 'styled-components';
import ButtonComponent from '../../Button';
import PopupActionsComponent from '../../PopupActions';

export const IconDownload = styled(MdFileDownload)`
  padding-right: 0.5rem;
  font-size: 2rem;
`;

export const EditIcon = styled(MdEdit)`
  padding-right: 0.5rem;
  font-size: 2rem;
`;

export const ViewerIcon = styled(MdVisibility)`
  padding-right: 0.5rem;
  font-size: 2rem;
`;

export const ContainerFiles = styled.div`
  margin-top: 1.2rem;
  border: 1px solid #eee;
  border-radius: ${({ theme }) => theme.radius.medium}px;
  padding: 1rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-top: 1rem;

  div {
    align-items: center;
  }
`;

export const IconOptions = styled(MdMoreVert)`
  color: #333;
  cursor: pointer;
  font-size: 1.8rem;
`;

export const PopupActions = styled(PopupActionsComponent)`
  position: absolute;
  right: 0;
  top: -7rem;
`;

export const Button = styled(ButtonComponent)`
  margin-left: 0.5rem;
`;

export const FileNameWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  & > div {
    margin-right: 0.5rem;
    width: 2.5rem;
  }

  img {
    width: 100%;
  }
`;

export const AppendedDataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const AppendedDataChip = styled.div`
  background: #ececec;
  padding: 0 1rem;
  border-radius: 2rem;
  margin-top: 0.5rem;
  margin-right: 1rem;
`;

export const AppendedDataTitle = styled.strong`
  font-weight: bold;
  text-transform: capitalize;
  color: #000;
`;

export const CategoryWrapper = styled.div`
  margin-top: 1rem;
`;

export const CategoryTitle = styled.strong`
  font-size: 1.5rem;
`;

export const LoadingFilesWarning = styled.span`
  color: #777;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
