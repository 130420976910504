import { ImagePropsParams } from 'components/Modal/ImageViewer';
import useModal from 'hooks/use-modal';
import React from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { GalleryPhoto, ParamsGallery } from '../../types';
import { normalizeImagesForImageViewer } from '../utils';

import Card from './components/Card';
import { ContainerImgMaster, ContainerImgSub, Content } from './styles';
import { useSetAtom } from 'jotai';
import { fetchImagesAtom } from '../../atom';
import useUser from 'hooks/use-user';

interface Props {
  mainImage: GalleryPhoto;
}
export default function Gallery(props: Props) {
  const { mainImage } = props;

  const { params } = useModal<ParamsGallery>(ModalName.PHOTO_GALLERY_COMMENTS);

  const fetchImages = useSetAtom(fetchImagesAtom);

  const dispatch = useDispatch();
  const user = useUser();

  function handleShowImageViewer(imageKey: string) {
    return () => {
      const handleCloseCB = () => {
        const { idContrato, uuidOcorrencia, tipo } = params;

        fetchImages({
          idContrato,
          uuidOcorrencia,
          tipo,
        });
      };

      const imagesForPreview = normalizeImagesForImageViewer(
        [mainImage, ...mainImage.replyImagens],
        user.idAcoes,
        user.idUsuario!,
        user.idPerfilModulo!,
      );

      dispatch(
        showModal<ImagePropsParams>(ModalName.IMAGE_VIEWER, {
          images: imagesForPreview,
          initialCurrentImageIndex: imagesForPreview.findIndex(
            image => image.key === imageKey,
          ),
          onCloseCB: handleCloseCB,
        }),
      );
    };
  }

  return (
    <Content>
      <ContainerImgMaster>
        <Card
          image={mainImage}
          onShowImageViewer={handleShowImageViewer(mainImage.key.toString())}
        />
      </ContainerImgMaster>

      <ContainerImgSub>
        {mainImage.replyImagens.map(item => (
          <Card
            small
            image={item}
            key={item.key.toString()}
            onShowImageViewer={handleShowImageViewer(item.key.toString())}
          />
        ))}
      </ContainerImgSub>
    </Content>
  );
}
