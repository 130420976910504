import React from 'react';
import { RoundedIcon } from '../../../../../../../TableReport/components/Icons/index';
import { StyledDiv } from './styles';

export default function ArrowsType(props) {
  const { value } = props;

  function renderIcon() {
    if (value === 1) {
      return <RoundedIcon containerColor="red" />;
    }
    if (value === 2) {
      return <RoundedIcon containerColor="yellow" />;
    }
    if (value === 3) {
      return <RoundedIcon containerColor="green" />;
    }
  }
  return <StyledDiv className="celltype-farol">{renderIcon()}</StyledDiv>;
}
