import React from 'react';
import WarningPageContainer from '../WarningPageContainer';

export default function WarningInvalidTokenPage() {
  return (
    <WarningPageContainer>
      <h1>Ops, seu link expirou!</h1>

      <p>
        O link que você recebeu por e-mail tinha validade de <b>7 dias </b>e
        expirou.
      </p>

      <br />

      <p>
        Fique tranquilo! já enviamos um novo e-mail com um link válido para você
        assinar o documento.
      </p>
    </WarningPageContainer>
  );
}
