import showAlert from 'components/Alert';
import {
  fetchImagesAtom,
  onShowUploadImagesModalAtom,
} from 'components/Modal/PhotoGalleryComments/atom';
import {
  GalleryPhoto,
  ParamsGallery,
} from 'components/Modal/PhotoGalleryComments/types';
import { SingleInputModalParams } from 'components/Modal/SingleInput';
import useModal from 'hooks/use-modal';
import useUser from 'hooks/use-user';
import { useSetAtom } from 'jotai';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { addNew, editNew } from 'services/comment';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { Text } from 'styles/text';
import { generateUUID } from 'utils/common';
import { getUsDateTime, toDateTimeBR } from 'utils/date';
import { normalizeFileUrl } from 'utils/file';
import {
  Button,
  ContainerCard,
  ContainerComment,
  ContainerFlex,
  ContainerNoPhoto,
  ImgCard,
  NoImageText,
} from './styles';
import { checkUserAllowedEdit } from '../../../utils';

interface Props {
  image: GalleryPhoto;
  small?: boolean;
  onShowImageViewer(): void;
}

const TYPE = 'RESPOSTA_FOTO';

export default function Card(props: Props) {
  const { params } = useModal<ParamsGallery>(ModalName.PHOTO_GALLERY_COMMENTS);

  const onShowUploadImagesModal = useSetAtom(onShowUploadImagesModalAtom);
  const fetchImages = useSetAtom(fetchImagesAtom);

  const { image, small, onShowImageViewer } = props;

  const dispatch = useDispatch();
  const user = useUser();
  const { addToast } = useToasts();

  function renderImage() {
    if (!image.referenciaFoto) {
      return (
        <ContainerNoPhoto small={small}>
          <NoImageText>sem foto</NoImageText>
        </ContainerNoPhoto>
      );
    }

    return (
      <ImgCard small={small} src={normalizeFileUrl(image.referenciaFoto)} />
    );
  }

  function handleShowImageViewer() {
    if (!image.referenciaFoto) {
      handleShowSingleInput();

      return;
    }

    onShowImageViewer();
  }

  function handleShowUploadImagesModal() {
    const { idContrato, uuidOcorrencia } = params;

    onShowUploadImagesModal({
      idContrato,
      tipo: TYPE,
      idReferencia: +image.key,
      uuidReferencia: image.uuidRaiz,
      uuidOcorrencia: uuidOcorrencia,
    });
  }

  function handleShowSingleInput() {
    const { idContrato, uuidOcorrencia } = params;

    const isMainImage = image.nivel === 'pai';

    const handleSave = async (comment: string) => {
      dispatch(showModal(ModalName.LOADING, { text: 'Salvando comentário' }));

      if (isMainImage || !image.uuidComentario) {
        await addNew({
          id: 0,
          idContrato,
          tipo: TYPE,
          origem: 'FOTOS',
          comentario: comment,
          uuid: generateUUID(),
          idUsuario: user.idUsuario!,
          uuidReferencia: image.uuidRaiz,
          dtCriacao: getUsDateTime(),
        });
      } else {
        await editNew({
          idContrato,
          flagStatus: 1,
          comentario: comment,
          uuid: image.uuidComentario,
        });
      }

      await fetchImages({ idContrato, tipo: TYPE, uuidOcorrencia });

      dispatch(closeModal(ModalName.SINGLE_INPUT));
      dispatch(closeModal(ModalName.LOADING));

      addToast('Comentário salvo com sucesso', { appearance: 'success' });
    };

    const handleRemoveComment = async () => {
      dispatch(showModal(ModalName.LOADING, { text: 'Removendo comentário' }));

      await editNew({
        idContrato,
        flagStatus: 0,
        comentario: image.comentario,
        uuid: image.uuidComentario,
      });

      await fetchImages({ idContrato, tipo: TYPE, uuidOcorrencia });

      dispatch(closeModal(ModalName.SINGLE_INPUT));
      dispatch(closeModal(ModalName.LOADING));

      addToast('Comentário removido com sucesso', { appearance: 'success' });
    };

    let isAllowEdit = checkUserAllowedEdit(
      false,
      user.idAcoes,
      image.idUsuario,
      user.idUsuario!,
      user.idPerfilModulo!,
    );

    isAllowEdit = isMainImage ? true : isAllowEdit;

    const isRemoveDisabled = isMainImage || !image.uuidComentario;

    dispatch(
      showModal<SingleInputModalParams>(ModalName.SINGLE_INPUT, {
        title: 'Comentário de resposta',
        value: isMainImage ? '' : image.comentario,
        onSave: isAllowEdit ? handleSave : undefined,
        onRemove:
          isRemoveDisabled || !isAllowEdit ? undefined : handleRemoveComment,
      }),
    );
  }

  function handleRelpy() {
    showAlert({
      title: 'Responder com',
      actions: [
        {
          text: 'Apenas comentário',
          onClick: handleShowSingleInput,
        },
        {
          text: 'Imagens',
          onClick: handleShowUploadImagesModal,
        },
      ],
    });
  }

  function renderUploadButton() {
    if (image.nivel === 'pai') {
      return <Button small primary text="Responder" onClick={handleRelpy} />;
    }
  }

  return (
    <ContainerCard small={small}>
      <div onClick={handleShowImageViewer}>
        <ContainerFlex>
          <div>
            <Text truncate style={{ marginRight: '1rem' }}>
              {image.usuario}
            </Text>
          </div>
          <div>
            <Text>{toDateTimeBR(image.dtCriacao)}</Text>
          </div>
        </ContainerFlex>

        {renderImage()}

        <ContainerComment>
          <Text truncate>{image.comentario}</Text>
        </ContainerComment>
      </div>

      {renderUploadButton()}
    </ContainerCard>
  );
}
