import styled from 'styled-components';

export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 2px;
`;

export const InputEmailWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  gap: 0.5rem;
`;

export const SelectContainer = styled.div`
  width: 100%;
`;

export const AddedEmail = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-inline: 1rem;
  gap: 0.5rem;

  svg {
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-top: 3px;
    color: #ccc;

    &:hover {
      color: #333;
    }
  }
`;
