import { Card } from 'styles/card';
import styled from 'styled-components';
import { Text } from 'styles/text';
import ButtonComponent from 'components/Button';

export const ContainerCard = styled(Card)`
  height: auto;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  height: ${({ small }) => (small ? '25rem' : '100%')};
  width: ${({ small }) => (small ? '33rem' : '100%')};

  &:hover {
    box-shadow: 3px 4px 5px rgb(0 0 0 / 20%);
    transform: translateY(-1px);
  }
`;

export const ImgCard = styled.img`
  width: ${({ small }) => (small ? '31rem' : '100%')};
  height: ${({ small }) => (small ? '17rem' : '42.6rem')};
  object-fit: contain;
`;

export const ContainerComment = styled.div`
  margin-top: 5px;
  height: 20px;
  padding: 0.05rem;
  border-top: 1px solid #eee;
`;

export const ContainerFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  div {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ContainerNoPhoto = styled.div`
  width: 7rem;
  height: ${({ small }) => (small ? '17rem' : ' 42.6rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

export const NoImageText = styled(Text)`
  color: #ccc;
`;

export const Button = styled(ButtonComponent)`
  margin-left: auto;
`;
