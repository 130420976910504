import {
  ConfigActionTypes,
  SET_IS_IMPERSONATE,
  CLEAR_IS_IMPERSONATE,
} from './types';

export function setIsImpersonate(): ConfigActionTypes {
  return {
    type: SET_IS_IMPERSONATE,
    payload: true,
  };
}

export function clearIsImpersonate(): ConfigActionTypes {
  return {
    type: CLEAR_IS_IMPERSONATE,
    payload: false,
  };
}
