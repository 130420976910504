import Button from 'components/Button';
import useDeviceDetect from 'hooks/use-device-detect';
import { useAtomValue, useSetAtom } from 'jotai';
import { currentStepAtom, urlParamsAtom } from 'pages/DocSign/atoms';
import { CancelIcon, GoBackIcon, IconCheck } from 'pages/DocSign/styles';
import { handleConfirmSignCancel, isSourceApp } from 'pages/DocSign/utils';
import React from 'react';
import { Container, Footer, WrapperDoc } from './styles';
import { getPageContainerSize } from './utils';
import { getSpacesFolder, normalizeFileUrl } from 'utils/file';
import { EnumStep } from 'pages/DocSign/types';
import { useToasts } from 'react-toast-notifications';
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Inject,
  ThumbnailView,
  TextSearch,
  Navigation,
} from '@syncfusion/ej2-react-pdfviewer';

let viewer: PdfViewerComponent;

export default function PdfViewer() {
  const deviceSize = useDeviceDetect();
  const urlParams = useAtomValue(urlParamsAtom);

  const { addToast } = useToasts();

  const setCurrentStep = useSetAtom(currentStepAtom);

  function handleClick(page) {
    setCurrentStep(page);
  }

  function getFileURL() {
    return normalizeFileUrl(
      `${getSpacesFolder()}/datafiles/reports/signed/${
        urlParams!.uuidArquivo
      }.pdf`,
    );
  }

  function handleRedirectCancelPage() {
    if (isSourceApp(urlParams)) {
      setCurrentStep(EnumStep.SUBSCRIBER_LIST);

      addToast('Assinatura cancelada com sucesso.', {
        appearance: 'success',
      });

      return;
    }

    setCurrentStep(EnumStep.REGISTER_SUBSCRIBERS_CANCEL);
  }

  function created() {
    viewer.toolbarSettings = {
      toolbarItems: ['MagnificationTool', 'PageNavigationTool', 'PanTool'],
    };
    viewer.dataBind();
  }

  return (
    <Container
      height={getPageContainerSize(isSourceApp(urlParams), deviceSize)}
    >
      <WrapperDoc isDesktop={deviceSize.isDesktop}>
        <PdfViewerComponent
          ref={scope => {
            viewer = scope;
          }}
          documentPath={getFileURL()}
          resourceUrl="https://cdn.syncfusion.com/ej2/23.2.6/dist/ej2-pdfviewer-lib"
          created={created}
          style={{ height: '100%' }}
        >
          <Inject
            services={[
              Toolbar,
              Navigation,
              Magnification,
              ThumbnailView,
              TextSearch,
            ]}
          />
        </PdfViewerComponent>
      </WrapperDoc>
      <Footer>
        <Button
          text="Voltar"
          onClick={() => handleClick(EnumStep.SUBSCRIBER_DOC)}
          small
          icon={<GoBackIcon />}
        />

        <Button
          onClick={() => handleConfirmSignCancel(handleRedirectCancelPage)}
          text="Cancelar"
          icon={<CancelIcon />}
          small
        />
        <Button
          primary
          text="Avançar"
          onClick={() => handleClick(EnumStep.SIGNATURE_PAGE)}
          small
          icon={<IconCheck />}
        />
      </Footer>
    </Container>
  );
}
