import styled from 'styled-components';
import { MdDoneAll, MdDeleteForever, MdEdit } from 'react-icons/md';

export const Container = styled.div<{
  fixed?: boolean;
}>`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  background-color: #fff;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 0.8rem;
  overflow: hidden;
  white-space: nowrap;

  button {
    background-color: transparent;
    border: none;
    outline: none;
  }
`;

export const ActionWrapper = styled.div`
  padding: 0.5rem 0.5rem;

  &:not(:first-child) {
    border-top: 1px solid #f5f5f5;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const ActionButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
`;

export const ActionText = styled.span`
  font-weight: 500;
  padding-right: 0.5rem;
`;

export const IconCheckAll = styled(MdDoneAll)`
  padding-right: 0.5rem;
  font-size: 2rem;
`;

export const IconTrash = styled(MdDeleteForever)`
  padding-right: 0.5rem;
  font-size: 2rem;
`;

export const Edit = styled(MdEdit)`
  margin-right: 0.5rem;
  margin-left: 0.2rem;
`;
