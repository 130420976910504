import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalStyles } from '../../global-styles';
import { RootState } from '../../store/rootReducer';

export default function GlobalStylesWrapper() {
  const styleAux = useSelector(
    (state: RootState) => state.config.customGlobalStyles,
  );

  return <GlobalStyles customGlobalStyle={styleAux} />;
}
