import React, { useEffect, useState } from 'react';
import Span from 'components/Span';
import { InputCurrency } from './styles';
import { DynamicFormsInputProps } from '../../types';
import { useDynamicForm } from '../../context';
import useUser from 'hooks/use-user';
import { checkUserEditPermission } from '../../utils';

import { currencyToNumber } from 'utils/common';

export default function InputCurrencyComponent(props: DynamicFormsInputProps) {
  const { field, defaultValue, isRequired, onChange, isDisabled } = props;
  const [value, setValue] = useState(defaultValue);
  const { approvalData, fieldsAllowProps } = useDynamicForm();

  const user = useUser();

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    const normalizedValue =
      defaultValue == 0 || defaultValue ? defaultValue : '';

    setValue(normalizedValue);
  }, [defaultValue]);

  function handleChange(newValue: string | undefined) {
    onChange(field.name, currencyToNumber(newValue));
    setValue(newValue);
  }

  return (
    <>
      <InputCurrency
        label={`${field.label}:`}
        disabled={isDisableChecked}
        id={field.name}
        name={field.name}
        onValueChange={handleChange}
        value={value}
        allowNegativeValue={false}
        decimalScale={2}
        decimalSeparator=","
        groupSeparator="."
        intlConfig={{ locale: 'pt-br', currency: 'BRL' }}
      />

      {isRequired && <Span text="Campo obrigatório" />}
    </>
  );
}
