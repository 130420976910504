import { Card as CardComponent } from 'styles/card';
import styled from 'styled-components';
import { RemoveIcon } from 'styles/icons';
import Button from 'components/Button';
import TextArea from 'components/TextArea';

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

export const StatusWrapper = styled.div``;

export const Card = styled(CardComponent)`
  padding: 1rem;
  margin-bottom: 1rem;

  overflow: visible;
`;

export const GroupWrapper = styled.div<{
  width?: string;
  gap?: string;
}>`
  display: flex;
  flex-direction: column;

  min-width: ${({ width }) => width || 'auto'};
  gap: ${({ gap }) => gap};
`;

export const Container = styled.div`
  margin: 0.5rem 0;
`;

export const CardTag = styled.div`
  background-color: #e0f2f1;
  padding: 0.2rem 0.4rem;
  font-size: 12px;
  display: inline-block;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

export const CancelApprovalIcon = styled(RemoveIcon)`
  margin-right: 0.5rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 1rem;
`;

export const PhotoButtonWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 1rem;
`;

export const NewCommentButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-top: 1rem;

  &:hover {
    background-color: #ededed;
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
`;

export const TextAreaDescription = styled(TextArea)`
  border: none;
`;
