import {
  CLOSE_MODAL,
  ModalActionTypes,
  SHOW_MODAL,
  ModalName,
  ModalState,
  SHOW_MODALS,
} from './types';

export function showModal<T>(modal: ModalName, params?: T): ModalActionTypes {
  return {
    type: SHOW_MODAL,
    modal,
    params,
  };
}

export function closeModal(modal?: ModalName): ModalActionTypes {
  return {
    type: CLOSE_MODAL,
    modal,
  };
}

export function showMultipleModal(modals: ModalState[]): ModalActionTypes {
  return {
    type: SHOW_MODALS,
    modals,
  };
}
