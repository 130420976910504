import styled from 'styled-components';

interface ContainerProps {
  width?: string;
  height?: string;
}
export const Container = styled.div<ContainerProps>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
