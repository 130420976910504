import styled from 'styled-components';

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 2px;
`;

export const Row = styled.div`
  display: flex;
  gap: 1rem;

  & > div {
    flex: 1;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

export const ContainerTagButton = styled.div`
  width: 20rem;
  margin-top: 3.2rem;
`;
