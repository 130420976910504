import { DatasetParams, TableConfigFile } from '../store/types';
import api from './api';

export interface GeneratePDFProps {
  idFormulario: number;
  [key: string]: any;
}

interface GenerateTableReportPdfProps {
  config: TableConfigFile;
  params: DatasetParams;
}

export function get(familyId: number) {
  return api.get(`/relatorios/get/${familyId}.json`);
}

export function generate(params) {
  return api.post('/relatorios/generate.json', params);
}

export function makeFvsPdfReport(params) {
  return api.post('/relatorios/gerar-fvs-pdf.json', params);
}

export function gerarPdfRocontec(params) {
  return api.post('/relatorios/gerar-pdf-rocontec.json', params);
}

export function generateTableReportPdf(params: GenerateTableReportPdfProps) {
  return api.post('/relatorios/generate-table-report-pdf.json', params);
}

export function generateBoletimMedicao(params: GenerateTableReportPdfProps) {
  return api.post('/relatorios/generate-boletim-medicao.json', params);
}

// TODO: acho que esse endpoint está no lugar errado
export function generateFormPdf(params: GeneratePDFProps) {
  return api.post(`/formularios/generate-report.json`, params);
}

interface ExportDynamicTableProps {
  idDataset: number;
  nomeEmpreendimento: string;
  filtros: DatasetParams;
}

export function exportDynamicTable(
  params: ExportDynamicTableProps,
): Promise<string> {
  return api.post(`/relatorios/export-dynamic-table.json`, params);
}
