import styled, { css } from 'styled-components';

interface Props {
  readonly kind: string;
  readonly small: boolean;
  readonly tiny: boolean;
  readonly outline?: boolean;
}

const PrimaryOutline = css`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  background: transparent;

  &:hover {
    background-color: #fafafa;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

const Primary = css`
  color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: #008f88;
  }
  &:active {
    background-color: #008988;
  }
`;

const Danger = css`
  color: #fff;
  border: 2px solid ${({ theme }) => theme.colors.danger};
  background: ${({ theme }) => theme.colors.danger};

  &:hover {
    background-color: #f7395f;
  }

  &:active {
    background-color: #ed375b;
  }
`;

const DangerOutline = css`
  border: 1px solid ${({ theme }) => theme.colors.danger};
  color: ${({ theme }) => theme.colors.danger};
  background: transparent;

  &:hover {
    background-color: #fafafa;
    border: 1px solid ${({ theme }) => theme.colors.danger};
  }
`;

const Info = css`
  color: #fff;
  border: 2px solid ${({ theme }) => theme.colors.info};
  background: ${({ theme }) => theme.colors.info};

  &:hover {
    background-color: #097acf;
  }

  &:active {
    background-color: #197bdf;
  }
`;

const InfoOutline = css`
  border: 1px solid ${({ theme }) => theme.colors.info};
  color: ${({ theme }) => theme.colors.info};
  background: transparent;

  &:hover {
    background-color: #fafafa;
    border: 1px solid ${({ theme }) => theme.colors.info};
  }
`;

const Default = css`
  border: 1px solid #d3d3d3;
  background-color: #fff;

  &:hover {
    border: 1px solid #d3d3d3;
    background-color: #f8f8f8;
  }

  &:active {
    border: 1px solid #d3d3d3;
    background-color: #f0f0f0;
  }
`;

function getTheme(kind, outline) {
  if (kind === 'primary' && outline) {
    return PrimaryOutline;
  }

  if (kind === 'primary' && !outline) {
    return Primary;
  }

  if (kind === 'danger' && !outline) {
    return Danger;
  }

  if (kind === 'danger' && outline) {
    return DangerOutline;
  }

  if (kind === 'info' && !outline) {
    return Info;
  }

  if (kind === 'info' && outline) {
    return InfoOutline;
  }

  return null;
}

function getPadding(props: Props) {
  if (props.tiny) {
    return '0 .8rem';
  }

  if (props.small) {
    return '0 1rem';
  }

  return '0 1.4rem';
}

function getFontsize(props: Props) {
  if (props.tiny) {
    return '1.1rem';
  }

  if (props.small) {
    return '1.2rem';
  }

  return '1.4rem';
}

function getHeigth(props: Props) {
  if (props.tiny) {
    return '2.5rem';
  }

  if (props.small) {
    return '3rem';
  }

  return '4rem';
}

export const StyledButton = styled.button<Props>`
  font-size: ${props => getFontsize(props)};
  height: ${props => getHeigth(props)};
  padding: ${props => getPadding(props)};
  border-radius: ${({ theme }) => theme.radius.medium}px;
  color: #333;
  cursor: pointer;
  letter-spacing: 0.08rem;
  display: flex;
  align-items: center;
  justify-content: center;
  

  &:hover {
    border-color: transparent;
  }

  &:active {
    border-color: transparent;
  }

  &:focus {
    outline: 0;
  }

  ${props => getTheme(props.kind, props.outline)}

  ${props => props.kind === 'default' && Default}

  ${props => props.disabled && 'opacity: .7; cursor: not-allowed;'}

`;
