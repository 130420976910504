/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-confusing-arrow */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v1 as uuid } from 'uuid';
import { useToasts } from 'react-toast-notifications';
import { startCase } from 'lodash';
import { Text } from 'styles/text';
import Spinner from 'components/Spinner';
import {
  download,
  editFile,
  get,
  getByKeyReferencia,
  remove,
  RemoveProps,
  saveLog,
  update,
} from '../../../services/file';
import { uploadFile, UploadFileProps } from '../../../services/upload';
import { closeModal, showModal } from '../../../store/modal/actions';
import { ModalName } from '../../../store/modal/types';
import {
  DatasetParams,
  EnumActions,
  EnumProfile,
  File,
  PopoupActionsShow,
} from '../../../store/types';
import { chunkArray, isObjectEmpty } from '../../../utils/common';
import { getFileImg, getFileName, getFileType } from '../../../utils/file';
import Input from '../../Input';
import InputFile from '../../InputFile';
import { getSqlResponse } from 'services/dataset';
import {
  ContainerFiles,
  IconDownload,
  EditIcon,
  Row,
  IconOptions,
  PopupActions,
  Button,
  FileNameWrapper,
  AppendedDataWrapper,
  AppendedDataTitle,
  AppendedDataChip,
  CategoryTitle,
  CategoryWrapper,
  SpinnerWrapper,
  ViewerIcon,
} from './styles';
import { ImagePropsParams } from 'components/Modal/ImageViewer';
import { normalizeFileUrl } from 'utils/file';
import WithPermission from 'components/WithPermission';
import useUser from 'hooks/use-user';
import { UserState } from 'store/user/types';

interface Props {
  idReferencia?: number;
  idContrato?: number;
  keyReferencia?: string;
  datasetId?: number;
  historyKeyReferencia?: string | number;
  beforeUploadModal?: string;
  datasetParams?: DatasetParams;
  appendedData?: { [key: string]: any };
  type: string;
  isHideCategory?: boolean;
  title?: string;
  onFilesUpdated?: () => void;
  saveHistory?: boolean;
  inputId: string | number | null | undefined;
  afterSubmit?: () => void;
  isDisabled?: boolean;
  ammountUploadItemsAllowed?: 1 | 2 | 3;
  status?: string;
  setTotalFiles?: (value: number) => void;
  afterLoading?: (files: File[]) => void;
  isRemovePermission?: boolean;
}

interface FilesByCategory {
  [key: string]: File[];
}

export default function Attachments(props: Props) {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const user = useUser();

  const {
    idReferencia,
    keyReferencia,
    historyKeyReferencia,
    type,
    beforeUploadModal,
    isHideCategory,
    appendedData,
    title,
    onFilesUpdated,
    saveHistory,
    afterSubmit,
    inputId,
    idContrato,
    datasetParams,
    isDisabled,
    ammountUploadItemsAllowed,
    status,
    setTotalFiles,
    afterLoading,
    isRemovePermission,
  } = props;

  const [files, setFiles] = useState<File[]>([]);
  const [filesByCategory, setFilesByCategory] = useState<FilesByCategory>();
  const [fileCategories, setFileCategories] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [isInputFileDisabled, setIsInputFileDisabled] = useState(false);

  useEffect(() => {
    getFiles();
  }, [idReferencia, keyReferencia, datasetParams]);

  useEffect(() => {
    if (afterLoading) {
      afterLoading(files);
    }
  }, [files]);

  useEffect(() => {
    if (
      ammountUploadItemsAllowed &&
      files.length >= ammountUploadItemsAllowed
    ) {
      setIsInputFileDisabled(true);
    } else {
      setIsInputFileDisabled(false);
    }

    if (isHideCategory) {
      return;
    }

    if (!files.length) {
      setFileCategories([]);
      setFilesByCategory({});

      return;
    }

    const categories = files.reduce((acc: string[], file: File) => {
      const category = file.appendedData?.categoria?.label || '';

      if (!acc.includes(category)) {
        acc.push(category);
      }

      return acc;
    }, []);

    const reducedFiles = files.reduce((acc, file) => {
      const category = file.appendedData?.categoria?.label || '';

      if (!acc[category]) {
        acc[category] = [];
      }

      acc[category].push(file);

      return acc;
    }, {} as { [key: string]: File[] });

    setFileCategories(categories);
    setFilesByCategory(reducedFiles);
  }, [files]);

  async function getFiles() {
    const { datasetId } = props;

    if (!idReferencia && !keyReferencia) return;

    if (datasetId && datasetParams && isObjectEmpty(datasetParams)) {
      return;
    }

    setLoading(true);

    if (datasetId) {
      const newParams = {
        ...datasetParams,
        idReferencia,
      };

      const res = await getSqlResponse<File[] | undefined>(
        datasetId,
        newParams,
      );

      const normalizedFiles = res || [];

      if (setTotalFiles) {
        setTotalFiles(normalizedFiles.length);
      }

      setFiles(normalizedFiles);
      setLoading(false);

      return;
    }

    if (idReferencia) {
      const res = await get(idReferencia, type);
      const responseFilesFormatter = res.map(row => ({
        ...row,
        nome: row.nome.toString(),
      }));

      const normalizedFiles = responseFilesFormatter || [];

      if (setTotalFiles) {
        setTotalFiles(normalizedFiles.length);
      }

      setFiles(responseFilesFormatter);
      setLoading(false);

      return;
    }

    if (keyReferencia) {
      const res = await getByKeyReferencia(keyReferencia, type);

      const normalizedFiles = res || [];

      if (setTotalFiles) {
        setTotalFiles(normalizedFiles.length);
      }

      setFiles(res);
    }

    setLoading(false);
  }

  function handleBeforeUploadModal(file) {
    return new Promise((res, rej) => {
      dispatch(
        showModal(ModalName[beforeUploadModal!], {
          fileName: file.name,
          onCancel: () => {
            dispatch(closeModal(ModalName[beforeUploadModal!]));
            rej();
          },
          onSubmit: async data => {
            dispatch(
              showModal(ModalName.LOADING, { text: 'Salvando anexo...' }),
            );

            const fileData = data;
            const { nome } = fileData;

            delete fileData.nome;

            const upload = await uploadPayload(
              file,
              JSON.stringify(fileData),
              nome,
            );

            if (saveHistory) {
              await saveLog({
                idArquivo: upload.id,
                versao: upload.versao,
                acao: 'upload',
                descricao: `anexou o arquivo ${upload.nome}`,
                keyReferencia: historyKeyReferencia!,
              });
            }

            await afterSubmit!();

            await getFiles();

            dispatch(closeModal(ModalName.LOADING));

            if (onFilesUpdated) {
              onFilesUpdated();
            }

            res(true);
          },
        }),
      );
    });
  }

  async function handleUpload(selectedFiles: globalThis.File[]) {
    if (!beforeUploadModal) {
      dispatch(showModal(ModalName.LOADING, { text: 'Salvando anexos...' }));
    }

    try {
      if (beforeUploadModal) {
        for (const part of selectedFiles) {
          await handleBeforeUploadModal(part);
        }
      } else {
        const chunks = chunkArray<globalThis.File>(selectedFiles, 5);

        const normalizedAppendedData = appendedData
          ? JSON.stringify(appendedData)
          : '';

        const execute = (part: globalThis.File[]) => {
          return part.map(file => uploadPayload(file, normalizedAppendedData));
        };

        for (const part of chunks) {
          await Promise.all(execute(part));
        }

        if (afterSubmit) {
          afterSubmit();
        }
      }

      await getFiles();
    } catch (error) {
      addToast('Não foi possível salvar o arquivo.', {
        appearance: 'error',
      });
    }

    dispatch(closeModal(ModalName.LOADING));
  }

  function uploadPayload(file, appendedData = '', updatedFileName = '') {
    const payload: UploadFileProps = {
      file,
      data: {
        nome: getFileName(updatedFileName) || getFileName(file.name),
        tipoArquivo: getFileType(file.name),
        uuid: uuid(),
        tipo: type,
        appendedData,
        idReferencia,
        keyReferencia,
        idContrato,
      },
    };

    return uploadFile(payload);
  }

  async function handleRemove(file: File) {
    if (onFilesUpdated) {
      onFilesUpdated();
    }

    await remove({ id: file.id, versao: 1 } as RemoveProps);

    if (saveHistory) {
      await saveLog({
        idArquivo: file.id,
        versao: file.versao,
        acao: 'delete',
        descricao: `removeu o arquivo ${file.nome}`,
        keyReferencia: historyKeyReferencia!,
      });
    }

    const newFiles = files.filter(f => f.id !== file.id) || [];

    if (setTotalFiles) {
      setTotalFiles(newFiles.length);
    }

    setFiles(newFiles);
  }

  async function handleSave(file: File) {
    try {
      await update({
        id: file.id,
        nome: file.nome,
        idCategoria: 0,
        idComentario: 0,
        tipoArquivo: file.tipoArquivo,
        versao: file.versao,
      });

      const newFiles = files.map(f => {
        if (f.id === file.id) {
          return file;
        }

        return f;
      });

      if (setTotalFiles) {
        setTotalFiles(newFiles.length);
      }

      setFiles(newFiles);
    } catch (error) {
      addToast('Houve um problema ao atualizar o nome do arquivo.', {
        appearance: 'error',
      });
    }
  }

  function renderFileList() {
    if (!filesByCategory) {
      return files.map(file => (
        <Item
          isHideCategory
          key={file.id}
          file={file}
          onRemove={fileItem => handleRemove(fileItem)}
          onSave={fileItem => handleSave(fileItem)}
          editModalName={ModalName[beforeUploadModal!]}
          getFiles={getFiles}
          keyReferencia={historyKeyReferencia}
          isDisabled={isDisabled}
          status={status}
          user={user}
          isRemovePermission={isRemovePermission}
        />
      ));
    }

    return fileCategories.map(category => (
      <CategoryWrapper key={category}>
        <CategoryTitle>{category}</CategoryTitle>
        <div>
          {filesByCategory[category].map(file => (
            <Item
              key={file.id}
              file={file}
              onRemove={fileItem => handleRemove(fileItem)}
              onSave={fileItem => handleSave(fileItem)}
              editModalName={ModalName[beforeUploadModal!]}
              getFiles={getFiles}
              keyReferencia={historyKeyReferencia}
              afterSubmit={afterSubmit}
              isDisabled={isDisabled}
              status={status}
              user={user}
              isRemovePermission={isRemovePermission}
            />
          ))}
        </div>
      </CategoryWrapper>
    ));
  }

  function renderInputFile() {
    if (isDisabled || isInputFileDisabled) return null;

    if (isRemovePermission) {
      return (
        <InputFile
          id={inputId?.toString()}
          multiple
          text="Selecionar arquivo"
          onSelectFiles={handleUpload}
        />
      );
    }

    return (
      <WithPermission
        action={EnumActions.SALVAR}
        rules={[
          EnumProfile.ADMINISTRADOR,
          EnumProfile.PRODUCAO,
          EnumProfile.EXDCUTIVO,
          EnumProfile.DEMO,
          EnumProfile.OPERACIONAL,
          EnumProfile.APROVADOR,
          EnumProfile.AUDITOR,
          EnumProfile.GESTOR,
        ]}
      >
        <InputFile
          id={inputId?.toString()}
          multiple
          text="Selecionar arquivo"
          onSelectFiles={selectedFiles => handleUpload(selectedFiles)}
        />
      </WithPermission>
    );
  }

  return (
    <ContainerFiles>
      {title && <h2>{title}</h2>}

      {renderInputFile()}

      <SpinnerWrapper>
        {loading ? <Spinner size="small" /> : null}
      </SpinnerWrapper>

      {renderFileList()}
    </ContainerFiles>
  );
}

interface ItemProps {
  file: File;
  isHideCategory?: boolean;
  onRemove(file: File): void;
  onSave(file: File): void;
  editModalName?: ModalName;
  getFiles: () => void;
  keyReferencia?: string | number;
  afterSubmit?: () => void;
  isDisabled?: boolean;
  status: string;
  user: UserState;
  isRemovePermission?: boolean;
}

function Item(props: ItemProps) {
  const {
    file,
    isHideCategory,
    onRemove,
    onSave,
    editModalName,
    getFiles,
    keyReferencia,
    afterSubmit,
    isDisabled,
    status,
    user,
    isRemovePermission,
  } = props;

  const [allowEdit, setAllowEdit] = useState(false);
  const [fileName, setFileName] = useState(file.nome);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [popUpConfig, setShowActions] = useState<PopoupActionsShow>({
    isVisible: false,
    actions: [],
  });

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setFileName(e.target.value);
  }

  async function handleSave() {
    setLoading(true);

    await onSave({ ...file, nome: fileName });

    setAllowEdit(false);

    setShowActions({
      isVisible: false,
    });

    setLoading(false);
  }

  async function submit(data) {
    const parsedData = data;

    if (keyReferencia) {
      parsedData.keyReferencia = keyReferencia;
    }

    dispatch(showModal(ModalName.LOADING, { text: 'Salvando...' }));

    await editFile(data);
    await getFiles();
    afterSubmit!();

    dispatch(closeModal(ModalName.LOADING));
  }

  function checkedTypeDocumentViewer() {
    const fileType = file.tipoArquivo.toLowerCase();

    return (
      fileType === 'png' ||
      fileType === 'jpg' ||
      fileType === 'jpeg' ||
      fileType === 'pdf'
    );
  }

  function getDocumentViewer(file: File) {
    const fileType = file.tipoArquivo.toLowerCase();

    if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
      const photoPath = normalizeFileUrl(
        `/datafiles/arquivos/${file.uuid}.${file.tipoArquivo}`,
      );

      dispatch(
        showModal<ImagePropsParams>(ModalName.IMAGE_VIEWER, {
          images: [{ src: photoPath, downloadUrl: photoPath, alt: '' }],
        }),
      );

      return;
    }

    dispatch(showModal(ModalName.PDF_VIEWER, { file: file }));
  }

  function validationActionId(action: EnumActions, rules: EnumProfile[]) {
    const { idAcoes, idPerfilModulo } = user;

    if (!idAcoes) {
      if (!idPerfilModulo || !rules?.length) return false;

      return rules.includes(idPerfilModulo);
    }

    const actionsArr = idAcoes.split(',').map(Number);

    return actionsArr.includes(action);
  }

  function handleSetShowActions() {
    const validateEnumProfile = [
      EnumProfile.ADMINISTRADOR,
      EnumProfile.PRODUCAO,
      EnumProfile.EXDCUTIVO,
      EnumProfile.DEMO,
      EnumProfile.OPERACIONAL,
      EnumProfile.APROVADOR,
      EnumProfile.AUDITOR,
      EnumProfile.GESTOR,
    ];

    const actions = [
      {
        text: 'Baixar',
        iconComponent: <IconDownload />,
        onClick: () => download(file, true),
        visible: true,
      },
      {
        text: 'Visualizar ',
        iconComponent: <ViewerIcon />,
        onClick: () => {
          getDocumentViewer(file);
        },
        visible: checkedTypeDocumentViewer(),
      },
      {
        text: 'Editar',
        iconComponent: <EditIcon />,
        onClick: () => {
          if (editModalName) {
            dispatch(
              showModal(editModalName!, {
                file,
                onCancel: () => dispatch(closeModal(editModalName)),
                onSubmit: submit,
              }),
            );

            return;
          }

          setAllowEdit(true);
        },
        visible: isRemovePermission
          ? true
          : !(status === 'APROVADO' || status === 'APROVADO PÓS CORREÇÃO') &&
            validationActionId(EnumActions.EDITAR, validateEnumProfile),
      },
      {
        text: 'Excluir',
        icon: 'IconTrash',
        onClick: () => onRemove(file),
        visible: isRemovePermission
          ? true
          : !(status === 'APROVADO' || status === 'APROVADO PÓS CORREÇÃO') &&
            validationActionId(EnumActions.EXCLUIR, validateEnumProfile),
      },
    ];

    if (isDisabled) {
      delete actions[2];
      delete actions[3];
    }

    setShowActions({
      isVisible: true,
      actions,
    });
  }

  if (allowEdit) {
    return (
      <Row style={{ marginBottom: '.5rem' }}>
        <Input value={fileName} onChange={handleChange} />
        <Button
          primary
          tiny
          isLoading={loading}
          text="Salvar"
          onClick={handleSave}
          type="button"
        />
        <Button
          tiny
          text="Cancelar"
          type="button"
          onClick={() => setAllowEdit(false)}
        />
      </Row>
    );
  }

  function renderFileAppendedData() {
    if (!file.appendedData || isHideCategory) return null;

    const chips = Object.keys(file.appendedData).map(item => {
      if (item === 'categoria') return null;
      if (!file.appendedData[item]) return null;

      const value =
        typeof file.appendedData[item] === 'object'
          ? file.appendedData[item].label
          : file.appendedData[item];

      return (
        <AppendedDataChip key={value}>
          <AppendedDataTitle>{startCase(item)}: </AppendedDataTitle>
          <span>{value}</span>
        </AppendedDataChip>
      );
    });

    return <AppendedDataWrapper>{chips}</AppendedDataWrapper>;
  }

  function renderFileName(fileRender: File) {
    const verifyExtension = fileRender.nome
      .toString()
      .includes(`.${fileRender.tipoArquivo}`);

    return verifyExtension
      ? fileRender.nome.toString()
      : `${fileRender.nome}.${fileRender.tipoArquivo}`;
  }

  const fileType = String(file.tipoArquivo).toLowerCase();

  if (isObjectEmpty(file)) return null;

  return (
    <div key={file.id}>
      <Row onMouseLeave={() => setShowActions({ isVisible: false })}>
        <FileNameWrapper>
          <div>
            <img alt="" src={getFileImg(fileType)} />
          </div>
          <Text onClick={() => download(file, true)}>
            {renderFileName(file)}
          </Text>
        </FileNameWrapper>

        <IconOptions onMouseEnter={handleSetShowActions} />
        <PopupActions popUpConfig={popUpConfig} />
      </Row>

      {renderFileAppendedData()}
    </div>
  );
}
