import { UserRelationship } from 'store/types';
import apiAdonis from './apiAdonis';

interface PayloadStore {
  id?: number;
  idEmpresa: number;
  idUsuario: number;
  idCadastroFornecedo: number;
  flagStatus?: number;
}

export function store(data: PayloadStore[]): Promise<UserRelationship[]> {
  return apiAdonis.post(`/usuario-fornecedor/store`, data);
}
