import React, { useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {
  Button,
  ButtonsWrapperMobile,
  ButtonsWrapperWeb,
  ContainerMobile,
  ContainerWeb,
  SignatureCanvasWrapperMobile,
  SignatureCanvasWrapperWeb,
  SignatureName,
} from './styles';

import useDeviceDetect from 'hooks/use-device-detect';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  currentStepAtom,
  formDataAtom,
  urlParamsAtom,
} from 'pages/DocSign/atoms';
import {
  CancelIcon,
  GoBackIcon,
  ReloadIcon,
  TrashIcon,
} from 'pages/DocSign/styles';
import { EnumStep } from 'pages/DocSign/types';
import { handleConfirmSignCancel, isSourceApp } from 'pages/DocSign/utils';
import { FaCheck } from 'react-icons/fa';
import { LuRefreshCw } from 'react-icons/lu';
import { useToasts } from 'react-toast-notifications';
import { getCanvasSize, getCanvasSizeWeb } from './utils';

interface Props {
  onSave(isSignByName: boolean);
  signCanvasRef: React.MutableRefObject<SignatureCanvas>;
}

export default function Signature(props: Props) {
  const urlParams = useAtomValue(urlParamsAtom);
  const [isSignByName, setIsSignByName] = useState(false);
  const formData = useAtomValue(formDataAtom);
  const setCurrentStep = useSetAtom(currentStepAtom);
  const { addToast } = useToasts();
  const deviceSize = useDeviceDetect();

  const { onSave, signCanvasRef } = props;

  function clearSignature() {
    signCanvasRef.current.on();
    signCanvasRef.current.clear();
  }

  function handleAutomaticallySign() {
    setIsSignByName(!isSignByName);
  }

  function handleRedirectCancelPage() {
    if (isSourceApp(urlParams)) {
      setCurrentStep(EnumStep.SUBSCRIBER_LIST);

      addToast('Assinatura cancelada com sucesso.', {
        appearance: 'success',
      });

      return;
    }

    setCurrentStep(EnumStep.REGISTER_SUBSCRIBERS_CANCEL);
  }

  function renderButtons() {
    return (
      <>
        <Button
          onClick={() => setCurrentStep(EnumStep.PDF_VIEWER)}
          text="Voltar"
          icon={<GoBackIcon />}
          small
        />
        <Button
          onClick={() => handleConfirmSignCancel(handleRedirectCancelPage)}
          text="Cancelar"
          icon={<CancelIcon />}
          small
        />
        <Button
          onClick={clearSignature}
          text="Limpar"
          danger
          outline
          icon={<TrashIcon style={{ fontSize: '1.3rem' }} />}
          small
        />

        <Button
          onClick={handleAutomaticallySign}
          text={isSignByName ? 'Assinar com Desenho' : 'Assinar com Nome'}
          icon={<ReloadIcon />}
          small
        />

        <Button
          onClick={() => onSave(isSignByName)}
          text="Finalizar"
          primary
          icon={<FaCheck style={{ fontSize: '1.3rem' }} />}
          small
        />
      </>
    );
  }

  function renderLayout() {
    if (!isSignByName) {
      return (
        <SignatureCanvas
          ref={signCanvasRef}
          canvasProps={
            deviceSize.isMobile ? getCanvasSize(deviceSize) : getCanvasSizeWeb()
          }
        />
      );
    }

    return (
      <SignatureName>{formData.nome || 'Assinatura de Teste'}</SignatureName>
    );
  }

  if (deviceSize.isMobile) {
    return (
      <ContainerMobile>
        <ButtonsWrapperMobile>{renderButtons()}</ButtonsWrapperMobile>

        <SignatureCanvasWrapperMobile {...getCanvasSize(deviceSize)}>
          {renderLayout()}
        </SignatureCanvasWrapperMobile>
      </ContainerMobile>
    );
  }

  return (
    <ContainerWeb>
      <SignatureCanvasWrapperWeb {...getCanvasSizeWeb()}>
        {renderLayout()}
      </SignatureCanvasWrapperWeb>

      <ButtonsWrapperWeb {...getCanvasSizeWeb()}>
        {renderButtons()}
      </ButtonsWrapperWeb>
    </ContainerWeb>
  );
}
