import { IoIosClose } from 'react-icons/io';
import styled from 'styled-components';
import { Card } from 'styles/card';

export const Container = styled(Card)`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  height: auto;
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
  box-shadow: 0 3px 5px 2px rgb(0 0 0 / 20%);
  width: 40rem;
  z-index: 10000;
  flex-direction: column;

  h3 {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  h4 {
    font-size: 1.3rem;
    font-weight: normal;
  }

  span {
    display: block;
    margin-top: 0.5rem;
    font-size: 1.2rem;
    color: #686c73;
  }
`;

export const ListContainer = styled.div`
  max-height: 30vh;
  overflow-y: scroll;
`;

export const Header = styled.div`
  position: relative;
`;

export const ButtonCloseWrapper = styled.div`
  cursor: pointer;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;

  &:hover {
    background-color: #ccc;
  }
`;

export const ButtonClose = styled(IoIosClose)`
  font-size: 3rem;
  color: #333;
`;

export const ReportItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  h4 {
    flex: 1;
  }
`;
