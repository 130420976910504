import styled from 'styled-components';
import { MdFileDownload } from 'react-icons/md';
import { Card } from '../../styles/card';
import { ButtonWithNoStyle } from '../../styles/common';

export const Container = styled(Card)`
  overflow: scroll;
  width: 55%;
  height: 90%;
  margin: 3rem auto 0 auto;
`;

export const CategoryName = styled.h2`
  color: #333;
  margin-bottom: 0.5rem;
`;

export const FileWrapper = styled.button.attrs({ type: 'button' })`
  ${ButtonWithNoStyle}
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.6rem;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #f5f5f5;
  }
`;

export const LinkFile = styled.span`
  color: #333;
  font-size: 1.4rem;
  margin-left: 1rem;
`;

export const CategoryWrapper = styled.div`
  margin-bottom: 0.8rem;
`;

export const LeftIconWrapper = styled.div`
  flex: 0 0 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightIconWrapper = styled.div`
  margin-left: auto;
  padding: 0 0.6rem;
  display: flex;
  align-items: center;

  div {
    margin: 0 0.5rem;
  }
`;

export const IconDownload = styled(MdFileDownload)`
  color: #333;
  font-size: 2.2rem;
  cursor: pointer;
`;
