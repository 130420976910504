import showAlert from 'components/Alert';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import useUser from 'hooks/use-user';
import { Params } from 'pages/TableReport/components/Modals/FormOccurrence';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { getSqlResponse } from 'services/dataset';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { useDynamicForm } from '../../context';
import { RowTitle, TitleWrapper } from '../../styles';
import { Field, State } from '../../types';
import { checkUserEditPermission } from '../../utils';
import {
  AddIcon,
  CommentWrapper,
  EditIcon,
  OccurrenceTR,
  TableStyled,
  TableWrapper,
  Th,
} from './styles';
import { Params as ListOccurrenceDynamicFormParams } from 'components/Widgets/DynamicForm/components/TableOccurrence2/components/ListOccurrenceDynamicForm';
import { RemoveIcon } from 'styles/icons';
import { update } from 'services/occurrence';
import camelcaseKeys from 'camelcase-keys';

interface FieldProps extends Field {
  templateType: string;
}

interface Props {
  field: FieldProps;
  onLoadDynamicFormPage(idForumario?: number): Promise<void>;
  isDisabled?: boolean;
}

export default function TableOccurrence2(props: Props) {
  const [data, setData] = useState<State[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    globalState,
    contractId: idContrato,
    approvalData,
    onSaveForm,
    setGlobalState,
    fieldsAllowProps,
  } = useDynamicForm();

  const dispatch = useDispatch();

  const user = useUser();

  const { field, onLoadDynamicFormPage, isDisabled } = props;

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    getData();
  }, [globalState]);

  async function getData() {
    const { datasetParams, defaultFormData } = globalState!;

    setIsLoading(true);

    const params = {
      ...datasetParams,
      idContrato,
      idFormulario: defaultFormData?.idFormulario || null,
    };

    const res = await getSqlResponse<State[]>(field.datasetId!, params, true);
    setData(res);

    setIsLoading(false);
  }

  async function openFormModal(rowData: State | null) {
    if (isDisableChecked) {
      return;
    }

    const { defaultFormData } = globalState!;

    /* The form need to be saved before add images */
    if (!defaultFormData?.idFormulario) {
      showAlert({
        title: 'Atenção!',
        subtitle:
          'Para adicionar uma ocorrência é necessário salvar antes. Deseja salvar os dados editados?',
        actions: [
          {
            text: 'Não',
          },
          {
            text: 'Sim',
            onClick: onSaveForm,
          },
        ],
      });

      return;
    }

    await onSaveForm();

    dispatch(
      showModal<Params>(ModalName.FORM_OCCURRENCE, {
        idContrato: rowData?.id_contrato || idContrato,
        itemNome: '',
        idOcorrencia: rowData?.id_ocorrencia || 0,
        referencia: defaultFormData!.idFormulario.toString(),
        isNewSupplierTable: true,
        onClose: () => {
          onLoadDynamicFormPage(defaultFormData!.idFormulario);
        },
        shouldUpdateOccurrenceAfterSaveComment: true,
        templateType: field?.templateType,
        idTemplateFormulario:
          field?.idTemplateFormulario || rowData?.id_template_formulario,
      }),
    );
  }

  function handleDelete(rowData: State) {
    if (isDisableChecked) {
      return;
    }

    const handleConfirm = () => {
      const { defaultFormData } = globalState!;

      const normalizedCamelcaseProps = camelcaseKeys(rowData);

      let referencia = normalizedCamelcaseProps.referencia
        .toString()
        .replaceAll(defaultFormData?.idFormulario, '')
        .replaceAll(',,', ',');

      referencia = referencia === ',' ? null : referencia;

      update({
        idContrato: normalizedCamelcaseProps?.idContrato || idContrato,
        referencia,
        idEmpresa: user.idEmpresa,
        idOcorrencia: normalizedCamelcaseProps.idOcorrencia,
        origem: normalizedCamelcaseProps.origem,
      });

      setData(data.filter(occurrences => occurrences !== rowData));
    };

    showAlert({
      title: 'Atenção!',
      subtitle: `Confirma a exclusão da ocorrência desta ata?`,
      actions: [
        {
          text: 'Não',
        },
        {
          text: 'Sim',
          onClick: handleConfirm,
        },
      ],
    });
  }

  function renderColumns(rowData: State) {
    const normalizeText = (tableField: Field) => {
      return rowData[tableField.name];
    };

    return field.fields.map(tableField => {
      return (
        <td key={`${rowData.uuid}-${tableField.name}`}>
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {normalizeText(tableField)}
          </pre>
        </td>
      );
    });
  }

  function renderRows() {
    const occurrences = data.filter(occurrence => !occurrence.isComment);

    return occurrences.map(rowData => (
      <>
        <OccurrenceTR key={rowData.uuid}>
          {renderColumns(rowData)}

          {!isDisableChecked ? (
            <>
              <td>
                <RemoveIcon onClick={() => handleDelete(rowData)} />
              </td>
              <td>
                <EditIcon onClick={() => openFormModal(rowData)} />
              </td>
            </>
          ) : null}
        </OccurrenceTR>

        {data
          .filter(
            occurrence =>
              occurrence.isComment &&
              occurrence.id_ocorrencia === rowData.id_ocorrencia,
          )
          .map(comment => (
            <tr key={comment.uuid}>
              <td colSpan={8}>
                <CommentWrapper>
                  <pre style={{ whiteSpace: 'pre-wrap' }}>
                    {comment.ocorrencia}
                  </pre>
                </CommentWrapper>
              </td>
            </tr>
          ))}
      </>
    ));
  }

  function handleOpenOccurrenceListModal() {
    const { defaultFormData } = globalState!;

    /* The form need to be saved before add images */
    if (!defaultFormData?.idFormulario) {
      showAlert({
        title: 'Atenção!',
        subtitle:
          'Para adicionar uma ocorrência é necessário salvar antes. Deseja salvar os dados editados?',
        actions: [
          {
            text: 'Não',
          },
          {
            text: 'Sim',
            onClick: onSaveForm,
          },
        ],
      });

      return;
    }

    dispatch(
      showModal<ListOccurrenceDynamicFormParams>(
        ModalName.LIST_OCCURRENCE_DYNAMIC_FORM,
        {
          idContrato,
          idFormulario: defaultFormData!.idFormulario!,
          idEmpresa: user.idEmpresa!,
          onAfterSave: () => setGlobalState({ ...globalState }),
        },
      ),
    );
  }

  function renderButtons() {
    if (isDisableChecked) {
      return <></>;
    }

    return (
      <>
        {!field.disableAdd && (
          <>
            <Button
              tiny
              primary
              outline
              text="Pendências"
              onClick={handleOpenOccurrenceListModal}
            />
            <AddIcon data-tip="Adicionar" onClick={() => openFormModal(null)} />
          </>
        )}
      </>
    );
  }

  return (
    <TableWrapper>
      <TitleWrapper>
        {field.label ? (
          <RowTitle dangerouslySetInnerHTML={{ __html: field.label }} />
        ) : (
          <div />
        )}

        {isLoading && <Spinner />}
        {renderButtons()}
      </TitleWrapper>

      <TableStyled>
        <thead>
          <tr>
            {field.fields.map(configField => (
              <Th key={`h-${configField.name}`} size={configField.size}>
                {configField.label}
              </Th>
            ))}

            {!isDisableChecked ? (
              <>
                <Th> </Th> <Th> </Th>
              </>
            ) : null}
          </tr>
        </thead>

        <tbody>{renderRows()}</tbody>
      </TableStyled>
      <ReactTooltip />
    </TableWrapper>
  );
}
