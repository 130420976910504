import { AiOutlineFilePdf } from 'react-icons/ai';
import { MdCheck, MdClose } from 'react-icons/md';
import styled, { css } from 'styled-components';

export const ContainerHeader = css`
  background-color: #e0f2f1;
  padding: 0 2rem;
  height: 4rem;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  position: fixed;
  top: 5.3rem;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerFooter = css`
  padding: 0;
  height: 4rem;
  top: 5.3rem;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const Container = styled.div`
  ${({ widgetStyle }) => (!widgetStyle ? ContainerHeader : ContainerFooter)}
`;

export const Title = styled.h1`
  flex: 1;
  margin-left: 2rem;
`;

export const IconGoBack = styled(MdClose)`
  font-size: 2.5rem;
  cursor: pointer;
`;

export const IconPdf = styled(AiOutlineFilePdf)`
  font-size: 2.4rem;
  cursor: pointer;
  margin-right: 3rem;
`;

export const IconSave = styled(MdCheck)<{ disabled: boolean }>`
  font-size: 2.5rem;
  cursor: pointer;
  ${({ disabled }) => disabled && 'color: #a8a8a8'};
`;
