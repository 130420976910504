import React from 'react';
import { useDynamicForm } from 'components/Widgets/DynamicForm/context';

import { Button } from '../../../styles';
import WithPermission from 'components/WithPermission';
import { EnumActions, EnumProfile } from 'store/types';
import { ContentVisible } from './styles';

interface Props {
  isDisabled?: boolean;
  isHiddenButtonSave?: boolean;
}

export default function SaveButton(props: Props) {
  const {
    approvalData,

    onSaveForm,
  } = useDynamicForm();

  const { isDisabled, isHiddenButtonSave } = props;

  function renderSaveButton() {
    return (
      <WithPermission
        action={EnumActions.SALVAR}
        rules={[
          EnumProfile.ADMINISTRADOR,
          EnumProfile.PRODUCAO,
          EnumProfile.EXDCUTIVO,
          EnumProfile.DEMO,
          EnumProfile.OPERACIONAL,
          EnumProfile.APROVADOR,
          EnumProfile.AUDITOR,
          EnumProfile.GESTOR,
        ]}
      >
        <Button
          small
          primary
          text="Salvar"
          onClick={onSaveForm}
          disabled={isDisabled || !!approvalData?.usuarioAprovacao}
        />
      </WithPermission>
    );
  }

  return (
    <ContentVisible isHiddenButtonSave={isHiddenButtonSave}>
      {renderSaveButton()}
    </ContentVisible>
  );
}
