/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { v1 as uuid } from 'uuid';
import { CreatableSelect } from 'components/ReactSelect';
import Span from 'components/Span';
import { getSqlResponse } from 'services/dataset';
import { create as createRole } from 'services/role';
import { create as createSupplier } from 'services/supplier';
import { create as createCatalogList } from 'services/catalog_list';
import { create as createCatalogListContract } from 'services/catalog_list_contract';
import {
  CatalogList,
  CatalogListContract,
  OptionsType,
  Role,
  Supplier,
} from 'store/types';
import { Label } from '../../styles';
import { DynamicFormsInputProps } from '../../types';
import { useDynamicForm } from '../../context';
import useUser from 'hooks/use-user';
import { checkUserEditPermission } from '../../utils';
import { generateUUID } from 'utils/common';

async function createNewRole(role: Role) {
  const newRole = await createRole(role);

  const newOption: OptionsType = {
    label: role.cargo,
    value: newRole.idCargo!,
  };

  return newOption;
}

async function createNewSupplier(supplier: Supplier) {
  const newSupplier = await createSupplier(supplier);

  const newOption: OptionsType = {
    label: supplier.razaoSocial,
    value: newSupplier.idFornecedor!,
  };

  return newOption;
}

async function createNewCatalogList(catalogList: CatalogList) {
  const newCatalog = await createCatalogList(catalogList);

  const newOption: OptionsType = {
    label: catalogList.descricao,
    value: newCatalog.id,
  };

  return newOption;
}

async function createNewCatalogListContract(catalogList: CatalogListContract) {
  await createCatalogListContract(catalogList);

  const newOption: OptionsType = {
    label: catalogList.descricao,
    value: catalogList.uuid,
  };

  return newOption;
}

export default function InputCreatableSelect(props: DynamicFormsInputProps) {
  const { field, defaultValue, isRequired, onChange, isDisabled } = props;
  const {
    contractId: idContrato,
    approvalData,
    fieldsAllowProps,
  } = useDynamicForm();

  const [selectedOptions, setSelectedOptions] = useState<
    OptionsType | OptionsType[]
  >(defaultValue);

  const [options, setOptions] = useState<OptionsType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const user = useUser();

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    setSelectedOptions(defaultValue || null);
  }, [defaultValue]);

  useEffect(() => {
    loadOptions();
  }, [user]);

  async function loadOptions() {
    if (!user) {
      return;
    }

    if (field.datasetId) {
      const { idEmpresa, idUsuario } = user;

      const params = {
        idUsuario,
        idEmpresa,
        idContrato,
        idModulo: 10,
        ...field.datasetParam,
      };

      for (const iterator of [1, 2, 3, 4, 5]) {
        try {
          const res = await getSqlResponse<OptionsType[]>(
            field.datasetId,
            params,
          );
          setOptions(res);
          setIsLoading(false);
          break;
        } catch (error) {
          console.log('retentaviva', iterator);
        }
      }

      return;
    }

    setOptions([
      { label: 'Sim', value: 'Sim' },
      { label: 'Não', value: 'Não' },
    ]);
  }

  function handleSelect(option: OptionsType | OptionsType[]) {
    onChange(field.name, option);
    setSelectedOptions(option);
  }

  async function handleCreate(option: string) {
    setIsLoading(true);

    let newOption;

    if (field.table === 'tbl_cargo') {
      newOption = await createNewRole({
        idEmpresa: user.idEmpresa!,
        cargo: option,
      });
    } else if (field.table === 'tbl_fornecedor') {
      newOption = await createNewSupplier({
        idEmpresa: user.idEmpresa!,
        razaoSocial: option,
        fornecedor: option,
        uuid: uuid(),
      });
    } else if (field.table === 'tbl_catalogo_lista') {
      newOption = await createNewCatalogList({
        id: 0,
        idEmpresa: user.idEmpresa!,
        descricao: option,
        idTipo: Number(field?.datasetParam?.idTipo),
        idModulo: Number(field?.datasetParam?.idModulo),
      });
    } else if (field.table === 'tbl_catalogo_listas_contrato') {
      newOption = await createNewCatalogListContract({
        id: 0,
        seq: 0,
        flagStatus: 1,
        idContrato,
        uuid: generateUUID(),
        idEmpresa: user.idEmpresa!,
        descricao: option,
        idTipo: Number(field?.datasetParam?.idTipo),
      });
    }

    setOptions([...options, newOption]);

    if (field.isMulti) {
      onChange(field.name, [...(selectedOptions as OptionsType[]), newOption]);
      setSelectedOptions([...(selectedOptions as OptionsType[]), newOption]);
    } else {
      onChange(field.name, newOption);
      setSelectedOptions(newOption);
    }

    setIsLoading(false);
  }

  return (
    <>
      <Label
        htmlFor={field.name}
        dangerouslySetInnerHTML={{ __html: field.label }}
        isLabel={field.label}
      />
      <div style={{ textAlign: 'left', height: 'auto' }}>
        <CreatableSelect
          isDisabled={isDisableChecked}
          isLoading={isLoading}
          options={options}
          value={selectedOptions}
          onChange={handleSelect}
          onCreateOption={handleCreate}
          error={isRequired}
          isMulti={field.isMulti}
          formatCreateLabel={() => 'Criar novo'}
        />
        {isRequired && <Span text="Campo obrigatório" />}
      </div>
    </>
  );
}
