import showAlert from 'components/Alert';
import Button from 'components/Button';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  currentStepAtom,
  fetchSubscribersAtom,
  formDataAtom,
  isMainDocGeneratedAtom,
  subscriberListAtom,
  urlParamsAtom,
} from 'pages/DocSign/atoms';
import { IconCheck } from 'pages/DocSign/styles';
import { generateSignPDF } from 'pages/DocSign/utils';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { sendEmail, updateOrCreate } from 'services/signature';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { EnumStatus, EnumStep, Subscriber } from '../../types';
import CreateSubscriberRegistration from './components/Modal/CreateSubscriberRegistration';
import DocViewerModal from './components/Modal/DocViewerModal';
import SubscriberRegistration, {
  SubscriberRegistrationParams,
} from './components/Modal/SubscriberRegistration';
import ReportStatus from './components/ReportStatus';
import ShowDocButton from './components/ShowDocButton';
import SubscriberCard from './components/SubscriberCard';
import {
  Container,
  Footer,
  IconMore,
  ListContainer,
  TotalContainer,
  WrapperButton,
} from './styles';
import { normalizeSubscribersRegistrationCompleted } from './utils';

export default function SubscriberList() {
  const [subscriberList, setSubscriberList] = useAtom(subscriberListAtom);
  const urlParams = useAtomValue(urlParamsAtom);
  const isMainDocGenerated = useAtomValue(isMainDocGeneratedAtom);
  const setFormData = useSetAtom(formDataAtom);
  const setCurrentStep = useSetAtom(currentStepAtom);
  const fetchSubscribers = useSetAtom(fetchSubscribersAtom);

  const dispatch = useDispatch();

  const { addToast } = useToasts();

  useEffect(() => {
    loadData();
  }, [urlParams?.uuidArquivo]);

  async function loadData() {
    if (!urlParams?.uuidArquivo) return;

    const users = await fetchSubscribers();

    if (users.length) return;

    dispatch(
      showModal<SubscriberRegistrationParams>(
        ModalName.SUBSCRIBER_REGISTRATION,
        {
          onSave: fetchSubscribers,
        },
      ),
    );
  }

  function handleShowSubscriberRegistrationModal() {
    dispatch(
      showModal<SubscriberRegistrationParams>(
        ModalName.SUBSCRIBER_REGISTRATION,
        {
          onSave: fetchSubscribers,
        },
      ),
    );
  }

  function handletoSign(data: Subscriber) {
    setFormData(data);

    setCurrentStep(EnumStep.SUBSCRIBER_DOC);
  }

  function handleEdit(data: Subscriber) {
    dispatch(
      showModal<SubscriberRegistrationParams>(
        ModalName.SUBSCRIBER_REGISTRATION,
        {
          data: data,
          onSave: fetchSubscribers,
        },
      ),
    );
  }

  function handleDelete(data: Subscriber) {
    showAlert({
      title: 'Atenção!',
      subtitle: `Realmente deseja excluir o assinante?`,
      actions: [
        {
          text: 'Não',
        },
        {
          text: 'Sim',
          onClick: () => remove(data),
        },
      ],
    });

    async function remove(data) {
      dispatch(showModal(ModalName.LOADING, { text: 'Excluindo...' }));

      const newAsign = {
        id: data.id as number,
        uuid: data.uuid as string,
        flagStatus: 0,
      };

      await updateOrCreate(newAsign);

      await fetchSubscribers();

      dispatch(closeModal(ModalName.LOADING));
    }
  }

  async function onConfirm() {
    dispatch(showModal(ModalName.LOADING, { text: 'Salvando...' }));

    setSubscriberList(
      normalizeSubscribersRegistrationCompleted(subscriberList),
    );

    try {
      const updateData = subscriberList.map(subscriber => ({
        uuid: subscriber.uuid,
        status: EnumStatus.REGISTER_SUBSCRIBERS_DONE,
      }));

      await updateOrCreate(updateData);

      await sendEmail(subscriberList.map(subscriber => subscriber.uuid));

      generateSignPDF(urlParams!);

      addToast('Liberado para assinatura e emails enviados', {
        appearance: 'success',
      });
    } catch (error) {
      addToast('Erro ao liberar assinaturas', {
        appearance: 'error',
      });
    }

    dispatch(closeModal(ModalName.LOADING));
  }

  const isRegistrationComplete = subscriberList.some(item => item.status);

  return (
    <>
      <Container>
        <TotalContainer>
          <ReportStatus />
          <span>Total de assinantes: {subscriberList?.length || 0}</span>
        </TotalContainer>

        <ListContainer>
          {subscriberList.map(item => (
            <SubscriberCard
              key={item.id}
              data={item}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handletoSign={handletoSign}
            />
          ))}
        </ListContainer>

        <WrapperButton>
          {isMainDocGenerated ? <ShowDocButton /> : <div />}

          {!isRegistrationComplete && (
            <Button
              small
              outline
              primary
              onClick={handleShowSubscriberRegistrationModal}
              text="Adicionar"
              icon={<IconMore />}
            />
          )}
        </WrapperButton>

        <Footer>
          <div />
          {!isRegistrationComplete && (
            <Button
              small
              primary
              onClick={onConfirm}
              text="Concluir"
              disabled={!isMainDocGenerated}
              icon={<IconCheck />}
            />
          )}
        </Footer>
      </Container>
      <SubscriberRegistration />
      <CreateSubscriberRegistration />
      <DocViewerModal />
    </>
  );
}
