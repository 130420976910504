import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'components/Modal';
import useModal from 'hooks/use-modal';
import { ModalName } from 'store/modal/types';
import { closeModal } from 'store/modal/actions';
import { ButtonClose, ModalTitle } from 'styles/modal';
import { Field, FormNewItemModalParams, State } from '../../types';
import InputCreatableSelect from '../InputCreatableSelect';
import InputDate from '../InputDate';
import InputMask from '../InputMask';
import InputSelect from '../InputSelect';
import InputTextArea from '../InputTextArea';
import InputUpload from '../InputUpload';
import { v1 as uuid } from 'uuid';
import {
  Button,
  ButtonsWrapper,
  Container,
  InputWrapper,
  ModalContainer,
  ModalHeader,
} from './styles';
import WithPermission from 'components/WithPermission';
import { EnumActions, EnumProfile } from 'store/types';

export default function FormNewItemModal() {
  const [formData, setFormData] = useState<State>({});
  const [requiredFields, setRequiredFields] = useState<State>({});

  const { isOpen, params, windowSec } = useModal<FormNewItemModalParams>(
    ModalName.DYNAMIC_FORM_NEW_ITEM,
  );

  const { onSave } = params;

  const dispatch = useDispatch();

  useEffect(() => {
    setFormData(params.previousData || { uuid: uuid() });
  }, [params.previousData]);

  function getTitle() {
    const { title } = params;

    if (title) {
      return title.replace('<b>', '').replace('</b>', '');
    }

    return 'Formulário';
  }

  function handleChange(name: string, value: any) {
    setFormData({ ...formData, [name]: value });
  }

  function handleClose() {
    dispatch(closeModal(ModalName.DYNAMIC_FORM_NEW_ITEM));
  }

  function handleSave() {
    const required = {};

    params.fields.forEach(field => {
      if (
        ['date', 'time', 'datetime'].includes(field.type) &&
        (formData[field.name] === 'Invalid date' ||
          formData[field.name] === 'Data inválida')
      ) {
        required[field.name] = true;

        return;
      }

      if (field.required && !formData[field.name]) {
        required[field.name] = true;
      }
    });

    if (Object.keys(required).length > 0) {
      setRequiredFields(required);

      return;
    }

    onSave(formData);

    handleClose();
  }

  function handleRemove() {
    const { previousData, onRemove } = params;

    if (onRemove) {
      onRemove(previousData!.uuid!);
    }

    handleClose();
  }

  function renderFields() {
    if (!params || !params.fields) {
      return null;
    }

    const { fields } = params;

    const renderInput = (field: Field) => {
      const inputProps = {
        field,
        isRequired: requiredFields[field.name],
        defaultValue: formData[field.name],
        formData,
        onSave: onSave,
        onChange: handleChange,
      };

      if (!field.type || field.type === 'number') {
        return <InputMask {...inputProps} />;
      }

      if (field.type === 'select') {
        return <InputSelect {...inputProps} />;
      }

      if (field.type === 'creatable-select') {
        return <InputCreatableSelect {...inputProps} />;
      }

      if (field.type === 'input-upload') {
        return <InputUpload {...inputProps} uuid={formData.uuid!} />;
      }

      if (
        field.type === 'date' ||
        field.type === 'time' ||
        field.type === 'datetime'
      ) {
        return <InputDate {...inputProps} />;
      }

      return <InputTextArea {...inputProps} />;
    };

    return fields.map(field => (
      <InputWrapper key={`${params.tableFieldName}-${field.name}`}>
        {renderInput(field)}
      </InputWrapper>
    ));
  }

  function renderButtons() {
    const { config, previousData } = params;

    return (
      <ButtonsWrapper>
        {previousData && !config?.disableRemove ? (
          <WithPermission
            action={EnumActions.EXCLUIR}
            rules={[
              EnumProfile.ADMINISTRADOR,
              EnumProfile.PRODUCAO,
              EnumProfile.EXDCUTIVO,
              EnumProfile.DEMO,
              EnumProfile.OPERACIONAL,
              EnumProfile.APROVADOR,
              EnumProfile.AUDITOR,
              EnumProfile.GESTOR,
            ]}
          >
            <Button danger outline text="Excluir" onClick={handleRemove} />
          </WithPermission>
        ) : (
          <div />
        )}

        {!config?.disableEdit && (
          <WithPermission
            action={EnumActions.SALVAR}
            rules={[
              EnumProfile.ADMINISTRADOR,
              EnumProfile.PRODUCAO,
              EnumProfile.EXDCUTIVO,
              EnumProfile.DEMO,
              EnumProfile.OPERACIONAL,
              EnumProfile.APROVADOR,
              EnumProfile.AUDITOR,
              EnumProfile.GESTOR,
            ]}
          >
            <Button primary text="Salvar" onClick={handleSave} />
          </WithPermission>
        )}
      </ButtonsWrapper>
    );
  }

  return (
    <Modal
      onModalOpen={() => setFormData({})}
      onRequestClose={handleClose}
      isOpen={isOpen}
      style={params?.customStyle}
      windowSec={windowSec}
    >
      <ModalHeader>
        <ButtonClose onClick={handleClose} />
        <ModalTitle> {getTitle()} </ModalTitle>
      </ModalHeader>

      <ModalContainer>
        <Container>{renderFields()}</Container>
        {renderButtons()}
      </ModalContainer>
    </Modal>
  );
}
