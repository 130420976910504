import {
  SET,
  CLEAR,
  SupplierMeasureState,
  SupplierMeasureTypes,
} from './types';

export function setSupplierMeasure(
  data: SupplierMeasureState,
): SupplierMeasureTypes {
  return {
    type: SET,
    payload: data,
  };
}

export function clearSupplierMeasure(): SupplierMeasureTypes {
  return {
    type: CLEAR,
  };
}
