import styled from 'styled-components';

export const P = styled.p`
  font-size: 1.4rem;
`;

export const LabelInfo = styled.span`
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
`;

export const Text = styled.span<{
  capitalize?: boolean;
  truncate?: boolean;
  width?: string;
}>`
  text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : 'none')};
  width: ${({ width }) => width || 'auto'};
  font-size: 1.4rem;
  flex: 1;

  ${({ truncate }) =>
    truncate &&
    `
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    overflow: hidden;
  `}
`;

export const Danger = styled.p`
  color: ${({ theme }) => theme.colors.danger};
`;

export const TextEllipsis = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
