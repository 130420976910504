import React, { ChangeEvent, useEffect, useState } from 'react';
import Modal from 'components/Modal';
import useModal from 'hooks/use-modal';
import { ModalName } from 'store/modal/types';
import { useDispatch } from 'react-redux';
import { closeModal, showModal } from 'store/modal/actions';
import { ButtonClose, ModalContainer, ModalHeader } from 'styles/modal';
import { Content, Footer, Row, ScrollArea, Title } from './styles';
import { OccurrenceUpdates } from '../FormOccurrence/components/UpdatesOccurrence';
import TextArea from 'components/TextArea';
import {
  EnumActions,
  EnumProfile,
  EnumRoutes,
  OccurrenceStatus,
} from 'store/types';
import { Label } from 'styles/form';
import { UploadIcon } from '../FormOccurrence/styles';
import Button from 'components/Button';
import { UploadModalParams } from 'components/Widgets/DynamicForm/types';
import { getSingleResponse } from 'services/dataset';
import useUser from 'hooks/use-user';
import { add, update as updateComment } from 'services/comment';
import { getUsDateTime } from 'utils/date';
import { useToasts } from 'react-toast-notifications';
import { update } from 'services/occurrence';
import showAlert from 'components/Alert';
import WithPermission from 'components/WithPermission';

export interface OccurrenceUpdatesParams {
  occurrence: OccurrenceUpdates;
  onReload?(): void;
}

export default function UpdatesOccurrenceModal() {
  const { isOpen, windowSec, params } = useModal<OccurrenceUpdatesParams>(
    ModalName.UPDATES_OCCURRENCE_MODAL,
  );

  const dispatch = useDispatch();
  const { occurrence, onReload } = params;

  const user = useUser();
  const { addToast } = useToasts();

  const [formData, setFormData] = useState<OccurrenceUpdates>(
    {} as OccurrenceUpdates,
  );

  useEffect(() => {
    if (!isOpen) {
      setFormData({} as OccurrenceUpdates);

      return;
    }

    getData();
  }, [isOpen, formData.uuid]);

  async function getData() {
    if (occurrence.uuid || formData.uuid) {
      const data = await getSingleResponse<OccurrenceUpdates>(2604, {
        idContrato: occurrence.idContrato,
        uuid: occurrence.uuid || formData.uuid,
      });

      setFormData(data);

      return;
    }

    return setFormData({
      ...occurrence,
      dtCriacao: getUsDateTime()!,
      idUsuario: user.idUsuario!,
      usuario: `${user.nome} ${user.sobrenome}`,
    });
  }

  function handleClose() {
    dispatch(closeModal(ModalName.UPDATES_OCCURRENCE_MODAL));

    if (onReload) {
      onReload();
    }
  }

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const { name } = e.target;
    const { value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  }

  function handleShowUploadModal() {
    if (!formData.uuid) {
      return addToast('Salve antes de enviar uma foto', {
        appearance: 'warning',
      });
    }

    return dispatch(
      showModal<UploadModalParams>(ModalName.MODAL_UPLOAD, {
        idContrato: formData.idContrato,
        idDatasetImages: 2593,
        uuidReferencia: formData.uuid,
        allowDisableImage: false,
        id: 1,
        tipo: 'RESPOSTA_OCORRENCIA',
        idUsuario: user.idUsuario!,
        idPerfilModulo: user.idPerfilModulo,
        typeOfPage: EnumRoutes.UPLOAD_IMAGE,
        onReloadPageForm: getData,
        title: 'Imagens da ocorrência',
      }),
    );
  }

  function saveOccurrenceStatus() {
    const confirm = async () => {
      await saveComment(OccurrenceStatus.FINALIZADO);
      await update({
        idOcorrencia: formData.idOcorrencia,
        flagStatus: OccurrenceStatus.FINALIZADO,
        status: OccurrenceStatus.FINALIZADO,
        idContrato: formData.idContrato,
        idEmpresa: formData.idEmpresa || user.idEmpresa,
        dtStatus: getUsDateTime(),
        idUltimoUsuario: user.idUsuario!,
      });

      handleClose();
    };

    showAlert({
      title: 'Atenção!',
      subtitle: 'Você selecionou "Finalizar a Ocorrência", deseja prosseguir?',
      actions: [
        {
          text: 'Não',
        },
        {
          text: 'Sim',
          onClick: confirm,
        },
      ],
    });
  }

  async function saveComment(status: number) {
    if (!formData.comentario) {
      return addToast('Preenchar os campos de descrição', {
        appearance: 'warning',
      });
    }

    if (!formData.uuid) {
      const newComment = {
        idContrato: formData.idContrato,
        idUsuario: formData.idUsuario,
        origem: 'COMENTARIO',
        tipo: 'ATUALIZACAO_OCORRENCIA',
        dtCriacao: formData.dtCriacao,
        comentario: formData.comentario,
        status,
        flagStatus: 1,
        uuidReferencia: formData.uuidReferencia,
        id: 0,
      };

      const responseComment = await add(newComment);

      setFormData({
        ...formData,
        uuid: responseComment[0].uuid!,
      });
      handleClose();

      return addToast('Salvo com sucesso', { appearance: 'success' });
    }

    await updateComment({ ...formData, status });

    handleClose();

    return addToast('Atualizado com sucesso', { appearance: 'success' });
  }

  function renderLabelPhoto() {
    if (formData.qtdFotos) {
      return `Upload fotos (${formData.qtdFotos})`;
    }

    return `Upload fotos`;
  }

  function validationComment() {
    if (formData.id && formData.tipo === 'OCORRENCIA') {
      return true;
    }

    return false;
  }

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Modal isOpen={isOpen} windowSec={windowSec}>
        <ModalHeader>
          <ButtonClose onClick={handleClose} />
          <Title>Atualização</Title>
        </ModalHeader>
        <ModalContainer width="30vw" height="40vh">
          <Content>
            <ScrollArea>
              <Row>
                <TextArea
                  minRows={4}
                  label="Descrição"
                  name="comentario"
                  defaultValue={formData.comentario}
                  onChange={handleChange}
                />
              </Row>

              <Row>
                <div>
                  <Label>Fotos</Label>
                  <Button
                    icon={<UploadIcon />}
                    text={renderLabelPhoto()}
                    onClick={handleShowUploadModal}
                    disabled={validationComment()}
                  />
                </div>
              </Row>
            </ScrollArea>

            <Footer>
              <WithPermission
                action={EnumActions.FINALIZAR_OCORRENCIA}
                rules={[
                  EnumProfile.APROVADOR,
                  EnumProfile.ADMINISTRADOR,
                  EnumProfile.AUDITOR,
                  EnumProfile.GESTOR,
                ]}
              >
                <div>
                  <Button
                    primary
                    outline
                    text="Finalizar ocorrência"
                    onClick={saveOccurrenceStatus}
                  />
                </div>
              </WithPermission>

              <div>
                <Button
                  primary
                  text="Salvar comentário"
                  onClick={() => saveComment(occurrence.status)}
                />
              </div>
            </Footer>
          </Content>
        </ModalContainer>
      </Modal>
    </>
  );
}
