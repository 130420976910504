import React from 'react';
import LevelSelect from './components/LevelSelect';
import XlsExport from './components/XlsExport';
import { useSimpleTableContext } from '../../context';
import { Container } from './styles';

export default function ActionsBar() {
  const { xlsExport } = useSimpleTableContext();

  return (
    <Container>
      <LevelSelect />
      {xlsExport ? <XlsExport /> : <></>}
    </Container>
  );
}
