import styled from 'styled-components';

export const MenuMainWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 5.3rem;
  width: 100%;
  height: calc(100vh - 5.3rem);

  @media only screen and (max-width: 960px) {
    top: 3.8rem;
    height: calc(100% - 3.8rem);
  }

  @media only screen and (max-width: 480px) {
    top: ${props => props.navHeight};
    height: calc(100% - ${props => props.navHeight});
  }
`;
export const Main = styled.div`
  flex-grow: 1;
  overflow: auto;
  height: 100%;
`;
