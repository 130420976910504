import styled from 'styled-components';
import { IoIosCheckmarkCircle } from 'react-icons/io';

export const ContainerList = styled.div`
  flex: 1;
  overflow: scroll;
`;

export const SelectedIcon = styled(IoIosCheckmarkCircle)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.8rem;
  cursor: pointer;
`;

export const Text = styled.span`
  font-size: 1.3rem;
  margin: 0 0.8rem;
  flex: 1;
`;
