import styled from 'styled-components';

export const Container = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: 300px;
  min-width: 300px;
  background-color: white;

  .fishboneChart {
    font-size: 16px !important;
    padding: 10px;
  }

  .causes {
    font-size: 16px !important;
  }

  .effect {
    font-size: 16px !important;
    max-width: 150px;
  }

  .legend {
    font-size: 16px !important;
  }
`;
