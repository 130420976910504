import React from 'react';
import useContract from 'hooks/use-contract';
import ExportDatasetXls from 'pages/Reports/components/XlsExport';
import { useSimpleTableContext } from '../../../../context';
import { Container } from './styles';

export default function XlsExport() {
  const { xlsExport, idPeriodo } = useSimpleTableContext();
  const { idContrato } = useContract() || {};

  return (
    <Container>
      <ExportDatasetXls
        iconButton
        showModalMessage
        datasetId={xlsExport.idDataset}
        fileName={xlsExport.fileName || 'Relatório'}
        params={{ idContrato, idPeriodo }}
      />
    </Container>
  );
}
