export const SET_IS_MOBILE = 'config/SET_IS_MOBILE';
export const SET_CUSTOM_GLOBAL_STYLES = 'config/SET_CUSTOM_GLOBAL_STYLES';

export interface ConfigState {
  isMobile: boolean;
  customGlobalStyles: object;
}

interface SetIsMobileAction {
  type: typeof SET_IS_MOBILE;
  payload: boolean;
}

interface SetCustomGlobalStylesAction {
  type: typeof SET_CUSTOM_GLOBAL_STYLES;
  payload: object;
}

export type ConfigActionTypes = SetIsMobileAction | SetCustomGlobalStylesAction;
