import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.grife.fisico.gerencial' */
  ),
);
const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'relatorios.grife.financeiro.obras' */
  ),
);
const DesviosOrcamento = lazy(() =>
  import(
    './layouts/financeiro/desviosOrcamento' /* webpackChunkName: 'relatorios.grife.financeiro.desvios-do-orcamento' */
  ),
);
const InspecaoSeguranca = lazy(() =>
  import(
    './layouts/seguranca/inspecaoSeguranca' /* webpackChunkName: 'relatorios.grife.seguranca.inspecao-seguranca' */
  ),
);
const RelatorioGeralDeSeguranca = lazy(() =>
  import(
    './layouts/seguranca/relatorioGeralDeSeguranca' /* webpackChunkName: 'relatorios.grife.seguranca.relatorio-geral-de-seguranca' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/grife/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/grife/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/grife/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/grife/financeiro/obras"
  />,
  <PrivateRoute
    key="/relatorios/grife/financeiro/desvios-do-orcamento"
    component={DesviosOrcamento}
    path="/relatorios/grife/financeiro/desvios-do-orcamento"
  />,
  <PrivateRoute
    key="/relatorios/grife/seguranca/inspecao-seguranca"
    component={InspecaoSeguranca}
    path="/relatorios/grife/seguranca/inspecao-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/grife/seguranca/relatorio-geral-de-seguranca"
    component={RelatorioGeralDeSeguranca}
    path="/relatorios/grife/seguranca/relatorio-geral-de-seguranca"
  />,
];
