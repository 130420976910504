import React, {
  ChangeEvent,
  InputHTMLAttributes,
  ReactElement,
  useRef,
} from 'react';

import {
  FileListWrapper,
  IconRemove,
  IconUpload,
  LabelContainer,
} from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  onSelectFiles: (files: File[]) => void;
  text?: string;
  containerStyle?: any;
}

export default function InputFile(props: Props): ReactElement {
  const { onSelectFiles, text, containerStyle, id, style } = props;

  const inputRef = useRef<InputHTMLAttributes<HTMLInputElement>>();

  function handleSelectFiles(e: ChangeEvent<HTMLInputElement>) {
    const files: globalThis.File[] = Array.from(e.target.files!);
    onSelectFiles(files);
    inputRef.current!.value = '';
  }

  return (
    <LabelContainer style={containerStyle || style} htmlFor={id || 'file'}>
      <IconUpload />
      {text && <span>{text}</span>}
      <input
        ref={inputRef}
        id={id || 'file'}
        type="file"
        onChange={handleSelectFiles}
        {...props}
      />
    </LabelContainer>
  );
}

interface FileListProps {
  files: globalThis.File[];
  onRemoveFile: (file: globalThis.File) => void;
}

export function FileList(props: FileListProps): ReactElement {
  const { files, onRemoveFile } = props;

  if (!files || !files.length) {
    return <></>;
  }

  return (
    <FileListWrapper>
      {files.map(file => (
        <li key={file.name}>
          <span>{file.name}</span>
          <IconRemove onClick={() => onRemoveFile(file)} />
        </li>
      ))}
    </FileListWrapper>
  );
}
