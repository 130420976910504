import apiAdonis from './apiAdonis';

export interface PayloadBulkInsertOrCreate {
  id: number;
  idUsuario: number;
  idPerfil: number;
  idEmpresa: number;
  idTipoContrato: number;
  flagStatus?: number;
}
export function updateOrCreateMany(data: any[]): Promise<boolean> {
  return apiAdonis.post(
    '/usuario-perfil-tipo-contrato/update-or-create-many',
    data,
  );
}

export function storeByUserTemplate(data: {
  idUsuario: number;
  idUserModelo: number;
}): Promise<boolean> {
  return apiAdonis.post(
    '/usuario-perfil-tipo-contrato/store-by-user-template',
    data,
  );
}

export function bulkInsertOrCreate(
  data: PayloadBulkInsertOrCreate[],
): Promise<boolean> {
  return apiAdonis.post(
    '/usuario-perfil-tipo-contrato/bulk-insert-or-create',
    data,
  );
}

export function removeTemplateAccesses(data: {
  idUsuario: number;
}): Promise<boolean> {
  return apiAdonis.post(
    '/usuario-perfil-tipo-contrato/remove-template-accesses',
    data,
  );
}
