import styled, { css } from 'styled-components';
import CurrencyInput from 'react-currency-input-field';

const Error = css`
  border-color: ${({ theme }) => theme.colors.danger};
`;

const Small = css`
  height: 2.8rem;
  padding: 0 0.6rem;
`;

const CommonStyle = css<{ error?: boolean; small?: boolean }>`
  height: 4rem;
  width: 100%;
  padding: 0 1rem;
  color: #333;
  font-size: 1.4rem;
  border: 1px solid #ddd;
  font-style: inherit;
  letter-spacing: 0.08rem;
  border-radius: ${({ theme }) => theme.radius.medium}px;
  font-family: 'Roboto', 'Open Sans', sans-serif;
  ${({ error }) => error && Error};
  ${({ small }) => small && Small};
  &::placeholder {
    color: #999;
    font-weight: 300;
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.grey40};
    ${({ error }) => error && Error};
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
    outline: 0 none;
    ${({ error }) => error && Error};
  }
`;

export const StyledInput = styled.input<{ error?: boolean; small?: boolean }>`
  ${CommonStyle}
`;

export const FormGroup = styled.div`
  display: block;
  width: 100%;
`;

export const StyledCurrencyInput = styled(CurrencyInput)<{
  error?: boolean;
  small?: boolean;
}>`
  ${CommonStyle}
`;
