import React from 'react';
import useModal from 'hooks/use-modal';
import { useDispatch } from 'react-redux';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import {
  ButtonClose,
  ModalContainer,
  ModalHeader,
  ModalTitle,
} from 'styles/modal';
import Modal from 'components/Modal';
import { useHistory } from 'react-router';
import { EnumRoutes } from 'store/types';

export interface CallTypeModalParams {
  id: number;
  idStatus?: number;
  onAfterSave: () => void;
}

export default function CallTypeModal() {
  const { isOpen, windowSec } = useModal<CallTypeModalParams>(
    ModalName.CALL_TYPE_MODAL,
  );

  const dispatch = useDispatch();

  const history = useHistory();

  function handleClose() {
    dispatch(closeModal(ModalName.CALL_TYPE_MODAL));
  }

  function renderModal() {
    dispatch(showModal(ModalName.CHECK_CNPJ));
    dispatch(closeModal(ModalName.CALL_TYPE_MODAL));
  }

  return (
    <Modal isOpen={isOpen} windowSec={windowSec}>
      <ModalHeader>
        <ButtonClose onClick={handleClose} />

        <ModalTitle>Tipo de chamado</ModalTitle>
      </ModalHeader>

      <ModalContainer
        width="38vw"
        height="18vh"
        style={{ minWidth: '47rem', minHeight: '15rem' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '20rem',
            gap: '2rem',
            alignItems: 'center',
            paddingTop: '2rem',
          }}
        >
          <span
            style={{
              backgroundColor: '#DEF4F1',
              height: '5rem',
              width: '45rem',
              padding: '0 4rem',
              display: 'flex',
              fontSize: '2rem',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => (
              dispatch(closeModal(ModalName.CALL_TYPE_MODAL)),
              history.push(EnumRoutes.SUPPORT)
            )}
          >
            Suporte / Abertura de chamado
          </span>

          <span
            style={{
              backgroundColor: '#DEF4F1',
              height: '5rem',
              width: '45rem',
              display: 'flex',
              fontSize: '2rem',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => renderModal()}
          >
            Ativar Módulo
          </span>
        </div>
      </ModalContainer>
    </Modal>
  );
}
