import * as Yup from 'yup';

export const validation = Yup.object().shape({
  nome: Yup.string()
    .typeError('Este campo é obrigatório')
    .required('Este campo é obrigatório'),
  email: Yup.string()
    .max(50, 'Este email é muito longo')
    .email('E-mail inválido.')
    .required('Este campo é obrigatório'),
  empresa: Yup.string()
    .typeError('Este campo é obrigatório')
    .required('Este campo é obrigatório'),
  celular: Yup.string().required('Este campo é obrigatório'),
  // .max(15, 'Este telefone é muito longo')
  // .matches(
  //   /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/,
  //   'Este telefone é inválido',
  // ),
});
