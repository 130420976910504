import styled from 'styled-components';

export const Container = styled.div`
  button {
    padding: 0 0.4rem;
    border: none;

    img {
      margin-right: 0;
    }
  }
`;
