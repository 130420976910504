import { BiMailSend, BiPlusCircle, BiPrinter } from 'react-icons/bi';
import { BsArrowsExpand } from 'react-icons/bs';
import { CgAttachment } from 'react-icons/cg';
import { FaCalendarAlt } from 'react-icons/fa';
import { FiFilter, FiTrash2 } from 'react-icons/fi';
import { ImUndo2 } from 'react-icons/im';
import {
  IoIosArrowForward,
  IoIosCheckmarkCircle,
  IoMdAddCircle,
  IoMdCloseCircle,
  IoMdInformationCircle,
} from 'react-icons/io';
import {
  MdBlock,
  MdEdit,
  MdFileDownload,
  MdFileUpload,
  MdMenu,
  MdMoreVert,
} from 'react-icons/md';
import styled, { keyframes } from 'styled-components';
import { FaSpinner } from 'react-icons/fa';

export const CheckedIcon = styled(IoIosCheckmarkCircle)<{
  color?: string;
  checked?: boolean;
}>`
  font-size: 1.8rem;
  color: ${({ theme, checked, color }) => {
    if (color) return color;

    return checked ? theme.colors.primary : '#ccc';
  }};
  cursor: pointer;
`;

export const NoCheckedIcon = styled(IoMdCloseCircle)`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.danger};
  cursor: pointer;
`;

export const DragAndDropIcon = styled(MdMenu)`
  font-size: 2rem;
  color: #ccc;
`;

export const EditIcon = styled(MdEdit)`
  font-size: 1.8rem;
  color: #ccc;
  cursor: pointer;

  &:hover {
    color: #333;
  }
`;

export const RemoveIcon = styled(FiTrash2)`
  font-size: 1.8rem;
  color: #ccc;

  cursor: pointer;

  &:hover {
    color: #333;
  }
`;

export const IconOptions = styled(MdMoreVert)`
  color: #333;
  cursor: pointer;
  font-size: 1.8rem;
`;

export const TreeviewIconArrow = styled(IoIosArrowForward)<{
  isOpen?: boolean;
  level?: number;
  lastLevel?: boolean;
}>`
  cursor: pointer;
  color: #333;
  margin-right: 1rem;
  margin-bottom: 2px;
  font-size: 1.5rem;
  transition: all 0.1s;
  margin-left: ${({ level }) => (level ? level + 1 : 0)}rem;
  ${({ isOpen }) => (isOpen ? 'transform: rotate(90deg);' : '')}
  ${({ lastLevel }) => (lastLevel ? 'color: transparent;' : '')}
`;

export const InfoIcon = styled(IoMdInformationCircle)<{ color?: string }>`
  font-size: 2rem;
  color: ${({ color }) => color || '#333'};
  cursor: pointer;
`;

export const AttachIcon = styled(CgAttachment)`
  font-size: 1.8rem;
  color: #ccc;
  cursor: pointer;

  &:hover {
    color: #333;
  }
`;

export const AddIcon = styled(IoMdAddCircle)`
  font-size: 1.8rem;
  color: #ccc;
  cursor: pointer;

  &:hover {
    color: #333;
  }
`;

export const DisabledIcon = styled(MdBlock)`
  font-size: 1.8rem;
  color: #ccc;
  cursor: pointer;
`;

export const UndoIcon = styled(ImUndo2)`
  font-size: 1.8rem;
  color: #ccc;
  cursor: pointer;

  &:hover {
    color: #333;
  }
`;

export const FilterIcon = styled(FiFilter)`
  font-size: 1.8rem;
  color: #ccc;
  cursor: pointer;

  &:hover {
    color: #333;
  }
`;

export const EmailIcon = styled(BiMailSend)`
  font-size: 1.8rem;
  color: #ccc;
  cursor: pointer;

  &:hover {
    color: #333;
  }
`;

export const DonwloadIcon = styled(MdFileDownload)`
  font-size: 1.8rem;
  color: #ccc;
  cursor: pointer;

  &:hover {
    color: #333;
  }
`;

export const UploadIcon = styled(MdFileUpload)`
  font-size: 1.8rem;
  color: #ccc;
  cursor: pointer;

  &:hover {
    color: #333;
  }
`;

export const CalendarIcon = styled(FaCalendarAlt)`
  font-size: 1.8rem;
  color: #333;
`;

export const PrintIcon = styled(BiPrinter)`
  font-size: 1.8rem;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
`;

export const ArrowsExpandsIcon = styled(BsArrowsExpand)`
  font-size: 2rem;
  color: #ccc;
  cursor: n-resize;
`;

export const PlusIcon = styled(BiPlusCircle)`
  font-size: 2.4rem;
  cursor: pointer;
  margin: auto;

  &:hover {
    color: #333;
  }
`;

const rotate = keyframes`
  to { 
    transform: rotate(360deg);
  }
`;

export const IconSpinner = styled(FaSpinner)<{
  color: string | undefined;
  size: string | undefined;
}>`
  animation: ${rotate} 2s linear infinite;
  font-size: ${({ size }) => size};
  color: ${({ theme, color }) => color || theme.colors.primary};
`;
