import styled, { keyframes } from 'styled-components';
import { FaSpinner } from 'react-icons/fa';

const rotate = keyframes`
  to { 
    transform: rotate(360deg);
  }
`;

export const IconSpinner = styled(FaSpinner)<{
  color: string | undefined;
  size: string | undefined;
}>`
  animation: ${rotate} 2s linear infinite;
  font-size: ${({ size }) => size};
  color: ${({ theme, color }) => color || theme.colors.primary};
`;

export const SpinnerWrapper = styled.div`
  margin: 5rem auto 0 auto;
`;
