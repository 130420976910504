import React, {
  ReactElement,
  useState,
  ChangeEvent,
  useEffect,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  IconWrapper,
  ArrowIcon,
  SearchIcon,
  ContractsWrapper,
  SpinnerWrapper,
} from './styles';
import { Contract } from '../../store/types';
import { RootState } from '../../store/rootReducer';
import { getDataset } from '../../services/portal';
import Spinner from '../Spinner';
import { set, clear } from '../../store/contract/actions';
import useOutsideClick from '../../hooks/use-outside-click-handle';

export default function ContractFilter(): ReactElement {
  const [allowInput, setAllowInput] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [contractName, setContractName] = useState('');
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [filtered, setFiltered] = useState<Contract[]>([]);
  const { datasetId, contract } = useSelector(
    (state: RootState) => state.contract,
  );
  const datasetParams = useSelector(
    (state: RootState) => state.report.getDatasetData,
  );
  const isMobile = useSelector((state: RootState) => state.config.isMobile);
  const user = useSelector((state: RootState) => state.user);

  const inputRef = useRef(null);
  const contractsListRef = useRef(null);
  const dispatch = useDispatch();

  useOutsideClick({
    ref: contractsListRef,
    state: allowInput,
    onOutsideClicked: handleOutsideClick,
  });

  useEffect(() => {
    getContracts();
  }, [datasetId, user?.idUsuario]);

  useEffect(() => {
    if (allowInput) {
      inputRef.current.focus();
    }
  }, [allowInput]);

  useEffect(() => {
    if (contract) {
      setContractName(contract.nome);
    }
  }, [contract]);

  async function getContracts() {
    if (datasetId && user?.idUsuario) {
      dispatch(clear());
      setIsFetching(true);
      setAllowInput(true);
      setContracts([]);
      setContractName('');

      const newDatasetParams = [
        ...datasetParams,
        {
          datakey: 'id_usuario',
          value: user.idUsuario,
        },
        {
          datakey: 'id_empresa',
          value: user.idEmpresa,
        },
      ];

      const res = await getDataset(datasetId, newDatasetParams);

      setFiltered(res);
      setContracts(res);
      setIsFetching(false);
    }
  }

  function handleOutsideClick() {
    if (allowInput && contract) {
      setAllowInput(false);
    }
  }

  function handleFilter(e: ChangeEvent<HTMLInputElement>) {
    const filter = (contractToFilter: Contract): boolean =>
      contractToFilter.nome
        .toLowerCase()
        .indexOf(e.target.value.toLowerCase()) >= 0;
    const newContracts = contracts.filter(filter);
    setFiltered(newContracts);
    setContractName(e.target.value);
  }

  function handleSelectContract(selectedContract: Contract) {
    dispatch(set(selectedContract));
    setAllowInput(false);
  }

  function handleAllowInput() {
    setFiltered(contracts);
    setAllowInput(true);
    setContractName('');
  }

  function renderContracts() {
    if (isFetching) {
      return (
        <ContractsWrapper>
          <SpinnerWrapper>
            <Spinner size="medium" />
          </SpinnerWrapper>
        </ContractsWrapper>
      );
    }

    return (
      <ContractsWrapper ref={contractsListRef} isMobile={isMobile}>
        {filtered.map(selectedContract => (
          <li key={selectedContract.idContrato}>
            <button
              type="button"
              onClick={() => handleSelectContract(selectedContract)}
            >
              <span>{selectedContract.nome}</span>
            </button>
          </li>
        ))}
      </ContractsWrapper>
    );
  }

  return (
    <Container>
      <IconWrapper style={{ height: '100%' }} hide={!allowInput}>
        <SearchIcon />
        <input
          ref={inputRef}
          value={contractName}
          placeholder="Selecione um contrato"
          onChange={handleFilter}
          readOnly={isMobile}
        />

        {renderContracts()}
      </IconWrapper>

      <IconWrapper hide={allowInput} onClick={handleAllowInput}>
        <ArrowIcon />
        <span id="contract-name">
          {contract ? contract.nome : 'SELECIONE UM CONTRATO'}
        </span>
      </IconWrapper>
    </Container>
  );
}
