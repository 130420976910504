export const SET_LAYOUT_ID = 'report/SET_LAYOUT_ID';
export const SET_GET_DATASET_DATA = 'report/SET_GET_DATASET_DATA';
export const SET_PRINTER_SETTINGS = 'report/SET_PRINTER_SETTINGS';
export const SET_REPORT_QUEUE = 'report/SET_REPORT_QUEUE';
export const REMOVE_REPORT_QUEUE = 'report/REMOVE_REPORT_QUEUE';
export const SET_REPORT_QUEUE_LIST = 'report/SET_REPORT_QUEUE_LIST';

export interface ReportQueue {
  uuid: string;
  nome: string;
  status: 0 | 1 | 2 | 3;
}

export interface ReportState {
  layoutId: number | null;
  companyId: number | null;
  impersonateId: number | null;
  getDatasetData: DatasetParams[];
  printerSettings: object;
  reportQueue: ReportQueue[];
}

export interface DatasetParams {
  datakey: string;
  value: number | null;
}

interface SetLayoutIdAction {
  type: typeof SET_LAYOUT_ID;
  payload: number;
}

interface SetGetDatasetDataAction {
  type: typeof SET_GET_DATASET_DATA;
  payload: DatasetParams[];
}

interface SetPrinterSettingsAction {
  type: typeof SET_PRINTER_SETTINGS;
  payload: object;
}

interface SetReportQueueAction {
  type: typeof SET_REPORT_QUEUE;
  payload: ReportQueue;
}

interface RemoveReportQueueAction {
  type: typeof REMOVE_REPORT_QUEUE;
  uuid: string;
}

interface SetReportQueueActionList {
  type: typeof SET_REPORT_QUEUE_LIST;
  payload: ReportQueue[];
}

export type ReportActionTypes =
  | SetLayoutIdAction
  | SetGetDatasetDataAction
  | SetReportQueueAction
  | RemoveReportQueueAction
  | SetReportQueueActionList
  | SetPrinterSettingsAction;
