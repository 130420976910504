import Button from 'components/Button';
import Modal from 'components/Modal';
import TextArea from 'components/TextArea';
import Attachments from 'components/Widgets/Attachments';
import { useFormik } from 'formik';
import useModal from 'hooks/use-modal';
import useUser from 'hooks/use-user';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { add, update } from 'services/comment';
import { update as updateRepproval } from 'services/approval';
import { getSingleResponse, getSqlResponse } from 'services/dataset';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { Comment, OptionsType } from 'store/types';
import {
  ButtonClose,
  ModalContainer,
  ModalHeader,
  ModalTitle,
} from 'styles/modal';
import { generateUUID } from 'utils/common';
import { getUtcDatetime } from 'utils/date';
import Info from './components/Info';
import { Content, Footer, Form } from './styles';
import { validation } from './validation';
import { Label } from 'styles/form';
import Select from 'components/ReactSelect';
import { Danger } from 'styles/text';

export interface FormRepprovalReevaluateModalParams {
  idComentario: number | undefined;
  idOcorrencia: number;
  idAprovacao: number;
  seq: number;
  uuidOcorrencia: string;
  idContrato: number;
  idTipo?: number;
  onAfterSave(): Promise<void> | void;
}

interface SaveValuesProps extends Comment {
  areaResponsavel?: OptionsType;
}

const ORIGEM = 'OCORRENCIA';

export default function FormRepprovalReevaluateModal() {
  const { isOpen, params, windowSec } = useModal<
    FormRepprovalReevaluateModalParams
  >(ModalName.FORM_REPPROVAL_REEVALUATE_MODAL);

  const [optionsList, setOptionsList] = useState<OptionsType[]>();

  const user = useUser();

  const formik = useFormik({
    initialValues: {} as SaveValuesProps,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    if (isOpen) {
      getData();
    } else {
      formik.setValues({} as SaveValuesProps);
    }
  }, [isOpen]);

  async function getData() {
    const { idComentario, idTipo } = params;

    const optionsListResponse = await getSqlResponse<OptionsType[]>(2324, {
      idEmpresa: user?.idEmpresa,
      idTipo,
    });

    setOptionsList(optionsListResponse);

    if (!idComentario) {
      formik.setFieldValue('uuid', generateUUID());

      return;
    }

    dispatch(showModal(ModalName.LOADING, { text: 'Carregando atualização' }));

    const commentResponse = await getSingleResponse<SaveValuesProps>(1106, {
      idComentario,
    });

    const selected = optionsListResponse.filter(
      option => option.value === commentResponse.areaResponsavel,
    );

    const newValues = {
      ...commentResponse,
      areaResponsavel: selected.length ? selected[0] : ({} as OptionsType),
    };

    formik.setValues(newValues);

    dispatch(closeModal(ModalName.LOADING));
  }

  async function handleSubmit(values: SaveValuesProps) {
    dispatch(showModal(ModalName.LOADING, { text: 'Salvando resposta' }));

    const { idComentario, onAfterSave, idAprovacao } = params;

    if (!idComentario) {
      await createNewReply(values);
      await updateRepproval({
        id: idAprovacao,
        areaResponsavel: values.areaResponsavel?.value,
        status: 2,
      });
    } else {
      await updateCurrentReply(values);
      await updateRepproval({
        id: idAprovacao,
        areaResponsavel: values.areaResponsavel?.value,
        status: 2,
      });
    }

    await onAfterSave();

    dispatch(closeModal(ModalName.LOADING));
    addToast('Resposta salva com sucesso', { appearance: 'success' });
    handleClose();
  }

  function createNewReply(values: SaveValuesProps) {
    const { idContrato, idOcorrencia, uuidOcorrencia } = params;

    const occurrence: Partial<SaveValuesProps> = {
      ...values,
      origem: 'OCORRENCIA',
      idContrato,
      id: idOcorrencia,
      idUsuario: user.idUsuario!,
      keyReferencia: uuidOcorrencia,
      dtCriacao: getUtcDatetime(),
      tipo: 'ATUALIZACAO OCORRENCIA',
    };

    return add(occurrence as SaveValuesProps);
  }

  function updateCurrentReply(values: SaveValuesProps) {
    const { idContrato, idComentario } = params;

    const occurrence: Partial<SaveValuesProps> = {
      ...values,
      idContrato,
      idComentario,
    };

    return update(occurrence as SaveValuesProps);
  }

  function checkIsDisabledField() {
    const { idComentario } = params;

    if (!idComentario) {
      return false;
    }

    return user.idUsuario !== formik.values.idUsuario;
  }

  function handleClose() {
    dispatch(closeModal(ModalName.FORM_REPPROVAL_REEVALUATE_MODAL));
  }

  function handleSelectChange(selectedOption: OptionsType) {
    formik.setFieldValue('areaResponsavel', selectedOption);
  }

  const { seq } = params;

  const isFieldDisabled = checkIsDisabledField();

  return (
    <Modal isOpen={isOpen} windowSec={windowSec}>
      <ModalHeader>
        <ButtonClose onClick={handleClose} />
        <ModalTitle>Atualização</ModalTitle>
      </ModalHeader>

      <ModalContainer width="70rem" height="60vh">
        <Form onSubmit={formik.handleSubmit}>
          <Content>
            <Info reply={formik.values} />
            <TextArea
              label="Descrição"
              name="comentario"
              value={formik.values.comentario}
              errorMessage={formik.errors.comentario}
              onChange={formik.handleChange}
              disabled={isFieldDisabled}
            />

            {seq === 1 && (
              <div style={{ width: '20rem' }}>
                <Label>Enviar Para</Label>
                <Select
                  name="area_responsavel"
                  options={optionsList}
                  value={formik.values.areaResponsavel}
                  onChange={handleSelectChange}
                  error={!!formik.errors.areaResponsavel}
                  isDisabled={isFieldDisabled}
                />
                {formik.errors.areaResponsavel ? (
                  <Danger>{formik.errors.areaResponsavel}</Danger>
                ) : null}
              </div>
            )}

            <Attachments
              type={ORIGEM}
              title="Anexos"
              idReferencia={0}
              inputId={'anexo_resposta_reprovacao'}
              keyReferencia={formik.values.uuid}
            />
          </Content>

          <Footer>
            <Button primary text="Salvar" type="submit" />
          </Footer>
        </Form>
      </ModalContainer>
    </Modal>
  );
}
