import { Device } from 'hooks/use-device-detect';

export function getPageContainerSize(isAppSource: boolean, deviceSize: Device) {
  if (isAppSource) {
    return 'calc(100vh - 7rem);';
  }

  if (deviceSize.isTablet || deviceSize.isMobile) {
    return 'calc(100vh - 11rem);';
  }

  return 'calc(100vh - 15rem);';
}
