export const SET_MENU_FLAG = 'menu/SET_MENU_FLAG';
export const SET_MENU_ROUTES = 'menu/SET_MENU_ROUTES';
export const SET_MENU_PARAMS = 'menu/SET_MENU_PARAMS';
export const SHOW_MENU = 'menu/SHOW_MENU';
export const HIDE_MENU = 'menu/HIDE_MENU';
export const BACK_TO_HOME_DISPLAY = 'menu/BACK_TO_HOME_DISPLAY';
export const HAS_MENU = 'menu/HAS_MENU';
export const SET_SUB_MENU = 'menu/SET_SUB_MENU';
export const SET_MENU_RAW = 'menu/SET_MENU_RAW';

interface MenuParams {
  layoutId?: number;
  mobileLayoutId?: number;
  flagMobile?: number | boolean | null;
  datasetId?: number | null;
}

export interface MenuState {
  menuFlag: string;
  menuRoutes: string[];
  menuParams: MenuParams;
  display: string;
  homeBtnDisplay: string;
  hasMenu: boolean;
  menuRendered: any[];
  menuRaw: any[];
}

interface SetMenuFlagAction {
  type: typeof SET_MENU_FLAG;
  payload: string;
}

interface SetMenuRoutesAction {
  type: typeof SET_MENU_ROUTES;
  payload: string[];
}

interface SetMenuParamsAction {
  type: typeof SET_MENU_PARAMS;
  payload: object;
}

interface ShowMenuAction {
  type: typeof SHOW_MENU;
}

interface HideMenuAction {
  type: typeof HIDE_MENU;
}

interface BackToHomeDisplayAction {
  type: typeof BACK_TO_HOME_DISPLAY;
  payload: string;
}

interface SetHasMenu {
  type: typeof HAS_MENU;
  payload: boolean;
}

interface SetSubMenu {
  type: typeof SET_SUB_MENU;
  payload: any;
}

interface SetMenuRaw {
  type: typeof SET_MENU_RAW;
  payload: any;
}

export type MenuActionTypes =
  | SetMenuFlagAction
  | SetMenuRoutesAction
  | SetMenuParamsAction
  | ShowMenuAction
  | HideMenuAction
  | SetHasMenu
  | SetSubMenu
  | SetMenuRaw
  | BackToHomeDisplayAction;
