import { isNullOrUndefined } from 'utils/common';
import { Photo } from './types';

export function isEditAllowed(image: Photo) {
  const { habilitaEdicaoFoto } = image;

  return isNullOrUndefined(habilitaEdicaoFoto)
    ? true
    : Number(habilitaEdicaoFoto);
}
