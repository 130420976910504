import NavLogoMobile from 'assets/navbar-logo-o.png';
import NavLogo from 'assets/navbar-logo3.png';
import React, { ReactElement, useEffect, useState } from 'react';
import { Container, NavLogoImg, StyledNavBar, TitleWrapper } from './styles';

interface Props {
  title: string;
}

export default function SimpleNavbar(props: Props): ReactElement {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    checkScreenSize();
  }, []);

  function checkScreenSize() {
    setIsSmallScreen(window.innerWidth < 768);
  }

  const { title } = props;

  return (
    <Container>
      <StyledNavBar>
        <NavLogoImg src={isSmallScreen ? NavLogoMobile : NavLogo} />

        <TitleWrapper size={title ? title.length : 0}>
          <span>{title}</span>
        </TitleWrapper>
      </StyledNavBar>
    </Container>
  );
}
