import React from 'react';
import { Comment } from 'store/types';
import { LabelInfo, Text } from 'styles/text';
import { toDateTimeBR } from 'utils/date';
import { Row } from './styles';

interface Props {
  reply: Comment;
}

export default function Info(props: Props) {
  const { reply } = props;

  if (!reply.dtCriacao) {
    return null;
  }

  return (
    <Row>
      <div>
        <LabelInfo>Criado por</LabelInfo>
        <Text>{reply.nomeUsuario}</Text>
      </div>
      <div>
        <LabelInfo>Data de criação</LabelInfo>
        <Text>{toDateTimeBR(reply.dtCriacao)}</Text>
      </div>
    </Row>
  );
}
