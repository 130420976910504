import React from 'react';
import { useDispatch } from 'react-redux';
import { Approval } from 'store/types';
import { useDynamicForm } from 'components/Widgets/DynamicForm/context';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { useToasts } from 'react-toast-notifications';
import {
  cancel as cancelApproval,
  create as createApproval,
} from 'services/approval';
import useUser from 'hooks/use-user';

import { Button } from '../../../styles';

export default function ApprovalButton() {
  const {
    approvalData,
    globalState,
    getApprovalData,
    setApprovalData,
    contractId: idContrato,
  } = useDynamicForm();

  const user = useUser();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  async function handleApproval() {
    if (!globalState?.defaultFormData?.idFormulario) {
      return;
    }

    dispatch(showModal(ModalName.LOADING, { text: 'Aprovando Formulário.' }));

    const { defaultFormData } = globalState;

    const approval = {
      idEmpresa: defaultFormData.idEmpresa,
      idContrato,
      origem: defaultFormData.tipo,
      idReferencia: defaultFormData.idFormulario,
      dtAprovacao: '',
      idAprovador: user.idUsuario!,
    } as Approval;

    await createApproval(approval);

    await getApprovalData(defaultFormData.idFormulario, defaultFormData.tipo);

    addToast('Aprovado com sucesso', { appearance: 'success' });

    dispatch(closeModal(ModalName.LOADING));
  }

  async function handleCancelApproval() {
    if (!globalState?.defaultFormData?.idFormulario || !approvalData) {
      return;
    }

    dispatch(
      showModal(ModalName.LOADING, {
        text: 'Cancelando Aprovação do Formulário.',
      }),
    );

    await cancelApproval(approvalData.id);

    setApprovalData({
      ...approvalData,
      usuarioAprovacao: '',
      dtAprovacao: '',
    });
    addToast('Aprovação cancelada com sucesso', { appearance: 'success' });

    dispatch(closeModal(ModalName.LOADING));
  }

  function renderApprovalButton() {
    if (
      !globalState?.templateConfig?.templatePortal?.allowApproval ||
      !globalState?.defaultFormData?.idFormulario
    ) {
      return <></>;
    }

    if (!approvalData?.usuarioAprovacao) {
      return <Button small text="Aprovar" onClick={handleApproval} />;
    }

    return (
      <Button
        outline
        small
        text="Cancelar Aprovação"
        onClick={handleCancelApproval}
      />
    );
  }

  return renderApprovalButton();
}
