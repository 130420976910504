export function getInitValuesFormik() {
  return {
    name: null,
    email: null,
    position: null,
    role: null,
    phone: null,
    cpf: null,
  };
}
