import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const AcumuladoFamilia = lazy(() =>
  import(
    './layouts/financeiro/acumuladoFamilia' /* webpackChunkName: 'relatorios.tenda.financeiro.acumuladoFamilia' */
  ),
);
const DashboardTendencia = lazy(() =>
  import(
    './layouts/financeiro/dashboardTendencia' /* webpackChunkName: 'relatorios.tenda.financeiro.dashboardtendencia' */
  ),
);
const DesvioAcumulado = lazy(() =>
  import(
    './layouts/financeiro/desvioAcumulado' /* webpackChunkName: 'relatorios.tenda.financeiro.desvioacumulado' */
  ),
);
const DesvioAcumuladoGrupo = lazy(() =>
  import(
    './layouts/financeiro/desvioAcumuladoGrupo' /* webpackChunkName: 'relatorios.tenda.financeiro.desvioAcumuladoGrupo' */
  ),
);

const DesvioAcumuladoPorObraTag = lazy(() =>
  import(
    './layouts/financeiro/desvioAcumuladoPorObraTag' /* webpackChunkName: 'relatorios.tenda.financeiro.DesvioAcumuladoPorObraTag' */
  ),
);
const DesvioAcumuladoPorObraGrupo = lazy(() =>
  import(
    './layouts/financeiro/desvioAcumuladoPorObraGrupo' /* webpackChunkName: 'relatorios.tenda.financeiro.DesvioAcumuladoPorObraGrupo' */
  ),
);
const DesvioAcumuladoRegionalTag = lazy(() =>
  import(
    './layouts/financeiro/desvioAcumuladoRegionalTag' /* webpackChunkName: 'relatorios.tenda.financeiro.desvioAcumuladoRegionalTag' */
  ),
);
const DesvioAcumuladoTag = lazy(() =>
  import(
    './layouts/financeiro/desvioAcumuladoTag' /* webpackChunkName: 'relatorios.tenda.financeiro.DesvioAcumuladoTag' */
  ),
);
const DesvioMesGrupo = lazy(() =>
  import(
    './layouts/financeiro/desvioMesGrupo' /* webpackChunkName: 'relatorios.tenda.financeiro.desviomesgrupo' */
  ),
);
const histDesvioMesGrupo = lazy(() =>
  import(
    './layouts/financeiro/histDesvioMesGrupo' /* webpackChunkName: 'relatorios.tenda.financeiro.histDesvioMesGrupo' */
  ),
);
const DesvioMesCompetencia = lazy(() =>
  import(
    './layouts/financeiro/desvioMesCompetencia' /* webpackChunkName: 'relatorios.tenda.financeiro.desviomescompetencia' */
  ),
);
const HistDesvioAcumulado = lazy(() =>
  import(
    './layouts/financeiro/histDesvioAcumulado' /* webpackChunkName: 'relatorios.tenda.financeiro.hist.desvio.acumulado' */
  ),
);
const HistDesvioMesPorObraGrupo = lazy(() =>
  import(
    './layouts/financeiro/histDesvioMesPorObraGrupo' /* webpackChunkName: 'relatorios.tenda.financeiro.hist.desvio.mes.por.obra.grupo' */
  ),
);
const HistDesvioMesRegionalTag = lazy(() =>
  import(
    './layouts/financeiro/histDesvioMesRegionalTag' /* webpackChunkName: 'relatorios.tenda.financeiro.hist.desvio.mes.regional.tag' */
  ),
);
const HistDesvioMesTag = lazy(() =>
  import(
    './layouts/financeiro/histDesvioMesTag' /* webpackChunkName: 'relatorios.tenda.financeiro.hist.desvio.mes.tag' */
  ),
);
const HistMesFamilia = lazy(() =>
  import(
    './layouts/financeiro/histMesFamilia' /* webpackChunkName: 'relatorios.tenda.financeiro.hist.mes.familia' */
  ),
);
const mesFamilia = lazy(() =>
  import(
    './layouts/financeiro/mesFamilia' /* webpackChunkName: 'relatorios.tenda.financeiro.mesfamilia' */
  ),
);
const mesBrasil = lazy(() =>
  import(
    './layouts/financeiro/mesBrasil' /* webpackChunkName: 'relatorios.tenda.financeiro.mesbrasil' */
  ),
);
const mesRegionalTag = lazy(() =>
  import(
    './layouts/financeiro/desvioMesRegionalTag' /* webpackChunkName: 'relatorios.tenda.financeiro.mesRegionalTag' */
  ),
);
const mesTag = lazy(() =>
  import(
    './layouts/financeiro/desvioMesTag' /* webpackChunkName: 'relatorios.tenda.financeiro.mesTag' */
  ),
);

const desvioMesPorObraGrupo = lazy(() =>
  import(
    './layouts/financeiro/desvioMesPorObraGrupo' /* webpackChunkName: 'relatorios.tenda.financeiro.desviomesporobragrupo' */
  ),
);

const desvioMesPorObraTag = lazy(() =>
  import(
    './layouts/financeiro/desvioMesPorObraTag' /* webpackChunkName: 'relatorios.tenda.financeiro.mesTag' */
  ),
);

const desvioMesAnteriorPorObraTag = lazy(() =>
  import(
    './layouts/financeiro/desvioMesAnteriorPorObraTag' /* webpackChunkName: 'relatorios.tenda.financeiro.mesTag' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/tenda/financeiro/acumuladofamilia"
    component={AcumuladoFamilia}
    path="/relatorios/tenda/financeiro/acumuladofamilia"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/dashboardtendencia"
    component={DashboardTendencia}
    path="/relatorios/tenda/financeiro/dashboardtendencia"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/desvioacumulado"
    component={DesvioAcumulado}
    path="/relatorios/tenda/financeiro/desvioacumulado"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/desvioacumuladogrupo"
    component={DesvioAcumuladoGrupo}
    path="/relatorios/tenda/financeiro/desvioacumuladogrupo"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/desvioacumuladoporobratag"
    component={DesvioAcumuladoPorObraTag}
    path="/relatorios/tenda/financeiro/desvioacumuladoporobratag"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/desvioacumuladoporobragrupo"
    component={DesvioAcumuladoPorObraGrupo}
    path="/relatorios/tenda/financeiro/desvioacumuladoporobragrupo"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/desvioacumuladoregionaltag"
    component={DesvioAcumuladoRegionalTag}
    path="/relatorios/tenda/financeiro/desvioacumuladoregionaltag"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/desvioacumuladotag"
    component={DesvioAcumuladoTag}
    path="/relatorios/tenda/financeiro/desvioacumuladotag"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/desviomesgrupo"
    component={DesvioMesGrupo}
    path="/relatorios/tenda/financeiro/desviomesgrupo"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/histdesviomesgrupo"
    component={histDesvioMesGrupo}
    path="/relatorios/tenda/financeiro/histdesviomesgrupo"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/desviomescompetencia"
    component={DesvioMesCompetencia}
    path="/relatorios/tenda/financeiro/desviomescompetencia"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/histdesvioacumulado"
    component={HistDesvioAcumulado}
    path="/relatorios/tenda/financeiro/histdesvioacumulado"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/histdesviomesporobragrupo"
    component={HistDesvioMesPorObraGrupo}
    path="/relatorios/tenda/financeiro/histdesviomesporobragrupo"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/histdesviomesregionaltag"
    component={HistDesvioMesRegionalTag}
    path="/relatorios/tenda/financeiro/histdesviomesregionaltag"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/histdesviomestag"
    component={HistDesvioMesTag}
    path="/relatorios/tenda/financeiro/histdesviomestag"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/histmesfamilia"
    component={HistMesFamilia}
    path="/relatorios/tenda/financeiro/histmesfamilia"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/mesfamilia"
    component={mesFamilia}
    path="/relatorios/tenda/financeiro/mesfamilia"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/mesbrasil"
    component={mesBrasil}
    path="/relatorios/tenda/financeiro/mesbrasil"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/desviomesregionaltag"
    component={mesRegionalTag}
    path="/relatorios/tenda/financeiro/desviomesregionaltag"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/desviomestag"
    component={mesTag}
    path="/relatorios/tenda/financeiro/desviomestag"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/desviomesporobragrupo"
    component={desvioMesPorObraGrupo}
    path="/relatorios/tenda/financeiro/desviomesporobragrupo"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/desviomesporobratag"
    component={desvioMesPorObraTag}
    path="/relatorios/tenda/financeiro/desviomesporobratag"
  />,
  <PrivateRoute
    key="/relatorios/tenda/financeiro/desviomesanteriorporobratag"
    component={desvioMesAnteriorPorObraTag}
    path="/relatorios/tenda/financeiro/desviomesanteriorporobratag"
  />,
];
