import { OptionsType } from 'store/types';

export interface RawURLParams {
  0: string;
}

export enum EnumSource {
  APP = 'app',
  EMAIL = 'email',
  PORTAL = 'portal',
  VIEWER = 'VIEWER',
}

export interface URLParams {
  uuidArquivo?: string;
  token?: string;
  source: EnumSource;
  idEmpresa?: number;
  idContrato?: number;
  uuid?: string;
  idReferencia?: number;
  idUsuarioCriacao?: number;
  uuidReferencia?: string;
  tipo?: string;
}

export interface FormDataSignature {
  idEmpresa?: number;
  idContrato?: number;
  idReferencia?: number;
  tipo?: string;
  nome: string;
  email: string;
  cargo: string;
  telefone?: string;
  cpf?: string;
  uuidArquivo?: string;
  dtAssinatura: string;
  dtCriacao?: string;
  status: string | number;
  flagStatus?: number;
  idUsuarioCriacao?: number;
  uuidReferencia?: string;
  uuid: string;
  imgSignature?: File;
}

export interface Subscriber {
  name: OptionsType;
  nome: string;
  nomeDocumento: string;
  email: string;
  cargo: string;
  uuid: string;
  id: number;
  status: string | number;
  dtAssinatura: string;
  alcada: string;
}

export interface DataHardwareType {
  latitude: number;
  longitude: number;
  ip: number;
}

export enum EnumStatus {
  REGISTER_SUBSCRIBERS_DONE = 1,
  SIGNED = 2,
  SIGNED_PDF = 3,
  CANCELED = 4,
}

export enum EnumStep {
  SUBSCRIBER_LIST = 0,
  SUBSCRIBER_DOC = 1,
  PDF_VIEWER = 2,
  SIGNATURE_PAGE = 3,
  OPEN_CAM = 4,
  REGISTER_SUBSCRIBERS_CANCEL = 5,
  SIGNED = 6,
  TOKEN_INVALID = 7,
  SIGNED_CANCELED = 8,
}
