import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: "relatorios.alphaville.fisico.gerencial" */
  ),
);

const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: "relatorios.alphaville.financeiro.obras" */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/alphaville/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/alphaville/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/alphaville/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/alphaville/financeiro/obras"
  />,
];
