import { Occurrence } from 'store/types';
import api from './api';
import apiAdonis from './apiAdonis';

export function update(data: Partial<Occurrence>): Promise<Occurrence> {
  return api.post(`/ocorrencias/update.json`, data);
}

export function create(data: Occurrence): Promise<Occurrence> {
  return api.post(`/ocorrencias/create`, data);
}

export function disableOcurrence(payload: {
  idContrato: number;
  idOcorrencia: number;
}) {
  return apiAdonis.post(`ocorrencia/disable-occurrences`, payload);
}
