import {
  NavbarState, NavbarActionTypes, SET_TITLE, CLEAR_TITLE,
} from './types';

const initialState: NavbarState = {
  title: null,
};

export default (state = initialState, action: NavbarActionTypes): NavbarState => {
  switch (action.type) {
    case SET_TITLE:
      return {
        title: action.payload,
      };
    case CLEAR_TITLE:
      return {
        title: null,
      };
    default:
      return state;
  }
};
