import React from 'react';
import WarningPageContainer from '../WarningPageContainer';

export default function WarningSignedSuccessPage() {
  return (
    <WarningPageContainer>
      <h1>Assinatura concluída</h1>
      <p>
        📝 O documento foi <b>assinado com sucesso!</b>
      </p>
      <br />
      <p>
        Agora é só aguardar que iremos enviar um novo e-mail com o documento
        assinado (PDF)
      </p>
      <br />
    </WarningPageContainer>
  );
}
