import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const FisicoDash = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'passarelli.fisico.gerencial' */
  ),
);
const LinhaDeBalanco = lazy(() =>
  import(
    './layouts/fisico/linhaDeBalanco' /* webpackChunkName: 'passarelli.fisico.linha-de-balanco' */
  ),
);
const PocosRedes = lazy(() =>
  import(
    './layouts/fisico/pocosRedes' /* webpackChunkName: 'passarelli.fisico.pocos-e-redes' */
  ),
);
const Portfolio = lazy(() =>
  import(
    './layouts/fisico/portfolio' /* webpackChunkName: 'passarelli.fisico.portfolio' */
  ),
);
const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'passarelli.financeiro.obras' */
  ),
);
const FinanceiroPortfolio = lazy(() =>
  import(
    './layouts/financeiro/portfolio' /* webpackChunkName: 'passarelli.financeiro.portfolio' */
  ),
);
const FinanceiroObraMesGrupoTag = lazy(() =>
  import(
    './layouts/financeiro/obramesgrupotag' /* webpackChunkName: 'passarelli.financeiro.obramesgrupotag' */
  ),
);
const DashboardAcidentes = lazy(() =>
  import(
    './layouts/seguranca/DashboardAcidentes' /* webpackChunkName: 'relatorios.passarelli.seguranca.dashboard-acidentes' */
  ),
);
const DashboardComportamental = lazy(() =>
  import(
    './layouts/seguranca/DashboardComportamental' /* webpackChunkName: 'relatorios.passarelli.seguranca.dashboard-comportamental' */
  ),
);
const DashboardEquipamentos = lazy(() =>
  import(
    './layouts/seguranca/DashboardEquipamentos' /* webpackChunkName: 'relatorios.passarelli.seguranca.dashboard-equipamentos' */
  ),
);
const IPS = lazy(() =>
  import(
    './layouts/seguranca/IPS' /* webpackChunkName: 'relatorios.passarelli.seguranca.ips' */
  ),
);
const FvmMateriais = lazy(() =>
  import(
    './layouts/fvm/materiais' /* webpackChunkName: 'relatorios.passarelli.seguranca.ips' */
  ),
);
const VistoriaVias = lazy(() =>
  import(
    './layouts/ccop/vistoriaVias' /* webpackChunkName: 'relatorios.passarelli.ccop.vistoria-vias' */
  ),
);
const VistoriaResidenciais = lazy(() =>
  import(
    './layouts/ccop/vistoriaResidenciais' /* webpackChunkName: 'relatorios.passarelli.ccop.vistoria-residenciais' */
  ),
);
const Ocorrencias = lazy(() =>
  import(
    './layouts/ccop/ocorrencias' /* webpackChunkName: 'relatorios.passarelli.ccop.ocorrencias' */
  ),
);
const AcompanhamentoProducao = lazy(() =>
  import(
    './layouts/ccop/acompanhamentoProducao' /* webpackChunkName: 'relatorios.passarelli.ccop.acompanhamento-de-producao' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/passarelli/fisico/gerencial"
    component={FisicoDash}
    path="/relatorios/passarelli/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/fisico/linha-de-balanco"
    component={LinhaDeBalanco}
    path="/relatorios/passarelli/fisico/linha-de-balanco"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/fisico/pocos-e-redes"
    component={PocosRedes}
    path="/relatorios/passarelli/fisico/pocos-e-redes"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/fisico/portfolio"
    component={Portfolio}
    path="/relatorios/passarelli/fisico/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/passarelli/financeiro/obras"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/financeiro/portfolio"
    component={FinanceiroPortfolio}
    path="/relatorios/passarelli/financeiro/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/financeiro/obramesgrupotag"
    component={FinanceiroObraMesGrupoTag}
    path="/relatorios/passarelli/financeiro/obramesgrupotag"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/seguranca/dashboard-acidentes"
    component={DashboardAcidentes}
    path="/relatorios/passarelli/seguranca/dashboard-acidentes"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/seguranca/dashboard-comportamental"
    component={DashboardComportamental}
    path="/relatorios/passarelli/seguranca/dashboard-comportamental"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/seguranca/dashboard-equipamentos"
    component={DashboardEquipamentos}
    path="/relatorios/passarelli/seguranca/dashboard-equipamentos"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/seguranca/ips"
    component={IPS}
    path="/relatorios/passarelli/seguranca/ips"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/fvm/materiais"
    component={FvmMateriais}
    path="/relatorios/passarelli/fvm/materiais"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/ccop/vistoria-vias"
    component={VistoriaVias}
    path="/relatorios/passarelli/ccop/vistoria-vias"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/ccop/vistoria-residenciais"
    component={VistoriaResidenciais}
    path="/relatorios/passarelli/ccop/vistoria-residenciais"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/ccop/ocorrencias"
    component={Ocorrencias}
    path="/relatorios/passarelli/ccop/ocorrencias"
  />,
  <PrivateRoute
    key="/relatorios/passarelli/ccop/acompanhamento-de-producao"
    component={AcompanhamentoProducao}
    path="/relatorios/passarelli/ccop/acompanhamento-de-producao"
  />,
];
