import showAlert from 'components/Alert';
import Button from 'components/Button';
import Input from 'components/Input';
import Modal from 'components/Modal';
import ImageViewerModal from 'components/Modal/ImageViewer';
import ReactSelect from 'components/ReactSelect';
import TextArea from 'components/TextArea';
import DynamicForm from 'components/Widgets/DynamicForm';
import {
  DynamicFormRef,
  UploadModalParams,
} from 'components/Widgets/DynamicForm/types';
import MultipleApproval from 'components/Widgets/MultipleApproval';
import WithPermission, { hasPermission } from 'components/WithPermission';
import useModal from 'hooks/use-modal';
import useUser from 'hooks/use-user';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { getSingleResponse, getSqlResponse } from 'services/dataset';
import { create, update } from 'services/occurrence';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { setReportQueue } from 'store/report/actions';
import {
  EnumActions,
  EnumProfile,
  EnumRoutes,
  Form,
  OptionsType,
  UserRelationship,
} from 'store/types';
import { Label } from 'styles/form';
import { PrintIcon } from 'styles/icons';
import { ButtonClose, ModalHeader, ModalTitle } from 'styles/modal';
import { generateUUID, isObjectEmpty } from 'utils/common';
import { getUsDateTime, toDateBR } from 'utils/date';
import InputDate from './components/InputDate';
import SelectCategory from './components/SelectCategory';
import SelectProvider from './components/SelectProvider';
import {
  ButtonPDF,
  ButtonSubmit,
  ContainerComment,
  ContainerModal,
  ContainerScroll,
  Content,
  ContentForm,
  ContentRight,
  ContentVisibleForm,
  NavBar,
  NavButton,
  ScrollArea,
  SubRow,
} from './styles';

import {
  create as createReportQueue,
  sendEmailResponsibles,
} from 'services/report_queue';
import { Footer, PhotoButtonWrapper, Row, UploadIcon } from './styles';
import { FetchAreaType, FormData, Occurrence, Responsible } from './types';
import { ParamsGallery } from 'components/Modal/PhotoGalleryComments/types';
import SelectListResponsible from './components/SelectListResponsible';
import {
  removeDynamicFormForCanceledOccurrence,
  saveAndGetAreas,
} from './utils';
import UpdatesOccurrence from './components/UpdatesOccurrence';
import { bulkInsertOrUpdate } from 'services/user_relationship';
import SelectArea from './components/SelectArea';
import Attachments from 'components/Widgets/Attachments';
import CreateUserModal from 'pages/UsersManagement/components/CreateUserModal';

export const statusOptions: OptionsType[] = [
  { value: 3, label: 'PENDENTE' },
  { value: 5, label: 'CANCELADO' },
  { value: 4, label: 'FINALIZADO' },
  { value: 6, label: 'INFORMATIVO' },
];

export enum EnumCurrentForm {
  MESSAGE = 0,
  APPROVAL = 2,
}
export interface Params {
  idOcorrencia: number;
  referencia: string;
  itemNome: string;
  idMedicao: string | null;
  idContrato: number;
  isNewSupplierTable?: boolean;
  onClose?(occurrence: Occurrence): void;
  shouldUpdateOccurrenceAfterSaveComment?: boolean;
  templateType: string;
  onReloadTableReport?(isNew: boolean): void;
  idEtapa?: number;
  idServico?: number;
  idSetorPai?: number;
  idSubProjeto?: number;
  idSubservico?: number;
  idFormularioChecklist?: number;
  uuidChecklist?: string;
  isDynamicFormHidden?: boolean;
  idTemplateFormulario?: number;
}

export default function FormOccurrenceModal() {
  const { isOpen, params, windowSec } = useModal<Params>(
    ModalName.FORM_OCCURRENCE,
  );

  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [hasApprovalConfig, setHasApprovalConfig] = useState(false);
  const [isDynamicVisible, setIsDynamicVisible] = useState(false);
  const [isDynamicCheckVisible, setIsDynamicCheckVisible] = useState(false);
  const [currentForm, setCurrentForm] = useState(EnumCurrentForm.MESSAGE);

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const user = useUser();
  const shouldReloadReport = useRef(false);
  const isNew = useRef(false);
  const previousForm = useRef<Occurrence>({} as Occurrence);
  const dynamicFormRef = useRef({} as DynamicFormRef);

  const { idContrato, itemNome } = params;

  const isPermitted = hasPermission(
    user.idAcoes,
    EnumActions.EDITAR,
    user.idPerfilModulo,
    [EnumProfile.AUDITOR],
  );

  const { templateType } = params;

  useEffect(() => {
    if (!isOpen) {
      setFormData({} as FormData);
      setHasApprovalConfig(false);
      setIsDynamicVisible(false);
      isNew.current = false;
      previousForm.current = {} as FormData;
      setCurrentForm(EnumCurrentForm.MESSAGE);

      return;
    }

    getData();
  }, [isOpen]);

  function handleClose() {
    if (
      previousForm.current !== formData &&
      !shouldReloadReport.current &&
      isPermitted
    ) {
      const confirm = () => {
        const { onClose } = params;

        if (onClose) {
          const newFormData = {
            ...formData,
            idContrato,
          };

          onClose(newFormData);
        }

        dispatch(closeModal(ModalName.FORM_OCCURRENCE));
      };

      showAlert({
        title: 'Atenção!',
        subtitle: 'Deseja sair sem salvar?',
        actions: [
          {
            text: 'Não',
          },
          {
            text: 'Sim',
            onClick: confirm,
          },
        ],
      });

      return;
    }

    if (shouldReloadReport.current) {
      const { onReloadTableReport } = params;

      if (onReloadTableReport) {
        onReloadTableReport(isNew.current);
      }

      const { onClose } = params;

      if (onClose) {
        const newFormData = {
          ...formData,
          idContrato,
        };

        onClose(newFormData);
      }

      shouldReloadReport.current = false;
    }

    dispatch(closeModal(ModalName.FORM_OCCURRENCE));
  }

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const { name } = e.target;
    const { value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  }

  async function getData() {
    const { idOcorrencia } = params;

    if (!idOcorrencia) {
      setFormData({
        uuid: generateUUID(),
        flagStatus: 3 as number,
        responsaveis: [],
        previousResponsibles: [],
        areas: [],
        previousAreas: [],
      } as FormData);

      return;
    }

    dispatch(showModal(ModalName.LOADING, { text: 'Carregando ocorrência' }));

    const data = await getSingleResponse<FormData>(825, {
      idContrato,
      idOcorrencia,
      tipo: templateType || 'OCORRENCIA',
    });

    const areasFormPromise = getSqlResponse<FetchAreaType[]>(2662, {
      uuidReferencia: data.uuid,
    });

    const responsiblesFormPromise = getSqlResponse<Responsible[]>(2407, {
      uuidReferencia: data.uuid,
    });

    const [areasForm, responsiblesForm] = await Promise.all([
      areasFormPromise,
      responsiblesFormPromise,
    ]);

    setFormData({
      ...data,
      idOcorrencia,
      responsaveis: responsiblesForm || [],
      previousResponsibles: responsiblesForm || [],
      areas: areasForm || [],
      previousAreas: areasForm || [],
    });

    previousForm.current = data;

    dispatch(closeModal(ModalName.LOADING));
  }

  function getCurrentStatusOption() {
    return statusOptions.filter(state => state.value === formData?.flagStatus);
  }

  function handleSelectState(option: OptionsType) {
    const { value } = option;

    setFormData({
      ...formData,
      flagStatus: value as number,
      status: value as number,
    });
  }

  async function handleSubmit() {
    if (isDynamicVisible && dynamicFormRef?.current) {
      try {
        await dynamicFormRef.current.onSave();
      } catch (error) {
        return;
      }
    }

    const inputDescription = formData?.ocorrencia?.trim();

    if (!inputDescription) {
      return addToast('Campo descrição esta vazio', { appearance: 'warning' });
    }

    if (!formData.idCategoriaOcorrencia) {
      return addToast('Categoria obrigatória', { appearance: 'warning' });
    }

    dispatch(showModal(ModalName.LOADING, { text: 'Salvando ocorrencia' }));
    const { idOcorrencia } = formData;

    if (idOcorrencia) {
      await editOccurrence();
    } else {
      isNew.current = true;
      await createOccurrence();
    }

    await removeDynamicFormForCanceledOccurrence(formData);

    const responsiblesForm = await saveResponsibles();

    const areasForm = await saveAndGetAreas(
      formData,
      idContrato,
      user.idUsuario!,
    );

    const newFormData = {
      ...formData,
      idOcorrencia,
      responsaveis: responsiblesForm || [],
      previousResponsibles: responsiblesForm || [],
      areas: areasForm || [],
      previousAreas: areasForm || [],
    };

    setFormData(newFormData);

    if (formData.flagEmailEnviado === 1) {
      sendEmailResponsibles({ idContrato, idUsuario: user.idUsuario! });
    }

    previousForm.current = newFormData;

    dispatch(closeModal(ModalName.LOADING));

    shouldReloadReport.current = true;

    handleClose();
  }

  function normalizeReference() {
    const { referencia } = params;

    if (!referencia) {
      return formData.referencia;
    }

    return formData.referencia?.toString() !== referencia?.toString()
      ? `${formData.referencia},${referencia}`
      : referencia;
  }

  async function editOccurrence() {
    const { idOcorrencia } = formData;

    const newFormData = { ...formData };
    newFormData.responsaveis = [];
    newFormData.previousResponsibles = [];

    try {
      const isStatusChanged =
        formData.flagStatus !== previousForm.current.flagStatus;

      await update({
        ...newFormData,
        idOcorrencia,
        idEmpresa: user.idEmpresa,
        idUltimoUsuario: isStatusChanged
          ? user.idUsuario!
          : previousForm.current.idUltimoUsuario,
        referencia: normalizeReference(),
        dtStatus: isStatusChanged
          ? getUsDateTime()
          : previousForm.current.dtStatus,
      });

      addToast('Ocorrência salva com sucesso', { appearance: 'success' });
    } catch (error) {
      addToast(`Erro ao salvar ocorrência!`, {
        appearance: 'error',
      });

      throw error;
    }
  }

  async function saveResponsibles() {
    const isArray = Array.isArray(formData.responsaveis);

    const responsiblesToSave = isArray
      ? formData.responsaveis
      : [formData.responsaveis];
    const { idContrato } = params;

    formData.previousResponsibles.forEach(previousResponse => {
      if (
        !responsiblesToSave.some(
          currentResponsible =>
            currentResponsible.uuid === previousResponse.uuid,
        )
      ) {
        responsiblesToSave.push({ ...previousResponse, flagStatus: 0 });
      }
    });

    if (!responsiblesToSave.length) return;

    const normalizedResponsibles: UserRelationship[] =
      responsiblesToSave.map(resp => ({
        uuid: resp.uuid || generateUUID(),
        uuidUsuario: resp.value as string,
        uuidReferencia: formData.uuid!,
        idContrato: idContrato || formData.idContrato!,
        flagStatus: resp.flagStatus === 0 ? 0 : 1,
      })) || [];

    await bulkInsertOrUpdate(normalizedResponsibles);

    return getSqlResponse<Responsible[]>(2407, {
      uuidReferencia: formData.uuid,
    });
  }

  async function createOccurrence() {
    const newFormData = { ...formData };
    newFormData.responsaveis = [];
    newFormData.previousResponsibles = [];

    const {
      referencia,
      idContrato,
      idEtapa,
      idServico,
      idSetorPai,
      idSubProjeto,
      idSubservico,
      idMedicao,
    } = params;

    try {
      const responseOccurrence = await create({
        ...newFormData,
        idMedicao,
        idEtapa,
        idServico,
        idSetorPai,
        idSubProjeto,
        idSubservico,
        idContrato,
        referencia,
        idEmpresa: user.idEmpresa,
        origem: templateType || 'OCORRENCIA',
        idOcorrencia: 0,
        dtStatus: getUsDateTime(),
        idUsuarioCriacao: user.idUsuario!,
        idUltimoUsuario: user.idUsuario!,
      });

      setFormData({
        ...formData,
        idContrato,
        idOcorrencia: responseOccurrence.idOcorrencia,
        dtAbertura: responseOccurrence.dtAbertura,
      });

      addToast('Ocorrência salva com sucesso', { appearance: 'success' });
    } catch (error) {
      addToast(`Erro ao salvar ocorrência!`, {
        appearance: 'error',
      });

      throw error;
    }
  }

  async function handleShowUploadModal() {
    const moduleId = await getSingleResponse<{ idModulo: number } | undefined>(
      2056,
      { idContrato },
    );

    if (moduleId?.idModulo === 1) {
      const { idOcorrencia } = formData;

      return dispatch(
        showModal<UploadModalParams>(ModalName.MODAL_UPLOAD, {
          idContrato: formData?.idContrato || idContrato,
          idDatasetImages: 2276,
          uuidReferencia: formData.uuid,
          allowDisableImage: false,
          id: idOcorrencia,
          tipo: templateType || 'OCORRENCIA',
          idUsuario: user.idUsuario!,
          idPerfilModulo: user.idPerfilModulo,
          typeOfPage: EnumRoutes.UPLOAD_IMAGE,
          onReloadPageForm: getData,
          title: 'Imagens da ocorrência',
        }),
      );
    }

    dispatch(
      showModal<ParamsGallery>(ModalName.PHOTO_GALLERY_COMMENTS, {
        idContrato: formData?.idContrato || idContrato,
        tipo: 'OCORRENCIA',
        idReferencia: formData.idOcorrencia,
        uuidOcorrencia: formData.uuid!,
        title: `${formData.ocorrencia} > ${formData.categoriaOcorrencia}`,
      }),
    );
  }

  function getLabelButtonPhoto() {
    if (!formData?.totalImages) {
      return `Ver fotos (0)`;
    }

    return `Ver fotos (${formData.totalImages})`;
  }

  async function handleGeneragePDF() {
    const reportUuid = generateUUID();

    const nome = 'Relatório de ocorrência';

    dispatch(
      setReportQueue({
        nome,
        uuid: reportUuid,
        status: 0,
      }),
    );

    const { idContrato, idOcorrencia } = formData;
    await createReportQueue({
      idDataset: 0,
      nome,
      uuid: reportUuid,
      idContrato: idContrato!,
      idEmpresa: user.idEmpresa!,
      parametros: {
        idContrato,
        idOcorrencia,
        tipo: templateType || 'OCORRENCIA',
        uuidChecklist: params.uuidChecklist,
      },
      tipoRelatorio: 'ocorrencia',
    });
  }

  function renderUpdates() {
    const { uuid, idContrato, idOcorrencia, flagStatus } = previousForm.current;

    if (!uuid && !formData.uuid!) {
      return null;
    }

    return (
      <ContainerComment>
        <UpdatesOccurrence
          uuidReference={uuid || formData.uuid!}
          idContrato={idContrato! || formData.idContrato!}
          idOcorrencia={idOcorrencia || formData.idOcorrencia}
          statusOccurrence={flagStatus || formData.flagStatus}
          getReload={getData}
        />
      </ContainerComment>
    );
  }

  function handleAfterApproval() {
    shouldReloadReport.current = true;

    const { onReloadTableReport } = params;

    if (onReloadTableReport) {
      onReloadTableReport(false);
    }
  }

  function handleAfterSaveDynamicForm(savedForm: Form) {
    setFormData({ ...formData, idFormulario: savedForm.idFormulario });
  }

  function renderFormOcorrence() {
    if (isObjectEmpty(params)) {
      return <></>;
    }

    return (
      <ContentForm>
        <h2>{itemNome}</h2>
        <Row>
          <TextArea
            disabled={!isPermitted}
            label="Descrição"
            name="ocorrencia"
            defaultValue={formData.ocorrencia}
            onChange={handleChange}
          />
        </Row>
        <Row>
          <div>
            <SelectCategory
              formData={formData}
              setFormData={setFormData}
              idContrato={idContrato!}
            />
          </div>
        </Row>
        <Row>
          <InputDate
            label="Prazo"
            fieldDate="prazo"
            formData={formData}
            setFormData={setFormData}
          />

          <div>
            <Label>Status</Label>
            <ReactSelect
              onChange={handleSelectState}
              value={getCurrentStatusOption()}
              options={statusOptions}
            />
          </div>
        </Row>

        <Row>
          <InputDate
            label="Data execução inicio"
            fieldDate="dtIniReal"
            formData={formData}
            setFormData={setFormData}
          />

          <div>
            <InputDate
              label="Data execução fim"
              fieldDate="dtFimReal"
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        </Row>

        <Row>
          <div>
            <SelectProvider
              formData={formData}
              setFormData={setFormData}
              isNewSupplierTable={params?.isNewSupplierTable}
            />
          </div>
        </Row>

        <Row>
          <SubRow>
            <SelectListResponsible
              formData={formData}
              setFormData={setFormData}
              idContrato={idContrato}
            />
          </SubRow>
        </Row>

        <Row>
          <div>
            <SelectArea
              idContrato={idContrato}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        </Row>

        <Row>
          <Input
            disabled
            value={toDateBR(formData.dtAbertura)}
            label="Data de abertura"
          />

          <PhotoButtonWrapper>
            <Button
              icon={<UploadIcon />}
              text={getLabelButtonPhoto()}
              onClick={handleShowUploadModal}
            />
          </PhotoButtonWrapper>
        </Row>

        <Row>
          <Attachments
            title="Anexos"
            inputId={formData.uuid}
            keyReferencia={formData.uuid}
            type="DETALHE_OCORRENCIA"
            status={'ABERTO'}
          />
        </Row>
      </ContentForm>
    );
  }

  const renderFormOcorrenceMemo = useMemo(() => renderFormOcorrence(), [
    formData,
    params,
    idContrato,
  ]);

  if (isObjectEmpty(params)) {
    return null;
  }

  const { idOcorrencia } = formData;
  const {
    idFormularioChecklist,
    uuidChecklist,
    isDynamicFormHidden,
    idTemplateFormulario,
  } = params;

  function renderDynamicForm() {
    if (isDynamicFormHidden || !formData.uuid) {
      return <>{renderFormOcorrenceMemo}</>;
    }

    return (
      <>
        <DynamicForm
          ref={dynamicFormRef}
          widgetStyle
          isOpen={true}
          formType={templateType || 'OCORRENCIA'}
          referenciaTemplate={formData.idTipoContrato}
          idFormulario={formData.idFormulario}
          idTemplate={idTemplateFormulario}
          {...params}
          referencia={formData.uuid}
          idContrato={idContrato}
          setIsDynamicVisible={setIsDynamicVisible}
          onAfterSave={handleAfterSaveDynamicForm}
          isHiddenButtonSave
        >
          {renderFormOcorrenceMemo}
        </DynamicForm>
      </>
    );
  }

  function renderNavBar() {
    const { uuid, idOcorrencia } = previousForm.current;

    const isShowAproval = idOcorrencia && hasApprovalConfig;

    const isShowMessage = uuid || formData.uuid!;

    return (
      <NavBar>
        {isShowMessage && (
          <NavButton
            isCkeck={EnumCurrentForm.MESSAGE === currentForm}
            onClick={() => setCurrentForm(EnumCurrentForm.MESSAGE)}
          >
            Comentários
          </NavButton>
        )}

        {isShowAproval && (
          <NavButton
            isCkeck={EnumCurrentForm.APPROVAL === currentForm}
            onClick={() => setCurrentForm(EnumCurrentForm.APPROVAL)}
          >
            Aprovação
          </NavButton>
        )}
      </NavBar>
    );
  }

  function renderLayoutRight() {
    return (
      <>
        <ContentVisibleForm
          isVisible={EnumCurrentForm.APPROVAL === currentForm}
        >
          <MultipleApproval
            idDataset={1149}
            idContrato={idContrato}
            idReferencia={idOcorrencia}
            origem={templateType || 'OCORRENCIA'}
            onAfterApproval={handleAfterApproval}
            setHasApprovalConfig={setHasApprovalConfig}
          />
        </ContentVisibleForm>

        <ContentVisibleForm isVisible={EnumCurrentForm.MESSAGE === currentForm}>
          {renderUpdates()}
        </ContentVisibleForm>
      </>
    );
  }

  return (
    <Modal isOpen={isOpen} windowSec={windowSec}>
      <ModalHeader>
        <ButtonClose onClick={handleClose} />
        <ModalTitle>Detalhe da Ocorrência</ModalTitle>
      </ModalHeader>
      <ContainerModal width={'85vw'}>
        <Content>
          <ScrollArea>
            {idFormularioChecklist && uuidChecklist && (
              <ContentVisibleForm isVisible={isDynamicCheckVisible}>
                <DynamicForm
                  widgetStyle
                  isOpen={true}
                  formType="CHECKLIST"
                  idFormulario={idFormularioChecklist}
                  referencia={uuidChecklist}
                  idContrato={idContrato}
                  setIsDynamicVisible={setIsDynamicCheckVisible}
                />
              </ContentVisibleForm>
            )}

            {renderDynamicForm()}
          </ScrollArea>

          <Footer>
            <ButtonPDF
              text="Gerar PDF"
              icon={<PrintIcon />}
              onClick={handleGeneragePDF}
            />
          </Footer>
        </Content>

        <ContentRight>
          {renderNavBar()}
          <ContainerScroll>{renderLayoutRight()}</ContainerScroll>

          <Footer>
            <WithPermission
              action={EnumActions.SALVAR}
              rules={[EnumProfile.AUDITOR]}
            >
              <div>
                <ButtonSubmit primary text="Salvar" onClick={handleSubmit} />
              </div>
            </WithPermission>
          </Footer>
        </ContentRight>
      </ContainerModal>
      <ImageViewerModal />
      <CreateUserModal />
    </Modal>
  );
}
