import { useEffect } from 'react';

interface Props {
  ref: any;
  state: any;
  onOutsideClicked: () => void;
}

export default function useOutsideClick(props: Props) {
  const { ref, state, onOutsideClicked } = props;

  useEffect(() => {
    function handleClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClicked();
      }
    }

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, state]);
}
