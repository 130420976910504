import React, { useEffect, useState } from 'react';
import { Field } from '../../types';
import { useDynamicForm } from '../../context';
import Attachments from 'components/Widgets/Attachments';
import showAlert from 'components/Alert';
import { DatasetParams, EnumActions, EnumProfile } from 'store/types';
import { ButtonUpload, IconUpload } from './styles';
import useUser from 'hooks/use-user';
import { checkUserEditPermission } from '../../utils';
import WithPermission from 'components/WithPermission';

interface Props {
  field: Field;
  uuid: string;
  onSave?: (any) => void;
  onChange?: (any) => void;
  formData?: any;
  isDisabled?: boolean;
}

interface AppendData {
  inputFormName: string;
  tableRowUuid: string;
}

export default function InputUpload(props: Props) {
  const { field, uuid, formData, onSave, onChange, isDisabled } = props;

  const [appendData, setAppendData] = useState({} as AppendData);
  const [totalFiles, setTotalFiles] = useState(0);

  const {
    globalState,
    onSaveForm,
    approvalData,
    fieldsAllowProps,
  } = useDynamicForm();
  const { defaultFormData } = globalState!;

  const user = useUser();

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    setAppendData({
      inputFormName: field.name,
      tableRowUuid: uuid,
    });
  }, [uuid]);

  useEffect(() => {
    if (!onSave || !onChange) {
      return;
    }

    onChange(field.name, totalFiles);
    onSave({ ...formData, [field.name]: totalFiles });
  }, [totalFiles]);

  function hanlderCheckExistForm() {
    if (defaultFormData?.idFormulario) {
      return;
    }

    showAlert({
      title: 'Atenção!',
      subtitle:
        'Para realizar o upload de arquivos é necessário salvar antes. Deseja salvar os dados editados?',
      actions: [
        {
          text: 'Não',
        },
        {
          text: 'Sim',
          onClick: onSaveForm,
        },
      ],
    });
  }

  if (!defaultFormData?.idFormulario) {
    return (
      <WithPermission
        action={EnumActions.SALVAR}
        rules={[
          EnumProfile.ADMINISTRADOR,
          EnumProfile.PRODUCAO,
          EnumProfile.EXDCUTIVO,
          EnumProfile.DEMO,
          EnumProfile.OPERACIONAL,
          EnumProfile.APROVADOR,
          EnumProfile.AUDITOR,
          EnumProfile.GESTOR,
        ]}
      >
        <ButtonUpload onClick={hanlderCheckExistForm}>
          <>
            <IconUpload />
            Selecionar arquivo
          </>
        </ButtonUpload>
      </WithPermission>
    );
  }

  return (
    <Attachments
      inputId={uuid || 0}
      title={field.label}
      idReferencia={defaultFormData.idFormulario}
      type={defaultFormData.tipo}
      appendedData={appendData}
      datasetParams={(appendData as unknown) as DatasetParams}
      datasetId={field.datasetId}
      isHideCategory
      isDisabled={isDisableChecked}
      setTotalFiles={setTotalFiles}
    />
  );
}
