import { DefaultTheme } from 'styled-components';

export const colors = {
  primary: '#009688',
  danger: '#ff3860',
  info: '#007bff',

  green: '#52C3BC',

  grey10: '#f3f4f8',
  grey20: '#e1e5eb',
  grey30: '#c2c6cc',
  grey40: '#9ea2a8',
  grey50: '#686c73',
  grey60: '#30363d',
  darkGrey: '#404040',
  rowHover: '#f5f5f5',
  warning: '#f58a42',
};

export const theme: DefaultTheme = {
  colors,
  radius: {
    small: 2,
    medium: 4,
    large: 8,
    huge: 10,
  },
  spacing: {
    large: 30,
    medium: 20,
    small: 15,
    tiny: 10,
  },
};
