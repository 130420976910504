import apiAdonis from './apiAdonis';

type StorePayload = {
  idUsuario: number;
  idModelo: number;
};

export function store(user: StorePayload): Promise<void> {
  return apiAdonis.post(
    '/usuario-perfil-tipo-contrato/store-by-user-template',
    user,
  );
}
