import { Card as CardComponent } from 'styles/card';
import styled from 'styled-components';
import { RemoveIcon } from 'styles/icons';

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

export const StatusWrapper = styled.div``;

export const Card = styled(CardComponent)`
  padding: 1rem;
`;

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  margin-bottom: 0.5rem;
`;

export const CardTag = styled.div`
  background-color: #e0f2f1;
  padding: 0.2rem 0.4rem;
  font-size: 12px;
  display: inline-block;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

export const CancelApprovalIcon = styled(RemoveIcon)`
  margin-right: 0.5rem;
`;
