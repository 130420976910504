import styled from 'styled-components';
import { IoIosClose } from 'react-icons/io';

export const ButtonClose = styled(IoIosClose)`
  font-size: 3rem;
  position: absolute;
  right: 1.2rem;
  top: 1rem;
  color: #333;
  cursor: pointer;
  transition: 200ms;
  border-radius: 50%;
  color: #ccc;

  &:hover {
    color: #999;
  }
`;

export const ModalTitle = styled.h1`
  color: #333;
  padding: 1rem;
  margin: 0 4rem;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ModalContainer = styled.div<{ width?: string; height?: string }>`
  border-radius: ${({ theme }) => theme.radius.medium}px;
  padding: 0 2rem 2rem;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: 3rem;
`;

export const FlexModalContainer = styled(ModalContainer)`
  display: flex;
  flex-direction: column;
`;

export const ModalContainerLikePage = styled.div`
  padding: 2rem;
  width: 100vw;
  height: calc(100vh - 5.7rem);
  background-color: #fff;
  padding-top: 6rem;
  overflow: hidden;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 2rem;
  gap: 1rem;
`;
