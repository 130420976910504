import React from 'react';
import Classification from 'pages/Reports/clients/passarelli/layouts/seguranca/IPS/components/Classification';
import { StyledDiv } from './styles';

interface StatusIPSProps {
  value: number;
}

export default function DefaultType(props: StatusIPSProps) {
  const { value } = props;

  return (
    <StyledDiv className="celltype-default">
      <Classification value={value} />
    </StyledDiv>
  );
}
