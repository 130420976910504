import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const AcompanhamentoSemanal = lazy(() =>
  import(
    './layouts/fisico/acompanhamentoSemanal' /* webpackChunkName: 'relatorios.rocontec.fisico.acompanhamento-semanal' */
  ),
);
const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'relatorios.rocontec.financeiro.obras' */
  ),
);
const FinanceiroPortfolio = lazy(() =>
  import(
    './layouts/financeiro/portfolio' /* webpackChunkName: 'relatorios.rocontec.financeiro.portfolio' */
  ),
);
const FisicoPortfolioSemanal = lazy(() =>
  import(
    './layouts/fisico/portfolioSemanal' /* webpackChunkName: 'relatorios.rocontec.fisico.portfolio-semanal' */
  ),
);
const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.rocontec.fisico.gerencial' */
  ),
);
const LinhaDeBalanco = lazy(() =>
  import(
    './layouts/fisico/linhaDeBalanco' /* webpackChunkName: 'relatorios.rocontec.fisico.linha-de-balanco' */
  ),
);
const FisicoGerencialCliente = lazy(() =>
  import(
    './clients/layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.rocontec.clientes.fisico.gerencial' */
  ),
);
const InspecaoSeguranca = lazy(() =>
  import(
    './layouts/seguranca/inspecaoSeguranca' /* webpackChunkName: 'relatorios.rocontec.seguranca.inspecao-seguranca' */
  ),
);
const ChecklistSeguranca = lazy(() =>
  import(
    './layouts/seguranca/checklistDeSeguranca' /* webpackChunkName: 'relatorios.rocontec.seguranca.checklist-de-seguranca' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/rocontec/fisico/acompanhamento-semanal"
    component={AcompanhamentoSemanal}
    path="/relatorios/rocontec/fisico/acompanhamento-semanal"
  />,
  <PrivateRoute
    key="/relatorios/rocontec/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/rocontec/financeiro/obras"
  />,
  <PrivateRoute
    key="/relatorios/rocontec/financeiro/portfolio"
    component={FinanceiroPortfolio}
    path="/relatorios/rocontec/financeiro/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/rocontec/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/rocontec/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/rocontec/fisico/portfolio-semanal"
    component={FisicoPortfolioSemanal}
    path="/relatorios/rocontec/fisico/portfolio-semanal"
  />,
  <PrivateRoute
    key="/relatorios/rocontec/fisico/linha-de-balanco"
    component={LinhaDeBalanco}
    path="/relatorios/rocontec/fisico/linha-de-balanco"
  />,
  <PrivateRoute
    key="/relatorios/rocontec/clientes/fisico/gerencial"
    component={FisicoGerencialCliente}
    path="/relatorios/rocontec/clientes/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/rocontec/seguranca/inspecao-seguranca"
    component={InspecaoSeguranca}
    path="/relatorios/rocontec/seguranca/inspecao-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/rocontec/seguranca/checklist-de-seguranca"
    component={ChecklistSeguranca}
    path="/relatorios/rocontec/seguranca/checklist-de-seguranca"
  />,
];
