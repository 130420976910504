import apiAdonis from './apiAdonis';

type StorePayload = {
  idUsuario: number;
  idModelo: number;
  idEmpresa: number;
};

export function store(user: StorePayload): Promise<void> {
  return apiAdonis.post('/usuario-relatorio/store-by-user-template', user);
}
