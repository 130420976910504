import {
  ConfigState,
  ConfigActionTypes,
  SET_IS_IMPERSONATE,
  CLEAR_IS_IMPERSONATE,
} from './types';

const initialState: ConfigState = {
  isImpersonate: false,
};

export default (
  state = initialState,
  action: ConfigActionTypes,
): ConfigState => {
  switch (action.type) {
    case SET_IS_IMPERSONATE:
      return {
        ...state,
        isImpersonate: action.payload,
      };
    case CLEAR_IS_IMPERSONATE:
      return {
        ...state,
        isImpersonate: action.payload,
      };
    default:
      return state;
  }
};
