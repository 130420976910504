import styled, { css } from 'styled-components';

export const ContainerCentered = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 0 8rem;
  display: flex;
  justify-content: center;
`;

export const Scrollable = styled.div`
  overflow: scroll;
  overflow-x: hidden;
`;

export const ButtonWithNoStyle = css`
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;
