/* eslint-disable no-console */
import { useMsal } from '@azure/msal-react';
import PasswordInput from 'components/PasswordInput';
import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LogPNG from '../../assets/sigin-logo.png';
import Span from '../../components/Span';
import Spinner from '../../components/Spinner';
import { checkUserAuthenticatedFromAd, signIn } from '../../services/auth';
import { create as createLogAcessos } from '../../services/logs_menu';
import { getUserWithProfile } from '../../services/user';
import { EnumRoutes, LogMenu } from '../../store/types';
import { loginRequest } from '../../utils/azureAuthConfig';
import { getBrowserName, getDevice } from '../../utils/common';
import {
  ButtonAzure,
  Container,
  ForgotPassword,
  Form,
  SocialMediaOptionsWrapper,
  WrapErrors,
} from './styles';

export default function SignIn(): ReactElement {
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [errors, setErrors] = useState<string[] | null>();
  const [loading, setLoading] = useState(false);

  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    checkUserAuthenticatedFromAd(inProgress, accounts, instance, null);
  }, [inProgress, accounts, instance]);

  async function sendLog() {
    const getProfile = await getUserWithProfile();

    const logMenu: LogMenu = {
      idReferencia: '0',
      idUsuario: getProfile.idUsuario!,
      idEmpresa: getProfile.idEmpresa!,
      rota: 'login',
      browser: getBrowserName(),
      dispositivo: getDevice(),
    };

    return createLogAcessos(logMenu);
  }

  async function handleSignIn(e: FormEvent) {
    e.preventDefault();
    setLoading(true);
    setErrors(null);

    const resErrors: string[] = await signIn({ email, senha });

    if (resErrors.length) {
      setErrors(resErrors);
    } else {
      let pathname = location.state?.from?.pathname || '';
      if (pathname) {
        pathname += location.state?.from?.search || '';
      }

      history.push(pathname || EnumRoutes.HOME);
      sendLog();
    }

    setLoading(false);
  }

  function handleSignInWithAzure() {
    setLoading(true);
    setErrors(null);

    instance.loginRedirect(loginRequest);

    setLoading(false);
  }

  function handleErrors(): ReactElement[] | undefined {
    if (errors?.length === 0) return undefined;

    return errors?.map(err => <Span key={err} text={err} danger />);
  }

  function handleResetPassword() {
    history.push(EnumRoutes.RESET_PASSWORD, { email, fromPortal: true });
  }

  function verifyEmail(e) {
    e.preventDefault();

    const email = e.target.value;

    setEmail(email);
  }

  return (
    <Container>
      <Form onSubmit={handleSignIn}>
        <img src={LogPNG} alt="no img" />

        <input
          type="email"
          placeholder="Endereço de e-mail"
          onChange={verifyEmail}
        />

        <PasswordInput
          type="password"
          placeholder="Senha"
          onChange={e => setSenha(e.target.value)}
        />

        <WrapErrors>{handleErrors()}</WrapErrors>

        <button className="primary-button" type="submit">
          {!loading ? (
            <span>Entrar</span>
          ) : (
            <Spinner color="#fff" size="medium" />
          )}
        </button>

        {!email.match(/@tenda/gi) && (
          <ForgotPassword onClick={handleResetPassword}>
            Esqueceu sua senha?
          </ForgotPassword>
        )}

        <SocialMediaOptionsWrapper>
          <ButtonAzure
            small
            type="button"
            text="Conectar com Azure AD"
            onClick={handleSignInWithAzure}
          />
        </SocialMediaOptionsWrapper>
      </Form>
    </Container>
  );
}
