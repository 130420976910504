import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSqlResponseAdonis } from 'services/dataset';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { Container } from './styles';
import { Field, FormNewItemModalParams, State } from '../../types';
import SimpleTable from 'pages/Reports/components/tables/SimpleTable';
import { useDynamicForm } from '../../context';
import SelectLabel from './components/SelectLabel';
import {
  PropsHandleClick,
  SimpleTableConfig,
} from 'pages/Reports/components/tables/SimpleTable/types';
import Redirect from './components/Redirect';
import DateFormatter from './components/DateFormatter';
import { jsonParse } from 'utils/common';

interface Props {
  field: Field;
  setFormAnswer(name: string, value: State[]): void;
}

interface DataAuxTypes {
  uuid: number;
  key: string;
  description: string;
  columns: object | null | string;
  seq: number;
}

export default function SimpleTableComponent(props: Props) {
  const { field, setFormAnswer } = props;
  const [data, setData] = useState([] as DataAuxTypes[]);
  const [config, setConfig] = useState([] as SimpleTableConfig[]);

  const dispatch = useDispatch();

  const { globalState } = useDynamicForm();

  useEffect(() => {
    if (!globalState) {
      return;
    }

    initData();
  }, [globalState?.defaultFormData]);

  useEffect(() => {
    normalizeConfigAndSetState();

    saveGlobalState();
  }, [data]);

  async function initData() {
    dispatch(showModal(ModalName.LOADING, 'Carregando dados ...'));

    const dataAux = await getSqlResponseAdonis<DataAuxTypes[]>(
      field.datasetId!,
      {
        ...globalState!.datasetParams,
      },
      true,
    );

    const newData = normalizeData(dataAux);

    setData(newData);

    dispatch(closeModal(ModalName.LOADING));
  }

  function normalizeConfigAndSetState(): void {
    const newConfig = field.fields.map(field => {
      return {
        ...field,
        ...renderCellTypeParams(field),
        onClick: openModalEdit,
      };
    });

    setConfig(newConfig);
  }

  function renderCellTypeParams(field: Field) {
    if (!field.type) {
      return {
        cellType: 'default',
      };
    }

    if (field.type === 'select') {
      return {
        cellType: 'customContent',
        cellTypeParams: {
          content: SelectLabel,
        },
      };
    }

    if (
      field.type === 'date' ||
      field.type === 'time' ||
      field.type === 'datetime'
    ) {
      return {
        cellType: 'customContent',
        cellTypeParams: {
          content: DateFormatter,
        },
      };
    }

    if (field.type === 'link-clip') {
      return {
        cellType: 'customContent',
        cellTypeParams: {
          content: props => <Redirect {...props} field={field} />,
        },
      };
    }

    return {
      cellType: 'default',
    };
  }

  function saveGlobalState() {
    const columns = data
      .filter(dado => dado.columns)
      .map(dado => {
        return {
          ...jsonParse(dado.columns as string)!,
          uuid: dado.uuid,
        };
      });

    setFormAnswer(field.name, columns);
  }

  function openModalEdit(prop: PropsHandleClick): void {
    const { row } = prop;

    if (!row.isLastChild) {
      return;
    }

    dispatch(
      showModal<FormNewItemModalParams>(ModalName.DYNAMIC_FORM_NEW_ITEM, {
        fields: field.fields,
        tableFieldName: field.name,
        config: field,
        previousData: row,
        onSave: handleSave,
        onRemove: () => null,
        title: field.label,
      }),
    );
  }

  function handleSave(newData: State): void {
    const previousDataIndex = data.findIndex(
      answer => answer.key === newData.key,
    );

    if (previousDataIndex < 0) {
      return;
    }

    const newColumnsToUpdate = Object.fromEntries(
      field.fields.map(field => [field.name, newData[field.name] || null]),
    );

    const newDataToUpdate = [...data];

    newDataToUpdate[previousDataIndex].columns = JSON.stringify(
      newColumnsToUpdate,
    );

    setData(newDataToUpdate);
  }

  function normalizeData(dataAux: DataAuxTypes[]) {
    const newData = dataAux.map(dado => {
      if (!dado.columns) {
        return dado;
      }

      const columnsParsed = jsonParse(dado.columns);

      const fieldKeySelect = field.fields.filter(
        field => field.type === 'select',
      );

      const typeSelectParsed = {};

      fieldKeySelect.forEach(fieldKey => {
        typeSelectParsed[fieldKey.name] = jsonParse(
          columnsParsed[fieldKey.name],
        );
      });

      const newColumns = {
        ...columnsParsed,
        ...typeSelectParsed,
      };

      const newDado = {
        ...dado,
        columns: JSON.stringify(newColumns),
      };

      return newDado;
    });

    return newData;
  }

  return (
    <Container>
      <SimpleTable
        id="simpleTableComponent"
        data={data}
        config={config}
        {...field.simpleTableProps}
      />
    </Container>
  );
}
