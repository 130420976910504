import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useEffect, useState } from 'react';

export default function useQuery<T>(): T {
  const [state, setState] = useState<T>({} as T);

  const location = useLocation();

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    setState((queryParams as unknown) as T);
  }, [location]);

  return state;
}
