import { FaPaperclip } from 'react-icons/fa';
import { MdInsertPhoto } from 'react-icons/md';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 5rem;
`;

export const ContainerImages = styled.div`
  margin-top: 2rem;
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const DragText = styled.p`
  color: #bdbdbd;
  font-size: 1.8rem;
`;

export const FileDropWrapper = styled.div`
  .file-drop > .file-drop-target {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 4rem;
    border-width: 2px;
    border-radius: 4px;
    border-color: #bdbdbd;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer;
  }

  .file-drop > .file-drop-target.file-drop-dragging-over-target {
    border: 2px dashed #52c3bc;
    background-color: rgba(82, 195, 188, 0.05);
  }
`;

export const Title = styled.h1`
  margin-top: 1.5rem;
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  left: 5rem;
  top: 1.8rem;
  display: flex;
`;

export const Option = styled.div<{ isSelected: boolean }>`
  width: 5rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 0.6rem;
  width: 8rem;

  ${({ isSelected }) =>
    isSelected && 'background-color: rgba(82, 195, 188, .4);'}

  span {
    font-size: 1.4rem;
  }
`;

export const PhotoIcon = styled(MdInsertPhoto)`
  font-size: 2rem;
`;

export const AttachmentIcon = styled(FaPaperclip)`
  font-size: 2rem;
`;

export const SaveButtonWrapper = styled.div`
  position: fixed;
  top: 5.8rem;
  z-index: 10;
  right: 3.5rem;
`;
