import styled from 'styled-components';

export const Text = styled.span`
  font-size: 1.3rem;
  margin: 0 1rem;
  color: #fff;
`;

export const Row = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  justify-content: flex-start;
  background: #404040;

  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }

  &:hover {
    background: #aaa;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
