import styled from 'styled-components';

export const Container = styled.div<{
  color?: string;
  backgroundColor?: string;
}>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.danger};
  color: ${({ color }) => color || '#fff'};
  padding: 0.1rem 0.4rem 0.2rem 0.4rem;
  border-radius: 3px;
  display: inline-block;
`;
