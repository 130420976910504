export const SET = 'supplierMeasure/SET';
export const CLEAR = 'supplierMeasure/CLEAR';

export interface SupplierContract {
  totalMedido?: number;
  totalPedido?: number;
  nomeContrato: string;
  idContrato: number;
  idContratoFornecedor: number;
  totalOf: number;
  idCfPai?: number;
  saldoFaturamentoDireto?: number;
  saldoContrato?: number;
  totalFaturamentoDireto?: number;
  flagCriarBoletim?: boolean;
  tipo: 'CONTRATO' | 'PEDIDO';
}

export type SupplierMeasureState = {
  contract: SupplierContract | null;
  isEditMode?: boolean;
  isSupplierMeasure?: boolean;
};

interface SetAction {
  type: typeof SET;
  payload: SupplierMeasureState;
}

interface ClearAction {
  type: typeof CLEAR;
}

export type SupplierMeasureTypes = SetAction | ClearAction;
