import { atom } from 'jotai';
import { UploadModalParams } from 'components/Widgets/DynamicForm/types';
import { getSqlResponse, getSqlResponseAdonis } from 'services/dataset';
import store from 'store';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { EnumRoutes } from 'store/types';
import { compareKeys } from 'utils/treeview';
import {
  FecthImagesProps,
  GalleryPhoto,
  ShowUploadImagesModalProps,
} from './types';
import { orderByDate } from './utils';

export const imagesAtom = atom<GalleryPhoto[]>([]);

export const fetchImagesAtom = atom(
  null,
  async (_, set, props: FecthImagesProps) => {
    const { uuidOcorrencia, idContrato, tipo } = props;

    if (!uuidOcorrencia || !idContrato) {
      return;
    }

    const { dispatch } = store;

    dispatch(showModal(ModalName.LOADING, { text: 'Carregando imagens' }));

    const res = await getSqlResponseAdonis<GalleryPhoto[]>(1627, {
      tipo,
      idContrato,
      referenciaUuid: uuidOcorrencia,
    });

    const normalizedData = orderByDate(res);

    let mainImage = normalizedData.filter(
      item => item.key.toString().split('-').length === 1,
    );

    mainImage = mainImage.map(img => ({
      ...img,
      replyImagens: normalizedData.filter(
        item =>
          compareKeys(img.key, item.key) &&
          item.key.toString().split('-').length > 1,
      ),
    }));

    set(imagesAtom, mainImage);

    dispatch(closeModal(ModalName.LOADING));
  },
);

export const onShowUploadImagesModalAtom = atom(
  null,
  (_, set, props: ShowUploadImagesModalProps) => {
    const {
      idContrato,
      uuidReferencia,
      idReferencia,
      tipo,
      uuidOcorrencia,
      title,
    } = props;

    const { dispatch, getState } = store;
    const { user } = getState();

    dispatch(
      showModal<UploadModalParams>(ModalName.MODAL_UPLOAD, {
        tipo,
        idContrato,
        id: idReferencia,
        idDatasetImages: 2276,
        uuidReferencia: uuidReferencia,
        allowDisableImage: false,
        idUsuario: user.idUsuario!,
        idPerfilModulo: user.idPerfilModulo,
        typeOfPage: EnumRoutes.UPLOAD_IMAGE,
        onReloadPageForm: () =>
          set(fetchImagesAtom, {
            tipo,
            idContrato,
            uuidOcorrencia,
          }),
        title: title || 'Respostas da ocorrência',
      }),
    );
  },
);
