import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const InspecaoQualidade = lazy(() =>
  import(
    './layouts/fisico/gerencial' /*webpackChunkName: "relatorios.brookfield.fisico.gerencial" */
  ),
);

const Dashboard = lazy(() =>
  import(
    './layouts/fisico/dashboard' /*webpackChunkName: "relatorios.brookfield.fisico.gerencial" */
  ),
);

const MedicaoOcorrencia = lazy(() =>
  import(
    './layouts/medicao/ocorrencia' /*webpackChunkName: "relatorios.brookfield.medicao.ocorrencia" */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/brookfield/fisico/gerencial"
    component={InspecaoQualidade}
    path="/relatorios/brookfield/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/brookfield/fisico/dashboard"
    component={Dashboard}
    path="/relatorios/brookfield/fisico/dashboard"
  />,
  <PrivateRoute
    key="/relatorios/brookfield/medicao/ocorrencia"
    component={MedicaoOcorrencia}
    path="/relatorios/brookfield/medicao/ocorrencia"
  />,
];
