import React from 'react';
import { formatNumber } from 'pages/Reports/utils/dataFunctions';
import { StyledDiv } from './styles';

export default function CustomStyleType(props) {
  const {
    col: { valueFormatter, numberFormatting },
    value,
  } = props;

  const { value: dataValue, params, customStyle } = value || JSON.parse(value);

  const calcValue = !numberFormatting
    ? dataValue
    : formatNumber(numberFormatting)(dataValue);
  const normalizedFormatter = valueFormatter || (v => v);

  return (
    <StyledDiv
      style={{
        backgroundColor: params ? params.bgColor : '',
        color: params ? params.fontColor : '',
        position: params?.position && params.position,
        ...customStyle,
      }}
      className="celltype-customStyle"
    >
      {normalizedFormatter(calcValue)}
    </StyledDiv>
  );
}
