import React from 'react';
import { Label } from '../../styles';
import { Field } from '../../types';
import { Container } from './styles';

export default function StaticImage(props: { field: Field }) {
  const { field } = props;

  return (
    <>
      <Label
        htmlFor={field.name}
        dangerouslySetInnerHTML={{ __html: field.label }}
        isLabel={field.label}
      />
      <Container>
        <img alt="img" src={field.url} style={field.styles} />
      </Container>
    </>
  );
}
