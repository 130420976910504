import styled from 'styled-components';

export const Container = styled.div.attrs(props => ({
  className: 'SimpleTable__actions-bar__container',
}))`
  display: flex;
  align-items: center;
  /* margin-top: 4px;
  margin-bottom: 5px; */
  /* margin-left: 90px; */
  user-select: none;

  & > div {
    margin-right: 2rem;
  }
`;
