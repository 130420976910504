import styled, { keyframes } from 'styled-components';

const topToBottom = keyframes`
  0% {
    top: -20%;
  }

  100% {
    top: 10%;
  }
`;

export const ContainerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999998;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
`;

export const Body = styled.div`
  z-index: 99999998;
  background: #fff;
  position: relative;
  margin: 0 auto;
  border-radius: 3px;
  width: 54rem;
  max-height: 68rem;
  padding: 1.5rem;
  animation: ${topToBottom} 0.2s ease-in;
  top: 14%;

  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;

  button {
    cursor: pointer;

    border: none;
    background-color: transparent;

    position: absolute;
    font-size: 1.5rem;
    font-weight: bold;
    top: -1px;
    right: 0.2rem;

    &:hover {
      color: #ff0000;
    }
  }
`;

export const Title = styled.h1``;

export const Subtitle = styled.p`
  font-size: 1.4rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const ShowAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ShowAlertList = styled.ul`
  max-height: 23rem;
  overflow: auto;
`;

export const ShowAlertLabel = styled.label`
  font-weight: 700;
`;
