/* eslint-disable @typescript-eslint/no-explicit-any */
export const SHOW_MODALS = 'modal/SHOW_MODALS';
export const SHOW_MODAL = 'modal/SHOW_MODAL';
export const CLOSE_MODAL = 'modal/CLOSE_MODAL';

export enum ModalName {
  FILE_GROUP = 'FILE_GROUP',
  SELECT_SECTORS = 'SELECT_SECTORS',
  FORM_GROUP_MODAL = 'FORM_GROUP_MODAL',
  OPEN_MODAL_EXCEL = 'OPEN_MODAL_EXCEL',
  NEW_SECTOR_FAMILY_MODAL = 'NEW_SECTOR_FAMILY_MODAL',
  NEW_SECTOR_FORM = 'NEW_SECTOR_FORM',
  DYNAMIC_TABLE_PHOTOS = 'DYNAMIC_TABLE_PHOTOS',
  DYNAMIC_FORM = 'DYNAMIC_FORM',
  DYNAMIC_FORM_NEW_ITEM = 'DYNAMIC_FORM_NEW_ITEM',
  LOADING = 'LOADING',
  DYNAMIC_FORM_UPLOAD = 'DYNAMIC_FORM_UPLOAD',
  MODAL_UPLOAD = 'MODAL_UPLOAD',
  UPDATE_PLANNING = 'UPDATE_PLANNING',
  MATERIAL_APPROVAL = 'MATERIAL_APPROVAL',
  MATERIAL_DISAPPROVAL_COMMENT = 'MATERIAL_DISAPPROVAL_COMMENT',
  TRISUL_TEAM = 'TRISUL_TEAM',
  ACTIVITIES_TABLE_MODAL = 'ACTIVITIES_TABLE_MODAL',
  FORM_NEW_SERVICES = 'FORM_NEW_SERVICES',
  FORM_ADDITIVE = 'FORM_ADDITIVE',
  CLOSE_ADDITIVES = 'CLOSE_ADDITIVES',
  REMOVE_BALANCE_ADDITIVE = 'REMOVE_BALANCE_ADDITIVE',
  LIST_CONTRACT_SERVICE = 'LIST_CONTRACT_SERVICE',
  FVM_INSPECTION = 'FVM_INSPECTION',
  FVM_INSPECTION_EDITOR = 'FVM_INSPECTION_EDITOR',
  NC_EDITOR = 'NC_EDITOR',
  FORM_SUPPLIER = 'FORM_SUPPLIER',
  FORM_SUPPLIER_CONTRACT = 'FORM_SUPPLIER_CONTRACT',
  FINISH_SUPPLIER_CONTRACT = 'FINISH_SUPPLIER_CONTRACT',
  REPORT_MEASUREMENT_SERVICE = 'REPORT_MEASUREMENT_SERVICE',
  FORM_OCCURRENCE = 'FORM_OCCURRENCE',
  FINISH_ORDER_CONTRACT = 'FINISH_ORDER_CONTRACT',
  FINISH_CREATE_ORDER_CONTRACT = 'FINISH_CREATE_ORDER_CONTRACT',
  FINISH_ORDER_RECEIPT = 'FINISH_ORDER_RECEIPT',
  MEASUREMENT_SHEETS = 'MEASUREMENT_SHEETS',
  TREEVIEW_SECTOR = 'TREEVIEW_SECTOR',
  SERVICE_INSPECTION = 'SERVICE_INSPECTION',
  CHECKLIST_APPROVAL = 'CHECKLIST_APPROVAL',
  CHECKLIST_FORM = 'CHECKLIST_FORM',
  ACCESS_LEVEL_USERS = 'ACCESS_LEVEL_USERS',
  CREATE_USER = 'CREATE_USER',
  CONTRACTING_PROCESS = 'CONTRACTING_PROCESS',
  CONTRACTING_PROCESS_FILE_MODAL = 'CONTRACTING_PROCESS_FILE_MODAL',
  CREATE_CONTRACTING_PROCESS_TAB = 'CREATE_CONTRACTING_PROCESS_TAB',
  TREEVIEW_FVS_SUMMARY = 'TREEVIEW_FVS_SUMMARY',
  REPORT_APPROPRIATION = 'REPORT_APPROPRIATION',
  DISHCHARGE_REPORT = 'DISHCHARGE_REPORT',
  SELECT_SUBSERVICE = 'SELECT_SUBSERVICE',
  SERVICE_ADDITIVE = 'SERVICE_ADDITIVE',
  EDIT_QUOTATION_DATES = 'EDIT_QUOTATION_DATES',
  EDIT_ANTECEDENCES = 'EDIT_ANTECEDENCES',
  SERVICE_FORM = 'SERVICE_FORM',
  SINGLE_INPUT = 'SINGLE_INPUT',
  DATE_TIME = 'DATE_TIME',
  FINISH_MEASURE = 'FINISH_MEASURE',
  UPLOAD_ORDER = 'UPLOAD_ORDER',
  TAG_FORM = 'TAG_FORM',
  TAG_FILTER = 'TAG_FILTER',
  APPROPRIATION_BUDGET = 'APPROPRIATION_BUDGET',
  CONTRACT_TYPE_FORMATTER = 'CONTRACT_TYPE_FORMATTER',
  IMAGE_VIEWER = 'IMAGE_VIEWER',
  CAP_CONFIRMATION = 'CAP_CONFIRMATION',
  SAVE_BASELINE_BUDGET = 'SAVE_BASELINE_BUDGET',
  SHOW_TABLE = 'SHOW_TABLE',
  SELECT_TAGS_MODAL = 'SELECT_TAGS_MODAL',
  DISBURSEMENT_TABLE = 'DISBURSEMENT_TABLE',
  PDF_VIEWER = 'PDF_VIEWER',
  SERVICE_MESURE = 'SERVICE_MESURE',
  DATE_SERVICE_MEASURE = 'DATE_SERVICE_MEASURE',
  FORM_FVS = 'FORM_FVS',
  FORM_NC_FVS = 'FORM_NC_FVS',
  FORM_REPPROVAL_OCCURRENCE_MODAL = 'FORM_REPPROVAL_OCCURRENCE_MODAL',
  FORM_REPLY_OCCURRENCE_MODAL = 'FORM_REPLY_OCCURRENCE_MODAL',
  ANALYSIS_LPU_ADDITIVE = 'ANALYSIS_LPU_ADDITIVE',
  GENERATE_PHOTO_REPORT = 'GENERATE_PHOTO_REPORT',
  SELECT_EMAILS = 'SELECT_EMAILS',
  APPROVAL_FLOW_CONTRACT = 'APPROVAL_FLOW_CONTRACT',
  APPROVAL_FLOW_REQUEST = 'APPROVAL_FLOW_REQUEST',
  CHARGEBACK_MANAGEMENT = 'CHARGEBACK_MANAGEMENT',
  TIMELINE_FORM_MODAL = 'TIMELINE_FORM_MODAL',
  PHOTO_GALLERY_COMMENTS = 'PHOTO_GALLERY_COMMENTS',
  FORM_SUPPLIER_QUOTATION = 'FORM_SUPPLIER_QUOTATION',
  FINISH_SUPPLIER_QUOTATION = 'FINISH_SUPPLIER_QUOTATION',
  FORM_REGISTRATION_COMPOSITION = 'FORM_REGISTRATION_COMPOSITION',
  FORM_ADDITIVE_PRICE = 'FORM_ADDITIVE_PRICE',
  APPROVAL_FLOW_QUOTATION = 'APPROVAL_FLOW_QUOTATION',
  TABLE_SUPPLIER_QUOTATION_MANAGEMNT = 'TABLE_SUPPLIER_QUOTATION_MANAGEMNT',
  FORM_SUPPLIERS_QUOTATION = 'FORM_SUPPLIERS_QUOTATION',
  SHOW_SUPPLIER_QUOTATION = 'SHOW_SUPPLIER_QUOTATION',
  SUPPLIER_INFO_SHEET = 'SUPPLIER_INFO_SHEET',
  RESISTANCE_CONTROL_FORM = 'RESISTANCE_CONTROL_FORM',
  RESISTANCE_CONTROL_LIST = 'RESISTANCE_CONTROL_LIST',
  SCAFFOLDING_CATEGORY_MANAGEMENT = 'SCAFFOLDING_CATEGORY_MANAGEMENT',
  FORM_REGISTRATION_PAVEMENT = 'FORM_REGISTRATION_PAVEMENT',
  FORM_EDIT_SCAFFOLDING = 'FORM_EDIT_SCAFFOLDING',
  INPUT_FILE_SCAFFOLDING_PROJECT_MODAL = 'INPUT_FILE_SCAFFOLDING_PROJECT_MODAL',
  INPUT_FILE_SCAFFOLDING_PACKING_LIST_MODAL = 'INPUT_FILE_SCAFFOLDING_PACKING_LIST_MODAL',
  PROJECT_REGISTRATION_FORM = 'PROJECT_REGISTRATION_FORM',
  PROJECT_REGISTRATION_LIST = 'PROJECT_REGISTRATION_LIST',
  CLASSIFY_BUDGET_MODAL = 'CLASSIFY_BUDGET_MODAL',
  SELECT_SERVICES_SHEET = 'SELECT_SERVICES_SHEET',
  CREATE_INPUT_FLOW_REQUEST = 'CREATE_INPUT_FLOW_REQUEST',
  INPUT_REGISTER_FILE_MODAL = 'INPUT_REGISTER_FILE_MODAL',
  ADDITIVE_BUDGET_HISTORY_MODAL = 'ADDITIVE_BUDGET_HISTORY_MODAL',
  FINISH_SUPPLIER_CONTRACT_REQUEST = 'FINISH_SUPPLIER_CONTRACT_REQUEST',
  INPUT_ADDTIVE_LOAD = 'INPUT_ADDTIVE_LOAD',
  EDIT_PACKING_LIST_SCAFFOLDING_MODAL = 'EDIT_PACKING_LIST_SCAFFOLDING_MODAL',
  PACKING_LIST_SCAFFOLDING_FVM_MODAL = 'PACKING_LIST_SCAFFOLDING_FVM_MODAL',
  LIST_OCCURRENCE_DYNAMIC_FORM = 'LIST_OCCURRENCE_DYNAMIC_FORM',
  CLOSE_QUOTATION_INFOS_MODAL = 'CLOSE_QUOTATION_INFOS_MODAL',
  SUPPLIER_INFOS_MODAL = 'SUPPLIER_INFOS_MODAL',
  REQUEST_ADDITIVE_MODAL = 'REQUEST_ADDITIVE_MODAL',
  RETURN_SCAFFOLDING_SUPPLIER = 'RETURN_SCAFFOLDING_SUPPLIER',
  ASSOCIATE_SUPPLIER_MODAL = 'ASSOCIATE_SUPPLIER_MODAL',
  TREEVIEW_SECTORS_MODAL = 'TREEVIEW_SECTORS_MODAL',
  TRACEABILITY = 'TRACEABILITY',
  FORM_CATALOG = 'FORM_CATALOG',
  UPDATES_OCCURRENCE_MODAL = 'UPDATES_OCCURRENCE_MODAL',
  JUSTIFY_REQUISITION_QUANTITY_MODAL = 'JUSTIFY_REQUISITION_QUANTITY_MODAL',
  FORM_CATALOG_CONTRACT = 'FORM_CATALOG_CONTRACT',
  SELECT_USER_COMPANY = 'SELECT_USER_COMPANY',
  FORM_REPPROVAL_REEVALUATE_MODAL = 'FORM_REPPROVAL_REEVALUATE_MODAL',
  ADDITIVE_SUPPLIER_CONTRACT = 'ADDITIVE_SUPPLIER_CONTRACT',
  TABLE_REPORT_MODAL = 'TABLE_REPORT_MODAL',
  LIST_MANAGEMENT = 'LIST_MANAGEMENT',
  NEW_LIST_ITEM_FORM = 'NEW_LIST_ITEM_FORM',
  INPUT_FILE_UPLOAD_ITEMS = 'INPUT_FILE_UPLOAD_ITEMS',
  SELECT_ITEMS_MILESTONE = 'SELECT_ITEMS_MILESTONE',
  UPLOAD_LEAD_TIME_MILESTONE_CONFIGURATION = 'UPLOAD_LEAD_TIME_MILESTONE_CONFIGURATION',
  MILESTONE_ASSOCIATION_TABLE = 'MILESTONE_ASSOCIATION_TABLE',
  MILESTONE_ASSOCIATION_EAP = 'MILESTONE_ASSOCIATION_EAP',
  NEW_QUOTATION_AQUISITION_PLAN = 'NEW_QUOTATION_AQUISITION_PLAN',
  QUOTATION_PLAN_MODAL = 'QUOTATION_PLAN_MODAL',
  EDIT_QUOTATION_PLAN_DATES = 'EDIT_QUOTATION_PLAN_DATES',
  MILESTONE_ASSOCIATION_INFORMATION = 'MILESTONE_ASSOCIATION_INFORMATION',
  SELECT_USER_MODAL = 'SELECT_USER_MODAL',
  MEASUREMENT_SUMMARY_MODAL = 'MEASUREMENT_SUMMARY_MODAL',
  CREATE_USER_SIMPLE = 'CREATE_USER_SIMPLE',
  DIRECT_BILING = 'DIRECT_BILING',
  MATERIAL_CANCELLATION = 'MATERIAL_CANCELLATION',
  DYNAMIC_FORM_MODAL = 'DYNAMIC_FORM_MODAL',
  MILESTONE_ASSOCIATION_DATES = 'MILESTONE_ASSOCIATION_DATES',
  PHOTO_GALLERY = 'PHOTO_GALLERY',
  MODAL_NCS = 'MODAL_NCS',
  PURCHASE_ORDER_REGISTRATION = 'PURCHASE_ORDER_REGISTRATION',
  ADMINISTRATIVE_MANAGERS = 'ADMINISTRATIVE_MANAGERS',
  ORDER_SUMMARY = 'ORDER_SUMMARY',
  PRODUCT_REGISTRATION = 'PRODUCT_REGISTRATION',
  CLIENT_REGISTRATION = 'CLIENT_REGISTRATION',
  REPRESENTATIVE_REGISTRATION = 'REPRESENTATIVE_REGISTRATION',
  SELECT_CLIENT_PRODUCTS = 'SELECT_CLIENT_PRODUCTS',
  SELECT_PRODUCTS_REPORT = 'SELECT_PRODUCTS_REPORT',
  SELECT_CONTRACT_MODAL = 'SELECT_CONTRACT_MODAL',
  UPDATE_PLANNING_V2 = 'UPDATE_PLANNING_V2',
  FORM_MEASUREMENT_CONTRACT = 'FORM_MEASUREMENT_CONTRACT',
  SIGNATURE_RESPONSABLE = 'SIGNATURE_RESPONSABLE',
  NEW_REGISTER_MEASUREMENT = 'NEW_REGISTER_MEASUREMENT',
  SUPPLIER_MEASUREMENT = 'SUPPLIER_MEASUREMENT',
  NEW_FINISH_MEASURE = 'NEW_FINISH_MEASURE',
  EDIT_PURCHASING_PLAN_DATES = 'EDIT_PURCHASING_PLAN_DATES',
  ATTACHMENTS_MODAL = 'ATTACHMENTS_MODAL',
  MEASUREMENT_HISTORY_MODAL = 'MEASUREMENT_HISTORY_MODAL',
  SUPPLIER_SELECT_TIMELINE = 'SUPPLIER_SELECT_TIMELINE',
  RESPONSABLE_SELECT_TIMELINE = 'RESPONSABLE_SELECT_TIMELINE',
  ORDER_BY_SERVICE = 'ORDER_BY_SERVICE',
  ORDER_LINES_BALANCE_LINE = 'ORDER_LINES_BALANCE_LINE',
  ACCUMULATED_ADVANCE = 'ACCUMULATED_ADVANCE',
  LIST_SELECTED_REPORT = 'LIST_SELECTED_REPORT',
  CREATE_FVS_AND_RECORD = 'CREATE_FVS_AND_RECORD',
  MANAGE_GUIDELINES = 'MANAGE_GUIDELINES',
  MANAGE_ITEM_GUIDELINES = 'MANAGE_ITEM_GUIDELINES',
  SORT_RECORDS_AND_FAMILY = 'SORT_RECORDS_AND_FAMILY',
  SHEDULE_VERSIONING_SHEET = 'SHEDULE_VERSIONING_SHEET',
  MESSAGE_LIST_BY_TYPE = 'MESSAGE_LIST_BY_TYPE',
  SHEDULE_MESSAGES_APP = 'SHEDULE_MESSAGES_APP',
  COMMENT_REVIEW_SHEET = 'COMMENT_REVIEW_SHEET',
  BUDGET_COMPOSITION_FORM = 'BUDGET_COMPOSITION_FORM',
  CONFIGURATION_INCC_MODAL = 'CONFIGURATION_INCC_MODAL',
  CONTRACT_CONFIGURATION_MODAL = 'CONTRACT_CONFIGURATION_MODAL',
  BUDGET_MASK_CONFIGURATION_MODAL = 'BUDGET_MASK_CONFIGURATION_MODAL',
  PHOTO_GALLERY_PERIOD = 'PHOTO_GALLERY_PERIOD',
  NON_COMPLIANCE_FVS = 'NON_COMPLIANCE_FVS',
  CREATE_NON_COMPLIANCE_FVS = 'CREATE_NON_COMPLIANCE_FVS',
  LOCAL_STATUS_MODAL = 'LOCAL_STATUS_MODAL',
  ADD_COMMENT_AND_PHOTO = 'ADD_COMMENT_AND_PHOTO',
  DYNAMIC_ATTACHMENTS_MODAL = 'DYNAMIC_ATTACHMENTS_MODAL',
  DOWNLOAD_SCHEDULE_FILES = 'DOWNLOAD_SCHEDULE_FILES',
  OPENING_CALL = 'OPENING_CALL',
  TICKET_SUPPORT = 'TICKET_SUPPORT',
  GESCORPGO_COMMENT_MODAL = 'GESCORPGO_COMMENT_MODAL',
  CLIENT_COMMENT_MODAL = 'CLIENT_COMMENT_MODAL',
  CALL_TYPE_MODAL = 'CALL_TYPE_MODAL',
  NEW_WORK_REGISTRATION = 'NEW_WORK_REGISTRATION',
  CHECK_CNPJ = 'CHECK_CNPJ',
  ECONOMY_ADDITIVE_COMPOSITION = 'ECONOMY_ADDITIVE_COMPOSITION',
  REMOVE_ECONOMY_ADDITIVE_COMPOSITION = 'REMOVE_ECONOMY_ADDITIVE_COMPOSITION',
  BUDGET_READJUSTMENT_MODAL = 'BUDGET_READJUSTMENT_MODAL',
  SUBSCRIBER_REGISTRATION = 'SUBSCRIBER_REGISTRATION',
  CREATE_SUBSCRIBER_REGISTRATION = 'CREATE_SUBSCRIBER_REGISTRATION',
  PICTURE_CONFIRM_MODAL = 'PICTURE_CONFIRM_MODAL',
  SORT_STEPLADDER_FAMILY = 'SORT_STEPLADDER_FAMILY',
  MODAL_FILTER = 'MODAL_FILTER',
  RETENTION_DISCHARGE = 'RETENTION_DISCHARGE',
  RETENTION_DISCHARGE_BULLETINS = 'RETENTION_DISCHARGE_BULLETINS',
  QUOTATION_FRAME_LIST_MANAGEMENT = 'QUOTATION_FRAME_LIST_MANAGEMENT',
  QUOTATION_FRAME_CONFIGURATION_GROUP = 'QUOTATION_FRAME_CONFIGURATION_GROUP',
  CONFIGURATION_CONSTANTS_VARIABLES = 'CONFIGURATION_CONSTANTS_VARIABLES',
  SIGN_DOC_VIEWER = 'SIGN_DOC_VIEWER',
  CAP_APPROVATION = 'CAP_APPROVATION',
  DISCOUNT_APPROPRIATION = 'DISCOUNT_APPROPRIATION',
}

export interface ModalState {
  modal: ModalName | null;
  params: any;
}

interface SetAction {
  type: typeof SHOW_MODAL;
  modal: ModalName;
  params: any;
}

interface ClearAction {
  type: typeof CLOSE_MODAL;
  modal?: ModalName;
}

interface ShowModalsAction {
  type: typeof SHOW_MODALS;
  modals: ModalState[];
}

export type ModalActionTypes = SetAction | ClearAction | ShowModalsAction;
