import { Subscriber } from 'pages/DocSign/types';

export function getInitValuesFormik(data: Subscriber | undefined) {
  const { name, cargo, alcada } = data || {};

  return {
    name: name || null,
    position: cargo || null,
    role: alcada ? { label: alcada, value: alcada } : null,
  };
}
