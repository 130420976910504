import styled from 'styled-components';
import { GrTree } from 'react-icons/gr';
import { OptionsWrapper } from 'pages/TableReport/components/ExpandRowAction/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 160;
`;

export const IconTree = styled(GrTree)`
  font-size: 2.2rem;
  cursor: pointer;
`;

export const ListContainer = styled(OptionsWrapper)`
  left: 3.5rem;
  top: 0.4rem;
`;
