/* eslint-disable @typescript-eslint/no-explicit-any */
import api from './api';
import apiAdonis from './apiAdonis';
import { User } from '../store/types';
import { UserState, UserToCreate } from '../store/user/types';
import { storeByUserTemplate as storeByUserTemplateContract } from 'services/user_profile_contract_type';
import { storeByUserTemplate as storeByUserTemplateReport } from 'services/user_report';
import { storeByUserTemplate as storeByUserTemplateModule } from 'services/user_profile_module';
import fileDownload from 'js-file-download';
import { XlsRow } from 'pages/UsersManagement/utils';

interface UserToCreateProps {
  uuid?: string;
  idUsuario?: number;
  idEmpresa?: number;
  nome?: string;
  sobrenome?: string;
  apelido?: string;
  email?: string;
  telefone?: string;
  flagStatus?: number | boolean;
  flagEmailEnviado?: number;
  flagUserNovo?: number;
  flagUserModelo?: number;
  flagRecarregarAcessos?: number;
  flagUsuarioDemo?: number;
}

interface CompanyProps {
  idEmpresa: number;
  empresa: string;
}

export function get(id: number): Promise<User> {
  return api.get(`/usuarios/get-by-id/${id}.json`);
}

export function getUserWithProfile(impersonateId = 0): Promise<User> {
  return api.get(`/usuarios/get-user-with-profile/${impersonateId}.json`);
}

export function getByCompany(companyId: number): Promise<User[]> {
  return api.get(`/usuarios/get-by-company/${companyId}.json`);
}

export function updateFileGroupId(
  data: { idUsuario: number; idGrupoArquivo: number }[],
): Promise<any> {
  return api.post('/usuarios/update-file-group-id.json', data);
}

export function attachContracts(
  userIds: number[],
  contractIds: number[],
  detach: boolean,
): Promise<any> {
  return api.post('/usuario-contrato/update', {
    userIds,
    contractIds,
    detach,
  });
}

export function store(user: UserToCreate): Promise<any> {
  return api.post('/usuarios/store', user);
}

export function update(user: UserToCreate): Promise<any> {
  return api.put('/usuarios/update', user);
}

export function deactivate(id: number): Promise<any> {
  return api.post(`/usuarios/deactivate/${id}`);
}

export function impersonate(
  id: number,
  impersonateToken: string | null,
): Promise<{ token: string; impersonateToken: string }> {
  return api.get(`/usuarios/impersonate/${id}/${impersonateToken}`);
}

export async function bulkCreate(usersList: XlsRow[]): Promise<any> {
  const response = await apiAdonis.post('/usuario/bulk-create', usersList, {
    params: { ignoreInterceptor: true },
    responseType: 'blob',
  });

  const { data } = response;

  if (data.type !== 'text/xml') {
    fileDownload(data, `user-repeated-emails.xlsx`);
  }

  return data.type;
}

export async function create(user: any): Promise<any> {
  const response = await apiAdonis.post('/usuario/create', user);

  return response;
}

export async function createUserResponsible(user: {
  payload: UserToCreate[];
  idContrato: number;
}): Promise<any> {
  const responseUuids = await apiAdonis.post(
    `/usuario/create-responsable`,
    user,
  );

  return responseUuids;
}

export function storeAdonis(data: UserToCreateProps[]): Promise<any> {
  return apiAdonis.post('/usuario/store', data);
}

export function reloadUserByTemplate(data: number[]): Promise<any> {
  return apiAdonis.post('/usuario/reload-user-by-template', data);
}

export async function resetUserAccess(user: UserState) {
  const { flagRecarregarAcessos, idUsuario, idUserModelo, idEmpresa } = user;

  if (flagRecarregarAcessos && idUsuario && idUserModelo && idEmpresa) {
    const resContractPromise = storeByUserTemplateContract({
      idUsuario,
      idUserModelo,
    });

    const resReportPromise = storeByUserTemplateReport({
      idUsuario,
      idUserModelo,
      idEmpresa,
    });

    const resModulePromise = storeByUserTemplateModule({
      idUsuario,
      idUserModelo,
    });

    await Promise.all([resContractPromise, resReportPromise, resModulePromise]);

    storeAdonis([
      {
        idUsuario,
        idEmpresa,
        flagRecarregarAcessos: 0,
      },
    ]);
  }
}

export function getUserCompanyFromStorage(): CompanyProps {
  const userCompanyInfo = localStorage.getItem('@gescorpgo-company');

  return userCompanyInfo !== null ? JSON.parse(userCompanyInfo.toString()) : {};
}

export function saveUserCompanyOnStorage(item: CompanyProps): void {
  localStorage.setItem('@gescorpgo-company', JSON.stringify(item));
}

export function removeUserCompanyFromStorage(): void {
  localStorage.removeItem('@gescorpgo-company');
}
