import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  *, *::after, *::before {
    box-sizing: inherit;
  }

  pre {
    font-family: inherit;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%; /* 1rem = 10px, 10px / 16px= 62.5% */

    @media only screen and (max-width: 37.5em){
      /* font-size: 70%; */
    }

  }

  body {
    font-family: 'Roboto', 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 1.6;
    background-color: #E9EDF0;
    letter-spacing: 0.08rem;
    color: #333;
    
    width: 100vw;
    overflow-x: hidden;
  }

  ul {
    list-style: none;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }

 
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
    background-clip: content-box; 
  }

  /* Track */
  ::-webkit-scrollbar-track:hover {
    border: 1px solid #ccc;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a3a3a3; 
  }

  /* Custom style for reports layout config file */
  ${props => props.customGlobalStyle};


  .react-toast-notifications__container {
    z-index: 2000 !important;
  }
`;

export const ScrollShadow = css`
  /* scroll shadow */

  background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(240, 240, 240, 0.5),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(240, 240, 240, 0.5),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 150px, 100% 150px, 100% 50px, 100% 50px;
`;
