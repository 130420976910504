/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { OptionTypeBase } from 'react-select';
import { colors } from '../../theme';
import { Creatable } from 'react-select-virtualized';
import './styles.css';

// TODO: ISSO DAQUI TA UMA BOSTA, REFATORAR USANDO COMPONENT COM E REF

type CreatableSelectProps = OptionTypeBase;

export function CreatableSelect(props: CreatableSelectProps): ReactElement {
  const { minHeight, error } = props;

  const height = props.height || '4rem';
  const fontSize = props.fontSize || '1.4rem';
  const fontControlSize = props.fontControlSize || '1.4rem';

  function getBorderColor(state) {
    if (error) {
      return colors.danger;
    }

    return state.menuIsOpen ? colors.green : '#ddd';
  }

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      fontSize: fontControlSize,
      minHeight,
      height: minHeight ? 'auto' : height,
      borderColor: getBorderColor(state),
      boxShadow: null,
      '&:hover': {
        borderColor: state.isFocused ? colors.green : '#ddd',
      },
      '&:placeholder': {
        color: '#000',
      },
    }),

    option: (provided: any, state: any) => ({
      ...provided,
      fontSize,
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
      backgroundColor: state.isSelected ? '#B9E2DE' : null,

      color: '#333',
      '&:active': {
        backgroundColor: '#f5f5f5',
      },
    }),
  };

  return (
    <Creatable
      placeholder="Selecionar"
      styles={customStyles}
      formatCreateLabel={() => 'Criar novo'}
      {...props}
      classNamePrefix="my-custom-select"
    />
  );
}
