import { atom } from 'jotai';
import { getSqlResponseAdonis } from 'services/dataset';
import { updateOrCreate } from 'services/signature';
import store from 'store';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import {
  DataHardwareType,
  EnumStatus,
  EnumStep,
  FormDataSignature,
  Subscriber,
  URLParams,
} from './types';
import { generateSignPDF, getGeolocation } from './utils';
import { GeolocationType } from 'utils/maps';
import { getUtcDatetime } from 'utils/date';

export const currentStepAtom = atom<EnumStep>(EnumStep.SUBSCRIBER_LIST);
export const subscriberListAtom = atom<Subscriber[]>([]);
export const dataHardwareAtom = atom({} as DataHardwareType);
export const formDataAtom = atom<FormDataSignature>({} as FormDataSignature);
export const urlParamsAtom = atom<URLParams | undefined>();
export const docNameAtom = atom('');
export const isMainDocGeneratedAtom = atom(false);

export const fetchSubscribersAtom = atom(null, async (get, set) => {
  const { dispatch } = store;
  dispatch(showModal(ModalName.LOADING, { text: 'Carregando lista.' }));

  const urlParams = get(urlParamsAtom);

  if (!urlParams) {
    return [];
  }

  try {
    const users = await getSqlResponseAdonis<Subscriber[]>(4580, {
      uuidArquivo: urlParams!.uuidArquivo,
    });

    set(subscriberListAtom, users);

    if (users.length) {
      const [{ nomeDocumento }] = users;
      set(docNameAtom, nomeDocumento);
    }

    return users;
  } catch (error) {
    console.error('Erro ao buscar usuários:', error);

    return [];
  } finally {
    dispatch(closeModal(ModalName.LOADING));
  }
});

export const handleSaveSignatureAtom = atom(null, async get => {
  const formData = get(formDataAtom);
  const urlParams = get(urlParamsAtom);

  const removeKeys = (obj, keysToRemove) =>
    Object.fromEntries(
      Object.entries(obj).filter(([key]) => !keysToRemove.includes(key)),
    );

  const newData = removeKeys(formData, [
    'imgSignature',
    'name',
    'nomeDocumento',
    'value',
    'label',
    'token',
    'source',
  ]);

  newData.dtAssinatura = getUtcDatetime();

  await updateOrCreate({ ...newData, status: EnumStatus.SIGNED });

  return generateSignPDF(urlParams!);
});

export const getCurrenttGeolocationAtom = atom(null, async (get, set) => {
  const permission = await navigator.permissions.query({
    name: 'geolocation',
  });

  if (permission.state === 'denied') {
    alert(
      'Você negou o acesso à localização. Ative a permissão nas configurações do navegador.',
    );

    throw new Error('Location denied');
  }

  try {
    const currentGeolocation = await getGeolocation();
    const dataHardware = get(dataHardwareAtom);

    set(dataHardwareAtom, { ...dataHardware, ...currentGeolocation });
  } catch (errors) {
    const error = errors as GeolocationType;

    alert(error.errorMessage);
  }
});
