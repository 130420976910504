export const SET_TITLE = 'navbar/SET_TITLE';
export const CLEAR_TITLE = 'navbar/CLEAR_TITLE';

export interface NavbarState {
  title: string | null
}

interface SetTitleAction {
  type: typeof SET_TITLE;
  payload: string
}

interface ClearTitleAction {
  type: typeof CLEAR_TITLE;
}

export type NavbarActionTypes = SetTitleAction | ClearTitleAction
