/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { v1 as uuid } from 'uuid';
import XLSX from 'xlsx';
import InputFile from 'components/InputFile';
import { Field, State } from '../../types';
import { useDynamicForm } from '../../context';
import { checkUserEditPermission } from '../../utils';
import useUser from 'hooks/use-user';

interface Props {
  field: Field;
  onChange(name: string, value: any): void;
  isDisabled?: boolean;
}

export default function UploadMultipleTab(props: Props) {
  const { field, onChange, isDisabled } = props;

  const {
    globalState,
    onSaveForm,
    approvalData,
    fieldsAllowProps,
  } = useDynamicForm();

  const user = useUser();

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  function handleReadXlsx(files: File[]) {
    const reader = new FileReader();

    reader.onload = (evt: ProgressEvent<FileReader>) => {
      const bstr = evt.target!.result;

      const workbook = XLSX.read(bstr, {
        type: 'binary',
      });

      workbook.SheetNames.forEach((_, idx) => {
        const firstWorksheet = workbook.Sheets[workbook.SheetNames[idx]];

        const sheetData = XLSX.utils.sheet_to_json(firstWorksheet, {
          header: 1,
        });

        normalizeXlsToTable(sheetData, idx);
      });

      onSaveForm();
    };

    reader.readAsBinaryString(files[0]);
  }

  function normalizeXlsToTable(sheetData: any[], sheetIdx: number) {
    const newSheetData = [...sheetData];
    delete newSheetData[0];

    const newTableData: State[] = [];

    const targetTableConfig = globalState!.templateConfig.templatePortal?.fields.find(
      conf => conf.name && conf.name === field.tablesToFill[sheetIdx],
    );

    if (!targetTableConfig) {
      return;
    }

    newSheetData.forEach(xlsRow => {
      const newFiledObject: State = {
        uuid: uuid(),
      };

      xlsRow.forEach((xlsCell, idx) => {
        if (targetTableConfig?.fields[0]?.fields[idx]) {
          newFiledObject[
            targetTableConfig!.fields[0].fields[idx].name
          ] = xlsCell;
        }
      });
      if (Object.keys(newFiledObject).length > 1) {
        newTableData.push(newFiledObject);
      }
    });

    if (newTableData.length) {
      onChange(field.tablesToFill[sheetIdx], newTableData);
    }
  }

  return (
    <>
      <InputFile
        accept=".xlsx, .xls, .csv"
        id={`${field.id}-upload-table`}
        text="Selecionar arquivo"
        onSelectFiles={handleReadXlsx}
        disabled={isDisableChecked}
      />
    </>
  );
}
