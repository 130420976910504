import React, { useEffect, useState } from 'react';
import Select, { CreatableSelect } from 'components/ReactSelect';
import { Label } from 'styles/form';
import { Danger } from 'styles/text';
import { OptionsType } from 'store/types';
import { getSqlResponseAdonis } from 'services/dataset';
import { useDispatch } from 'react-redux';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { useSetAtom } from 'jotai';
import { fetchSubscribersAtom, formDataAtom } from 'pages/DocSign/atoms';
import { FormDataType } from '../../type';
import { FormikProps } from 'formik';
import { CreateSubscriberRegistrationParams } from '../../../CreateSubscriberRegistration';

interface Props {
  name: string;
  label: string;
  idEmpresa: number;
  idDataset: number;
  validateField: (name: string) => void;
  isCreate?: boolean;
  formik: FormikProps<FormDataType>;
}

export default function FieldSelect(props: Props) {
  const {
    label,
    name,
    isCreate,
    validateField,
    idEmpresa,
    idDataset,
    formik,
  } = props;

  const { values, setValues, errors } = formik;

  const [options, setOptions] = useState<OptionsType[]>([]);

  const setFormData = useSetAtom(formDataAtom);
  const fetchSubscribers = useSetAtom(fetchSubscribersAtom);

  const dispatch = useDispatch();

  const isName = name === 'name';

  const error = errors[name] || '';

  useEffect(() => {
    initData();
  }, []);

  async function initData() {
    const dataAux = await getSqlResponseAdonis<OptionsType[]>(idDataset, {
      idEmpresa,
    });

    setOptions(dataAux);
  }

  function handleSelectChange(selectedOption: OptionsType) {
    if (!selectedOption?.value) {
      setFormData(prev => ({
        ...prev,
        selectedOption,
      }));

      setValues(prevValues => ({
        ...prevValues,
        [name]: null,
      }));

      return;
    } else {
      if (selectedOption?.__isNew__) {
        dispatch(
          showModal<CreateSubscriberRegistrationParams>(
            ModalName.CREATE_SUBSCRIBER_REGISTRATION,
            {
              name: selectedOption.label as string,
              onSave: fetchSubscribers,
            },
          ),
        );

        return;
      }

      setFormData(prev => ({
        ...prev,
        ...selectedOption,
      }));

      const updateValues = isName
        ? {
            position: selectedOption.cargo,
          }
        : {};

      setValues(prevValues => ({
        ...prevValues,
        [name]: {
          label: selectedOption.label,
          value: selectedOption.value,
          nome: selectedOption?.nome,
        },
        ...updateValues,
      }));
    }

    setTimeout(() => {
      validateField(name);

      if (isName) {
        validateField('position');
      }
    }, 10);
  }

  function renderSelect() {
    const value = values[name];

    if (!isCreate) {
      return (
        <Select
          options={options}
          value={value}
          error={error}
          onChange={handleSelectChange}
        />
      );
    }

    return (
      <>
        <CreatableSelect
          onChange={handleSelectChange}
          options={options}
          value={value}
          error={error}
          isClearable
          className="styleRemove styleAttention"
        />
      </>
    );
  }

  return (
    <div>
      <Label>{label}</Label>

      {renderSelect()}
      {error ? <Danger>{error}</Danger> : null}
    </div>
  );
}
