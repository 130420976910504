import React, { useEffect, useState } from 'react';
import { Label, LabelInfo } from 'styles/form';
import {
  Card,
  Container,
  GroupWrapper,
  Header,
  NewCommentButton,
  PhotoButtonWrapper,
  Row,
  TextAreaDescription,
} from './styles';
import { Text } from 'styles/text';
import { UploadIcon } from '../../styles';
import Button from 'components/Button';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { useDispatch } from 'react-redux';
import { getSqlResponse } from 'services/dataset';
import { EnumRoutes, OccurrenceStatus } from 'store/types';
import { UploadModalParams } from 'components/Widgets/DynamicForm/types';
import useUser from 'hooks/use-user';
import UpdatesOccurrenceModal, {
  OccurrenceUpdatesParams,
} from '../../../UpdatesOccurrence';
import { toDateTimeBR } from 'utils/date';
import { statusOptions } from '../..';
import showAlert from 'components/Alert';
import { update as updateComment } from 'services/comment';

export interface OccurrenceUpdates {
  idComentario: number;
  idContrato: number;
  idSubProjeto: number;
  tipo: string;
  id: number;
  origem: string;
  dtCriacao: string;
  comentario: string;
  idUsuario: number;
  dtUltimaAlteracao: string;
  alteracao: string;
  idChecklist: number;
  checklistKey: string;
  flagStatus: number;
  idResposta: number;
  idCategoria: number;
  uuid: string;
  uuidReferencia: string;
  keyReferencia: string;
  status: number;
  uuidChecklist: string;
  usuario: string;
  qtdFotos: number;
  idOcorrencia: number;
  idEmpresa: number;
}

interface UpdatesOccurrenceProps {
  uuidReference: string;
  idContrato: number;
  idOcorrencia: number;
  statusOccurrence: number;
  getReload(): void;
}

export default function UpdatesOccurrence({
  uuidReference,
  idContrato,
  idOcorrencia,
  statusOccurrence,
  getReload,
}: UpdatesOccurrenceProps) {
  const [formDataOccurrence, setFormDataOccurrence] = useState(
    [] as OccurrenceUpdates[],
  );
  const dispatch = useDispatch();
  const user = useUser();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const data = await getSqlResponse<OccurrenceUpdates[]>(2589, {
      idContrato,
      referenciaUuid: uuidReference,
      keyReferencia: idOcorrencia,
      tipo: 'OCORRENCIA',
    });

    setFormDataOccurrence(data);
  }

  function onReload() {
    getReload();
    getData();
  }

  function handleShowUploadModal(uuidComment: string) {
    dispatch(
      showModal<UploadModalParams>(ModalName.MODAL_UPLOAD, {
        idContrato: idContrato,
        idDatasetImages: 2593,
        uuidReferencia: uuidComment,
        allowDisableImage: false,
        id: 1,
        tipo: 'RESPOSTA_OCORRENCIA',
        idUsuario: user.idUsuario!,
        idPerfilModulo: user.idPerfilModulo,
        typeOfPage: EnumRoutes.UPLOAD_IMAGE,
        onReloadPageForm: getData,
        title: 'Imagens da ocorrência',
      }),
    );
  }

  function handleUpdatesOccurrenceModal(occurrence?: OccurrenceUpdates) {
    dispatch(
      showModal<OccurrenceUpdatesParams>(ModalName.UPDATES_OCCURRENCE_MODAL, {
        occurrence,
        onReload: onReload,
      }),
    );
  }

  function getCurrentStatusOption(status: number) {
    const statusOccurrence = statusOptions.filter(
      state => state.value === status,
    );

    return statusOccurrence.length ? (
      <Text>{statusOccurrence[0].label}</Text>
    ) : (
      <Text>-</Text>
    );
  }

  function handleRemove(occurrence: OccurrenceUpdates) {
    const confirm = async () => {
      await updateComment({ ...occurrence, flagStatus: 0 });
      onReload();
    };

    showAlert({
      title: 'Atenção!',
      subtitle: 'Deseja excluir esta resposta ?',
      actions: [
        {
          text: 'Não',
        },
        {
          text: 'Sim',
          onClick: confirm,
        },
      ],
    });

    return;
  }

  return (
    <Container>
      <Header>
        <Label>Atualização</Label>
        {statusOccurrence !== OccurrenceStatus.FINALIZADO && (
          <>
            <NewCommentButton
              text="Responder"
              onClick={() =>
                handleUpdatesOccurrenceModal({
                  idContrato,
                  uuidReferencia: uuidReference,
                  idOcorrencia,
                  status: statusOccurrence,
                })
              }
              primary
              outline
              small
            />
          </>
        )}
      </Header>

      {formDataOccurrence.length
        ? formDataOccurrence.map(occurrence => {
            return (
              <Card key={occurrence.idComentario}>
                <Row>
                  <GroupWrapper>
                    <LabelInfo>Usuário:</LabelInfo>
                    <Text>{occurrence.usuario}</Text>
                  </GroupWrapper>

                  <GroupWrapper>
                    <LabelInfo>Data:</LabelInfo>
                    <Text>{toDateTimeBR(occurrence.dtCriacao)}</Text>
                  </GroupWrapper>

                  <GroupWrapper>
                    <LabelInfo>Status:</LabelInfo>
                    {getCurrentStatusOption(occurrence.status)}
                  </GroupWrapper>

                  <PhotoButtonWrapper>
                    <Button
                      icon={<UploadIcon />}
                      text={`Ver fotos (${occurrence.qtdFotos})`}
                      onClick={() => handleShowUploadModal(occurrence.uuid)}
                      disabled={occurrence.id && occurrence.tipo}
                    />
                  </PhotoButtonWrapper>
                </Row>

                <Row>
                  <GroupWrapper width="80%">
                    <LabelInfo>Descrição:</LabelInfo>
                    <TextAreaDescription
                      name="descricao"
                      value={occurrence.comentario}
                    />
                  </GroupWrapper>

                  {occurrence.idUsuario === user.idUsuario &&
                  statusOccurrence !== OccurrenceStatus.FINALIZADO ? (
                    <GroupWrapper gap="1rem">
                      <Button
                        text="Editar"
                        onClick={() => {
                          handleUpdatesOccurrenceModal(occurrence);
                        }}
                      />

                      <Button
                        text="Excluir"
                        danger
                        onClick={() => handleRemove(occurrence)}
                      />
                    </GroupWrapper>
                  ) : (
                    <div />
                  )}
                </Row>
              </Card>
            );
          })
        : null}
      <UpdatesOccurrenceModal />
    </Container>
  );
}
