import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSqlResponse } from '../../../services/dataset';
import { showModal } from '../../../store/modal/actions';
import { ModalName } from '../../../store/modal/types';
import { RootState } from '../../../store/rootReducer';
import { OptionsType, Supplier as SupplierType } from '../../../store/types';
import { Label } from '../../../styles/form';
import { CreatableSelect } from '../../ReactSelectVirtualized';
import CustomRow from './components/CustomRow';
import { SupplierCreatableSelectContext } from './context';

export interface Props {
  companyId?: number;
  datasetId?: number;
  supplier: OptionsType | OptionsType[] | null;
  setSupplier(option: OptionsType | OptionsType[]): void;
  isDisabled?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  flexBottomHeight?: boolean;
  label?: string;
}

export default function SupplierCreatableSelect(props: Props) {
  const [options, setOptions] = useState<OptionsType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<
    OptionsType | OptionsType[] | null
  >(null);
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const {
    datasetId,
    supplier,
    isDisabled,
    isClearable,
    isMulti,
    setSupplier,
    companyId,
    label,
  } = props;

  useEffect(() => {
    setSelectedOption(supplier as OptionsType);
  }, [supplier]);

  useEffect(() => {
    getData();
  }, [user, companyId]);

  async function getData() {
    if (!user || isLoading) {
      return;
    }

    setIsLoading(true);

    const fetchedOptions = await getSqlResponse<OptionsType[]>(
      datasetId || 512,
      {
        idEmpresa: companyId || user.idEmpresa,
      },
    );

    const normalizedOptionsLabelAsString = fetchedOptions.map(opt => ({
      ...opt,
      label: opt.label?.toString() || '',
    }));

    setOptions(normalizedOptionsLabelAsString);
    setIsLoading(false);
  }

  function handleSetNewSupplier(newSupplier: SupplierType) {
    const newOption: OptionsType = {
      value: newSupplier.idFornecedor!,
      label: `${newSupplier.cnpj} ${newSupplier.nomeFantasia ||
        newSupplier.razaoSocial}`,
      uuidFornecedor: newSupplier?.uuid,
    };

    const newSuppliers = isMulti
      ? [...((selectedOption as OptionsType[]) || []), newOption]
      : newOption;

    setSupplier(newSuppliers);
    setOptions([...options, newOption]);
  }

  function handleChange(option: OptionsType) {
    if (option && option.__isNew__) {
      return dispatch(
        showModal(ModalName.FORM_SUPPLIER, {
          supplier: { razaoSocial: option.value },
          onReloadCB: handleSetNewSupplier,
        }),
      );
    }

    setSelectedOption(option);
    setSupplier(option);
  }

  return (
    <SupplierCreatableSelectContext.Provider
      value={{
        getData,
        handleChange,
      }}
    >
      <>
        <Label>{label || 'Fornecedor'}</Label>
        <CreatableSelect
          isMulti={isMulti}
          onChange={handleChange}
          value={selectedOption}
          options={options}
          isLoading={isLoading}
          placeholder="Selecione ou crie um fornecedor"
          components={{ Option: CustomRow }}
          isDisabled={isDisabled}
          isClearable={isClearable}
          minHeight={'auto'}
        />
      </>
    </SupplierCreatableSelectContext.Provider>
  );
}
