/* eslint-disable no-extra-boolean-cast */
import { FormApproval, OptionsType, TemplateForm } from 'store/types';
import { UserState } from 'store/user/types';
import { State, Field } from './types';
import { removeDuplicate } from 'utils/common';

interface FieldAllowsProps extends Field {
  allowEditRole?: number[];
  notAllowEditRole?: number[];
}

export interface SuppliersAndJobs {
  uuid?: string;
  fornecedor: string;
  funcao: string;
  quantidade: number;
}

export function getFieldsAllowedToCopy(
  fieldsData: State,
  template: TemplateForm,
): State {
  const formattedFieldsData = { ...fieldsData };
  const {
    template: { fields: fieldsTemplate },
  } = template;

  fieldsTemplate.forEach(fieldTemplate => {
    fieldTemplate.fields.forEach(field => {
      const deleteAll = Boolean(field.name && field.disableCopy);

      if (deleteAll) delete formattedFieldsData[field.name];
    });
  });

  return formattedFieldsData;
}

export function checkUserEditPermission(
  approvalData: FormApproval | null,
  field: FieldAllowsProps,
  user: UserState,
  fieldsAllowProps: string[],
  isDisabled?: boolean,
) {
  if (isDisabled) {
    return true;
  }

  if (!!approvalData?.usuarioAprovacao) {
    return true;
  }

  if (fieldsAllowProps?.length) {
    const allowProfiles = field.allowEditRole || [];
    const notAllowProfiles = field.notAllowEditRole || [];

    const verifyAllowProfiles = allowProfiles.some(
      idProfile => idProfile === user.idPerfilModulo,
    );

    const verifyNotAllowProfiles = notAllowProfiles.some(
      idProfile => idProfile === user.idPerfilModulo,
    );

    if (fieldsAllowProps?.length === 2) {
      if (verifyAllowProfiles) {
        return false;
      }

      if (verifyNotAllowProfiles) {
        return true;
      }

      return false;
    }

    if (fieldsAllowProps.includes('allowEditRole')) {
      return !verifyAllowProfiles;
    } else if (fieldsAllowProps.includes('notAllowEditRole')) {
      return verifyNotAllowProfiles;
    }

    return false;
  }
}

function verifyAllowOrNotProps(element: Field) {
  if (Object.keys(element).some(field => field === 'allowEditRole')) {
    return 'allowEditRole';
  }

  if (Object.keys(element).some(field => field === 'notAllowEditRole')) {
    return 'notAllowEditRole';
  }

  return 'withoutEditRole';
}

function verifyProfileInField(field: Field[]) {
  const verifyField = field.map(element => {
    if (element.fields) {
      const responseElementFields = element.fields.map(elementField => {
        return verifyAllowOrNotProps(elementField);
      });

      return responseElementFields;
    } else {
      return verifyAllowOrNotProps(element);
    }
  });

  return verifyField;
}

function convertArrayToString(resultArray: Array<string>) {
  if (!resultArray) return;

  return resultArray.reduce((prev, curr) => prev + ',' + curr);
}

function clearArray(resultArray: Array<string> | Array<Array<string>>) {
  const createOneArray = resultArray.map(result => {
    if (typeof result[0] === 'object') {
      return convertArrayToString(result[0]);
    }

    return convertArrayToString(result);
  });

  const newResultArray = createOneArray.toString().split(',');

  const removeArrayNullResults = newResultArray.filter(
    result => result !== 'withoutEditRole',
  );

  return removeArrayNullResults;
}

function parseName(value: string | number) {
  return value
    ?.toString()
    .trim()
    .toLowerCase();
}

export function verifyFields(objectFields: Field[]) {
  const haveProfilesArray: string[] | string[][] = objectFields.map(
    ({ fields }) => {
      return verifyProfileInField(fields);
    },
  );

  const clearHaveProfilesArray = clearArray(haveProfilesArray);

  const setHaveProfiles = new Set(clearHaveProfilesArray);

  const filterProfilesAllows = [...setHaveProfiles];

  return filterProfilesAllows;
}

export function normalizedInputSelect(rowData: State, tableField: Field) {
  if (!rowData[tableField.name]) {
    return '';
  }

  if (typeof rowData[tableField.name] === 'string') {
    return JSON.parse(rowData[tableField.name]).label;
  }

  return rowData[tableField.name].label;
}

export function getJobsToCreate(
  savedJobsList: OptionsType[],
  newSuppliersAndJobs: SuppliersAndJobs[],
): SuppliersAndJobs[] {
  const savedJobListNames = savedJobsList.map(job => parseName(job.label));

  const newJobsToSave = newSuppliersAndJobs.filter(
    ({ funcao }) => !savedJobListNames.includes(parseName(funcao)),
  );

  return removeDuplicate(newJobsToSave, 'funcao');
}

export function getSupplierToCreate(
  savedSuppliersList: OptionsType[],
  newSuppliersAndJobs: SuppliersAndJobs[],
): SuppliersAndJobs[] {
  const savedSupplierListNames = savedSuppliersList.map(supplier =>
    parseName(supplier.label),
  );

  const newSuppliersToSave = newSuppliersAndJobs.filter(
    ({ fornecedor }) => !savedSupplierListNames.includes(parseName(fornecedor)),
  );

  return removeDuplicate(newSuppliersToSave, 'fornecedor');
}

export function normalizeExcelHours(time: number | string) {
  if (typeof time !== 'number') {
    return time;
  }

  const [hour, minute] = (time * 24).toFixed(2).split('.');

  return `${hour}:${minute}`;
}
