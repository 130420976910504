import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import InputFile, { FileList } from '../../../../components/InputFile';
import Select, { CreatableSelect } from '../../../../components/ReactSelect';
import TextArea from '../../../../components/TextArea';
import {
  create as createRelatedGroup,
  getAll as getAllRelatedFileGroup,
  remove,
} from '../../../../services/related_file_group';
import { showModal } from '../../../../store/modal/actions';
import { ModalName } from '../../../../store/modal/types';
import { File, OptionsType, RelatedFileGroup } from '../../../../store/types';
import { Label } from '../../../../styles/form';
import { useFileGroup } from '../../contexts/FileGroup';
import {
  ButtonsWrapper,
  Container,
  FormContainer,
  GroupWrapper,
} from './styles';

interface Props {
  companyId: number | null;
  file: File | null;
  categoryOptions: OptionsType[];
  onSave: (fileData: File, newFile: globalThis.File | null) => void;
}

export default function Form(props: Props): ReactElement {
  const [isSaving, setIsSavig] = useState(false);

  const [category, setCategory] = useState<OptionsType | null>(null);
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');

  const [fileToUpload, setFileToUpload] = useState<globalThis.File | null>(
    null,
  );
  const [selectedFileGroup, setSelectedFileGroups] = useState<OptionsType[]>(
    [],
  );

  const relatedFileGroup = useRef<RelatedFileGroup[]>([]);

  const { fileGroupOptions } = useFileGroup();

  const dispatch = useDispatch();

  const { companyId, file, categoryOptions } = props;

  useEffect(() => {
    setName('');
    setComment('');
    setCategory(null);
    setFileToUpload(null);
    setSelectedFileGroups([]);

    relatedFileGroup.current = [];

    if (file) {
      setName(file.nome);
      setComment(file.comentario.comentario || '');

      const categoryName =
        file.categoria.categoria === 'Arquivos não Classificados'
          ? ''
          : file.categoria.categoria;
      setCategory({ label: categoryName, value: file.categoria.id });

      if (companyId) {
        getRelatedFileGroup();
      }
    }
  }, [file]);

  async function getRelatedFileGroup() {
    const res = await getAllRelatedFileGroup(file!.id);

    const filteredRelatedFiles = fileGroupOptions.filter(
      ({ value }) =>
        res.findIndex(({ idGrupoArquivo }) => value === idGrupoArquivo) >= 0,
    );

    setSelectedFileGroups(filteredRelatedFiles || []);
    relatedFileGroup.current = res;
  }

  async function handleSave() {
    const { onSave } = props;

    setIsSavig(true);

    if (companyId) {
      await onRelateFiles();
    }

    await onSave(
      {
        ...file,
        nome: name,
        comentario: {
          idComentario: file!.comentario && file!.comentario.idComentario,
          comentario: comment,
        },
        categoria: {
          categoria: category!.label.toString(),
          id: category!.__isNew__ ? 0 : (category!.value as number),
        },
      } as File,
      fileToUpload,
    );

    setIsSavig(false);
  }

  async function onRelateFiles() {
    const toCreate: OptionsType[] = [];
    const toRemove: RelatedFileGroup[] = [];

    /* Get files to relate with file and group */
    selectedFileGroup.forEach(group => {
      if (
        relatedFileGroup.current.findIndex(
          ({ idGrupoArquivo }) => idGrupoArquivo === group.value,
        ) === -1
      ) {
        toCreate.push(group);
      }
    });

    /* Get files to remove from relationship */
    relatedFileGroup.current.forEach(group => {
      if (
        selectedFileGroup.findIndex(
          ({ value }) => value === group.idGrupoArquivo,
        ) === -1
      ) {
        toRemove.push(group);
      }
    });

    await Promise.all(
      toCreate.map(related =>
        createRelatedGroup({
          id: 0,
          idEmpresa: companyId!,
          idGrupoArquivo: Number(related.value),
          idArquivo: file!.id,
        }),
      ),
    );

    if (toRemove.length) {
      await remove(toRemove);
    }

    getRelatedFileGroup();
  }

  function handleSelectGroup(options: OptionsType[]) {
    setSelectedFileGroups(options || []);
  }

  function handleSelectFile(files: globalThis.File[]) {
    setFileToUpload(files[0]);
  }

  function handleShowFileGroupModal() {
    dispatch(showModal(ModalName.FILE_GROUP));
  }

  function renderGroupFileField(): ReactElement | null {
    if (!companyId) {
      return null;
    }
    return (
      <div>
        <Label htmlFor="selectGroup">Grupo de arquivo</Label>
        <GroupWrapper>
          <Select
            id="selectGroup"
            isMulti
            value={selectedFileGroup}
            options={fileGroupOptions}
            onChange={handleSelectGroup}
            minHeight="4rem"
          />

          <Button text="+" onClick={handleShowFileGroupModal} />
        </GroupWrapper>
      </div>
    );
  }

  if (!file) {
    return <></>;
  }

  return (
    <Container>
      <FormContainer>
        {renderGroupFileField()}

        <div>
          <Label htmlFor="inputName">Nome</Label>
          <Input
            id="inputName"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>

        <div>
          <Label htmlFor="selectCategory">Categoria</Label>
          <CreatableSelect
            id="selectCategory"
            value={category}
            options={categoryOptions}
            onChange={e => setCategory(e)}
            formatCreateLabel={() => <span> Criar nova categoria </span>}
          />
        </div>

        <div>
          <Label htmlFor="comment">Comentário</Label>
          <TextArea
            minRows={9}
            id="comment"
            placeholder="Adicione um comentário..."
            value={comment}
            onChange={e => setComment(e.target.value)}
          />
        </div>

        <div>
          <InputFile
            containerStyle={{ width: '18rem' }}
            accept={`.${file?.tipoArquivo}`}
            onSelectFiles={handleSelectFile}
            text="Upload nova versão"
          />
        </div>

        <div>
          <FileList
            files={fileToUpload ? [fileToUpload] : []}
            onRemoveFile={() => setFileToUpload(null)}
          />
        </div>
        <ButtonsWrapper>
          <Button
            primary
            isLoading={isSaving}
            text="Salvar"
            onClick={handleSave}
          />
        </ButtonsWrapper>
      </FormContainer>
    </Container>
  );
}
