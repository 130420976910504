/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import useUser from 'hooks/use-user';
import 'moment/locale/pt-br';
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import ReactTooltip from 'react-tooltip';
import showAlert from '../../Alert';
import { IconGoBack } from '../../HeaderAction/styles';
import { getSingleResponse } from '../../../services/dataset';
import { create, edit, getById } from '../../../services/form';
import { getByType } from '../../../services/form_template';
import { setDatasetID } from '../../../store/contract/actions';
import { closeModal, showModal } from '../../../store/modal/actions';
import { ModalName } from '../../../store/modal/types';
import {
  EnumActions,
  EnumProfile,
  Form,
  FormApproval,
  TemplateForm,
} from '../../../store/types';
import { DynamicFormContext } from './context';
import Files from './components/Files';
import InputCreatableSelect from './components/InputCreatableSelect';
import InputDate from './components/InputDate';
import InputMask from './components/InputMask';
import InputSelect from './components/InputSelect';
import InputTextArea from './components/InputTextArea';
import Link from './components/Link';
import Photos from './components/Photos';
import Table from './components/Table';
import HeaderTitle from './components/Title';
import Treeview from './components/Treeview';
import {
  Col,
  Container,
  WrapperContainer,
  Row,
  RowTitle,
  TitleWrapper,
  HeaderContent,
  ActionSection,
} from './styles';
import TableModal from './components/TableModal';
import TableDataset from './components/TableDataset';
import UploadMultipleTab from './components/UploadMultipleTab';
import StaticImage from './components/StaticImage';
import WithPermission from '../../WithPermission';

import {
  Field,
  State,
  GlobalState,
  DynamicFormModalParams,
  DynamicFormRef,
} from './types';
import InputUpload from './components/InputUpload';
import PdfViewer from 'components/Modal/PdfViewer';
import PdfButton from './components/Renders/PdfButton';
import DeleteButton from './components/Renders/DeleteButton';
import ApprovalButton from './components/Renders/ApprovalButton';
import CopyButton from './components/Renders/CopyButton';
import ClearButton from './components/Renders/ClearButton';
import SaveButton from './components/Renders/SaveButton';
import SubHeader from './components/Renders/SubHeader';
import ActionContainer from './components/ActionContainer';
import MultipleApproval from 'components/Widgets/MultipleApproval';
import { ApprovalLevel } from '../MultipleApproval/types';
import TableUser from './components/TableUser';
import TableOccurrence from './components/TableOccurrence';

import { verifyFields } from './utils';
import { isObjectEmpty } from 'utils/common';
import SendEmailButton from './components/Renders/SendEmailButton';
import ListOccurrenceDynamicForm from './components/TableOccurrence2/components/ListOccurrenceDynamicForm';
import TableOccurrence2 from './components/TableOccurrence2';
import FormNewItemModal from './components/FormNewItemModal';
import SimpleTableComponent from './components/SimpleTableComponent';
import Chart from './components/Chart';
import CreateUserModal from 'pages/UsersManagement/components/CreateUserModal';
import InputCurrencyComponent from './components/InputCurrencyComponent';
import SelectEmailsModal from 'components/Modal/SelectEmails';
import InputCreatableSupplier from './components/InputCreatableSupplier';

interface ParamsProps extends DynamicFormModalParams {
  dia?: string;
  formType?: string;
  getRows?: () => null;
  idFormulario?: number | undefined;
  parametersToSave?: {
    data: string;
    id_formulario: number;
  };
  titleDatasetId?: number | undefined;
  idContrato: number | undefined;
  referencia?: number | string | undefined;
  widgetStyle?: boolean;
  onAfterSave?(form: Form): any;
  onValidate?(): void;
  onBeforeSave?(): Promise<void>;
  referenciaTemplate?: number | string;
  setIsDynamicVisible?(visible: boolean): void;
  isDisabled?: boolean;
  idTemplate?: number;
  isHiddenButtonSave?: boolean;
  isHiddenButtonClear?: boolean;
  children?: ReactElement;
}

interface DynamicFormProps extends ParamsProps {
  isOpen: boolean;
}

/* eslint-disable react/display-name */
const DynamicForm = forwardRef<DynamicFormRef, DynamicFormProps>(
  (props, ref) => {
    const {
      isOpen,
      parametersToSave,
      widgetStyle,
      idContrato: contractIdFromProps,
      isDisabled,
      children,
    } = props;

    const [globalState, setGlobalState] = useState<GlobalState | null>(null);

    const [requiredFields, setRequiredFields] = useState<State>({});
    const [approvalData, setApprovalData] = useState<FormApproval | null>(null);

    const [contractId, setContractId] = useState<number>();

    const [fieldsAllowProps, setFieldsAllowProps] = useState<string[]>([]);

    const newFormData = useRef<State>({});
    const reportData = useRef<State>({});

    const user = useUser();

    const dispatch = useDispatch();
    const { addToast } = useToasts();

    useEffect(() => {
      if (isOpen) {
        loadPage();
      } else {
        clearForm();
      }
    }, [isOpen]);

    useEffect(() => {
      if (!globalState) {
        return;
      }

      const { defaultFormData } = globalState;

      setContractId(defaultFormData?.idContrato || contractIdFromProps);
    }, [globalState, contractIdFromProps]);

    useEffect(() => {
      verifyFieldsInTemplate();
    }, [globalState]);

    async function loadPage(idForumarioReference?: number) {
      clearForm();

      dispatch(showModal(ModalName.LOADING, { text: 'Carregando formulário' }));
      const { idFormulario, formType, parametersToSave, idTemplate } = props;
      let formTypeTmp = formType;

      const newGlobalState = {} as GlobalState;

      /* Fecth previous form data */
      if (idFormulario || idForumarioReference) {
        const previousData = await getById(
          idFormulario || idForumarioReference!,
        );

        await getApprovalData(
          idFormulario || idForumarioReference!,
          previousData.tipo,
        );
        newGlobalState.defaultFormData = previousData;

        formTypeTmp = previousData.tipo;
        newFormData.current = previousData.form;
      } else if (parametersToSave) {
        newFormData.current = { ...parametersToSave };
      }

      /* Get template */
      const { referenciaTemplate, setIsDynamicVisible } = props;

      let template;

      if (idTemplate || newGlobalState.defaultFormData?.idTemplate) {
        template = await getSingleResponse(3081, {
          idTemplate: idTemplate || newGlobalState.defaultFormData?.idTemplate,
        });

        template = {
          ...template,
          templatePortal: JSON.parse(template.templatePortal),
        };
      } else if (referenciaTemplate) {
        template = await getSingleResponse<TemplateForm>(
          1061,
          {
            tipo: formTypeTmp!,
            idEmpresa: user.idEmpresa!,
            referencia: referenciaTemplate,
          },
          {
            deep: false,
          },
        );

        if (!template) {
          dispatch(closeModal(ModalName.LOADING));

          return;
        }

        template = {
          ...template,
          templatePortal: JSON.parse(template.templatePortal),
        };
      } else {
        template = await getByType(formTypeTmp!, user.idEmpresa!);
      }

      const paramsGlobalState = parametersToSave || {};

      paramsGlobalState.idFormulario = idFormulario || idForumarioReference!;

      newGlobalState.datasetParams = paramsGlobalState;
      newGlobalState.templateConfig = template;

      setGlobalState(newGlobalState);

      if (
        setIsDynamicVisible &&
        !isObjectEmpty(newGlobalState?.templateConfig)
      ) {
        setIsDynamicVisible(true);
      }

      if (
        !idFormulario &&
        !idForumarioReference &&
        template?.templatePortal?.contractDatasetId
      ) {
        dispatch(setDatasetID(template.templatePortal.contractDatasetId));
      }

      dispatch(closeModal(ModalName.LOADING));
    }

    async function getApprovalData(idFormulario: number, tipo: string) {
      const approvalResponse = await getSingleResponse<FormApproval>(340, {
        idFormulario,
        tipo,
      });

      setApprovalData(approvalResponse);
    }

    function clearForm() {
      setGlobalState(null);

      newFormData.current = {};
    }

    function checkRequiredFields() {
      const required = {};

      const checkRequiredBySiblings = (field: Field): string | null => {
        if (field.requiredIF && field.requiredIF.length) {
          for (const requiredSiblingField of field.requiredIF) {
            if (!newFormData.current[requiredSiblingField]) {
              return field.name;
            }
          }
        }

        return null;
      };

      const { templateConfig } = globalState!;

      templateConfig!.templatePortal?.fields.forEach(config => {
        config.fields.forEach(field => {
          if (
            [
              'table',
              'table-occurrence',
              'table-user',
              'table-dataset',
              'photo',
              'file',
              'link',
            ].includes(field.type)
          ) {
            return;
          }

          if (
            ['date', 'time', 'datetime'].includes(field.type) &&
            (newFormData.current[field.name] === 'Invalid date' ||
              newFormData.current[field.name] === 'Data inválida')
          ) {
            required[field.name] = true;

            return;
          }

          if (
            field.required &&
            newFormData.current[field.name] !== 0 &&
            !newFormData.current[field.name]
          ) {
            required[field.name] = true;
          }

          const requiredBySibling = checkRequiredBySiblings(field);

          if (requiredBySibling) {
            required[requiredBySibling] = true;
          }
        });
      });

      setRequiredFields(required);

      if (Object.keys(required).length > 0) {
        return true;
      }

      return false;
    }

    function validateForm() {
      if (approvalData?.usuarioAprovacao) {
        throw new Error('Formularío já aprovado.');
      }

      if (checkRequiredFields()) {
        throw new Error('Existem campos obrigatórios não preenchidos');
      }
    }

    useImperativeHandle(ref, () => ({
      onSave: handleSave,
    }));

    async function handleSave() {
      const { onValidate } = props;

      try {
        if (onValidate) {
          onValidate();
        }

        validateForm();
      } catch (error) {
        addToast(error.message, {
          appearance: 'warning',
        });

        dispatch(closeModal(ModalName.LOADING));

        throw new Error('');
      }

      const { onBeforeSave } = props;

      try {
        if (onBeforeSave) {
          await onBeforeSave();
        }
      } catch (error) {
        return;
      }

      dispatch(showModal(ModalName.LOADING, { text: 'Salvando formulário' }));

      const { formType, referencia } = props;
      const { templateConfig, defaultFormData, datasetParams } = globalState!;

      let newFormSaved: Form | null = defaultFormData;

      const { ...formDataToSave } = newFormData.current;

      if (!defaultFormData?.idFormulario) {
        const newForm: Form = {
          idContrato: contractId as number,
          idEmpresa: user.idEmpresa!,
          idUsuario: user.idUsuario!,
          idTemplate: templateConfig!.id,
          idEmpreendimento: 0,
          idFormulario: 0,
          form: formDataToSave,
          tipo: formType || templateConfig.tipo,
          referencia: referencia as number,
        };

        newFormSaved = await create(newForm);
        newFormSaved = { ...newFormSaved, form: formDataToSave! };

        await getApprovalData(newFormSaved.idFormulario, newFormSaved.tipo);
      } else {
        newFormSaved = { ...defaultFormData, form: formDataToSave! };

        await edit({
          idFormulario: defaultFormData.idFormulario,
          form: formDataToSave,
        });
      }

      setGlobalState({
        ...globalState!,
        defaultFormData: newFormSaved,
        datasetParams: {
          ...datasetParams,
          idFormulario: newFormSaved!.idFormulario,
        },
      });

      addToast('Formulário salvo com sucesso', { appearance: 'success' });

      const { onAfterSave } = props;

      if (onAfterSave) {
        onAfterSave(newFormSaved);
      }

      dispatch(closeModal(ModalName.LOADING));
    }

    function handleSetNewFormData(field: string, value: any) {
      newFormData.current[field] = value;
    }

    function onCloseForm() {
      dispatch(closeModal(ModalName.DYNAMIC_FORM));
      clearForm();

      const { getRows } = props;

      if (getRows) {
        getRows();
      }
    }

    function handleClose() {
      showAlert({
        title: 'Atenção!',
        subtitle: 'Deseja sair do formulário?',
        actions: [
          {
            text: 'Não',
          },
          {
            text: 'Sim',
            onClick: onCloseForm,
          },
        ],
      });
    }

    function renderRow(config: Field): ReactElement {
      return (
        <div className="row-block" key={config.id}>
          {config.label && (
            <TitleWrapper className="row-block__title-container">
              <RowTitle dangerouslySetInnerHTML={{ __html: config.label }} />
            </TitleWrapper>
          )}
          {renderFields(config)}
        </div>
      );
    }

    function renderFields(config: Field) {
      const renderInput = (field: Field) => {
        const previousAnswers = globalState?.defaultFormData?.form || {};
        const { datasetParams } = globalState || {};

        if (!field.type || field.type === 'number') {
          return (
            <InputMask
              field={field}
              isRequired={requiredFields[field.name]}
              defaultValue={previousAnswers[field.name]}
              onChange={handleSetNewFormData}
              isDisabled={isDisabled}
            />
          );
        }

        if (field.type === 'input-currency') {
          return (
            <InputCurrencyComponent
              field={field}
              isRequired={requiredFields[field.name]}
              defaultValue={previousAnswers[field.name]}
              onChange={handleSetNewFormData}
              isDisabled={isDisabled}
            />
          );
        }

        if (field.type === 'select') {
          return (
            <InputSelect
              field={field}
              isRequired={requiredFields[field.name]}
              defaultValue={previousAnswers[field.name]}
              onChange={handleSetNewFormData}
              isDisabled={isDisabled}
            />
          );
        }

        if (field.type === 'creatable-select') {
          return (
            <InputCreatableSelect
              field={field}
              isRequired={requiredFields[field.name]}
              defaultValue={previousAnswers[field.name]}
              onChange={handleSetNewFormData}
              isDisabled={isDisabled}
            />
          );
        }

        if (field.type === 'creatable-supplier') {
          return (
            <InputCreatableSupplier
              field={field}
              isRequired={requiredFields[field.name]}
              defaultValue={previousAnswers[field.name]}
              onChange={handleSetNewFormData}
              isDisabled={isDisabled}
            />
          );
        }

        if (field.type === 'upload-table') {
          return (
            <UploadMultipleTab
              field={field}
              onChange={handleSetNewFormData}
              isDisabled={isDisabled}
            />
          );
        }

        if (
          field.type === 'date' ||
          field.type === 'time' ||
          field.type === 'datetime'
        ) {
          return (
            <InputDate
              field={field}
              isRequired={requiredFields[field.name]}
              defaultValue={previousAnswers[field.name]}
              onChange={handleSetNewFormData}
              isDisabled={isDisabled}
            />
          );
        }

        if (field.type === 'link') {
          return <Link field={field} />;
        }

        if (field.type === 'static-image') {
          return <StaticImage field={field} />;
        }

        if (field.type === 'table') {
          return (
            <Table
              key={field.name}
              field={field}
              setFormAnswer={handleSetNewFormData}
              isDisabled={isDisabled}
            />
          );
        }

        if (field.type === 'table-occurrence') {
          return (
            <TableOccurrence
              key={field.name}
              field={field}
              isDisabled={isDisabled}
            />
          );
        }

        if (field.type === 'table-occurrence2') {
          return (
            <TableOccurrence2
              key={field.name}
              field={field}
              onLoadDynamicFormPage={loadPage}
              isDisabled={isDisabled}
            />
          );
        }

        if (field.type === 'table-user') {
          return (
            <TableUser
              key={field.name}
              field={field}
              setFormAnswer={handleSetNewFormData}
              isDisabled={isDisabled}
            />
          );
        }

        if (field.type === 'table-dataset') {
          return <TableDataset key={field.name} field={field} />;
        }

        if (field.type === 'treeview') {
          return (
            <Treeview
              key={field.name}
              field={field}
              dateIniParam={datasetParams?.data}
              defaultValue={previousAnswers[field.name]}
              setFormAnswer={handleSetNewFormData}
              isDisabled={isDisabled}
            />
          );
        }

        if (field.type === 'photo') {
          return (
            <Photos field={field} key={field.id} isDisabled={isDisabled} />
          );
        }

        if (field.type === 'file') {
          return <Files field={field} key={field.id} isDisabled={isDisabled} />;
        }

        if (field.type === 'input-upload') {
          return (
            <InputUpload
              field={field}
              uuid={field.name}
              isDisabled={isDisabled}
            />
          );
        }

        if (field.type === 'simple-table') {
          return (
            <SimpleTableComponent
              field={field}
              setFormAnswer={handleSetNewFormData}
            />
          );
        }

        if (field.type === 'chart') {
          return <Chart field={field} datasetParams={datasetParams} />;
        }

        return (
          <InputTextArea
            field={field}
            isRequired={requiredFields[field.name]}
            defaultValue={previousAnswers[field.name]}
            onChange={handleSetNewFormData}
            isDisabled={isDisabled}
          />
        );
      };

      return (
        <Row className="row-block__fields-row" style={config.styles}>
          {config.fields.map(field => (
            <Col
              className={`field-container field-type--${field.type ||
                'number'}`}
              key={field.name}
              size={field.size || 100}
            >
              {renderInput(field)}
            </Col>
          ))}
        </Row>
      );
    }

    function renderMultipleApproval() {
      if (!globalState) {
        return null;
      }

      const { templateConfig, defaultFormData } = globalState;

      if (
        !templateConfig?.templatePortal?.allowMultipleApproval ||
        !defaultFormData ||
        !defaultFormData?.idFormulario
      ) {
        return null;
      }

      const onAfterLoad = (approvalLevels: ApprovalLevel[]) => {
        reportData.current = {
          ...reportData?.current,
          multipleApproval: approvalLevels,
        };

        getApprovalData(defaultFormData.idFormulario, defaultFormData.tipo);
      };

      const handleBeforeApproval = async () => {
        if (checkRequiredFields()) {
          addToast('Existem campos obrigatórios não preenchidos', {
            appearance: 'warning',
          });

          dispatch(closeModal(ModalName.LOADING));

          return false;
        }

        const formDataToSave = newFormData.current;

        delete formDataToSave.xlsFile; //TODO: PODE REMOVER NO WIDGET

        await edit({
          idFormulario: defaultFormData.idFormulario,
          form: formDataToSave,
        });

        return true;
      };

      return (
        <MultipleApproval
          idDataset={774}
          idTipo={121}
          idContrato={defaultFormData.idContrato}
          idReferencia={defaultFormData.idFormulario}
          origem={defaultFormData.tipo}
          onAfterLoad={onAfterLoad}
          onBeforeApproval={handleBeforeApproval}
        />
      );
    }

    function render(): ReactElement[] | null {
      if (
        !isOpen ||
        !globalState?.templateConfig ||
        (!contractId && contractId !== 0)
      ) {
        return null;
      }

      if (globalState.templateConfig.templatePortal?.fields) {
        return globalState.templateConfig.templatePortal.fields.map(renderRow);
      }
    }

    function verifyFieldsInTemplate() {
      if (!globalState?.templateConfig?.templatePortal) return;
      const allFields = globalState?.templateConfig?.templatePortal?.fields;

      const haveAllowProps = verifyFields(allFields) as string[];

      setFieldsAllowProps(haveAllowProps);
    }

    const contextProps = useMemo(
      () => ({
        ...props,
        approvalData,
        reportData,
        newFormData,
        globalState,
        setGlobalState,
        onSaveForm: handleSave,
        getApprovalData,
        setApprovalData,
        setRequiredFields,
        contractId,
        fieldsAllowProps,
      }),
      [
        props,
        approvalData,
        reportData,
        newFormData,
        globalState,
        setGlobalState,
        approvalData?.usuarioAprovacao,
        handleSave,
        getApprovalData,
        setApprovalData,
        setRequiredFields,
        contractId,
        fieldsAllowProps,
      ],
    );

    function renderForm() {
      return (
        <DynamicFormContext.Provider value={contextProps}>
          <WrapperContainer
            className="DynamicFormContainer"
            widgetStyle={widgetStyle}
          >
            {!widgetStyle && <SubHeader />}

            {renderMultipleApproval()}
            <Container className="form-container" widgetStyle={widgetStyle}>
              {render()}
            </Container>

            <ActionContainer widgetStyle={widgetStyle}>
              {!widgetStyle ? (
                <HeaderContent>
                  <IconGoBack
                    data-tip="Fechar formulário"
                    onClick={handleClose}
                  />
                  <HeaderTitle params={props} />
                </HeaderContent>
              ) : (
                <></>
              )}

              <ActionSection>
                <SendEmailButton />

                {!widgetStyle ? <PdfButton /> : <></>}

                <DeleteButton clearForm={clearForm} isDisabled={isDisabled} />

                <ClearButton
                  parametersToSave={parametersToSave}
                  isDisabled={isDisabled}
                  isHiddenButtonClear={props?.isHiddenButtonClear}
                />

                <WithPermission
                  action={EnumActions.APROVAR}
                  rules={[EnumProfile.GESTOR]}
                >
                  <ApprovalButton />
                </WithPermission>

                <CopyButton
                  parametersToSave={parametersToSave}
                  isDisabled={isDisabled}
                />

                <SaveButton
                  isDisabled={isDisabled}
                  isHiddenButtonSave={props?.isHiddenButtonSave}
                />
              </ActionSection>
            </ActionContainer>
          </WrapperContainer>

          <ReactTooltip id="tooltipPdf" place="bottom" />
          <TableModal />
          <PdfViewer />
          <CreateUserModal />
          <ListOccurrenceDynamicForm />
          <FormNewItemModal />
          <SelectEmailsModal />
        </DynamicFormContext.Provider>
      );
    }

    function renderOrderForm() {
      if (isObjectEmpty(globalState?.templateConfig)) {
        return <>{children}</>;
      }

      const { templateSeq } = globalState?.templateConfig.templatePortal || {};

      const isShowFirstChild = !templateSeq || templateSeq <= 1 ? true : false;
      const isShowSecondChild = templateSeq && templateSeq > 1;

      return (
        <div>
          {isShowFirstChild && renderForm()}
          {children}
          {isShowSecondChild && renderForm()}
        </div>
      );
    }

    return renderOrderForm();
  },
);

export default DynamicForm;
