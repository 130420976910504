import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @media print {
    @page {
      size: ${({ containerWidth }) => `${containerWidth}mm`} ${({
  containerHeight,
}) => `${containerHeight}mm`} !important;
      margin-top: 15mm !important;
    }

    .print-hide {
      display: none;
    }
  }
`;

export const ReportWrapper = styled.div`
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  max-width: 1360px;
  margin: auto;
  position: relative;

  .loading-screen-wrapper {
    background-color: #ecececed;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
  }

  .loading-screen-text-container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 30px 0;
    background-color: #fff;
  }

  .loading-screen-text {
    width: 240px;
    margin: auto;
    display: flex;
    justify-content: space-around;

    p {
      font-size: 18px;
      color: #009688;
    }

    .spinner-container {
      display: inline-flex;
      align-items: center;
    }
  }

  .curvas-legend {
    top: -12px;
  }
`;

export const LayoutContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-bottom: 100px;

  &.header {
    p {
      font-size: 26px;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .curvas-title {
    text-align: center;
    font-size: 21px;
    font-weight: 500;
    border-bottom: 1px solid #333;
    padding-bottom: 5px;
  }

  .recharts-surface {
    overflow: visible;
  }

  .g.recharts-layer.recharts-line path[stroke='#89a4d1'],
  .g.recharts-layer.recharts-line path[stroke='#ef9d54'] {
    stroke-dasharray: 10;
  }
  .recharts-legend-wrapper {
    top: 25px !important;
  }

  .recharts-tooltip-sublabel {
    margin-left: 6px;
    font-weight: 500;
    margin-bottom: 2px;
  }
`;
interface ContainerProps {
  width?: string;
  height?: string;
}
export const Container = styled.div<ContainerProps>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  flex-grow: 1;
  box-sizing: border-box;
`;

export const ColumnFilterWrapper = styled.div`
  position: relative;
  display: flex;
  width: 233px;
  left: 10px;
  top: 8px;
  padding: 5px;
  background-color: #fff;
  border-top: 1px solid #b8b8b8;
  border-bottom: 1px solid #b8b8b8;
  z-index: 10;

  #curvas-filter-container {
    font-size: 12px;

    .button-wrapper span {
      cursor: pointer;
    }

    .col-description {
      text-align: left;
      padding-left: 4px;
    }
    .button-wrapper {
      width: 39px;
      text-align: center;

      div {
        display: flex;
        width: 18px;
        margin: auto;
        cursor: pointer;
      }
    }
  }

  div.class-dummy {
    width: 1px;
    flex-grow: 1;
  }

  .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &:first-child {
      margin-bottom: 5px;
    }

    p {
      width: 75px;
      text-align: right;
      line-height: 19px;
    }

    button {
      cursor: pointer;
      padding: 3px 0;
      width: 110px;
      font-size: 10px;
      border: 1px solid #d2d2d2;
      border-radius: 3px;
      background-color: #e7e7e7;
      color: #333;

      &:hover {
        background-color: #f1f1f1;
      }

      &.active {
        background-color: #5f5f5f;
        color: white;

        &:hover {
          background-color: #727272;
        }
      }
    }
  }
`;

export const HistoryPathContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 10px;

  button {
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: 500;
  }
`;

export const HistoryPathElement = styled.div`
  display: flex;
  align-items: center;
`;
