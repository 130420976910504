import moment from 'moment';
import { OptionsType } from 'store/types';
import { Occurrence } from '../../types';
import CheckBox from '../CheckBox';
import { SimpleTableConfig } from 'pages/Reports/components/tables/SimpleTable/types';

export enum EnumOrderby {
  CREATED_AT_ASC,
  CREATED_AT_DESC,
  DEADLINE_ASC,
  DEADLINE_DESC,
}

export const tableConfig: SimpleTableConfig[] = [
  {
    key: 'dtAbertura',
    headerLabel: 'Data de Criação',
    tdStyle: {
      width: '10rem',
    },
  },
  {
    key: 'assunto',
    headerLabel: 'Assunto',
    tdStyle: {
      width: '30rem',
      textAlign: 'left',
    },
  },
  {
    key: 'ocorrencia',
    headerLabel: 'Descrição',
    tdStyle: {
      textAlign: 'left',
    },
  },
  {
    key: 'responsavel',
    headerLabel: 'Responsável',
    tdStyle: {
      width: '30rem',
      textAlign: 'left',
    },
  },
  {
    key: 'prazo',
    headerLabel: 'Prazo',
    tdStyle: {
      width: '10rem',
    },
  },
  {
    key: 'status',
    headerLabel: 'Status',
    tdStyle: {
      width: '10rem',
    },
  },
  {
    key: 'status',
    headerLabel: '',
    cellType: 'customContent',
    tdStyle: {
      width: '4rem',
    },
    cellTypeParams: {
      content: CheckBox,
    },
  },
];

export const orderByOptions = [
  {
    label: 'Data criação (A-Z)',
    value: EnumOrderby.CREATED_AT_ASC,
  },
  {
    label: 'Data criação (Z-A)',
    value: EnumOrderby.CREATED_AT_DESC,
  },
  { label: 'Prazo (A-Z)', value: EnumOrderby.DEADLINE_ASC },
  { label: 'Prazo (Z-A)', value: EnumOrderby.DEADLINE_DESC },
];

export function orderByTable(
  occurrences: Occurrence[],
  option: OptionsType | undefined,
) {
  if (!option) {
    return occurrences;
  }

  const compareDates = (a: Occurrence, b: Occurrence, field: string) => {
    return moment(a[field], 'YYYY-MM-DD HH:mm:ss').isBefore(
      moment(b[field], 'YYYY-MM-DD HH:mm:ss'),
    );
  };

  switch (option.value) {
    case EnumOrderby.CREATED_AT_ASC: {
      return occurrences.sort((a, b) =>
        compareDates(a, b, 'dtAbertura2') ? -1 : 1,
      );
    }

    case EnumOrderby.CREATED_AT_DESC: {
      return occurrences.sort((a, b) =>
        compareDates(a, b, 'dtAbertura2') ? 1 : -1,
      );
    }

    case EnumOrderby.DEADLINE_ASC: {
      return occurrences.sort((a, b) =>
        compareDates(a, b, 'prazo2') ? -1 : 1,
      );
    }

    case EnumOrderby.DEADLINE_DESC: {
      return occurrences.sort((a, b) =>
        compareDates(a, b, 'prazo2') ? 1 : -1,
      );
    }

    default:
      return occurrences;
  }
}

interface ApplyFiltersProps {
  occurrences: Occurrence[];
  responsibleFilter?: OptionsType;
  origenFilter?: OptionsType;
}

export function applyFilter(props: ApplyFiltersProps) {
  const { occurrences, responsibleFilter, origenFilter } = props;

  return occurrences.filter(occurrence => {
    let isOK = responsibleFilter
      ? occurrence.responsavel === responsibleFilter.value
      : true;

    if (!isOK) {
      return;
    }

    isOK = origenFilter ? occurrence.filtroOrigem === origenFilter.value : true;

    return isOK;
  });
}
