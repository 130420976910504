import React from 'react';
import { Container } from './styles';
import { useAtomValue } from 'jotai';
import { docNameAtom, urlParamsAtom } from 'pages/DocSign/atoms';
import { isSourceApp } from 'pages/DocSign/utils';

export default function DocNameHeader() {
  const docName = useAtomValue(docNameAtom);
  const urlParams = useAtomValue(urlParamsAtom);

  return (
    <Container isSourceApp={isSourceApp(urlParams)}>
      <span>Documento</span>
      <p> {docName || '-'}</p>
    </Container>
  );
}
