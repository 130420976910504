import { MdDeleteSweep } from 'react-icons/md';
import styled, { css } from 'styled-components';

const commonIconsStyles = css`
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 0.8rem;
  cursor: pointer;
`;

export const DeleteAllIcon = styled(MdDeleteSweep)`
  font-size: 31px;
  ${commonIconsStyles}
`;

export const TableStyled = styled.table`
  border: 1px solid #dddddd;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 5px;
  }

  td {
    font-size: 1.4rem;
  }

  tr {
    &:hover {
      cursor: pointer;
      background-color: #eee;
    }
  }
`;

export const Th = styled.th<{ size: number }>`
  width: ${({ size }) => size}%;
  font-size: 1.4rem;
`;

export const ThAction = styled.th`
  width: 6rem;
  font-size: 1.4rem;
`;

export const ActionWrapper = styled.div`
  width: 6rem;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TableWrapper = styled.div`
  position: relative;
  flex: 1;
`;

export const SelectUserWrapper = styled.div`
  margin-bottom: 1rem;
`;
