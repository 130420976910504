import Button from 'components/Button';
import { CurrencyInput } from 'components/Input';
import useModal from 'hooks/use-modal';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import {
  ButtonClose,
  ModalContainer,
  ModalHeader,
  ModalTitle,
} from 'styles/modal';
import Modal from '..';
import { TextArea, Footer } from './styles';

export interface SingleInputModalParams {
  title: string;
  placeholder?: string;
  value: string | null;
  isCurrency?: boolean;
  onSave?(value: string): void;
  onRemove?(): void;
  length?: number;
  label?: string;
}

export default function SingleInputModal() {
  const [state, setState] = useState('');

  const { isOpen, params, windowSec } = useModal<SingleInputModalParams>(
    ModalName.SINGLE_INPUT,
  );

  const dispatch = useDispatch();

  const {
    title,
    placeholder,
    isCurrency,
    length,
    value,
    onSave,
    onRemove,
    label,
  } = params;

  useEffect(() => {
    if (isOpen) {
      setState(value || '');
    }
  }, [isOpen, value]);

  function handleClose() {
    dispatch(closeModal(ModalName.SINGLE_INPUT));
  }

  return (
    <Modal isOpen={isOpen} windowSec={windowSec}>
      <ModalHeader>
        <ButtonClose onClick={handleClose} />
        <ModalTitle>{title || 'Titulo'}</ModalTitle>
      </ModalHeader>

      <ModalContainer width="30vw" height="auto">
        {isCurrency ? (
          <CurrencyInput
            value={state}
            onValueChange={e => setState(e || '')}
            allowNegativeValue={false}
            decimalSeparator=","
            groupSeparator="."
            label={label}
          />
        ) : (
          <TextArea
            value={state}
            placeholder={placeholder}
            onChange={e => setState(e.target.value)}
            maxLength={length}
            label={label}
          />
        )}

        <Footer>
          {onRemove ? (
            <Button danger text="Remover" onClick={onRemove} />
          ) : (
            <div />
          )}

          {onSave ? (
            <Button primary text="Salvar" onClick={() => onSave(state)} />
          ) : (
            <div />
          )}
        </Footer>
      </ModalContainer>
    </Modal>
  );
}
