import {
  AccountInfo,
  IPublicClientApplication,
  InteractionStatus,
} from '@azure/msal-browser';
import { UserState } from 'store/user/types';
import { loginRequest } from '../utils/azureAuthConfig';
import { AxiosResponse, Credentials, EnumRoutes } from '../store/types';
import api from './api';
import apiAdonis from './apiAdonis';

export const TOKEN_KEY = '@gescorpgo-Token';
export const TOKEN_KEY_ADONIS = '@adonis-Token';
export const IMPERSONATE_TOKEN_KEY = '@impersonate-Token';
export const IS_LOGIN_FROM_AD = '@login-ad';

export function isAuthenticated() {
  return localStorage.getItem(TOKEN_KEY) !== null;
}

export function getToken(): string | null {
  const tokenVal = localStorage.getItem(TOKEN_KEY);

  if (
    tokenVal ===
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjEsImV4cCI6MTYxODc2NTYzNH0.ayWB55gEPloQaVwqOI956DkRyaM_U5h-qMkA6VBiZQg'
  ) {
    signOut();

    return null;
  }

  return tokenVal;
}

export function getAdonisToken(): string | null {
  const tokenVal = localStorage.getItem(TOKEN_KEY_ADONIS);

  return tokenVal;
}

export function saveToken(token: string): void {
  localStorage.setItem(TOKEN_KEY, token);
}

export function saveAdonisToken(token: string): void {
  localStorage.setItem(TOKEN_KEY_ADONIS, token);
}

export function isImpersonate() {
  const hasImpersonateToken = getImpersonateToken();

  if (hasImpersonateToken) {
    return true;
  }

  return false;
}

export function saveImpersonateToken(token: string) {
  if (token) {
    localStorage.setItem(IMPERSONATE_TOKEN_KEY, token);
  }
}

export function getImpersonateToken(): string | null {
  const tokenVal = localStorage.getItem(IMPERSONATE_TOKEN_KEY);

  return tokenVal;
}

export function removeImpersonateToken(): void {
  localStorage.removeItem(IMPERSONATE_TOKEN_KEY);
}

export function removeToken(): void {
  localStorage.removeItem(TOKEN_KEY);
}

export function removeAdonisToken(): void {
  localStorage.removeItem(TOKEN_KEY_ADONIS);
}

export async function signIn(credentials: Credentials): Promise<string[]> {
  const res: AxiosResponse = await api.post(
    '/usuarios/login.json',
    credentials,
    { params: { ignoreInterceptor: true } },
  );
  const { success, user, errors } = res;

  if (success) {
    removeImpersonateToken();
    saveToken(user.token);

    const adonisResponse = (await apiAdonis.post('/auth/login', {
      password: credentials.senha,
      email: credentials.email,
    })) as { token: string };

    saveAdonisToken(adonisResponse.token);
  }

  return errors;
}

export function signOut() {
  removeToken();
  removeImpersonateToken();

  localStorage.removeItem(IS_LOGIN_FROM_AD);

  sessionStorage.clear();

  const isInSignInPage = window.location.pathname === EnumRoutes.SIGNIN;

  if (!isInSignInPage) {
    window.location.href = EnumRoutes.SIGNIN;
  }
}

export function forgotPassword(credentials: {
  email: string;
  fromPortal: number;
}) {
  return apiAdonis.post('/usuario/forgot-password', credentials);
}

export function resetPassword(credentials: {
  email: string;
  password: string;
  tempPassword: string;
}) {
  return apiAdonis.post('/usuario/reset-password', credentials);
}

export async function adLogin(token: string) {
  const { phpToken, adonisToken } = await apiAdonis.post<{
    phpToken: string;
    adonisToken: string;
  }>('/auth/ad-login', {
    token,
  });

  localStorage.setItem(IS_LOGIN_FROM_AD, 'true');
  removeImpersonateToken();

  saveToken(phpToken);
  saveAdonisToken(adonisToken);
}

export async function checkUserAuthenticatedFromAd(
  inProgress: InteractionStatus,
  accounts: AccountInfo[],
  instance: IPublicClientApplication,
  user: UserState | null,
) {
  if (isImpersonate()) {
    return;
  }

  const isEmailFromAd = localStorage.getItem(IS_LOGIN_FROM_AD);

  if (user && !isEmailFromAd) {
    return;
  }

  if (inProgress !== InteractionStatus.None) {
    return;
  }

  const isInSignInPage = window.location.pathname === EnumRoutes.SIGNIN;

  if (!accounts.length && !isInSignInPage) {
    signOut();

    return;
  }

  if (!accounts.length && isInSignInPage) {
    return;
  }

  try {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });

    await adLogin(response.accessToken);

    if (isInSignInPage) {
      window.location.href = EnumRoutes.HOME;
    }
  } catch (error) {
    signOut();
  }
}

export function tempToken(): Promise<string> {
  return apiAdonis.get('/auth/temp-token');
}

export function check(): Promise<string> {
  return apiAdonis.get('/auth/check');
}
