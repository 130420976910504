import apiAdonis from './apiAdonis';

export interface PayloadBulkInsertOrCreate {
  id: number;
  idUsuario: number;
  idPerfil?: number;
  idEmpresa: number;
  idLayout: number;
  idPagina: number;
  idAcoes?: string;
  flagStatus?: number;
}
export function updateOrCreateMany(data: { id?: number }[]): Promise<boolean> {
  return apiAdonis.post('/usuario-relatorio/update-or-create-many', data);
}

export function storeByUserTemplate(data: {
  idUsuario: number;
  idUserModelo: number;
  idEmpresa: number;
}): Promise<boolean> {
  return apiAdonis.post('/usuario-relatorio/store-by-user-template', data);
}

export function bulkInsertOrCreate(
  data: PayloadBulkInsertOrCreate[],
): Promise<boolean> {
  return apiAdonis.post('/usuario-relatorio/bulk-insert-or-create', data);
}

export function removeTemplateAccesses(data: {
  idUsuario: number;
  idEmpresa: number;
}): Promise<boolean> {
  return apiAdonis.post('/usuario-relatorio/remove-template-accesses', data);
}
