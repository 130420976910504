import { Contract } from '../types';

export const SET_CONTRACT = 'contract/SET_CONTRACT';
export const CLEAR_CONTRACT = 'contract/CLEAR_CONTRACT';
export const SET_DATASET_ID = 'contract/SET_DATASET_ID';
export const CLEAR_DATASET_ID = 'contract/CLEAR_DATASET_ID';

export interface ContractState {
  contract: Contract | null;
  datasetId: number;
}

// TODO: renomear depois do merge

interface SetDatasetIdAction {
  type: typeof SET_DATASET_ID;
  datasetId: number;
}

interface ClearDatasetIdAction {
  type: typeof CLEAR_DATASET_ID;
}

interface SetAction {
  type: typeof SET_CONTRACT;
  payload: Contract;
}

interface ClearAction {
  type: typeof CLEAR_CONTRACT;
}

export type ContractActionTypes =
  | SetAction
  | ClearAction
  | SetDatasetIdAction
  | ClearDatasetIdAction;
