import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Form = styled.form`
  background: #fff;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45rem;
  border-radius: ${({ theme }) => theme.radius.large}px;

  input {
    height: 5.2rem;
    width: 100%;
    padding: 0 1.5rem;
    color: #777;
    font-size: 1.8rem;
    border: 1px solid #ddd;
    margin-bottom: 1.5rem;
    font-style: inherit;
    letter-spacing: 0.08rem;
    border-radius: ${({ theme }) => theme.radius.medium}px;

    &::placeholder {
      color: #999;
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.green};
      outline: 0 none;
    }
  }

  button {
    height: 4.6rem;
    width: 100%;
    border-radius: ${({ theme }) => theme.radius.medium}px;
    font-size: 1.6rem;
    background: ${({ theme }) => theme.colors.green};
    color: #fff;
    border: none;
    letter-spacing: 0.08rem;
    font-weight: 600;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:active {
      background-color: #4cada6;
    }
  }
`;

export const H1 = styled.h1`
  color: #333;
  margin-bottom: 1rem;
`;

export const Sent = styled.img`
  width: 9rem;
  margin: 0 auto;
  margin-bottom: 1rem;
`;
