import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const AreaTecnica = lazy(() =>
  import(
    './layouts/geral/areaTecnica' /* webpackChunkName: 'relatorios.setin.geral.area-tecnica' */
  ),
);
const RGO = lazy(() =>
  import(
    './layouts/geral/rgo' /* webpackChunkName: 'relatorios.setin.geral.rgo' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/setin/geral/area-tecnica"
    component={AreaTecnica}
    path="/relatorios/setin/geral/area-tecnica"
  />,
  <PrivateRoute
    key="/relatorios/setin/geral/rgo"
    component={RGO}
    path="/relatorios/setin/geral/rgo"
  />,
];
