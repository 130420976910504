import TimePicker from 'react-weblineindia-time-picker';
import styled from 'styled-components';

export const InputDate = styled(TimePicker)`
  & > .p-datepicker {
    border: none;

    padding-top: 0;
  }

  .p-datepicker-month,
  .p-datepicker-year {
    border: none;

    cursor: pointer;
  }

  .p-datepicker-title,
  .p-datepicker-header {
    display: none;
  }

  .p-datepicker .p-datepicker-title select {
    font-size: 1.8rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

export const Content = styled.div`
  height: 93%;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex: 1;
  gap: 10px;
  margin: 10px 24px;
`;

export const SelectContainer = styled.div`
  width: 100%;
`;

export const MonthYearSelectSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span {
    font-size: 1.5rem;
    font-weight: 500;
  }

  & > svg {
    cursor: pointer;

    &:hover {
      color: #888;
    }
  }
`;
