import React, { InputHTMLAttributes, ReactElement } from 'react';
import { CurrencyInputProps } from 'react-currency-input-field';
import { Label } from '../../styles/form';
import { Danger } from '../../styles/text';
import { FormGroup, StyledCurrencyInput, StyledInput } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  small?: boolean;
  label?: string;
  errorMessage?: string;
}

export default function Input(props: Props): ReactElement {
  const { label, id, errorMessage } = props;

  if (label) {
    return (
      <FormGroup {...props}>
        <Label htmlFor={id}>{label}</Label>
        <StyledInput {...props} />
        {errorMessage ? <Danger>{errorMessage}</Danger> : null}
      </FormGroup>
    );
  }

  return <StyledInput {...props} />;
}

interface CurrencyProps extends CurrencyInputProps {
  id?: string;
  error?: boolean;
  small?: boolean;
  label?: string;
  errorMessage?: string;
}

export function CurrencyInput(props: CurrencyProps) {
  const { label, id, errorMessage } = props;

  if (label) {
    return (
      <FormGroup>
        <Label htmlFor={id}>{label}</Label>
        <StyledCurrencyInput {...props} />
        {errorMessage ? <Danger>{errorMessage}</Danger> : null}
      </FormGroup>
    );
  }

  return <StyledCurrencyInput {...props} />;
}
