import React from 'react';
import useUser from 'hooks/use-user';
import { useDynamicForm } from 'components/Widgets/DynamicForm/context';
import HeaderInfo from '../../../components/HeaderInfo';

export default function SubHeader() {
  const user = useUser();
  const { approvalData, globalState } = useDynamicForm();

  function renderSubHeader() {
    if (!globalState?.defaultFormData?.idFormulario || !user) {
      return null;
    }

    return <HeaderInfo approvalData={approvalData} />;
  }

  return renderSubHeader();
}
