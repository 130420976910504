import styled from 'styled-components';
import { Card } from '../../styles/card';
import { ButtonWithNoStyle } from '../../styles/common';

export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  margin-top: 4rem;
  height: 80vh;
`;

export const LinkButton = styled.button`
  ${ButtonWithNoStyle};
  text-decoration: underline;
`;

export const FileDropWrapper = styled(Card)`
  flex: 1;
  margin-right: 1rem;
  max-width: 50vw;
  margin: 0 auto;
  
  .file-drop {
    height: 100%;
  }

  .file-drop > .file-drop-target  {
    height: 100%;
    border-radius: ${({ theme }) => theme.radius.medium}px;
    /* margin-top: 1rem; */
    /* padding: 2rem 4rem; */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* border: 2px dashed #bdbdbd; */
    /* border-radius: 4px; */
    /* background-color: #fafafa; */
    /* color: #bdbdbd; */
    /* outline: none; */
    /* transition: border .24s ease-in-out; */
    cursor: pointer;
  }

  .file-drop > .file-drop-target.file-drop-dragging-over-target {
    border: 3px dashed #52C3BC;
    background-color: rgba(82, 195, 188, .05);
    z-index: 100;
  }
`;

export const FooterFileDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    color: #bdbdbd;
    font-size: 1.6rem;
  }
`;

export const SpinnerWrapper = styled.div`
  margin-top: 5rem;
  text-align: center;
`;

export const TitleWrapper = styled.div`
  margin-top: 5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 4.7rem;

  h1 {
    text-transform: uppercase;
  }
`;

export const SelectFileLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    font-size: 1.4rem;
    text-decoration: underline;
    cursor: pointer;
  }
`;
