import { lazy } from 'react';
import { ModalName } from 'store/modal/types';

const TableReportModal = lazy(() => import('components/Modal/TableReport'));

const CreateFvsaAndFvmRecord = lazy(() =>
  import('components/Modal/Financeiro/CreateFvsaAndFvmRecord'),
);

const ManageGuidelines = lazy(() =>
  import('components/Modal/Financeiro/ManageGuidelines'),
);

const ManageItemGuidelines = lazy(() =>
  import('components/Modal/Financeiro/ManageItemGuidelines'),
);

const CommentReviewSheet = lazy(() =>
  import('components/Modal/Financeiro/CommentReviewSheet'),
);

const ScheduleMessagesApp = lazy(() =>
  import('components/Modal/Financeiro/ScheduleMessagesApp'),
);

const ScheduleVersioningSheet = lazy(() =>
  import('components/Modal/Financeiro/ScheduleVersioningSheet'),
);

const MessageListByType = lazy(() =>
  import('components/Modal/Financeiro/MessageListByType'),
);

const SortRecordsAndFamily = lazy(() =>
  import('components/Modal/Financeiro/SortRecordsAndFamily'),
);

const NonComplianceFvs = lazy(() => import('../NonComplianceFvs'));

const CalendarModal = lazy(() => import('components/Modal/Calendar'));

const AttachmentsModal = lazy(() =>
  import('components/Modal/Financeiro/AttachmentsModal'),
);
const CreateNonComplianceFvs = lazy(() =>
  import('components/Modal/CreateNonComplianceFvs'),
);

const LocalStatusModal = lazy(() =>
  import(
    '../CreateNonComplianceFvs/components/DetailNc/components/LocalStatusModal'
  ),
);

const AddCommentAndPhoto = lazy(() =>
  import(
    '../CreateNonComplianceFvs/components/DetailNc/components/AddCommentAndPhoto'
  ),
);

const PdfViewer = lazy(() => import('components/Modal/PdfViewer'));
const EconomyAdditiveComposition = lazy(() =>
  import('pages/TableReport/components/Modals/EconomyAdditiveComposition'),
);

const RemoveEconomyAdditiveComposition = lazy(() =>
  import(
    'pages/TableReport/components/Modals/RemoveEconomyAdditiveComposition'
  ),
);

const ReadjustmentModal = lazy(() =>
  import('pages/TableReport/components/Modals/ReadjustmentModal'),
);
const NcsModal = lazy(() => import('components/Modal/NcsByParameters'));

const FormFVSModal = lazy(() => import('components/Modal/FormFVS'));

const OrderStepldderFamilyModal = lazy(() =>
  import('components/Modal/OrderStepladderFamily'),
);

const ModalFilter = lazy(() => import('components/Modal/ModalFilter'));

const QuotationFrameListManagement = lazy(() =>
  import('components/Modal/Suprimentos/QuotationFrameListManagement'),
);

const QuotationFrameInputAssociation = lazy(() =>
  import('components/Modal/Suprimentos/QuotationFrameInputAssociation'),
);

const CreateOrEditCalculations = lazy(() =>
  import('components/Modal/Suprimentos/CreateOrEditCalculations'),
);

const DynamicAttachmentsModal = lazy(() =>
  import('../Financeiro/DynamicAttachmentsModal'),
);

const TicketSupportModal = lazy(() =>
  import('pages/Support/components/Modals/TicketSupport'),
);

const ClientCommentModal = lazy(() =>
  import('pages/Support/components/Modals/ClientCommentModal'),
);
const GescorpGoCommentModal = lazy(() =>
  import('pages/Support/components/Modals/GescorpGoCommentModal'),
);
const OpeningCallModal = lazy(() =>
  import('pages/Support/components/Modals/OpeningCallModal'),
);
const NewWorkRegistrationModal = lazy(() =>
  import('pages/Support/components/Modals/NewWorkRegistrationModal'),
);

export const modalsLazy: {
  [modalName in ModalName]?: React.LazyExoticComponent<
    () => React.JSX.Element | null
  >;
} = {
  [ModalName.TABLE_REPORT_MODAL]: TableReportModal,
  [ModalName.CREATE_FVS_AND_RECORD]: CreateFvsaAndFvmRecord,
  [ModalName.MANAGE_GUIDELINES]: ManageGuidelines,
  [ModalName.MANAGE_ITEM_GUIDELINES]: ManageItemGuidelines,
  [ModalName.COMMENT_REVIEW_SHEET]: CommentReviewSheet,
  [ModalName.SHEDULE_MESSAGES_APP]: ScheduleMessagesApp,
  [ModalName.SHEDULE_VERSIONING_SHEET]: ScheduleVersioningSheet,
  [ModalName.MESSAGE_LIST_BY_TYPE]: MessageListByType,
  [ModalName.SORT_RECORDS_AND_FAMILY]: SortRecordsAndFamily,
  [ModalName.NON_COMPLIANCE_FVS]: NonComplianceFvs,
  [ModalName.DATE_TIME]: CalendarModal,
  [ModalName.ATTACHMENTS_MODAL]: AttachmentsModal,
  [ModalName.CREATE_NON_COMPLIANCE_FVS]: CreateNonComplianceFvs,
  [ModalName.LOCAL_STATUS_MODAL]: LocalStatusModal,
  [ModalName.ADD_COMMENT_AND_PHOTO]: AddCommentAndPhoto,
  [ModalName.PDF_VIEWER]: PdfViewer,
  [ModalName.OPENING_CALL]: OpeningCallModal,
  [ModalName.CLIENT_COMMENT_MODAL]: ClientCommentModal,
  [ModalName.GESCORPGO_COMMENT_MODAL]: GescorpGoCommentModal,
  [ModalName.TICKET_SUPPORT]: TicketSupportModal,
  [ModalName.NEW_WORK_REGISTRATION]: NewWorkRegistrationModal,
  [ModalName.ECONOMY_ADDITIVE_COMPOSITION]: EconomyAdditiveComposition,
  [ModalName.REMOVE_ECONOMY_ADDITIVE_COMPOSITION]: RemoveEconomyAdditiveComposition,
  [ModalName.BUDGET_READJUSTMENT_MODAL]: ReadjustmentModal,
  [ModalName.MODAL_NCS]: NcsModal,
  [ModalName.FORM_FVS]: FormFVSModal,
  [ModalName.SORT_STEPLADDER_FAMILY]: OrderStepldderFamilyModal,
  [ModalName.MODAL_FILTER]: ModalFilter,
  [ModalName.QUOTATION_FRAME_LIST_MANAGEMENT]: QuotationFrameListManagement,
  [ModalName.QUOTATION_FRAME_CONFIGURATION_GROUP]: QuotationFrameInputAssociation,
  [ModalName.CONFIGURATION_CONSTANTS_VARIABLES]: CreateOrEditCalculations,
  [ModalName.DYNAMIC_ATTACHMENTS_MODAL]: DynamicAttachmentsModal,
};
