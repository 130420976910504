import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const DashboardMateriais = lazy(() =>
  import(
    './layouts/fvm/materiais' /* webpackChunkName: 'relatorios.mitre.fvm.dashboard-materiais' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/matec/fvm/dashboard-materiais"
    component={DashboardMateriais}
    path="/relatorios/matec/fvm/dashboard-materiais"
  />,
];
