import { IoMdAddCircle } from 'react-icons/io';
import styled, { css } from 'styled-components';
import { MdDeleteSweep, MdEdit, MdOutlineDownloading } from 'react-icons/md';
import { CgBrowse } from 'react-icons/cg';

export const EditIcon = styled(MdEdit)`
  position: absolute;
  right: -20px;
  bottom: 2px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.grey30};
  display: none;
`;

const commonIconsStyles = css`
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 0.8rem;
  cursor: pointer;
`;

export const AddIcon = styled(IoMdAddCircle)`
  font-size: 24px;
  ${commonIconsStyles}
`;

export const ListIcon = styled(CgBrowse)`
  font-size: 24px;
  ${commonIconsStyles}
`;

export const DeleteAllIcon = styled(MdDeleteSweep)`
  font-size: 31px;
  ${commonIconsStyles}
`;

export const TableStyled = styled.table`
  border: 1px solid #dddddd;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 5px;
  }

  td {
    position: relative;
    font-size: 1.4rem;
  }

  tr {
    &:hover {
      cursor: pointer;
      background-color: #eee;

      & ${EditIcon} {
        display: block;
      }
    }
  }
`;

export const Th = styled.th<{ size: number }>`
  width: ${({ size }) => size}%;
  font-size: 1.4rem;
`;

export const TableWrapper = styled.div`
  position: relative;
  flex: 1;
`;

export const CellIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadDataIcon = styled(MdOutlineDownloading)`
  font-size: 2.5rem;
  ${commonIconsStyles}
`;
