import { Comment } from 'store/types';

export interface Props {
  idDataset: number;
  idContrato: number;
  idReferencia: number;
  idTipoAprovacao?: number;
  origem: string;
  onAfterLoad?(approval: ApprovalLevel[]): void;
  onBeforeApproval?(): Promise<boolean>;
  onAfterApproval?(): void;
  approvalButtonText?: string;
  disableApproval?: boolean;
  setHasApprovalConfig?(approval: boolean): void;
  idTipo?: number;
}

export enum StatusApproval {
  APPROVED = 1,
  REPPROVED = 2,
  REEVALUATE = 3,
}

export interface ApprovalLevel {
  idOcorrencia: number | null;
  uuidOcorrencia: string | null;
  idNivelAprovacao: number;
  idAprovacao: number;
  allowApproval: number;
  dtAprovacao: string | null;
  nivel: string;
  nomeUsuario: string | null;
  observacaoOriginal: string | null;
  observacaoComplementar: string | null;
  status: StatusApproval;
  showTagButton?: boolean;
  idTipoAprovacao?: number;
}

export interface Reply extends Comment {
  isOccurrence: 0 | 1;
  seq: number;
}
