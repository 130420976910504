import React, { InputHTMLAttributes, ReactElement } from 'react';
import { Label, FormGroup } from 'styles/form';
import { Danger } from 'styles/text';
import { StyledInput } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: boolean;
  dateFormat: string;
  showTime?: boolean;
  selectOtherMonths?: boolean;
  showOtherMonths?: boolean;
  errorMessage?: string;
}

export default function InputDate(props: Props): ReactElement {
  const { label, id, errorMessage } = props;

  if (label) {
    return (
      <FormGroup>
        <Label htmlFor={id}>{label}</Label>
        <StyledInput {...props} />
        {errorMessage ? <Danger>{errorMessage}</Danger> : null}
      </FormGroup>
    );
  }

  return <StyledInput {...props} />;
}
