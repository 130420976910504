import React, { ReactElement } from 'react';
import { Container } from './styles';

interface Props {
  children: ReactElement | ReactElement[];
}

export default function HeaderAction(props: Props) {
  const { children } = props;

  return <Container>{children}</Container>;
}
