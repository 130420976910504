import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal, showModal } from 'store/modal/actions';
import { EnumActions, EnumProfile } from 'store/types';
import { ModalName } from 'store/modal/types';
import { remove } from 'services/form';
import { useDynamicForm } from 'components/Widgets/DynamicForm/context';
import WithPermission from 'components/WithPermission';
import showAlert from 'components/Alert';

import { Button } from '../../../styles';

interface Props {
  clearForm: () => void;
  isDisabled?: boolean;
}

export default function DeleteButton(props: Props) {
  const { approvalData, globalState, getRows } = useDynamicForm();

  const { isDisabled } = props;

  const dispatch = useDispatch();

  if (
    !globalState?.defaultFormData?.idFormulario ||
    !globalState?.templateConfig?.templatePortal?.allowDelete ||
    approvalData?.usuarioAprovacao
  ) {
    return <></>;
  }

  const allowProfilesDelete =
    globalState.templateConfig.templatePortal.allowProfilesDelete || [];

  function onCloseForm() {
    const { clearForm } = props;
    dispatch(closeModal(ModalName.DYNAMIC_FORM));
    clearForm();

    if (getRows) {
      getRows();
    }
  }

  function handleDelete() {
    const confirm = async () => {
      dispatch(showModal(ModalName.LOADING, { text: 'Excluindo formulário' }));

      const { defaultFormData } = globalState!;
      await remove(defaultFormData!.idFormulario);

      onCloseForm();
      dispatch(closeModal(ModalName.LOADING));
    };

    showAlert({
      title: 'Atenção!',
      subtitle: 'Deseja excluir o formulário?',
      actions: [
        {
          text: 'Não',
        },
        {
          text: 'Sim',
          onClick: confirm,
        },
      ],
    });
  }

  return (
    <WithPermission
      action={EnumActions.EXCLUIR}
      rules={[EnumProfile.GESTOR, ...allowProfilesDelete]}
    >
      <Button
        small
        text="Excluir"
        onClick={handleDelete}
        disabled={isDisabled}
      />
    </WithPermission>
  );
}
