import { hot } from 'react-hot-loader/root';
import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import { setConfig } from 'react-hot-loader';
import App from './App';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './utils/azureAuthConfig';
import { init as SentryInit } from '@sentry/react';
import store from './store';
import { Provider } from 'react-redux';

SentryInit({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  maxBreadcrumbs: 50,
  debug: false,
  environment: import.meta.env.VITE_NODE_ENV,
});

const pca = new PublicClientApplication(msalConfig);
setConfig({
  reloadHooks: false,
});

//TODO sera que ainda precisa do hot reload
const HotApp = import.meta.env.VITE_NODE_ENV === 'development' ? hot(App) : App;

ReactDOM.render(
  <MsalProvider instance={pca}>
    <Provider store={store}>
      <HotApp />
    </Provider>
  </MsalProvider>,

  document.getElementById('root'),
);
