import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const DashboardGeral = lazy(() =>
  import(
    './layouts/qualidade/dashboardGeral' /* webpackChunkName: 'relatorios.ryazbek.qualidade.dashboard-geral' */
  ),
);

const DashboardFisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.ryazbek.fisico.gerencial' */
  ),
);

const DashboardFisicoPortfolio = lazy(() =>
  import(
    './layouts/fisico/portfolio' /* webpackChunkName: 'relatorios.ryazbek.fisico.portfolio' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/ryazbek/qualidade/dashboard-geral"
    component={DashboardGeral}
    path="/relatorios/ryazbek/qualidade/dashboard-geral"
  />,
  <PrivateRoute
    key="/relatorios/ryazbek/fisico/gerencial"
    component={DashboardFisicoGerencial}
    path="/relatorios/ryazbek/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/ryazbek/fisico/portfolio"
    component={DashboardFisicoPortfolio}
    path="/relatorios/ryazbek/fisico/portfolio"
  />,
];
