import React, { ReactElement } from 'react';
import { Column } from 'react-data-grid';
import { Cell } from './styles';
import {
  formatNumber,
  FormatNumber,
} from '../../../../Reports/utils/dataFunctions';

interface Props {
  value: number | string | null;
  column: ColumnProps;
}

interface ColumnProps extends Column<any> {
  numberFormatting: FormatNumber;
}

export default function Default(props: Props): ReactElement {
  const { value, column } = props;

  const calcValue = !column?.numberFormatting
    ? value
    : formatNumber(column?.numberFormatting)(value);

  return (
    <Cell className="formatter-type-default">
      <span>{calcValue}</span>
    </Cell>
  );
}
