import { State } from 'components/Widgets/DynamicForm/types';
import { Form } from '../store/types';
import api from './api';

export function getName(
  contractId: number,
  formId: number,
  datasetId: number,
): Promise<string> {
  return api.get(
    `/formularios/get-form-name/${contractId}/${formId}/${datasetId}.json`,
  );
}

export function create(form: Form): Promise<Form> {
  return api.post('/formularios/add.json', form, {
    params: { apiConfig: { deep: false } },
  });
}

export function edit(form: {
  idFormulario: number;
  form: State;
}): Promise<void> {
  return api.post('/formularios/edit.json', form, {
    params: { apiConfig: { deep: false } },
  });
}

export function remove(formId: number): Promise<void> {
  return api.post(`/formularios/delete.json`, {
    idFormulario: formId,
  });
}

export function getById(formId: number): Promise<Form> {
  return api.get(`/formularios/get-by-id/${formId}.json`, {
    params: { apiConfig: { deep: false } },
  });
}

export function editMerge(form: {
  idFormulario: number;
  form: State;
}): Promise<void> {
  return api.post('/formularios/edit-merge.json', form, {
    params: { apiConfig: { deep: false } },
  });
}
