import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);

  .toggle-icon {
    cursor: pointer;
    font-size: 13px;
  }
`;
