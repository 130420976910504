import styled, { css } from 'styled-components';

export const TableWrapper = styled.div.attrs(props => ({
  className: 'SimpleTable__table-wrapper',
}))`
  position: relative;
  ${({ maxHeight }) => {
    if (maxHeight) {
      return {
        maxHeight: `${maxHeight}px`,
        overflowY: 'scroll',
      };
    }
  }}

  ${({ lockFirstColumn }) => {
    if (lockFirstColumn) {
      return {
        overflowX: 'scroll',
      };
    }
  }}
`;

export const TableStyled = styled.table.attrs(props => ({
  className: 'SimpleTable__table',
}))`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: white;

  thead {
    ${({ stickyHeader }) => {
      if (stickyHeader) {
        return {
          position: 'sticky',
          top: 0,
          zIndex: 102,
        };
      }
    }}
  }

  td,
  th {
    text-align: center;
  }

  th {
    padding: 5px 7px;
    font-size: 1.3rem;
    text-transform: uppercase;
    background-color: #fff;

    .header-wrapper {
      position: relative;
    }
  }

  td {
    position: relative;
    padding: 2px 7px;
    font-size: 1.2rem;
    background-color: white;

    &.col-index-0 {
      position: relative;
      text-align: left;
    }

    &.treeview__is-handler {
      padding-right: 26px;
    }
  }

  tbody tr {
    height: 26px;

    // treeview logic - start
    &[data-is-open='no'] {
      display: none;
    }

    &[data-is-visible='no'] {
      display: none;
    }

    &.treeview__is-last-child td {
      background-color: white;
    }
    // treeview logic - end

    &:not(:last-child) td {
      border-bottom: 1px solid #ededed;
    }

    &:hover > td {
      background-color: #ededed;
    }

    &.row-index-0 {
      ${({ stickyFirstRowStyle }) => stickyFirstRowStyle}
    }
  }

  .col-index-0 {
    ${({ lockFirstColumn }) => {
      if (lockFirstColumn) {
        return {
          position: 'sticky !important',
          left: 0,
          zIndex: 100,
        };
      }
    }}
  }

  ${({ preset }) => preset && presetStyles[preset]}
  ${({ treeviewStyle }) => treeviewStyle}
`;

export const Container = styled.div.attrs(props => ({
  className: 'SimpleTable__container',
}))`
  width: 100%;
  margin: auto;

  ${({ customStyle }) => customStyle}
`;

const gridStyle = css`
  th {
    background-color: #f1f1f1;
    border: 1px solid #dddddd;
    padding: 6px 5px;
    text-transform: unset;

    &:not(:first-child) {
      border-left: none;
    }
  }

  td {
    border: 1px solid #dddddd;
    border-top: none;

    &:not(:first-child) {
      border-left: none;
    }
  }
`;

const headerBorders = css`
  th {
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
  }
`;

const presetStyles = {
  gridStyle,
  headerBorders,
};
