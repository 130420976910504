/* eslint-disable @typescript-eslint/no-explicit-any */
import apiAdonis from './apiAdonis';

type StorePayload = {
  id?: number;
  idEmpresa?: number;
  uuid?: string;
  uuidUsuario?: string;
  uuidCatalogoListas?: string;
  flagStatus?: number;
};

export function store(user: StorePayload[]): Promise<any> {
  return apiAdonis.post('/usuario-area/store', user);
}
