import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

const Error = css`
  border-color: ${({ theme }) => theme.colors.danger};
`;

export const StyledTextArea = styled(TextareaAutosize)<{
  error?: boolean;
  customMaxHeight?: string;
}>`
  width: 100%;
  padding: 1rem 1rem 1rem 1rem;
  resize: none;
  color: #333;
  font-size: 1.4rem;
  border: 1px solid #ddd;
  font-family: 'Roboto', 'Open Sans', sans-serif;
  letter-spacing: 0.08rem;
  border-radius: ${({ theme }) => theme.radius.medium}px;
  max-height: ${({ customMaxHeight }) => customMaxHeight || 'auto'};
  min-height: 4.1rem;

  ${({ error }) => error && Error};
  height: auto;
  resize: none;

  &::placeholder {
    color: #999;
    font-weight: 300;
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
    outline: 0 none;
    ${({ error }) => error && Error};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey40};

    ${({ error }) => error && Error};
  }
`;
