import { remove } from 'services/form';
import { FetchAreaType, FormData } from './types';
import { generateUUID } from 'utils/common';
import { getUtcDatetime } from 'utils/date';
import { bulkInsertOrUpdate as bulkInsertOrUpdateArea } from 'services/relationship_area';
import { getSqlResponse } from 'services/dataset';

export function removeDynamicFormForCanceledOccurrence(occurrence: FormData) {
  if (occurrence.flagStatus === 5 && occurrence.idFormulario) {
    return remove(occurrence.idFormulario);
  }
}

export async function saveAndGetAreas(
  occurrence: FormData,
  idContrato: number,
  idUsuario: number,
) {
  const areasToSave = occurrence?.areas || [];

  occurrence?.previousAreas?.forEach(previousResponse => {
    if (
      !occurrence.areas.some(
        currentArea => currentArea.value === previousResponse.value,
      )
    ) {
      areasToSave.push({ ...previousResponse, flagStatus: 0 });
    }
  });

  if (!areasToSave.length) return;

  const normalizedArea = areasToSave.map(item => {
    return {
      uuid: item.uuid || generateUUID(),
      idContrato,
      uuidReferencia: occurrence.uuid!,
      uuidCatalogo: item.value,
      idUsuarioCriacao: idUsuario,
      dtCriacao: getUtcDatetime(),
      flagStatus: item.flagStatus === 0 ? 0 : 1,
    };
  });

  await bulkInsertOrUpdateArea(normalizedArea);

  return getSqlResponse<FetchAreaType[]>(2662, {
    uuidReferencia: occurrence.uuid,
  });
}
