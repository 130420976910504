/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import React, { useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearSupplierMeasure } from 'store/supplierMeasure/actions';
import { isAuthenticated } from '../../services/auth';
import { getMenu } from '../../services/portal';
import { create as createLogAcessos } from '../../services/logs_menu';
import {
  clear,
  clearDatasetID,
  setDatasetID,
} from '../../store/contract/actions';
import {
  setHasMenu,
  setMenuFlag,
  setMenuParams,
  setMenuRaw,
  setMenuRendered,
  setMenuRoutes,
} from '../../store/menu/actions';
import { showModal } from '../../store/modal/actions';
import { ModalName } from '../../store/modal/types';
import { setTitle } from '../../store/navbar/actions';
import { RootState } from '../../store/rootReducer';
import { EnumRoutes, LogMenu } from '../../store/types';
import Impersonate from './components/Impersonate';
import {
  BackToHomeContainer,
  BackToHomeIcon,
  Container,
  LestLevel,
  StyledInput,
  StyledLabel,
  StyledLi,
  StyledNav,
  StyledSpan,
  StyledUl,
} from './styles';
import { getBrowserName, getDevice } from '../../utils/common';
import {
  setUserActions,
  setUserInfo,
  setUserProfileModule,
} from '../../store/user/actions';
import SelectUserCompanyButton from './components/SelectUserCompanyButton';
import { getUserCompanyFromStorage } from 'services/user';
import { resetUserAccess } from 'services/user';
import { resetUserEnterprise } from 'services/contract_user';
import useQuery from 'hooks/use-query';
import { getFullRoute, getReloadInfo } from './utils';
import { ReloadInfo, UserCompanyProps } from './types';

export default function Menu() {
  const { display: menuDisplay, menuFlag } = useSelector(
    (state: RootState) => state.menu,
  );
  const isMobile = useSelector((state: RootState) => state.config.isMobile);
  const user = useSelector((state: RootState) => state.user);
  const { menuRendered, menuRaw } = useSelector(
    (state: RootState) => state.menu,
  );

  const { idEmpresa } = useSelector((state: RootState) => state.user);

  const datasetIdRdx = useSelector(
    (state: RootState) => state.contract.datasetId,
  );
  const impersonateId = useSelector(
    (state: RootState) => state.report.impersonateId,
  );
  const { isImpersonate } = useSelector(
    (state: RootState) => state.impersonate,
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const urlParams = useQuery<{ idLayout: number }>();

  useEffect(() => {
    initData();
  }, [impersonateId, isMobile, idEmpresa]);

  useEffect(() => {
    initDataForSelectButton();
  }, [idEmpresa]);

  function initDataForSelectButton() {
    const userCompanyInformation: UserCompanyProps = getUserCompanyFromStorage();

    if (!Object.keys(userCompanyInformation).length) return;

    dispatch(setUserInfo({ ...user, ...userCompanyInformation }));
  }

  async function initData() {
    if (!isAuthenticated() || !idEmpresa) return;

    if (menuRendered.length) {
      return;
    }

    await resetUserAccess(user);
    await resetUserEnterprise(user);

    const menuDataRes = await getMenu(impersonateId, idEmpresa);
    const menuDataParsed = JSON.parse(menuDataRes[0].menu);

    if (!menuDataParsed) {
      dispatch(setHasMenu(false));

      return;
    }

    if (
      window.location.pathname !== '/' &&
      !user.idAcoes &&
      !user.idPerfilModulo
    ) {
      const reloadInfo = getReloadInfo(menuDataParsed, urlParams.idLayout);
      addReloadInfo(reloadInfo);
    }

    const menuDataOrdered = orderMenu(menuDataParsed);
    const menuData = isMobile
      ? filterNonMobile(menuDataOrdered)
      : menuDataOrdered;

    dispatch(setMenuRaw(menuData));
    dispatch(setMenuRendered(menuData));

    const menuRoutes = getMenuRoutes(menuData);

    dispatch(setMenuRoutes(menuRoutes));
    dispatch(setHasMenu(!!menuRoutes.length));
  }

  function addReloadInfo(info: ReloadInfo) {
    const { idPerfilModulo, name, idAcoes, datasetId: datasetIdToSet } = info;

    if (idPerfilModulo) {
      dispatch(setUserProfileModule(idPerfilModulo));
    }

    if (name) {
      dispatch(setTitle(name));
    }

    if (idAcoes) {
      dispatch(setUserActions(idAcoes));
    }

    if (datasetIdToSet && !datasetIdRdx) {
      dispatch(setDatasetID(datasetIdToSet));
    }
  }

  function handleBackToHomeClick() {
    dispatch(setMenuFlag('topMenu'));
    dispatch(setMenuRendered(menuRaw));
    history.push(EnumRoutes.HOME);
  }

  function sendLog(rowId: string, rowRoute: string) {
    if (isImpersonate) {
      return;
    }

    const logMenu: LogMenu = {
      idReferencia: rowId,
      idUsuario: user.idUsuario!,
      idEmpresa: user.idEmpresa!,
      rota: rowRoute,
      browser: getBrowserName(),
      dispositivo: getDevice(),
    };

    createLogAcessos(logMenu);
  }

  function handleClick(row) {
    const {
      route,
      params,
      modalParams,
      subMenu,
      modal,
      name,
      idPerfilModulo,
      idAcoes,
    } = row;

    dispatch(clearSupplierMeasure());

    if (route) {
      const fullRoute = getFullRoute(route, params.layoutId);
      history.push(fullRoute);
      dispatch(setTitle(name));
      sendLog(row.id, row.route);
    }

    if (idAcoes) {
      dispatch(setUserActions(idAcoes));
    }

    if (idPerfilModulo) {
      dispatch(setUserProfileModule(idPerfilModulo));
    }

    if (modal) {
      dispatch(setTitle(name));
      dispatch(showModal(modal as ModalName, modalParams));
      sendLog(row.id, row.route);

      return;
    }

    if (params) {
      dispatch(setMenuParams(params));
      const { datasetId } = params;

      if (datasetId) {
        if (datasetId !== datasetIdRdx) {
          dispatch(clear());
        }

        dispatch(setDatasetID(datasetId));
      } else if (route) {
        dispatch(clear());
        dispatch(clearDatasetID());
      }
    }

    if (subMenu) {
      dispatch(setMenuRendered(subMenu));
      dispatch(setMenuFlag('subMenu'));
    }
  }

  function handleExpandTreeview(row) {
    return () => {
      const newMenuRendered = menuRendered.map(menu => {
        if (menu.id === row.id) {
          return { ...menu, isExpanded: !row.isExpanded };
        }

        return menu;
      });

      dispatch(setMenuRendered(newMenuRendered));
    };
  }

  function renderMenu() {
    if (menuFlag === 'topMenu') {
      return (
        <StyledUl>
          {menuRaw.map(row => (
            <StyledLi key={row.id} onClick={() => handleClick(row)}>
              <StyledLabel>
                <span>{row.name} </span>
              </StyledLabel>
            </StyledLi>
          ))}
        </StyledUl>
      );
    }

    return (
      <StyledUl>
        {menuRendered.map(row => (
          <>
            <StyledLi key={row.id}>
              <StyledInput
                type="checkbox"
                id={`check-${row.id}`}
                checked={row.isExpanded}
              />
              <StyledLabel
                htmlFor={`check-${row.id}`}
                onClick={handleExpandTreeview(row)}
              >
                <span>{row.name}</span>
                <span className="arrow-down">
                  <IoIosArrowDown />
                </span>
                <span className="arrow-up">
                  <IoIosArrowUp />
                </span>
              </StyledLabel>
            </StyledLi>

            {row.isExpanded
              ? row.children?.map(row2 => (
                  <LestLevel key={row.id} onClick={() => handleClick(row2)}>
                    <StyledLabel>
                      <span>{row2.name}</span>
                    </StyledLabel>
                  </LestLevel>
                ))
              : null}
          </>
        ))}
      </StyledUl>
    );
  }

  function renderImpersonate() {
    if (idEmpresa !== 2 && !isImpersonate) {
      return null;
    }

    return <Impersonate />;
  }

  function renderInfoSupport() {
    function handleClickSupport() {
      history.push(EnumRoutes.SUPPORT);
    }

    return (
      <StyledUl style={{ height: 'auto' }}>
        <StyledLi key="support" onClick={handleClickSupport}>
          <StyledLabel>
            <StyledSpan>SUPORTE</StyledSpan>
          </StyledLabel>
        </StyledLi>
      </StyledUl>
    );
  }

  return (
    <Container id="menu-component-container" style={{ left: menuDisplay }}>
      <SelectUserCompanyButton />
      <StyledNav>
        {renderMenu()}
        <div>
          {menuFlag === 'subMenu' ? (
            <BackToHomeContainer onClick={handleBackToHomeClick}>
              <span>VOLTAR AO INÍCIO</span>
              <BackToHomeIcon />
            </BackToHomeContainer>
          ) : (
            <></>
          )}
          {renderInfoSupport()}
          {renderImpersonate()}
        </div>
      </StyledNav>
    </Container>
  );
}

function getMenuRoutes(menu): any[] {
  function setRoutes(menuAux) {
    menuAux.forEach(row => {
      if (row.subMenu) {
        setRoutes(row.subMenu);
      }

      if (row.children) {
        setRoutes(row.children);
      }

      menuRoutesAux.push(row.route);
    });
  }

  const menuRoutesAux: any = [];

  setRoutes(menu);
  const menuRoutes = menuRoutesAux.filter(
    (value, index, array) => value && array.indexOf(value) === index,
  );

  return menuRoutes;
}

function filterNonMobile(menuParam) {
  const menu = [...menuParam];

  const filteredMenu = menu.filter(row => row.id !== 10 && row.id !== 20);

  const moduleArr = menu.filter(row => row.id === 10 || row.id === 20);

  const newMenu = [];
  moduleArr.forEach(mod => {
    const { subMenu } = mod;
    const subMenuAux = !subMenu
      ? []
      : subMenu
          .map(item => ({
            ...item,
            children: item.children.filter(child => child.params?.flagMobile),
          }))
          .filter(item => item.children.length);
    if (subMenuAux.length) {
      newMenu.push({
        ...mod,
        subMenu: subMenuAux,
      });
    }
  });

  const mobileMenu = filteredMenu
    .concat(newMenu)
    .sort((a, b) => compareByKey(a, b, 'seq'));

  return mobileMenu;
}

function orderMenu(menu) {
  const newMenu = [...menu];

  const filteredMenu = newMenu.filter(row => !row.subMenu); //sem submenu

  const modulerArr = newMenu.filter(row => row.subMenu); //com submenu

  const orderedModules = modulerArr.map(mod => ({
    ...mod,
    subMenu: !mod.subMenu
      ? []
      : mod.subMenu
          .map(item => ({
            ...item,
            children: item.children
              .sort((a, b) => compareByKey(a, b, 'id'))
              .sort((a, b) => compareByKey(a, b, 'seq')),
          }))
          .sort((a, b) => compareByKey(a, b, 'seq')),
  }));

  const orderedMenu = filteredMenu
    .concat(orderedModules)
    .sort((a, b) => compareByKey(a, b, 'seq'));

  return orderedMenu;
}

function compareByKey(a, b, key) {
  const obj1 = a[key];
  const obj2 = b[key];
  if (obj1 > obj2) return 1;
  if (obj2 > obj1) return -1;

  return 0;
}
