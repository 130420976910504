import { PropsHandleClick } from 'pages/Reports/components/tables/SimpleTable/types';
import { iconsMap } from 'pages/TableReport/components/Formatters/GenericIcon';
import React from 'react';
import { IconContainer } from './styles';

export default function SelectLabel(props: PropsHandleClick) {
  const { row, col } = props;

  if (!col.isRenderIcon && row.isLastChild) {
    return row[col.key]?.label;
  }

  if (row[col.key]?.label === 'Sim') {
    return <IconContainer>{iconsMap['iconeFarolVerde']}</IconContainer>;
  } else if (row[col.key]?.label === 'Não') {
    return <IconContainer>{iconsMap['iconeFarolVermelho']}</IconContainer>;
  } else {
    return <IconContainer>{iconsMap[row[col.key]]}</IconContainer>;
  }
}
