import { FileGroup } from '../store/types';
import api from './api';

export function getAll(): Promise<FileGroup[]> {
  return api.get('/grupo-arquivo/list.json');
}

export function create(data: FileGroup): Promise<FileGroup> {
  return api.post('/grupo-arquivo/add.json', data);
}
