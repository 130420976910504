import React from 'react';
import Input from 'components/Input';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import { Occurrence } from '../../types';
import { toDateBR, toDateUS, toInputDate } from 'utils/date';
import { useDispatch } from 'react-redux';
import { showModal } from 'store/modal/actions';
import { DateModalParams } from 'components/Modal/Calendar';
import { ModalName } from 'store/modal/types';

interface Props {
  label?: string;
  fieldDate: 'dtIniReal' | 'dtFimReal' | 'prazo';
  setFormData(formData: Occurrence): void;
  formData: Occurrence;
}

export default function InputDate(props: Props) {
  const { label, fieldDate, formData, setFormData } = props;
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  function handleShowModal(typedate: 'dtIniReal' | 'dtFimReal') {
    const handleChangeDate = (name: string, value: Date) => {
      const validDataIni = moment(formData?.dtIniReal) <= moment(value);
      const validDataEnd = moment(formData?.dtFimReal) >= moment(value);

      if (
        (!validDataIni && name === 'dtFimReal' && value) ||
        (!validDataEnd && name === 'dtIniReal' && formData?.dtFimReal)
      ) {
        return addToast('Data fim tem que ser maíor que data inicio.', {
          appearance: 'warning',
        });
      }
      setFormData({
        ...formData,
        [name]: value ? toDateUS(value) : '',
      });
    };
    dispatch(
      showModal<DateModalParams>(ModalName.DATE_TIME, {
        autoCloseModal: true,
        name: typedate,
        value: toInputDate(formData[typedate]),
        showTime: false,
        selectOtherMonths: true,
        showOtherMonths: true,
        dateFormat: 'dd/mm/yy',
        disableClear: false,
        onSelectDate: handleChangeDate,
      }),
    );
  }

  return (
    <Input
      label={label}
      onClick={() => handleShowModal(fieldDate)}
      value={toDateBR(formData[fieldDate]) || ''}
    />
  );
}
