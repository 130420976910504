import React, { ReactElement, useEffect, useState } from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

interface Props {
  shouldCloseOnOverlayClick?: boolean;
  children: ReactElement | ReactElement[];
  onRequestClose?: () => void;
  onModalOpen?: (isOpen: boolean) => void;
  isOpen: boolean;
  openLikePage?: boolean;
  style?: any;
  windowSec?: number;
  onAfterClose?: () => void;
}

const customStyles = {
  content: {
    top: '47%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 998,
    top: '5.3rem',
  },
};

const customStyleLikePage = {
  content: {
    inset: 0,
    bottom: 'auto',
    border: 'none',
    padding: 0,
    borderRadius: 0,
    backgroundColor: '#E9EDF0',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 998,
    top: '5.3rem',
  },
};

export default function Modal(props: Props): ReactElement {
  const {
    shouldCloseOnOverlayClick = false,
    onRequestClose,
    children,
    onModalOpen,
    isOpen,
    style,
    openLikePage,
    windowSec,
    onAfterClose,
  } = props;

  const [newStyle, setNewStyle] = useState(style);

  useEffect(() => {
    if (onModalOpen) {
      onModalOpen(isOpen);
    }

    if (isOpen && !style) {
      getStyle();
    }
  }, [isOpen]);

  function getStyle() {
    if (windowSec) {
      const defaultStyle = openLikePage ? customStyleLikePage : customStyles;

      const normalizedStyle = {
        ...defaultStyle,
        overlay: {
          ...defaultStyle.overlay,
          zIndex: defaultStyle.overlay.zIndex + windowSec,
        },
      };

      setNewStyle(normalizedStyle);
    } else {
      setNewStyle(openLikePage ? customStyleLikePage : customStyles);
    }
  }

  return (
    <ReactModal
      onRequestClose={onRequestClose}
      style={newStyle}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onAfterClose={onAfterClose}
    >
      {children}
    </ReactModal>
  );
}
