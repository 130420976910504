import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Input from 'components/Input';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { useDispatch } from 'react-redux';
import Span from 'components/Span';
import { Label } from '../../styles';
import { DynamicFormsInputProps } from '../../types';
import InputDateComponent from 'components/InputDate';
import { useDynamicForm } from '../../context';
import { capitalizeFirstLetter } from 'utils/formaters';
import { DateModalParams } from 'components/Modal/Calendar';
import { checkUserEditPermission, normalizeExcelHours } from '../../utils';
import useUser from 'hooks/use-user';

export default function InputDate(props: DynamicFormsInputProps) {
  const { field, defaultValue, isRequired, onChange, isDisabled } = props;
  const [value, setValue] = useState(defaultValue);
  const { approvalData, fieldsAllowProps } = useDynamicForm();
  const dispatch = useDispatch();

  const user = useUser();

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    if (!defaultValue) {
      setValue(null);

      return;
    }

    if (field.type === 'time') {
      const timeValue = normalizeExcelHours(defaultValue);

      const tmp = timeValue.replace('h', '');

      setValue(`1970-01-01T${tmp}:00-03:00`);
    } else {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  function handleChange(name: string, datetime: Date | null) {
    setValue(datetime);

    const formatType = field.type === 'time' ? 'HH:mm' : 'YYYY-MM-DD HH:mm:ss';

    onChange(name, datetime ? moment(datetime).format(formatType) : null);

    if (field.showWeekday) {
      const weekCaptalized = datetime
        ? capitalizeFirstLetter(moment(datetime).format('dddd'))
        : '';
      onChange(`${name}_dia`, weekCaptalized);
    }
  }

  function showWeekday() {
    if (!field.showWeekday) {
      return <></>;
    }

    return (
      <b>
        <p>{capitalizeFirstLetter(moment(value).format('dddd'))}</p>
      </b>
    );
  }

  function handleShowModal() {
    dispatch(
      showModal<DateModalParams>(ModalName.DATE_TIME, {
        value,
        name: field.name,
        timeOnly: field.type === 'time',
        showTime: field.type === 'datetime',
        dateFormat: 'dd/mm/yy',
        onSelectDate: handleChange,
      }),
    );
  }

  function normalizeDate() {
    if (!value) {
      return '';
    }

    const normalizedDate = moment(value);

    if (!normalizedDate.isValid()) {
      return '';
    }

    if (field.type === 'time') {
      return normalizedDate.format('HH:mm');
    }

    if (field.type === 'datetime') {
      return normalizedDate.format('DD/MM/YYYY HH:mm:ss');
    }

    return normalizedDate.format('DD/MM/YYYY');
  }

  return (
    <>
      <Label
        htmlFor={field.name}
        dangerouslySetInnerHTML={{ __html: field.label }}
        isLabel={field.label}
      />

      {field.type !== 'time' ? (
        <Input
          onClick={handleShowModal}
          disabled={isDisableChecked}
          id={field.name}
          name={field.name}
          value={normalizeDate()}
          error={isRequired}
        />
      ) : null}

      {field.type === 'time' ? (
        <InputDateComponent
          disabled={isDisableChecked}
          id={field.name}
          name={field.name}
          value={value && moment(value).toDate()}
          onChange={e => handleChange(e.target.name, e.target.value)}
          dateFormat="dd/mm/yy"
          error={isRequired}
          timeOnly
        />
      ) : null}

      {showWeekday()}
      {isRequired && <Span text="Campo obrigatório" />}
    </>
  );
}
