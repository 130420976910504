import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const FisicoDash = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.cte.fisico.gerencial' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/cte/fisico/gerencial"
    component={FisicoDash}
    path="/relatorios/cte/fisico/gerencial"
  />,
];
