export function makeColorGradient(count: number, start: string, end: string) {
  const { r: rStart, g: gStart, b: bStart } = hexToRgb(start);
  const { r: rEnd, g: gEnd, b: bEnd } = hexToRgb(end);
  // calc deltas
  const rDelta = rEnd - rStart;
  const gDelta = gEnd - gStart;
  const bDelta = bEnd - bStart;
  // calc intervals
  const rInterval = rDelta / count;
  const gInterval = gDelta / count;
  const bInterval = bDelta / count;

  const gradientArray = [start];
  for (let i = 1; i < count - 1; i += 1) {
    const r = parseInt(rStart + i * rInterval);
    const g = parseInt(gStart + i * gInterval);
    const b = parseInt(bStart + i * bInterval);
    const hexVal = rgbToHex(r, g, b);
    gradientArray.push(hexVal);
  }

  gradientArray.push(end);

  return gradientArray;
}

function hexToRgb(hex) {
  const normalizeHex = hex => {
    if (hex.length === 7) {
      return hex;
    }
    const r = hex.slice(1, 2);
    const g = hex.slice(2, 3);
    const b = hex.slice(3, 4);
    return `#${r}${r}${g}${g}${b}${b}`;
  };

  const normalizedHex = normalizeHex(hex);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    normalizedHex,
  );
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function rgbToHex(r, g, b) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length == 1 ? `0${hex}` : hex;
}
