import useQuery from 'hooks/use-query';
import { formatNumber } from 'pages/Reports/utils/dataFunctions';
import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Customized } from 'recharts';
import { getSqlResponse } from 'services/dataset';
import { isObjectEmpty } from 'utils/common';

import { Container } from './styles';

interface Data {
  idPeriodo: number | string;
  totalNotas: number;
  naoConformidade: number;
  conformidade: number;
}

interface QueryProps {
  idDataset: number;
  params: string;
  height?: number;
  width?: number;
}
const colors = [
  '#01315f',
  '#0097d6',
  '#0fb7a8',
  '#06ac6e',
  '#fc3f55',
  '#fc946d',
  '#676b6a',
  '#ddd',
  '#ddcc56',
  '#ffbf59',
  '#b972fc',
  '#dc5fa3',
];

const percentFormatter = formatNumber({ style: 'percent', decimalPlaces: 2 });

export default function TopicOverview() {
  const [staticData, setStaticData] = useState<Data[]>([]);
  const [chartData, setChartData] = useState<Data[]>([]);
  const [tableData, setTableData] = useState<Data>({} as Data);
  const [ncRate, setNcRate] = useState(0);

  const dataUrl = useQuery<QueryProps>();

  useEffect(() => {
    initData();
  }, [dataUrl]);

  useEffect(() => {
    filterData();
  }, [staticData]);

  async function initData() {
    if (isObjectEmpty(dataUrl)) return;

    const { idDataset, params } = dataUrl;

    const normalizedParams = JSON.parse(decodeURIComponent(params));

    const staticDataAux: Data[] = await getSqlResponse(
      idDataset,
      normalizedParams,
    );
    if (!staticDataAux.length) return;

    // setTableData(staticDataAux);
    setStaticData(staticDataAux);
    setChartData(staticDataAux);
  }

  function filterData() {
    const filteredData = staticData[0]; //.find(row => row.idPeriodo == idPeriodo);
    setTableData(filteredData);

    const ncRateAux = filteredData
      ? filteredData.naoConformidade / filteredData.totalNotas
      : 0;
    setNcRate(ncRateAux);
  }

  function renderNcRate(localProps) {
    const { children } = localProps;
    const { cx, cy } = children[0].props;

    return (
      <g>
        <text x={cx} y={cy + 5} textAnchor="middle" fontSize={24}>
          {percentFormatter(ncRate)}
        </text>
        <text x={cx} y={cy + 26} textAnchor="middle" fontSize={13}>
          Taxa de NC
        </text>
      </g>
    );
  }

  function renderChart() {
    return (
      <PieChart width={500} height={500}>
        <Pie
          data={chartData}
          dataKey="totalNotas"
          nameKey="totalNotas"
          outerRadius={80}
          fill="red"
          label
        >
          {chartData?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index]} />
          ))}
        </Pie>
        <Customized component={renderNcRate} />
      </PieChart>
    );
  }

  function renderTable() {
    if (!tableData) return <></>;
    const { conformidade, totalNotas, naoConformidade } = tableData;
    const ncPercent = naoConformidade / totalNotas;
    const confPercent = conformidade / totalNotas;

    return (
      <div
        style={{
          height: '64px',
          fontSize: '14px',
        }}
      >
        <table width="300px">
          <tr>
            <td
              style={{
                width: '180px',
              }}
            >
              Total de entregas
            </td>
            <td
              style={{
                width: '80px',
                textAlign: 'center',
              }}
            >
              {totalNotas}
            </td>
            <td
              style={{
                width: '80px',
                textAlign: 'center',
              }}
            >
              100%
            </td>
          </tr>
          <tr>
            <td>Entregas sem NC</td>
            <td
              style={{
                textAlign: 'center',
              }}
            >
              {conformidade}
            </td>
            <td
              style={{
                textAlign: 'center',
              }}
            >
              {percentFormatter(confPercent)}
            </td>
          </tr>
          <tr>
            <td>Entregas com NC</td>
            <td
              style={{
                textAlign: 'center',
              }}
            >
              {naoConformidade}
            </td>
            <td
              style={{
                textAlign: 'center',
              }}
            >
              {percentFormatter(ncPercent)}
            </td>
          </tr>
        </table>
      </div>
    );
  }

  const { width, height } = dataUrl;

  return (
    <Container width={width} height={height}>
      <div>{renderChart()}</div>

      <div>{renderTable()}</div>
    </Container>
  );
}
