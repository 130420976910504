/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import api from './api';
import { DatasetParams } from '../store/report/types';

export function getCompanyId(): Promise<any> {
  return api.get('/portal/get-company-id.json');
}

export function getLayout(layoutId: number): Promise<any> {
  return api.get(`/portal/get-layout/${layoutId}.json`);
}

export function getMobileLayout(layoutId: number): Promise<any> {
  return api.get(`/portal/get-mobile-layout/${layoutId}.json`);
}

/**
 * @deprecated Function deprecated, use getSqlResponse instead.
 */
export function getDataset(
  datasetId: number,
  data: DatasetParams[],
): Promise<any> {
  return api.post(`/portal/get-dataset/${datasetId}.json`, data);
}

export function getTopMenu(): Promise<any> {
  return api.get('/portal/get-top-menu.json');
}

export function getMenu(userIdParam = 0, idEmpresa): Promise<any> {
  const userId = userIdParam || 0;

  return api.get(`/portal/get-menu/${userId}/${idEmpresa}.json`);
}

export function getReportMenu(moduleId: number): Promise<any> {
  return api.get(`/portal/get-report-menu/${moduleId}.json`);
}

export function getImpAuth(): Promise<any> {
  return api.get('/portal/get-imp-auth.json');
}

// Imp suffix refers to impersonate functions that receives impersonateId as parameter
export function getTopMenuImp(impersonateId: number): Promise<any> {
  return api.get(`/portal/get-top-menu-imp/${impersonateId}.json`);
}

export function getReportMenuImp(
  impersonateId: number,
  moduleId: number,
): Promise<any> {
  return api.get(
    `/portal/get-report-menu-imp/${impersonateId}/${moduleId}.json`,
  );
}

export function getCompanyIdImp(impersonateId: number): Promise<any> {
  return api.get(`/portal/get-company-id-imp/${impersonateId}.json`);
}

export function isContractInJobQueue(contractId: number): Promise<any> {
  return api.get(`/portal/is-contract-in-job-queue/${contractId}.json`);
}
