import React, { ReactElement } from 'react';
import { Container } from './styles';

interface Props {
  text: string;
  color?: string;
  backgroundColor?: string;
}

export default function Badge(props: Props): ReactElement {
  const { text } = props;

  return (
    <Container {...props}>
      <span>{text}</span>
    </Container>
  );
}
