import { PropsHandleClick } from 'pages/Reports/components/tables/SimpleTable/types';
import React from 'react';
import { LinkIcon } from './styles';
import { Field } from 'components/Widgets/DynamicForm/types';

interface Props extends PropsHandleClick {
  field: Field;
}

export default function Redirect(props: Props) {
  const { row, field } = props;

  if (!row[field.name]) {
    return null;
  }

  function normalizeLink() {
    if (!row[field.name].includes('http')) {
      return `http://${row[field.name]}`;
    }

    return row[field.name];
  }

  return (
    <a
      target="_blank"
      onClick={e => e.stopPropagation()}
      href={normalizeLink()}
      rel="noreferrer"
    >
      <LinkIcon />
    </a>
  );
}
