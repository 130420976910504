import { TreeViewItem } from '../store/types';

interface NormalizeProps {
  node: TreeViewItem[];
  items: TreeViewItem[];
  currentKey: string | number;
}

export function normalizeNode(props: NormalizeProps): TreeViewItem[] {
  const { node, items, currentKey } = props;

  /* Return items when is the first data */
  if (!node.length) {
    return items;
  }

  const newNode = [...node];

  /* Compare the keys and set items in children node */
  const checkDeepMerge = (item: TreeViewItem) => {
    if (item.key === currentKey) {
      item.children = items;

      /* Throw a fake error to stop the loop */
      throw new Error('Finished');
    }
  };

  const deepFind = (item: TreeViewItem) => {
    /* Check if is the same item, prevent unnecessary loop */
    const regex = new RegExp(`${item.key}.*`);
    if (!currentKey.toString().match(regex)) {
      return;
    }

    /* Run recursively when the node has childrens */
    if (item.children) {
      item.children.forEach(deepFind);
    } else {
      checkDeepMerge(item);
    }
  };

  try {
    newNode.forEach(deepFind);
  } catch (error) {
    return newNode;
  }

  return node;
}

export function checkKey(currentItem: string, toCompare: string) {
  const normalizedItem =
    currentItem.toString().length === 1 ? `${currentItem}-` : currentItem;

  const regex = new RegExp(`^${normalizedItem}.*$.*`);

  return toCompare.toString().match(regex);
}

function getSplitKeys(keysList: string | number) {
  const parsedKeysList = keysList.toString();

  return parsedKeysList.includes('-')
    ? parsedKeysList.split('-')
    : [parsedKeysList];
}

export function compareKeys(
  parentItem: string | number,
  childItem: string | number,
) {
  const parentItemKeys = getSplitKeys(parentItem.toString());
  const childItemKeys = getSplitKeys(childItem.toString());

  return parentItemKeys.every((value, index) => childItemKeys[index] === value);
}

export function getTreeviewDescriptionPath(
  itemKey: string,
  treeviewRows: any[],
): string {
  const arraykeys = itemKey.toString().split('-');

  return treeviewRows
    .filter(
      row =>
        row?.idMedicaoServico &&
        arraykeys.includes(row.idMedicaoServico.toString()),
    )
    .map(row => row.descricao)
    .join(' > ');
}
