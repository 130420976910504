import { UserState } from 'store/user/types';
import apiAdonis from './apiAdonis';

export interface PayloadStore {
  idUsuario: string;
}

export function storeContractByEnterprise(
  data: PayloadStore,
): Promise<boolean> {
  return apiAdonis.post('/usuario-contrato/store-contract-by-enterprise', data);
}

export function resetUserEnterprise(user: UserState) {
  const { flagRecarregaEmpreendimento, idUsuario } = user;

  if (flagRecarregaEmpreendimento) {
    return storeContractByEnterprise({ idUsuario: idUsuario!.toString() });
  }
}
