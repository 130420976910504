import styled from 'styled-components';
import { FiFilter } from 'react-icons/fi';

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  .filter-icon-container {
    display: flex;
    cursor: pointer;
  }
`;

export const FilterContainer = styled.div`
  display: none;
  position: absolute;
  top: 42px;
  background-color: white;
  padding: 5px 10px;
  border: 1px solid #ccc;
  box-shadow: 0.5px 0.5px 2px 0px #aaa;
  min-width: 170px;

  p {
    margin-bottom: 3px;
    font-weight: 500;
    text-align: left;
    padding-left: 9px;
    font-size: 14px;
  }
`;

export const FilterIcon = styled(FiFilter)`
  stroke-width: 1;
`;

export const FilterButton = styled.div`
  position: relative;
  cursor: pointer;
  margin-left: 29px;
  margin-bottom: 12px;
  margin-top: 3px;
  border: 1px solid #ccc;
  padding: 3px 5px;
  background-color: #f3f3f3;
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  width: fit-content;
`;

export const SelectAllWrapper = styled.div`
  margin-left: 1px;
  margin-bottom: 8px;
  max-width: 190px;

  span {
    cursor: pointer;
    font-size: 11px;
    margin-bottom: 2px;
    margin-left: 17px;
    color: #555;
    display: block;
    position: relative;
    text-align: left;

    &:hover {
      background-color: #f1f1f1;
      font-weight: 500;
      padding-left: 3px;
    }

    &::before {
      content: '';
      width: 12px;
      height: 12px;
      border: 1px solid #7a7a7a;
      border-radius: 2px;
      display: inline-block;
      position: absolute;
      left: -18px;
      top: 2px;
    }

    &:first-child::after {
      content: '';
      border-left: 2px solid #333;
      border-bottom: 2px solid #333;
      display: inline-block;
      position: absolute;
      left: -16px;
      top: 4px;
      width: 8px;
      height: 5px;
      transform: rotate(-45deg);
    }
  }
`;

export const FilterList = styled.div`
  max-width: 180px;
  max-height: 184px;
  overflow: scroll;
  padding-right: 20px;

  .input-wrapper {
    display: flex;
    cursor: pointer;

    & * {
      cursor: pointer;
    }

    label {
      margin-left: 6px;
      font-weight: 400;
      font-size: 12px;
      text-align: left;

      &:hover {
        font-weight: 500 !important;
      }
    }
  }
`;
