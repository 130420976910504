import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const FisicoDash = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.ekko.fisico.gerencial' */
  ),
);
const Gerencial = lazy(() =>
  import(
    './layouts/gerencial' /* webpackChunkName: 'relatorios.ekko.gerencial' */
  ),
);
const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'relatorios.ekko.financeiro.obras' */
  ),
);
const FinanceiroPortfolio = lazy(() =>
  import(
    './layouts/financeiro/portfolio' /* webpackChunkName: 'relatorios.ekko.financeiro.portfolio' */
  ),
);
const CkGerencial = lazy(() =>
  import(
    './layouts/seguranca/relatorioGerencial' /* webpackChunkName: 'relatorios.ekko.checklist.relatorio-gerencial' */
  ),
);
const CkGerencialGroup = lazy(() =>
  import(
    './layouts/seguranca/relatorioGerencialGroup' /* webpackChunkName: 'relatorios.ekko-group.checklist.relatorio-gerencial' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/ekko/fisico/gerencial"
    component={FisicoDash}
    path="/relatorios/ekko/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/ekko/gerencial"
    component={Gerencial}
    path="/relatorios/ekko/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/ekko/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/ekko/financeiro/obras"
  />,
  <PrivateRoute
    key="/relatorios/ekko/financeiro/portfolio"
    component={FinanceiroPortfolio}
    path="/relatorios/ekko/financeiro/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/ekko/checklist/relatorio-gerencial"
    component={CkGerencial}
    path="/relatorios/ekko/checklist/relatorio-gerencial"
  />,
  <PrivateRoute
    key="/relatorios/ekko-group/checklist/relatorio-gerencial"
    component={CkGerencialGroup}
    path="/relatorios/ekko-group/checklist/relatorio-gerencial"
  />,
];
