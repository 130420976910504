import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.mpd.fisico.gerencial' */
  ),
);
const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'relatorios.mpd.financeiro.obras' */
  ),
);
const FisicoPortfolio = lazy(() =>
  import(
    './layouts/financeiro/portfolio' /* webpackChunkName: 'relatorios.mpd.financeiro.portfolio' */
  ),
);
const FvmGerencial = lazy(() =>
  import(
    './layouts/fvm/gerencial' /* webpackChunkName: 'relatorios.mpd.fvm.gerencial' */
  ),
);
const UtilizacaoComportamental = lazy(() =>
  import(
    './layouts/seguranca/UtilizacaoComportamental' /* webpackChunkName: 'relatorios.mpd.seguranca.utilizacao-comportamental' */
  ),
);
const DashboardComportamental = lazy(() =>
  import(
    './layouts/seguranca/DashboardComportamental' /* webpackChunkName: 'relatorios.mpd.seguranca.dashboard-comportamental' */
  ),
);
const DashboardNRs = lazy(() =>
  import(
    './layouts/seguranca/DashboardNRs' /* webpackChunkName: 'relatorios.mpd.seguranca.dashboard-nrs' */
  ),
);
const DashboardAcidentes = lazy(() =>
  import(
    './layouts/seguranca/DashboardAcidentes' /* webpackChunkName: 'relatorios.mpd.seguranca.dashboard-acidentes' */
  ),
);
const ChecklistSeguranca = lazy(() =>
  import(
    './layouts/seguranca/ChecklistSeguranca' /* webpackChunkName: 'relatorios.mpd.seguranca.checklist-de-seguranca' */
  ),
);
const Gerencial = lazy(() =>
  import(
    './layouts/gerencial' /* webpackChunkName: 'relatorios.mpd.gerencial' */
  ),
);
const GeralDeSeguranca = lazy(() =>
  import(
    './layouts/seguranca/relatorioGeralDeSeguranca' /* webpackChunkName: 'mpd.seguranca.relatorio-geral-de-seguranca' */
  ),
);
const DashboardRegraOuro = lazy(() =>
  import(
    './layouts/seguranca/RegraOuro' /* webpackChunkName: 'mpd.seguranca.dashboard-regra-ouro' */
  ),
);
const DashboardStopWork = lazy(() =>
  import(
    './layouts/seguranca/DashboardStopWork' /* webpackChunkName: 'mpd.seguranca.dashboard-stop-work' */
  ),
);
const DashboardPatrulha = lazy(() =>
  import(
    './layouts/seguranca/DashboardPatrulha' /* webpackChunkName: 'mpd.seguranca.dashboard-patrulha' */
  ),
);
const DashboardIncidentes = lazy(() =>
  import(
    './layouts/seguranca/DashboardIncidentes' /* webpackChunkName: 'mpd.seguranca.dashboard-incidentes' */
  ),
);
const DashboardMeioAmbiente = lazy(() =>
  import(
    './layouts/qualidade/dashboardMeioAmbiente' /* webpackChunkName: 'mpd.qualidade.dashboard-meio-ambiente' */
  ),
);
const GeralMeioAmbiente = lazy(() =>
  import(
    './layouts/meioAmbiente/dashboardGeral' /* webpackChunkName: 'mpd.meio-ambiente.geral-meio-ambiente' */
  ),
);

const ComportamentalDashboard = lazy(() =>
  import(
    './layouts/seguranca/IPS' /* webpackChunkName: 'relatorios.mpd.seguranca.comportamental-dashboard' */
  ),
);
const DashboardQualidadeMeioAmbienteIndices = lazy(() =>
  import(
    './layouts/qualidade/meioAmbienteIndices' /* webpackChunkName: 'relatorios.mpd.qualidade.meio-ambiente-indices' */
  ),
);
const DashboardQualidadeMeioAmbienteIndicesComparativo = lazy(() =>
  import(
    './layouts/qualidade/meioAmbienteIndicesComparativo' /* webpackChunkName: 'relatorios.mpd.qualidade.meio-ambiente-indices-comparativo' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/mpd/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/mpd/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/mpd/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/mpd/financeiro/obras"
  />,
  <PrivateRoute
    key="/relatorios/mpd/financeiro/portfolio"
    component={FisicoPortfolio}
    path="/relatorios/mpd/financeiro/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/mpd/fvm/gerencial"
    component={FvmGerencial}
    path="/relatorios/mpd/fvm/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/mpd/seguranca/utilizacao-comportamental"
    component={UtilizacaoComportamental}
    path="/relatorios/mpd/seguranca/utilizacao-comportamental"
  />,
  <PrivateRoute
    key="/relatorios/mpd/seguranca/dashboard-comportamental"
    component={DashboardComportamental}
    path="/relatorios/mpd/seguranca/dashboard-comportamental"
  />,
  <PrivateRoute
    key="/relatorios/mpd/seguranca/comportamental-dashboard"
    component={ComportamentalDashboard}
    path="/relatorios/mpd/seguranca/comportamental-dashboard"
  />,
  <PrivateRoute
    key="/relatorios/mpd/seguranca/dashboard-nrs"
    component={DashboardNRs}
    path="/relatorios/mpd/seguranca/dashboard-nrs"
  />,
  <PrivateRoute
    key="/relatorios/mpd/seguranca/dashboard-acidentes"
    component={DashboardAcidentes}
    path="/relatorios/mpd/seguranca/dashboard-acidentes"
  />,
  <PrivateRoute
    key="/relatorios/mpd/seguranca/checklist-de-seguranca"
    component={ChecklistSeguranca}
    path="/relatorios/mpd/seguranca/checklist-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/mpd/gerencial"
    component={Gerencial}
    path="/relatorios/mpd/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/mpd/seguranca/relatorio-geral-de-seguranca"
    component={GeralDeSeguranca}
    path="/relatorios/mpd/seguranca/relatorio-geral-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/mpd/seguranca/regra-ouro"
    component={DashboardRegraOuro}
    path="/relatorios/mpd/seguranca/regra-ouro"
  />,
  <PrivateRoute
    key="/relatorios/mpd/seguranca/stop-work"
    component={DashboardStopWork}
    path="/relatorios/mpd/seguranca/stop-work"
  />,
  <PrivateRoute
    key="/relatorios/mpd/seguranca/patrulha"
    component={DashboardPatrulha}
    path="/relatorios/mpd/seguranca/patrulha"
  />,
  <PrivateRoute
    key="/relatorios/mpd/seguranca/incidentes"
    component={DashboardIncidentes}
    path="/relatorios/mpd/seguranca/incidentes"
  />,
  <PrivateRoute
    key="/relatorios/mpd/qualidade/meio-ambiente"
    component={DashboardMeioAmbiente}
    path="/relatorios/mpd/qualidade/meio-ambiente"
  />,
  <PrivateRoute
    key="/relatorios/mpd/qualidade/meio-ambiente-indices"
    component={DashboardQualidadeMeioAmbienteIndices}
    path="/relatorios/mpd/qualidade/meio-ambiente-indices"
  />,
  <PrivateRoute
    key="/relatorios/mpd/qualidade/meio-ambiente-indices-comparativo"
    component={DashboardQualidadeMeioAmbienteIndicesComparativo}
    path="/relatorios/mpd/qualidade/meio-ambiente-indices-comparativo"
  />,
  <PrivateRoute
    key="/relatorios/mpd/meioambiente/dashboard-geral"
    component={GeralMeioAmbiente}
    path="/relatorios/mpd/meioambiente/dashboard-geral"
  />,
];
