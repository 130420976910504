import useModal from 'hooks/use-modal';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import {
  ButtonClose,
  ModalContainer,
  ModalHeader,
  ModalTitle,
} from 'styles/modal';
import Modal from 'components/Modal';
import InputMaskComponent from 'react-input-mask';
import { Label } from 'styles/form';
import Input from 'components/Input';
import Button from 'components/Button';
import { getSqlResponseAdonis } from 'services/dataset';
import { Footer } from './styles';
import useUser from 'hooks/use-user';

interface ResponseDataCNPJS {
  cnpjObra: string | number;
}

const removeMask = cnpj => cnpj.toString().replace(/\D/g, '');

export default function CheckCNPJModal() {
  const { isOpen, windowSec } = useModal(ModalName.CHECK_CNPJ);
  const [CNPJs, setCNPJs] = useState<ResponseDataCNPJS[]>([]);
  const [CNPJToConsult, setCNPJToConsult] = useState<string>('');

  const dispatch = useDispatch();
  const { idEmpresa } = useUser();

  useEffect(() => {
    if (isOpen) {
      setCNPJToConsult('');
      initData();
    }
  }, [isOpen]);

  async function initData() {
    const responseDataAux = await getSqlResponseAdonis<ResponseDataCNPJS[]>(
      4538,
      { idEmpresa },
    );

    setCNPJs(responseDataAux);
  }

  function handleClose() {
    dispatch(closeModal(ModalName.CHECK_CNPJ));
  }

  function cnpjAlreadyExists() {
    const CNPJExists = CNPJs.some(
      cnpj => removeMask(cnpj.cnpjObra) === CNPJToConsult,
    );

    if (!CNPJExists) return false;

    return true;
  }

  function handleClick() {
    if (CNPJToConsult.length !== 14) return;

    if (cnpjAlreadyExists()) {
      dispatch(showModal(ModalName.OPENING_CALL, { isNewModule: true }));
    } else {
      dispatch(
        showModal(ModalName.NEW_WORK_REGISTRATION, { CNPJ: CNPJToConsult }),
      );
    }

    dispatch(closeModal(ModalName.CHECK_CNPJ));
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const CNPJWithoutMask = removeMask(e.target.value);

    setCNPJToConsult(CNPJWithoutMask);
  }

  return (
    <Modal isOpen={isOpen} windowSec={windowSec}>
      <ModalHeader>
        <ButtonClose onClick={handleClose} />

        <ModalTitle>Consultar CNPJ</ModalTitle>
      </ModalHeader>

      <ModalContainer width="45vw" height="15vh">
        <div>
          <Label>CNPJ</Label>
          <InputMaskComponent
            mask="99.999.999/9999-99"
            id="cnpj"
            name="cnpj"
            onChange={handleChange}
            value={CNPJToConsult}
          >
            {inputProps => <Input {...inputProps} />}
          </InputMaskComponent>
        </div>
      </ModalContainer>
      <Footer>
        <Button text="Buscar" primary onClick={handleClick} />
      </Footer>
    </Modal>
  );
}
