import React, { useEffect, useState } from 'react';
import { getSqlResponse } from 'services/dataset';
import { RowTitle, TitleWrapper } from '../../styles';
import { Field, State } from '../../types';
import { useDynamicForm } from '../../context';
import { TableStyled, TableWrapper, Th } from './styles';

interface Props {
  field: Field;
}

export default function TableDataset(props: Props) {
  const [data, setData] = useState<State[]>([]);
  const { reportData, globalState, contractId: idContrato } = useDynamicForm();

  const { field } = props;

  useEffect(() => {
    if (globalState) {
      getData();
    }
  }, [field.datasetId, globalState]);

  async function getData() {
    const { datasetParams } = globalState!;

    const params = {
      idContrato,
      ...datasetParams,
    };

    const res = await getSqlResponse<State[]>(field.datasetId!, params, true);
    setData(res);

    reportData.current[field.name] = res;
  }

  function renderColumns(rowData: State) {
    return field.fields.map(tableField => {
      return (
        <td key={`${rowData.uuid}-${tableField.name}`}>
          {rowData[tableField.name]}
        </td>
      );
    });
  }

  function renderRows() {
    return data.map(rowData => (
      <tr key={rowData.uuid}>{renderColumns(rowData)}</tr>
    ));
  }

  return (
    <TableWrapper>
      <TitleWrapper>
        {field.label && (
          <RowTitle dangerouslySetInnerHTML={{ __html: field.label }} />
        )}
      </TitleWrapper>

      <TableStyled>
        <thead>
          <tr>
            {field.fields.map(configField => (
              <Th key={`h-${configField.name}`} size={configField.size}>
                {configField.label}
              </Th>
            ))}
          </tr>
        </thead>

        <tbody>{renderRows()}</tbody>
      </TableStyled>
    </TableWrapper>
  );
}
