import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const ChecklistInspecao = lazy(() =>
  import(
    './layouts/gerencial/checklistInspecao' /* webpackChunkName: 'relatorios.cury.gerencial.checklist-inspecao' */
  ),
);
const ChecklistSeguranca = lazy(() =>
  import(
    './layouts/seguranca/checklistDeSeguranca' /* webpackChunkName: 'relatorios.cury.seguranca.checklist-de-seguranca' */
  ),
);
const RelatorioGeralDeSeguranca = lazy(() =>
  import(
    './layouts/seguranca/relatorioGeralDeSeguranca' /* webpackChunkName: 'relatorios.cury.seguranca.relatorio-geral-de-seguranca' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/cury/gerencial/checklist-inspecao"
    component={ChecklistInspecao}
    path="/relatorios/cury/gerencial/checklist-inspecao"
  />,
  <PrivateRoute
    key="/relatorios/cury/seguranca/checklist-de-seguranca"
    component={ChecklistSeguranca}
    path="/relatorios/cury/seguranca/checklist-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/cury/seguranca/relatorio-geral-de-seguranca"
    component={RelatorioGeralDeSeguranca}
    path="/relatorios/cury/seguranca/relatorio-geral-de-seguranca"
  />,
];
