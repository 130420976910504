import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'relatorios.mitre.financeiro.obras' */
  ),
);
const FinanceiroPortfolio = lazy(() =>
  import(
    './layouts/financeiro/portfolio' /* webpackChunkName: 'relatorios.mitre.financeiro.portfolio' */
  ),
);
const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.mitre.fisico.gerencial' */
  ),
);
const FvmDash = lazy(() =>
  import(
    './layouts/fvm/materiais' /* webpackChunkName: 'relatorios.mitre.fvm.dashboard-materiais' */
  ),
);
const LinhaDeBalanco = lazy(() =>
  import(
    './layouts/fisico/linhaDeBalanco' /* webpackChunkName: 'relatorios.mitre.fisico.linha-de-balanco' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/mitre/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/mitre/financeiro/obras"
  />,
  <PrivateRoute
    key="/relatorios/mitre/financeiro/portfolio"
    component={FinanceiroPortfolio}
    path="/relatorios/mitre/financeiro/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/mitre/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/mitre/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/mitre/fvm/dashboard-materiais"
    component={FvmDash}
    path="/relatorios/mitre/fvm/dashboard-materiais"
  />,
  <PrivateRoute
    key="/relatorios/mitre/fisico/linha-de-balanco"
    component={LinhaDeBalanco}
    path="/relatorios/mitre/fisico/linha-de-balanco"
  />,
];
