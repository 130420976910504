import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const GerencialMensal = lazy(() =>
  import(
    './layouts/gerencial/mensal' /* webpackChunkName: 'relatorios.kallas.gerencial.mensal' */
  ),
);
const AcompanhamentoSemanal = lazy(() =>
  import(
    './layouts/fisico/acompanhamentoSemanal' /* webpackChunkName: 'relatorios.kallas.fisico.acompanhamento-semanal' */
  ),
);
const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.kallas.fisico.gerencial' */
  ),
);
const FisicoPortfolio = lazy(() =>
  import(
    './layouts/fisico/portfolio' /* webpackChunkName: 'relatorios.kallas.fisico.portfolio' */
  ),
);
const FisicoPortfolioSemanal = lazy(() =>
  import(
    './layouts/fisico/portfolioSemanal' /* webpackChunkName: 'relatorios.kallas.fisico.portfolio-semanal' */
  ),
);
const LinhaDeBalanco = lazy(() =>
  import(
    './layouts/fisico/linhaDeBalanco' /* webpackChunkName: 'relatorios.kallas.fisico.linha-de-balanco' */
  ),
);

const FSemanal = lazy(() =>
  import(
    './layouts/fsemanal' /* webpackChunkName: 'relatorios.kallas.fisico.portfolio-semanal' */
  ),
);
export const routes = [
  <PrivateRoute
    key="/relatorios/kallas/gerencial/mensal"
    component={GerencialMensal}
    path="/relatorios/kallas/gerencial/mensal"
  />,
  <PrivateRoute
    key="/relatorios/kallas/fisico/acompanhamento-semanal"
    component={AcompanhamentoSemanal}
    path="/relatorios/kallas/fisico/acompanhamento-semanal"
  />,
  <PrivateRoute
    key="/relatorios/kallas/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/kallas/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/kallas/fisico/portfolio"
    component={FisicoPortfolio}
    path="/relatorios/kallas/fisico/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/kallas/fisico/portfolio-semanal"
    component={FisicoPortfolioSemanal}
    path="/relatorios/kallas/fisico/portfolio-semanal"
  />,
  <PrivateRoute
    key="/relatorios/kallas/fisico/linha-de-balanco"
    component={LinhaDeBalanco}
    path="/relatorios/kallas/fisico/linha-de-balanco"
  />,
  <PrivateRoute
    key="/kallas/fsemanal"
    component={FSemanal}
    path="/kallas/fsemanal"
  />,
];
