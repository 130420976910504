import { ModalParams } from 'pages/UsersManagement/components/CreateUserModal/types';
import Spinner from 'components/Spinner';
import UserCreatableSelect from 'components/Widgets/UserCreatableSelect';
import useUser from 'hooks/use-user';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { getSqlResponse } from 'services/dataset';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { OptionsType } from 'store/types';
import { EditIcon, RemoveIcon } from 'styles/icons';
import { useDynamicForm } from '../../context';
import { Field, State } from '../../types';
import { checkUserEditPermission } from '../../utils';
import {
  ActionWrapper,
  SelectUserWrapper,
  TableStyled,
  TableWrapper,
  Th,
  ThAction,
} from './styles';

interface Props {
  field: Field;
  setFormAnswer(name: string, value: State[]): void;
  isDisabled?: boolean;
}

export default function TableUser(props: Props) {
  const [data, setData] = useState<State[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { globalState, approvalData, fieldsAllowProps } = useDynamicForm();
  const dispatch = useDispatch();

  const user = useUser();

  const { field, isDisabled } = props;

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    const { defaultFormData } = globalState!;
    const usersId = defaultFormData?.form[field.name] || [];

    getData(usersId);
  }, [globalState]);

  async function getData(usersId: number[]) {
    if (!usersId.length) {
      return;
    }

    setIsLoading(true);

    const res = await getSqlResponse<State[]>(
      field.datasetIdTable!,
      { idUsuario: usersId.join(',') },
      true,
    );

    setData(res);

    const { setFormAnswer } = props;

    setFormAnswer('tableUserForEmail', res);

    setIsLoading(false);
  }

  function handleRemove(userId: number) {
    const removedAnsers = data.filter(answer => answer.id_usuario !== userId);
    setData(removedAnsers);

    const { setFormAnswer } = props;

    setFormAnswer(
      field.name,
      removedAnsers.map(user => user.id_usuario),
    );
  }

  function openFormModal(rowData: State) {
    if (isDisableChecked) {
      return;
    }

    dispatch(
      showModal<ModalParams>(ModalName.CREATE_USER_SIMPLE, {
        idOrUuidUser: rowData.id_usuario!,
        idEmpresa: user.idEmpresa!,
        afterSubmit: newUser =>
          handleSetUser({
            label: newUser.nome,
            value: newUser.idUsuario as number,
          }),
      }),
    );
  }

  function handleSetUser(userOption: OptionsType) {
    const { setFormAnswer } = props;

    const previousIds = data.map(user => user.id_usuario);

    const newUsers = [...previousIds, userOption.value];

    setFormAnswer(field.name, newUsers);

    getData(newUsers);
  }

  function renderColumns(rowData: State) {
    const normalizeText = (tableField: Field) => {
      return rowData[tableField.name];
    };

    const columns = field.fields.map(tableField => {
      return (
        <td key={`${rowData.uuid}-${tableField.name}`}>
          {normalizeText(tableField)}
        </td>
      );
    });

    if (isDisableChecked) {
      return columns;
    }

    return (
      <>
        {columns}
        <td>
          <ActionWrapper>
            <RemoveIcon onClick={() => handleRemove(rowData.id_usuario)} />
            <EditIcon onClick={() => openFormModal(rowData)} />
          </ActionWrapper>
        </td>
      </>
    );
  }

  function renderRows() {
    return data.map(rowData => (
      <tr key={rowData.uuid}>{renderColumns(rowData)}</tr>
    ));
  }

  return (
    <TableWrapper>
      <SelectUserWrapper>
        <UserCreatableSelect
          isAutoClearAfterChange
          label={field.label}
          placeholder={field.placeholder}
          datasetId={field.datasetIdInputSelect!}
          setUser={handleSetUser}
        />
      </SelectUserWrapper>

      {isLoading ? <Spinner /> : null}

      <TableStyled>
        <thead>
          <tr>
            {field.fields.map(configField => (
              <Th key={`h-${configField.name}`} size={configField.size}>
                {configField.label}
              </Th>
            ))}

            {isDisableChecked ? null : <ThAction />}
          </tr>
        </thead>

        <tbody>{renderRows()}</tbody>
      </TableStyled>
      <ReactTooltip />
    </TableWrapper>
  );
}
