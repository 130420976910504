import { CatalogListContract } from '../store/types';
import api from './api';
import apiAdonis from './apiAdonis';

export function bulkInsertOrUpdate(
  data: CatalogListContract[],
): Promise<CatalogListContract> {
  return api.post(`/catalogo-listas-contrato/bulk-insert-or-update`, data);
}

export function create(
  data: CatalogListContract,
): Promise<CatalogListContract> {
  return api.post(`/catalogo-listas-contrato/create`, data);
}

export function update(data: CatalogListContract): Promise<void> {
  return api.post(`/catalogo-listas-contrato/update/${data.id}`, data);
}

export function updateOrCreateMany(
  payload: Partial<CatalogListContract>[],
): Promise<CatalogListContract[]> {
  return apiAdonis.post(`/catalogo-listas-contrato/update-or-create-many`, {
    payload,
  });
}
