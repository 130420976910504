import { SupplierFormated } from 'components/CreateUserModal/types';
import { EnumProfile, OptionsType } from '../types';

export const SET_USER_INFO = 'user/SET_USER_INFO';
export const SET_USER_PROFILE_MODULE = 'user/SET_USER_PROFILE_MODULE';
export const SET_USER_ACTIONS = 'user/SET_USER_ACTIONS';

export interface UserState {
  idUsuario: number | null;
  idEmpresa: number | null;
  empresa: string | null;
  nome: string | null;
  sobrenome: string | null;
  apelido: string | null;
  email: string | null;
  status: number | null;
  idPerfil: number | null;
  idUserModelo: number | null;
  flagRecarregarAcessos: number | null;
  flagRecarregaEmpreendimento: number | null;
  idPerfilModulo: EnumProfile | null;
  idAcoes: string | null;
}

export interface UserToCreate {
  idUsuario?: number | null;
  idEmpresa: number | null;
  idModelo?: number | null;
  nome: string;
  sobrenome: string;
  apelido?: string;
  email: string;
  telefone?: string;
  fornecedores?: OptionsType[] | SupplierFormated[];
  idCargo?: number | null;
  cargo?: string;
  flagStatus: number | boolean;
  uuid?: string;
  idUserModelo?: number | null;
}

interface SetUserInfoAction {
  type: typeof SET_USER_INFO;
  payload: object;
}

interface SetUserProfileModuleAction {
  type: typeof SET_USER_PROFILE_MODULE;
  payload: EnumProfile | null;
}

interface SetUserActionsAction {
  type: typeof SET_USER_ACTIONS;
  payload: string;
}

export type UserActionTypes =
  | SetUserInfoAction
  | SetUserProfileModuleAction
  | SetUserActionsAction;
