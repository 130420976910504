import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 8rem);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const InputsWrapper = styled.div`
  flex: 1;
`;

export const Footer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
`;
