import { Contract } from '../types';
import {
  CLEAR_CONTRACT,
  ContractActionTypes,
  SET_CONTRACT,
  SET_DATASET_ID,
  CLEAR_DATASET_ID,
} from './types';

export function setDatasetID(datasetId: number): ContractActionTypes {
  return {
    type: SET_DATASET_ID,
    datasetId,
  };
}

export function clearDatasetID(): ContractActionTypes {
  return {
    type: CLEAR_DATASET_ID,
  };
}

export function set(contract: Contract): ContractActionTypes {
  return {
    type: SET_CONTRACT,
    payload: contract,
  };
}

export function clear(): ContractActionTypes {
  return {
    type: CLEAR_CONTRACT,
  };
}
