import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const UtilizacaoComportamental = lazy(() =>
  import(
    './layouts/seguranca/UtilizacaoComportamental' /* webpackChunkName: 'relatorios.forcasa.seguranca.utilizacao-comportamental' */
  ),
);
const DashboardComportamental = lazy(() =>
  import(
    './layouts/seguranca/DashboardComportamental' /* webpackChunkName: 'relatorios.forcasa.seguranca.dashboard-comportamental' */
  ),
);
const DashboardNRs = lazy(() =>
  import(
    './layouts/seguranca/DashboardNRs' /* webpackChunkName: 'relatorios.forcasa.seguranca.dashboard-nrs' */
  ),
);
const DashboardAcidentes = lazy(() =>
  import(
    './layouts/seguranca/DashboardAcidentes' /* webpackChunkName: 'relatorios.forcasa.seguranca.dashboard-acidentes' */
  ),
);
const ChecklistSeguranca = lazy(() =>
  import(
    './layouts/seguranca/ChecklistSeguranca' /* webpackChunkName: 'relatorios.forcasa.seguranca.checklist-de-seguranca' */
  ),
);
const DashboardQualidadeIQ = lazy(() =>
  import(
    './layouts/qualidade/IQ' /* webpackChunkName: 'relatorios.forcasa.qualidade.iq' */
  ),
);
const QuadroComparativoIQ = lazy(() =>
  import(
    './layouts/IQ/quadroComparativo' /* webpackChunkName: 'forcasa.IQ.quadro-comparativo' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/forcasa/seguranca/utilizacao-comportamental"
    component={UtilizacaoComportamental}
    path="/relatorios/forcasa/seguranca/utilizacao-comportamental"
  />,
  <PrivateRoute
    key="/relatorios/forcasa/seguranca/dashboard-comportamental"
    component={DashboardComportamental}
    path="/relatorios/forcasa/seguranca/dashboard-comportamental"
  />,
  <PrivateRoute
    key="/relatorios/forcasa/seguranca/dashboard-nrs"
    component={DashboardNRs}
    path="/relatorios/forcasa/seguranca/dashboard-nrs"
  />,
  <PrivateRoute
    key="/relatorios/forcasa/seguranca/dashboard-acidentes"
    component={DashboardAcidentes}
    path="/relatorios/forcasa/seguranca/dashboard-acidentes"
  />,
  <PrivateRoute
    key="/relatorios/forcasa/seguranca/checklist-de-seguranca"
    component={ChecklistSeguranca}
    path="/relatorios/forcasa/seguranca/checklist-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/forcasa/qualidade/iq"
    component={DashboardQualidadeIQ}
    path="/relatorios/forcasa/qualidade/iq"
  />,
  <PrivateRoute
    key="/relatorios/forcasa/iq/dashboard-geral"
    component={QuadroComparativoIQ}
    path="/relatorios/forcasa/iq/dashboard-geral"
  />,
];
