import React from 'react';
import { components } from 'react-select';
import { OptionsType } from 'store/types';
import { EditIcon } from 'styles/icons';
import { useUserCreatableSelectContext } from '../../context';
import { StyleOption } from './styles';

interface Props {
  data: OptionsType;
}

export default function CustomRow(props: Props): JSX.Element {
  const { Option } = components;

  const { data } = props;
  const { handleUpdate, options } = useUserCreatableSelectContext()!;

  return (
    <Option {...props}>
      <StyleOption>
        {data.label}
        {!data.__isNew__ && (
          <EditIcon onClick={() => handleUpdate(options, data.value)} />
        )}
      </StyleOption>
    </Option>
  );
}
