// src\components\Modal\PdfViewer
import React, { useState } from 'react';
import useModal from 'hooks/use-modal';
import { ModalName } from 'store/modal/types';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modal/actions';
import Modal from 'components/Modal';
import { normalizeFileUrl } from 'utils/file';
import { File } from 'store/types';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'; //webpack-comments
import { Document, Page, pdfjs } from 'react-pdf'; // vite-comments
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import { ButtonClose, ModalTitle } from 'styles/modal';
import {
  ButtonOption,
  ModalContainer,
  ModalHeader,
  OptionsButtonContainer,
  SectionButton,
} from './styles';
import { FaMinus, FaPlus } from 'react-icons/fa';

interface ModalProps {
  file: File;
}

export default function PdfViewer() {
  const { isOpen, params, windowSec } = useModal<ModalProps>(
    ModalName.PDF_VIEWER,
  );
  const [numPages, setNumPages] = useState<number>(0);
  const [scale, setScale] = useState<number>(1);
  const pdf = normalizeFileUrl(`/datafiles/arquivos/${params?.file?.uuid}.pdf`);

  const dispatch = useDispatch();

  function handleClose() {
    dispatch(closeModal(ModalName.PDF_VIEWER));
    setScale(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function handleZoomIn() {
    setScale(state => state * 1.1);
  }

  function handleZoomOut() {
    setScale(state => Math.max(0.1, state * 0.9));
  }

  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} windowSec={windowSec}>
      <ModalHeader>
        <ButtonClose onClick={handleClose} />
        <ModalTitle>{params?.file?.nome}</ModalTitle>
      </ModalHeader>
      <ModalContainer>
        <Document
          file={pdf}
          options={{ workerSrc: '/pdf.worker.js' }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              renderMode="svg"
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={scale}
            />
          ))}
        </Document>
      </ModalContainer>
      <OptionsButtonContainer>
        <SectionButton>
          <ButtonOption onClick={handleZoomIn}>
            <FaPlus size={12} />
          </ButtonOption>
          <ButtonOption onClick={handleZoomOut}>
            <FaMinus size={12} />
          </ButtonOption>
        </SectionButton>
      </OptionsButtonContainer>
    </Modal>
  );
}
