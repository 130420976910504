import api from './api';
import { Photo } from '../store/types';
import apiAdonis from './apiAdonis';

interface UploadPayload {
  referencia: string;
}

interface StoreAppPayload {
  referencia: string;
  uuid: string;
  uuidReferencia: string;
  idContrato: number;
  tipo: string;
  id: number;
  idUsuario: number;
  dtCriacao: string;
}

export function get(contractId: number, id: number): Promise<Photo[]> {
  return api.get(`/fotos/get-by-id/${contractId}/${id}.json`);
}

export function remove(photoUniqueId: number | string) {
  return api.post(`/upload/delete/${photoUniqueId}.json`);
}

export function edit(data: Photo[]) {
  return api.post(`/fotos/edit.json`, data);
}

export function editSequence(data: { uuid: string; seq: number }[]) {
  return api.post(`/fotos/edit-sequence.json`, data);
}

export function upload(data: any): Promise<UploadPayload> {
  return apiAdonis.post(`foto/upload`, data);
}

export function storeApp(data: any): Promise<StoreAppPayload> {
  return apiAdonis.post(`foto/store-app`, data);
}
