import ButtonComponent from 'components/Button';
import styled from 'styled-components';
import { ModalContainer } from 'styles/modal';
import { UploadIcon as UploadIconStyled } from 'styles/icons';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  & > div {
    flex: 1;
  }

  div div button {
    top: -5.2rem;
    right: -0.5rem;
  }
`;

export const SubRow = styled.div`
  margin-top: 8px;
`;

export const ButtonSubmit = styled(ButtonComponent)``;

export const ContainerModal = styled(ModalContainer)<{ width?: string }>`
  overflow-y: auto;
  scroll-behavior: smooth;
  display: flex;
  width: ${({ width }) => width};
  height: 80vh;
`;

export const ContainerComment = styled.div`
  margin-top: 2rem;

  & > div {
    flex: 1;
  }

  #containerCommit {
    border: 2px solid #ddd;
    margin-top: 1rem;
    padding: 0.5rem;
  }

  div div button {
    top: -5.2rem;
    right: -0.5rem;
  }
`;

export const PhotoButtonWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 1rem;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ScrollArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 2px;
`;

export const ButtonPDF = styled(ButtonComponent)``;

export const ContentForm = styled.div`
  flex: 1;
  padding-right: 2px;
`;

export const ContentVisibleForm = styled.div<{ isVisible?: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  flex: 1;
  padding: 0.5rem;
`;

export const ApprovalWrapper = styled.div<{ hasApprovalConfig?: string }>`
  flex: 1;
  overflow-y: auto;
  border-left: 1px solid #eee;
  padding-left: 1rem;
  padding-right: 2px;

  display: ${({ hasApprovalConfig }) => (hasApprovalConfig ? 'block' : 'none')};
`;

export const ContentRight = styled.div`
  flex: 1;
  overflow-y: auto;
  border-left: 1px solid #eee;
  padding-left: 1rem;
  padding-right: 2px;
  

  /* display: ${({ hasApprovalConfig }) =>
    hasApprovalConfig ? 'block' : 'none'}; */
`;

export const UploadIcon = styled(UploadIconStyled)`
  margin-right: 0.5rem;
  color: #333;
`;

export const Footer = styled.div`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

export const NavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const NavButton = styled.button<{ isCkeck: true }>`
  padding: 0.5rem 4rem;
  font-size: 1.4rem;
  border-radius: ${({ theme }) => theme.radius.medium}px;

  cursor: pointer;
  color: ${props => (props.isCkeck ? 'white' : '#333')};
  background-color: ${props => (props.isCkeck ? '#3b3c3c' : '')};

  &:hover {
    background-color: ${props => (props.isCkeck ? '#3b3c3c' : '#d3d3d3')};
    color: ${props => (props.isCkeck ? 'white' : '#333')};
  }
`;

export const ContainerScroll = styled.div`
  min-height: calc(80vh - 10rem);

  overflow-y: scroll;
`;
