import React, { useState, useEffect } from 'react';
import { RoleOptions } from '../types';
import { get as getUserById } from 'services/user';
import { OptionsType } from 'store/types';
import Select from 'components/ReactSelect';
import { getSqlResponseAdonis } from 'services/dataset';

interface Props {
  idEmpresa: number | null;
  idUsuario: number | null;
  initialUserTemplateId: number;
  setTemplate: (value: number) => void;
}

export default function UserTemplateSelect(props: Props) {
  const [options, setOptions] = useState<OptionsType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<RoleOptions | null>(
    null,
  );

  const { idEmpresa, idUsuario, initialUserTemplateId, setTemplate } = props;

  useEffect(() => {
    getData();
  }, [idEmpresa, idUsuario, initialUserTemplateId]);

  async function getData() {
    setIsLoading(true);

    const params = {
      idEmpresa,
    };

    const resTemaplte = await getSqlResponseAdonis<OptionsType[]>(2715, params);

    if (initialUserTemplateId) {
      const getUser = await getUserById(initialUserTemplateId);

      const optionUserTemplate = {
        nome: getUser.nome,
        idUserModelo: getUser.idUsuario,
      };

      const selectedOptionUserTemplate =
        optionUserTemplate === undefined
          ? null
          : {
              value: optionUserTemplate?.idUserModelo,
              label: optionUserTemplate?.nome,
            };

      setSelectedOption(selectedOptionUserTemplate as RoleOptions);
    }

    setOptions(resTemaplte);
    setIsLoading(false);
  }

  function handleChange(option: RoleOptions) {
    setTemplate(option.value);
    setSelectedOption(option);
  }

  return (
    <Select
      onChange={handleChange}
      value={selectedOption}
      options={options}
      isLoading={isLoading}
      placeholder="Selecione um modelo"
      isClearable
    />
  );
}
