import styled from 'styled-components';
import { Card } from 'styles/card';

export const WebContainer = styled(Card)`
  width: 50rem;
  height: auto;
  margin: 5rem auto 0 auto;
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div`
  flex: 1;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2rem 0;
`;
