import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

// const DashboardAcidentes = lazy(() =>
//   import(
//     './layouts/seguranca/DashboardAcidentes' /* webpackChunkName: 'relatorios.dialogo.seguranca.dashboard-acidentes' */
//   ),
// );
const DashboardComportamental = lazy(() =>
  import(
    './layouts/seguranca/DashboardComportamental' /* webpackChunkName: 'relatorios.dialogo.seguranca.dashboard-comportamental' */
  ),
);
const GeralDeSeguranca = lazy(() =>
  import(
    './layouts/seguranca/relatorioGeralDeSeguranca' /* webpackChunkName: 'dialogo.seguranca.relatorio-geral-de-seguranca' */
  ),
);
const InspecaoSeguranca = lazy(() =>
  import(
    './layouts/seguranca/inspecaoSeguranca' /* webpackChunkName: 'dialogo.seguranca.inspecao-seguranca' */
  ),
);

export const routes = [
  // <PrivateRoute
  //   key="/relatorios/dialogo/seguranca/dashboard-acidentes"
  //   component={DashboardAcidentes}
  //   path="/relatorios/dialogo/seguranca/dashboard-acidentes"
  // />,
  <PrivateRoute
    key="/relatorios/dialogo/seguranca/dashboard-comportamental"
    component={DashboardComportamental}
    path="/relatorios/dialogo/seguranca/dashboard-comportamental"
  />,
  <PrivateRoute
    key="/relatorios/dialogo/seguranca/inspecao-seguranca"
    component={InspecaoSeguranca}
    path="/relatorios/dialogo/seguranca/inspecao-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/dialogo/seguranca/relatorio-geral-de-seguranca"
    component={GeralDeSeguranca}
    path="/relatorios/dialogo/seguranca/relatorio-geral-de-seguranca"
  />,
];
