import React, { ReactElement } from 'react';
import { DefaultToast } from 'react-toast-notifications';

export default function ToastMessage(props: any): ReactElement {
  const { children } = props;
  return (
    <DefaultToast {...props} style={{ marginTop: '6rem' }}>
      {children}
    </DefaultToast>
  );
}
