import React, { useState } from 'react';
import { numericArrayOf } from 'utils/common';
import { useSimpleTableContext } from '../../../../context';
import { IconTree, Container, ListContainer } from './styles';

export default function LevelSelect() {
  const [showList, setShowList] = useState(false);
  const { id, setLevelSelection, maxLevel } = useSimpleTableContext();

  function handleAbrirTodos() {
    setLevelSelection('abrir-todos');
    const rows = document.querySelectorAll(`#${id} tbody tr`);
    rows.forEach(row => {
      row?.setAttribute('data-is-open', 'yes');
      row?.setAttribute('data-is-visible', 'yes');
    });

    setShowList(!showList);
  }

  function handleFecharTodos() {
    setLevelSelection('fechar-todos');
    const rows = document.querySelectorAll(`#${id} tbody tr`);
    rows.forEach(row => {
      if (row.classList.value.indexOf('row-level-1') === -1) {
        row?.setAttribute('data-is-open', 'no');
        row?.setAttribute('data-is-visible', 'no');
      }
    });

    setShowList(!showList);
  }

  function handleLevelClick(level) {
    setLevelSelection(level);
    const rows = document.querySelectorAll(`#${id} tbody tr`);
    rows.forEach(row => {
      const rowLevel = row.attributes['data-key'].value.split('-').length;
      if (rowLevel <= level) {
        row?.setAttribute('data-is-open', 'yes');
        row?.setAttribute('data-is-visible', 'yes');
      } else {
        row?.setAttribute('data-is-open', 'no');
        row?.setAttribute('data-is-visible', 'no');
      }
    });

    setShowList(!showList);
  }

  function renderList() {
    const mapArray = numericArrayOf(maxLevel);

    const list = (
      <ListContainer style={{ display: showList ? 'block' : 'none' }}>
        <li>
          <button type="button" onClick={handleAbrirTodos}>
            Abrir Todos
          </button>
        </li>
        <li>
          <button type="button" onClick={handleFecharTodos}>
            Fechar Todos
          </button>
        </li>
        {mapArray.map(level => (
          <li key={level}>
            <button type="button" onClick={() => handleLevelClick(level)}>
              {`Nível ${level}`}
            </button>
          </li>
        ))}
      </ListContainer>
    );

    return list;
  }

  return (
    <Container>
      <IconTree onClick={() => setShowList(!showList)} />
      {renderList()}
    </Container>
  );
}
