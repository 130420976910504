import Button from 'components/Button';
import Modal from 'components/Modal';
import useModal from 'hooks/use-modal';
import { GoBackIcon, IconCheck } from 'pages/DocSign/styles';
import { getModalStyle } from 'pages/DocSign/utils';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { closeModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { ModalContainer, ModalHeader } from 'styles/modal';
import { Content, Footer, ImageWrapper, ModalTitle } from './styles';
import { ModalParams } from './types';

export default function PictureConfirmModal() {
  const { isOpen, params, windowSec } = useModal<ModalParams>(
    ModalName.PICTURE_CONFIRM_MODAL,
  );

  const dispatch = useDispatch();

  const { addToast } = useToasts();

  const { urlPreviewImg, imageFile, onConfirm, onRetry } = params;

  function handleClick(isConfirm: boolean) {
    if (isConfirm) {
      addToast('Foto confirmada.', { appearance: 'success' });
      onConfirm(isConfirm, imageFile);
    } else {
      onRetry();
    }

    dispatch(closeModal(ModalName.PICTURE_CONFIRM_MODAL));
  }

  return (
    <Modal isOpen={isOpen} windowSec={windowSec} style={getModalStyle()}>
      <ModalContainer width="99vw" height="99vh">
        <Content>
          <ModalHeader>
            <ModalTitle> Confirmar Foto </ModalTitle>
          </ModalHeader>
          <ImageWrapper>
            <img src={urlPreviewImg} alt="gescorp" />
          </ImageWrapper>

          <Footer>
            <Button
              small
              text="Tentar novamente"
              onClick={() => handleClick(false)}
              icon={<GoBackIcon />}
            />
            <Button
              small
              primary
              text="Confirmar"
              onClick={() => handleClick(true)}
              icon={<IconCheck />}
            />
          </Footer>
        </Content>
      </ModalContainer>
    </Modal>
  );
}
