import { useDynamicTable } from 'pages/TableReport/contexts/DynamicTable';
import React, { ReactElement } from 'react';
import { Column } from 'react-data-grid';
import { TableTreeviewRow } from 'store/types';
import {
  RoundedEmptyIcon,
  RoundedHalfMoonIcon,
  RoundedIcon,
  RoundedXIcon,
  RoundedCheckIcon,
  RoundedAlertIcon,
  DashIcon,
  Clip,
} from '../../Icons';
import { Cell } from '../styles';

interface Props {
  row: TableTreeviewRow;
  column: Column<{
    key: string;
  }>;
  col: Column<{
    key: string;
  }>;
  value: string;
}

export const iconsMap = {
  iconeFarolVerde: <RoundedIcon containerColor="#009688" />,
  iconeFarolAmarelo: <RoundedIcon containerColor="#ffd966" />,
  iconeFarolVermelho: <RoundedIcon containerColor="#ff3860" />,
  iconeAprovado: <RoundedCheckIcon containerColor="#009688" />,
  iconeAprovadoAnterior: <RoundedCheckIcon containerColor="#009688" />,
  iconeAtencao: <RoundedAlertIcon containerColor="#ffd966" checkColor="#fff" />,
  iconeNaoConforme: <RoundedXIcon containerColor="#ff3860" />,
  iconeInformativo: (
    <RoundedAlertIcon containerColor="#4287f5" checkColor="#fff" />
  ),
  iconeNaoIniciado: <RoundedEmptyIcon />,
  iconeAndamento: <RoundedHalfMoonIcon />,
  iconeConcluido: <RoundedIcon containerColor="#333" />,
  iconeDash: <DashIcon />,
  iconeClip: <Clip />,
};

export default function GenericIcon(props: Props): ReactElement {
  const { row, column, col } = props;

  const { isLastLevel } = row;

  const { layoutConfig } = useDynamicTable();

  let tooltipIcons = undefined;

  if (layoutConfig) {
    tooltipIcons = layoutConfig?.tooltipIcons;
  }

  const iconName = column
    ? row[column.key]
    : row[col.key] || row.columns[col.key];

  const tooltipText = tooltipIcons ? tooltipIcons[iconName] : undefined;

  if (column?.formatterParams?.isShowTextLastLevelOnly && isLastLevel) {
    return (
      <Cell style={{ justifyContent: 'space-evenly' }} title={tooltipText}>
        {row[column.formatterParams.key]}
      </Cell>
    );
  }

  if (column?.formatterParams?.iconWithValue)
    return (
      <Cell style={{ justifyContent: 'space-evenly' }} title={tooltipText}>
        {iconsMap[iconName]}
        {row[column.formatterParams.key]}
      </Cell>
    );

  return <Cell title={tooltipText}>{iconsMap[iconName]}</Cell>;
}
