import styled from 'styled-components';
import { IoIosClose } from 'react-icons/io';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 16rem;
  position: absolute;
  bottom: 6rem;
  left: 1.4rem;
  background-color: #1d1d1d;
  padding: 0.8rem 1.1rem;
  z-index: 1;
`;

export const Label = styled.label`
  color: white;
  display: inline-block;
  font-size: 14px;
`;

export const Input = styled.input`
  display: inline-block;
  width: 35px;
  background: none;
  background-color: white;
  color: black;
  border: none;
  border-bottom: 1px solid black;
  padding-left: 8px;
`;

export const Btn = styled.button`
  cursor: pointer;
  margin-top: 5px;
  width: 100%;
  padding: 5px;
  color: white;
  background-color: #009688;
  border: none;
  border-radius: 2px;
  font-size: 12px;
`;

export const CloseLabel = styled.label`
  cursor: pointer;
  color: #949494;
  position: relative;
  text-align: right;
  width: 100%;
  top: 6px;
  right: 4px;
  font-size: 13px;
  padding-right: 14px;
  margin-top: 3px;
`;

export const CloseBtn = styled(IoIosClose)`
  cursor: pointer;
  color: #949494;
  font-size: 20px;
  position: absolute;
  right: -9px;
`;

export const ShowBtn = styled.button`
  /* position: absolute; */
  /* bottom: 6rem; */

  /* left: 2rem; */
  margin-left: 1rem;
  margin-top: 0.5rem;
  border: none;
  background: none;
  color: #999999;
  cursor: pointer;
`;

export const InfoContainer = styled.div`
  margin-top: 3px;
  padding: 5px;

  p:first-child {
    color: white;
    font-size: 12px;
  }

  p {
    color: #c4c4c4;
  }
`;
