export const SET_IS_IMPERSONATE = 'impersonate/SET_IS_IMPERSONATE';
export const CLEAR_IS_IMPERSONATE = 'impersonate/CLEAR_IS_IMPERSONATE';

export interface ConfigState {
  isImpersonate: boolean;
}

interface SetIsImpersonate {
  type: typeof SET_IS_IMPERSONATE;
  payload: boolean;
}

interface ClearIsImpersonate {
  type: typeof CLEAR_IS_IMPERSONATE;
  payload: boolean;
}

export type ConfigActionTypes = SetIsImpersonate | ClearIsImpersonate;
