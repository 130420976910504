import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'bacaba.financeiro.obras' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/bacaba/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/bacaba/financeiro/obras"
  />,
];
