import { Sheet } from '../types';

export const SET_SHEET = 'SET_SHEET';
export const CLEAR_SHEET = 'CLEAR_SHEET';

export interface SheetState {
  sheet: Sheet | null;
}

interface SetAction {
  type: typeof SET_SHEET;
  payload: Sheet;
}

interface ClearAction {
  type: typeof CLEAR_SHEET;
}

export type SheetActionTypes = SetAction | ClearAction;
