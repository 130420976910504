import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const AcompanhamentoSemanal = lazy(() =>
  import(
    './layouts/fisico/acompanhamentoSemanal' /* webpackChunkName: 'relatorios.out.fisico.acompanhamento-semanal' */
  ),
);
const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.out.fisico.gerencial' */
  ),
);
const FisicoPortfolioSemanal = lazy(() =>
  import(
    './layouts/fisico/portfolioSemanal' /* webpackChunkName: 'relatorios.out.fisico.portfolio-semanal' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/out/fisico/acompanhamento-semanal"
    component={AcompanhamentoSemanal}
    path="/relatorios/out/fisico/acompanhamento-semanal"
  />,
  <PrivateRoute
    key="/relatorios/out/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/out/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/out/fisico/portfolio-semanal"
    component={FisicoPortfolioSemanal}
    path="/relatorios/out/fisico/portfolio-semanal"
  />,
];
