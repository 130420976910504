import React from 'react';
import WarningPageContainer from '../WarningPageContainer';

export default function WarningSignedCanceled() {
  return (
    <WarningPageContainer>
      <h1>Assinatura cancelada</h1>

      <br />
      <p>A assinatura deste documento foi cancelada.</p>
      <br />
    </WarningPageContainer>
  );
}
