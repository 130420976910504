/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import { getToken, signOut } from './auth';
import { captureException } from '@sentry/react';

export const filesHost =
  'https://gescorpgo-datafiles.nyc3.digitaloceanspaces.com';

/* Não remover essa regra */
// const host = process.env.BASE_URL; //webpack-comments
const host = import.meta.env.VITE_BASE_URL; // vite-comments

export const baseURL = `${host}/api/v11`;
export const isDevMode = host !== 'https://api.gescorpgo.com';

const api = axios.create({ baseURL });
api.defaults.validateStatus = () => true;

api.interceptors.request.use(configRes => {
  const config = configRes;

  /* Add token  */
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  /* Normalize props to snake_case  */
  if (config.data && !config.params?.apiConfig?.disableConvertToSnakecase) {
    config.data = snakeCaseKeys(config.data);
  }

  return config;
});

api.interceptors.response.use(res => {
  /* Ignore interceptor */
  if (res.config.params?.ignoreInterceptor) {
    return res.data;
  }

  const { code } = res.data;
  const { response } = res.data;

  if (res.status >= 500 || (code !== 200 && code !== 201)) {
    handleError(res);
  }

  // if (code !== 200 && code !== 201) {
  //   if (message) {
  //     alert(message);
  //   } else if (errors) {
  //     alert(errors.join(', '));
  //   }
  // }

  if (res.config.params?.apiConfig?.disableConvertToCamelcase) {
    return response;
  }

  if (typeof response === 'object') {
    /* Normalize payload data to camelCase  */
    const camelcaseKeysConfig = res.config.params?.apiConfig || {};

    return camelcaseKeys(response, { deep: true, ...camelcaseKeysConfig });
  }

  return response;
}, handleError);

// estava dando erro ao entrar na fn handleError - error.data.message eh undefined

function handleError(error: AxiosResponse<any>): Promise<any> {
  console.error(error);

  captureException(error, {
    tags: {
      status: error.status,
      method: error.config.method,
      payload: error.config.data,
      base: 'php',
      'api-url': error.config.url,
      'request-api-error': true,
    },
  });

  if (error.data.message === 'Expired token') {
    signOut();
    throw error;
  }

  console.error('Erro');
  throw error.data ? error.data.errors : error;
}

export default api;
