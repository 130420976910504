import arrayMove from 'array-move';
import camelcaseKeys from 'camelcase-keys';
import moment from 'moment';
import queryString from 'query-string';
import React, {
  InputHTMLAttributes,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FileDrop } from 'react-file-drop';
import ImgsViewer from 'react-images-viewer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  SortableContainer,
  SortableElement,
  SortEnd,
} from 'react-sortable-hoc';
import { v1 as uuid } from 'uuid';
import Button from '../../components/Button';
import NavBar from '../../components/Navbar';
import { getToken, saveToken } from '../../services/auth';
import {
  addAndSetPhoto,
  remove as removeComment,
  update as updateComment,
} from '../../services/comment';
import { getSqlResponse } from '../../services/dataset';
import { getName } from '../../services/form';
import { editSequence, get, remove } from '../../services/photo';
import { upload } from '../../services/upload';
import { closeModal, showModal } from '../../store/modal/actions';
import { ModalName } from '../../store/modal/types';
import { Comment, DatasetParams, EnumRoutes } from '../../store/types';
import { Photo } from './types';
import { chunkArray } from '../../utils/common';
import { ALLOWED_TYPE_LABEL, normalizeFileUrl } from '../../utils/file';
import CardPhoto from './components/CardPhoto';
import {
  AttachmentIcon,
  Container,
  ContainerImages,
  DragText,
  FileDropWrapper,
  Option,
  OptionsWrapper,
  PhotoIcon,
  SaveButtonWrapper,
  Title,
} from './styles';
import { useToasts } from 'react-toast-notifications';

const SortableItem = SortableElement(props => <CardPhoto {...props} />);

const SortableList = SortableContainer(({ items, ...rest }) => (
  <ContainerImages>
    {items.map((item, index) => (
      <SortableItem
        disabled={rest.allowDisableImage}
        key={`${item.uuid}`}
        index={index}
        indexImg={index}
        image={item}
        {...rest}
      />
    ))}
  </ContainerImages>
));

interface UploadData {
  dtCriacao: string;
  uuid: string;
  comentario: string;
  idFoto: number;
  idComentario: number;
  uuidRaiz: string;
}

interface UrlParams {
  id: number;
  idContrato: number;
  idDataset: number;
  tipo: string;
  idDatasetImages?: number;
  eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9: string; // token
  allowDisableImage?: boolean;
}

interface Props {
  id?: number;
  allowDisableImage?: boolean;
  disableUpload?: boolean;
  allowedTypes?: string[];
  onAfterSave?(): void;
}

export default function UploadImage(props: Props): ReactElement {
  const [urlParams, setUrlParams] = useState<UrlParams>({} as UrlParams);

  const [filesSaved, setFilesSaved] = useState<Photo[]>([]);

  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [vwCurrentImg, setVwCurrentImg] = useState(0);
  const [pageTitle, setPageTitle] = useState('');

  const inputFileRef = useRef<InputHTMLAttributes<HTMLInputElement>>();

  const history = useHistory();
  const dispatch = useDispatch();

  const { addToast } = useToasts();

  useEffect(() => {
    if (!history) {
      return;
    }

    const queryData = queryString.parse(history.location.search);

    const { eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9 } = queryData;

    if (eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9) {
      saveToken(eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9 as string);
    }

    if (!getToken()) {
      history.push(EnumRoutes.SIGNIN);

      return;
    }

    const normalizedData = (camelcaseKeys(queryData) as unknown) as UrlParams;

    if (Object.keys(normalizedData).length) {
      setUrlParams(normalizedData);
    }
  }, []);

  useEffect(() => {
    if (props.id) {
      const newUrlParams = { ...props };
      delete newUrlParams.allowedTypes;

      setUrlParams(newUrlParams as UrlParams);
    }
  }, [props?.id]);

  useEffect(() => {
    if (Object.keys(urlParams).length) {
      getName(
        urlParams.idContrato,
        urlParams.id,
        urlParams.idDataset || 53,
      ).then(setPageTitle);

      getPhotos();
    }
  }, [urlParams?.id]);

  async function getPhotos() {
    setFilesSaved([]);

    if (urlParams.idDatasetImages) {
      const res = await getSqlResponse(
        urlParams.idDatasetImages,
        urlParams as DatasetParams,
      );
      normalizeImages(res);
    } else {
      const res = await get(urlParams.idContrato, urlParams.id);
      normalizeImages(res);
    }
  }

  function normalizeImages(images: Photo[]) {
    const normalized = images.map<Photo>(img => ({
      ...img,
      src: normalizeFileUrl(img.referencia),
      caption: img.comentario,
    }));
    setFilesSaved(normalized);
  }

  async function saveComment(data: Comment) {
    if (!data.comentario) {
      return;
    }

    if (data.idComentario) {
      await updateComment({
        idComentario: data.idComentario,
        comentario: data.comentario,
      });
    } else {
      /* Create a new comment and set the id to photo */
      await addAndSetPhoto({ ...data, ...urlParams });
    }
  }

  async function uploadNewImages() {
    const filesToUpload = filesSaved.filter(i => i.isNew);

    if (!filesToUpload.length) {
      return;
    }

    const chunks = chunkArray<globalThis.File>(filesToUpload, 5);

    const execute = (part: globalThis.File[]) => {
      return part.map(async file => {
        const data: UploadData = {
          ...urlParams,
          dtCriacao: moment().format('YYYY-MM-DD HH:mm:ss'),
          uuid: file.uuid,
          uuidRaiz: file.uuid,
          idComentario: file.idComentario,
          comentario: file.comentario || null,
          seq: file.seq,
          idFoto: file.idFoto,
          appendedData: urlParams.fieldName
            ? { inputFormName: urlParams.fieldName }
            : undefined,
        };

        return upload(data, file);
      });
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const part of chunks) {
      // eslint-disable-next-line no-await-in-loop
      await Promise.all(execute(part));
    }
  }

  async function updateSequence() {
    const imagesToUpdate = filesSaved.filter(
      i => !i.isNew && i.shouldUpdateSequence,
    );

    if (!imagesToUpdate.length) {
      return;
    }

    const photosToEdit = imagesToUpdate.map(i => ({
      uuid: i.uuid!,
      seq: i.seq,
      idContrato: urlParams.idContrato,
    }));

    await editSequence(photosToEdit);
  }

  async function handleSaveAll() {
    dispatch(showModal(ModalName.LOADING, { text: 'Salvando imagens' }));

    await uploadNewImages();
    await updateSequence();

    /* Save comments */
    const imagesChangedToUpload = filesSaved.filter(
      i => !i.isNew && i.shouldSaveComment,
    );

    if (imagesChangedToUpload.length) {
      const commentsToEdit = imagesChangedToUpload.map(saveComment);
      await Promise.all(commentsToEdit);
    }

    await getPhotos();

    const { onAfterSave } = props;

    if (onAfterSave) {
      onAfterSave();
    }

    dispatch(closeModal(ModalName.LOADING));
  }

  function handleRemove(file: Photo) {
    const newPhotos = filesSaved.filter(f => f.uuid !== file.uuid);
    setFilesSaved(newPhotos);

    if (!file.isNew) {
      remove(file.idFoto);

      if (file.idComentario) {
        removeComment(file.idComentario);
      }
    }
  }

  function onChange(e: any) {
    const newFiles: File[] = Array.from(e.target.files);
    mergeFiles(newFiles);
  }

  function checkFilesType(newFiles: File[]) {
    const { allowedTypes } = props;

    if (!allowedTypes) {
      return;
    }

    newFiles.forEach(newFile => {
      if (!allowedTypes.includes(newFile.type)) {
        throw new Error(`Formato de arquivo incorreto ${newFile.name}`);
      }
    });
  }

  function mergeFiles(newFiles: File[]) {
    try {
      checkFilesType(newFiles);
    } catch (error) {
      return addToast((error as Error).message, { appearance: 'error' });
    }

    const normalizedNewFiles = [];

    newFiles.forEach(file => {
      file.uuid = uuid();
      file.src = URL.createObjectURL(file);
      file.seq = 0;
      file.isNew = true;

      normalizedNewFiles.push(file);
    });

    setFilesSaved([...normalizedNewFiles, ...filesSaved]);
  }

  function handleShowViewer(imgIdx: number) {
    setViewerIsOpen(!viewerIsOpen);
    setVwCurrentImg(imgIdx);
  }

  function handleVwGoToNext() {
    setVwCurrentImg(vwCurrentImg + 1);
  }

  function handleVwGoToPrevious() {
    setVwCurrentImg(vwCurrentImg ? vwCurrentImg - 1 : 0);
  }

  function handleGoToFiles() {
    history.push(`upload-file${history.location.search}`);
  }

  function handleSetImage(image: globalThis.File | Photo) {
    const newImages = filesSaved.map(f => {
      return f.uuid === image.uuid ? image : f;
    });
    setFilesSaved(newImages);
  }

  function renderSaveButton() {
    const { allowDisableImage } = props;

    if (allowDisableImage) {
      return null;
    }

    return (
      <SaveButtonWrapper>
        <Button primary small text="Salvar" onClick={handleSaveAll} />
      </SaveButtonWrapper>
    );
  }

  function renderSubheader(): ReactElement | null {
    const { id } = props;

    const buttonSave = renderSaveButton();

    if (id) {
      return buttonSave;
    }

    return (
      <>
        <OptionsWrapper>
          <Option isSelected>
            <PhotoIcon />
            <span> FOTOS </span>
          </Option>
          <Option isSelected={false} onClick={handleGoToFiles}>
            <AttachmentIcon />
            <span> ANEXOS </span>
          </Option>
        </OptionsWrapper>
        <Title>{pageTitle.toUpperCase()}</Title>
        {buttonSave}
      </>
    );
  }

  function handleSortEnd(e: SortEnd) {
    const { oldIndex, newIndex } = e;
    const sortedImages = arrayMove(filesSaved, oldIndex, newIndex);

    const normalizedImages = sortedImages.map((i, idx) => {
      i.seq = idx + 1;
      i.shouldUpdateSequence = true;

      return i;
    });

    setFilesSaved(normalizedImages);
  }

  function renderImages() {
    return (
      <SortableList
        items={filesSaved}
        axis="xy"
        helperClass="dragging-helper-class"
        onRemove={handleRemove}
        onSortEnd={handleSortEnd}
        onShowViewer={handleShowViewer}
        onSetImage={handleSetImage}
        distance={1}
        allowDisableImage={props.allowDisableImage}
      />
    );
  }

  function renderDragFiles() {
    const { allowDisableImage, disableUpload, allowedTypes } = props;

    if (allowDisableImage || disableUpload) {
      return null;
    }

    return (
      <DragFiles
        allowedTypes={allowedTypes}
        inputFileRef={inputFileRef.current!}
        onSelectFiles={mergeFiles}
      />
    );
  }

  const { id, allowedTypes } = props;
  const renderedImages = renderImages();

  return (
    <>
      {!id && <NavBar hideButtons />}

      <Container>
        {renderSubheader()}
        {renderDragFiles()}
        {renderedImages}

        <ImgsViewer
          imgs={filesSaved}
          currImg={vwCurrentImg}
          isOpen={viewerIsOpen}
          onClickPrev={handleVwGoToPrevious}
          onClickNext={handleVwGoToNext}
          onClose={handleShowViewer}
          closeBtnTitle=""
          leftArrowTitle=""
          rightBtnTitle=""
        />
      </Container>

      <input
        style={{ display: 'none' }}
        ref={inputFileRef}
        type="file"
        id="multi"
        name="multi"
        onChange={onChange}
        multiple
        accept={allowedTypes?.join(',')}
      />
    </>
  );
}

interface DragFilesProps {
  allowedTypes?: string[];
  inputFileRef: InputHTMLAttributes<HTMLInputElement>;
  onSelectFiles(files: FileList[]): void;
}

function DragFiles(props: DragFilesProps) {
  const { allowedTypes, inputFileRef, onSelectFiles } = props;

  function getAllowedTypesText() {
    if (!allowedTypes) {
      return;
    }

    return `: ${allowedTypes.map(type => ALLOWED_TYPE_LABEL[type]).join(', ')}`;
  }

  return (
    <FileDropWrapper onClick={() => inputFileRef.click()}>
      <FileDrop onDrop={(files, _) => onSelectFiles(Array.from(files))}>
        <DragText>
          Arraste e solte arquivos aqui, ou clique para selecionar
          {getAllowedTypesText()}
        </DragText>
      </FileDrop>
    </FileDropWrapper>
  );
}
