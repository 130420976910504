import { combineReducers } from 'redux';
import sheetManager from './sheetManager/reducers';
import contract from './contract/reducers';
import sheet from './sheet/reducers';
import modal from './modal/reducers';
import navbar from './navbar/reducers';
import menu from './menu/reducers';
import report from './report/reducers';
import slicer from './slicer/reducers';
import config from './config/reducers';
import user from './user/reducers';
import supplierMeasure from './supplierMeasure/reducers';
import impersonate from './impersonate/reducers';

const rootReducer = combineReducers({
  sheetManager,
  contract,
  sheet,
  modal,
  navbar,
  menu,
  report,
  slicer,
  config,
  user,
  supplierMeasure,
  impersonate,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
