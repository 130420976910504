import React from 'react';
import SelectCatalog from 'components/Widgets/SelectCatalog';
import { FormikErrors } from 'formik';
import { OptionsType } from 'store/types';
import { FormUser } from '../../types';

interface Props {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<FormUser>>;
  values: FormUser;
}

export default function CatalogSelect(props: Props) {
  const { values, setFieldValue } = props;

  function normalizedOptionSelected(option: OptionsType | OptionsType[]) {
    const newOption = option ? option : [];

    const normalizedOption = newOption.map(item => {
      const currentPreviousArea =
        values.previousAreas?.find(area => area.value === item.value) || {};

      return {
        ...item,
        ...currentPreviousArea,
      };
    });

    setFieldValue('area', normalizedOption);
  }

  return (
    <SelectCatalog
      isClearable
      idTipo={170}
      datasetId={2720}
      isMulti
      idModulo={28}
      name="area"
      label="Area"
      setCatalog={(name, option) => normalizedOptionSelected(option)}
      catalog={values.area}
    />
  );
}
