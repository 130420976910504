import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v1 as uuid } from 'uuid';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { CreatableSelect } from '../../../../components/ReactSelect';
import useModal from '../../../../hooks/use-modal';
import { create as createGroup } from '../../../../services/file_group';
import { getByCompany, updateFileGroupId } from '../../../../services/user';
import { closeModal } from '../../../../store/modal/actions';
import { ModalName } from '../../../../store/modal/types';
import { OptionsType, User } from '../../../../store/types';
import { Scrollable } from '../../../../styles/common';
import { Label } from '../../../../styles/form';
import { ButtonClose, ModalTitle } from '../../../../styles/modal';
import { ModalContainer } from '../../../SectorsManager/styles';
import { useFileGroup } from '../../contexts/FileGroup';
import {
  ActionButtom,
  ActionWrapper,
  Container,
  EmailItem,
  EmailsWrapper,
  IconRemove,
  IconWrapper,
  LabelWrapper,
} from './styles';

interface UserState extends User {
  toUpdate: boolean;
  idGrupoArquivo: number | string | null;
}

export default function FileGroupModal(): ReactElement {
  const [selectedGroup, setSelectedGroup] = useState<OptionsType | null>(null);
  const [users, setUsers] = useState<UserState[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const { fileGroupOptions, company, setFileGroupOptions } = useFileGroup();

  const { isOpen, windowSec } = useModal(ModalName.FILE_GROUP);

  const dispatch = useDispatch();

  useEffect(() => {
    if (company) {
      getByCompany(company.idEmpresa).then(setUsers);
    }
  }, [company]);

  function handleSetGroup(selected: OptionsType) {
    let newGroup = selected;

    if (
      newGroup.__isNew__ &&
      fileGroupOptions.findIndex(x => x.value === newGroup.value) === -1
    ) {
      newGroup = { ...selected, value: uuid() };
      setFileGroupOptions([...fileGroupOptions, newGroup]);
    }

    setSelectedGroup(newGroup);
  }

  async function handleSave() {
    setIsSaving(true);

    const newGroupIds = await onCreateGroup();

    await onUpdateUsers(newGroupIds);

    setSelectedGroup(null);
    handleClose();
    setIsSaving(false);
  }

  async function onCreateGroup() {
    const newGroupIds: { [key: string]: number } = {};

    const newGroups = await Promise.all(
      fileGroupOptions.map(async fileGroup => {
        const newFileGroup = fileGroup;

        if (newFileGroup.__isNew__) {
          newFileGroup.label = newFileGroup.label.toString().toUpperCase();

          const { id } = await createGroup({
            id: 0,
            descricao: newFileGroup.label,
          });

          newGroupIds[newFileGroup.value] = id;
          newFileGroup.value = id;

          delete newFileGroup.__isNew__;
        }

        return newFileGroup;
      }),
    );

    setFileGroupOptions(newGroups);

    return newGroupIds;
  }

  async function onUpdateUsers(newGroupIds: { [key: string]: number }) {
    const normalizedUsersGroupFileId = users.map(user => {
      const newUser = user;
      if (
        newUser.idGrupoArquivo != null &&
        newUser.idGrupoArquivo in newGroupIds
      ) {
        newUser.idGrupoArquivo = newGroupIds[newUser.idGrupoArquivo];
      }

      return newUser;
    });

    setUsers(normalizedUsersGroupFileId);

    const usersToUpdate = normalizedUsersGroupFileId
      .filter(user => user.toUpdate)
      .map(({ idUsuario, idGrupoArquivo }) => ({
        idUsuario,
        idGrupoArquivo: idGrupoArquivo as number,
      }));

    await updateFileGroupId(usersToUpdate);
  }

  function handleClose() {
    dispatch(closeModal());
    setSelectedGroup(null);
  }

  function handleSelectUser(userId: number, groupId: number | null | string) {
    const newUsers = users.map(user => {
      const newUser = user;

      if (newUser.idUsuario === userId) {
        newUser.idGrupoArquivo = groupId;
        newUser.toUpdate = true;
      }

      return newUser;
    });

    setUsers(newUsers);
  }

  function handleSelectAll() {
    if (selectedGroup) {
      const newUsers = users.map(user => {
        const newUser = user;
        newUser.idGrupoArquivo = selectedGroup.value;
        newUser.toUpdate = true;

        return newUser;
      });

      setUsers(newUsers);
    }
  }

  function handleRemoveAll() {
    if (selectedGroup) {
      const newUsers = users.map(user => {
        const newUser = user;
        newUser.idGrupoArquivo = null;
        newUser.toUpdate = true;

        return newUser;
      });

      setUsers(newUsers);
    }
  }

  function renderUsersWithGroup(): ReactElement[] | null {
    if (selectedGroup) {
      return users
        .filter(user => user.idGrupoArquivo === selectedGroup.value)
        .map(user => (
          <EmailItem key={user.email} enambleHover>
            <span>{`${user.nome} ${user.sobrenome} <${user.email}>`}</span>
            <IconWrapper onClick={() => handleSelectUser(user.idUsuario, null)}>
              <IconRemove />
            </IconWrapper>
          </EmailItem>
        ));
    }

    return null;
  }

  function renderUsersWithoutGroup(): ReactElement[] | null {
    if (selectedGroup) {
      return users
        .filter(user => !user.idGrupoArquivo)
        .map(user => (
          <EmailItem
            key={user.email}
            onClick={() =>
              handleSelectUser(user.idUsuario, selectedGroup.value)
            }
          >
            <span>{`${user.nome} ${user.sobrenome} <${user.email}>`}</span>
          </EmailItem>
        ));
    }

    return null;
  }

  const usersByGroup = useMemo(() => renderUsersWithGroup(), [
    users,
    selectedGroup,
  ]);
  const usersWithoutGroup = useMemo(() => renderUsersWithoutGroup(), [
    users,
    selectedGroup,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      windowSec={windowSec}
    >
      <ModalContainer
        style={{ width: '80rem', padding: '2rem', height: '85vh' }}
      >
        <ButtonClose onClick={handleClose} />
        <ModalTitle> Relacionamento Grupo Arquivo & Usuário </ModalTitle>

        <Container>
          <div>
            <Label htmlFor="selectGroup">Grupo</Label>
            <CreatableSelect
              id="selectGroup"
              options={fileGroupOptions}
              onChange={handleSetGroup}
              formatCreateLabel={() => <span> Criar novo grupo </span>}
            />
          </div>

          <Scrollable>
            <div>
              <LabelWrapper>
                <Label>Relacionados</Label>
                <ActionButtom onClick={handleRemoveAll}>
                  Remover todos
                </ActionButtom>
              </LabelWrapper>

              <EmailsWrapper>{usersByGroup}</EmailsWrapper>
            </div>

            <div>
              <LabelWrapper>
                <Label>Sem grupo</Label>
                <ActionButtom onClick={handleSelectAll}>
                  Selecionar Todos
                </ActionButtom>
              </LabelWrapper>

              <EmailsWrapper>{usersWithoutGroup}</EmailsWrapper>
            </div>
          </Scrollable>

          <ActionWrapper>
            <Button
              primary
              text="Salvar"
              isLoading={isSaving}
              onClick={handleSave}
            />
          </ActionWrapper>
        </Container>
      </ModalContainer>
    </Modal>
  );
}
