import styled from 'styled-components';
import { FiCamera } from 'react-icons/fi';
import { GrDocumentText } from 'react-icons/gr';

export const Cell = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px !important;
  transform: translateY(-1px);
  position: relative;
`;

export const IconCamera = styled(FiCamera)`
  font-size: 1.6rem;
  cursor: pointer;
`;

export const IconDocument = styled(GrDocumentText)`
  font-size: 1.6rem;
  cursor: pointer;
`;
