import ButtonComponent from 'components/Button';
import styled, { css } from 'styled-components';

export const displayNone = css`
  display: none !important;
`;

export const DocWrapper = styled.div<{ isDesktop: boolean }>`
  width: 99.5vw;
  margin: 0 auto;
  height: calc(100vh - 11rem);
  margin-bottom: 1rem;

  .e-pv-viewer-main-container {
    .e-toolbar-right {
      ${({ isDesktop }) => (!isDesktop ? displayNone : '')}
    }
  }
  .e-pv-bookmark-button {
    ${({ isDesktop }) => (isDesktop ? displayNone : '')}
  }

  @media only screen and (max-width: 960px) {
    height: calc(100vh - 7rem);
  }
`;

export const Button = styled(ButtonComponent)`
  margin-left: 1.5rem;
`;
