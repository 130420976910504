import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: "relatorios.artesano.fisico.gerencial" */
  ),
);
const DesviosOrcamento = lazy(() =>
  import(
    './layouts/financeiro/desviosOrcamento' /* webpackChunkName: "relatorios.artesano.financeiro.desvios-do-orcamento" */
  ),
);
const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: "relatorios.artesano.financeiro.obras" */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/artesano/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/artesano/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/artesano/financeiro/desvios-do-orcamento"
    component={DesviosOrcamento}
    path="/relatorios/artesano/financeiro/desvios-do-orcamento"
  />,
  <PrivateRoute
    key="/relatorios/artesano/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/artesano/financeiro/obras"
  />,
];
