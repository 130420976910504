import React, { useEffect, useState } from 'react';
import Select from 'components/ReactSelect';
import { Label } from 'styles/form';
import { Danger } from 'styles/text';
import { OptionsType } from 'store/types';
import { getSqlResponseAdonis } from 'services/dataset';
import { FormDataType } from '../../type';
import { FormikProps } from 'formik';

interface Props {
  name: string;
  label: string;
  formik: FormikProps<FormDataType>;
  validateField: (name: string) => void;
}

export default function FieldSelect(props: Props) {
  const { name, label, formik, validateField } = props;

  const { values, setFieldValue, errors } = formik;
  const [options, setOptions] = useState<OptionsType[]>([]);

  const error = errors[name] || '';

  useEffect(() => {
    initData();
  }, []);

  async function initData() {
    const dataAux = await getSqlResponseAdonis<OptionsType[]>(4596);

    setOptions(dataAux);
  }

  function handleSelectChange(selectedOption: OptionsType) {
    setFieldValue(name, selectedOption);
  }

  function renderSelect() {
    const value = values[name];

    return (
      <Select
        options={options}
        value={value}
        onChange={handleSelectChange}
        onBlur={() => validateField(name)}
      />
    );
  }

  return (
    <div>
      <Label>{label}</Label>
      {renderSelect()}
      {error ? <Danger>{error}</Danger> : null}
    </div>
  );
}
