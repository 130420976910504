import { makeColorGradient } from 'pages/Reports/utils/helperFunctions';

export default function makeTreeviewData(data, startColor) {
  if (!data.length) {
    return [];
  }

  const stringifiedData = data.map(row => ({
    ...row,
    key: row.key.toString(),
  }));

  const normalizedColumns = normalizeColumns(stringifiedData);
  const separatedData = separateIntoLevels(normalizedColumns);
  const [normalizedRows, keysInfo] = normalizeRows(separatedData);

  const maxLevel = Object.keys(separatedData).length;
  const treeviewStyle = makeTreeviewStyle(maxLevel, startColor);

  return [normalizedRows, keysInfo, treeviewStyle, maxLevel];
}

function normalizeColumns(data) {
  const dataAux = data.map(row => {
    const { columns } = row;

    if (columns?.length) {
      const parsedColumns = JSON.parse(columns);

      return {
        ...row,
        ...parsedColumns,
        key: row.key.toString(),
        isLastChild: false,
      };
    }

    return {
      ...row,
      key: row.key.toString(),
      isLastChild: false,
    };
  });

  return dataAux;
}

function separateIntoLevels(data) {
  const arrayAux = {};
  data.forEach(row => {
    const { key } = row;
    const levelStr = `level${key.split('-').length}`;
    if (!arrayAux[levelStr]) {
      arrayAux[levelStr] = [];
    }

    arrayAux[levelStr].push(row);
  });

  return arrayAux;
}

function normalizeRows(data) {
  const maxLevel = Object.keys(data).length;
  const keysInfo = {};

  const normalizedDataAux = recursive(data, 1, '', maxLevel, keysInfo);

  return [normalizedDataAux, keysInfo, maxLevel];
}

function recursive(data, currentLevel, previousKey, maxLevel, keysInfo) {
  let dataAux = [];
  const currentLevelData = data[`level${currentLevel}`];

  for (let i = 0; i < currentLevelData.length; i += 1) {
    const currentRow = currentLevelData[i];
    const currentKey = currentRow.key;

    // if current key descends from previous
    if (currentKey.indexOf(previousKey) === 0) {
      const newRow = { ...currentRow };
      newRow.level = currentLevel;
      if (
        currentLevel === maxLevel ||
        isLastChild(data[`level${currentLevel + 1}`], currentKey)
      ) {
        newRow.isLastChild = true;
      }

      dataAux.push(newRow);

      if (currentLevel + 1 <= maxLevel) {
        const newDataAux = recursive(
          data,
          currentLevel + 1,
          currentKey,
          maxLevel,
          keysInfo,
        );
        const descendants = newDataAux.map(row => row.key);
        const children = descendants.filter(
          desc => desc.split('-').length === currentLevel + 1,
        );
        keysInfo[currentKey] = {
          descendants,
          children,
          isOpen: true,
        };

        dataAux = dataAux.concat(newDataAux);
      }
    }
  }

  return dataAux;
}

function isLastChild(data, currentKey) {
  return data.every(({ key }) => key.indexOf(currentKey) !== 0);
}

function makeTreeviewStyle(maxLevel, startColor) {
  const normalizedStartColor = startColor || '#dddddd';
  const gradientColors = makeColorGradient(
    maxLevel,
    normalizedStartColor,
    '#ffffff',
  );

  const styleObj = {};
  gradientColors.forEach((color, index) => {
    styleObj[`.row-level-${index + 1} td`] = {
      backgroundColor: color,
    };
    styleObj[`.row-level-${index + 1} td.col-index-0`] = {
      paddingLeft: `${7 + index * 11}px`,
    };
  });

  return styleObj;
}
