import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const Gerencial = lazy(() =>
  import(
    './layouts/gerencial' /* webpackChunkName: 'relatorios.construcompany.gerencial' */
  ),
);
const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'relatorios.construcompany.financeiro.obras' */
  ),
);
const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.construcompany.fisico.gerencial' */
  ),
);
const FvmGerencial = lazy(() =>
  import(
    './layouts/fvm/gerencial' /* webpackChunkName: 'relatorios.construcompany.fvm.gerencial' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/construcompany/gerencial"
    component={Gerencial}
    path="/relatorios/construcompany/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/construcompany/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/construcompany/financeiro/obras"
  />,
  <PrivateRoute
    key="/relatorios/construcompany/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/construcompany/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/construcompany/fvm/gerencial"
    component={FvmGerencial}
    path="/relatorios/construcompany/fvm/gerencial"
  />,
];
