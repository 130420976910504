import {
  MenuState,
  MenuActionTypes,
  SET_MENU_FLAG,
  SET_MENU_ROUTES,
  SET_MENU_PARAMS,
  HIDE_MENU,
  SHOW_MENU,
  BACK_TO_HOME_DISPLAY,
  HAS_MENU,
  SET_SUB_MENU,
  SET_MENU_RAW,
} from './types';

const initialState: MenuState = {
  menuFlag: 'topMenu',
  menuRoutes: [],
  menuParams: {},
  display: '0px',
  homeBtnDisplay: 'none',
  hasMenu: true,
  menuRendered: [],
  menuRaw: [],
};

export default (state = initialState, action: MenuActionTypes): MenuState => {
  switch (action.type) {
    case SET_MENU_FLAG:
      return {
        ...state,
        menuFlag: action.payload,
      };
    case SET_MENU_ROUTES:
      return {
        ...state,
        menuRoutes: action.payload,
      };
    case SET_MENU_PARAMS:
      return {
        ...state,
        menuParams: action.payload,
      };
    case SHOW_MENU:
      return {
        ...state,
        display: '0px',
      };
    case HIDE_MENU:
      return {
        ...state,
        display: '-260px',
      };
    case BACK_TO_HOME_DISPLAY:
      return {
        ...state,
        homeBtnDisplay: action.payload,
      };
    case HAS_MENU:
      return {
        ...state,
        hasMenu: action.payload,
      };
    case SET_SUB_MENU:
      return {
        ...state,
        menuRendered: action.payload,
      };
    case SET_MENU_RAW:
      return {
        ...state,
        menuRaw: action.payload,
      };
    default:
      return state;
  }
};
