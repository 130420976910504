import styled from 'styled-components';

export const Container = styled.div`
  flex: 0 0 25rem;
`;

export const WrapperCheckbox = styled.div`
  display: flex;
  justify-content: end;
  padding: 10px 0 0;
  gap: 8px;
`;
