import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.rg.fisico.gerencial' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/rg/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/rg/fisico/gerencial"
  />,
];
