import React, { ChangeEvent, useEffect, useState } from 'react';
import Span from 'components/Span';
import TextArea from 'components/TextArea';
import { Label } from '../../styles';
import { DynamicFormsInputProps } from '../../types';
import { useDynamicForm } from '../../context';
import useUser from 'hooks/use-user';
import { checkUserEditPermission } from '../../utils';
import { getSingleResponse } from 'services/dataset';

export default function InputTextArea(props: DynamicFormsInputProps) {
  const { field, defaultValue, isRequired, onChange, isDisabled } = props;
  const [value, setValue] = useState(defaultValue);
  const {
    approvalData,
    fieldsAllowProps,
    globalState,
    contractId: idContrato,
    newFormData,
  } = useDynamicForm();

  const user = useUser();

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    if (globalState) {
      fetchDatasetData();
    }
  }, [user, globalState]);

  useEffect(() => {
    const normalizedValue =
      defaultValue == 0 || defaultValue ? defaultValue : '';

    setValue(normalizedValue);
  }, [defaultValue]);

  async function fetchDatasetData() {
    if (value || !user || !field.datasetId) {
      return;
    }

    const { idEmpresa, idUsuario } = user;
    const { datasetParams } = globalState!;

    const params = {
      idUsuario,
      idEmpresa,
      idContrato,
      ...field.datasetParam,
      ...datasetParams,
    };

    for (const iterator of [1, 2, 3, 4, 5]) {
      try {
        const res = await getSingleResponse<any>(field.datasetId, params, {
          disableConvertToCamelcase: true,
        });
        if (res) {
          setValue(res[field.name]);
          newFormData.current[field.name] = res[field.name];
        }

        break;
      } catch (error) {
        console.log('retentaviva', iterator);
      }
    }
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.name, e.target.value);
    setValue(e.target.value);
  }

  return (
    <>
      <Label
        htmlFor={field.name}
        dangerouslySetInnerHTML={{ __html: field.label }}
        isLabel={field.label}
      />

      <TextArea
        disabled={isDisableChecked}
        id={field.name}
        name={field.name}
        value={value}
        error={isRequired}
        onChange={handleChange}
      />

      {isRequired && <Span text="Campo obrigatório" />}
    </>
  );
}
