import {
  SupplierMeasureState,
  SupplierMeasureTypes,
  SET,
  CLEAR,
} from './types';

const initialState: SupplierMeasureState = {
  contract: null,
  isEditMode: false,
};

export default (
  state = initialState,
  action: SupplierMeasureTypes,
): SupplierMeasureState => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
};
