import React from 'react';
import {
  UpArrow,
  DownArrow,
  MidRect,
} from '../../../../../../../TableReport/components/Icons/index';
import { StyledDiv } from './styles';

export default function ArrowsType(props) {
  const {
    col: { valueFormatter },
    value,
  } = props;

  const normFormatter = valueFormatter || (v => v);

  const { index, value: newValue } = JSON.parse(value);

  function renderIcon() {
    if (index === 1) {
      return <DownArrow />;
    }
    if (index === 2) {
      return <MidRect />;
    }
    if (index === 3) {
      return <UpArrow />;
    }
  }
  return (
    <StyledDiv className="celltype-arrows">
      <div className="icon-container">{renderIcon()}</div>
      <span className="value">{normFormatter(newValue)}</span>
    </StyledDiv>
  );
}
