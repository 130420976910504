import * as Yup from 'yup';

export const validation = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required('Este campo é obrigatório'),
  email: Yup.string()
    .nullable()
    .email('Digite um e-mail válido')
    .required('Este campo é obrigatório'),
  position: Yup.string()
    .nullable()
    .required('Este campo é obrigatório'),
  role: Yup.object()
    .nullable()
    .required('Este campo é obrigatório'),
});
