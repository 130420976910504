/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import Span from 'components/Span';
import { OptionsType } from 'store/types';
import { DynamicFormsInputProps } from '../../types';
import { useDynamicForm } from '../../context';
import useUser from 'hooks/use-user';
import { checkUserEditPermission } from '../../utils';
import SupplierCreatableSelect from 'components/Widgets/SupplierCreatableSelect';

export default function InputCreatableSupplier(props: DynamicFormsInputProps) {
  const { field, defaultValue, isRequired, isDisabled, onChange } = props;

  const { approvalData, fieldsAllowProps } = useDynamicForm();

  const [selectedOptions, setSelectedOptions] = useState<
    OptionsType | OptionsType[]
  >(defaultValue || null);

  const user = useUser();

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    setSelectedOptions(defaultValue || null);
  }, [defaultValue]);

  function handleSelect(option: OptionsType | OptionsType[]) {
    onChange(field.name, option);
    setSelectedOptions(option);
  }

  return (
    <div style={{ textAlign: 'left', height: 'auto' }}>
      <SupplierCreatableSelect
        datasetId={field.datasetId}
        supplier={selectedOptions}
        setSupplier={handleSelect}
        isDisabled={isDisableChecked}
        isMulti={field.isMulti}
        label={field.label}
        isClearable
      />
      {isRequired && <Span text="Campo obrigatório" />}
    </div>
  );
}
