import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';
import { isIOS } from 'react-device-detect';
import { RootState } from 'store/rootReducer';
import Dashdoards from 'pages/Dashboards';
import NavBar from './components/Navbar';
import Menu from './components/Menu';
import { MenuMainWrapper, Main } from './styles/routes';
import SignIn from './pages/SignIn';
import NewPassword from './pages/NewPassword';
import ResetPassword from './pages/ResetPassword';
import UploadImage from './pages/UploadImage';
import { isAuthenticated } from './services/auth';
import { EnumRoutes } from './store/types';
import UploadFile from './pages/UploadFile';
import Files from './pages/Files';
import { reportRoutes } from './pages/Reports/routes';
import Download from 'pages/Download';
import GenerateDownloadPDF from 'pages/GenerateDownloadPDF';
import PrivacyPolicy from 'pages/PrivacyPolicy';

const ReportQueue = lazy(() => import('./pages/ReportQueue'));
const ContractListFinishReport = lazy(() =>
  import('./pages/Financeiro/ContractListFinishReport'),
);
const ReportOrderReceipt = lazy(() =>
  import('./pages/Financeiro/ReportOrderReceipt'),
);

const FlowDisbursement = lazy(() =>
  import('./pages/Financeiro/FlowDisbursement'),
);

const QuotationTable = lazy(() => import('./pages/Financeiro/QuotationTable'));

const Measurement = lazy(() => import('./pages/Financeiro/Measurement'));

const OrderMaterialReceipt = lazy(() =>
  import('./pages/Financeiro/OrderMaterialReceipt'),
);

const SheetsManager = lazy(() => import('./pages/SheetsManager'));
const OrderList = lazy(() => import('./pages/Financeiro/OrderList'));

const ListSupplierContract = lazy(() =>
  import('./pages/Financeiro/ListSupplierContract'),
);
const ListOrderContract = lazy(() =>
  import('./pages/Financeiro/ListOrderContract'),
);
const ImportXls = lazy(() => import('./pages/Producao/ImportXls'));
const SectorsManager = lazy(() => import('./pages/SectorsManager'));
const ScheduleSectorsManager = lazy(() =>
  import('./pages/ScheduleSectorsManager'),
);

const FvsScheduleConfigureSectors = lazy(() =>
  import('./pages/FvsScheduleConfigureSectors'),
);
const FvsSetup = lazy(() => import('./pages/FvsSetup'));
const FvsSetupSchedule = lazy(() => import('./pages/FvsSetupSchedule'));
const Sheets = lazy(() => import('./pages/Sheets'));
const Home = lazy(() => import('./pages/Home'));
const TableReport = lazy(() => import('./pages/TableReport'));
const Reports = lazy(() => import('./pages/Reports'));
const MobileUnderConstruction = lazy(() =>
  import('./pages/MobileUnderConstruction'),
);
const CompanyFiles = lazy(() => import('./pages/CompanyFiles'));
const DynamicForm = lazy(() => import('./pages/DynamicForm'));
const MeasurementSheet = lazy(() =>
  import('./pages/Financeiro/MeasurementSheet'),
);

const ContractAccess = lazy(() => import('./pages/ContractAccess'));
const UserAccessModel = lazy(() => import('./pages/UserAccessModel'));
const UsersManagement = lazy(() => import('./pages/UsersManagement'));
const SupplierManagement = lazy(() => import('./pages/SupplierManagement'));
const RegisterMeasure = lazy(() =>
  import('./pages/Financeiro/RegisterMeasure'),
);
const OrderScope = lazy(() => import('./pages/Financeiro/OrderScope'));
const BudgetMaintenance = lazy(() =>
  import('./pages/Financeiro/BudgetMaintenance'),
);
const BaselineBudgetMaintenance = lazy(() =>
  import('./pages/Financeiro/BaselineBudgetMaintenance'),
);
const Tag = lazy(() => import('./pages/Tags'));
const ConfigAppropriation = lazy(() =>
  import('pages/Financeiro/ConfigAppropriation'),
);
const TableApproval = lazy(() => import('pages/TableApproval'));
const SqliteBackupList = lazy(() => import('pages/SqliteBackupList'));
const ListRateMeasurementContract = lazy(() =>
  import('./pages/Financeiro/ListRateMeasurementContract'),
);
const PhotoGallery = lazy(() => import('./pages/PhotoGallery'));

const MilestoneAssociation = lazy(() =>
  import('./components/Modal/Suprimentos/MilestoneAssociationTable'),
);

const Support = lazy(() => import('pages/Support'));

import Feicon from 'pages/Feicon';
import FeiconDownload from 'pages/Feicon/pages';
import GraphicPrint from 'pages/GraphicPrint';
import GlobalModalImporter from 'components/Modal/GlobalModalImporter';
import DocSign from 'pages/DocSign';

const Maps = lazy(() => import('./pages/Maps'));
const MapsNew = lazy(() => import('./pages/MapsNew'));

export default function Routes() {
  useEffect(() => {
    if (isIOS) turnOffZoom();
  }, []);

  function turnOffZoom() {
    const el = document.querySelector('meta[name=viewport]');
    const content =
      'width=device-width, initial-scale=1, maximum-scale=1, minimium-scale=1, user-scalable=no';
    el.content = content;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path={EnumRoutes.SIGNIN} component={SignIn} />
        <Route path={EnumRoutes.UPLOAD_FILE} component={UploadFile} />
        <Route path={EnumRoutes.NEW_PASSWORD} component={NewPassword} />
        <Route path={EnumRoutes.RESET_PASSWORD} component={ResetPassword} />
        <Route path={EnumRoutes.PHANTOM_REPORT_TEST} component={Dashdoards} />
        <Route path={EnumRoutes.DOWNLOAD} component={Download} />
        <Route
          path={EnumRoutes.GENERATE_DOWNLOAD_PDF}
          component={GenerateDownloadPDF}
        />
        <Route path={EnumRoutes.FEICON} component={Feicon} />
        <Route path={EnumRoutes.GRAPHIC_PRINT} component={GraphicPrint} />
        <Route path={EnumRoutes.GOOGLE_PLAY} component={FeiconDownload} />
        <Route path={EnumRoutes.PRIVACY_POLICY} component={PrivacyPolicy} />
        <PrivateRoute path={EnumRoutes.REPORT_QUEUE} component={ReportQueue} />

        <PrivateRoute exact path={EnumRoutes.HOME} component={Home} />
        <PrivateRoute
          path={EnumRoutes.SHEET_MANAGER}
          component={SheetsManager}
        />
        <PrivateRoute
          path={EnumRoutes.FVS_SCHEDULE_CONFIGURE_SECTORS}
          component={FvsScheduleConfigureSectors}
        />

        <PrivateRoute
          path={EnumRoutes.SCHEDULE_SECTORS_MANAGER}
          component={ScheduleSectorsManager}
        />
        <PrivateRoute
          path={EnumRoutes.SECTORS_MANAGER}
          component={SectorsManager}
        />
        <PrivateRoute path={EnumRoutes.REPORTS} component={Reports} />
        {reportRoutes()}
        <PrivateRoute
          path={EnumRoutes.MOBILE_CONSTRUCTION}
          component={MobileUnderConstruction}
        />
        <PrivateRoute path={EnumRoutes.FVS} component={FvsSetup} />
        <PrivateRoute
          path={EnumRoutes.FVS_SCHEDULE}
          component={FvsSetupSchedule}
        />
        <PrivateRoute path={EnumRoutes.SHEETS} component={Sheets} />
        <PrivateRoute
          path={EnumRoutes.CONTRACT_MANAGEMENT}
          component={ContractAccess}
        />

        <PrivateRoute
          path={EnumRoutes.USER_ACCESS_LEVEL}
          component={UserAccessModel}
        />

        <PrivateRoute
          path={EnumRoutes.USERS_MANAGEMENT}
          component={UsersManagement}
        />
        <PrivateRoute
          path={EnumRoutes.SUPPLIER_MANAGEMENT}
          component={SupplierManagement}
        />
        <PrivateRoute path={EnumRoutes.TABLE_REPORT} component={TableReport} />
        <PrivateRoute
          path={EnumRoutes.TABLE_APPROVAL}
          component={TableApproval}
        />
        <PrivateRoute path={EnumRoutes.SUPPORT} component={Support} />
        <Route path={EnumRoutes.DOC_SIGN} component={DocSign} />

        <Route path={EnumRoutes.UPLOAD_IMAGE} component={UploadImage} />
        <PrivateRoute path={EnumRoutes.FILES} component={Files} />
        <PrivateRoute
          path={EnumRoutes.COMPANY_FILES}
          component={CompanyFiles}
        />
        <PrivateRoute path={EnumRoutes.DYNAMIC_FORM} component={DynamicForm} />
        <PrivateRoute path={EnumRoutes.IMPORT_XLS} component={ImportXls} />
        <PrivateRoute
          path={EnumRoutes.LIST_SUPPLIER_CONTRACT}
          component={ListSupplierContract}
        />
        <PrivateRoute
          path={EnumRoutes.LIST_ORDER_CONTRACT}
          component={ListOrderContract}
        />
        <PrivateRoute
          path={EnumRoutes.MEASUREMENT_SHEET}
          component={MeasurementSheet}
        />
        <PrivateRoute path={EnumRoutes.ORDER_LIST} component={OrderList} />

        <PrivateRoute
          path={EnumRoutes.ORDER_MATERIAL_RECEIPT}
          component={OrderMaterialReceipt}
        />
        <PrivateRoute
          path={EnumRoutes.REPORT_ORDER_RECEIPT}
          component={ReportOrderReceipt}
        />
        <PrivateRoute
          path={EnumRoutes.REGISTER_MEASUREMENT}
          component={RegisterMeasure}
        />
        <PrivateRoute path={EnumRoutes.MEASUREMENT} component={Measurement} />
        <PrivateRoute
          path={EnumRoutes.CONTRACT_LIST_FINISH_REPORT}
          component={ContractListFinishReport}
        />
        <PrivateRoute
          path={EnumRoutes.FLOW_DISBURSEMENT}
          component={FlowDisbursement}
        />
        <PrivateRoute
          path={EnumRoutes.QUOTATION_TABLE}
          component={QuotationTable}
        />

        <PrivateRoute path={EnumRoutes.ORDER_SCOPE} component={OrderScope} />
        <PrivateRoute
          path={EnumRoutes.BUDGET_MAINTENANCE}
          component={BudgetMaintenance}
        />
        <PrivateRoute
          path={EnumRoutes.BASELINE_BUDGET_MAINTENANCE}
          component={BaselineBudgetMaintenance}
        />
        <PrivateRoute path={EnumRoutes.TAGS} component={Tag} />
        <PrivateRoute path={EnumRoutes.MAPS} component={Maps} />
        <PrivateRoute path={EnumRoutes.MAPSNEW} component={MapsNew} />
        <PrivateRoute
          path={EnumRoutes.CONFIG_APPROPRIATION}
          component={ConfigAppropriation}
        />
        <PrivateRoute
          path={EnumRoutes.MILESTONE_MANAGER}
          component={MilestoneAssociation}
        />
        <PrivateRoute
          path={EnumRoutes.SQLITE_BACKUP_LIST}
          component={SqliteBackupList}
        />
        <PrivateRoute
          path={EnumRoutes.LIST_MEASUREMENT_CONTRACT}
          component={ListRateMeasurementContract}
        />
        <PrivateRoute
          path={EnumRoutes.PHOTO_GALLERY}
          component={PhotoGallery}
        />
        <PrivateRoute
          path={EnumRoutes.NOT_FOUND}
          component={() => <h1>Page not found</h1>}
        />
      </Switch>
      <GlobalModalImporter />
    </BrowserRouter>
  );
}

export function PrivateRoute({ component, ...rest }: any) {
  const [mobileNavbarHeight, setMobileNavbarHeight] = useState('3.8rem');
  const { datasetId } = useSelector((state: RootState) => state.contract);

  useEffect(() => {
    const mobileNavbarHeightAux = datasetId ? '7.2rem' : '3.8rem';
    setMobileNavbarHeight(mobileNavbarHeightAux);
  }, [mobileNavbarHeight, datasetId]);

  const routeComponent = (props: RouteComponentProps) => {
    if (isAuthenticated()) {
      return React.createElement(component, props);
    }

    const { location } = props;

    return <Redirect to={{ pathname: '/signin', state: { from: location } }} />;
  };

  return (
    <>
      {/* <UrlValidator /> */}
      <NavBar hideButtons={false} />
      <MenuMainWrapper navHeight={mobileNavbarHeight}>
        <Menu id="menu-wrapper" />
        <Main id="main-view">
          <Suspense fallback={null}>
            <Route
              {...rest}
              render={props => routeComponent({ ...props, ...rest })}
            />
          </Suspense>
        </Main>
      </MenuMainWrapper>
    </>
  );
}
