import Input from 'components/Input';
import Select from 'components/ReactSelect';
import React, { useState } from 'react';
import { EnumRoutes, OptionsType } from 'store/types';
import { useFormik } from 'formik';
import { DataValues, SaveValues } from 'services/feicon';
import Button from 'components/Button';
import { useHistory } from 'react-router';
import { validation } from './validation';

const areasOfInterest: OptionsType[] = [
  { value: 'Planejamento', label: 'Planejamento e controle de obras' },
  { value: 'Gestão', label: 'Gestão do orçamento' },
  { value: 'Qualidade', label: 'Qualidade' },
  { value: 'Segurança', label: 'Segurança' },
];

export default function Faicon() {
  const [selectedType, setSelectedType] = useState<OptionsType[]>([]);

  const history = useHistory();

  const formik = useFormik({
    validationSchema: validation,
    initialValues: {
      nome: '',
      email: '',
      empresa: '',
      celular: 0,
      comentario: '',
    },
    onSubmit: (values: DataValues) => {
      const normalizedComment = selectedType.map(item => item.value).join(', ');

      SaveValues({ ...values, comentario: normalizedComment || '' });
      history.push(EnumRoutes.FEICON_DOWNLOAD);
    },
  });

  function handleSelectChange(selectedOption: OptionsType[]) {
    setSelectedType(selectedOption);
  }

  function handleChangePhoneNumber(e) {
    if (e.target.value.length > 11) return;

    formik.setFieldValue('celular', e.target.value);
  }

  return (
    <div style={{ overflow: 'scroll', height: '100vh' }}>
      <div
        style={{
          backgroundColor: '#54C3BD',
          height: '55px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '15px',
          paddingTop: '18px',
        }}
      >
        <img
          width={120}
          height={25}
          src="https://gescorpgo-datafiles.nyc3.digitaloceanspaces.com/datafiles/logos/empresa/2b.png"
          alt="gescorp"
        />

        <img
          width={120}
          height={25}
          src="https://gescorpgo-datafiles.nyc3.digitaloceanspaces.com/datafiles/logos/empresa/feicon.png"
          alt="feicon"
        />
      </div>

      <div style={{ margin: '15px' }}>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '15px',
            fontSize: '1.4rem',
          }}
        >
          <b>Bem vindo à GescorpGO!</b>
        </p>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '10px',
            fontSize: '1.4rem',
          }}
        >
          Faça seu cadastro e conheça mais sobre como podemos levar mais
          agilidade e eficiência no controle de seus empreeendimentos.
        </p>
      </div>

      <form onSubmit={formik.handleSubmit} style={{ margin: '15px' }}>
        <div>
          <p
            style={{
              margin: '5px 0',
              display: 'block',
              position: 'relative',
              fontSize: '1.4rem',
            }}
          >
            Áreas de interesse
          </p>
        </div>
        <Select
          name="comentario"
          value={selectedType}
          options={areasOfInterest}
          onChange={handleSelectChange}
          isMulti={true}
          height={'auto'}
        ></Select>
        <Input
          label="Nome"
          id="nome"
          name="nome"
          type="text"
          errorMessage={formik.errors.nome}
          onChange={formik.handleChange}
          value={formik.values.nome}
        />

        <Input
          label="Email"
          id="email"
          name="email"
          errorMessage={formik.errors.email}
          onChange={formik.handleChange}
          value={formik.values.email}
        />

        <Input
          label="Empresa"
          id="empresa"
          name="empresa"
          type="text"
          errorMessage={formik.errors.empresa}
          onChange={formik.handleChange}
          value={formik.values.empresa}
        />

        <Input
          label="Telefone"
          id="celular"
          name="celular"
          type="number"
          placeholder="(00) 00000-0000"
          errorMessage={formik.errors.celular}
          onChange={handleChangePhoneNumber}
          value={formik.values.celular}
        />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '40px',
          }}
        >
          <Button
            type="submit"
            text="Enviar"
            style={{ backgroundColor: '#54C3BD', color: 'white' }}
          ></Button>
        </div>
      </form>
    </div>
  );
}
