import React, { ReactElement, ButtonHTMLAttributes } from 'react';
import { withTheme, ThemeProvider } from 'styled-components';
import { StyledButton } from './styles';
import Spinner from '../Spinner';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  outline?: boolean;
  primary?: boolean;
  danger?: boolean;
  info?: boolean;
  isLoading?: boolean;
  small?: boolean;
  tiny?: boolean;
  theme: any;
  children?: ReactElement | ReactElement[];
  icon?: ReactElement | ReactElement[];
}

function Button(props: Props): ReactElement {
  const {
    children,
    danger,
    text,
    primary,
    small,
    tiny,
    isLoading,
    theme,
    outline,
    icon,
    info,
  } = props;

  function getKind(): string {
    if (primary) {
      return 'primary';
    }

    if (danger) {
      return 'danger';
    }

    if (info) {
      return 'info';
    }

    return 'default';
  }

  function getSpinnerSize() {
    if (tiny) {
      return 'tiny';
    }

    if (small) {
      return 'small';
    }

    return '';
  }

  function renderSpinner() {
    if (!isLoading) {
      return null;
    }

    return (
      <Spinner
        color={
          outline || getKind() === 'default' ? theme.colors.primary : '#fff'
        }
        size={getSpinnerSize()}
      />
    );
  }

  function renderText() {
    if (isLoading) {
      return null;
    }

    return text && <span>{text}</span>;
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledButton {...props} kind={getKind()}>
        {icon}
        {renderSpinner()}
        {renderText()}
        {children}
      </StyledButton>
    </ThemeProvider>
  );
}

export default withTheme(Button);
