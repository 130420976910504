import styled from 'styled-components';

export const StyledDiv = styled.div`
  display: flex;
  padding: 0 4px;
  position: relative;
  align-items: center;

  .separator {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 16px;
    width: 2px;
    border-left: 1px solid #666;
  }

  .bar {
    position: absolute;
    height: 10px;
  }

  .label {
    position: absolute;
  }
`;
