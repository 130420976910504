import { createContext, useContext } from 'react';
import { XlsExportType } from './types';

export interface SimpleTableContextProps {
  id?: string | number;
  parentRowsInfo: any;
  setFilters: any;
  levelSelection: any;
  setLevelSelection: any;
  xlsExport: XlsExportType;
  idPeriodo?: number;
}

export const SimpleTableContext = createContext<SimpleTableContextProps>(
  {} as SimpleTableContextProps,
);

export function useSimpleTableContext() {
  return useContext(SimpleTableContext);
}
