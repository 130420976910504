import showAlert from 'components/Alert';
import Button from 'components/Button';
import Modal from 'components/Modal';
import TextArea from 'components/TextArea';
import useModal from 'hooks/use-modal';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import Viewer from 'react-viewer';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import { addNew, editNew as editNewComment } from 'services/comment';
import { edit, remove } from 'services/photo';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { ModalContainer } from 'styles/modal';
import { LabelInfo, Text } from 'styles/text';
import { generateUUID, isNullOrUndefined } from 'utils/common';
import { getUsDateTime, toDateTimeBR } from 'utils/date';
import { getSqlResponse } from '../../../services/dataset';
import { DatasetParams, Photo } from '../../../store/types';
import { normalizeFileUrl } from '../../../utils/file';
import {
  ActionsWrapper,
  Row,
  Sidebar,
  ViewerContainer,
  Wrapper,
} from './styles';
import useUser from 'hooks/use-user';
import { addCommentToList } from './utils';
import { Legend } from './components/Legends';

interface PhotoResponse {
  idForo: number;
  referencia: string;
  usuario: string;
  dtCriacao: string;
  appendedData?: string;
}

export interface Image extends ImageDecorator {
  usuario: string;
  dtCriacao: string;
  comentario?: string;
  uuidComentario?: string;
  uuidReferencia?: string;
  uuidFoto?: string;
  idContrato?: number;
  isNewComment?: boolean;
  isMainImage?: boolean;
  key?: string;
  tipo?: string;
  isAllowEdit?: boolean;
  appendedData?: string;
}

export interface ImagePropsParams {
  datasetParams?: DatasetParams;
  datasetId?: number;
  images?: Image[];
  initialCurrentImageIndex?: number;
  onCloseCB?(): Promise<void> | void;
}

export default function ImageViewerModal() {
  const { isOpen, params, windowSec } = useModal<ImagePropsParams>(
    ModalName.IMAGE_VIEWER,
  );

  const [images, setImages] = useState<Image[]>([]);

  const [currentImage, setCurrentImage] = useState({} as Image);
  const [currentImageIndex, setCurrentImageIndex] = useState<
    number | undefined
  >(0);

  const [containerRef, setContainerRef] = useState();

  const isDataUpdate = useRef(false);

  const user = useUser();

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    if (isOpen) {
      fetchImages();
    } else {
      isDataUpdate.current = false;

      setImages([]);
      setCurrentImage({} as Image);
      setCurrentImageIndex(undefined);
    }
  }, [isOpen]);

  async function fetchImages() {
    const { images: imagesFromParams, datasetId, datasetParams } = params;

    if (imagesFromParams) {
      setImages(imagesFromParams);

      setInitialCurrentImage(imagesFromParams);

      return;
    }

    if (!datasetId) {
      return;
    }

    const response = await getSqlResponse<PhotoResponse[]>(
      datasetId,
      datasetParams,
    );

    const normalized = response.map<Image>(img => ({
      ...img,
      src: normalizeFileUrl(img.referencia),
      downloadUrl: normalizeFileUrl(img.referencia),
      alt: '',
      appendedData: normalizedAppended(img.appendedData),
    }));

    if (!normalized?.length) {
      addToast('Nenhuma imagem encontrata');
      handleClose();

      return;
    }

    setImages(normalized);

    setInitialCurrentImage(normalized);
  }

  function normalizedAppended(data) {
    if (data) {
      const newData = JSON.parse(data);

      return newData;
    }

    return null;
  }

  function setInitialCurrentImage(currentImages: Image[]) {
    const { initialCurrentImageIndex } = params;

    if (!currentImages.length) {
      return;
    }

    const imageIndexToSet = isNullOrUndefined(initialCurrentImageIndex)
      ? 0
      : initialCurrentImageIndex!;

    setCurrentImageIndex(imageIndexToSet);
    setCurrentImage(currentImages[imageIndexToSet]);
  }

  async function handleClose() {
    const { onCloseCB } = params;

    if (isDataUpdate.current && onCloseCB) {
      await onCloseCB();
    }

    setImages([]);
    dispatch(closeModal(ModalName.IMAGE_VIEWER));
  }

  function removeImageFromState() {
    if (currentImage.isMainImage) {
      return handleClose();
    }

    const removedImageIndex = images.findIndex(
      image => image.uuidFoto === currentImage.uuidFoto,
    );

    const newImages = images.filter(
      image => image.uuidFoto !== currentImage.uuidFoto,
    );

    if (!newImages.length) {
      return handleClose();
    }

    let newCurrentImageIndex = removedImageIndex;

    if (newImages.length === 1) {
      newCurrentImageIndex = 0;
    } else if (removedImageIndex === newImages.length) {
      newCurrentImageIndex = removedImageIndex - 1;
    }

    setCurrentImageIndex(newCurrentImageIndex);
    setCurrentImage(newImages[newCurrentImageIndex]);
    setImages(newImages);
  }

  function getImagesToDelete() {
    let commentsToRemove: Image[] = [currentImage];

    if (currentImage.isMainImage) {
      commentsToRemove = images;
    }

    return commentsToRemove;
  }

  function deleteComments() {
    const removeCommentPromises = getImagesToDelete().map(image => {
      if (!image.uuidComentario) {
        return;
      }

      return editNewComment({
        flagStatus: 0,
        idContrato: image.idContrato,
        comentario: image.comentario!,
        uuid: image.uuidComentario,
      });
    });

    return removeCommentPromises;
  }

  function deleteImages() {
    const removeCommentPromises = getImagesToDelete().map(image =>
      remove(image.uuidFoto!),
    );

    return removeCommentPromises;
  }

  async function removeImage() {
    isDataUpdate.current = true;

    dispatch(showModal(ModalName.LOADING, { text: 'Excluindo imagem' }));

    const removeCommentPromises = deleteComments();

    const removeImagesPromises = deleteImages();

    await Promise.all([...removeCommentPromises, ...removeImagesPromises]);

    removeImageFromState();

    dispatch(closeModal(ModalName.LOADING));

    addToast('Imagem excluida com sucesso', { appearance: 'success' });
    isDataUpdate.current = true;
  }

  function handleRemoveImage() {
    showAlert({
      title: 'Atenção!',
      subtitle: `Confirma a inclusão da imagem`,
      actions: [
        {
          text: 'Não',
        },
        {
          text: 'Sim',
          onClick: removeImage,
        },
      ],
    });
  }

  async function handleSaveComment() {
    dispatch(showModal(ModalName.LOADING, { text: 'Salvando comentário' }));

    const {
      tipo,
      idContrato,
      comentario,
      uuidComentario,
      uuidReferencia,
    } = currentImage;

    let newCurrentImage = { ...currentImage };

    if (!uuidComentario) {
      const newCommentUuid = generateUUID();

      await addNew({
        id: 0,
        idContrato,
        tipo,
        origem: 'FOTOS',
        comentario: comentario!,
        uuid: newCommentUuid,
        idUsuario: user.idUsuario!,
        uuidReferencia: uuidReferencia,
        dtCriacao: getUsDateTime(),
      });

      await edit([
        {
          idContrato,
          uuid: currentImage.uuidFoto,
          uuidComentario: newCommentUuid,
        } as Photo,
      ]);

      newCurrentImage = {
        ...newCurrentImage,
        uuidComentario: newCommentUuid,
        comentario,
      };
    } else {
      await editNewComment({
        idContrato,
        flagStatus: 1,
        comentario: comentario!,
        uuid: uuidComentario,
      });

      newCurrentImage = {
        ...newCurrentImage,
        comentario,
      };
    }

    setCurrentImage({ ...newCurrentImage, isNewComment: false });
    setImages(addCommentToList(images, newCurrentImage));

    dispatch(closeModal(ModalName.LOADING));

    addToast('Comentário salvo com sucesso', { appearance: 'success' });

    isDataUpdate.current = true;
  }

  function handleChangeComment(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    setCurrentImage({
      ...currentImage,
      comentario: e.target.value,
      isNewComment: true,
    });
  }

  function handleChangeImage(activeImage: ImageDecorator, index: number) {
    setCurrentImageIndex(index);
    setCurrentImage(activeImage as Image);

    if (currentImage.isNewComment) {
      const previousImageIndex = currentImageIndex;
      const previousImage = currentImage;

      showAlert({
        title: 'Atenção!',
        subtitle: `Você prossui alterações não salvas, deseja prosseguir sem salvar?`,
        actions: [
          {
            text: 'Não',
            onClick: () => {
              setCurrentImageIndex(previousImageIndex);
              setCurrentImage(previousImage);
            },
          },
          {
            text: 'Sim',
          },
        ],
      });
    }
  }

  function renderActions() {
    if (!currentImage.isAllowEdit) {
      return;
    }

    return (
      <ActionsWrapper>
        <Button danger text="Excluir" onClick={handleRemoveImage} />
        <Button primary text="Salvar" onClick={handleSaveComment} />
      </ActionsWrapper>
    );
  }

  const { comentario, usuario, dtCriacao, appendedData } = currentImage;

  return (
    <Modal isOpen={isOpen} windowSec={windowSec}>
      <ModalContainer
        width="90vw"
        height="95vh"
        style={{ padding: 0, overflow: 'hidden' }}
      >
        <Wrapper>
          <Sidebar>
            <div>
              <Row>
                <LabelInfo>Data</LabelInfo>
                <Text>{toDateTimeBR(dtCriacao)}</Text>
              </Row>

              <Row>
                <LabelInfo>Criado por</LabelInfo>
                <Text>{usuario}</Text>
              </Row>

              <Row>
                <LabelInfo>Comentário</LabelInfo>
                <TextArea
                  disabled={!currentImage.isAllowEdit}
                  value={comentario || ''}
                  onChange={handleChangeComment}
                />
              </Row>

              <Row>
                <Legend data={appendedData} />
              </Row>
            </div>

            {renderActions()}
          </Sidebar>

          <ViewerContainer ref={newRef => setContainerRef(newRef)}>
            <Viewer
              downloadable
              noImgDetails
              downloadInNewWindow
              images={images}
              scalable={false}
              activeIndex={images.length ? currentImageIndex : undefined}
              visible={!!images.length}
              container={containerRef}
              onClose={handleClose}
              onChange={handleChangeImage}
            />
          </ViewerContainer>
        </Wrapper>
      </ModalContainer>
    </Modal>
  );
}
