import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
`;

export const RowTitle = styled.p`
  font-size: 1.5rem;
  flex: 1;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 1rem;
  background-color: #eee;
  padding: 0.5rem;
  border-radius: 2px;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  height: 4rem;
  justify-content: space-between;
`;

export const Label = styled.label<{ isLabel: string }>`
  font-size: 1.4rem;

  &:after {
    display: ${({ isLabel }) => !isLabel && 'none'};
    content: ':';
    position: absolute;
  }
`;
