import { GiPaperClip } from 'react-icons/gi';
import styled from 'styled-components';

export const Cell = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconFile = styled(GiPaperClip)`
  font-size: 1.6rem;
  color: #000;
  cursor: pointer;
`;
