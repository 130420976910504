import { useAtomValue, useSetAtom } from 'jotai';
import { isMainDocGeneratedAtom, urlParamsAtom } from 'pages/DocSign/atoms';
import React, { useEffect, useRef, useState } from 'react';
import { FaCheck, FaInfoCircle } from 'react-icons/fa';
import {
  getAdonis,
  ReportQueueStatus,
  updateByUuid,
} from 'services/report_queue';
import { IconSpinner } from 'styles/icons';
import { isObjectEmpty } from 'utils/common';
import { IconWrapper } from './styles';
import { useToasts } from 'react-toast-notifications';
import { handleAlertErrorGeneratingDocumentation } from './utils';
import { closeModal, showModal } from 'store/modal/actions';
import { useDispatch } from 'react-redux';
import { ModalName } from 'store/modal/types';

export default function ReportStatus() {
  const intervalRef = useRef<number | null>(null);
  const { addToast } = useToasts();

  const previousReportStatus = useRef<ReportQueueStatus | undefined>();

  const [reportStatus, setReportStatus] = useState(ReportQueueStatus.PENDING);

  const urlParams = useAtomValue(urlParamsAtom);
  const setMainDocGenerated = useSetAtom(isMainDocGeneratedAtom);

  const dispatch = useDispatch();

  useEffect(() => {
    checkReportStatus();

    intervalRef.current = window.setInterval(checkReportStatus, 5000);

    return () => {
      window.clearInterval(intervalRef.current!);
    };
  }, [urlParams]);

  async function checkReportStatus() {
    if (isObjectEmpty(urlParams)) {
      return;
    }

    const queueDoc = await getAdonis(urlParams!.uuidArquivo);

    if (queueDoc.status === ReportQueueStatus.REPORT_SENT) {
      setMainDocGenerated(true);
      setReportStatus(ReportQueueStatus.REPORT_SENT);

      if (previousReportStatus.current) {
        addToast('Relatório gerado', { appearance: 'success' });
      }

      window.clearInterval(intervalRef.current!);

      return;
    }

    if (
      [ReportQueueStatus.ERROR, ReportQueueStatus.REPORT_SENT].includes(
        queueDoc.status!,
      )
    ) {
      setReportStatus(queueDoc.status!);
      window.clearInterval(intervalRef.current!);
      handleAlertErrorGeneratingDocumentation(handleResetGenerateDocument);
    }

    previousReportStatus.current = queueDoc.status;
  }

  async function handleResetGenerateDocument() {
    setReportStatus(ReportQueueStatus.PENDING);

    dispatch(
      showModal(ModalName.LOADING, { text: 'Solicitando novo documento...' }),
    );

    await updateByUuid({
      uuid: urlParams!.uuidArquivo!,
      status: ReportQueueStatus.PENDING,
    });

    dispatch(closeModal(ModalName.LOADING));
    previousReportStatus.current = undefined;

    intervalRef.current = window.setInterval(checkReportStatus, 5000);
  }

  function renderDocInfo(): JSX.Element | null {
    switch (reportStatus) {
      case ReportQueueStatus.REPORT_SENT:
        return (
          <IconWrapper>
            <FaCheck color={'green'} />
            <span>Documento Gerado</span>
          </IconWrapper>
        );
      case ReportQueueStatus.ERROR:
        return (
          <IconWrapper>
            <FaInfoCircle color={'red'} />
            <span>Erro ao gerar documento</span>
          </IconWrapper>
        );
      default:
        return (
          <IconWrapper>
            <IconSpinner size="10px" color="#333" />
            <span>Gerando Documento...</span>
          </IconWrapper>
        );
    }
  }

  return renderDocInfo();
}
