import React from 'react';
import WarningPageContainer from '../WarningPageContainer';

export default function WarningSignedCancelPage() {
  return (
    <WarningPageContainer>
      <h1>Processo de Assinatura Cancelado</h1>

      <br />

      <p>
        Caso deseje recomeçar o processo de assinatura, abra novamente o link
        enviado para seu e-mail.
      </p>
    </WarningPageContainer>
  );
}
