import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const DashboardAcidentes = lazy(() =>
  import(
    './layouts/seguranca/DashboardAcidentes' /* webpackChunkName: 'relatorios.skr.seguranca.dashboard-acidentes' */
  ),
);
const DashboardComportamental = lazy(() =>
  import(
    './layouts/seguranca/DashboardComportamental' /* webpackChunkName: 'relatorios.skr.seguranca.dashboard-comportamental' */
  ),
);
const GeralDeSeguranca = lazy(() =>
  import(
    './layouts/seguranca/relatorioGeralDeSeguranca' /* webpackChunkName: 'relatorios.skr.seguranca.relatorio-geral-de-seguranca' */
  ),
);
const Gerencial = lazy(() =>
  import(
    './layouts/gerencial' /* webpackChunkName: 'relatorios.skr.gerencial' */
  ),
);
const DashboardSemanal = lazy(() =>
  import(
    './layouts/fisico/dashboardSemanal' /* webpackChunkName: 'relatorios.skr.dashboard-semanal' */
  ),
);
const InspecaoSeguranca = lazy(() =>
  import(
    './layouts/seguranca/inspecaoSeguranca' /* webpackChunkName: 'relatorios.skr.seguranca.inspecao-seguranca' */
  ),
);
const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.skr.fisico.gerencial' */
  ),
);
const FisicoPortfolio = lazy(() =>
  import(
    './layouts/fisico/portfolio' /* webpackChunkName: 'relatorios.skr.fisico.portfolio' */
  ),
);
const FisicoLinhaDeBalanco = lazy(() =>
  import(
    './layouts/fisico/linhaDeBalanco' /* webpackChunkName: 'relatorios.skr.fisico.linha-de-balanco' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/skr/seguranca/dashboard-acidentes"
    component={DashboardAcidentes}
    path="/relatorios/skr/seguranca/dashboard-acidentes"
  />,
  <PrivateRoute
    key="/relatorios/skr/seguranca/dashboard-comportamental"
    component={DashboardComportamental}
    path="/relatorios/skr/seguranca/dashboard-comportamental"
  />,
  <PrivateRoute
    key="/relatorios/skr/seguranca/inspecao-seguranca"
    component={InspecaoSeguranca}
    path="/relatorios/skr/seguranca/inspecao-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/skr/seguranca/relatorio-geral-de-seguranca"
    component={GeralDeSeguranca}
    path="/relatorios/skr/seguranca/relatorio-geral-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/skr/gerencial"
    component={Gerencial}
    path="/relatorios/skr/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/skr/fisico/semanal"
    component={DashboardSemanal}
    path="/relatorios/skr/fisico/semanal"
  />,
  <PrivateRoute
    key="/relatorios/skr/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/skr/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/skr/fisico/portfolio"
    component={FisicoPortfolio}
    path="/relatorios/skr/fisico/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/skr/fisico/linha-de-balanco"
    component={FisicoLinhaDeBalanco}
    path="/relatorios/skr/fisico/linha-de-balanco"
  />,
];
