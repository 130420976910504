import { CatalogList, CatalogType } from '../store/types';
import api from './api';
import apiAdonis from './apiAdonis';

export function listByType(
  type: number,
  companyId: number,
): Promise<CatalogList[]> {
  return api.get(`/catalogo-listas/list-by-type/${type}/${companyId}.json`);
}

export function create(data: CatalogList): Promise<CatalogType> {
  return api.post(`/catalogo-listas/add.json`, data);
}

export function edit(datasetId: number, params: CatalogList[]): Promise<void> {
  return api.post(`/catalogo-listas/edit/${datasetId}.json`, params);
}

export function update(params: CatalogList): Promise<void> {
  return api.post(`/catalogo-listas/update.json`, params);
}

export function editSequence(data: { id: number; seq: number }[]) {
  return api.post(`/catalogo-listas/edit-sequence.json`, data);
}

export function remove(data: { id: number }) {
  return api.post(`/catalogo-listas/remove.json`, data);
}

export function updateOrCreateMany(
  payload: Partial<CatalogList>[],
): Promise<CatalogList[]> {
  return apiAdonis.post(`/catalogo-listas/update-or-create-many`, {
    payload,
  });
}

interface UpdateOrCreateCatalogList {
  id: number;
  idEmpresa: number;
  descricao: string;
  idTipo: number;
  idModulo: number;
  idCodQc?: number | null;
  uuid?: string;
}

export function updateOrCreate(
  payload: UpdateOrCreateCatalogList,
): Promise<UpdateOrCreateCatalogList> {
  return apiAdonis.post(`/catalogo-listas/update-or-create`, payload);
}
