import { IoIosCheckmarkCircle } from 'react-icons/io';
import { MdLibraryAdd, MdMoreVert } from 'react-icons/md';
import styled from 'styled-components';
import { Card as CardComponent } from '../../styles/card';
import Button from '../../components/Button';

export const ModalContainer = styled.div`
  border-radius: ${({ theme }) => theme.radius.medium}px;
  padding: 2rem;
  width: 45rem;
  height: 60rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

export const Container = styled.div`
  padding: 2rem;
  height: 92vh;
  width: 100vw;
`;

export const Card = styled(CardComponent)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const SectorsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
`;

export const TotalSelected = styled.div`
  display: flex;

  span {
    font-size: 1.4rem;
  }
`;

export const SelectAll = styled.span`
  cursor: pointer;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WrappSelectAll = styled.div`
  margin-left: auto;
  cursor: pointer;
  position: relative;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
`;

export const SelectedIcon = styled(IoIosCheckmarkCircle)<{
  disabled?: boolean;
  iconCollor?: string | null;
}>`
  color: ${({ theme, iconCollor }) =>
    !iconCollor ? theme.colors.primary : iconCollor};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed;' : 'pointer;')};
  font-size: 1.8rem;
`;

export const IconPopupMenu = styled(MdMoreVert)`
  color: #333;
  cursor: pointer;
  font-size: 1.8rem;
`;

export const AddIcon = styled(MdLibraryAdd)`
  color: #333;
  cursor: pointer;
  font-size: 2.2rem;
`;

export const ButtonAddSector = styled.button`
  border: none;
  outline: none;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* border: 1px solid #333; */
  /* padding: .2rem 1rem; */
  height: 4.3rem;

  span {
    font-weight: 500;
    margin-left: 0.5rem;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 20rem 1fr;
  grid-template-rows: 3rem 65vh;
  border: 1px solid #d3e3e2;

  & > div {
    display: flex;
    align-items: center;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
  }
`;

export const Cell = styled.div<{ size?: number; styles?: string }>`
  flex: 0 0 ${({ size }) => size || 14}rem;
  border-right: 1px solid #eee;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ styles }) => styles};
  height: 3rem;
  overflow: hidden;

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FakeRightColumn = styled.div`
  margin-right: 7px;
`;

export const FakeLeftRow = styled.div`
  padding-top: 7px;
`;

export const LeftCell = styled.div<{ size: number; isEnabled?: boolean }>`
  display: flex;
  width: ${({ size }) => size}rem;
  padding: 0 1.5rem;
  border-bottom: 1px solid #d3e3e2;
  flex: 0 0 3rem;
  align-items: center;
  justify-content: space-between;

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  background-color: ${({ isEnabled }) =>
    isEnabled ? '#E0F2F1' : 'rgba(224, 242, 241, .3)'};
  color: ${({ isEnabled }) =>
    isEnabled ? '#333' : 'rgba(0, 0, 0, .3)'} !important;
`;

export const ContentScrollable = styled.div`
  flex: 1;
  height: 100%;
  overflow: scroll;
`;

export const Row = styled.div<{ width: number }>`
  display: flex;
  border-bottom: 1px solid #d3e3e2;
  width: ${({ width }) => width}rem;
  height: 3rem;
`;

export const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const GridHeader = styled.div`
  color: #333;
  position: relative;
  overflow: hidden;

  span {
    font-size: 1.2rem;
  }
`;

export const TitleWrapper = styled.div`
  font-size: 1.6rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckButton = styled.button`
  margin: 0 auto;
  width: 90%;
  height: 90%;
`;

export const Bullet = styled.div`
  background-color: #ccc;
  border-radius: 50%;
  width: 0.7rem;
  height: 0.7rem;
  margin: 0 1rem 3px 1rem;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3rem;
  grid-template-rows: 1fr 3rem;
  border-radius: 2px;
`;

export const RightAddIconWrapper = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BottomAddIconWrapper = styled.div`
  margin-top: 3px;
  width: 20rem;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 35%;
  left: 50%;
  margin-left: -1.6rem;
`;

export const ButtonSave = styled(Button)`
  width: 15rem;
  display: flex;
  justify-content: center;
`;

export const IconDiv = styled.div`
  display: flex;
`;

export const IconSpace = styled.div`
  width: 20px;
`;
