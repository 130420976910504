import { createContext, useContext } from 'react';
import { OptionsType } from 'store/types';

type UserCreatableSelectContextType = {
  handleUpdate(options: OptionsType[], idOrUuidUser: number | string): void;
  options: OptionsType[];
};

export const UserCreatableSelectContext = createContext<
  UserCreatableSelectContextType | undefined
>(undefined);

export function useUserCreatableSelectContext() {
  return useContext(UserCreatableSelectContext);
}
