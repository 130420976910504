import styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';
import { FiSearch } from 'react-icons/fi';

export const Container = styled.div`
  transition: all ease 2s, opacity ease 2s;

  @media only screen and (max-width: 480px) {
    position: absolute;
    left: 0;
    top: 38px;
    width: 100%;
    background-color: #009688;
    padding-left: 48px;
    height: 3.4rem;
    line-height: 3.4rem;
    border: 1px solid #006f65;
  }
`;

export const IconWrapper = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  cursor: pointer;
  width: 28rem;
  margin-right: 1.2rem;
  position: relative;

  @media only screen and (max-width: 960px) {
    width: 18rem;
  }

  @media only screen and (max-width: 480px) {
    width: unset;
  }

  span {
    color: #fff;
    font-size: 1.4rem;
    padding-right: 2rem;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;

    @media only screen and (max-width: 480px) {
      font-size: 1.3rem;
    }
  }

  input {
    background-color: ${({ theme }) => theme.colors.primary};
    border: none;
    color: #fff;
    border-bottom: 1px solid #fff;
    flex: 1;
    padding-left: 1rem;
    padding-bottom: 0.3rem;
    font-size: 1.4rem;

    @media only screen and (max-width: 960px) {
      padding-left: 0.3rem;
      padding-bottom: 0.1rem;
    }

    &::placeholder {
      color: #fff;
      opacity: 0.7;
    }

    &:focus {
      outline: 0 none;
      overflow: hidden;
    }
  }
`;

export const ArrowIcon = styled(IoIosArrowDown)`
  color: #fff;
  font-size: 1.8rem;
  margin-right: 0.5rem;
  margin-bottom: 2px;
`;

export const SearchIcon = styled(FiSearch)`
  color: #fff;
  font-size: 1.8rem;
  margin-right: 0.5rem;
  margin-bottom: 2px;

  @media only screen and (max-width: 960px) {
    font-size: 1.6rem;
  }
`;

export const ContractsWrapper = styled.ul<{ isMobile: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 2.5rem;
  background-color: #fff;
  border-radius: ${({ theme }) => theme.radius.medium}px;
  max-height: ${({ isMobile }) => (isMobile ? '21rem' : '30rem')};
  overflow-y: scroll;
  box-shadow: 0 3px 20px rgba(89, 105, 129, 0.3), 0 1px 2px rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(89, 105, 129, 0.1);
  padding: 0.5rem 0;

  li {
    button {
      background-color: #fff;
      padding: 1rem;
      width: 100%;
      height: 100%;

      &:hover {
        background-color: #f5f5f5;
      }

      span {
        display: block;
        color: #333;
        font-size: 1.2rem;
        text-transform: uppercase;
        text-align: left;
      }
    }
  }
`;

export const SpinnerWrapper = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: center;
`;

export const ContractName = styled.span`
  color: #fff;
  font-size: 1.6rem;
  padding-right: 2rem;
`;
