import SupplierCreatableSelect from 'components/Widgets/SupplierCreatableSelect';
import React from 'react';
import { OptionsType } from 'store/types';
import { Occurrence } from '../../types';
import { Container } from './styles';

interface Props {
  setFormData(formData: Occurrence): void;
  formData: Occurrence;
  isNewSupplierTable: boolean;
}

export default function SelectProvider(props: Props) {
  const { isNewSupplierTable, formData, setFormData } = props;

  function handleSelectProvider(option: OptionsType) {
    const { label, value } = option;

    setFormData({
      ...formData,
      fornecedor: label as string,
      idFornecedor: value as number,
      uuidFornecedor: option?.uuidFornecedor,
    });
  }

  function getSelectedValue() {
    const { fornecedor, idFornecedor } = formData;

    return {
      label: fornecedor,
      value: idFornecedor,
    };
  }

  return (
    <Container>
      <SupplierCreatableSelect
        isClearable
        datasetId={isNewSupplierTable ? 537 : 838}
        supplier={getSelectedValue()}
        setSupplier={handleSelectProvider}
      />
    </Container>
  );
}
