import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSqlResponse } from '../../../services/dataset';
import { showModal } from '../../../store/modal/actions';
import { ModalName } from '../../../store/modal/types';
import { RootState } from '../../../store/rootReducer';
import { OptionsType } from '../../../store/types';
import { Label } from '../../../styles/form';
import { CreatableSelect } from '../../ReactSelect';
import CustomRow from './components/CustomRow';
import { UserCreatableSelectContext } from './context';
import {
  FormUser,
  ModalParams,
} from 'pages/UsersManagement/components/CreateUserModal/types';

export interface Props {
  label?: string;
  placeholder?: string;
  companyId?: number;
  datasetId: number;
  idContrato?: number;
  user?: OptionsType | OptionsType[];
  setUser(option: OptionsType | OptionsType[]): void;
  isDisabled?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  isAutoClearAfterChange?: boolean;
  isFullUserModal?: boolean;
  primaryKey?: 'idUsuario' | 'uuid';
  dataOptions: OptionsType[];
}

export default function UserCreatableSelect(props: Props) {
  const [options, setOptions] = useState<OptionsType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<
    OptionsType | OptionsType[] | null
  >(null);
  const userLogged = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const {
    datasetId,
    user,
    isDisabled,
    isClearable,
    isMulti,
    setUser,
    companyId,
    isFullUserModal,
    primaryKey,
    idContrato,
    dataOptions,
  } = props;

  useEffect(() => {
    setSelectedOption(user || null);
  }, [user]);

  useEffect(() => {
    getData();
  }, [datasetId, userLogged, idContrato, dataOptions?.length]);

  async function getData() {
    if (dataOptions?.length) {
      setOptions(dataOptions);

      return;
    }

    if (!datasetId || !userLogged || isLoading) {
      return;
    }

    setIsLoading(true);

    const fetchedOptions = await getSqlResponse<OptionsType[]>(datasetId, {
      idEmpresa: companyId || userLogged.idEmpresa,
      idContrato: idContrato,
    });

    setOptions(fetchedOptions);
    setIsLoading(false);
  }

  function handleSetNewUser(newUser: FormUser) {
    const optionsWithouCurrentUser = options.filter(
      opt => opt.value !== newUser[primaryKey || 'idUsuario'],
    );

    const newOption: OptionsType = {
      value: newUser[primaryKey || 'idUsuario'],
      label: `${newUser.nome} ${newUser.sobrenome} (${newUser.email})`,
    };

    const currentSelectedOption = (selectedOption as OptionsType[]) || [];

    const normalizedOption = isMulti
      ? [...currentSelectedOption, newOption]
      : newOption;

    setUser(normalizedOption);
    setOptions([...optionsWithouCurrentUser, newOption]);
  }

  function handleCreateNew(inputValue: string) {
    dispatch(
      showModal<ModalParams>(
        isFullUserModal ? ModalName.CREATE_USER : ModalName.CREATE_USER_SIMPLE,
        {
          nome: inputValue,
          idEmpresa: companyId! || userLogged.idEmpresa!,
          idContrato: idContrato,
          afterSubmit: handleSetNewUser,
        },
      ),
    );
  }

  function handleChange(option: OptionsType) {
    const { isAutoClearAfterChange } = props;

    if (!isAutoClearAfterChange) {
      setSelectedOption(option);
    }

    setUser(option);
  }

  function handleUpdate(options: OptionsType[], idOrUuidUser: number | string) {
    const afterSubmit = (newUser: FormUser) => {
      const optionsWithouCurrentUser = options.filter(
        opt => opt.value !== newUser[primaryKey || 'idUsuario'],
      );

      const newOption: OptionsType = {
        value: newUser[primaryKey || 'idUsuario'],
        label: `${newUser.nome} ${newUser.sobrenome} (${newUser.email})`,
      };

      setUser(newOption);
      setOptions([...optionsWithouCurrentUser, newOption]);
    };

    dispatch(
      showModal<ModalParams>(
        isFullUserModal ? ModalName.CREATE_USER : ModalName.CREATE_USER_SIMPLE,
        {
          idOrUuidUser,
          idEmpresa: companyId! || userLogged.idEmpresa!,
          afterSubmit: afterSubmit,
        },
      ),
    );
  }

  const contextValues = useMemo(() => ({ handleUpdate, options }), [options]);

  const { label, placeholder } = props;

  return (
    <UserCreatableSelectContext.Provider value={contextValues}>
      <>
        {label ? <Label>{label}</Label> : null}

        <CreatableSelect
          isMulti={isMulti}
          onCreateOption={handleCreateNew}
          onChange={handleChange}
          value={selectedOption}
          options={options}
          isLoading={isLoading}
          placeholder={placeholder || ''}
          components={{ Option: CustomRow }}
          isDisabled={isDisabled}
          isClearable={isClearable}
          minHeight={'auto'}
        />
      </>
    </UserCreatableSelectContext.Provider>
  );
}
