import { normalizeFileUrl } from 'utils/file';
import { GalleryPhoto, ImageViewer } from '../types';
import { EnumActions } from 'store/types';
import { hasPermission } from 'components/WithPermission';
import { EnumProfile } from 'store/types';

export function normalizeImagesForImageViewer(
  images: GalleryPhoto[],
  userActions: string | null,
  currentSignedUser: number,
  currentUserProfile: EnumProfile,
): ImageViewer[] {
  return images
    .filter(image => image.referenciaFoto)
    .map(image => {
      const isMainImage = image.nivel === 'pai';
      const isAllowEdit = checkUserAllowedEdit(
        isMainImage,
        userActions,
        image.idUsuario,
        currentSignedUser,
        currentUserProfile,
      );

      return {
        key: image.key.toString(),
        src: normalizeFileUrl(image.referenciaFoto!),
        downloadUrl: normalizeFileUrl(image.referenciaFoto!),
        comentario: image.comentario,
        idContrato: image.idContrato,
        uuidComentario: image.uuidComentario,
        uuidFoto: image.uuidFoto,
        usuario: image.usuario,
        dtCriacao: image.dtCriacao,
        isMainImage,
        tipo: image.tipo,
        uuidReferencia:
          image.nivel === 'pai' ? image.uuidOcorrencia : image.uuidRaiz,
        isAllowEdit,
      };
    });
}

export function checkUserAllowedEdit(
  isMainImage: boolean,
  userActions: string | null,
  currentImageUserId: number,
  currentSignedUser: number,
  currentUserProfile: EnumProfile,
) {
  if (isMainImage) {
    return hasPermission(userActions, EnumActions.EDITAR, currentUserProfile, [
      EnumProfile.AUDITOR,
    ]);
  }

  return currentSignedUser === currentImageUserId;
}
