import React, { ReactElement } from 'react';
import { Column } from 'react-data-grid';
import { FiCamera } from 'react-icons/fi';
import { GrDocumentText } from 'react-icons/gr';
import { MdAttachFile } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import store from '../../../../../store';
import { showModal } from '../../../../../store/modal/actions';
import { ModalName } from '../../../../../store/modal/types';
import { TableTreeviewRow } from '../../../../../store/types';
import { getMatchIndex } from '../../../../../utils/conditionalFormatting';
import * as helperFn from '../../../../../utils/helperFunctions';
import {
  DashIcon,
  RoundedAlertIcon,
  GreenCircleAlertIcon,
  RoundedCheckIcon,
  RoundedXIcon,
} from '../../Icons';
import { PhotosModalParams } from '../../PhotosModal';
import Default from '../Default';
import { Cell } from './styles';

interface Props {
  value: number;
  formatterParams: any;
}

interface CustomColumns extends Column<any> {
  formatterParams: any;
}

interface Props {
  value: any;
  column: CustomColumns;
  row: TableTreeviewRow;
}

const icons = {
  roundedCheckIcon: RoundedCheckIcon,
  roundedXIcon: RoundedXIcon,
  roundedAlertIcon: RoundedAlertIcon,
  greenCircleAlertIcon: GreenCircleAlertIcon,
  dashIcon: DashIcon,
};

const iconsColors = {
  roundedCheckIcon: '#009688',
  roundedXIcon: '#ff3860',
  roundedAlertIcon: '#fff716',
  greenCircleAlertIcon: '#009688',
};

const docIcons = {
  grDocumentText: GrDocumentText,
  fiCamera: FiCamera,
  mdAttachFile: MdAttachFile,
};

export default function Checklist(props: Props): ReactElement {
  const {
    value,
    row,
    row: { photoParameters },
    column,
  } = props;

  const dispatch = useDispatch();

  if (!value && value !== 0) {
    return <></>;
  }

  const treeDepth = row?.treeDepth;
  const startingDepth = column?.formatterParams?.startingDepth;

  if (value === 'CIENTE' || value === 'PENDENTE') {
    return (
      <Cell
        backgroundColor={value === 'CIENTE' ? '#009688' : '#eb8634'}
        onClick={handleShowModal}
      >
        <span>{value}</span>
      </Cell>
    );
  }

  if (treeDepth < startingDepth) {
    return <Default value={value} />;
  }

  function handleShowModal() {
    const { key } = column;

    const splitedKey = key.split('-');
    const checklistKey = splitedKey[splitedKey.length - 1];

    dispatch(
      showModal(ModalName.CHECKLIST_APPROVAL, {
        idChecklist: checklistKey,
        key,
      }),
    );
  }

  function handleClick() {
    const {
      key,
      formatterParams: {
        renderPhoto: { idDataset },
      },
    } = column;

    const idContrato = store.getState().contract.contract?.idContrato;

    const modalParams: PhotosModalParams = {
      idContrato,
      idDatasetImages: idDataset,
      ...photoParameters[key],
    };

    dispatch(showModal(ModalName.DYNAMIC_TABLE_PHOTOS, modalParams));
  }

  function getBackgroundColor() {
    if (!column.formatterParams) return;

    const { bgFormatting } = column.formatterParams;

    if (bgFormatting) {
      const { values, colors } = bgFormatting;
      const colorIndex = getMatchIndex(value, values, 'values');
      if (colorIndex !== false) return colors[colorIndex];
    }

    return 'transparent';
  }

  const backgroundColor = getBackgroundColor();

  function renderIcon(): ReactElement | null {
    if (!column.formatterParams) return;

    const { iconFormatting } = column.formatterParams;

    if (iconFormatting) {
      const { ranges, icons: iconsArr } = iconFormatting;
      const matchIndex = getMatchIndex(value, ranges, 'ranges');
      const iconStr = iconsArr[matchIndex];
      if (matchIndex !== false) {
        return React.createElement(icons[iconStr], {
          containerColor: iconsColors[iconStr],
        });
      }
    }

    return null;
  }

  function renderModalIcon(): ReactElement | null {
    const {
      key,
      formatterParams: { renderPhoto },
    } = column;
    const flagFoto = photoParameters?.[key]?.flag_foto;
    if (!flagFoto) return null;
    const docIcon = docIcons[renderPhoto?.icon] || docIcons.fiCamera;
    const style: any = {
      fontSize: '1.1rem',
      cursor: 'pointer',
    };
    if (helperFn.getLumaValue(backgroundColor) <= 100) style.color = 'white';

    const modalIcon = React.createElement(
      docIcon,
      {
        style,
        onClick: () => handleClick(),
      },
      null,
    );

    return <div className="modal-icon-container">{modalIcon}</div>;
  }

  return (
    <Cell
      className="formatter-type-checklist"
      backgroundColor={backgroundColor}
    >
      {renderIcon()}
      {renderModalIcon()}
    </Cell>
  );
}
