import styled, { css } from 'styled-components';
import { Card } from 'styles/card';

const CommonStyles = css`
  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.4rem;
  }
`;

export const WebContainer = styled(Card)`
  width: 70rem;
  height: auto;
  margin: 10rem auto 0 auto;

  ${CommonStyles}
`;

export const MobileContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 10rem 2rem;

  ${CommonStyles}
`;

// export const Container = styled(Card)<{ isMobile: boolean }>`
//   margin: 10rem auto;
//   width: 70rem;

//   /* @media (max-width: 960px) {
//     margin: 0;
//     width: 100vw;
//     height: 100vh;
//     height: calc(100vh - 5rem);
//   } */

//   h1 {
//     text-align: center;
//     margin-bottom: 2rem;
//   }

//   p {
//     font-size: 1.4rem;
//   }
// `;
