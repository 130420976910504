import styled from 'styled-components';
import { ModalTitle } from 'styles/modal';

export const Title = styled(ModalTitle)`
  padding: 1rem 2rem 1rem 0;
`;
export const ScrollArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 2px;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Footer = styled.div`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;
