export function getGoogleMapsKey() {
  // return process.env.MAP_KEY || ''; //webpack-comments

  return import.meta.env.VITE_MAP_KEY || ''; // vite-comments
}

export interface GeolocationType {
  latitude?: number;
  longitude?: number;
  errorMessage?: string;
}

export enum EnumErrorGeolocation {
  DENIED = 1,
}

export function getCurrentLocation(): Promise<GeolocationType> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      error => {
        if (error.code === EnumErrorGeolocation.DENIED) {
          reject({
            errorMessage:
              'Você negou o acesso à localização. Ative a permissão nas configurações do navegador.',
          });
        } else {
          reject({
            errorMessage:
              'Você negou o acesso à localização. Ative a permissão nas configurações do navegador.',
          });
        }
      },
    );
  });
}
