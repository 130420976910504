import styled from 'styled-components';
import { Card } from '../../../../styles/card';
import InputFileComponent from '../../../../components/InputFile';

export const Container = styled(Card)`
  flex: 1rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 1rem;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;

  button {
    flex: 0 0 25%;
  }
`;

export const InputFile = styled(InputFileComponent)`
  width: 10rem;
`;

export const GroupWrapper = styled.div`
  display: flex;

  & > div {
    flex: 1;
  }

  & > button {
    margin-left: 1rem;
  }
`;
