import React, { useEffect } from 'react';
import { download } from 'services/file';
import { LogoImg, Container, Wrapper, DonwloadIcon } from './styles';
import HomeLogo from '../../assets/sigin-logo.png';
import useQuery from 'hooks/use-query';
import Button from 'components/Button';
import { Text } from 'styles/text';
import { EnumFileType } from 'store/types';

interface PropsUrl {
  file: string;
  name: string;
}

export default function Download() {
  const params = useQuery<PropsUrl>();

  useEffect(() => {
    handleDownloadToFile();
  }, [params]);

  function handleDownloadToFile() {
    if (!params.file) return;
    const { file, name } = params;

    download(
      {
        referencia: file,
        nome: name,
        tipoArquivo: file.split('.').pop() as EnumFileType,
      },
      true,
    );
  }

  return (
    <Container>
      <Wrapper>
        <LogoImg src={HomeLogo} />

        <h2> {params?.name} </h2>

        <Button
          primary
          small
          text="Baixar arquivo"
          onClick={handleDownloadToFile}
          icon={<DonwloadIcon />}
        />

        <Text>
          Caso o download não tenha sido efetuado automaticamente favor clicar
          no botão "Baixar arquivo"
        </Text>
      </Wrapper>
    </Container>
  );
}
