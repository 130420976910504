import api from './api';
import { DatasetParams } from '../store/types';
import apiAdonis from './apiAdonis';
import snakecaseKeys from 'snakecase-keys';

export function getSqlResponse<T>(
  id: number,
  params?: DatasetParams,
  disableConvertToCamelcase = false,
): Promise<T> {
  return api.post(`/dataset/get-sql-response/${id}.json`, params, {
    params: { apiConfig: { disableConvertToCamelcase } },
  });
}

export function getById(id: number): Promise<[]> {
  return api.get(`/relatorios/get-by-id/${id}.json`);
}

export function getSingleResponse<T>(
  id: number,
  params: DatasetParams,
  apiConfig?: { deep?: boolean; disableConvertToCamelcase?: boolean },
): Promise<T> {
  return api
    .post(`/dataset/get-sql-response/${id}.json`, params, {
      params: { apiConfig },
    })
    .then(res => res[0]);
}

// ADONIS

export function getSqlResponseAdonis<T>(
  id: number,
  params?: DatasetParams,
  disableConvertToCamelcase = false,
): Promise<T> {
  params = params ? snakecaseKeys(params) : {};

  return apiAdonis.post(
    `/dataset/get-sql-response/${id}`,
    { idDataset: id, params },
    {
      params: { apiConfig: { disableConvertToCamelcase } },
    },
  );
}

export function getSingleResponseAdonis<T>(
  id: number,
  params?: DatasetParams,
  disableConvertToCamelcase = false,
): Promise<T> {
  params = params ? snakecaseKeys(params) : {};

  return apiAdonis
    .post(
      `/dataset/get-sql-response/${id}`,
      { idDataset: id, params },
      {
        params: { apiConfig: { disableConvertToCamelcase } },
      },
    )
    .then(res => res[0]);
}
