import api from './api';
import { Category } from '../store/types';

export function get(companyId: number): Promise<Category[]> {
  return api.get(`/categoria-arquivo/list/${companyId}.json`);
}

interface SaveProps {
  categoria: string;
  idContrato: number;
  idReferencia: number;
}

export function save(category: SaveProps): Promise<number> {
  return api.post('/categoria-arquivo/add.json', category);
}
