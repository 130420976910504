import {
  SheetsManagerState,
  SheetsManagerActionTypes,
  SET_SERVICE_FAMILY_N2,
  SET_SERVICE_FAMILY_N1,
  CLEAR_SERVICES,
  SET_SERVICES,
} from './types';

const initialState: SheetsManagerState = {
  services: [],
  selectedServiceFamilyN1: null,
  selectedServiceFamilyN2: null,
};

export default (
  state = initialState,
  action: SheetsManagerActionTypes,
): SheetsManagerState => {
  switch (action.type) {
    case SET_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case CLEAR_SERVICES:
      return {
        ...state,
        services: [],
      };
    case SET_SERVICE_FAMILY_N1:
      return {
        ...state,
        selectedServiceFamilyN1: action.family,
      };
    case SET_SERVICE_FAMILY_N2:
      return {
        ...state,
        selectedServiceFamilyN2: action.family,
      };

    default:
      return state;
  }
};
