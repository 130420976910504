import { isEditAllowed } from 'pages/UploadImage/utils';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { Text } from 'styles/text';
import { toDateTimeBR } from 'utils/date';
import showAlert from '../../../../components/Alert';
import Spinner from '../../../../components/Spinner';
import { edit } from '../../../../services/photo';
import { normalizeFileUrl } from '../../../../utils/file';
import { Photo } from '../../types';
import {
  ActionStyled,
  Actions,
  CalendarIcon,
  Card,
  Comment,
  DisableCheck,
  IconRemove,
  Image,
  ImageWrapper,
  InforHeader,
  InforWrapper,
  InputWrapper,
  Label,
  NotUploadIcon,
  OpenImageIcon,
  OpenImageWrapper,
  SectionComment,
  SpinnerWrapper,
  UploadIconWrapper,
  UploadedIcon,
  UserIcon,
} from './styles';

interface Props {
  indexImg: number;
  image: Photo;
  onRemove(file: Photo): void;
  onSetImage(file: Photo): void;
  onShowViewer(indexImg: number): void;
  allowDisableImage?: boolean;
}

export default function CardPhoto(props: Props): ReactElement {
  const { image, indexImg, onRemove, onShowViewer, onSetImage } = props;
  const [isChecked, setIsChecked] = useState(image.habilitado);
  const [isRemoving, setIsRemoving] = useState(false);

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const newImage = image;
    newImage.shouldSaveComment = true;
    newImage[e.target.name] = e.target.value;

    onSetImage(newImage);
  }

  function handleRemove() {
    const remove = async () => {
      setIsRemoving(true);
      await onRemove(image);
      setIsRemoving(false);
    };

    showAlert({
      title: 'Excluir',
      actions: [{ text: 'Cancelar' }, { text: 'Ok', onClick: remove }],
    });
  }

  function getImagePath(): string {
    return image.isNew ? image.src! : normalizeFileUrl(image.referencia);
  }

  async function handleDisableChange() {
    setIsChecked(!isChecked);
    image.habilitado = +!image.habilitado;
    await edit([image]);
  }

  function renderComement() {
    const { allowDisableImage } = props;
    if (allowDisableImage) {
      return null;
    }

    return (
      <>
        <Label htmlFor="comentario">Comentário</Label>
        <Comment
          id="comentario"
          name="comentario"
          value={image.comentario}
          placeholder="Adicione um comentário..."
          disabled={!isEditAllowed(image)}
          onChange={onChange}
        />
      </>
    );
  }

  function renderDisable() {
    const { allowDisableImage } = props;

    if (!allowDisableImage || !isEditAllowed(image)) {
      return null;
    }

    return (
      <InputWrapper>
        <DisableCheck isChecked={isChecked} onClick={handleDisableChange} />
        <div style={{ marginLeft: '25px', display: 'inline-block' }}>
          Habilitar
        </div>
      </InputWrapper>
    );
  }

  function renderDelete() {
    const { allowDisableImage } = props;
    if (allowDisableImage || !isEditAllowed(image)) {
      return null;
    }

    return (
      <Action
        text="Excluir"
        color="#ff3860"
        onClick={handleRemove}
        isLoading={isRemoving}
        icon={<IconRemove defaultColor="#ff3860" />}
      />
    );
  }

  return (
    <Card>
      <InforHeader>
        <InforWrapper>
          <CalendarIcon />
          <Text> {toDateTimeBR(image.dtCriacao)} </Text>
        </InforWrapper>

        <InforWrapper>
          <UserIcon />
          <Text> {image.usuario} </Text>
        </InforWrapper>
      </InforHeader>

      <ImageWrapper onClick={() => onShowViewer(indexImg)}>
        <OpenImageWrapper>
          <OpenImageIcon />
        </OpenImageWrapper>
        <Image alt="foto" src={getImagePath()} />

        <UploadIconWrapper>
          {image.isNew ? <NotUploadIcon /> : <UploadedIcon />}
        </UploadIconWrapper>
      </ImageWrapper>
      <SectionComment>
        {renderComement()}

        <Actions>
          {renderDisable()}
          {renderDelete()}
        </Actions>
      </SectionComment>
    </Card>
  );
}

interface ActionProps {
  isLoading: boolean;
  text: string;
  color: string;
  onClick(): void;
  icon: ReactElement;
}

function Action(props: ActionProps): ReactElement {
  const { isLoading, onClick, text, color, icon } = props;

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner color={color} />
      </SpinnerWrapper>
    );
  }

  return (
    <ActionStyled defaultColor={color} onClick={onClick}>
      {icon}
      <span>{text}</span>
    </ActionStyled>
  );
}
