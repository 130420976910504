import showAlert from 'components/Alert';
import { Device } from 'hooks/use-device-detect';
import {
  check,
  getAdonisToken,
  saveAdonisToken,
  tempToken,
} from 'services/auth';
import { createAdonis, ReportQueue } from 'services/report_queue';
import { get, sendEmail } from 'services/signature';
import { OptionsType } from 'store/types';
import { generateUUID, isObjectEmpty } from 'utils/common';
import { getSpacesFolder, normalizeFileUrl } from 'utils/file';
import { GeolocationType, getCurrentLocation } from 'utils/maps';
import {
  EnumSource,
  EnumStatus,
  EnumStep,
  RawURLParams,
  URLParams,
} from './types';

export function isExistToken(token: string | undefined) {
  return !token && !getAdonisToken();
}

export function normalizeUrlParams(urlParams: RawURLParams): URLParams {
  if (isObjectEmpty(urlParams) || !urlParams[0]) {
    throw new Error('Source not found');
  }

  const parts = urlParams[0].split('/');

  if (parts[0] === 'app') {
    return {
      source: EnumSource.APP,
      tipo: parts[1],
      idEmpresa: Number(parts[2]),
      idContrato: Number(parts[3]),
      idReferencia: Number(parts[4]),
      uuidArquivo: parts[5],
      uuidReferencia: parts[6],
      idUsuarioCriacao: Number(parts[7]),
      token: parts[8],
    };
  }

  if (parts[0] === 'email') {
    return {
      source: EnumSource.EMAIL,
      uuid: parts[1],
      token: parts[2],
    };
  }

  if (parts[0] === 'viewer') {
    return {
      source: EnumSource.VIEWER,
      uuid: parts[1],
    };
  }

  throw new Error('Source not found');
}

export async function checkTokenValidity() {
  try {
    await check();

    return true;
  } catch (error) {
    console.error(error);

    return false;
  }
}

export function getModalStyle() {
  return {
    overlay: {
      position: 'fixed',
      inset: 0,
      zIndex: 999,
    },
    content: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      inset: 0,
      padding: 0,
    },
  };
}

export async function resendEmailWhenExpiredToken(subscriberUuid: string) {
  const token = await tempToken();
  saveAdonisToken(token);

  await sendEmail([subscriberUuid]);
}

export function getStepsConfig(source: EnumSource): OptionsType[] {
  if (source === EnumSource.APP) {
    return [
      { value: 1, label: 'Dados' },
      { value: 2, label: 'Documento' },
      { value: 3, label: 'Assinatura' },
      { value: 4, label: 'Foto' },
    ];
  }

  return [
    { value: 1, label: 'Dados' },
    { value: 2, label: 'Documento' },
    { value: 3, label: 'Assinatura' },
  ];
}

export function isSourceApp(urlParams: URLParams | undefined) {
  return urlParams?.source === EnumSource.APP;
}

export function getProgressContianerMarginSize(
  isAppSource: boolean,
  deviceSize: Device,
) {
  if (isAppSource) {
    return '1rem';
  }

  if (deviceSize.isMobile) {
    return '1rem';
  }

  return '5rem';
}

export function generateSignPDF(urlParams: URLParams) {
  const dataReport: ReportQueue = {
    idDataset: 0,
    nome: '',
    uuid: generateUUID(),
    tipoRelatorio: 'assinatura',
    idEmpresa: urlParams.idEmpresa,
    idContrato: urlParams.idContrato,
    parametros: {
      uuidArquivo: urlParams.uuidArquivo,
      flagAssinatura: true,
    },
  };

  return createAdonis(dataReport);
}

export function handleConfirmSignCancel(confirm: () => void) {
  showAlert({
    title: 'Atenção!',
    subtitle: `Deseja realmente cancelar a assinatura?`,
    actions: [
      {
        text: 'Não',
      },
      {
        text: 'Sim',
        onClick: confirm,
      },
    ],
  });
}

export function isShowProgressHeader(currentStep: number) {
  return ![
    EnumStep.SUBSCRIBER_LIST,
    EnumStep.REGISTER_SUBSCRIBERS_CANCEL,
    EnumStep.SIGNED,
  ].some(value => value === currentStep);
}

export async function getGeolocation(): Promise<GeolocationType> {
  const permission = await navigator.permissions.query({
    name: 'geolocation',
  });

  if (permission.state === 'denied') {
    return {
      errorMessage:
        'Você negou o acesso à localização. Ative a permissão nas configurações do navegador.',
    };
  }

  return getCurrentLocation();
}

export async function checkFileViewer(
  subscriberUuid: string,
  setCurrentStep: (step: EnumStep) => void,
) {
  const token = await tempToken();
  saveAdonisToken(token);

  const signature = await get(subscriberUuid);

  if (signature.status === EnumStatus.CANCELED) {
    return setCurrentStep(EnumStep.SIGNED_CANCELED);
  }

  window.location.href = normalizeFileUrl(
    `${getSpacesFolder()}/datafiles/reports/signed/${signature.uuidArquivo!}.pdf`,
  );

  setCurrentStep(EnumStep.SIGNED);
}
