import {
  ReportState,
  ReportActionTypes,
  SET_LAYOUT_ID,
  SET_GET_DATASET_DATA,
  SET_PRINTER_SETTINGS,
  SET_REPORT_QUEUE,
  REMOVE_REPORT_QUEUE,
  SET_REPORT_QUEUE_LIST,
} from './types';

const initialState: ReportState = {
  layoutId: null,
  companyId: null,
  impersonateId: null,
  getDatasetData: [],
  printerSettings: {},
  reportQueue: [],
};

export default (
  state = initialState,
  action: ReportActionTypes,
): ReportState => {
  switch (action.type) {
    case SET_LAYOUT_ID:
      return {
        ...state,
        layoutId: action.payload,
      };
    case SET_GET_DATASET_DATA:
      return {
        ...state,
        getDatasetData: action.payload,
      };
    case SET_PRINTER_SETTINGS:
      return {
        ...state,
        printerSettings: action.payload,
      };
    case SET_REPORT_QUEUE_LIST:
      return {
        ...state,
        reportQueue: action.payload,
      };
    case SET_REPORT_QUEUE:
      return {
        ...state,
        reportQueue: [action.payload, ...state.reportQueue],
      };
    case REMOVE_REPORT_QUEUE:
      return {
        ...state,
        reportQueue: state.reportQueue.filter(
          item => item.uuid === action.uuid,
        ),
      };
    default:
      return state;
  }
};
