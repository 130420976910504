// refKey - will be used for consolidating
// transposeKey - value will be turned into key

import { Row, SimpleTableConfig } from '../components/tables/SimpleTable/types';
import { DataSource } from '../layouts/seguranca/Alojamento/components/DynamicSimpleTable/types';

// dataKey - value will be attributed to transposeKey
interface TransposeConfig {
  refKey: number | string;
  transposeKey: number | string;
  dataKey: number | string;
}

export function transposeData(data, config: TransposeConfig) {
  const { refKey, transposeKey, dataKey } = config;
  const refArray = data
    .map(row => row[refKey])
    .filter((item, index, array) => array.indexOf(item) === index);
  // const tKeyArray = data
  //   .map(row => row[transposeKey])
  //   .filter((item, index, array) => array.indexOf(item) === index);
  const transposedData = refArray.map(key => {
    const keyArr = data.filter(row => row[refKey] === key);
    const objAux = { [refKey]: key };
    keyArr.forEach(item => {
      objAux[item[transposeKey]] = item[dataKey];
    });

    return objAux;
  });

  return transposedData;
}

export function aggregateData(data, aggKeys, dataKeys, aggType = 'sum') {
  const uniqueRows = getUniqueRows(data, aggKeys);

  const aggDataAux = getAggregatedData(
    data,
    uniqueRows,
    aggKeys,
    dataKeys,
    aggType,
  );

  return aggDataAux;
}

function getUniqueRows(data, aggKeys) {
  const uniqueRowsArray = [];

  data.forEach(row => {
    let isNewSet = true;

    // seach for the aggKeys subset in row, through all uniqueRowsArray
    uniqueRowsArray.forEach(set => {
      let allEqual = true;
      // if all values from aggKeys match, then it's not a new set
      aggKeys.forEach(key => {
        if (row[key] !== set[key]) {
          allEqual = false;
        }
      });
      if (allEqual) {
        isNewSet = false;
      }
    });

    if (isNewSet) {
      uniqueRowsArray.push(row);
    }
  });

  return uniqueRowsArray;
}

function getAggregatedData(sourceData, aggDataset, aggKeys, dataKeys, aggType) {
  const aggData = [];

  const aggKeysMatch = (sourceObj, aggObj, aggKeys) => {
    let isMatch = true;
    aggKeys.forEach(key => {
      if (sourceObj[key] !== aggObj[key]) {
        isMatch = false;
      }
    });

    return isMatch;
  };

  aggDataset.forEach(set => {
    const sourceDataSubset = sourceData.filter(row =>
      aggKeysMatch(row, set, aggKeys),
    );

    const dataKeysObj = getDataKeysObj(sourceDataSubset, dataKeys, aggType);

    aggData.push({
      ...set,
      ...dataKeysObj,
    });
  });

  return aggData;
}

function getDataKeysObj(dataset, dataKeys, aggType) {
  const objAux = {};
  if (aggType === 'sum') {
    dataKeys.forEach(key => {
      objAux[key] = dataset
        .map(set => set[key])
        .reduce((total, item) => total + item);
    });
  }

  if (aggType === 'avg') {
    dataKeys.forEach(key => {
      const keySet = dataset.map(set => set[key]);
      const avg = keySet.reduce((total, item) => total + item) / keySet.length;
      objAux[key] = avg;
    });
  }

  return objAux;
}

export function makeSlicerData(data, key, label) {
  const uniqueKeys = data
    .map(row => row[key])
    .filter((item, index, array) => array.indexOf(item) === index);
  const slicerData = [];
  uniqueKeys.forEach(unKey => {
    const dataAux = data.filter(item => item[key] === unKey)[0];
    const objAux = {
      key: dataAux[key],
      // "value" is used in React Select
      value: dataAux[key],
      label: dataAux[label],
    };
    slicerData.push(objAux);
  });

  return slicerData;
}

export function compareByKey(a, b, key, reverse = false) {
  const reverseFactor = reverse ? -1 : 1;
  const obj1 = a[key];
  const obj2 = b[key];
  if (obj1 > obj2) return 1 * reverseFactor;
  if (obj2 > obj1) return -1 * reverseFactor;

  return 0;
}

export function sortByKey(data, key, reverse = false) {
  return data.sort((a, b) => compareByKey(a, b, key, reverse));
}

export interface FormatNumber {
  style?: string;
  prefix?: string | number;
  decimalPlaces?: number;
  suffix?: string | number;
  divisor?: number;
}

export function formatNumber(format: FormatNumber = {}) {
  const style = format.style || 'decimal';
  const prefix = format.prefix || '';
  const { decimalPlaces } = format;
  const suffix = format.suffix || '';
  const divisor = format.divisor || 1;

  return v =>
    typeof v === 'number'
      ? `${prefix}${(v / divisor).toLocaleString('pt-br', {
          style,
          maximumFractionDigits: decimalPlaces,
          minimumFractionDigits: decimalPlaces,
        })}${suffix}`
      : v;
}

export function sliceString(string, charCount) {
  return string.slice(0, charCount) + (string.length > charCount ? '...' : '');
}

export function getNomeMes(monthNumber) {
  const monthInt = parseInt(monthNumber);
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  return months[monthInt - 1];
}

export function mergeObjects(obj1, obj2) {
  // doNotMerge avoid merging and returns the whole obj2
  if (obj1 === undefined || obj2?.doNotMerge) return obj2;
  if (obj2 === undefined) return obj1;

  const mergeObjectKeys = (obj1, obj2) => {
    const obj1Aux = obj1 && typeof obj1 === 'object' ? obj1 : [];
    const obj2Aux = obj2 && typeof obj2 === 'object' ? obj2 : [];
    const arrAux = Object.keys(obj1Aux).concat(Object.keys(obj2Aux));

    return arrAux.filter((v, i, t) => t.indexOf(v) === i);
  };

  const arrAux = mergeObjectKeys(obj1, obj2);
  const oAux = {};
  for (let i = 0; i < arrAux.length; i += 1) {
    const key = arrAux[i];
    // if has null explicitly as value, removes the key from the final object
    if (obj2[key] === null) {
      continue;
    }

    // doesn't exist
    if (obj2[key] === undefined) {
      oAux[key] = obj1[key];
      continue;
    }

    // is "value". that is, string, number, bool or array
    if (
      obj2[key] !== undefined &&
      (typeof obj2[key] !== 'object' || Array.isArray(obj2[key]))
    ) {
      oAux[key] = obj2[key];
      continue;
    }

    // is an object
    if (typeof obj2[key] === 'object') {
      // obj1 is also an object
      if (obj1[key] !== null && typeof obj1[key] === 'object') {
        oAux[key] = mergeObjects(obj1[key], obj2[key]);
        continue;
      } else {
        // if obj1 is not an object, obj2 wins
        oAux[key] = obj2[key];
        continue;
      }
    }
  }

  return oAux;
}

export function alphanumericfy(str, onlyLower = false) {
  const alphanumericAux = str.toString().replace(/[^a-z0-9]/gi, '');
  const newStr = onlyLower ? alphanumericAux.toLowerCase() : alphanumericAux;

  return newStr;
}

// function mergeObjectKeys(obj1, obj2) {
//   const obj1Aux = obj1 && typeof obj1 === 'object' ? obj1 : [];
//   const obj2Aux = obj2 && typeof obj2 === 'object' ? obj2 : [];
//   const arrAux = Object.keys(obj1Aux).concat(Object.keys(obj2Aux));
//   return arrAux.filter((v, i, t) => t.indexOf(v) === i);
// }

interface PropsConfigSimpleTable {
  key: string;
  headerLabel: string;
  data: [];
}

export function fixConfigSimpleTable(props: PropsConfigSimpleTable) {
  const { key, headerLabel, data } = props;

  const newConfig = [{ key: key, headerLabel: headerLabel }, ...(data || [])];

  return newConfig;
}

export function formatterDataTable(props: { data: [] }) {
  const { data } = props;

  const slicedDataTable: DataSource[] = data.slice(1, data.length);

  const newformattedDataTable = slicedDataTable.map(item => {
    const newData = { ...item, ...item.columns };

    return newData;
  });

  return newformattedDataTable;
}

export function getDynamicColumns<T extends Row>(
  rows: T[],
): SimpleTableConfig[] {
  const dynamicColumns = rows.find(row => row.key === 'dynamicColumns');

  if (!dynamicColumns?.columns) {
    return [];
  }

  return dynamicColumns.columns;
}

export function normalizeSimpleTableRows<T extends Row>(rows: T[]) {
  const rowsWithoutDynamicColumns = rows.filter(
    row => row.key !== 'dynamicColumns',
  );

  return rowsWithoutDynamicColumns.map(item => {
    return { ...item, ...(item.columns || {}) };
  });
}
