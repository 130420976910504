import React, { useEffect, useState, useRef } from 'react';
import { alphanumericfy } from 'pages/Reports/utils/dataFunctions';
import { useSimpleTableContext } from '../../context';
import {
  Wrapper,
  FilterContainer,
  FilterIcon,
  FilterButton,
  SelectAllWrapper,
  FilterList,
} from './styles';

interface Props {
  data: object[];
  colKey: string;
  filterOrientation: string | null | undefined;
}

export default function ColumnFilter(props: Props) {
  const { data, colKey, filterOrientation } = props;
  const [valuesList, setValuesList] = useState([]);
  const [displayFilter, setDisplayFilter] = useState(false);

  const { setFilters } = useSimpleTableContext();

  const containerRef = useRef(null);
  const filterButtonRef = useRef(null);
  const selectAllRef = useRef(null);
  const clearAllRef = useRef(null);

  useEffect(() => {
    initRows();
  }, [data]);

  function initRows() {
    const valuesListAux = data
      ?.map(row => row[colKey])
      .filter((item, index, array) => array.indexOf(item) === index);

    const inputList = containerRef.current.querySelectorAll('input') || [];
    inputList.forEach(item => (item.checked = true));

    setValuesList(valuesListAux);
  }

  function handleShowFilter() {
    const mainView = document.getElementById('main-view');

    mainView?.addEventListener('click', closeOnExternalClick);
    containerRef.current.addEventListener('click', e => e.stopPropagation());
    filterButtonRef.current.addEventListener('click', handleFilter);
    selectAllRef.current.addEventListener('click', handleSelectAll);
    clearAllRef.current.addEventListener('click', handleClearAll);

    setDisplayFilter(!displayFilter);
  }

  function handleFilter() {
    const el = containerRef.current.querySelectorAll('input') || [];

    const filteredNames = [];
    el.forEach(item => {
      if (item.checked) {
        filteredNames.push(item.value);
      }
    });

    setFilters(prev => ({
      ...prev,
      [colKey]: filteredNames,
    }));
    setDisplayFilter(false);

    filterButtonRef.current.removeEventListener('click', handleFilter);
    selectAllRef.current.removeEventListener('click', handleSelectAll);
    clearAllRef.current.removeEventListener('click', handleClearAll);
  }

  function handleSelectAll() {
    const inputList = containerRef.current.querySelectorAll('input') || [];
    inputList.forEach(item => (item.checked = true));
  }

  function handleClearAll() {
    const inputList = containerRef.current.querySelectorAll('input') || [];
    inputList.forEach(item => (item.checked = false));
  }

  function isOutsideIcon(path) {
    let res = true;
    try {
      res = !path.find(
        row =>
          row.getAttribute('id') === `filter-icon-${alphanumericfy(colKey)}`,
      );
    } catch {
      res = true;
    }
    return res;
  }

  function closeOnExternalClick(e) {
    const mainView = document.getElementById('main-view');
    selectAllRef.current.removeEventListener('click', handleSelectAll);
    clearAllRef.current.removeEventListener('click', handleClearAll);
    mainView?.removeEventListener('click', closeOnExternalClick);
    if (isOutsideIcon(e.path)) {
      setDisplayFilter(false);
    }
  }

  function renderFilterIcon() {
    return (
      <div className="filter-icon-container">
        <FilterIcon
          id={`filter-icon-${alphanumericfy(colKey)}`}
          size={12}
          onClick={handleShowFilter}
        />
      </div>
    );
  }

  function renderFilterList() {
    const renderItem = (name, i) => (
      <div className="input-wrapper" key={alphanumericfy(name)}>
        <input
          type="checkbox"
          id={`${colKey}-${i}`}
          value={name}
          defaultChecked
        />
        <label htmlFor={`${colKey}-${i}`}>{name}</label>
      </div>
    );

    const containerPositionAux =
      filterOrientation === 'left' ? { right: 0 } : { left: 0 };

    return (
      <FilterContainer
        className="filter-container"
        style={{
          display: displayFilter ? 'block' : 'none',
          ...containerPositionAux,
        }}
        ref={containerRef}
      >
        <FilterButton ref={filterButtonRef} className="filter-button">
          <span>FILTRAR</span>
        </FilterButton>
        <SelectAllWrapper className="select-all-wrapper">
          <span ref={selectAllRef}>SELECIONAR TUDO</span>
          <span ref={clearAllRef}>LIMPAR SELEÇÃO</span>
        </SelectAllWrapper>
        <FilterList className="filter-list">
          {valuesList.map((item, index) => renderItem(item, index))}
        </FilterList>
      </FilterContainer>
    );
  }

  return (
    <Wrapper>
      {renderFilterIcon()}
      {renderFilterList()}
    </Wrapper>
  );
}
