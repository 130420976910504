import { TemplateForm } from '../store/types';
import api from './api';

export function getByType(
  type: string,
  companyId: number,
): Promise<TemplateForm> {
  return api.get(`/template-formulario/get-by-type/${type}/${companyId}.json`, {
    params: { apiConfig: { deep: false } },
  });
}
