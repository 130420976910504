import ButtonComponent from 'components/Button';
import { MdAddCircle, MdFileUpload } from 'react-icons/md';
import styled from 'styled-components';

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const AddCircle = styled(MdAddCircle)`
  margin-right: 3px;
`;
export const FileUpload = styled(MdFileUpload)`
  margin-right: 3px;
`;

export const Button = styled(ButtonComponent)`
  margin-top: 0.6rem;
  border: none;
`;
