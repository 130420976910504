export function getMatchIndex(value, conditions, type = 'ranges') {
  switch (type) {
    case 'ranges':
      return matchRange(value, conditions);
    case 'values':
      return matchValue(value, conditions);
    default:
      return false;
  }
}

function matchRange(number, ranges) {
  for (let index = 0; index < ranges.length; index += 1) {
    const range = ranges[index];
    const validation = numberMeetsRange(number, range);
    if (validation) {
      return index;
    }
  }
  return false;
}

function numberMeetsRange(number, range) {
  const firstCondition = range.slice(0, 1);
  const secondCondition = range.slice(-1);
  const txtValues = range.slice(1, -1).split(',');
  const values = [parseFloat(txtValues[0]), parseFloat(txtValues[1])];
  let response = true;
  response = firstCondition === '[' ? number >= values[0] : number > values[0];
  if (!response) return false;
  response = secondCondition === ']' ? number <= values[1] : number < values[1];
  if (!response) return false;
  return true;
}

function matchValue(value, values) {
  return values.indexOf(value) >= 0 ? values.indexOf(value) : false;
}
