import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const DashboardConcretagem = lazy(() =>
  import(
    './layouts/concretagem' /* webpackChunkName: 'relatorios.vibra.concretagem' */
  ),
);

const DashboardPlanejamentoMensal = lazy(() =>
  import(
    './layouts/planejamentoMensal' /* webpackChunkName: 'relatorios.vibra.planejamento-mensal' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/vibra/concretagem"
    component={DashboardConcretagem}
    path="/relatorios/vibra/concretagem"
  />,
  <PrivateRoute
    key="/relatorios/vibra/planejamento-mensal"
    component={DashboardPlanejamentoMensal}
    path="/relatorios/vibra/planejamento-mensal"
  />,
];
