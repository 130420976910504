import * as Yup from 'yup';

export const validation = Yup.object().shape({
  name: Yup.object()
    .nullable()
    .required('Este campo é obrigatório'),
  position: Yup.string()
    .nullable()
    .required('Este campo é obrigatório'),
  role: Yup.object()
    .nullable()
    .required('Este campo é obrigatório'),
  cpfSignature: Yup.object()
    .nullable()
    .when('isCpf', {
      is: true,
      then: Yup.object()
        .nullable()
        .required('Este campo é obrigatório'),
    }),
  isCpf: Yup.boolean(),
});
