import moment from 'moment';
import { PropsHandleClick } from 'pages/Reports/components/tables/SimpleTable/types';
import React from 'react';

export default function DateFormatter(props: PropsHandleClick) {
  const { col, value } = props;

  function normalizedDate() {
    if (!value) {
      return '';
    }

    const normalizedDateAux = moment(value);

    if (!normalizedDateAux.isValid()) {
      return '';
    }

    if (col.type === 'time') {
      return normalizedDateAux.format('HH:mm');
    }

    if (col.type === 'datetime') {
      return normalizedDateAux.format('DD/MM/YYYY HH:mm:ss');
    }

    return normalizedDateAux.format('DD/MM/YYYY');
  }

  return <p>{normalizedDate()}</p>;
}
