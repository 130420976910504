import React, { lazy } from 'react';
import { PrivateRoute } from '../../../routes';

// import { routes as demoRoutesArrays } from './seguranca/demo/routes';
const LinhaDeBalancoDev = lazy(() =>
  import(
    './dev/linhaDeBalanco' /* webpackChunkName: 'relatorios.ggo.linha-de-balanco' */
  ),
);
const WaterfallAditivoDev = lazy(() =>
  import(
    './dev/waterfallAditivo' /* webpackChunkName: 'relatorios.ggo.waterfall-aditivo' */
  ),
);
const FisicoDash = lazy(() =>
  import(
    './fisico/gerencial/index' /* webpackChunkName: 'relatorios.ggo.fisico.gerencial' */
  ),
);
const FisicoTab1 = lazy(() =>
  import(
    './fisico/tab1_index' /* webpackChunkName: 'relatorios.ggo.fisico.tabela-1' */
  ),
);
const FisicoLinhaDeBalancoV2 = lazy(() =>
  import(
    './fisico/linhaDeBalancov2' /* webpackChunkName: 'relatorios.gescorpgo.fisico.linha-de-balanco-v2' */
  ),
);
const FinPortfolio = lazy(() =>
  import(
    './financeiro/portfolio' /* webpackChunkName: 'relatorios.ggo.financeiro.portfolio' */
  ),
);
const FinObras = lazy(() =>
  import(
    './financeiro/obras' /* webpackChunkName: 'relatorios.ggo.financeiro.obras' */
  ),
);
const Fisico01 = lazy(() =>
  import(
    './demo/fisico01_index' /* webpackChunkName: 'relatorios.gescorpgo.acompanhamento-de-obra' */
  ),
);
const Seguranca01 = lazy(() =>
  import(
    './demo/seguranca01_index' /* webpackChunkName: 'relatorios.gescorpgo.checklist-de-seguranca' */
  ),
);
const CkSeguranca = lazy(() =>
  import(
    './seguranca/gafisa/gafisa_exemplo' /* webpackChunkName: 'relatorios.gafisa.checklist-de-seguranca-exemplo' */
  ),
);
const CkSegurancaComDados = lazy(() =>
  import(
    './seguranca/gafisa/gafisa_com_dados' /* webpackChunkName: 'relatorios.gafisa.checklist-de-seguranca' */
  ),
);
const FvmGerencial = lazy(() =>
  import('./fvm/gerencial' /* webpackChunkName: 'relatorios.fvm.gerencial' */),
);
const FvmDemo = lazy(() =>
  import('./fvm/gerencial/demo' /* webpackChunkName: 'relatorios.fvm.demo' */),
);
const FvmAvaliacaoFornecedores = lazy(() =>
  import(
    './fvm/portfolio' /* webpackChunkName: 'relatorios.fvm.avaliacao-de-fornecedores' */
  ),
);
const FvmV2 = lazy(() =>
  import(
    './qualidade/fvmV2' /*portfolioChunkName: 'report.qualidade.fvm.dashboard-obra2' */
  ),
);
const DashboardControleTecnologico = lazy(() =>
  import(
    './qualidade/DashboardControleTecnológico' /*portfolioChunkName: 'report.qualidade.controle-tecnologico' */
  ),
);

const UtilizacaoComportamental = lazy(() =>
  import(
    './seguranca/demo/UtilizacaoComportamental' /* webpackChunkName: 'relatorios.gescorp.seguranca.utilizacao-comportamental' */
  ),
);
const DashboardComportamental = lazy(() =>
  import(
    './seguranca/demo/DashboardComportamental' /* webpackChunkName: 'relatorios.gescorp.seguranca.dashboard-comportamental' */
  ),
);
const DashboardNRs = lazy(() =>
  import(
    './seguranca/demo/DashboardNRs' /* webpackChunkName: 'relatorios.gescorp.seguranca.dashboard-nrs' */
  ),
);
const DashboardAcidentes = lazy(() =>
  import(
    './seguranca/demo/DashboardAcidentes' /* webpackChunkName: 'relatorios.gescorp.seguranca.dashboard-acidentes' */
  ),
);
const DashboardObra = lazy(() =>
  import(
    './qualidade/DashboardObra' /* webpackChunkName: 'relatorios.gescorp.qualidade.dashboard-obra' */
  ),
);
const DashboardObraNew = lazy(() =>
  import(
    './qualidade/DashboardObraNew' /* webpackChunkName: 'report.qualidade.fvs.dashboard-obra2' */
  ),
);
const DashboardAcidentesSeguranca = lazy(() =>
  import(
    './seguranca/DashboardAcidentes' /* webpackChunkName: 'relatorios.seguranca.dashboard-acidentes' */
  ),
);
const DashboardAlojamento = lazy(() =>
  import(
    './seguranca/Alojamento' /* webpackChunkName: 'relatorios.seguranca.alojamento' */
  ),
);
const DashboardEquipamentos = lazy(() =>
  import(
    './seguranca/DashboardEquipamentos' /* webpackChunkName: 'report.seguranca.equipamentos.dashboard-obra2' */
  ),
);

const PlanejamentoMensal = lazy(() =>
  import(
    './planejamentoMensal' /* webpackChunkName: 'relatorios.planejamento-mensal.' */
  ),
);
const DashboardAvaliacaoFornecedores = lazy(() =>
  import(
    './fvm/DashboardAvaliacaoFornecedores' /* webpackChunkName: 'relatorios.planejamento-mensal.' */
  ),
);

const FechamentoSemanal = lazy(() =>
  import(
    './fechamentoSemanal' /* webpackChunkName: 'relatorios.fechamento-semanal.' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/ggo/linha-de-balanco"
    component={LinhaDeBalancoDev}
    path="/relatorios/ggo/linha-de-balanco"
  />,
  <PrivateRoute
    key="/relatorios/ggo/waterfall-aditivo"
    component={WaterfallAditivoDev}
    path="/relatorios/ggo/waterfall-aditivo"
  />,
  <PrivateRoute
    key="/relatorios/ggo/fisico/gerencial"
    component={FisicoDash}
    path="/relatorios/ggo/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/ggo/fisico/tabela-1"
    component={FisicoTab1}
    path="/relatorios/ggo/fisico/tabela-1"
  />,
  <PrivateRoute
    key="/relatorios/gescorpgo/fisico/linha-de-balanco-v2"
    component={FisicoLinhaDeBalancoV2}
    path="/relatorios/gescorpgo/fisico/linha-de-balanco-v2"
  />,
  <PrivateRoute
    key="/relatorios/ggo/financeiro/portfolio"
    component={FinPortfolio}
    path="/relatorios/ggo/financeiro/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/ggo/financeiro/obras"
    component={FinObras}
    path="/relatorios/ggo/financeiro/obras"
  />,
  <PrivateRoute
    key="/relatorios/gescorpgo/acompanhamento-de-obra"
    component={Fisico01}
    path="/relatorios/gescorpgo/acompanhamento-de-obra"
  />,
  <PrivateRoute
    key="/relatorios/gescorpgo/checklist-de-seguranca"
    component={Seguranca01}
    path="/relatorios/gescorpgo/checklist-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/gafisa/checklist-de-seguranca-exemplo"
    component={CkSeguranca}
    path="/relatorios/gafisa/checklist-de-seguranca-exemplo"
  />,
  <PrivateRoute
    key="/relatorios/gafisa/checklist-de-seguranca"
    component={CkSegurancaComDados}
    path="/relatorios/gafisa/checklist-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/fvm/gerencial"
    component={FvmGerencial}
    path="/relatorios/fvm/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/fvm/demo"
    component={FvmDemo}
    path="/relatorios/fvm/demo"
  />,
  <PrivateRoute
    key="/relatorios/fvm/avaliacao-de-fornecedores"
    component={FvmAvaliacaoFornecedores}
    path="/relatorios/fvm/avaliacao-de-fornecedores"
  />,
  <PrivateRoute
    key="/relatorios/gescorp/seguranca/utilizacao-comportamental"
    component={UtilizacaoComportamental}
    path="/relatorios/gescorp/seguranca/utilizacao-comportamental"
  />,
  <PrivateRoute
    key="/relatorios/gescorp/seguranca/dashboard-comportamental"
    component={DashboardComportamental}
    path="/relatorios/gescorp/seguranca/dashboard-comportamental"
  />,
  <PrivateRoute
    key="/relatorios/gescorp/seguranca/dashboard-nrs"
    component={DashboardNRs}
    path="/relatorios/gescorp/seguranca/dashboard-nrs"
  />,
  <PrivateRoute
    key="/relatorios/gescorp/seguranca/dashboard-acidentes"
    component={DashboardAcidentes}
    path="/relatorios/gescorp/seguranca/dashboard-acidentes"
  />,
  <PrivateRoute
    key="/report/qualidade/fvs/dashboard-obra"
    component={DashboardObra}
    path="/report/qualidade/fvs/dashboard-obra"
  />,
  <PrivateRoute
    key="/report/qualidade/fvm/dashboard-obra2"
    component={FvmV2}
    path="/report/qualidade/fvm/dashboard-obra2"
  />,
  <PrivateRoute
    key="/report/qualidade/fvs/dashboard-obra2"
    component={DashboardObraNew}
    path="/report/qualidade/fvs/dashboard-obra2"
  />,
  <PrivateRoute
    key="/report/seguranca/equipamentos/dashboard-obra2"
    component={DashboardEquipamentos}
    path="/report/seguranca/equipamentos/dashboard-obra2"
  />,
  <PrivateRoute
    key="/relatorios/seguranca/dashboard-acidentes"
    component={DashboardAcidentesSeguranca}
    path="/relatorios/seguranca/dashboard-acidentes"
  />,
  <PrivateRoute
    key="/relatorios/seguranca/alojamento"
    component={DashboardAlojamento}
    path="/relatorios/seguranca/alojamento"
  />,
  <PrivateRoute
    key="/relatorios/planejamento-mensal"
    component={PlanejamentoMensal}
    path="/relatorios/planejamento-mensal"
  />,
  <PrivateRoute
    key="/relatorios/mitre/fvm/avaliacao-fornecedores"
    component={DashboardAvaliacaoFornecedores}
    path="/relatorios/mitre/fvm/avaliacao-fornecedores"
  />,
  <PrivateRoute
    key="/relatorios/qualidade/controle-tecnologico"
    component={DashboardControleTecnologico}
    path="/relatorios/qualidade/controle-tecnologico"
  />,
  <PrivateRoute
    key="/fechamento-semanal"
    component={FechamentoSemanal}
    path="/fechamento-semanal"
  />,
];
