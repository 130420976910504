import React, { ReactElement } from 'react';
import { Container } from './styles';

interface Props {
  widgetStyle?: boolean;
  children: ReactElement | ReactElement[];
}

export default function ActionContainer(props: Props) {
  const { children, widgetStyle } = props;

  return (
    <Container className="actions-container" widgetStyle={widgetStyle}>
      {children}
    </Container>
  );
}
