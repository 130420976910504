import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const Planejamento = lazy(() =>
  import(
    './layouts/planejamento' /* webpackChunkName: 'relatorios.trisul.planejamento' */
  ),
);
const Portfolio = lazy(() =>
  import(
    './layouts/financeiro/portfolio' /* webpackChunkName: 'relatorios.trisul.financeiro.portfolio' */
  ),
);
const Obras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'relatorios.trisul.financeiro.obras' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/trisul/planejamento"
    component={Planejamento}
    path="/relatorios/trisul/planejamento"
  />,
  <PrivateRoute
    key="/relatorios/trisul/financeiro/portfolio"
    component={Portfolio}
    path="/relatorios/trisul/financeiro/portfolio"
  />,
  <PrivateRoute
    key="/relatorios/trisul/financeiro/obras"
    component={Obras}
    path="/relatorios/trisul/financeiro/obras"
  />,
];
