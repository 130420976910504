import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const GeralDeSeguranca = lazy(() =>
  import(
    './layouts/seguranca/relatorioGeralDeSeguranca' /* webpackChunkName: 'tha.seguranca.relatorio-geral-de-seguranca' */
  ),
);
const InspecaoSeguranca = lazy(() =>
  import(
    './layouts/seguranca/inspecaoSeguranca' /* webpackChunkName: 'tha.seguranca.inspecao-seguranca' */
  ),
);
const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'tha.financeiro.obras' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/tha/seguranca/inspecao-seguranca"
    component={InspecaoSeguranca}
    path="/relatorios/tha/seguranca/inspecao-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/tha/seguranca/relatorio-geral-de-seguranca"
    component={GeralDeSeguranca}
    path="/relatorios/tha/seguranca/relatorio-geral-de-seguranca"
  />,
  <PrivateRoute
    key="/relatorios/tha/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/tha/financeiro/obras"
  />,
];
