import useQuery from 'hooks/use-query';
import React, { ReactElement, useEffect, useState } from 'react';
import { numericArrayOf } from 'utils/common';

export default function Dashdoards(): ReactElement {
  const [images, setImages] = useState<string[]>([]);

  const params = useQuery();

  useEffect(() => {
    getImages();
  }, [params?.totalImages]);

  async function getImages() {
    console.log('params', params);

    const newImages = numericArrayOf(+(params?.totalImages || '1')).map(
      idx => `https://picsum.photos/1024?${idx}`,
    );
    setImages(newImages);
  }

  return (
    <div>
      {images.map(i => (
        <img key={i} style={{ width: '30rem', marginRight: '1rem' }} src={i} />
      ))}
    </div>
  );
}
