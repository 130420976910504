import Button from 'components/Button';
import Input from 'components/Input';
import { VscDebugRestart } from 'react-icons/vsc';
import styled from 'styled-components';

export const Container = styled.div`
  width: 55vw;
  padding: 2rem;
  color: #444;

  @media (max-width: 960px) {
    width: 90vw;
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const ColumnGroup = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitlesWrapper = styled.div`
  font-size: 1.5rem;
  display: flex;
  align-items: center;

  strong {
    display: block;

    &:first-child {
      width: 25rem;
    }
  }
`;

export const ColumnTitle = styled.strong`
  font-size: 1.5rem;
`;

export const SectionTitle = styled.span`
  font-size: 1.5rem;
  width: 25rem;
  height: 3.5rem;
  display: block;
  margin-top: 1.7rem;
`;

export const AutoDateInput = styled(Input)`
  margin-top: 1rem;
  width: 11rem;
  margin-right: 4.2rem;
  display: block;
`;

export const DummyAntecedence = styled.div`
  margin-top: 1rem;
  width: 10rem;
  height: 4rem;
  display: block;
`;

export const AntecedenceInput = styled(Input)`
  margin-top: 1rem;
  width: 10rem;
  display: block;
`;

export const SubmitButton = styled(Button)`
  display: block;
  width: 15rem;
  margin: 2rem 0 0 auto;
`;

export const ResettableWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ResetButton = styled(VscDebugRestart)`
  font-size: 1.8rem;
  margin: 1rem 2rem 0 0.5rem;
  cursor: pointer;
  transition: 100ms;
  color: #ccc;

  &:hover {
    color: #999;
  }
`;
