import { RelatedFileGroup } from '../store/types';
import api from './api';

export function getAll(fileId: number): Promise<RelatedFileGroup[]> {
  return api.get(`/relacionamento-grupo-arquivo/list/${fileId}.json`);
}

export function create(data: RelatedFileGroup): Promise<RelatedFileGroup> {
  return api.post('/relacionamento-grupo-arquivo/add.json', data);
}

export function remove(data: RelatedFileGroup[]): Promise<any> {
  return api.post('/relacionamento-grupo-arquivo/remove.json', data);
}
