/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import ReactSelect, { OptionTypeBase } from 'react-select';
import AsyncSelectComponent, { Props as PropsSelct } from 'react-select/async';
import ReactCreatable from 'react-select/creatable';
import { colors } from '../../theme';
import { Placeholder } from './styles';
import { OptionsType } from 'store/types';

// TODO: ISSO DAQUI TA UMA BOSTA, REFATORAR USANDO COMPONENT COM E REF

interface Props extends OptionTypeBase {
  height?: string | number;
  error?: boolean;
  placeholder?: string;
  options: OptionsType[];
  value?: OptionsType | null;
  isDisabled?: boolean;
  className?: string;
  onChange: (option: OptionsType | undefined) => void;
}

type Props2 = PropsSelct & {
  height?: string | number;
  error?: boolean;
};

export default function Select(props: Props): ReactElement {
  const { minHeight, error } = props;

  const height = props.height || '4rem';
  const fontSize = props.fontSize || '1.4rem';
  const fontControlSize = props.fontControlSize || '1.4rem';
  const { placeholder } = props;

  function getBorderColor(state) {
    if (error) {
      return colors.danger;
    }

    return state.menuIsOpen ? colors.green : '#ddd';
  }

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      fontSize: fontControlSize,
      minHeight,
      height: minHeight ? 'auto' : height,
      borderColor: getBorderColor(state),
      boxShadow: null,
      '&:hover': {
        borderColor: state.isFocused ? colors.green : '#ddd',
      },
    }),

    option: (provided: any, state: any) => ({
      ...provided,
      fontSize,
      '&:hover': {
        backgroundColor: '#f5f5f5',
        cursor: 'pointer',
      },
      backgroundColor: state.isSelected ? '#B9E2DE' : null,

      color: '#333',
      '&:active': {
        backgroundColor: '#f5f5f5',
      },
    }),

    menu: provided => ({ ...provided, zIndex: 9999 }),
  };

  return (
    <ReactSelect
      classNamePrefix={'react-select'}
      styles={customStyles}
      {...props}
      placeholder={<Placeholder>{placeholder || 'Selecionar'}</Placeholder>}
    />
  );
}

export function AsyncSelect(props: Props2): ReactElement {
  const { minHeight, error } = props;

  const height = props.height || '4rem';
  const fontSize = props.fontSize || '1.4rem';
  const fontControlSize = props.fontControlSize || '1.4rem';
  const { placeholder } = props;

  function getBorderColor(state) {
    if (error) {
      return colors.danger;
    }

    return state.menuIsOpen ? colors.green : '#ddd';
  }

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      fontSize: fontControlSize,
      minHeight,
      height: minHeight ? 'auto' : height,
      borderColor: getBorderColor(state),
      boxShadow: null,
      '&:hover': {
        borderColor: state.isFocused ? colors.green : '#ddd',
      },
    }),

    option: (provided: any, state: any) => ({
      ...provided,
      fontSize,
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
      backgroundColor: state.isSelected ? '#B9E2DE' : null,

      color: '#333',
      '&:active': {
        backgroundColor: '#f5f5f5',
      },
    }),
  };

  return (
    <AsyncSelectComponent
      styles={customStyles}
      {...props}
      placeholder={<Placeholder>{placeholder || 'Selecionar'}</Placeholder>}
    />
  );
}

type CreatableSelectProps = OptionTypeBase;

export function CreatableSelect(props: CreatableSelectProps): ReactElement {
  const { minHeight, error } = props;

  const height = props.height || '4rem';
  const fontSize = props.fontSize || '1.4rem';
  const fontControlSize = props.fontControlSize || '1.4rem';
  const { placeholder } = props;

  function getBorderColor(state) {
    if (error) {
      return colors.danger;
    }

    return state.menuIsOpen ? colors.green : '#ddd';
  }

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      fontSize: fontControlSize,
      minHeight,
      height: minHeight ? 'auto' : height,
      borderColor: getBorderColor(state),
      boxShadow: null,
      '&:hover': {
        borderColor: state.isFocused ? colors.green : '#ddd',
      },
    }),

    option: (provided: any, state: any) => ({
      ...provided,
      fontSize,
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
      backgroundColor: state.isSelected ? '#B9E2DE' : null,

      color: '#333',
      '&:active': {
        backgroundColor: '#f5f5f5',
      },
    }),
  };

  return (
    <ReactCreatable
      styles={customStyles}
      formatCreateLabel={() => 'Criar novo'}
      createOptionPosition="first"
      {...props}
      placeholder={<Placeholder>{placeholder || 'Selecionar'}</Placeholder>}
    />
  );
}
