import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalName } from '../store/modal/types';
import { RootState } from '../store/rootReducer';

interface State<T> {
  isOpen: boolean;
  windowSec: number;
  params: T;
}

const initialStete = {
  isOpen: false,
  params: {},
  windowSec: 1,
};

export default function useModal<T>(
  modal: ModalName,
): { isOpen: boolean; windowSec: number; params: T } {
  const [modalState, setModalState] = useState(initialStete as State<T>);

  useSelector((state: RootState) => {
    const modalIdx = state.modal.findIndex(m => m.modal === modal);
    const modalFromRedux = state.modal[modalIdx];

    if (modalState.isOpen && !modalFromRedux) {
      setModalState(initialStete as State<T>);
      return;
    }

    if (
      !modalState.isOpen &&
      modalFromRedux &&
      modalFromRedux.modal === modal
    ) {
      setModalState({
        isOpen: true,
        windowSec: modalIdx + 1,
        params: modalFromRedux.params as T,
      });
    }
  });

  return modalState;
}
