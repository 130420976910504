import { formatNumber } from 'pages/Reports/utils/dataFunctions';
import React from 'react';
import { StyledDiv } from './styles';

export default function DefaultType(props) {
  const {
    col: { valueFormatter, numberFormatting },
    value,
  } = props;

  const calcValue = !numberFormatting
    ? value
    : formatNumber(numberFormatting)(value);

  const normalizedFormatter = valueFormatter || (v => v);

  return (
    <StyledDiv className="celltype-default">
      {normalizedFormatter(calcValue)}
    </StyledDiv>
  );
}
