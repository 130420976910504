import React, { useEffect, useState } from 'react';
import ImgsViewer from 'react-images-viewer';
import { useDispatch } from 'react-redux';
import useUser from 'hooks/use-user';
import showAlert from 'components/Alert';
import { getSqlResponse } from 'services/dataset';
import { get } from 'services/photo';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import {
  DatasetParams,
  EnumActions,
  EnumProfile,
  EnumRoutes,
} from 'store/types';
import { normalizeFileUrl } from 'utils/file';
import { RowTitle, TitleWrapper } from '../../styles';
import { Field, PhotoType, UploadModalParams } from '../../types';
import { useDynamicForm } from '../../context';
import {
  AddIcon,
  Container,
  EditIcon,
  FakeFigure,
  Figure,
  PhotoWrapper,
} from './styles';
import { checkUserEditPermission } from '../../utils';
import WithPermission from 'components/WithPermission';

interface Props {
  field: Field;
  isDisabled: boolean;
}

export default function Photos(props: Props) {
  const [photos, setPhotos] = useState<PhotoType[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const {
    reportData,
    globalState,
    onSaveForm,
    contractId: idContrato,
    approvalData,
    fieldsAllowProps,
  } = useDynamicForm();

  const user = useUser();

  const dispatch = useDispatch();

  const { field, isDisabled } = props;

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    if (globalState) {
      fetchPhotos();
    }
  }, [globalState]);

  function handleShowViewer(imgIdx: number) {
    setIsOpen(!isOpen);
    setCurrentImage(imgIdx);
  }

  function handleVwGoToNext() {
    setCurrentImage(currentImage + 1);
  }

  function handleVwGoToPrevious() {
    setCurrentImage(currentImage ? currentImage - 1 : 0);
  }

  async function fetchPhotos() {
    const { defaultFormData, datasetParams } = globalState!;

    if (!field.datasetId && !defaultFormData?.idFormulario) {
      return;
    }

    if (field.datasetId) {
      const params: DatasetParams = {
        idContrato: idContrato || null,
        fieldName: field.name,
        id: defaultFormData?.idFormulario,
        ...datasetParams,
      };
      const response = await getSqlResponse<PhotoType[]>(
        field.datasetId!,
        params,
      );
      normalizeImages(response);

      return;
    }

    if (defaultFormData?.idFormulario) {
      const responseImages: PhotoType[] = await get(
        idContrato,
        defaultFormData.idFormulario,
      );

      normalizeImages(responseImages);
    }
  }

  function normalizeImages(images: PhotoType[]) {
    const newImages = images.filter(image => image.habilitado);

    const normalized = newImages.map<PhotoType>(img => ({
      ...img,
      src: normalizeFileUrl(img.referencia),
      caption: img.comentario,
      appendedData: img.appendedData && JSON.parse(img.appendedData),
    }));

    reportData.current[field.name] = normalized;

    setPhotos(normalized);
  }

  function openFormModal() {
    const { defaultFormData, datasetParams } = globalState!;

    /* The form need to be saved before add images */
    if (!defaultFormData?.idFormulario) {
      showAlert({
        title: 'Atenção!',
        subtitle:
          'Para realizar o upload de imagens é necessário salvar antes. Deseja salvar os dados editados?',
        actions: [
          {
            text: 'Não',
          },
          {
            text: 'Sim',
            onClick: onSaveForm,
          },
        ],
      });

      return;
    }

    dispatch(
      showModal<UploadModalParams>(ModalName.MODAL_UPLOAD, {
        allowDisableImage: field.allowDisableImage,
        ...datasetParams,
        idContrato,
        id: defaultFormData.idFormulario,
        idDatasetImages: field.datasetId!,
        fieldName: field.name,
        tipo: 'FM',
        idUsuario: user.idUsuario!,
        typeOfPage: EnumRoutes.UPLOAD_IMAGE,
        onReloadPageForm: fetchPhotos,
      }),
    );
  }

  return (
    <>
      <Container>
        <TitleWrapper>
          {field.label && (
            <RowTitle dangerouslySetInnerHTML={{ __html: field.label }} />
          )}

          {!isDisableChecked && (!field.datasetId || field.allowAddImg) && (
            <WithPermission
              action={EnumActions.SALVAR}
              rules={[
                EnumProfile.ADMINISTRADOR,
                EnumProfile.PRODUCAO,
                EnumProfile.EXDCUTIVO,
                EnumProfile.DEMO,
                EnumProfile.OPERACIONAL,
                EnumProfile.APROVADOR,
                EnumProfile.AUDITOR,
                EnumProfile.GESTOR,
              ]}
            >
              <AddIcon style={{ top: '0.5rem' }} onClick={openFormModal} />
            </WithPermission>
          )}

          {field.allowDisableImage && (
            <WithPermission
              action={EnumActions.EDITAR}
              rules={[
                EnumProfile.ADMINISTRADOR,
                EnumProfile.PRODUCAO,
                EnumProfile.EXDCUTIVO,
                EnumProfile.DEMO,
                EnumProfile.OPERACIONAL,
                EnumProfile.APROVADOR,
                EnumProfile.AUDITOR,
                EnumProfile.GESTOR,
              ]}
            >
              <EditIcon onClick={openFormModal} />
            </WithPermission>
          )}
        </TitleWrapper>

        <PhotoWrapper>
          {photos.map((photo, idx) => (
            <Figure key={photo.idFoto}>
              <img
                alt="foto"
                src={photo.src}
                onClick={() => handleShowViewer(idx)}
              />
              <div>{photo.comentario}</div>
            </Figure>
          ))}
          {photos.length ? photos.length % 2 === 0 && <FakeFigure /> : null}
        </PhotoWrapper>
      </Container>

      <ImgsViewer
        imgs={photos}
        currImg={currentImage}
        isOpen={isOpen}
        onClickPrev={handleVwGoToPrevious}
        onClickNext={handleVwGoToNext}
        onClose={handleShowViewer}
        closeBtnTitle=""
        leftArrowTitle=""
        rightBtnTitle=""
      />
    </>
  );
}
