/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { v1 as uuid } from 'uuid';
import XLSX from 'xlsx';
import showAlert from 'components/Alert';
import InputFile from 'components/InputFile';
import { getSqlResponse } from 'services/dataset';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { isNullOrUndefined } from 'utils/common';
import { toDateBR, toTime } from 'utils/date';
import { RowTitle, TitleWrapper } from '../../styles';
import {
  ActivitiesTableModalParams,
  Field,
  FormNewItemModalParams,
  State,
} from '../../types';
import { useDynamicForm } from '../../context';
import {
  AddIcon,
  CellIcon,
  DeleteAllIcon,
  EditIcon,
  ListIcon,
  TableStyled,
  TableWrapper,
  Th,
} from './styles';
import { IconFile } from 'pages/TableReport/components/Formatters/Form/styles';
import {
  checkUserEditPermission,
  normalizedInputSelect,
  normalizeExcelHours,
} from '../../utils';
import useUser from 'hooks/use-user';
import moment from 'moment';

interface Props {
  field: Field;
  setFormAnswer(name: string, value: State[]): void;
  isDisabled?: boolean;
}

export default function Table(props: Props) {
  const [data, setData] = useState<State[]>([]);

  const {
    globalState,
    contractId: idContrato,
    approvalData,
    fieldsAllowProps,
  } = useDynamicForm();

  const dispatch = useDispatch();

  const user = useUser();

  const { field, setFormAnswer, isDisabled } = props;

  const isDisableChecked = checkUserEditPermission(
    approvalData,
    field,
    user,
    fieldsAllowProps,
    isDisabled,
  );

  useEffect(() => {
    if (field.datasetId) {
      getDataByDataset();

      return;
    }

    const { defaultFormData } = globalState!;
    const defaultValue = defaultFormData?.form[field.name];

    if (defaultValue?.length) {
      setData(defaultValue);
    } else {
      setData([]);
    }
  }, [globalState]);

  useEffect(() => {
    setFormAnswer(field.name, data);
  }, [data]);

  async function getDataByDataset() {
    const { datasetParams } = globalState!;

    const params = {
      ...datasetParams,
      idContrato,
      idEmpresa: user.idEmpresa,
    };

    const res = await getSqlResponse<State[]>(field.datasetId!, params, true);

    const normalizedRes: State[] = [];

    for (const item of res) {
      const newItem = item;

      Object.keys(item).forEach(key => {
        try {
          newItem[key] = JSON.parse(newItem[key]);
        } catch (e) {
          // console.error(e);
        }
      });

      normalizedRes.push(newItem);
    }

    setData(res);
  }

  function handleSave(newData: State) {
    const previousData = data.find(answer => answer.uuid === newData.uuid);

    /* Update data */
    if (previousData) {
      const previousAnswerIndex = data.findIndex(
        answer => answer.uuid === previousData.uuid,
      );

      const newDataToUpdate = [...data];
      newDataToUpdate[previousAnswerIndex] = newData;

      setData(newDataToUpdate);

      return;
    }

    /* Create new data */
    setData([...data, { uuid: uuid(), ...newData }]);
  }

  function handleRemove(answerUuid: string) {
    const confirm = () => {
      const removedAnsers = data.filter(answer => answer.uuid !== answerUuid);
      setData(removedAnsers);
    };

    showAlert({
      title: 'Atenção!',
      subtitle: 'Deseja deletar o registros?',
      actions: [
        {
          text: 'Não',
        },
        {
          text: 'Sim',
          onClick: confirm,
        },
      ],
    });
  }

  function openFormModal(rowData: State | null) {
    if (isDisableChecked) {
      return;
    }

    dispatch(
      showModal<FormNewItemModalParams>(ModalName.DYNAMIC_FORM_NEW_ITEM, {
        fields: field.fields,
        tableFieldName: field.name,
        config: field,
        previousData: rowData,
        onSave: handleSave,
        onRemove: handleRemove,
        title: field.label,
      }),
    );
  }

  function openTableSelectModal() {
    dispatch(
      showModal<ActivitiesTableModalParams>(ModalName.ACTIVITIES_TABLE_MODAL, {
        fields: field.fields,
        tableFieldName: field.name,
        title: field.label,
        previousData: data,
        onSave: (selectedData: State[]) => setData([...data, ...selectedData]),
      }),
    );
  }

  function normalizeXlsToTable(sheetData: any[]) {
    const newSheetData = [...sheetData];
    delete newSheetData[0];

    const newTableData: State[] = [];

    newSheetData.forEach(xlsRow => {
      const newFiledObject: State = {
        uuid: uuid(),
      };

      xlsRow.forEach((xlsCell, idx) => {
        newFiledObject[field.fields[idx].name] = xlsCell;
      });

      newTableData.push(newFiledObject);
    });

    setData(newTableData);
  }

  function handleReadXlsx(files: File[]) {
    const reader = new FileReader();

    reader.onload = (evt: ProgressEvent<FileReader>) => {
      const bstr = evt.target!.result;

      const workbook = XLSX.read(bstr, {
        type: 'binary',
      });

      const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(firstWorksheet, { header: 1 });

      normalizeXlsToTable(sheetData);
    };

    reader.readAsBinaryString(files[0]);
  }

  function handleRemoveAll() {
    showAlert({
      title: 'Atenção!',
      subtitle: 'Deseja remover todos os itens da lista?',
      actions: [
        {
          text: 'Não',
        },
        {
          text: 'Sim',
          onClick: () => setData([]),
        },
      ],
    });
  }

  function renderColumns(rowData: State) {
    const normalizeText = (tableField: Field) => {
      if (tableField.type === 'input-upload') {
        return (
          <CellIcon>
            <IconFile />
          </CellIcon>
        );
      }

      if (isNullOrUndefined(rowData[tableField.name])) {
        return null;
      }

      if (
        tableField.type === 'select' ||
        tableField.type === 'creatable-select'
      ) {
        return normalizedInputSelect(rowData, tableField);
      }

      if (tableField.type === 'date') {
        return toDateBR(rowData[tableField.name]);
      }

      if (tableField.type === 'time') {
        return (
          rowData[tableField.name] &&
          toTime(`1970-01-01 ${normalizeExcelHours(rowData[tableField.name])}`)
        );
      }

      return rowData[tableField.name];
    };

    return field.fields.map((tableField, idx) => {
      return (
        <td key={`${rowData.uuid}-${tableField.name}`}>
          {normalizeText(tableField)}

          {idx === field.fields.length - 1 && !isDisableChecked && <EditIcon />}
        </td>
      );
    });
  }

  function orderBy(data: any[], typeOrder: string, fieldToOrder: string) {
    let orderedData;

    if (typeOrder === 'date') {
      orderedData = data.sort((a, b) => {
        return moment(b[fieldToOrder]).unix() - moment(a[fieldToOrder]).unix();
      });
    } else {
      orderedData = data.sort((a, b) =>
        a[fieldToOrder].localeCompare(b[fieldToOrder]),
      );
    }

    return orderedData;
  }

  function renderRows() {
    const { orderBy: getFieldOrderBy, type: fieldType } = field;

    const newData = getFieldOrderBy
      ? orderBy(data, fieldType, getFieldOrderBy)
      : data;

    return newData.map(rowData => (
      <tr key={rowData.uuid} onClick={() => openFormModal(rowData)}>
        {renderColumns(rowData)}
      </tr>
    ));
  }

  function renderButtons() {
    if (isDisableChecked) {
      return <></>;
    }

    return (
      <>
        {!field.disableRemove && (
          <DeleteAllIcon data-tip="Remover todos" onClick={handleRemoveAll} />
        )}

        {field.allowTableModal && (
          <ListIcon data-tip="Selecionar" onClick={openTableSelectModal} />
        )}

        {!field.disableAdd && (
          <AddIcon data-tip="Adicionar" onClick={() => openFormModal(null)} />
        )}
      </>
    );
  }

  return (
    <TableWrapper>
      <TitleWrapper>
        {field.label ? (
          <RowTitle dangerouslySetInnerHTML={{ __html: field.label }} />
        ) : (
          <div />
        )}

        {renderButtons()}
      </TitleWrapper>

      {field.allowUpload && !isDisableChecked && (
        <InputFile
          id={field.id}
          text="Selecionar arquivo"
          onSelectFiles={handleReadXlsx}
        />
      )}

      <TableStyled>
        <thead>
          <tr>
            {field.fields.map(configField => (
              <Th key={`h-${configField.name}`} size={configField.size}>
                {configField.label}
              </Th>
            ))}
          </tr>
        </thead>

        <tbody>{renderRows()}</tbody>
      </TableStyled>
      <ReactTooltip />
    </TableWrapper>
  );
}
