import { ReportQueue as ReportQueueStatement } from 'store/report/types';
import api from './api';
import apiAdonis from './apiAdonis';

export enum ReportQueueStatus {
  PENDING = 0,
  RUNNING = 1,
  DONE = 2,
  REPORT_SENT = 3,
  ERROR = 4,
}

export interface ReportQueue {
  id?: number;
  uuid: string;
  idContrato?: number;
  idUsuario?: number;
  idDataset: number;
  parametros: object;
  tipoRelatorio: string;
  dtCriacao?: string;
  idEmpresa?: number;
  referencia?: string;
  status?: ReportQueueStatus;
  nome?: string;
  name?: string;
  uuidGrupo?: number;
  nomeZip?: string;
}

interface DownloadPhotosProps {
  params: { [key: string]: string | number };
  idDataset: number;
}

interface updateByuuidProps {
  uuid: string;
  status?: ReportQueueStatus;
}

export function createAdonis(data: ReportQueue): Promise<ReportQueue> {
  return apiAdonis.post(`fila-relatorio/create`, data);
}

export function create(data: ReportQueue): Promise<ReportQueue> {
  return api.post(`/fila-relatorio/create`, data);
}

export function bulkInsert(data: ReportQueue): Promise<ReportQueueStatement[]> {
  return api.post(`/fila-relatorio/bulk-insert`, data);
}

export function bulkInsertAdonis(
  data: ReportQueue,
): Promise<ReportQueueStatement[]> {
  return apiAdonis.post(`/fila-relatorio/bulk-insert`, data);
}

export function bulkInsertByDataset(
  data: ReportQueue,
): Promise<ReportQueueStatement[]> {
  return apiAdonis.post(`/fila-relatorio/bulk-insert-by-dataset`, data);
}

export function get(uuid: string): Promise<ReportQueue> {
  return api.get(`/fila-relatorio/get/${uuid}`);
}

export function getAdonis(uuid: string): Promise<ReportQueue> {
  return apiAdonis.get(`/fila-relatorio/get/${uuid}`);
}

export function sendEmailResponsible(data: {
  idContrato: number;
  idUsuario: number;
}): Promise<void> {
  return api.post(`/fila-relatorio/send-messaging-report`, data);
}

export function sendEmailResponsibles(data: {
  idContrato: number;
  idUsuario: number;
}): Promise<void> {
  return apiAdonis.post(`/fila-relatorio/send-messaging-report`, data);
}

export function downloadPhotos(data: DownloadPhotosProps): Promise<void> {
  return apiAdonis.post('fila-relatorio/send-photos-to-report-queue', data);
}

export function updateByUuid(data: updateByuuidProps): Promise<void> {
  return apiAdonis.post('fila-relatorio/update-by-uuid', data);
}
