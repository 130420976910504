import React from 'react';
import { EnumActions, EnumProfile, Form } from 'store/types';
import { useDynamicForm } from 'components/Widgets/DynamicForm/context';
import showAlert from 'components/Alert';

import { Button } from '../../../styles';
import WithPermission from 'components/WithPermission';
import { ContentVisible } from './styles';

interface Props {
  parametersToSave: {
    data: string;
    id_formulario: number;
  };
  isDisabled?: boolean;
  isHiddenButtonClear?: boolean;
}

export default function ClearButton(props: Props) {
  const {
    approvalData,
    globalState,
    newFormData,

    setGlobalState,
  } = useDynamicForm();

  const { isDisabled, isHiddenButtonClear } = props;

  function renderClearFormButton() {
    if (approvalData?.usuarioAprovacao) {
      return <></>;
    }

    const confirm = () => {
      const { parametersToSave } = props;

      if (parametersToSave) {
        newFormData.current = { ...parametersToSave };
      } else {
        newFormData.current = {};
      }

      const { defaultFormData } = globalState!;

      setGlobalState({
        ...globalState!,
        defaultFormData: {
          ...defaultFormData,
          form: { ...newFormData.current },
        } as Form,
      });
    };

    const handleClearForm = () => {
      showAlert({
        title: 'Atenção!',
        subtitle: 'Deseja limpar o formulário?',
        actions: [
          {
            text: 'Não',
          },
          {
            text: 'Sim',
            onClick: confirm,
          },
        ],
      });
    };

    return (
      <WithPermission
        action={EnumActions.LIMPAR}
        rules={[
          EnumProfile.ADMINISTRADOR,
          EnumProfile.PRODUCAO,
          EnumProfile.EXDCUTIVO,
          EnumProfile.DEMO,
          EnumProfile.OPERACIONAL,
          EnumProfile.APROVADOR,
          EnumProfile.AUDITOR,
          EnumProfile.GESTOR,
        ]}
      >
        <Button
          small
          text="Limpar"
          onClick={handleClearForm}
          disabled={isDisabled}
        />
      </WithPermission>
    );
  }

  return (
    <ContentVisible isHiddenButtonClear={isHiddenButtonClear}>
      {renderClearFormButton()}
    </ContentVisible>
  );
}
