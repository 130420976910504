import queryString from 'query-string';
import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Button from '../../components/Button';
import { resetPassword, signIn } from '../../services/auth';
import { H1 } from '../ResetPassword/styles';
import { Container, Form } from './styles';
import { EnumRoutes } from '../../store/types';
import camelcaseKeys from 'camelcase-keys';
import PasswordInput from 'components/PasswordInput';

interface Data {
  name: string;
  email: string;
  key: string;
  fromPortal?: string;
}

export default function NewPassword(): ReactElement {
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [data, setData] = useState<Data>({});
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const history = useHistory();
  const { addToast } = useToasts();

  useEffect(() => {
    const queryData = camelcaseKeys(queryString.parse(history.location.search));
    setData(queryData);
  }, []);

  async function handleSignIn(e: FormEvent) {
    e.preventDefault();

    if (!password) {
      addToast('Senha obrigatória', { appearance: 'warning' });

      return;
    }

    if (password.length < 8) {
      addToast('A nova senha deve conter pelo menos 8 caracteres', {
        appearance: 'warning',
      });

      return;
    }

    if (password !== confirmation) {
      addToast('A confirmação de senha não corresponde à nova senha', {
        appearance: 'warning',
      });

      return;
    }

    setLoading(true);

    await resetPassword({
      password,
      email: data.email,
      tempPassword: data.key,
    });

    if (data.fromPortal === '1') {
      const resErrors: string[] = await signIn({
        email: data.email,
        senha: password,
      });

      if (!resErrors.length) {
        history.push(EnumRoutes.HOME);
      }
    }

    setDone(true);
    setLoading(false);
  }

  if (done) {
    return (
      <Container>
        <Form>
          <H1>Senha definida com sucesso!</H1>
          <p style={{ fontSize: '1.6rem' }}>
            Seu acesso agora está liberado nos formulários e aplicativos da
            plataforma GescorpGO.
          </p>
        </Form>
      </Container>
    );
  }

  return (
    <Container>
      <Form onSubmit={handleSignIn}>
        <H1>{`Olá ${data.name}`}</H1>

        <p style={{ fontSize: '1.6rem', marginBottom: '1rem' }}>
          Favor definir sua nova senha
        </p>

        <PasswordInput
          type="password"
          placeholder="Nova senha"
          onChange={e => setPassword(e.target.value)}
        />

        <PasswordInput
          placeholder="Confirmar senha"
          onChange={e => setConfirmation(e.target.value)}
        />

        <Button primary text="Salvar" isLoading={loading} type="submit" />
      </Form>
    </Container>
  );
}
