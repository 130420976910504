import Button from 'components/Button';
import { FaCamera } from 'react-icons/fa';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
`;

export const WrapperCam = styled.div`
  width: 90%;
  height: 90%;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ba9494;
  margin: 2rem auto;
  border: 3px solid #009688;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Cam = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Canvas = styled.canvas`
  display: none;
`;

export const ButtonPicture = styled(Button)`
  gap: 0.5rem;
  margin: 4rem;
`;

export const IconCam = styled(FaCamera)`
  font-size: 1.3rem;
`;
