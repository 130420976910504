import React, { ReactElement } from 'react';
import { formatNumber } from '../../../../../../utils/dataFunctions';
import { AvancoBarChartWrapper, StyledDiv } from './styles';

interface Props {
  value: any;
  row: any;
  col: any;
  maxValue: boolean;
}

const percentFormatter = formatNumber({ style: 'percent', decimalPlaces: 2 });

export default function AvancoBarChart(props: Props): ReactElement {
  const {
    value: valueRaw,
    col: { cellTypeParams, key },
    maxValue,
  } = props;

  if (!valueRaw) {
    return <></>;
  }
  const parsedValue = JSON.parse(valueRaw);
  const { bgColor } = parsedValue.params;

  let value = 0;
  let label = '';

  if (typeof parsedValue === 'object') {
    value = parseFloat(parsedValue.value);
    label = cellTypeParams?.hideLabel
      ? ''
      : percentFormatter(parsedValue.value);
  } else {
    value = parseFloat(parsedValue);
    label = percentFormatter(cellTypeParams?.hideLabel ? '' : parsedValue);
  }

  const barWidth = maxValue ? (value / maxValue) * 50 : value * 50;
  const barColor = bgColor;

  const barStyle = {
    width: `${barWidth}%`,
    backgroundColor: barColor,
    zIndex: '10',
  };
  const barShadowStyle = {
    position: 'absolute',
    width: `50%`,
    backgroundColor: '#DDDDDD',
    height: '13px',
    left: '0px',
    padding: '0',
  };
  const labelStyle = {};

  barStyle.left = '0';
  barStyle.height = '13px';
  labelStyle.left = '50%';
  labelStyle.paddingLeft = '4px';

  const content = (
    <>
      <div className="bar" style={barStyle} />
      {key !== 'indicadorMensal' && (
        <div className="barShadow" style={barShadowStyle} />
      )}
      <span className="label" style={labelStyle}>
        {label}
      </span>
    </>
  );

  return <StyledDiv className="celltype-barchart">{content}</StyledDiv>;
}
