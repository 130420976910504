import React from 'react';
import { StyledDiv } from './styles';

export default function DefaultType(props) {
  const {
    col: {
      cellTypeParams: { content },
    },
  } = props;

  return (
    <StyledDiv className="celltype-custom-content">{content(props)}</StyledDiv>
  );
}
