import {
  ReportQueue,
  ReportActionTypes,
  DatasetParams,
  SET_LAYOUT_ID,
  SET_GET_DATASET_DATA,
  SET_PRINTER_SETTINGS,
  SET_REPORT_QUEUE,
  REMOVE_REPORT_QUEUE,
  SET_REPORT_QUEUE_LIST,
} from './types';

export function setLayoutId(id: number): ReportActionTypes {
  return {
    type: SET_LAYOUT_ID,
    payload: id,
  };
}

export function setGetDatasetData(data: DatasetParams[]): ReportActionTypes {
  return {
    type: SET_GET_DATASET_DATA,
    payload: data,
  };
}

export function setPrinterSettings(data: object): ReportActionTypes {
  return {
    type: SET_PRINTER_SETTINGS,
    payload: data,
  };
}

export function setReportQueue(data: ReportQueue): ReportActionTypes {
  return {
    type: SET_REPORT_QUEUE,
    payload: data,
  };
}

export function removeReportQueue(uuid: string): ReportActionTypes {
  return {
    type: REMOVE_REPORT_QUEUE,
    uuid,
  };
}

export function setReportQueueList(data: ReportQueue[]): ReportActionTypes {
  return {
    type: SET_REPORT_QUEUE_LIST,
    payload: data,
  };
}
