import { EnumProfile } from '../types';
import {
  UserActionTypes,
  SET_USER_INFO,
  SET_USER_PROFILE_MODULE,
  SET_USER_ACTIONS,
} from './types';

export function setUserInfo(userInfo: object): UserActionTypes {
  return {
    type: SET_USER_INFO,
    payload: userInfo,
  };
}

export function setUserProfileModule(id: EnumProfile | null): UserActionTypes {
  return {
    type: SET_USER_PROFILE_MODULE,
    payload: id,
  };
}

export function setUserActions(actions: string): UserActionTypes {
  return {
    type: SET_USER_ACTIONS,
    payload: actions,
  };
}
