import React, { ReactElement } from 'react';
import { Style } from '../../store/types';
import { StyledSpan } from './styles';

interface Props {
  style?: Style;
  danger?: boolean;
  success?: boolean;
  text: string;
}

export default function Span(props: Props): ReactElement {
  const { text, danger, success, style } = props;

  return <StyledSpan style={style}>{text}</StyledSpan>;
}
