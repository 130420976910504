import React, { useEffect, useState } from 'react';

import useUser from 'hooks/use-user';

import showAlert from 'components/Alert';
import Spinner from 'components/Spinner';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import {
  cancel as cancelApproval,
  create as createApproval,
  update as updateApproval,
} from 'services/approval';
import { getSqlResponse } from 'services/dataset';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { Approval } from 'store/types';
import Item from './components/Item';
import { Container, SpinnerWrapper } from './styles';
import { ApprovalLevel, Props, StatusApproval } from './types';
import FormRepprovalOccurrenceModal, {
  FormRepprovalOccurrenceModalParams,
} from './components/FormRepprovalOccurrenceModal';
import FormReplyOccurrenceModal from 'components/Widgets/MultipleApproval/components/FormReplyOccurrenceModal';
import FormRepprovalReevaluateModal from './components/FormRepprovalReevaluateModal';

export default function MultipleApproval(props: Props) {
  const user = useUser();
  const dispatch = useDispatch();

  const { addToast } = useToasts();

  const [approvalsList, setApprovalsList] = useState<ApprovalLevel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { idReferencia } = props;

  useEffect(() => {
    loadApprovalsList();
  }, [user.idUsuario, idReferencia]);

  async function loadApprovalsList() {
    const { idTipoAprovacao, setHasApprovalConfig } = props;

    const { idEmpresa, idUsuario } = user!;

    if (!idReferencia || !idUsuario) {
      if (setHasApprovalConfig) {
        setHasApprovalConfig(false);
      }

      setIsLoading(false);

      return;
    }

    const { idContrato, origem, idDataset } = props;

    const approvalsResponse = await getSqlResponse<ApprovalLevel[]>(idDataset, {
      idEmpresa,
      idUsuario,
      idReferencia,
      idContrato,
      origem,
      idTipoAprovacao: idTipoAprovacao || 0,
    });

    setApprovalsList(approvalsResponse);

    if (setHasApprovalConfig) {
      setHasApprovalConfig(!!approvalsResponse.length);
    }

    setIsLoading(false);

    const { onAfterLoad } = props;

    if (onAfterLoad) {
      onAfterLoad(approvalsResponse);
    }
  }

  function afterApproval() {
    const { onAfterApproval } = props;

    if (onAfterApproval) {
      onAfterApproval();
    }
  }

  async function handleApproval(approvalData: ApprovalLevel) {
    const { onBeforeApproval } = props;

    if (onBeforeApproval) {
      const allowContinue = await onBeforeApproval();

      if (!allowContinue) return;
    }

    dispatch(showModal(ModalName.LOADING, { text: 'Salvando' }));

    const { idContrato, origem } = props;
    const { idEmpresa, idUsuario } = user!;

    const approval = {
      origem,
      idContrato,
      idReferencia,
      idEmpresa,
      idAprovador: idUsuario!,
      idNivelAprovacao: approvalData.idNivelAprovacao,
      observacao: approvalData.observacaoOriginal,
      status: approvalData.status,
      dtAprovacao: '',
    } as Approval;

    await createApproval(approval);

    await loadApprovalsList();

    addToast('Salvo com sucesso', { appearance: 'success' });

    dispatch(closeModal(ModalName.LOADING));

    afterApproval();
  }

  async function handleEdit(approvalData: Partial<ApprovalLevel>) {
    dispatch(showModal(ModalName.LOADING, { text: 'Salvando' }));

    const newApprovalData: Partial<Approval> = {
      id: approvalData.idAprovacao,
      observacao: approvalData.observacaoOriginal || '',
      status: approvalData.status,
    };

    if (approvalData.dtAprovacao) {
      newApprovalData.dtAprovacao = approvalData.dtAprovacao;
    }

    await updateApproval(newApprovalData);

    await loadApprovalsList();

    addToast('Observação salva com sucesso', { appearance: 'success' });

    dispatch(closeModal(ModalName.LOADING));
  }

  function handleRepproval(approvalData: ApprovalLevel) {
    return async () => {
      const {
        idContrato,
        origem,

        onBeforeApproval,
        idTipo,
      } = props;

      if (onBeforeApproval) {
        const allowContinue = await onBeforeApproval();

        if (!allowContinue) return;
      }

      dispatch(
        showModal<FormRepprovalOccurrenceModalParams>(
          ModalName.FORM_REPPROVAL_OCCURRENCE_MODAL,
          {
            origem,
            idContrato,
            approvalData,
            referenciaAprovacao: idReferencia,
            idTipo,
            onAfterSave: () => {
              loadApprovalsList();
              afterApproval();
            },
          },
        ),
      );
    };
  }

  function handleCancelApproval(approvalData: ApprovalLevel) {
    return () => {
      if (!approvalData) {
        return;
      }

      const handleAfterCancell = async () => {
        await cancelApproval(approvalData.idAprovacao);
        await loadApprovalsList();
      };

      const onConfirm = async () => {
        if (approvalData.status === StatusApproval.APPROVED) {
          dispatch(showModal(ModalName.LOADING, { text: 'Cancelando' }));

          await handleAfterCancell();

          dispatch(closeModal(ModalName.LOADING));
          addToast('Cancelado com sucesso', { appearance: 'success' });

          afterApproval();

          return;
        }

        const { idContrato, origem, idTipo } = props;

        dispatch(
          showModal<FormRepprovalOccurrenceModalParams>(
            ModalName.FORM_REPPROVAL_OCCURRENCE_MODAL,
            {
              idContrato,
              approvalData,
              referenciaAprovacao: idReferencia,
              origem: origem,
              isCancellation: true,
              idTipo,
              onAfterCancell: handleAfterCancell,
            },
          ),
        );
      };

      showAlert({
        title: 'Cancelar?',
        actions: [
          {
            text: 'Não',
          },
          {
            text: 'Sim',
            onClick: onConfirm,
          },
        ],
      });
    };
  }

  function handleEditOccurrence(
    approvalData: ApprovalLevel,
    onReloadReplyList: () => Promise<void>,
  ) {
    const { idContrato, origem, idTipo } = props;

    dispatch(
      showModal<FormRepprovalOccurrenceModalParams>(
        ModalName.FORM_REPPROVAL_OCCURRENCE_MODAL,
        {
          idContrato,
          approvalData,
          referenciaAprovacao: idReferencia,
          origem: origem,
          idTipo,
          onAfterSave: onReloadReplyList,
        },
      ),
    );
  }

  function renderContent() {
    if (isLoading) {
      return (
        <SpinnerWrapper>
          <Spinner size="large" />
        </SpinnerWrapper>
      );
    }

    const { idContrato, disableApproval, approvalButtonText, idTipo } = props;

    return approvalsList.map(approval => (
      <Item
        key={approval.idNivelAprovacao}
        idContrato={idContrato}
        disableApproval={disableApproval}
        approvalButtonText={approvalButtonText}
        approval={approval}
        onRepproval={handleRepproval}
        onEdit={handleEdit}
        onApproval={handleApproval}
        onCancelApproval={handleCancelApproval}
        onEditOccurrence={handleEditOccurrence}
        afterApproval={afterApproval}
        idTipo={idTipo}
        afterReply={loadApprovalsList}
      />
    ));
  }

  if (!idReferencia) {
    return null;
  }

  return (
    <Container>
      <h2>Aprovações</h2>

      {renderContent()}

      <FormRepprovalOccurrenceModal />
      <FormReplyOccurrenceModal />
      <FormRepprovalReevaluateModal />
    </Container>
  );
}
