import { createStore, applyMiddleware } from 'redux';
// import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const middlewares = [thunk];

// if (import.meta.env.VITE_NODE_ENV === 'development') {
// middlewares.push(logger);
// }

const store = (function configureStore() {
  return createStore(rootReducer, applyMiddleware(...middlewares));
})();

export type DispatchType = typeof store.dispatch;

export default store;
