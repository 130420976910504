import { CLEAR_TITLE, NavbarActionTypes, SET_TITLE } from './types';

export function setTitle(title: string): NavbarActionTypes {
  return {
    type: SET_TITLE,
    payload: title,
  };
}

export function clearTitle(): NavbarActionTypes {
  return {
    type: CLEAR_TITLE,
  };
}
