import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.afonsofranca.fisico.gerencial' */
  ),
);
const FisicoGerencialCockpit = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.afonsofranca.fisico.cockpit' */
  ),
);
const LinhaDeBalanco = lazy(() =>
  import(
    './layouts/fisico/linhaDeBalanco' /* webpackChunkName: 'afonsofranca.fisico.linha-de-balanco' */
  ),
);
const FisicoPortfolio = lazy(() =>
  import(
    './layouts/fisico/portfolio' /* webpackChunkName: 'afonsofranca.fisico.portfolio' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/afonsofranca/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/afonsofranca/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/afonsofranca/fisico/cockpit"
    component={FisicoGerencialCockpit}
    path="/relatorios/afonsofranca/fisico/cockpit"
  />,
  <PrivateRoute
    key="/relatorios/afonsofranca/fisico/linha-de-balanco"
    component={LinhaDeBalanco}
    path="/relatorios/afonsofranca/fisico/linha-de-balanco"
  />,
  <PrivateRoute
    key="/relatorios/afonsofranca/fisico/portfolio"
    component={FisicoPortfolio}
    path="/relatorios/afonsofranca/fisico/portfolio"
  />,
];
