import { Family, ServiceManager } from '../types';

export interface SheetsManagerState {
  services: ServiceManager[];
  selectedServiceFamilyN1: Family | null;
  selectedServiceFamilyN2: Family | null;
}

export const SET_SERVICES = 'sheet-manager/SET_SERVICES';
export const CLEAR_SERVICES = 'sheet-manager/CLEAR_SERVICES';
export const ADD_SERVICE = 'sheet-manager/ADD_SERVICE';

interface SetServicesAction {
  type: typeof SET_SERVICES;
  payload: ServiceManager[];
}

interface ClearServicesAction {
  type: typeof CLEAR_SERVICES;
}

export const SET_SERVICE_FAMILY_N1 = 'sheet-manager/SET_SERVICE_FAMILY_N1';
export const SET_SERVICE_FAMILY_N2 = 'sheet-manager/SET_SERVICE_FAMILY_N2';

interface SetServiceFamilyN1Action {
  type: typeof SET_SERVICE_FAMILY_N1;
  family: Family | null;
}

interface SetServiceFamilyN2Action {
  type: typeof SET_SERVICE_FAMILY_N2;
  family: Family | null;
}

export type SheetsManagerActionTypes =
  | SetServicesAction
  | ClearServicesAction
  | SetServiceFamilyN1Action
  | SetServiceFamilyN2Action;
