import styled from 'styled-components';

export const Container = styled.div<{ isSourceApp: boolean }>`
  padding: 1rem;
  background-color: #636363;
  color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  span {
    font-size: 1.2rem;
  }

  p {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
  }
`;
