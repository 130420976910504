import styled, { css } from 'styled-components';
import { GoCheck, GoTriangleUp, GoTriangleDown, GoDash } from 'react-icons/go';
import { MdClear } from 'react-icons/md';
import { BsExclamation } from 'react-icons/bs';
import { PiPaperclipDuotone } from 'react-icons/pi';

const RoundedSize = css`
  border-radius: 50%;
  width: 1.4rem;
  height: 1.4rem;
`;

export const Container = styled.div<{ color: string }>`
 ${RoundedSize}
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const GreenCircle = styled.div`
  ${RoundedSize}
  border: 2px solid #009688;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyContainer = styled.div`
  ${RoundedSize}
  border: 1px solid #333;
`;

export const HalfMoon = styled.div`
  ${RoundedSize}
  border: 1px solid #333;
  border-left: 0.7rem solid #333;
`;

export const CheckIconStyled = styled(GoCheck)`
  color: #fff;
  font-size: 1.2rem;
`;

export const XIconStyled = styled(MdClear)`
  color: #fff;
  font-size: 1.2rem;
`;

export const DashIconStyled = styled(GoDash)`
  color: #333;
  font-size: 1.2rem;
`;

export const AlertIconStyled = styled(BsExclamation)`
  color: #f00;
  font-size: 1.5rem;
`;

export const StyledUpArrow = styled(GoTriangleUp)`
  color: ${({ reverseColor }) => (reverseColor ? '#ff5a45' : '#428d34')};
  font-size: 2.5rem;
`;

export const StyledDownArrow = styled(GoTriangleDown)`
  color: ${({ reverseColor }) => (reverseColor ? '#428d34' : '#ff5a45')};
  font-size: 2.5rem;
`;
export const PaperClip = styled(PiPaperclipDuotone)`
  color: #fd3500;
  border-radius: 50%;
  font-size: 2rem;
`;
