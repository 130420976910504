import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const Gerencial = lazy(() =>
  import(
    './layouts/gerencial' /* webpackChunkName: 'relatorios.mv.gerencial' */
  ),
);
const Obras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'relatorios.mv.financeiro.obras' */
  ),
);
const Portfolio = lazy(() =>
  import(
    './layouts/financeiro/portfolio' /* webpackChunkName: 'relatorios.mv.financeiro.portfolio' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/mv/gerencial"
    component={Gerencial}
    path="/relatorios/mv/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/mv/financeiro/obras"
    component={Obras}
    path="/relatorios/mv/financeiro/obras"
  />,
  <PrivateRoute
    key="/relatorios/mv/financeiro/portfolio"
    component={Portfolio}
    path="/relatorios/mv/financeiro/portfolio"
  />,
];
