import { ReactElement } from 'react';
import { EnumActions, EnumProfile } from '../../store/types';
import useUser from 'hooks/use-user';

interface Props {
  action: EnumActions;
  children: ReactElement;
  rules: EnumProfile[];
}

export function hasPermission(
  userActions: string | null,
  action: EnumActions,
  idPerfilModulo: number | null,
  rules: EnumProfile[],
) {
  if (!userActions) {
    if (!idPerfilModulo || !rules?.length) return false;

    return rules.includes(idPerfilModulo);
  }

  const actionsArr = userActions.split(',').map(Number);

  return actionsArr.includes(action);
}

export default function WithPermission(props: Props) {
  const { action, children, rules } = props;
  const user = useUser();
  const { idAcoes, idPerfilModulo } = user;

  if (!hasPermission(idAcoes, action, idPerfilModulo, rules)) return null;

  return children;
}
