import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const DashboardMateriais = lazy(() =>
  import(
    './layouts/fvm/materiais' /* webpackChunkName: 'relatorios.mitre.fvm.dashboard-materiais' */
  ),
);
const DashboardGerencial = lazy(() =>
  import(
    './layouts/gerencial' /* webpackChunkName: 'relatorios.nortis.gerencial' */
  ),
);
const DashboardPlanejamentoMensal = lazy(() =>
  import(
    './layouts/planejamentoMensal' /* webpackChunkName: 'relatorios.nortis.planejamento-mensal' */
  ),
);
const DashboardPlanejamentoExterno = lazy(() =>
  import(
    './layouts/planejamentoExterno' /* webpackChunkName: 'relatorios.nortis.planejamento-externo' */
  ),
);
const DashboardConcretagem = lazy(() =>
  import(
    './layouts/concretagem' /* webpackChunkName: 'relatorios.nortis.concretagem' */
  ),
);
const DashboardFisicoFVS = lazy(() =>
  import(
    './layouts/fisicoFvs' /* webpackChunkName: 'relatorios.nortis.fisico-fvs' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/nortis/fvm/dashboard-materiais"
    component={DashboardMateriais}
    path="/relatorios/nortis/fvm/dashboard-materiais"
  />,
  <PrivateRoute
    key="/relatorios/nortis/gerencial"
    component={DashboardGerencial}
    path="/relatorios/nortis/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/nortis/planejamento-mensal"
    component={DashboardPlanejamentoMensal}
    path="/relatorios/nortis/planejamento-mensal"
  />,
  <PrivateRoute
    key="/relatorios/nortis/planejamento-externo"
    component={DashboardPlanejamentoExterno}
    path="/relatorios/nortis/planejamento-externo"
  />,
  <PrivateRoute
    key="/relatorios/nortis/concretagem"
    component={DashboardConcretagem}
    path="/relatorios/nortis/concretagem"
  />,
  <PrivateRoute
    key="/relatorios/nortis/fisico-fvs"
    component={DashboardFisicoFVS}
    path="/relatorios/nortis/fisico-fvs"
  />,
];
