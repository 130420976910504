/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
// import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { routes as layoutRoutes } from './layouts/routes';
import { routes as afonsofrancaRoutes } from './clients/afonsofranca/routes';
import { routes as almaaRoutes } from './clients/almaa/routes';
import { routes as alphavilleRoutes } from './clients/alphaville/routes';
import { routes as artesanoRoutes } from './clients/artesano/routes';
import { routes as bacabaRoutes } from './clients/bacaba/routes';
import { routes as buenonettoRoutes } from './clients/buenonetto/routes';
import { routes as brookfieldRoutes } from './clients/brookfield/routes';
import { routes as canopusRoutes } from './clients/canopus/routes';
import { routes as construcompanyRoutes } from './clients/construcompany/routes';
import { routes as costaFeitosaRoutes } from './clients/costaFeitosa/routes';
import { routes as cteRoutes } from './clients/cte/routes';
import { routes as curyRoutes } from './clients/cury/routes';
import { routes as duoRoutes } from './clients/duo/routes';
import { routes as dialogoRoutes } from './clients/dialogo/routes';
import { routes as ekkoRoutes } from './clients/ekko/routes';
import { routes as forcasaRoutes } from './clients/forcasa/routes';
import { routes as gafisaRoutes } from './clients/gafisa/routes';
import { routes as gescorpgoRoutes } from './clients/gescorpgo/routes';
import { routes as grifeRoutes } from './clients/grife/routes';
import { routes as kallasRoutes } from './clients/kallas/routes';
import { routes as kmpgRoutes } from './clients/kmpg/routes';
import { routes as ldRoutes } from './clients/ld/routes';
import { routes as linkaRoutes } from './clients/linka/routes';
import { routes as macRoutes } from './clients/mac/routes';
import { routes as matecRoutes } from './clients/matec/routes';
import { routes as metrocasaRoutes } from './clients/metrocasa/routes';
import { routes as mitreRoutes } from './clients/mitre/routes';
import { routes as mpdRoutes } from './clients/mpd/routes';
import { routes as mvRoutes } from './clients/mv/routes';
import { routes as neoplanRoutes } from './clients/neoplan/routes';
import { routes as nortisRoutes } from './clients/nortis/routes';
import { routes as outRoutes } from './clients/out/routes';
import { routes as p4Routes } from './clients/p4/routes';
import { routes as passarelliRoutes } from './clients/passarelli/routes';
import { routes as planemRoutes } from './clients/planem/routes';
import { routes as rgRoutes } from './clients/rg/routes';
import { routes as rmrRoutes } from './clients/rmr/routes';
import { routes as ribeiroCaramRoutes } from './clients/ribeiroCaram/routes';
import { routes as rocontecRoutes } from './clients/rocontec/routes';
import { routes as rsmtRoutes } from './clients/rsmt/routes';
import { routes as ryazbekRoutes } from './clients/ryazbek/routes';
import { routes as r2xRoutes } from './clients/r2x/routes';
import { routes as samaarRoutes } from './clients/samaar/routes';
import { routes as setinRoutes } from './clients/setin/routes';
import { routes as skrRoutes } from './clients/skr/routes';
import { routes as tendaRoutes } from './clients/tenda/routes';
import { routes as thaRoutes } from './clients/tha/routes';
import { routes as trisulRoutes } from './clients/trisul/routes';
import { routes as vibraRoutes } from './clients/vibra/routes';
import { routes as vitraRoutes } from './clients/vitra/routes';
import { routes as yunyRoutes } from './clients/yuny/routes';

export function reportRoutes() {
  // const companyId = 2;
  const companyId = useSelector((state: RootState) => state.user.idEmpresa);
  const menuRoutes: any = useSelector(
    (state: RootState) => state.menu.menuRoutes,
  );

  const allReportsRoutes: any = [
    ...layoutRoutes,
    ...afonsofrancaRoutes,
    ...artesanoRoutes,
    ...almaaRoutes,
    ...alphavilleRoutes,
    ...bacabaRoutes,
    ...buenonettoRoutes,
    ...brookfieldRoutes,
    ...canopusRoutes,
    ...construcompanyRoutes,
    ...costaFeitosaRoutes,
    ...cteRoutes,
    ...curyRoutes,
    ...dialogoRoutes,
    ...duoRoutes,
    ...ekkoRoutes,
    ...forcasaRoutes,
    ...gafisaRoutes,
    ...gescorpgoRoutes,
    ...grifeRoutes,
    ...kallasRoutes,
    ...kmpgRoutes,
    ...ldRoutes,
    ...linkaRoutes,
    ...macRoutes,
    ...matecRoutes,
    ...metrocasaRoutes,
    ...mitreRoutes,
    ...mpdRoutes,
    ...mvRoutes,
    ...neoplanRoutes,
    ...nortisRoutes,
    ...outRoutes,
    ...p4Routes,
    ...passarelliRoutes,
    ...planemRoutes,
    ...rgRoutes,
    ...rmrRoutes,
    ...ribeiroCaramRoutes,
    ...rocontecRoutes,
    ...rsmtRoutes,
    ...ryazbekRoutes,
    ...r2xRoutes,
    ...samaarRoutes,
    ...setinRoutes,
    ...skrRoutes,
    ...tendaRoutes,
    ...thaRoutes,
    ...trisulRoutes,
    ...vibraRoutes,
    ...vitraRoutes,
    ...yunyRoutes,
  ];

  if (companyId === 2) {
    return allReportsRoutes;
  }

  const userRoutes = allReportsRoutes.filter(
    route => menuRoutes.indexOf(route.props.path) > -1,
  );

  return userRoutes;
}
