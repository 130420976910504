import Button from 'components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Form = styled.form`
  background: #fff;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 3rem);
  max-width: 45rem;
  border-radius: ${({ theme }) => theme.radius.large}px;

  @media only screen and (max-width: 480px) {
    padding: 5rem 2rem;
  }

  input {
    height: 5.2rem;
    width: 100%;
    padding: 0 1.5rem;
    color: #777;
    font-size: 1.8rem;
    border: 1px solid #ddd;
    margin-bottom: 1.5rem;
    font-style: inherit;
    letter-spacing: 0.08rem;
    border-radius: ${({ theme }) => theme.radius.medium}px;

    &::placeholder {
      color: #999;
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.green};
      outline: 0 none;
    }
  }

  .primary-button {
    height: 4.6rem;
    width: 100%;
    border-radius: ${({ theme }) => theme.radius.medium}px;
    font-size: 1.6rem;
    background: ${({ theme }) => theme.colors.green};
    color: #fff;
    border: none;
    letter-spacing: 0.08rem;
    font-weight: 600;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:active {
      background-color: #4cada6;
    }
  }

  img {
    width: 25rem;
    margin-bottom: 1rem;
  }
`;

export const WrapErrors = styled.div`
  margin-bottom: 1.5rem;
  align-self: flex-start;
`;

export const ForgotPassword = styled.a`
  font-size: 1.3rem;
  cursor: pointer;
  margin-top: 1rem;
`;

export const SocialMediaOptionsWrapper = styled.div`
  width: 100%;
  margin-top: 1.5rem;
`;

export const SocialLabel = styled.h3`
  font-weight: normal;
  margin-top: 1.5rem;
`;

export const ButtonAzure = styled(Button)`
  margin-top: 0.5rem;
  color: #333;
  background-color: ${({ theme }) => theme.colors.grey20};
  border-color: ${({ theme }) => theme.colors.grey20};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey30};
    border-color: ${({ theme }) => theme.colors.grey30};
  }
`;
