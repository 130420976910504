import styled from 'styled-components';

export const StyledButton = styled.button`
  padding: 0.4rem 1rem;
  margin: 0.3rem;
  border-radius: 2px;
  color: #333;
  letter-spacing: 0.08rem;
  cursor: pointer;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #333;

  &:focus {
    outline: 0;
  }

  span {
    font-size: 1.4rem;
  }

  &:hover {
    box-shadow: 3px 4px 5px rgb(0 0 0 / 20%);
    transform: translateY(-1px);
  }
`;
