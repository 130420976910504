import { useEffect, useState } from 'react';

interface Props {
  action?();
  deps?: React.DependencyList | undefined;
}

export default function useFetch<T>(props: Props): [boolean, T] {
  const [isFetching, setIsFetching] = useState(true);
  const [response, setResponse] = useState<T | null>(null);

  const { action, deps } = props;

  useEffect(() => {
    (async () => {
      if (action) {
        const res = (await action()) as T;
        setResponse(res);
        setIsFetching(false);
      }
    })();

    return () => {
      setResponse(null);
    };
  }, deps || []);

  return [isFetching, response as T];
}
