import React, { useEffect, useState } from 'react';
import useQuery from 'hooks/use-query';
import TopicOverview from './components/TopicOverview';
import BarDrillDown from './components/BarDrillDown';
import { Container } from './styles';
import Fishbone from 'pages/Reports/components/charts/Fishbone';
import { saveAdonisToken } from 'services/auth';
import { jsonParse } from 'utils/common';

interface QueryParameters {
  type: string;
  width: number;
  height: number;
  token: string;
  idDataset: number;
  datasetParams?: object;
}

export default function GraphicPrint() {
  const dataUrl = useQuery<QueryParameters>();

  const [isLoading, setIsLoading] = useState(true);

  const { type, width, token, idDataset } = dataUrl;
  let { datasetParams } = dataUrl;

  if (datasetParams) {
    datasetParams = jsonParse(datasetParams);
  }

  useEffect(() => {
    saveAdonisToken(token);
    setIsLoading(false);
  }, [token]);

  function renderTypeGraphic() {
    if (type === 'pizza') {
      return <TopicOverview />;
    }

    if (type === 'barDrillDown') {
      return <BarDrillDown />;
    }

    if (type === 'fishbone') {
      return (
        <Fishbone
          width={width}
          idDataset={Number(idDataset)}
          datasetParams={datasetParams}
        />
      );
    }

    return (
      <Container>
        <h1> Dashboard não encontrado</h1>
      </Container>
    );
  }

  if (isLoading) return <></>;

  return <>{renderTypeGraphic()}</>;
}
