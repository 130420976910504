import { FiTrash2 } from 'react-icons/fi';
import { MdEdit } from 'react-icons/md';
import styled from 'styled-components';

export const IconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
`;

export const ContainerCard = styled.div`
  padding: 1rem;
  border: 1px solid #ddd;

  border-radius: ${({ theme }) => theme.radius.medium}px;

  .text {
    font-weight: bold;
  }

  button {
    gap: 0.5rem;
  }
`;

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerStatus = styled.span`
  background-color: green;
  width: 8rem;
  height: 1.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  gap: 0.5rem;
`;

export const EditIcon = styled(MdEdit)`
  font-size: 1.8rem;
  color: #7d7d7d;
  cursor: pointer;

  &:hover {
    color: #333;
  }
`;

export const LabelList = styled.p`
  margin: 0.6rem 0;
`;

export const RemoveIcon = styled(FiTrash2)`
  font-size: 1.8rem;
  color: #7d7d7d;

  cursor: pointer;

  &:hover {
    color: #333;
  }
`;

export const SignedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
