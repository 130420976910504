import Select from 'components/ReactSelect';
import React from 'react';
import { OptionsType } from 'store/types';
import { Occurrence } from '../../types';
import { Container } from './styles';
import useFetch from 'hooks/use-fetch';
import { Label } from 'styles/form';
import { getSqlResponseAdonis } from 'services/dataset';
import useUser from 'hooks/use-user';

interface Props {
  idContrato: number;
  setFormData(formData: Occurrence): void;
  formData: Occurrence;
}

export default function SelectArea(props: Props) {
  const { idContrato, formData, setFormData } = props;
  const user = useUser();

  const [isFetching, areas = []] = useFetch<OptionsType[]>({
    action: fetchOptions,
    deps: [user.idEmpresa, idContrato],
  });

  function fetchOptions() {
    if (!user.idEmpresa) {
      return;
    }

    return getSqlResponseAdonis(2653, {
      idContrato,
      idTipo: 170,
      idEmpresa: user.idEmpresa,
    });
  }

  function handleSetOption(option: OptionsType[]) {
    const newOption = option ? option : [];

    const normalizedOption = newOption.map(item => {
      const currentPreviousArea =
        formData.previousAreas?.find(area => area.value === item.value) || {};

      return {
        ...item,
        ...currentPreviousArea,
      };
    });

    setFormData({
      ...formData,
      areas: normalizedOption,
    });
  }

  function getSelectedValue(): OptionsType[] | undefined {
    const { areas } = formData;

    return areas;
  }

  return (
    <Container>
      <Label>Enviar para</Label>

      <Select
        name="areas"
        value={getSelectedValue()}
        isLoading={isFetching}
        options={areas}
        height="4rem"
        placeholder="Selecione um contrato"
        onChange={handleSetOption}
        isMulti={true}
        isClearable
      />
    </Container>
  );
}
