import ButtonComponent from 'components/Button';
import { IoIosArrowDown } from 'react-icons/io';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Button = styled(ButtonComponent)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  min-width: 14.4rem;
`;

export const ArrowIcon = styled(IoIosArrowDown)`
  font-size: 1.8rem;
`;

export const PopupWrapper = styled.div`
  position: absolute;
  background-color: #fff;
  top: 4.5rem;
  left: 0;
  z-index: 10;
  border-radius: ${({ theme }) => theme.radius.medium}px;
  padding: 0.6rem 0;

  box-shadow: 0 3px 20px rgba(89, 105, 129, 0.3), 0 1px 2px rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(89, 105, 129, 0.1);

  ul {
    li {
      font-size: 1.4rem;
      white-space: nowrap;
      cursor: pointer;
      padding: 0.6rem 1.4rem;

      &:hover {
        background-color: #eee;
      }
    }
  }
`;
