import React from 'react';
import Spinner from '../Spinner';

import { Container, Text, Card } from './styles';
import Modal from 'components/Modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    background: 'transparent',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 9999999,
  },
};

interface Props {
  text: string;
}
export default function LoadingSuspense(props: Props) {
  const { text } = props;

  return (
    <Modal isOpen={true} style={customStyles}>
      <Container>
        <Card>
          <Spinner size="large" color="#fff" />
          <Text>{text || 'Carregando'}</Text>
        </Card>
      </Container>
    </Modal>
  );
}
