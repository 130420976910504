import Button from 'components/Button';
import Input from 'components/Input';
import Modal from 'components/Modal';
import { FormikProps, useFormik } from 'formik';
import useModal from 'hooks/use-modal';
import { useAtomValue } from 'jotai';
import { formDataAtom, urlParamsAtom } from 'pages/DocSign/atoms';
import { GoBackIcon, IconCheck } from 'pages/DocSign/styles';
import { getModalStyle } from 'pages/DocSign/utils';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { updateOrCreate } from 'services/signature';
import { closeModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { ModalContainer, ModalHeader } from 'styles/modal';
import { generateUUID } from 'utils/common';
import FieldSelect from './components/FieldSelect';
import { Container, Footer, InputsWrapper } from './styles';
import { FormDataType } from './type';
import { getInitValuesFormik } from './utils';
import { validation } from './validation';
import { ModalTitle } from '../styles';
import { Subscriber } from 'pages/DocSign/types';

export interface SubscriberRegistrationParams {
  data?: Subscriber;
  onSave?: () => Promise<Subscriber[]>;
}

export default function SubscriberRegistration() {
  const { isOpen, params, windowSec } = useModal<SubscriberRegistrationParams>(
    ModalName.SUBSCRIBER_REGISTRATION,
  );

  const { data, onSave } = params;

  const formData = useAtomValue(formDataAtom);
  const urlParams = useAtomValue(urlParamsAtom);

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const formik: FormikProps<FormDataType> = useFormik<FormDataType>({
    initialValues: getInitValuesFormik(data),
    validationSchema: validation,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    initFormik();
  }, [isOpen, data]);

  function initFormik() {
    formik.setErrors({});
    formik.setValues(getInitValuesFormik(data));
  }

  function handleCloseModal() {
    dispatch(closeModal(ModalName.SUBSCRIBER_REGISTRATION));
  }

  async function handleSubmit() {
    try {
      const uuid = generateUUID();

      const newAsign = {
        id: data?.id || 0,
        uuid: uuid,
        nome: formik.values.name?.nome,
        email: formData.email,
        cargo: formik.values.position,
        alcada: formik.values.role!.value,
        idContrato: urlParams!.idContrato,
        idEmpresa: urlParams!.idEmpresa,
        idReferencia: urlParams!.idReferencia,
        uuidArquivo: urlParams!.uuidArquivo,
        uuidReferencia: urlParams!.uuidReferencia,
        idUsuarioCriacao: urlParams!.idUsuarioCriacao,
        tipo: urlParams!.tipo,
      };

      await updateOrCreate(newAsign);

      if (onSave) {
        onSave();
      }

      handleCloseModal();
    } catch {
      addToast('Não foi possível salvar assinante.', {
        appearance: 'error',
      });
    }
  }

  function validateField(name: string) {
    formik.validateField(name);
  }

  return (
    <Modal
      isOpen={isOpen}
      windowSec={windowSec}
      openLikePage
      style={getModalStyle()}
    >
      <ModalContainer width="99vw" height="99vh">
        <ModalHeader>
          <ModalTitle>Cadastro de Assinantes</ModalTitle>
        </ModalHeader>

        <form onSubmit={formik.handleSubmit}>
          <Container>
            <InputsWrapper>
              <FieldSelect
                isCreate
                label="Nome"
                name="name"
                idDataset={4581}
                idEmpresa={urlParams!.idEmpresa!}
                validateField={validateField}
                formik={formik}
              />

              <Input
                label="Cargo"
                name="position"
                type="text"
                onChange={formik.handleChange}
                onBlur={() => validateField('position')}
                value={formik.values.position || ''}
              />
              {formik.errors?.position && (
                <span style={{ color: 'red' }}>{formik.errors?.position}</span>
              )}

              <FieldSelect
                label="Alçada"
                name="role"
                idDataset={4596}
                idEmpresa={urlParams!.idEmpresa!}
                validateField={validateField}
                formik={formik}
              />
            </InputsWrapper>
            <Footer>
              <Button
                onClick={handleCloseModal}
                text="Voltar"
                icon={<GoBackIcon />}
                small
              />
              <Button
                primary
                type="submit"
                text="Salvar"
                icon={<IconCheck />}
                small
              />
            </Footer>
          </Container>
        </form>
      </ModalContainer>
    </Modal>
  );
}
