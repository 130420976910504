import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Modal from 'components/Modal';
import Select from 'components/ReactSelect';
import useModal from 'hooks/use-modal';
import { useAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { getSqlResponse } from 'services/dataset';
import { update } from 'services/occurrence';
import { closeModal, showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { OptionsType } from 'store/types';
import { ButtonClose, ModalHeader, ModalTitle } from 'styles/modal';
import { removeDuplicate, toSelectOptions } from 'utils/common';
import { occurrencesAtom } from '../../atoms';
import { Occurrence } from '../../types';
import {
  Content,
  DropdownWrapper,
  FiltersContainer,
  Footer,
  ModalContainer,
  SelectWrapper,
  SimpleTable,
} from './styles';
import {
  applyFilter,
  orderByOptions,
  orderByTable,
  tableConfig,
} from './utils';

export interface Params {
  idContrato: number;
  idFormulario: number;
  idEmpresa: number;
  onAfterSave(): void;
}

export default function ListOccurrenceDynamicForm() {
  const [occurrences, setOccurrences] = useAtom(occurrencesAtom);
  const [filteredOccurrences, setFilteredOccurrences] = useState<Occurrence[]>(
    [],
  );

  const [responsibleFilter, setResponsibleFilter] = useState<OptionsType>();

  const [origenFilter, setOrigenFilter] = useState<OptionsType>();
  const [orderBy, setOrderBy] = useState<OptionsType | undefined>();

  const { isOpen, params, windowSec } = useModal<Params>(
    ModalName.LIST_OCCURRENCE_DYNAMIC_FORM,
  );

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    if (!isOpen) {
      setOccurrences([]);

      return;
    }

    getOccurrences();
  }, [isOpen]);

  useEffect(() => {
    const newFilteredOccurrences = applyFilter({
      occurrences,
      responsibleFilter,
      origenFilter,
    });

    const orderedOccurrences = orderByTable(newFilteredOccurrences, orderBy);

    setFilteredOccurrences([...orderedOccurrences]);
  }, [occurrences, responsibleFilter, origenFilter, orderBy]);

  async function getOccurrences() {
    dispatch(
      showModal(ModalName.LOADING, { text: 'Carregando ações pendentes' }),
    );

    const { idContrato, idFormulario } = params;

    const occurrencesResponse = await getSqlResponse<Occurrence[]>(1276, {
      idContrato,
      idFormulario,
    });

    setOccurrences(occurrencesResponse);

    if (!occurrencesResponse.length) {
      addToast('Nenhuma pendência encontrada', { appearance: 'info' });
      handleClose();
    }

    dispatch(closeModal(ModalName.LOADING));
  }

  function handleClose() {
    dispatch(closeModal(ModalName.LIST_OCCURRENCE_DYNAMIC_FORM));
  }

  async function handleSave() {
    dispatch(
      showModal(ModalName.LOADING, { text: 'Salvando ações pendentes' }),
    );

    const { idEmpresa, onAfterSave } = params;

    const normalizeReference = (occurrence: Occurrence) => {
      const { idFormulario } = params;

      if (!occurrence.referencia) {
        return idFormulario.toString();
      }

      return occurrence.referencia?.toString() !== idFormulario.toString()
        ? `${occurrence.referencia},${idFormulario}`
        : idFormulario.toString();
    };

    const occurrencesToUpdateReference = occurrences.filter(
      occurrence => occurrence.isSelected,
    );

    const updateOccurrencePromises = occurrencesToUpdateReference.map(
      occurrence => {
        return update({
          idContrato: occurrence.idContrato,
          idEmpresa,
          idOcorrencia: occurrence.idOcorrencia,
          origem: occurrence.origem,
          referencia: normalizeReference(occurrence),
        });
      },
    );

    await Promise.all(updateOccurrencePromises);

    onAfterSave();
    handleClose();

    dispatch(closeModal(ModalName.LOADING));
  }

  function checkIfAtLeastOneItemIsSelected() {
    return !occurrences.some(occurrence => occurrence.isSelected);
  }

  function getResponsibleFilterOptions() {
    const normalizedOccurrencesAsOptions = toSelectOptions(occurrences, {
      label: 'responsavel',
      value: 'responsavel',
    }).filter(option => option.value);

    return removeDuplicate(normalizedOccurrencesAsOptions, 'responsavel');
  }

  function geOrigenFilterOptions() {
    const normalizedOccurrencesAsOptions = toSelectOptions(occurrences, {
      label: 'filtroOrigem',
      value: 'filtroOrigem',
    }).filter(option => option.value);

    return removeDuplicate(normalizedOccurrencesAsOptions, 'value');
  }

  const isButtonDisabled = useMemo(checkIfAtLeastOneItemIsSelected, [
    occurrences,
  ]);

  return (
    <Modal isOpen={isOpen} windowSec={windowSec}>
      <ModalHeader>
        <ButtonClose onClick={handleClose} />
        <ModalTitle>Ações Pendentes</ModalTitle>
      </ModalHeader>

      <ModalContainer width="90vw" height="70vh">
        <Content>
          <FiltersContainer>
            <DropdownWrapper>
              <Dropdown
                value={orderBy?.label || 'Ordenar'}
                options={orderByOptions}
                onChange={setOrderBy}
              />
            </DropdownWrapper>

            <SelectWrapper>
              <Select
                isClearable
                placeholder="Filtro de responsável"
                value={responsibleFilter}
                options={getResponsibleFilterOptions()}
                onChange={setResponsibleFilter}
              />
            </SelectWrapper>

            <SelectWrapper>
              <Select
                isClearable
                placeholder="Filtro de origem"
                value={origenFilter}
                options={geOrigenFilterOptions()}
                onChange={setOrigenFilter}
              />
            </SelectWrapper>
          </FiltersContainer>

          <SimpleTable
            stickyHeader
            data={filteredOccurrences}
            config={tableConfig}
          />
        </Content>

        <Footer>
          <Button
            primary
            text="Selecionar"
            disabled={isButtonDisabled}
            onClick={handleSave}
          />
        </Footer>
      </ModalContainer>
    </Modal>
  );
}
