import React from 'react';
import ChecklistFormatter from 'pages/TableReport/components/Formatters/Checklist';

export default function Checklist(props: any) {
  const {
    row,
    col,
    value,
    col: { cellTypeParams: formatterParams },
  } = props;

  const checklistProps = {
    row: {
      ...row,
      photoParameters: { ...JSON.parse(row.photoParameters) },
      treeDepth: row.level,
    },
    column: {
      formatterParams,
      ...col,
    },
    value,
  };

  return (
    <>
      <ChecklistFormatter {...checklistProps} />
    </>
  );
}
