import apiAdonis from './apiAdonis';

export interface UserProfileModuleType {
  idUsuario: number;
  idEmpresa: number;
  idPerfil: number;
  idModulo: number;
  status: string;
  flagStatus: number;
}

export function updateOrCreate(data: UserProfileModuleType[]) {
  return apiAdonis.post('/usuario-perfil-modulo/update-or-create', data);
}

export function storeByUserTemplate(data: {
  idUsuario: number;
  idUserModelo: number;
}): Promise<boolean> {
  return apiAdonis.post('/usuario-perfil-modulo/store-by-user-template', data);
}
