import React, { useMemo } from 'react';
import Fishbone from 'pages/Reports/components/charts/Fishbone';

interface Props {
  field: {
    datasetId: string;
    chartName: string;
    heightPortal?: number;
    widthPortal?: number;
    datasetParams?: object;
  };
  datasetParams?: { idFormulario?: number };
}
export default function Chart(props: Props) {
  const { field, datasetParams } = props;

  const datasetParamsMemo = useMemo(
    () => ({ ...field.datasetParams, ...datasetParams }),
    [datasetParams],
  );

  if (field.chartName === 'fishbone') {
    return (
      <Fishbone
        idDataset={field.datasetId}
        height={field.heightPortal}
        width={field.widthPortal}
        datasetParams={datasetParamsMemo}
      />
    );
  }

  return <></>;
}
