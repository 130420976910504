import Spinner from 'components/Spinner';
import React, { useState, useEffect, useRef } from 'react';
import { get, ReportQueue, ReportQueueStatus } from 'services/report_queue';
import { useDispatch } from 'react-redux';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import {
  CheckedIcon,
  DonwloadIcon,
  NoCheckedIcon,
  EmailIcon,
} from 'styles/icons';
import { theme } from 'theme';
import { downloadFileByUrl, normalizeFileUrl } from 'utils/file';
import { ReportItemWrapper } from '../styles';
import { getSingleResponseAdonis } from 'services/dataset';

interface ItemProps {
  report: {
    isZip: number;
    nome: string;
    uuid: string;
    uuidGrupo: number | null;
    idContrato: number;
    status: number;
  };
}

export default function Item(props: ItemProps) {
  const [reportResponse, setReportResponse] = useState<ReportQueue | null>(
    null,
  );

  const intervalRef = useRef<NodeJS.Timeout>();
  const isDone = useRef<boolean>();
  const dispatch = useDispatch();

  const { report } = props;

  useEffect(() => {
    intervalRef.current = setInterval(checkReportStatus, 5000);

    return onClearInterval;
  }, [report.uuid]);

  async function checkReportStatus() {
    if (isDone.current) {
      onClearInterval();

      return;
    }

    let response: ReportQueue;

    if (report.uuidGrupo) {
      response = await getSingleResponseAdonis(1631, {
        uuidGrupo: report.uuidGrupo,
      });
    } else {
      response = await get(report.uuid);
    }

    setReportResponse(response);

    isDone.current =
      response.status === ReportQueueStatus.DONE ||
      response.status === ReportQueueStatus.REPORT_SENT ||
      response.status === ReportQueueStatus.ERROR;

    if (
      response.status === ReportQueueStatus.DONE ||
      response.status === ReportQueueStatus.REPORT_SENT
    ) {
      downloadFile(response);
    }
  }

  function downloadFile(response: ReportQueue) {
    if (response.uuidGrupo) {
      downloadFileByUrl(
        normalizeFileUrl(`/${response.referencia}`),
        `${response.nomeZip}.zip`,
      );

      return;
    }

    const extensionFile = response.referencia?.split('.').pop();

    downloadFileByUrl(
      normalizeFileUrl(`/${response.referencia}`),
      `${report.nome}.${extensionFile}`,
    );
  }

  function showSelectEmailsModal() {
    dispatch(
      showModal(ModalName.SELECT_EMAILS, { uuid: reportResponse?.uuid }),
    );
  }

  function onClearInterval() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  }

  function renderIcon() {
    if (reportResponse?.status === ReportQueueStatus.ERROR) {
      return <NoCheckedIcon />;
    }

    if (
      reportResponse?.status === ReportQueueStatus.DONE ||
      reportResponse?.status === ReportQueueStatus.REPORT_SENT
    ) {
      return <CheckedIcon color={theme.colors.primary} />;
    }

    return <Spinner />;
  }

  function renderDownloadAndEmailIcons() {
    if (
      reportResponse?.status === ReportQueueStatus.DONE ||
      reportResponse?.status === ReportQueueStatus.REPORT_SENT
    ) {
      return (
        <>
          <DonwloadIcon onClick={() => downloadFile(reportResponse)} />
          <EmailIcon onClick={showSelectEmailsModal} />
        </>
      );
    }

    return null;
  }

  return (
    <ReportItemWrapper key={report.uuid}>
      {renderIcon()}

      <h4> {report.nome} </h4>

      {renderDownloadAndEmailIcons()}
    </ReportItemWrapper>
  );
}
