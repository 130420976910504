import {
  ModalState,
  ModalActionTypes,
  SHOW_MODAL,
  CLOSE_MODAL,
  SHOW_MODALS,
} from './types';

const initialState: ModalState[] = [];

export default (
  state = initialState,
  action: ModalActionTypes,
): ModalState[] => {
  switch (action.type) {
    case SHOW_MODALS:
      return [...state, ...action.modals];
    case SHOW_MODAL:
      return [
        ...state,
        {
          modal: action.modal,
          params: action.params,
        },
      ];
    case CLOSE_MODAL: {
      if (action.modal) {
        return state.filter(modal => modal.modal !== action.modal);
      }
      return [];
    }
    default:
      return state;
  }
};
