import styled from 'styled-components';

export const TableStyled = styled.table`
  border: 1px solid #dddddd;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 5px;
  }

  td {
    position: relative;
    font-size: 1.4rem;
  }

  tr {
    &:hover {
      background-color: #eee;
    }
  }
`;

export const Th = styled.th<{ size: number }>`
  width: ${({ size }) => size}%;
  font-size: 1.4rem;
`;

export const TableWrapper = styled.div`
  position: relative;
  flex: 1;
`;
