import React from 'react';
import { components } from 'react-select';
import { EditIcon } from 'styles/icons';
import { useDispatch } from 'react-redux';
import { showModal } from 'store/modal/actions';
import { ModalName } from 'store/modal/types';
import { StyleOption } from './styles';
import { useSupplierCreatableSelectContext } from '../../context';

export default function CustomRow(props): JSX.Element {
  const dispatch = useDispatch();

  const { Option } = components;

  const { data } = props;
  const { getData, handleChange } = useSupplierCreatableSelectContext()!;

  function handleUpdate(value: number) {
    dispatch(
      showModal(ModalName.FORM_SUPPLIER, {
        idFornecedor: value,
        onReloadCB: getData,
        onChangeCB: handleChange,
      }),
    );
  }

  return (
    <Option {...props}>
      <StyleOption>
        {data.label}
        {!data.__isNew__ && (
          <EditIcon onClick={() => handleUpdate(data.value)} />
        )}
      </StyleOption>
    </Option>
  );
}
