import TimePicker from 'react-weblineindia-time-picker';
import styled, { css } from 'styled-components';

const Error = css`
  border-color: ${({ theme }) => theme.colors.danger};
`;

export const StyledInput = styled(TimePicker)<{ error?: boolean }>`
  width: 100%;

  .p-datepicker {
    min-width: 0;
  }

  .p-inputtext {
    height: 4rem;
    width: 100%;
    padding: 0 1rem;
    color: #333;
    font-size: 1.4rem;
    border: 1px solid #ddd;
    font-style: inherit;
    letter-spacing: 0.08rem;
    border-radius: ${({ theme }) => theme.radius.medium}px;

    ${({ error }) => error && Error};

    &::placeholder {
      color: #999;
      font-weight: 300 !important;
    }

    &:enabled:hover {
      border-color: ${({ theme }) => theme.colors.grey40};
      ${({ error }) => error && Error};
    }

    &:enabled:focus {
      border-color: ${({ theme }) => theme.colors.green};
      ${({ error }) => error && Error};

      outline: 0 none;
      box-shadow: none;
    }
  }
`;
