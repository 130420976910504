import { Image } from '.';

export function addCommentToList(images: Image[], newImageWithComment: Image) {
  return images.map(image => {
    if (image.uuidFoto === newImageWithComment.uuidFoto) {
      return {
        ...image,
        ...newImageWithComment,
      };
    }

    return image;
  });
}
