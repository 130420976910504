import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const FisicoGerencial = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.metrocasa.fisico.gerencial' */
  ),
);
const LinhaDeBalanco = lazy(() =>
  import(
    './layouts/fisico/linhaDeBalanco' /* webpackChunkName: 'relatorios.metrocasa.fisico.linha-de-balanco' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/metrocasa/fisico/gerencial"
    component={FisicoGerencial}
    path="/relatorios/metrocasa/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/metrocasa/fisico/linha-de-balanco"
    component={LinhaDeBalanco}
    path="/relatorios/metrocasa/fisico/linha-de-balanco"
  />,
];
