import React from 'react';
import { useAtom } from 'jotai';
import { CheckedIcon } from 'styles/icons';
import { occurrencesAtom } from '../../atoms';
import { Occurrence } from '../../types';

interface Props {
  row: Occurrence;
}

export default function CheckBox(props: Props) {
  const [occurrences, setOccurrences] = useAtom(occurrencesAtom);

  const { row } = props;

  function handleCheck() {
    const newOccurrences = occurrences.map(occurrence => {
      if (occurrence.idOcorrencia === row.idOcorrencia) {
        return {
          ...occurrence,
          isSelected: !occurrence.isSelected,
        };
      }

      return occurrence;
    });

    setOccurrences(newOccurrences);
  }

  return <CheckedIcon checked={row.isSelected} onClick={handleCheck} />;
}
