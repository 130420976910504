import apiAdonis from './apiAdonis';

export interface CreateOrUpdateSign {
  id?: number;
  uuid: string;
  nome?: string | null;
  email?: string | null;
  cargo?: string | null;
  alcada?: string | number | undefined;
  telefone?: number | null;
  cpf?: string | null;
  idContrato?: number | undefined;
  idEmpresa?: number | undefined;
  idReferencia?: number | undefined;
  uuidArquivo?: string | undefined;
  uuidReferencia?: string | undefined;
  tipo?: string | undefined;
  flagStatus?: number;
  status?: number;
  idUsuarioCriacao?: number;
}

export function updateOrCreate(
  data: CreateOrUpdateSign | CreateOrUpdateSign[],
): Promise<any> {
  return apiAdonis.post(`assinatura/update-or-create`, data);
}

export function get(uuid: string): Promise<CreateOrUpdateSign> {
  return apiAdonis.get(`assinatura/get/${uuid}`);
}

export function sendEmail(data: string[]): Promise<void> {
  return apiAdonis.post('assinatura/send-email', { uuids: data });
}
