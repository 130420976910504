import apiAdonis from './apiAdonis';

export interface ParamsProps {
  type: number;
  event: number;
  constructionId: number;
  beginTime: string | null;
}

interface ExtractDataProps {
  uuid: string;
  fornecedor: string;
  funcao: string;
  quantidade: number;
}

export function extractData(payload: ParamsProps): Promise<ExtractDataProps[]> {
  return apiAdonis.post(`/access-gate-api/extract-data`, payload);
}
