import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const ImageWrapper = styled.div`
  flex: 1;

  img {
    width: 100%;
    height: 80%;
  }
`;

export const ModalTitle = styled.h1`
  color: #333;
  padding: 1rem;
`;
