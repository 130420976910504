import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';

const FinanceiroObras = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'relatorios.buenonetto.financeiro.obras' */
  ),
);
const FinanceiroPortfolio = lazy(() =>
  import(
    './layouts/financeiro/portfolio' /* webpackChunkName: 'relatorios.buenonetto.financeiro.portfolio' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/buenonetto/financeiro/obras"
    component={FinanceiroObras}
    path="/relatorios/buenonetto/financeiro/obras"
  />,
  <PrivateRoute
    key="/relatorios/buenonetto/financeiro/portfolio"
    component={FinanceiroPortfolio}
    path="/relatorios/buenonetto/financeiro/portfolio"
  />,
];
