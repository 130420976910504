import {
  ContractState,
  ContractActionTypes,
  SET_CONTRACT,
  CLEAR_CONTRACT,
  SET_DATASET_ID,
  CLEAR_DATASET_ID,
} from './types';

const initialState: ContractState = {
  contract: null,
  datasetId: 0,
};

export default (
  state = initialState,
  action: ContractActionTypes,
): ContractState => {
  switch (action.type) {
    case SET_CONTRACT:
      return {
        ...state,
        contract: action.payload,
      };
    case CLEAR_CONTRACT:
      return {
        ...state,
        contract: null,
      };
    case SET_DATASET_ID:
      return {
        ...state,
        datasetId: action.datasetId,
      };
    case CLEAR_DATASET_ID:
      return {
        ...state,
        datasetId: 0,
      };
    default:
      return state;
  }
};
