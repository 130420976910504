import camelcaseKeys from 'camelcase-keys';
import moment from 'moment';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { v1 as uuid } from 'uuid';
import NavBar from '../../components/Navbar';
import Spinner from '../../components/Spinner';
import { saveToken, getToken } from '../../services/auth';
import {
  get as fetchCategories,
  save as saveCategory,
} from '../../services/category_file';
import {
  add as addComment,
  update as updateComment,
} from '../../services/comment';
import { download, get, remove, update } from '../../services/file';
import { getName } from '../../services/form';
import { uploadFile, uploadFileNewVersion } from '../../services/upload';
import {
  Category,
  CategoryWithFiles,
  Comment,
  EnumFileType,
  File,
  OptionsType,
  EnumRoutes,
  Company,
} from '../../store/types';
import { toSelectOptions } from '../../utils/common';
import {
  AttachmentIcon,
  Option,
  OptionsWrapper,
  PhotoIcon,
  Title,
} from '../UploadImage/styles';
import Form from './components/Form';
import ListFiles from './components/ListFiles';
import {
  Container,
  FileDropWrapper,
  FooterFileDrop,
  SelectFileLinkWrapper,
  SpinnerWrapper,
  TitleWrapper,
  LinkButton,
} from './styles';
import { sendFiles } from '../../services/email';
import FileGroupModal from './components/FileGroupModal';
import { getAll as getAllFileGroup } from '../../services/file_group';
import { FileGroupContext } from './contexts/FileGroup';

interface UrlParams {
  id: number;
  idContrato: number;
  idDataset: number;
  tipo: string;
  eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9: string; // token
}

interface GlobalFile extends globalThis.File {
  uuid: string;
}

const initialUrlParamsState: UrlParams = {
  id: 0,
  idContrato: 0,
  idDataset: 0,
  tipo: '',
  eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9: '',
};

let filesToUpload: GlobalFile[] = [];

interface Props {
  id?: number;
  idContrato?: number;
  tipo?: string;
}

export default function UploadFile(props: Props): ReactElement {
  const [urlParams, setUrlParams] = useState<UrlParams>(initialUrlParamsState);
  const [pageTitle, setPageTitle] = useState('');
  const [isFetchingFiles, setIsFetchingFiles] = useState(true);
  const [data, setData] = useState<File[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<OptionsType[]>([]);
  const [files, setFiles] = useState<CategoryWithFiles[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [allowSelectFiles, setAllowSelectFiles] = useState(false);
  const filesToSendEmail = useRef<number[]>();

  const [company, setCompany] = useState<Company | null>(null);
  const [fileGroupOptions, setFileGroupOptions] = useState<OptionsType[]>([]);

  const inputFileRef = useRef<HTMLInputElement>();
  const history = useHistory();

  const { addToast } = useToasts();

  useEffect(() => {
    getUrlParams();
  }, []);

  useEffect(() => {
    if (urlParams.id) {
      getCompany();
      getFiles();
      getCategories();
    }
  }, [urlParams]);

  useEffect(() => {
    normalizeData();
  }, [data]);

  useEffect(() => {
    if (props.id) {
      setUrlParams(props as UrlParams);
    }
  }, [props]);

  function getCompany() {
    if (!history) {
      return;
    }

    const { state } = history.location;
    if (state) {
      setCompany(state.company);
      setPageTitle(state.company.empresa);
      getFileGroup();
    } else {
      getPageTitle();
    }
  }

  async function getFileGroup() {
    const res = await getAllFileGroup();
    const options = toSelectOptions(res, { label: 'descricao', value: 'id' });
    setFileGroupOptions(options);
  }

  function getPageTitle() {
    const { id } = props;
    /* When oppened as a modal dones't need to get title */
    if (id) {
      getName(
        urlParams.idContrato,
        urlParams.id,
        urlParams.idDataset || 53,
      ).then(setPageTitle);
    }
  }

  function getUrlParams() {
    if (!history) {
      return;
    }

    const queryData = queryString.parse(history.location.search);

    const { eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9 } = queryData;

    if (eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9) {
      saveToken(eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9 as string);
    }

    if (!getToken()) {
      history.push(EnumRoutes.SIGNIN);

      return;
    }

    const normalizedData = (camelcaseKeys(queryData) as unknown) as UrlParams;

    setUrlParams(normalizedData);
  }

  async function getFiles() {
    const res = await get(urlParams.id, urlParams.tipo);

    setData(res);
    setIsFetchingFiles(false);
  }

  async function getCategories() {
    const id =
      (urlParams.idContrato && Number(urlParams.idContrato)) || urlParams.id;

    const res = await fetchCategories(id);
    const optionsCategories = toSelectOptions(res, {
      label: 'categoria',
      value: 'id',
    });

    setCategoryOptions(optionsCategories);
  }

  function normalizeData() {
    const categoriesName: { [key: string]: boolean } = {};

    data.forEach(({ categoria }) => {
      categoriesName[
        categoria?.categoria || 'Arquivos não Classificados'
      ] = true;
    });

    const normalizedFiles = Object.keys(categoriesName).map<CategoryWithFiles>(
      category => ({
        category,
        files: data.filter(file => file.categoria?.categoria === category),
      }),
    );

    setFiles(normalizedFiles);
  }

  function getFileTypeByName(fileName: string) {
    const splitedName = fileName.split('.');

    return splitedName[splitedName.length - 1] as EnumFileType;
  }

  function handleSelectFiles(newFilesToUpload: FileList | null) {
    if (!newFilesToUpload) {
      return;
    }

    const listFilesToUpload = Array.from(newFilesToUpload) as GlobalFile[];

    if (listFilesToUpload.length) {
      const normalizedFiles = listFilesToUpload.map<File>(file => {
        const uniqueId = uuid();

        /* add uuid by reference to use later */
        /* eslint-disable-next-line */
        file.uuid = uniqueId;

        return {
          nome: file.name,
          uuid: uniqueId,
          id: uniqueId,
          uploading: true,
          categoria: { id: 0, categoria: 'Arquivos não Classificados' },
          comentario: { idComentario: 0, comentario: '' },
          usuario: { idUsuario: 0, nome: '' },
          dtCriacao: '',
          referencia: '',
          tipo: '',
          idReferencia: urlParams.id,
        };
      });

      filesToUpload = listFilesToUpload;

      setData([...normalizedFiles, ...data]);
    }
  }

  async function handleUploadFile(uuidToUpload: string) {
    const file = filesToUpload.find(f => f.uuid === uuidToUpload);

    if (file) {
      const fileType = getFileTypeByName(file.name);

      const newData = {
        nome: file.name,
        tipoArquivo: fileType,
        tipo: urlParams.tipo,
        uuid: uuidToUpload,
        idReferencia: urlParams.id,
      };

      const dataSaved = (await uploadFile({ data: newData, file })) as File;

      /* Change reference of object to add new values with no re-render */
      /* eslint-disable */
      data.map(currentFile => {
        if (currentFile.uuid === dataSaved.uuid) {
          currentFile.uploading = false;
          currentFile.id = dataSaved.id;
          currentFile.dtCriacao = dataSaved.dtCriacao;
          currentFile.usuario = dataSaved.usuario;
          currentFile.referencia = dataSaved.referencia;
          currentFile.idReferencia = dataSaved.idReferencia;
          currentFile.versao = dataSaved.versao;
          currentFile.tipoArquivo = fileType;
          currentFile.tipo = urlParams.tipo;
        }
        return currentFile;
      });
      /* eslint-enable */
    }
  }

  function handleSelectFile(file: File) {
    if (!file.uploading) {
      setSelectedFile(file);
    }
  }

  async function handleSave(
    fileData: File,
    fileToUpdate: globalThis.File | null,
  ) {
    if (!fileData.nome || !fileData.categoria.categoria) {
      addToast('Campo nome e categoria obrigatórios', {
        appearance: 'warning',
      });

      return;
    }

    const newComment = await saveComment(fileData.comentario);
    const newCategory = await checkAndCreateCategory(fileData.categoria);

    let { tipoArquivo } = fileData;
    let { versao } = fileData;

    /* Upload new file */
    if (fileToUpdate) {
      const pathParts = fileData.referencia.split('/');

      await uploadFileNewVersion({
        file: fileToUpdate,
        data: {
          uuid: fileData.uuid,
          fileName: pathParts[pathParts.length - 1],
        },
      });

      tipoArquivo = getFileTypeByName(fileToUpdate.name);
      versao += 1;
    }

    /* Save new file data */
    await update({
      id: fileData.id,
      nome: fileData.nome,
      versao,
      idCategoria: newCategory.id,
      idComentario: newComment.idComentario,
      tipoArquivo,
    });

    /* Refresh data with new values */
    const newData = data.map(f => {
      const newFile = f;
      if (newFile.uuid === fileData.uuid) {
        newFile.nome = fileData.nome;
        newFile.versao = versao;
        newFile.categoria = newCategory;
        newFile.comentario = newComment;
        newFile.uploading = false;
        newFile.tipoArquivo = tipoArquivo;

        setSelectedFile(newFile);
      }

      return newFile;
    });

    setData(newData);
    setSelectedFile(null);
    addToast('Informações salvas', { appearance: 'success' });
  }

  async function checkAndCreateCategory(category: Category): Promise<Category> {
    const newCategory = {
      ...category,
      categoria: category.categoria.toUpperCase(),
    };

    if (newCategory.id) {
      return newCategory;
    }

    const id = await saveCategory({
      categoria: newCategory.categoria.toUpperCase(),
      idContrato: urlParams.idContrato,
      idReferencia: urlParams.id,
    });

    setCategoryOptions([
      ...categoryOptions,
      {
        label: newCategory.categoria.toUpperCase(),
        value: id,
      },
    ]);

    return { ...newCategory, id };
  }

  async function saveComment(comment: Comment): Promise<Comment> {
    if (!comment.idComentario) {
      if (comment.comentario) {
        const newComment: Comment = {
          ...comment,
          idContrato: 0,
          tipo: urlParams.tipo,
          origem: urlParams.tipo,
          id: 0,
          dtCriacao: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
        const { idComentario } = (await addComment(newComment))[0];

        return { ...comment, idComentario };
      }
    } else {
      await updateComment(comment);

      return comment;
    }

    return comment;
  }

  async function handleDelete(file: File) {
    await remove({
      id: file.id,
      idComentario: file.comentario.idComentario,
      versao: file.versao,
    });

    addToast('Anexo removido', { appearance: 'success' });

    const newData = data.filter(f => f.uuid !== file.uuid);
    setData(newData);

    setSelectedFile(null);
  }

  async function handleDownloadFile(file: File) {
    await download(file);
  }

  function handleGoToPhotos() {
    history.push(`upload-image${history.location.search}`);
  }

  function handleSelectFileToSendEmail(id: number) {
    filesToSendEmail.current!.push(id);
  }

  async function handleSendEmail() {
    if (!allowSelectFiles) {
      setAllowSelectFiles(true);
      filesToSendEmail.current = [];

      return;
    }

    if (!filesToSendEmail.current!.length) {
      addToast('Selecione pelo menos um arquivo!', { appearance: 'warning' });

      return;
    }

    await sendFiles(company!.idEmpresa, filesToSendEmail.current!.join(','));

    addToast('Email enviado!', { appearance: 'success' });

    filesToSendEmail.current = [];
    setAllowSelectFiles(false);
  }

  function renderButtons(): ReactElement | null {
    if (company) {
      return null;
    }

    return (
      <OptionsWrapper>
        <Option isSelected={false} onClick={handleGoToPhotos}>
          <PhotoIcon />
          <span> FOTOS </span>
        </Option>
        <Option isSelected>
          <AttachmentIcon />
          <span> ANEXOS </span>
        </Option>
      </OptionsWrapper>
    );
  }

  function renderSubheader() {
    const { id } = props;

    /* Oppend as a modal */
    if (id) {
      return null;
    }

    return (
      <TitleWrapper>
        {renderButtons()}
        <Title>{pageTitle}</Title>
      </TitleWrapper>
    );
  }

  return (
    <FileGroupContext.Provider
      value={{
        fileGroupOptions,
        setFileGroupOptions,
        company,
        setCompany,
      }}
    >
      {history?.location?.search && <NavBar hideButtons />}

      {renderSubheader()}

      <Container>
        <FileDropWrapper>
          <SelectFileLinkWrapper>
            <LinkButton style={{ marginRight: 10 }} onClick={handleSendEmail}>
              {!allowSelectFiles ? 'Comunicar alteração' : 'Enviar email'}
            </LinkButton>
            <LinkButton onClick={() => inputFileRef.current?.click()}>
              Selecionar arquivos
            </LinkButton>
          </SelectFileLinkWrapper>

          {isFetchingFiles && (
            <SpinnerWrapper>
              <Spinner size="medium" />
            </SpinnerWrapper>
          )}

          <FileDrop
            onDrop={(selectedFiles, event) => handleSelectFiles(selectedFiles)}
          >
            <ListFiles
              categories={files}
              allowSelectFiles={allowSelectFiles}
              onSelectFile={handleSelectFile}
              onUpload={handleUploadFile}
              onDelete={handleDelete}
              onDownloadFile={handleDownloadFile}
              onSelectFileToSendEmail={handleSelectFileToSendEmail}
              selectedFileId={selectedFile ? selectedFile.uuid : null}
            />

            <FooterFileDrop>
              <LinkButton onClick={() => inputFileRef.current?.click()}>
                Arraste e solte arquivos aqui, ou clique para selecionar
              </LinkButton>
            </FooterFileDrop>
          </FileDrop>
        </FileDropWrapper>

        <Form
          companyId={company ? company.idEmpresa : null}
          categoryOptions={categoryOptions}
          file={selectedFile}
          onSave={handleSave}
        />
      </Container>
      <FileGroupModal />
      <input
        style={{ display: 'none' }}
        ref={inputFileRef}
        type="file"
        id="multi"
        multiple
        onChange={e => handleSelectFiles(e.target.files)}
      />
    </FileGroupContext.Provider>
  );
}
