import React, { useEffect, useState } from 'react';
import { LogoImg, Container, Wrapper, DonwloadIcon } from './styles';
import HomeLogo from '../../assets/sigin-logo.png';
import useQuery from 'hooks/use-query';
import { Text } from 'styles/text';
import { generateUUID } from 'utils/common';
import { useDispatch } from 'react-redux';
import { setReportQueue } from 'store/report/actions';
import { createAdonis } from 'services/report_queue';
import Button from 'components/Button';

interface DadosParams {
  tipo: string;
  idContrato: number;
  idOcorrencia: number;
  idEmpresa: number;
  tipoRelatorio: string;
  nome: string;
}

interface PropsUrl {
  dados: DadosParams;
}

export default function GenerateDownloadPDF() {
  const [dataParams, setDataParams] = useState({} as DadosParams);
  const params = useQuery<PropsUrl>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.dados) {
      const paramsData = JSON.parse(params?.dados.toString());
      setDataParams(paramsData);

      handleGeneragePDF(paramsData);
    }
  }, [params]);

  async function handleGeneragePDF(params: DadosParams) {
    const reportUuid = generateUUID();

    dispatch(
      setReportQueue({
        nome: params.nome,
        uuid: reportUuid,
        status: 0,
      }),
    );

    await createAdonis({
      idDataset: 0,
      nome: params.nome,
      uuid: reportUuid,
      idContrato: params.idContrato,
      idEmpresa: params.idEmpresa,
      parametros: {
        ...params,
      },
      tipoRelatorio: params.tipoRelatorio,
    });
  }

  return (
    <Container>
      <Wrapper>
        <LogoImg src={HomeLogo} />

        <Button
          primary
          small
          text="Baixar PDF"
          onClick={() => handleGeneragePDF(dataParams)}
          icon={<DonwloadIcon />}
        />

        <Text>
          Caso o download não tenha sido efetuado automaticamente favor clicar
          no botão &#34;Baixar arquivo&#34;
        </Text>
      </Wrapper>
    </Container>
  );
}
