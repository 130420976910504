import styled from 'styled-components';
import { GrTree } from 'react-icons/gr';
import { ButtonWithNoStyle } from '../../../../styles/common';

export const Button = styled.button`
  ${ButtonWithNoStyle}
  width: 100%;
  padding: 1rem;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const ActionWrapper = styled.div`
  position: relative;
`;

export const OptionsWrapper = styled.ul`
  position: absolute;
  background-color: #fff;
  padding: .4rem 0;
  top: .3rem;
  left: 4.5rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  border-radius: ${({ theme }) => theme.radius.medium}px;
  box-shadow: 0 3px 20px rgba(89, 105, 129, .3), 0 1px 2px rgba(0, 0, 0, .05), 0 0 0 1px rgba(89, 105, 129, .1);
  width: 13rem;

  li {
    width: 100%;

    button {
      ${ButtonWithNoStyle}
      width: 100%;
      height: 100%;
      text-align: left;
      cursor: pointer;
      padding: .7rem 1rem;
      font-size: 1.3rem;
      color: #333;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }

  &::after {
    content: " ";
    position: absolute;
    top: 1.2rem;
    left: -1.6rem;
    border-width: .8rem;
    border-style: solid;
    border-color: transparent #fff transparent transparent;
  }
`;

export const IconTree = styled(GrTree)`
  font-size: 2.2rem;
  cursor: pointer;
`;
