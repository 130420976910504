import { FaCheck } from 'react-icons/fa6';
import { IoHomeSharp } from 'react-icons/io5';
import { TbArrowBackUp } from 'react-icons/tb';
import { FiX } from 'react-icons/fi';
import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa';
import { LuRefreshCw } from 'react-icons/lu';

export const ContainerPage = styled.div`
  width: 100vw;
  overflow: hidden;
  height: 100vh;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ProgressContainer = styled.div<{
  marginSize: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
  margin-top: ${({ marginSize }) => marginSize};

  &::before {
    content: '';
    position: absolute;
    top: 1rem;
    left: 5%;
    width: 90%;
    height: 2px;
    background-color: #009688;
    z-index: 0;
  }
`;

export const Step = styled.div<{
  completed: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 5rem;

  & .label {
    margin-top: 8px;
    font-size: 14px;
    color: ${({ completed }) => (completed ? '#009688' : '#666')};
  }
`;

export const Circle = styled.div<{
  completed: boolean;
}>`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${({ completed }) => (completed ? '#009688' : '#fff')};
  border: 2px solid #009688;
  color: ${({ completed }) => (completed ? '#fff' : '#009688')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 13px;
`;

export const IconCheck = styled(FaCheck)`
  font-size: 1.3rem;
  margin-right: 0.5rem;
`;

export const GoBackIcon = styled(TbArrowBackUp)`
  font-size: 1.3rem;
  margin-right: 5px;
`;

export const GoBackHomeIcon = styled(IoHomeSharp)`
  font-size: 1.3rem;
  margin-right: 0.5rem;
`;

export const CancelIcon = styled(FiX)`
  font-size: 1.3rem;
  margin-right: 0.5rem;
`;

export const TrashIcon = styled(FaTrash)`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 1.2rem;
  margin-right: 0.5rem;
`;

export const ReloadIcon = styled(LuRefreshCw)`
  font-size: 1.3rem;
  margin-right: 0.5rem;
`;
