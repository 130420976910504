import {
  ConfigActionTypes,
  SET_IS_MOBILE,
  SET_CUSTOM_GLOBAL_STYLES,
} from './types';

export function setIsMobile(flag: boolean): ConfigActionTypes {
  return {
    type: SET_IS_MOBILE,
    payload: flag,
  };
}

export function setCustomGlobalStyles(styles: object): ConfigActionTypes {
  return {
    type: SET_CUSTOM_GLOBAL_STYLES,
    payload: styles,
  };
}
