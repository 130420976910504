export function getLumaValue(color, colorCoding = 'hex') {
  if (colorCoding === 'hex') {
    let r = '';
    let g = '';
    let b = '';
    if (color.length === 4) {
      r = color.substring(1, 2) + color.substring(1, 2);
      g = color.substring(2, 3) + color.substring(2, 3);
      b = color.substring(3, 4) + color.substring(3, 4);
    }
    if (color.length === 7) {
      r = color.substring(1, 3);
      g = color.substring(3, 5);
      b = color.substring(5, 7);
    }
    const rInt = parseInt(r, 16);
    const gInt = parseInt(g, 16);
    const bInt = parseInt(b, 16);

    const luma = 0.2126 * rInt + 0.7152 * gInt + 0.0722 * bInt; // per ITU-R BT.709
    return luma;
  }

  if (colorCoding === 'rgb') {
    return 128;
  }

  return 128;
}

export function sliceText(str, charCount) {
  return str.slice(0, charCount) + (str.length > charCount ? '...' : '');
}
