import styled from 'styled-components';
import { MdFileUpload } from 'react-icons/md';
import { FiTrash2 } from 'react-icons/fi';

export const LabelContainer = styled.label`
  border-radius: ${({ theme }) => theme.radius.medium}px;
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #eee;
  height: 4rem;

  &:hover {
    background-color: #ddd;
  }

  input {
    display: none;
  }

  span {
    font-size: 1.2rem;
    margin-left: 0.6rem;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    justify-content: center;
  }
`;

export const IconUpload = styled(MdFileUpload)`
  font-size: 1.8rem;
`;

export const FileListWrapper = styled.ul`
  font-size: 1.2rem;
  font-weight: 300;
  width: 100%;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.rowHover};
    }
  }
`;

export const IconRemove = styled(FiTrash2)`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 1.4rem;
`;
