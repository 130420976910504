import { createContext, useContext } from 'react';
import { OptionsType } from 'store/types';

type SupplierCreatableSelectContextType = {
  getData: () => void;
  handleChange(option: OptionsType): void;
};

export const SupplierCreatableSelectContext = createContext<
  SupplierCreatableSelectContextType | undefined
>(undefined);

export function useSupplierCreatableSelectContext() {
  return useContext(SupplierCreatableSelectContext);
}