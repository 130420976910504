import SimpleNavbar from 'components/SimpleNavbar';
import useDeviceDetect from 'hooks/use-device-detect';
import { useAtomValue } from 'jotai';
import { urlParamsAtom } from 'pages/DocSign/atoms';
import { isSourceApp } from 'pages/DocSign/utils';
import React, { ReactNode } from 'react';
import { MobileContainer, WebContainer } from './styles';

interface Props {
  children: ReactNode | ReactNode[];
}

export default function WarningPageContainer(props: Props) {
  const urlParams = useAtomValue(urlParamsAtom);

  const { isMobile } = useDeviceDetect();

  const { children } = props;

  return (
    <>
      {!isSourceApp(urlParams) && <SimpleNavbar title="Assinatura" />}
      <>
        {isMobile ? (
          <MobileContainer>{children}</MobileContainer>
        ) : (
          <WebContainer>{children}</WebContainer>
        )}
      </>
    </>
  );
}
