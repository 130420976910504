import {
  ConfigState,
  ConfigActionTypes,
  SET_IS_MOBILE,
  SET_CUSTOM_GLOBAL_STYLES,
} from './types';

const initialState: ConfigState = {
  isMobile: false,
  customGlobalStyles: {},
};

export default (
  state = initialState,
  action: ConfigActionTypes,
): ConfigState => {
  switch (action.type) {
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    case SET_CUSTOM_GLOBAL_STYLES:
      return {
        ...state,
        customGlobalStyles: action.payload,
      };
    default:
      return state;
  }
};
