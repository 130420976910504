import React, { lazy } from 'react';
import { PrivateRoute } from '../../../../routes';
const FisicoDash = lazy(() =>
  import(
    './layouts/fisico/gerencial' /* webpackChunkName: 'relatorios.duo.fisico.gerencial' */
  ),
);
const LinhaDeBalanco = lazy(() =>
  import(
    './layouts/fisico/linhaDeBalanco' /* webpackChunkName: 'relatorios.duo.fisico.linha-de-balanco' */
  ),
);
const VendasRecebiveis = lazy(() =>
  import(
    './layouts/vendas/recebiveis' /* webpackChunkName: 'relatorios.duo.vendas.recebiveis' */
  ),
);
const FinanceiroDash = lazy(() =>
  import(
    './layouts/financeiro/obras' /* webpackChunkName: 'relatorios.duo.financeiro.obras' */
  ),
);
const AcompanhamentoSemanal = lazy(() =>
  import(
    './layouts/fisico/acompanhamentoSemanal' /* webpackChunkName: 'relatorios.duo.fisico.acompanhamento-semanal' */
  ),
);
const GerencialDash = lazy(() =>
  import(
    './layouts/gerencial' /* webpackChunkName: 'relatorios.duo.gerencial' */
  ),
);

export const routes = [
  <PrivateRoute
    key="/relatorios/duo/fisico/gerencial"
    component={FisicoDash}
    path="/relatorios/duo/fisico/gerencial"
  />,
  <PrivateRoute
    key="/relatorios/duo/fisico/linha-de-balanco"
    component={LinhaDeBalanco}
    path="/relatorios/duo/fisico/linha-de-balanco"
  />,
  <PrivateRoute
    key="/relatorios/duo/financeiro/obras"
    component={FinanceiroDash}
    path="/relatorios/duo/financeiro/obras"
  />,
  <PrivateRoute
    key="/relatorios/duo/vendas/recebiveis"
    component={VendasRecebiveis}
    path="/relatorios/duo/vendas/recebiveis"
  />,
  <PrivateRoute
    key="/relatorios/duo/fisico/acompanhamento-semanal"
    component={AcompanhamentoSemanal}
    path="/relatorios/duo/fisico/acompanhamento-semanal"
  />,
  <PrivateRoute
    key="/relatorios/duo/gerencial"
    component={GerencialDash}
    path="/relatorios/duo/gerencial"
  />,
];
