import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
`;

export const ContainerImgMaster = styled.div`
  margin-right: 2rem;
  flex: 0 0 30vw;
  height: 53rem;
`;

export const ContainerImgSub = styled.div`
  display: flex;
  flex-direction: column;
  height: 52rem;
  flex-wrap: wrap;
`;
