import XLSX from 'xlsx';
import { v1 as uuid } from 'uuid';

export function readXlsx<T>(files: File, sheet = 0): Promise<T> {
  const read = (res, rej) => {
    const reader = new FileReader();

    reader.onload = (evt: ProgressEvent<FileReader>) => {
      const bstr = evt.target!.result;

      const workbook = XLSX.read(bstr, {
        type: 'binary',
      });

      const firstWorksheet = workbook.Sheets[workbook.SheetNames[sheet]];
      const sheetData = XLSX.utils.sheet_to_json(firstWorksheet, { header: 1 });
      // normalizeXlsToTable(sheetData);

      res(sheetData);
    };

    reader.readAsBinaryString(files);
  };

  return new Promise(read);
}

export interface FormGate {
  uuid: string;
  funcao: string;
  fornecedor: string;
  quantidade: number;
}

export function normalizeFormGate(dataXls: any[]) {
  const supplyer = dataXls[5];

  const roles = dataXls.slice(6).filter(role => role[0] && role[0] !== 'Total');

  const finalData: FormGate[] = [];

  roles.forEach(roleData => {
    let roleDescription = '';

    roleData.forEach((role, idx) => {
      /* Get only the role description */
      if (idx === 0) {
        roleDescription = role;

        return;
      }

      /* Ignore the last column */
      if (idx === roleData.length - 1) {
        return;
      }

      finalData.push({
        uuid: uuid(),
        funcao: roleDescription,
        fornecedor: supplyer[idx],
        quantidade: role,
      });
    });
  });

  return finalData;
}

export function normalizeFormGateKallas(dataXls: any[]) {
  const supplyer = dataXls[8];

  const indexEndRoles = dataXls.findIndex(role => role[0] === 'TOTAL GERAL');
  const roles = dataXls
    .slice(9, indexEndRoles)
    .filter(role => role[0] && role[0] !== 'TOTAL GERAL');

  const finalData: FormGate[] = [];

  roles.forEach(roleData => {
    let roleDescription = '';

    roleData.forEach((role, idx) => {
      /* Get only the role description */
      if (idx === 0) {
        roleDescription = role;

        return;
      }

      /* Ignore the last column */
      if (idx === roleData.length - 1) {
        return;
      }

      finalData.push({
        uuid: uuid(),
        funcao: roleDescription,
        fornecedor: supplyer[idx],
        quantidade: role,
      });
    });
  });

  return finalData;
}

export function normalizeFormGateCsv(dataCsv: any[]) {
  const [, ...roles] = dataCsv;
  const finalData: FormGate[] = [];

  roles.forEach(roleData => {
    const alreadyExists = finalData.findIndex(
      row => row.fornecedor === roleData[11] && row.funcao === roleData[10],
    );

    if (alreadyExists >= 0) {
      return;
    }

    const quantitySupplier = roles.filter(
      row => row[11] === roleData[11] && row[10] === roleData[10],
    ).length;

    finalData.push({
      uuid: uuid(),
      fornecedor: roleData[11],
      funcao: roleData[10],
      quantidade: quantitySupplier,
    });
  });

  return finalData;
}
