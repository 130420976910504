import styled from 'styled-components';
import { FiTrash2 } from 'react-icons/fi';
import { MdFileDownload } from 'react-icons/md';
import CheckBoxComponent from '../../../../components/CheckBox';
import { ButtonWithNoStyle } from '../../../../styles/common';

export const Container = styled.div`
  overflow: scroll;
  height: 95%;
  flex: 0 0 50%;
`;

export const CategoryName = styled.h2`
  color: #333;
  margin-bottom: 0.5rem;
`;

export const ItemWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.6rem;
  cursor: pointer;

  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.rowHover : 'transparent'};

  &:not(:last-child) {
    border-bottom: 1px solid #f5f5f5;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.rowHover};
  }
`;

export const ItemButton = styled.div`
  ${ButtonWithNoStyle}
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1;

  span {
    color: #333;
    font-size: 1.4rem;
    margin-left: 1rem;
    word-break: break-word;
  }
`;

export const CategoryWrapper = styled.div`
  margin-bottom: 0.8rem;
`;

export const LeftIconWrapper = styled.div`
  flex: 0 0 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightIconWrapper = styled.div`
  margin-left: auto;
  padding: 0 0.6rem;
  display: flex;
  align-items: center;
`;

export const IconRemove = styled(FiTrash2)`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 2rem;
`;

export const IconDownload = styled(MdFileDownload)`
  color: #333;
  font-size: 2.2rem;
  margin-right: 1rem;
`;

export const CheckBox = styled(CheckBoxComponent)`
  margin-left: 1rem;
  margin-bottom: 0.2rem;
`;
