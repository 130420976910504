import React, { useEffect, useState } from 'react';
import FishboneChart from 'fishbone-chart';
import { Container } from './styles';
import { getSqlResponseAdonis } from 'services/dataset';

interface Props {
  idDataset: number;
  datasetParams?: any;
  height?: number;
  width?: number;
}

export default function Fishbone(props: Props) {
  const { idDataset, datasetParams, height, width } = props;

  const [data, setData] = useState({});

  useEffect(() => {
    initData();
  }, [idDataset, datasetParams]);

  async function initData() {
    if (!idDataset) {
      return;
    }

    const dataAux = await getSqlResponseAdonis(idDataset, {
      ...datasetParams,
    });

    setData(normalizedData(dataAux));
  }

  function normalizedData(data) {
    const newData = data.reduce((acc, item) => {
      const { cause, category, issues } = item;

      if (!acc[cause]) {
        acc[cause] = {};
      }

      if (!acc[cause][category]) {
        acc[cause][category] = [];
      }

      acc[cause][category].push(issues);

      return acc;
    }, {});

    return newData;
  }

  return (
    <Container height={height} width={width} id="graphic">
      <FishboneChart data={data} />
    </Container>
  );
}
