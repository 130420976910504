import UserCreatableSelect from 'components/Widgets/UserCreatableSelect';
import React from 'react';
import { Occurrence, Responsible } from '../../types';
import { Container, WrapperCheckbox } from './styles';
import showAlert from 'components/Alert';
import CheckBox from 'components/CheckBox';
import { Label } from 'styles/form';
interface Props {
  setFormData(formData: Occurrence): void;
  formData: Occurrence;
  idContrato: number;
}

export default function SelectListResponsible(props: Props) {
  const { formData, setFormData, idContrato } = props;

  function handleSelect(options: Responsible[]) {
    setFormData({
      ...formData,
      responsaveis: options || [],
      flagEmailEnviado: options && formData.flagEmailEnviado ? 1 : 0,
    });
  }

  function getSelectedValue(): Responsible[] | undefined {
    const { responsaveis } = formData;

    return responsaveis;
  }

  function handleCheckbox() {
    if (!formData.responsaveis.length) {
      showAlert({
        title: 'Preencha um responsável',
      });

      return;
    }

    const newCheckedValue = formData.flagEmailEnviado !== 0 ? 0 : 1;

    setFormData({
      ...formData,
      flagEmailEnviado: newCheckedValue,
    });
  }

  return (
    <Container>
      <Label> Responsáveis / Envolvidos </Label>

      <UserCreatableSelect
        isClearable
        datasetId={2405}
        user={getSelectedValue()}
        setUser={handleSelect}
        idContrato={idContrato}
        isMulti
        primaryKey="uuid"
      />
      <WrapperCheckbox>
        <CheckBox
          id="checkbox"
          label="Enviar email aos responsáveis"
          checked={!!formData.flagEmailEnviado}
          onChange={handleCheckbox}
        />
      </WrapperCheckbox>
    </Container>
  );
}
