import React from 'react';
import { P } from 'styles/text';
import { Container } from './styles';

export default function PrivacyPolicy() {
  return (
    <>
      <div
        style={{
          backgroundColor: '#54C3BD',
          height: '55px',
          display: 'flex',
          alignItems: 'center',
          padding: '15px',
          paddingTop: '18px',
        }}
      >
        <img
          width={120}
          height={25}
          src="https://gescorpgo-datafiles.nyc3.digitaloceanspaces.com/datafiles/logos/empresa/2b.png"
          alt="gescorp"
        />
      </div>
      <Container>
        <P>
          <b>Política de Privacidade para GescorpGO</b>
        </P>
        <br />
        <P>
          Esta Política de Privacidade descreve como GescorpGO coleta, usa e
          compartilha suas informações pessoais quando você usa nosso
          aplicativo.
        </P>
        <br />
        <P>
          Informações que coletamos Podemos coletar informações pessoais suas,
          tais como: Informações de identificação pessoal: como nome, endereço
          de e-mail. Informações técnicas: como endereço IP, tipo de
          dispositivo, sistema operacional, informações de localização.
        </P>
        <br />
        <P>
          Como usamos suas informações Usamos as informações coletadas para:
          Fornecer e melhorar nosso aplicativo. Responder às suas perguntas e
          solicitações. Para cumprir as obrigações legais e regulamentares.
        </P>
        <br />
        <P>
          Com quem compartilhamos suas informações Podemos compartilhar suas
          informações com: Autoridades reguladoras e governamentais, se exigido
          por lei.
        </P>
        <br />
        <P>
          Seus direitos Você tem o direito de solicitar o acesso, a correção, a
          exclusão ou a limitação do uso de suas informações pessoais. Para
          exercer esses direitos, entre em contato conosco em [endereço de
          e-mail de contato]. Alterações à nossa Política de Privacidade Podemos
          atualizar nossa Política de Privacidade periodicamente. Avisaremos
          sobre quaisquer alterações, publicando a nova Política de Privacidade
          nesta página.
        </P>
        <br />
        <P>
          Contate-Nos Se você tiver dúvidas sobre esta Política de Privacidade,
          entre em contato conosco em: rodrigo.sabanai@gescorpgo.com
        </P>
      </Container>
    </>
  );
}
