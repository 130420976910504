import { MenuData, ReloadInfo } from './types';

export function getReloadInfo(menuData: MenuData[], idLayout: number) {
  const values: ReloadInfo = {
    idPerfilModulo: 0,
    name: '',
    idAcoes: '',
    datasetId: 0,
  };

  for (const menu of menuData) {
    if (!menu.subMenu?.length) continue;
    for (const subMenu of menu.subMenu) {
      for (const child of subMenu.children) {
        if (
          child.route !== window.location.pathname ||
          (idLayout && idLayout != child.params?.layoutId)
        ) {
          continue;
        }

        if (menu.idPerfilModulo) {
          values.idPerfilModulo = menu.idPerfilModulo;
        }

        if (child.name) {
          values.name = child.name;
        }

        if (child.idAcoes) {
          values.idAcoes = child.idAcoes;
        }

        if (child.params?.datasetId) {
          values.datasetId = child.params.datasetId;
        }

        return values;
      }
    }
  }

  return values;
}

export function getFullRoute(route: string, layoutId: number | undefined) {
  let search = '';

  if (layoutId && route === '/table-report') {
    search = `?idLayout=${layoutId}`;
  }

  return route + search;
}
