import styled from 'styled-components';

export const Footer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
`;

export const ContainerInputs = styled.div``;

export const InputText = styled.input`
  height: 4rem;
  width: 100%;
  padding: 0 1rem;
  color: #333;
  font-size: 1.4rem;
  border: 1px solid #ddd;
  font-style: inherit;
  letter-spacing: 0.08rem;
  border-radius: ${({ theme }) => theme.radius.medium}px;
  font-family: 'Roboto', 'Open Sans', sans-serif;

  &::placeholder {
    color: #999;
    font-weight: 300;
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.grey40};
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
    outline: 0 none;
  }
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.danger};
`;
